import { useState } from "react";

import { Modal } from "@smart/itops-sb-design-system-dom";
import { isNotNullOrUndefined } from "@smart/itops-utils-basic";
import { useUpdateField } from "@smart/manage-gql-client-dom";
import { GqlFieldValues } from "@smart/manage-gql-operations-dom/src/generated/types";

import { mapMatterField } from "./helpers";
import { useMappedMatterFields } from "./hooks";
import { Picker, PickerSelection } from "./picker";
import {
  GqlField,
  GqlGroup,
  GqlMatterLayout,
  GqlMatterType,
} from "../../../types";
import { fieldComponentIcons } from "../../constants";
import { GqlGroupValues, LoadMatterFields } from "../../types";

export type MappedFieldsModalProps = {
  onClose: () => void;
  updateField: ReturnType<typeof useUpdateField>[0];
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  fields: GqlField[];
  groups: GqlGroup[];
  loadMatterFields: LoadMatterFields;
  selectedFieldToMap: GqlFieldValues | undefined;
  loading?: boolean;
};

export const ChangeMappedFieldTypeModal = ({
  selectedFieldToMap,
  onClose,
  matterLayouts,
  matterTypes,
  fields,
  groups,
  loadMatterFields,
  updateField,
  loading: dataLoading,
}: MappedFieldsModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const selectedFieldGroup = groups.find(
    (g) => g.uri === selectedFieldToMap?.groupUri,
  );

  const buildSelectionForLayoutContactGroup = (
    groupValues: GqlGroupValues | undefined,
  ): PickerSelection | undefined => {
    if (
      groupValues?.type !== "layoutContact" ||
      !groupValues.layout ||
      !groupValues.field
    )
      return undefined;

    const { layout, field } = groupValues;

    return {
      layout,
      prefix: field.details?.split("/") || [],
      fields: [],
      components: [
        {
          displayName: layout.displayName || layout.name,
          name: layout.name,
          type: "layout" as const,
        },
        {
          name: field.name,
          displayName: field.displayName || field.name,
          type: "role" as const,
        },
      ],
    };
  };

  const selectionForGroup = buildSelectionForLayoutContactGroup(
    selectedFieldGroup?.values,
  );

  const initial: PickerSelection = {
    layout: selectionForGroup?.layout,
    prefix: selectionForGroup?.prefix || [],
    fields: [],
    components: selectionForGroup?.components || [],
  };

  const [selection, setSelection] = useState<PickerSelection>(initial);

  const {
    isLoading: isLoadingMatterFields,
    matterFields,
    existingMappedFields,
  } = useMappedMatterFields({
    selection,
    fields,
    matterTypes,
    groups,
    loadMatterFields,
  });

  const onSaveChanges = async () => {
    setIsSaving(true);

    try {
      if (selection.fields.length && selection.layout && selectedFieldToMap) {
        const newField = selection.fields
          .map(mapMatterField(selection.layout))
          .filter(isNotNullOrUndefined)[0];

        await updateField({
          variables: {
            uri: selectedFieldToMap.uri,
            sectionUri: selectedFieldToMap.sectionUri,
            formUri: selectedFieldToMap.formUri,
            order: selectedFieldToMap.order,
            groupUri: selectedFieldToMap.groupUri || undefined,
            fields: {
              label: selectedFieldToMap.label,
              hint: selectedFieldToMap.hint,
              options: newField.options || [],
              mandatory: selectedFieldToMap.mandatory,
              allowCustomResponse: newField.allowCustomResponse,
              layout: newField.layout,
              field: newField.field,
              type: newField.type,
              links: undefined,
            },
          },
        });
      }
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
      setSelection(initial);
    }
  };

  return (
    <Modal
      className="[&_.modal-container]:min-h-[min(90rem,95vh)]"
      variant="scrollable"
      open={!!selectedFieldToMap}
      onClose={() => {
        setSelection(initial);
        onClose();
      }}
      loading={isSaving}
      header={{
        icon: fieldComponentIcons.mapped.name,
        iconBgColor: fieldComponentIcons.mapped.bgColor,
        text: "Select a mapped field",
      }}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: () => {
              setSelection(initial);
              onClose();
            },
          },
          {
            text: `Update field`,
            variant: "primaryDefault",
            disabled:
              !selection.layout ||
              !selection.fields.length ||
              isLoadingMatterFields ||
              dataLoading ||
              isSaving,
            onClick: onSaveChanges,
          },
        ],
      }}
    >
      <Picker
        loading={dataLoading || isLoadingMatterFields || false}
        isLoadingMatterFields={isLoadingMatterFields}
        selection={selection}
        setSelection={setSelection}
        matterTypes={matterTypes || []}
        layouts={matterLayouts || []}
        fields={matterFields}
        existingMappedFields={existingMappedFields}
        multiSelect={false}
        fixedSelection={selectionForGroup}
        isInGroup={!!selectedFieldGroup}
      />
    </Modal>
  );
};
