import { Toggle } from "@smart/itops-components-dom";
import { useToggleSlug } from "@smart/manage-gql-client-dom";

const Slug = ({ formUri, active }: { formUri: string; active: boolean }) => {
  const [mutate] = useToggleSlug();

  return (
    <Toggle
      sub="Active"
      size={5}
      toggled={active}
      onClick={() =>
        mutate({
          variables: {
            formUri,
            fields: { active: !active },
          },
        })
      }
    />
  );
};

export { Slug };
