import clsx from "clsx";
import { forwardRef, TextareaHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

import { getWrapperClassNames, inputClassNames } from "./class-names";
import { Label } from "../label";

const textAreaStyles = "resize-none min-h-20";

export type BaseTextAreaProps = {
  label?: string;
  mandatory?: boolean;
  message?: string;
  error?: boolean;
  className?: string;
  dataTestId?: string;
} & Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "placeholder"
  | "rows"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
>;

const BaseTextArea = forwardRef<HTMLTextAreaElement, BaseTextAreaProps>(
  (
    {
      id,
      error,
      message,
      disabled,
      dataTestId,
      className,
      ...inputProps
    }: BaseTextAreaProps,
    ref,
  ) => (
    <div
      className={twMerge(
        clsx(getWrapperClassNames({ error, disabled }), className),
      )}
      aria-disabled={disabled}
      data-testid={dataTestId}
    >
      <textarea
        id={id}
        disabled={disabled}
        ref={ref}
        aria-invalid={error}
        aria-errormessage={error ? message : undefined}
        className={clsx(inputClassNames, textAreaStyles)}
        {...inputProps}
      />
    </div>
  ),
);

export const TextArea = (props: BaseTextAreaProps) => (
  <Label
    text={props.label}
    mandatory={props.mandatory}
    disabled={props.disabled}
    name={props.id}
    error={!!props.error}
    message={props.message}
  >
    <BaseTextArea {...props} />
  </Label>
);
