import { z } from "zod";

import {
  fieldLinkSchema,
  fieldTypeSchema,
  groupTemplateTypeSchema,
  groupTypeSchema,
  meetingTypeSchema,
} from "@smart/bridge-types-basic";
import { hasDuplicate } from "@smart/itops-utils-basic";

export const fieldSchema = z.object({
  label: z.string(),
  hint: z.string(),
  type: fieldTypeSchema,
  options: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .superRefine((items, ctx) => {
      if (
        hasDuplicate(
          items.map((item) => ({
            ...item,
            value: item.value.toLocaleLowerCase(),
          })),
          "value",
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
  mandatory: z.boolean(),
  allowCustomResponse: z.boolean().optional().nullable(),
  layout: z
    .object({
      id: z.string(),
      providerId: z.string(),
      name: z.string(),
      parentId: z.string().optional().nullable(),
      parentName: z.string().optional().nullable(),
      parentProviderId: z.string().optional().nullable(),
      displayName: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  field: z
    .object({
      name: z.string(),
      type: z.string(),
      possibleValues: z.array(z.string()).optional().nullable(),
      displayName: z.string().optional().nullable(),
      details: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  links: z.array(fieldLinkSchema).optional().nullable(),
  allowCopyFromFieldUri: z.string().optional(),
  availableStaffIds: z.array(z.string()).optional(),
  duration: z.number().optional(),
  availability: z
    .array(
      z.object({
        day: z.number(),
        fromTime: z.object({
          hour: z.number(),
          minute: z.number(),
        }),
        toTime: z.object({
          hour: z.number(),
          minute: z.number(),
        }),
        enabled: z.boolean(),
      }),
    )
    .optional(),
  timezone: z.string().optional(),
  bufferTime: z.number().optional(),
  minimumNotice: z.number().optional(),
  meetingType: meetingTypeSchema.optional(),
});

export const groupSchema = z
  .object({
    label: z.string().optional(),
    hint: z.string(),
    type: groupTypeSchema,
    templateType: groupTemplateTypeSchema.optional(),
    layout: z
      .object({
        id: z.string(),
        providerId: z.string(),
        name: z.string(),
        parentId: z.string().optional().nullable(),
        parentName: z.string().optional().nullable(),
        parentProviderId: z.string().optional().nullable(),
        displayName: z.string().optional().nullable(),
      })
      .optional(),
    field: z
      .object({
        name: z.string(),
        type: z.string(),
        possibleValues: z.array(z.string()).optional().nullable(),
        displayName: z.string().optional().nullable(),
        details: z.string().optional().nullable(),
        allowedRepeatable: z.boolean().optional(),
      })
      .optional(),
    links: z.array(fieldLinkSchema).optional().nullable(),
    fields: z.array(
      z.object({
        uri: z.string(),
        order: z.string(),
        label: z.string(),
        hint: z.string(),
        type: fieldTypeSchema,
        options: z.array(
          z.object({
            value: z.string(),
            label: z.string(),
          }),
        ),
        layout: z
          .object({
            id: z.string(),
            providerId: z.string(),
            name: z.string(),
            displayName: z.string().optional().nullable(),
            parentId: z.string().optional().nullable(),
            parentName: z.string().optional().nullable(),
            parentProviderId: z.string().optional().nullable(),
          })
          .optional()
          .nullable(),
        field: z
          .object({
            name: z.string(),
            type: z.string(),
            possibleValues: z.array(z.string()).optional().nullable(),
            displayName: z.string().optional().nullable(),
            details: z.string().optional().nullable(),
            allowedRepeatable: z.boolean().optional(),
          })
          .optional()
          .nullable(),
        mandatory: z.boolean(),
        allowCustomResponse: z.boolean().optional().nullable(),
        roleFieldName: z.string().optional(),
      }),
    ),
    repeatable: z.boolean().optional(),
    repeatPrompt: z.string().optional(),
    minRepeat: z.number().optional(),
    maxRepeat: z.number().optional(),
    allowedRepeatable: z.boolean().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.type === "layout" && !data.layout)
      ctx.addIssue({
        code: "custom",
        message: "This field is required",
        path: ["layout"],
      });
    if (
      data.type === "layout" &&
      data.layout &&
      data.fields.every((f) => !f.layout && !f.field)
    )
      ctx.addIssue({
        code: "custom",
        message: "Layout field is required",
        path: ["fields"],
      });

    if (data.type === "template" && !data.templateType)
      ctx.addIssue({
        code: "custom",
        message: "This field is required",
        path: ["templateType"],
      });
  });

export const sectionSchema = z.object({
  title: z.string().min(1, "required"),
  links: z.array(fieldLinkSchema).optional().nullable(),
});

export type FieldSchema = z.infer<typeof fieldSchema>;

export type GroupSchema = z.infer<typeof groupSchema>;

export type SectionSchema = z.infer<typeof sectionSchema>;

export type GroupField = z.infer<typeof groupSchema>["fields"][number];
