import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { globalTeamUri } from "@smart/bridge-types-basic";
import { ContextMenu } from "@smart/itops-sb-design-system-dom";
import { extractId } from "@smart/itops-types-basic";
import {
  useToggleSlug,
  useUpdateFormOwnership,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { GqlForm } from "../types";

export type FormMenuProps = {
  form: GqlForm;
  setDeletingForm: (form: GqlForm) => void;
  setDuplicatingForm: (form: GqlForm) => void;
};

export const FormMenu = ({
  form,
  setDeletingForm,
  setDuplicatingForm,
}: FormMenuProps) => {
  const { permission } = useUser();
  const { globalForm } = useFeatureFlags();
  const [updateSlug, toggleSlug] = useToggleSlug();
  const [updateFormOwnership] = useUpdateFormOwnership();
  const navigate = useNavigate();

  const isGenerating =
    form.aiUserFeedback?.status === "generating" ||
    form.aiUserFeedback?.status === "generated";
  const canActivate =
    !isGenerating &&
    permission?.can("update", {
      type: "slug",
      value: {
        uri: form.rawForm.slug?.uri || "",
        active: form.active ?? false,
        formUri: form.uri,
      },
      parentForm: {
        uri: form.uri,
        teamUri: form.teamUri,
        active: form.active,
      },
    });
  const canView = permission?.can("view", {
    type: "form",
    value: { uri: form.uri, teamUri: form.teamUri, active: form.active },
  });
  const canDuplicate = canView && !isGenerating;
  const canDelete = permission?.can("delete", {
    type: "form",
    value: { uri: form.uri, teamUri: form.teamUri, active: form.active },
  });
  const canMoveToGlobal =
    form.teamUri !== globalTeamUri &&
    permission?.can("add", {
      type: "form",
      value: { uri: form.uri, teamUri: globalTeamUri },
    });

  return (
    <ContextMenu
      button={{
        className: "w-[2rem] h-[2rem]",
        variant: "secondarySubtle",
        leftIcon: "solidEllipsis",
      }}
      placement="bottom-end"
      offsetBy={5}
      items={[
        {
          label: form.active ? "Active" : "Inactive",
          toggled: form.active ?? false,
          disabled: toggleSlug.loading,
          hidden: !canActivate,
          keepOpen: true,
          onClick: () =>
            updateSlug({
              variables: {
                formUri: form.uri,
                fields: { active: !form.active },
              },
            }),
        },
        {
          label: "Open form",
          leftIcon: "regularShare",
          onClick: () => navigate(`/embedded/builder/${extractId(form.uri)}`),
        },
        {
          label: "Duplicate form",
          leftIcon: "regularCopy",
          onClick: () => setDuplicatingForm(form),
          hidden: !canDuplicate,
        },
        {
          label: "Delete form",
          leftIcon: "regularTrashCan",
          className: "text-red-550",
          hidden: !canDelete,
          onClick: () => setDeletingForm(form),
        },
        {
          label: "Move to global forms",
          leftIcon: "regularBooks",
          hidden: !globalForm || !canMoveToGlobal,
          onClick: () =>
            updateFormOwnership({
              variables: { uri: form.uri, teamUri: globalTeamUri },
            }),
        },
      ]}
      id="form-action-menu"
      className="w-fit"
    />
  );
};
