import styled from "@emotion/styled";

const AreaDisplayWrapper = styled.div`
  margin-top: 2rem;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    h2 {
      font-size: ${(props) => props.theme.fontSize.emphasis};
      font-weight: 600;
      margin: 0;
    }

    .edit-button {
      border: none;
      background: none;
      text-decoration: underline;
      color: ${(props) => props.theme.palette.primary.base};
      cursor: pointer;

      &:disabled {
        cursor: progress;
      }
    }
  }

  .contents {
    font-size: ${(props) => props.theme.fontSize.base};
  }
`;

type AreaDisplayProps = {
  heading: string;
  contents: string[] | null | undefined;
  loading?: boolean;
  empty: string;
  onEdit: () => void;
};

export const AreaDisplay = ({
  heading,
  contents,
  loading,
  empty,
  onEdit,
}: AreaDisplayProps) => {
  const contentsToDisplay = contents?.length ? (
    [...contents].sort().join(", ")
  ) : (
    <i>{loading && !contents ? "Loading..." : empty}</i>
  );

  return (
    <AreaDisplayWrapper>
      <div className="heading">
        <h2>{heading}</h2>
        <button
          className="edit-button"
          data-testid={`Edit ${heading}`}
          onClick={onEdit}
          type="button"
          disabled={loading}
        >
          Edit
        </button>
      </div>
      <div className="contents">{contentsToDisplay}</div>
    </AreaDisplayWrapper>
  );
};
