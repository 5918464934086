import { useMemo, useState } from "react";

import { getMatterTypeRepresentatives } from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { entriesOf, fromEntries, groupBy } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLazyLoadContacts,
  useLoadFields,
  useLoadForm,
  useLoadGroups,
  useLoadSections,
  useQuerySubmission,
  useUpdateResponseExistingItemIds,
  useUpdateResponseSyncStatus,
} from "@smart/manage-gql-client-dom";
import { useSyncToMatter, useToast } from "@smart/manage-hooks-dom";

import { ContactReview } from "../view/contact-review";

type ReviewContactProps = {
  onClose: () => void;
  matterSubmission: MatterSubmission | undefined;
};

export const ReviewContactModal = ({
  onClose,
  matterSubmission,
}: ReviewContactProps) => {
  const { setToasts } = useToast();
  const { matters, roles, contacts, relationships } = useSmokeballApp();
  const searchContacts = useLazyLoadContacts();
  const [updateResponseExistingItemIds] = useUpdateResponseExistingItemIds();
  const [updateResponseSyncStatus] = useUpdateResponseSyncStatus();
  const querySubmission = useQuerySubmission("network-only");
  const { sync } = useSyncToMatter();

  const [rolesAndRelationships, setRolesAndRelationships] = useState<
    Record<string, { contactId: string }[]>
  >({});

  const form = useLoadForm({ uri: matterSubmission?.formUri || "" });
  const sections = useLoadSections({
    formUri: matterSubmission?.formUri || "",
  });
  const groups = useLoadGroups({ formUri: matterSubmission?.formUri || "" });
  const fields = useLoadFields({ formUri: matterSubmission?.formUri || "" });

  const formMatterTypeRepresentatives = useMemo(
    () =>
      form.result
        ? getMatterTypeRepresentatives(form.result.values.matterTypes)
        : undefined,
    [form.result],
  );

  useAsync(async () => {
    const existingRoles = await roles?.get();
    const existingRelationships = await Promise.all(
      existingRoles?.roles?.map(async (role) => {
        const roleRelationships = await relationships?.get(role.id);
        return {
          roleId: role.id,
          relationships: roleRelationships?.relationships,
        };
      }) || [],
    );
    const rolesMap = groupBy(existingRoles?.roles || [], (r) => r.name);
    const relationshipsMap = entriesOf(rolesMap).reduce<
      Record<string, { contactId: string }[]>
    >((map, [roleName, rolesWithTheSameName]) => {
      const found = existingRelationships.find(
        (rs) => rs.roleId === rolesWithTheSameName[0].id,
      );
      const grouped = groupBy(found?.relationships || [], (rs) => rs.name);

      return {
        ...map,
        ...fromEntries(
          entriesOf(grouped).map(([relationshipName, groupedRelationships]) => [
            `${roleName}/${relationshipName}`,
            groupedRelationships,
          ]),
        ),
      };
    }, {});

    setRolesAndRelationships({
      ...rolesMap,
      ...relationshipsMap,
    });
  }, [roles, relationships]);

  return matterSubmission ? (
    <ContactReview
      loading={form.loading || fields.loading}
      matter={matters?.current}
      rolesAndRelationships={rolesAndRelationships}
      onClose={onClose}
      submission={matterSubmission}
      sections={sections.result}
      groups={groups.result}
      fields={fields.result}
      formMatterTypeRepresentatives={formMatterTypeRepresentatives}
      searchContacts={searchContacts}
      updateResponseExistingItemIds={updateResponseExistingItemIds}
      updateResponseSyncStatus={updateResponseSyncStatus}
      querySubmission={querySubmission}
      sync={sync}
      onSuccess={() => {
        setToasts([{ text: "Contacts successfully saved" }]);
        onClose();
      }}
      onFailure={() => {
        setToasts([
          {
            text: "Failed to save contacts",
            palette: "destructive",
          },
        ]);
        onClose();
      }}
      openContact={contacts?.open}
    />
  ) : null;
};
