/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUpdateSectionOrderMutationVariables = Types.Exact<{
  uri: Types.Scalars["ID"]["input"];
  formUri: Types.Scalars["ID"]["input"];
  order: Types.Scalars["String"]["input"];
}>;

export type GqlUpdateSectionOrderMutation = {
  __typename: "Mutation";
  updateSectionOrder: {
    __typename: "Operation";
    operationId: string;
    createdAt: string;
  };
};

export const UpdateSectionOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateSectionOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uri" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "formUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "order" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSectionOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "formUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "formUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "order" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "operationFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "operationFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Operation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUpdateSectionOrderMutation,
  GqlUpdateSectionOrderMutationVariables
>;
