/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlOperationFieldsFragment = {
  __typename: "Operation";
  operationId: string;
  createdAt: string;
};

export const OperationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "operationFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Operation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlOperationFieldsFragment, unknown>;
