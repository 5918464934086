import { clsx } from "clsx";
import { useSelected } from "slate-react";

import { ElementRendererProps } from "./paragraph";
import { useEditorContext } from "../hooks";

const getPlaceholderClassName = ({
  isLink,
  selected,
}: {
  isLink?: boolean;
  selected?: boolean;
}) => {
  const baseStyles =
    "inline-block mx-0 my-[0.2rem] px-2 py-1 rounded-small bg-gray-100";

  return clsx(
    baseStyles,
    isLink && "underline",
    selected && "shadow-[0_0_1px_1px_rgba(0,0,0,0.4)]",
  );
};

export const Placeholder = ({
  attributes,
  children,
  element,
}: ElementRendererProps<"placeholder">) => {
  const selected = useSelected();
  const { placeholders } = useEditorContext();
  const getDisplayText = () => {
    const display = placeholders?.data[element.name] || element.displayText;
    const displayWithCasing =
      element.casing === "lowercase" && display
        ? display.toLocaleLowerCase()
        : display;
    return displayWithCasing || element.name;
  };

  return (
    <span {...attributes} contentEditable={false} title={element.name}>
      {children}
      <span
        className={getPlaceholderClassName({
          selected,
          isLink: element.isLink,
        })}
      >
        {getDisplayText()}
      </span>
    </span>
  );
};
