import { clsx } from "clsx";

import { CardProps, CardSize } from "./type";

export const getCardClassNames = ({
  size = "readable",
  align,
  flow = "column",
  current,
}: Pick<CardProps, "size" | "align" | "flow" | "current">) => {
  const baseStyles =
    "flex w-full bg-white rounded p-7 relative border-2 border-solid";
  const shadowStyles = "shadow-[0_4px_8px_rgba(0,0,0,0.1)]";

  const sizeToStyles: Record<CardSize, string> = {
    mobile: "max-w-screen-mobile",
    tablet: "max-w-screen-tablet",
    readable: "max-w-screen-readable",
    medium: "max-w-screen-medium",
    maximum: "max-w-screen-maximum",
  };

  return clsx(
    baseStyles,
    shadowStyles,
    current ? "border-blue-360" : "border-transparent",
    sizeToStyles[size],
    flow === "row" && "flex-row",
    flow === "column" && "flex-col",
    align === "center" && "items-center",
    align === "start" && "items-start",
    align === "end" && "items-end",
  );
};
