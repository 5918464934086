import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { defaultAIFillSettings } from "@smart/bridge-types-basic";
import { EditableText } from "@smart/itops-ui-dom";

import { AIFillFileUpload } from "./ai-fill-file-upload";
import { GqlForm, UpdateAIFillSettings } from "../../types";

const AIFillSectionWrapper = styled.div`
  flex: 1;
  background: ${(props) => props.theme.scheme.grey.r20};
  overflow-y: auto;
  padding: 4rem;

  .section-title {
    max-width: ${(props) => props.theme.breakPoints.readable}px;
    margin: 0 auto 1rem;
  }
`;

type AIFillSectionProps = {
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

export const AIFillSection = ({
  aiFillSettings,
  updateAIFillSettings,
}: AIFillSectionProps) => {
  const [editingTitle, setEditingTitle] = useState(
    aiFillSettings?.sectionTitle || defaultAIFillSettings.sectionTitle,
  );

  useEffect(() => {
    setEditingTitle(
      aiFillSettings?.sectionTitle || defaultAIFillSettings.sectionTitle,
    );
  }, [aiFillSettings?.sectionTitle]);

  return (
    <AIFillSectionWrapper>
      <div className="section-title">
        <EditableText
          dataTestId="ai-fill-section-title"
          text={editingTitle}
          onChange={setEditingTitle}
          onComplete={() =>
            updateAIFillSettings({ sectionTitle: editingTitle })
          }
          fontSize="heading"
          bold
        />
      </div>
      <AIFillFileUpload
        aiFillSettings={aiFillSettings}
        updateAIFillSettings={updateAIFillSettings}
      />
    </AIFillSectionWrapper>
  );
};
