import { useEffect } from "react";

export type TimeoutProps = {
  fn: () => void;
  ms: number;
  condition?: boolean;
};

export const useTimeout = ({ fn, ms, condition }: TimeoutProps) => {
  useEffect(() => {
    if (condition === undefined || condition === true) {
      const timer = setTimeout(fn, ms);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [condition]);
};
