import {
  Empty,
  GqlStatusOverlay,
  GqlStatusOverlayProps,
} from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import { GqlForm } from "./types";
import { FormItem } from "../forms";
import { FormDisplayMode } from "../forms/types";

type GlobalFormListProps = {
  forms?: GqlForm[];
  mode: FormDisplayMode;
  select: (uri: string) => void;
  result: GqlStatusOverlayProps;
};

const GlobalFormList = ({
  forms,
  mode,
  select,
  result,
}: GlobalFormListProps) => (
  <>
    {mapOrEmpty(
      forms,
      (form) => (
        <FormItem key={form.uri} form={form} mode={mode} select={select} />
      ),
      <Empty>
        <p className="empty-text">There are no global forms in the Library</p>
      </Empty>,
    )}
    <GqlStatusOverlay {...result} />
  </>
);

export { GlobalFormList, GlobalFormListProps };
