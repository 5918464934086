import { RuleEvaluators } from "../types";

export const submission: RuleEvaluators["submission"] = (
  user,
  action,
  { value: { teamUri } },
) => {
  // User can perform any action on submissions in their own team
  if (teamUri === user.teamUri) return true;

  // Otherwise, no access
  return false;
};
