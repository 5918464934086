import { matterTypeProviderId } from "@smart/bridge-smokeball-basic";
import { FieldNS } from "@smart/bridge-types-basic";
import { toSentenceCase } from "@smart/itops-utils-basic";

import {
  GqlMatterLayout,
  GqlMatterType,
  MatterField,
  NewFieldOptions,
} from "../../types";
import { mapFieldOptions, mapFieldType } from "../../utils";

export const hideLayout = (
  matterTypes: GqlMatterType[],
  layout: GqlMatterLayout,
) => layout.providerId === matterTypeProviderId && matterTypes.length < 2;

export const hideField = (
  layout: GqlMatterLayout | undefined,
  field: MatterField,
) => {
  if (layout?.providerId === matterTypeProviderId) {
    if (field.possibleValues?.length === 1) return true;
  }

  return false;
};

const getLabelText = ({ name, displayName, details }: MatterField) => {
  const representativeName = displayName || name;
  const label = details
    ? representativeName.replace(`${details}/`, "")
    : representativeName;

  return toSentenceCase(label);
};

export const mapMatterField =
  (matterLayout: GqlMatterLayout) =>
  (matterField: MatterField): NewFieldOptions | undefined => {
    const layout = {
      id: matterLayout.id,
      name: matterLayout.name,
      providerId: matterLayout.providerId,
      parentId: matterLayout.parentId || undefined,
      parentName: matterLayout.parentName || undefined,
      parentProviderId: matterLayout.parentProviderId || undefined,
    };
    const field = {
      name: matterField.name,
      possibleValues: matterField.possibleValues || [],
      type: matterField.type,
    };
    const uri = FieldNS.generateUri();
    const label = getLabelText(matterField);
    const type = mapFieldType(matterField);

    return (
      type && {
        uri,
        type,
        label,
        layout,
        field,
        ...mapFieldOptions(matterField),
      }
    );
  };

export const isRoleProviderField = (field: MatterField) =>
  field.name.startsWith("person") || field.name.startsWith("company");
