import { loadEnv } from "@smart/bridge-env-dom";
import { track } from "@smart/bridge-metrics-dom";
import { appendFormLink } from "@smart/bridge-templates-basic";
import {
  FamilyProFormUri,
  extractFamilyProQuestionnaireType,
  familyProQuestionnaireFormUri,
  usIntakeTemplateId,
} from "@smart/bridge-types-basic";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Region } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useDeleteSubmission,
  useLoadFamilyProTemplate,
  useLoadMatterQuestionnaireStatus,
  useLoadMatterType,
  useLoadSetting,
  useLoadTeam,
  useSendMatterQuestionnaire,
  useSendSubmission,
  useUpdateResponse,
  useUpdateSubmission,
  useUploadFiles,
} from "@smart/manage-gql-client-dom";
import {
  useEvent,
  useRoleContacts,
  useSendMessage,
  useToast,
  useUser,
} from "@smart/manage-hooks-dom";

import { SubmissionActionCancel } from "./cancel";
import { SubmissionActionDelete } from "./delete";
import { SubmissionActionEdit } from "./edit";
import { SubmissionActionRemind } from "./remind";

export type SubmissionActionsProps = { onViewClose: () => void } & Record<
  "cancelling" | "deleting" | "reminding" | "editing",
  { selected?: MatterSubmission; onClose: () => void }
>;

export const SubmissionActions = ({
  onViewClose,
  cancelling,
  deleting,
  reminding,
  editing,
}: SubmissionActionsProps) => {
  const { matters } = useSmokeballApp();
  const { user } = useUser();
  const { logEvent } = useEvent();
  const { setToasts } = useToast();
  const { sendMessage } = useSendMessage();
  const roleContacts = useRoleContacts();
  const setting = useLoadSetting({});
  const team = useLoadTeam({});

  const [deleteSubmission] = useDeleteSubmission();
  const [updateSubmission] = useUpdateSubmission();
  const [sendSubmission] = useSendSubmission();
  const [updateResponse] = useUpdateResponse();
  const [uploadFiles] = useUploadFiles();

  const isUsRegion = (loadEnv("Region") as Region) === "nova";

  const questionnairePartyA = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "a",
    },
    { skip: true },
  );
  const questionnairePartyB = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "b",
    },
    { skip: true },
  );
  const familyProTemplate = useLoadFamilyProTemplate(
    { templateId: usIntakeTemplateId },
    {
      skip:
        !isUsRegion ||
        reminding.selected?.formUri !== familyProQuestionnaireFormUri,
    },
  );
  const [sendQuestionnaire] = useSendMatterQuestionnaire();

  const loadMatterType = useLoadMatterType(
    {
      matterTypeId: matters?.current?.matterTypeId || "",
    },
    { skip: !user || !matters?.current?.matterTypeId },
  );

  const matterType = loadMatterType.result || { name: "", id: "" };

  if (
    !matters?.current ||
    setting.loading ||
    loadMatterType.loading ||
    familyProTemplate.loading
  )
    return null;
  const teamValue = team.result?.values || { name: "Firm", phone: "" };

  return (
    <>
      <SubmissionActionCancel
        onConfirm={async ({ uri, formUri }) => {
          await updateSubmission({
            variables: { uri, formUri, fields: { status: "cancelled" } },
          });
          setToasts([{ text: "Form successfully closed" }]);

          track("Submission Cancelled", {
            submissionUri: uri,
            formUri,
            matterCategory: matters.current?.isLead ? "lead" : "matter",
            matterTypeId: matterType.id,
            matterTypeName: matterType.name,
          });
        }}
        onViewClose={onViewClose}
        {...cancelling}
      />
      <SubmissionActionDelete
        onConfirm={async ({ uri, formUri }) => {
          await deleteSubmission({ variables: { uri } });
          setToasts([{ text: "Form successfully deleted" }]);

          track("Submission Deleted", {
            submissionUri: uri,
            formUri,
            matterCategory: matters.current?.isLead ? "lead" : "matter",
            matterTypeId: matterType.id,
            matterTypeName: matterType.name,
          });
        }}
        onViewClose={onViewClose}
        {...deleting}
      />
      <SubmissionActionRemind
        matter={matters.current}
        matterType={matterType}
        roleContacts={roleContacts}
        team={teamValue}
        logEvent={logEvent}
        settingTemplate={
          setting.result?.defaultClientMessage?.reminder || undefined
        }
        familyProInviteTemplate={familyProTemplate.result?.inviteTemplate}
        onConfirm={async (
          { communicationMethod, form: supplementaryForm },
          { uri, formUri, recipient },
        ) => {
          if (!recipient) throw new Error(`Please select a recipient`);

          if (formUri === familyProQuestionnaireFormUri) {
            if (!matters.current) return undefined;

            if (supplementaryForm) {
              const result = await sendQuestionnaire({
                variables: {
                  matterId: matters.current?.id,
                  clientName: recipient.contactName,
                  email: recipient.email,
                  party: "a",
                  reset: true,
                  questionnaireType: extractFamilyProQuestionnaireType(
                    supplementaryForm.uri as FamilyProFormUri,
                  ),
                },
              });
              await questionnairePartyA.refetch();
              await questionnairePartyB.refetch();

              return result.data?.sendMatterQuestionnaire.link;
            }
          } else {
            await sendSubmission({
              variables: {
                uri,
                formUri,
                communicationMethod,
                recipient: {
                  contactId: recipient.contactId,
                  contactName: recipient.contactName,
                  email: recipient.email,
                },
              },
            });
          }
          track("Submission Reminded", {
            submissionUri: uri,
            formUri,
            matterCategory: matters.current?.isLead ? "lead" : "matter",
            matterTypeId: matterType.id,
            matterTypeName: matterType.name,
          });
          return undefined;
        }}
        onMessage={(
          { communicationMethod, template },
          { status, recipient },
          placeholderData,
        ) => {
          if (!recipient) throw new Error(`Please select a recipient`);
          if (communicationMethod === "internalUse") return;

          sendMessage({
            id: recipient.contactId,
            email: recipient.email,
            communicationMethod,
            placeholderData,
            subject: [
              matters.current?.reLine,
              "Please complete this online form",
            ]
              .filter(Boolean)
              .join(" - "),
            template: appendFormLink(template),
            actionText:
              status === "draft" ? undefined : "Reminder successfully sent",
          });
        }}
        {...reminding}
      />
      <SubmissionActionEdit
        {...editing}
        updateResponse={updateResponse}
        updateSubmission={updateSubmission}
        uploadFiles={uploadFiles}
      />
    </>
  );
};
