import styled from "@emotion/styled";

import { Form } from "@smart/itops-components-dom";

export const FieldForm = styled(Form)`
  --background: ${(props) => props.theme.palette.background.accent};

  display: grid;
  grid-template-areas: "label type handle delete";
  grid-template-columns: 5fr 2fr 3rem auto;
  align-items: center;

  column-gap: 1rem;
  padding: 1.6rem 1rem;
  row-gap: 1.4rem;

  > * {
    grid-column: span 4;
  }

  .label {
    margin: 0;
  }

  .label:nth-of-type(1) {
    grid-area: label;
  }

  .label:nth-of-type(2) {
    grid-area: type;
  }

  .handle {
    grid-area: handle;
    align-self: stretch;
  }

  .delete {
    grid-area: delete;

    button {
      margin: 0;
    }
  }

  .hint-group {
    display: grid;
    grid-template-areas: "hint mandatory-custom";
    grid-template-columns: auto min-content;
    column-gap: 1rem;

    .label:nth-of-type(1) {
      grid-area: hint;
    }

    .mandatory-custom {
      grid-area: mandatory-custom;
      display: flex;

      .label {
        .spacer-text {
          display: none;
        }
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15rem;
      }

      .label:last-of-type {
        margin-right: 0;
      }
    }
  }

  .layout-group {
    display: flex;

    .label {
      flex: 3;
      margin-left: 1rem;
    }

    .label:nth-of-type(1) {
      flex: 2;
      margin-left: 0;
    }
  }
`;
