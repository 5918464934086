import styled from "@emotion/styled";
import { useState } from "react";

import { Icon } from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import { Item, NotItem, Seperator } from "./components";
import { TagsProps, TagsWrapper } from "./wrapper";

const SubCollectionWrapper = styled.div<{ isTopLevel?: boolean }>`
  ${(props) => (props.isTopLevel ? "" : "padding-left: 1rem;")}
`;

type SubCollectionProps<V> = ItemTagsProps<V> & {
  collection: string;
};

const SubCollection = <V extends any>({
  collection,
  options,
  readOnly,
  isTopLevel,
  ...rest
}: SubCollectionProps<V>) => {
  const [isOpen, setIsOpen] = useState(!!readOnly);

  return (
    <SubCollectionWrapper key={collection} isTopLevel={isTopLevel}>
      <Item data-testid={`tags-${collection}-sub-collection`}>
        <input
          type="checkbox"
          name={`sub-collection-${collection}`}
          value={`sub-collection-${collection}`}
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
        />
        <Icon name="angleRightBold" className="arrow" size={8} />
        {collection}
      </Item>
      {isOpen &&
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        tagItems({
          options,
          readOnly,
          ...rest,
        })}
    </SubCollectionWrapper>
  );
};

const CheckboxWrapper = styled.div<{ readOnly?: boolean }>`
  ${(props) => (props.readOnly ? "visibility: hidden;" : "")}
`;

export type SubCollectionOption<V> = {
  isSubCollection: boolean;
  subCollectionName: string;
  options: V[];
};

export type ItemTagsProps<V> = TagsProps<V> & {
  options: (V | SubCollectionOption<V>)[];
  onToggleAll?: (select: boolean) => void;
  isTopLevel?: boolean;
  isFiltering?: boolean;
  empty: string;
};

export const tagItems = <V extends any>({
  options,
  name,
  keys,
  selected,
  onToggle,
  onToggleAll,
  isFiltering,
  isTopLevel,
  render,
  empty,
  readOnly,
  override,
}: ItemTagsProps<V>) => (
  <>
    {onToggleAll && options.length > 1 && !isFiltering && !readOnly && (
      <>
        <Item key="all">
          <input
            type="checkbox"
            name={name}
            value="all"
            checked={selected.length >= options.length}
            onChange={() => onToggleAll(!(selected.length >= options.length))}
          />
          <div className="checkmark bordered" />
          Select All
        </Item>
        <Seperator />
      </>
    )}
    {mapOrEmpty(
      options,
      (o) => {
        if (
          !!o &&
          typeof o === "object" &&
          (o as SubCollectionOption<V>).isSubCollection
        ) {
          const option = o as SubCollectionOption<V>;
          return (
            <SubCollection
              key={option.subCollectionName}
              collection={option.subCollectionName}
              options={option.options}
              name={name}
              keys={keys}
              selected={selected}
              onToggle={onToggle}
              isFiltering={isFiltering}
              isTopLevel={isTopLevel}
              render={render}
              empty={empty}
              readOnly={readOnly}
              override={override}
            />
          );
        }

        const option = o as V;
        const overrides = override?.(option);

        return (
          <Item
            key={keys(option)}
            data-testid={`tags-${name}-option`}
            aria-disabled={overrides?.disabled || !!readOnly}
            disabled={overrides?.disabled}
          >
            <CheckboxWrapper readOnly={readOnly}>
              <input
                type="checkbox"
                name={name}
                data-testid={`${name}-${keys(option)}`}
                value={keys(option)}
                checked={
                  overrides?.selected ??
                  selected.some((s) => keys(s) === keys(option))
                }
                onChange={() => onToggle(option)}
                disabled={overrides?.disabled}
              />
              <div className="checkmark bordered" />
            </CheckboxWrapper>
            {render(option)}
          </Item>
        );
      },
      <NotItem>{empty}</NotItem>,
    )}
  </>
);

export const ItemTags = <V extends any>({
  name,
  options,
  onToggle,
  onToggleAll,
  keys,
  override,
  render,
  selected,
  empty,
  isFiltering,
  readOnly,
}: Omit<ItemTagsProps<V>, "isTopLevel">) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <TagsWrapper
      name={name}
      showOptions={showOptions}
      setShowOptions={setShowOptions}
      keys={keys}
      render={render}
      onToggle={onToggle}
      selected={selected}
      readOnly={readOnly}
    >
      {tagItems({
        name,
        options,
        keys,
        override,
        selected,
        onToggle,
        onToggleAll,
        render,
        isFiltering,
        readOnly,
        empty,
      })}
    </TagsWrapper>
  );
};
