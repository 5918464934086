import {
  Empty,
  GqlStatusOverlay,
  GqlStatusOverlayProps,
  IconButton,
} from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import { FormItem } from "./item";
import { GqlForm } from "./types";

type FormListProps = {
  forms?: GqlForm[];
  create: () => void;
  select: (uri: string) => void;
  result: GqlStatusOverlayProps;
};

const FormList = ({ forms, create, select, result }: FormListProps) => (
  <>
    {mapOrEmpty(
      forms,
      (form) => (
        <FormItem key={form.uri} form={form} mode="edit" select={select} />
      ),
      <Empty>
        <p className="empty-text">
          You don&apos;t have any SmartForms, would you like to create one?
        </p>
        <IconButton
          className="empty-button"
          name="add"
          text="Create Form"
          onClick={create}
        />
      </Empty>,
    )}
    <GqlStatusOverlay {...result} />
  </>
);

export { FormList, FormListProps };
