import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Placeholder, PlaceholderName } from "@smart/bridge-templates-basic";
import { Input } from "@smart/itops-components-dom";
import { insertPlaceholder, useEditorContext } from "@smart/itops-editor-dom";
import { PlaceholderData } from "@smart/itops-serialisation-basic";
import {
  Field,
  FieldGroup,
  ItemSelect,
  Modal,
} from "@smart/itops-smokeball-components-dom";
import { RadioButtons } from "@smart/itops-ui-dom";

const Radios = styled(RadioButtons)`
  input {
    display: none;
  }
`;

const customFieldSchema = z
  .object({
    name: z.string(),
    type: z.enum(["text", "link", "element"]),
    displayText: z.string(),
    requiredDisplayText: z.boolean().optional(),
    casing: z.enum(["titleCase", "lowercase"]),
  })
  .superRefine((data, ctx) => {
    if (data.requiredDisplayText && data.displayText.length === 0)
      ctx.addIssue({
        code: "custom",
        path: ["displayText"],
        message: "Please enter link text",
      });
  });

const PlaceholderModal = styled(Modal)`
  .modal {
    height: 25rem;
    .content {
      overflow: visible;
      min-height: 10rem;
    }
  }
`;

export type AddPlaceholderModalProps = {
  addPlaceholderModal: [boolean, (v: boolean) => void];
  customFields: Placeholder[];
  placeholderData?: Partial<PlaceholderData>;
};

export const AddPlaceholderModal = ({
  addPlaceholderModal: [show, showModal],
  customFields,
  placeholderData,
}: AddPlaceholderModalProps) => {
  const { editor } = useEditorContext();

  const insert = useCallback(
    (name: string, displayText?: string, casing?: "lowercase") => {
      const placeholderDataValue = placeholderData?.[name as PlaceholderName];
      insertPlaceholder({
        editor,
        name,
        displayText,
        replacingValue:
          typeof placeholderDataValue === "string"
            ? placeholderDataValue
            : undefined,
        casing,
      });
    },
    [placeholderData],
  );

  const form = useForm({
    defaultValues: {
      name: "",
      displayText: "",
      type: "text",
      requiredDisplayText: false,
      casing: "titleCase",
    },
    resolver: zodResolver(customFieldSchema),
  });
  const { handleSubmit, setValue, register, control } = form;

  const [selectedField, setSelectedField] = useState(customFields[0]);

  const setFormValues = () => {
    setValue("type", selectedField.type || "text");
    setValue("requiredDisplayText", selectedField.requiredDisplayText || false);
    setValue("casing", "titleCase");

    if (selectedField?.requiredDisplayText) {
      setValue("displayText", selectedField.defaultValue || "");
    } else {
      setValue("displayText", selectedField.label);
    }
  };

  const onSubmit = handleSubmit(({ displayText, casing }) => {
    insert(
      selectedField.name,
      displayText,
      casing === "lowercase" ? "lowercase" : undefined,
    );
    setFormValues();
    showModal(false);
  });

  useEffect(() => {
    setFormValues();
  }, [selectedField]);

  return (
    <FormProvider {...form}>
      <PlaceholderModal
        size="mobile"
        title=" "
        subtitle="Add custom field"
        open={[show, () => showModal(false)]}
        buttons={[
          {
            text: "Cancel",
            onClick: () => {
              setFormValues();
              showModal(false);
            },
          },
          {
            disabled: !selectedField?.type,
            text: "Add field",
            palette: "primary",
            onClick: onSubmit,
          },
        ]}
      >
        <FieldGroup>
          <Field name="name" text="Type">
            <ItemSelect
              name="name"
              collections={[{ key: "fields", options: customFields }]}
              empty="No custom fields available"
              keys={(f) => f.name}
              render={(f) => f.label}
              selected={selectedField}
              onSelect={(f) => {
                if (f) setSelectedField(f);
              }}
            />
          </Field>
        </FieldGroup>
        {selectedField?.requiredDisplayText && (
          <FieldGroup>
            <Field name="displayText" text="Link Text">
              <Input
                {...register("displayText")}
                id="displayText"
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                  }
                }}
                autoFocus
              />
            </Field>
          </FieldGroup>
        )}
        {selectedField.lowerable && (
          <Controller
            control={control}
            name="casing"
            render={({ field, fieldState }) => (
              <FieldGroup>
                <Field name="casing" text="" transparent>
                  <Radios
                    id={field.name}
                    error={!!fieldState.error?.message}
                    direction="row"
                    align="left"
                    size="base"
                    options={[
                      { label: "Title Case", value: "titleCase" },
                      { label: "Lowercase", value: "lowercase" },
                    ]}
                    {...field}
                  />
                </Field>
              </FieldGroup>
            )}
          />
        )}
      </PlaceholderModal>
    </FormProvider>
  );
};
