import { ApolloError } from "@apollo/client";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  IconButton,
  InlineForm,
  Input,
  Label,
  Spinner,
  Modal,
} from "@smart/itops-components-dom";
import { useLazyLoadTeams } from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

type SwitchFirmActionProps = {
  loading: boolean;
  error: ApolloError | undefined;
  data: Gql.TeamsQuery | undefined;
  close: () => void;
};

const SwitchFirmAction = ({
  error,
  loading,
  data,
  close,
}: SwitchFirmActionProps) => {
  const { setActingAsFirm } = useManageFormsContext();

  if (loading) return <Spinner size={4} />;
  if (error) {
    console.error(error.message);
    return <p>Something went wrong!</p>;
  }
  if (!data) return null;

  if (!data.teams) return <p>Firm Not Found</p>;

  return (
    <>
      <p>Switch to &quot;{data.teams.values.name}&quot;?</p>
      <p>
        <Button
          type="button"
          onClick={() => {
            setActingAsFirm({
              ...data.teams!.values,
              email: data.teams!.values.email ?? undefined,
              picture: data.teams!.values.picture ?? undefined,
            });
            close();
          }}
        >
          Switch
        </Button>
      </p>
    </>
  );
};

const SwitchFirm = () => {
  const { isActing } = useManageFormsContext();
  const [open, setOpen] = useState(false);
  const [getTeam, { data, error, loading }] = useLazyLoadTeams();
  const { register, handleSubmit } = useForm({
    defaultValues: { teamId: "" },
  });

  return (
    <>
      {!isActing ? (
        <IconButton
          name="spanner"
          text="Act as firm"
          onClick={() => setOpen(true)}
        />
      ) : null}
      {open && (
        <Modal
          position="fixed"
          marginBottom={10}
          heading="Act as Firm"
          showCloseButton
          onClose={() => setOpen(false)}
        >
          <InlineForm
            onSubmit={handleSubmit(({ teamId }) =>
              getTeam({ variables: { teamId } }),
            )}
          >
            <Label name="teamId" text="Team Id" loading={loading} mandatory>
              <Input {...register("teamId")} autoFocus />
            </Label>
            <Button type="submit">Search</Button>
          </InlineForm>
          <SwitchFirmAction
            loading={loading}
            error={error}
            data={data}
            close={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export { SwitchFirm };
