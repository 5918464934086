import { twMerge } from "tailwind-merge";

import { getCardClassNames } from "./class-names";
import { CardProps } from "./type";
import { ProgressBar } from "../progress-bar";

export const Card = ({
  children,
  loading,
  size,
  align,
  flow,
  current,
}: CardProps) => (
  <div
    className={twMerge(
      getCardClassNames({
        size,
        align,
        current,
        flow,
      }),
    )}
  >
    {children}
    {loading && <ProgressBar overlay className="h-[0.2rem]" />}
  </div>
);
