import styled from "@emotion/styled";
import { useState } from "react";

import { customFields, PlaceholderName } from "@smart/bridge-templates-basic";
import { Editor as EditorComponent } from "@smart/itops-editor-dom";
import { PlaceholderData } from "@smart/itops-serialisation-basic";

import { AddPlaceholderModal } from "./add-placeholder";
import { HyperlinkModal } from "./hyperlink";

const Editor = styled(EditorComponent)`
  min-height: 10rem;
  background-color: ${(props) => props.theme.palette.background.base};
  border: 1px solid ${(props) => props.theme.palette.disabled.base};

  &:focus-within {
    border-color: ${(props) => props.theme.palette.primary.base};
  }

  flex: 1;
  overflow: auto;
`;

type MessageEditorProps = {
  initialValue?: string;
  onChange?: (value: string) => void;
  placeholderData?: Partial<PlaceholderData>;
  disabled?: boolean;
  className?: string;
  excludingFieldNames?: PlaceholderName[];
  autoFocus?: boolean;
};

const MessageEditor = ({
  initialValue,
  onChange,
  placeholderData,
  disabled,
  autoFocus,
  className,
  excludingFieldNames,
}: MessageEditorProps) => {
  const [showHyperlinkModal, setShowHyperlinkModal] = useState(false);
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);

  const filteredCustomFields = excludingFieldNames
    ? customFields.filter(
        (cf) => !excludingFieldNames.includes(cf.name as PlaceholderName),
      )
    : customFields;

  return (
    <Editor
      className={className}
      initialValue={initialValue}
      onChange={onChange}
      placeholderData={placeholderData}
      dataTestId="message-editor"
      toolbarOptions={{
        toolbarVisiblity: "fixed",
        hiddenButtons: ["link"],
      }}
      disabled={disabled}
      autoFocus={autoFocus}
      extendedActions={[
        {
          name: "hyperlink",
          iconName: "link",
          onClick: () => setShowHyperlinkModal(true),
        },
        {
          name: "custom field",
          iconName: "insertField",
          hotkey: "mod+shift+c",
          tooltipTexts: ["Custom Field", "Ctrl+Shift+C"],
          onClick: () => setShowCustomFieldModal(true),
        },
      ]}
    >
      <AddPlaceholderModal
        addPlaceholderModal={[showCustomFieldModal, setShowCustomFieldModal]}
        customFields={filteredCustomFields}
        placeholderData={placeholderData}
      />
      <HyperlinkModal
        hyperlinkModal={[showHyperlinkModal, setShowHyperlinkModal]}
      />
    </Editor>
  );
};

export { MessageEditor, MessageEditorProps };
