import styled from "@emotion/styled";
import { Draggable } from "@hello-pangea/dnd";

import { ButtonRow, Icon, IconButton } from "@smart/itops-components-dom";

// If the handles are not in the DOM, the library will log warnings
const Handle = styled.div<{ disabled: boolean }>`
  display: ${(props) => (props.disabled ? "none" : "block")};
`;

type PermittedOperations = {
  add?: boolean;
  remove?: boolean;
  changeLabel?: boolean;
  changeValue?: boolean;
  changeOrder?: boolean;
};

type DraggableOptionItemProps = {
  id: string;
  index: number;
  onRemove?: (index: number) => void;
  render: (index: number) => JSX.Element;
  permittedOperations?: PermittedOperations;
};

const DraggableOptionItem = ({
  id,
  index,
  onRemove,
  render,
  permittedOperations = { remove: true },
}: DraggableOptionItemProps) => (
  <Draggable draggableId={id} index={index} key={id}>
    {(provided) => (
      <div className="row" ref={provided.innerRef} {...provided.draggableProps}>
        {render(index)}
        <ButtonRow>
          <Handle
            disabled={!permittedOperations.changeOrder}
            className="handle"
            {...provided.dragHandleProps}
            aria-label="Draggable option item handle"
          >
            <Icon name="handle" />
          </Handle>
          {permittedOperations.remove && (
            <IconButton
              name="cancel"
              palette="danger"
              onClick={(e) => {
                e.preventDefault();
                onRemove?.(index);
              }}
              size={10}
            />
          )}
        </ButtonRow>
      </div>
    )}
  </Draggable>
);

export { DraggableOptionItem, DraggableOptionItemProps, PermittedOperations };
