import { NormalizedHtmlExtension } from "micromark-util-types";

import {
  CASING_KEY,
  DISPLAY_TEXT_KEY,
  IS_LINK_KEY,
  PLACEHOLDER_NAME_KEY,
} from "./constants";
import { PlaceholderData } from "../elements";

export const buildPlaceholderHtmlExtension = (
  data: PlaceholderData,
): NormalizedHtmlExtension =>
  /**
   * 1. On entering placeholder token, reset all keys
   * 2. On exiting placeholderName token, set placeholder name
   * 3. On exiting displayText token, set display text
   * 4. On exiting placeholder token, replace the placeholder with actual value
   */
  ({
    enter: {
      placeholder() {
        this.setData(PLACEHOLDER_NAME_KEY, undefined);
        this.setData(DISPLAY_TEXT_KEY, undefined);
        this.setData(IS_LINK_KEY, false);
        this.setData(CASING_KEY, undefined);
      },
      placeholderName() {
        this.buffer();
      },
      isLinkMarker() {},
      casing() {
        this.buffer();
      },
      displayText() {
        this.buffer();
      },
    },
    exit: {
      placeholder() {
        const placeholderName = this.getData(PLACEHOLDER_NAME_KEY);
        const isLink = this.getData(IS_LINK_KEY);
        const displayText = this.getData(DISPLAY_TEXT_KEY);
        const casing = this.getData(CASING_KEY);

        if (!placeholderName) return;

        const value = data[placeholderName];

        if (!value) return;

        if (isLink) {
          this.tag(`<a href="${value}" target="_blank">${displayText}</a>`);
        } else if (typeof value === "string") {
          this.raw(
            this.encode(
              casing === "lowercase" ? value.toLocaleLowerCase() : value,
            ),
          );
        }
      },
      placeholderName() {
        this.setData(PLACEHOLDER_NAME_KEY, this.resume());
      },
      isLinkMarker() {
        this.setData(IS_LINK_KEY, true);
      },
      casing() {
        this.setData(
          CASING_KEY,
          this.resume() === "_" ? "lowercase" : undefined,
        );
      },
      displayText() {
        this.setData(DISPLAY_TEXT_KEY, this.resume());
      },
    },
  });
