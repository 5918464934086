import styled from "@emotion/styled";
import { useState } from "react";
import { Node } from "slate";
import { useFocused, useSelected, useSlateStatic } from "slate-react";

import { Icon } from "@smart/itops-icons-dom";
import { EditorElement } from "@smart/itops-serialisation-basic";

import { EditLink } from "./edit-link";
import { editLink, removeLink } from "./link-actions";
import { EditorRenderElementProps } from "../../types";

const EditorLinkWrapper = styled.span`
  position: relative;

  a {
    color: ${(props) => props.theme.palette.primary.base};
  }
`;

const LinkToolbar = styled.span`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 2rem;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.base};
  padding: 0.5rem 1rem;
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  gap: 1rem;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-top: none;
  border-bottom: none;
`;

const ToolbarActionWrapper = styled.span`
  cursor: pointer;
`;

const EditLinkWrapper = styled.span`
  display: block;
  position: absolute;
  left: 0;
  z-index: 1;
`;

export const EditorLink = ({
  attributes,
  children,
  element,
}: EditorRenderElementProps<EditorElement<"link">>) => {
  const [isEditing, setIsEditing] = useState(false);
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const remove = () => removeLink(editor);
  const edit = () => setIsEditing(true);
  const close = () => setIsEditing(false);

  return (
    <EditorLinkWrapper>
      <a {...attributes} href={element.url}>
        {children}
      </a>
      {!isEditing && selected && focused && (
        <LinkToolbar contentEditable={false}>
          <ToolbarActionWrapper onClick={edit}>
            <Icon name="pencil" size={12} />
          </ToolbarActionWrapper>
          <Link href={element.url} rel="noreferrer" target="_blank">
            <Icon name="popOut" size={12} />
            {element.url}
          </Link>
          <ToolbarActionWrapper onClick={remove}>
            <Icon name="brokenLink" size={12} />
          </ToolbarActionWrapper>
        </LinkToolbar>
      )}
      {isEditing && (
        <EditLinkWrapper contentEditable={false}>
          <EditLink
            displayText={Node.string(element)}
            linkUrl={element.url}
            close={close}
            save={(text, url) => editLink(editor, element, text, url)}
          />
        </EditLinkWrapper>
      )}
    </EditorLinkWrapper>
  );
};
