import { Editor as SlateEditor } from "slate";

import { Mark } from "@smart/itops-serialisation-basic";

import { Editor } from "./types";

export const isMarkActive = (editor: Editor, mark: Mark) => {
  const marks = SlateEditor.marks(editor);
  return marks ? marks[mark] === true : false;
};

export const toggleMark = (editor: Editor, mark: Mark) => {
  const isActive = isMarkActive(editor, mark);

  if (isActive) {
    SlateEditor.removeMark(editor, mark);
  } else {
    SlateEditor.addMark(editor, mark, true);
  }
};
