import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Select } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { isNullOrUndefined, jsonParseOrReturn } from "@smart/itops-utils-basic";

import {
  ControlledLinkValueProps,
  DropdownCheckbox,
} from "./dropdown-checkbox";
import { SectionSchema } from "../shared-schemas";

const convertToSingle = (
  value: string | null | undefined,
  options: ControlledLinkValueProps["options"],
): string | undefined => {
  const defaultValue = options[0]?.value;
  if (isNullOrUndefined(value)) return defaultValue;
  const fromJson = jsonParseOrReturn(value);
  const convertedValue = Array.isArray(fromJson) ? fromJson[0] : value;

  /**
   * Since the link value field name is index based, when adding/remove links,
   * the previous value may be passed in.
   */
  return options.find((o) => o.value === convertedValue)
    ? convertedValue
    : defaultValue;
};

const ControlledSelect = ({
  onChange,
  options,
  value,
  disabled,
}: ControlledLinkValueProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(() =>
    convertToSingle(value, options),
  );

  useEffect(() => {
    if (selectedValue) {
      onChange(selectedValue);
    }
  }, []);

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const eventValue = event.target.value;
    setSelectedValue(eventValue);
    onChange(eventValue);
  };

  return (
    <Select value={selectedValue} onChange={onSelectChange} disabled={disabled}>
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </Select>
  );
};

type SwitchableValueSelectProps = ControlledLinkValueProps & {
  conditionFieldName: `links.${number}.condition`;
};

export const SwitchableValueSelect = ({
  onChange,
  options,
  value,
  conditionFieldName,
  disabled,
}: SwitchableValueSelectProps) => {
  const { watch } = useFormContext<UpdateHookValues<SectionSchema>>();
  const condition = watch(conditionFieldName);

  return condition !== "in" ? (
    <ControlledSelect
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <DropdownCheckbox
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
