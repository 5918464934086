import { useMutation } from "@apollo/client";

import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useLoadMatterQuestionnaireStatus = useQueryFactory(
  queryDocuments.matterQuestionnaireStatus,
  { map: ({ matterQuestionnaireStatus }) => matterQuestionnaireStatus },
);

export const useLoadFamilyProAuthorizationState = useQueryFactory(
  queryDocuments.familyProAuthorizationState,
  { map: ({ familyProAuthorizationState }) => familyProAuthorizationState },
);

export const useSendMatterQuestionnaire = () =>
  useMutation(mutationDocuments.sendMatterQuestionnaire, {
    update: (cache, { data }, { variables }) => {
      if (data?.sendMatterQuestionnaire.link && variables) {
        cache.updateQuery(
          {
            query: queryDocuments.matterQuestionnaireStatus,
            variables: {
              matterId: variables.matterId,
              party: variables.party,
            },
          },
          (ex) => {
            const exists = ex?.matterQuestionnaireStatus;
            if (exists)
              return {
                ...ex,
                matterQuestionnaireStatus: {
                  ...exists,
                  link: data.sendMatterQuestionnaire.link,
                  status: "sent" as const,
                  questionnaireType: variables.questionnaireType,
                },
              };
            return {
              matterQuestionnaireStatus: {
                id: `${variables.matterId}-party-${variables.party}`,
                link: data.sendMatterQuestionnaire.link,
                embedUrl: data.sendMatterQuestionnaire.embedUrl,
                status: "sent" as const,
                questionnaireType: variables.questionnaireType,
                __typename: "MatterQuestionnaireStatus" as const,
              },
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useLoadFamilyProTemplate = useQueryFactory(
  queryDocuments.familyProTemplate,
  { map: ({ familyProTemplate }) => familyProTemplate },
);
