import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FieldNS, FormNS, GroupNS, SectionNS } from "@smart/bridge-types-basic";
import {
  Button,
  ButtonRow,
  FileField,
  Form,
  Label,
  Loading,
} from "@smart/itops-components-dom";
import { jsonParse } from "@smart/itops-utils-basic";

import { guardImportedForm, ImportedForm } from "./schema";

const resolver = zodResolver(z.object({ import: z.instanceof(File) }));

const uniqueMatches = (uri: RegExp, contents: string) => [
  ...new Set([...contents.matchAll(new RegExp(uri, "g"))].flat()),
];

export type ImportUploadProps = {
  importedForm: ImportedForm | undefined;
  setImportedForm: (i: ImportedForm | undefined) => void;
};

export const ImportUpload = ({
  importedForm,
  setImportedForm,
}: ImportUploadProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm<{
    import: File;
  }>({ resolver });

  const onSubmit = handleSubmit(async (values) => {
    try {
      const contents = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(values.import);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () => reject(reader.error);
      });
      const formUris = uniqueMatches(FormNS.uriRegex, contents);
      const sectionUris = uniqueMatches(SectionNS.uriRegex, contents);
      const fieldUris = uniqueMatches(FieldNS.uriRegex, contents);
      const groupUris = uniqueMatches(GroupNS.uriRegex, contents);

      let newContents = contents;
      for (const formUri of formUris) {
        newContents = newContents.replace(
          new RegExp(formUri, "g"),
          FormNS.generateUri(),
        );
      }
      for (const sectionUri of sectionUris) {
        newContents = newContents.replace(
          new RegExp(sectionUri, "g"),
          SectionNS.generateUri(),
        );
      }

      for (const groupUri of groupUris) {
        newContents = newContents.replace(
          new RegExp(groupUri, "g"),
          GroupNS.generateUri(),
        );
      }

      for (const fieldUri of fieldUris) {
        newContents = newContents.replace(
          new RegExp(fieldUri, "g"),
          FieldNS.generateUri(),
        );
      }

      const parsed = jsonParse(newContents, "import parse");
      const guarded = await guardImportedForm(parsed, "import");

      setImportedForm(guarded);
    } catch (e) {
      setError("import", { message: "Invalid Import File" });
    }
  });

  const onReset = useCallback(() => {
    setImportedForm(undefined);
    reset();
  }, []);

  return (
    <Form onSubmit={onSubmit} onReset={onReset}>
      {isSubmitting && <Loading />}
      {importedForm ? (
        <ButtonRow>
          <Button disabled={isSubmitting} type="reset">
            Start Over
          </Button>
        </ButtonRow>
      ) : (
        <>
          <Label name="import" text="Exported Form">
            <FileField
              control={control}
              name="import"
              placeholder="Upload your exported form"
              disabled={isSubmitting}
            />
          </Label>
          <ButtonRow>
            <Button disabled={isSubmitting} type="submit">
              Import Form
            </Button>
          </ButtonRow>
        </>
      )}
    </Form>
  );
};
