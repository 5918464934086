import { PlaceholderName } from "@smart/bridge-templates-basic";
import { Spinner } from "@smart/itops-components-dom";
import { useDebounce } from "@smart/itops-hooks-dom";

import { MessageEditor } from "../../shared";

type MessageSectionProps = {
  description: string;
  loading?: boolean;
  value?: string | null;
  fallback: string;
  excludingFieldNames?: PlaceholderName[];
  disabled?: boolean;
  save: (value?: string) => Promise<void>;
};

const MessageSection = ({
  description,
  loading,
  value,
  fallback,
  disabled,
  excludingFieldNames,
  save,
}: MessageSectionProps) => {
  const onChange = useDebounce((updated) => {
    save(updated).catch(console.error);
  }, 500);

  return (
    <>
      <p className="description">{description}</p>
      {loading ? (
        <Spinner size={2} />
      ) : (
        <MessageEditor
          initialValue={value || fallback}
          onChange={onChange}
          excludingFieldNames={excludingFieldNames}
          disabled={disabled}
        />
      )}
    </>
  );
};

export { MessageSection };
