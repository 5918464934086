import styled from "@emotion/styled";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Modal } from "@smart/itops-smokeball-components-dom";
import { FailureMessage } from "@smart/itops-ui-dom";

import { AreaSelect } from "./area-select";
import { AreaInfo } from "../../types";

const SelectAreasWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .heading {
    padding: 0 1.5rem;

    h2 {
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: 600;
      margin: 0;
    }
  }
`;

type SelectAreasProps = {
  heading: string;
  description: string;
  name: string;
  emptyText: string;
  availableAreas: AreaInfo[];
  selectedAreas: AreaInfo[];
  setAreas: Dispatch<SetStateAction<AreaInfo[]>>;
};

const SelectAreas = ({
  availableAreas,
  selectedAreas,
  setAreas,
  name,
  emptyText,
  heading,
  description,
}: SelectAreasProps) => {
  const onToggle = (area: AreaInfo) =>
    setAreas((current) => {
      if (current.includes(area)) {
        return current.filter((c) => area !== c);
      }

      return [...current, area];
    });

  const onToggleAll = (select: boolean) => {
    if (select) {
      setAreas([...availableAreas]);
    } else {
      setAreas([]);
    }
  };

  return (
    <SelectAreasWrapper>
      <div className="heading">
        <h2>{heading}</h2>
        <p>{description}</p>
      </div>
      <AreaSelect
        name={name}
        areas={availableAreas}
        empty={emptyText}
        selected={selectedAreas}
        onToggle={onToggle}
        onToggleAll={onToggleAll}
      />
    </SelectAreasWrapper>
  );
};

type EditAreasModalProps = {
  editAreasModal: [boolean, (v: boolean) => void];
  storedAreas: AreaInfo[] | undefined | null;
  availableAreas: AreaInfo[] | undefined | null;
  save: (areas: string[]) => Promise<void>;
  loading?: boolean;
  error?: boolean;
  requiredText: string;
  heading: string;
  description: string;
  name: string;
  emptyText: string;
  closeOptions?: {
    closeButton?: boolean;
    escapeKey?: boolean;
    clickOutside?: boolean;
  };
  buttonOptions?: {
    saveLabel?: string;
    cancelLabel?: string;
    onCancelled?: () => void;
  };
  disableSaveIfNoChange?: boolean;
};

const EditAreasModal = ({
  editAreasModal,
  storedAreas,
  availableAreas,
  save,
  loading,
  error,
  requiredText,
  closeOptions,
  buttonOptions,
  disableSaveIfNoChange,
  ...rest
}: EditAreasModalProps) => {
  const [required, setRequired] = useState(false);
  const [selectedAreas, setAreas] = useState<AreaInfo[]>([]);

  const onSave = async () => {
    if (!selectedAreas.length) {
      setRequired(true);
      return;
    }

    setRequired(false);
    await save(selectedAreas.map((area) => area.name));
    editAreasModal[1](false);
  };

  useEffect(() => setRequired(false), [selectedAreas]);

  useEffect(() => {
    if (editAreasModal[0]) {
      setAreas(storedAreas || []);
    } else {
      setRequired(false);
    }
  }, [storedAreas, editAreasModal[0]]);

  let displayError;
  if (required)
    displayError = <FailureMessage action="" textOverride={requiredText} />;
  if (error) displayError = <FailureMessage action="save information" />;

  return (
    <Modal
      loading={loading}
      title=" "
      open={editAreasModal}
      isForm={false}
      buttons={[
        {
          text: buttonOptions?.cancelLabel || "Cancel",
          onClick: () => {
            editAreasModal[1](false);

            if (buttonOptions?.onCancelled) buttonOptions.onCancelled();
          },
        },
        {
          text: buttonOptions?.saveLabel || "Save",
          palette: "primary",
          onClick: onSave,
          disabled:
            disableSaveIfNoChange &&
            (storedAreas || []).length === selectedAreas.length &&
            (storedAreas || []).every((item) => selectedAreas.includes(item)),
        },
      ]}
      error={displayError}
      closeOptions={closeOptions}
    >
      <SelectAreas
        availableAreas={[...(availableAreas || [])].sort()}
        selectedAreas={selectedAreas}
        setAreas={setAreas}
        {...rest}
      />
    </Modal>
  );
};

export { EditAreasModal };
