import { DraggableItemProps } from "@smart/itops-components-dom";

import { FieldCard } from "./field-card";
import { Separator } from "./separator";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
  QuestionEditMode,
} from "../types";

type FieldProps = DraggableItemProps<GqlFieldValues> & {
  loading?: boolean;
  shrinking?: boolean;
  isDragging?: boolean;
  isEditing?: boolean;
  mode?: QuestionEditMode;
  item: GqlFieldValues;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  conditionActions: ConditionActions;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onFieldTypeChange: OnFieldTypeChange;
  isDropAnimating: boolean;
  isValidSection: boolean;
  ignoreDraggableStyle?: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

export const Field = ({
  item: field,
  index,
  loading,
  shrinking,
  isEditing,
  isDragging,
  draggableProvided,
  mode,
  onUpdateField,
  onDeleteField,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  conditionActions,
  groupLayout,
  groupMatterField,
  onFieldTypeChange,
  isDropAnimating,
  isValidSection,
  ignoreDraggableStyle,
  excludingFieldTemplates,
}: FieldProps) => {
  const getDraggableStyle = () => {
    if (ignoreDraggableStyle) return undefined;

    return isValidSection && isDropAnimating
      ? {
          ...draggableProvided?.draggableProps.style,
          transitionDuration: "0.000001ms",
        }
      : draggableProvided?.draggableProps.style;
  };

  return (
    <div
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      style={getDraggableStyle()}
    >
      <FieldCard
        field={field}
        loading={loading}
        onUpdateField={onUpdateField}
        onDeleteField={onDeleteField}
        dragHandleProps={draggableProvided?.dragHandleProps}
        isShrinking={shrinking}
        isDragging={isDragging}
        editing={isEditing}
        matterLayouts={matterLayouts}
        matterTypes={matterTypes}
        loadMatterFields={loadMatterFields}
        conditionActions={conditionActions}
        groupLayout={groupLayout}
        groupMatterField={groupMatterField}
        onFieldTypeChange={onFieldTypeChange}
        excludingFieldTemplates={excludingFieldTemplates}
      />
      <Separator
        index={index + 1}
        isFieldReordering={mode === "reordering"}
        isShrinking={shrinking}
        groupUri={field.groupUri}
      />
    </div>
  );
};
