import styled from "@emotion/styled";
import { useSlate } from "slate-react";

import { IconName, Icon } from "@smart/itops-icons-dom";
import { Mark } from "@smart/itops-serialisation-basic";

import { isMarkActive, toggleMark } from "../utils";

const MarkButtonWrapper = styled.span<{ active?: boolean }>`
  cursor: pointer;
  display: block;
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  padding: 0.5rem;
  margin: 0 0.5rem;
`;

type MarkButtonProps = {
  name: IconName;
  mark: Mark;
};

const MarkButton = ({ name, mark }: MarkButtonProps) => {
  const editor = useSlate();

  return (
    <MarkButtonWrapper
      active={isMarkActive(editor, mark)}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, mark);
      }}
    >
      <Icon name={name} size={12} />
    </MarkButtonWrapper>
  );
};

export { MarkButtonProps, MarkButton };
