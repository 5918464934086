import { rankBetween } from "./rank";

export type Reorderable = { uri: string; values: { order: string } };

export type ReorderItemProps<I extends Reorderable> = {
  items: I[];
  index: number;
  item: I;
};

export type ReorderItemsProps<I extends Reorderable> = {
  items: I[];
  index: number;
  reorderedItems: I[];
};

export const reorderItem = <I extends Reorderable>({
  items,
  index,
  item: { uri },
}: ReorderItemProps<I>) => {
  const after = items[index - 1];
  const before = items[index];

  if (uri !== after?.uri && uri !== before?.uri) {
    const order = rankBetween({
      after: after?.values.order,
      before: before?.values.order,
    });

    return order;
  }

  return undefined;
};
