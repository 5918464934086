import { useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { TTeam } from "@smart/bridge-resources-basic";
import { defaultTemplate } from "@smart/bridge-templates-basic";
import {
  FieldNS,
  FormNS,
  GroupNS,
  SectionNS,
  globalTeamUri,
} from "@smart/bridge-types-basic";
import { Namespace } from "@smart/itops-types-basic";
import { removeEmptyValues, removeKeys } from "@smart/itops-utils-basic";
import {
  useAddFormMatterType,
  useLoadFields,
  useLoadForm,
  useLoadGroups,
  useLoadGroupsWithFields,
  useLoadSections,
  useLoadTeam,
  useToggleSlug,
  useUpdateField,
  useUpdateForm,
  useUpdateGroupWithFields,
  useUpdateSection,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import {
  GqlFormCategory,
  GqlMatterType,
} from "@smart/manage-gql-operations-dom/src/generated/types";

import { ManageFormsContext } from "./forms-context";
import { useUser } from "./user";

export const useManageForms = (): { context: ManageFormsContext } => {
  const { actAsFirm } = useFeatureFlags();
  const { permission } = useUser();
  const [actingFirmId, setActingFirmId] = useState<string>("");
  const [actingTeam, setActingTeam] = useState<TTeam | undefined>(undefined);

  return {
    context: {
      canActAsFirm:
        !!actAsFirm &&
        !!permission?.can("update", {
          type: "form",
          value: { uri: "", teamUri: globalTeamUri, active: true },
        }),
      actingFirmId,
      actingTeam,
      setActingAsFirm: (team: TTeam | undefined) => {
        setActingTeam(team);
        setActingFirmId(team?.uri || "");
      },
      clearActingFirm: () => {
        setActingTeam(undefined);
        setActingFirmId("");
      },
      isActing: actingTeam !== undefined,
    },
  };
};

export const useDuplicateGqlForm = () => {
  const [updateForm] = useUpdateForm();
  const [updateSlug] = useToggleSlug();

  const duplicateForm = async ({
    formToDuplicate,
    newFormTitle,
    newFormUri,
    newTeamUri,
  }: {
    formToDuplicate: ReturnType<typeof useLoadForm>["result"];
    newFormTitle: string;
    newFormUri: string;
    newTeamUri: string;
  }) => {
    try {
      await updateForm({
        variables: {
          uri: newFormUri,
          teamUri: newTeamUri,
          fields: {
            title: newFormTitle,
            source: "duplicate",
            category:
              (formToDuplicate?.values.category as GqlFormCategory) ||
              GqlFormCategory.Lead,
            response:
              formToDuplicate?.values.response || defaultTemplate.response,
          },
        },
      });

      await updateSlug({
        variables: {
          formUri: newFormUri,
          fields: { active: false },
        },
      }).catch(console.error);
    } catch (err) {
      console.error(err);
    }
  };

  return [duplicateForm];
};

export const useDuplicateMatterTypes = () => {
  const [addFormMatterType] = useAddFormMatterType();

  const duplicateMatterTypes = async ({
    matterTypesToDuplicate,
    newFormUri,
  }: {
    matterTypesToDuplicate: GqlMatterType[];
    newFormUri: string;
  }) => {
    if (!matterTypesToDuplicate.length) return;
    await Promise.allSettled(
      matterTypesToDuplicate.map((newMatterType) =>
        addFormMatterType({
          variables: {
            formUri: newFormUri,
            matterType: {
              category: newMatterType.category,
              id: newMatterType.id,
              location: newMatterType.location,
              name: newMatterType.name,
              source: newMatterType.source,
              representativeOptions: newMatterType.representativeOptions,
            },
          },
        }).catch(console.error),
      ),
    ).catch(console.error);
  };

  return [duplicateMatterTypes];
};

export const useDuplicateSections = () => {
  const [updateSection] = useUpdateSection();

  const duplicateSections = async ({
    newFormUri,
    newSectionsUri,
    newFieldsUri,
    sectionsToDuplicate,
  }: {
    newFormUri: string;
    newSectionsUri: { [key: string]: string };
    newFieldsUri: { [key: string]: string };
    sectionsToDuplicate: ReturnType<typeof useLoadSections>["result"];
  }) => {
    if (!sectionsToDuplicate?.length) return;
    await Promise.allSettled(
      sectionsToDuplicate.map((newSection) =>
        updateSection({
          variables: {
            uri: newSectionsUri[newSection.uri],
            formUri: newFormUri,
            order: newSection.values.order,
            fields: {
              description: newSection.values.description || "",
              title: newSection.values.title,
              links: newSection.values.links
                ? newSection.values.links.map((link) => ({
                    ...removeKeys(link, ["__typename"]),
                    fieldUri: newFieldsUri[link.fieldUri],
                  }))
                : undefined,
            },
          },
        }).catch(console.error),
      ),
    ).catch(console.error);
  };

  return [duplicateSections];
};

export const useDuplicateGroups = () => {
  const [updateGroup] = useUpdateGroupWithFields();

  const duplicateGroups = async ({
    newFormUri,
    newFieldsUri,
    newGroupsUri,
    newSectionsUri,
    groupsToDuplicate,
  }: {
    newFormUri: string;
    newGroupsUri: { [key: string]: string };
    newFieldsUri: { [key: string]: string };
    newSectionsUri: { [key: string]: string };
    groupsToDuplicate: ReturnType<typeof useLoadGroupsWithFields>["result"];
  }) => {
    if (!groupsToDuplicate?.length) return;
    await Promise.allSettled(
      groupsToDuplicate.map((group) =>
        updateGroup({
          variables: {
            uri: newGroupsUri[group.uri],
            formUri: newFormUri,
            sectionUri: newSectionsUri[group.values.sectionUri],
            order: group.values.order,
            fields: {
              allowedRepeatable: group.values.allowedRepeatable,
              field: group.values.field
                ? { ...removeKeys(group.values.field, ["__typename"]) }
                : undefined,
              fields: group.values.fields
                ? group.values.fields.map((field) => ({
                    allowCustomResponse: field.allowCustomResponse,
                    field: field.field
                      ? {
                          ...removeKeys(field.field, [
                            "__typename",
                            "displayName",
                            "details",
                          ]),
                        }
                      : undefined,
                    label: field.label,
                    hint: field.hint,
                    layout: field.layout
                      ? {
                          ...removeKeys(field.layout, [
                            "__typename",
                            "displayName",
                          ]),
                          parentId: field.layout.parentId || undefined,
                          parentName: field.layout.parentName || undefined,
                          parentProviderId:
                            field.layout.parentProviderId || undefined,
                        }
                      : undefined,
                    mandatory: field.mandatory,
                    options: field.options.map((option) =>
                      removeKeys(option, ["__typename"]),
                    ),
                    order: field.order,
                    type: field.type,
                    uri: newFieldsUri[field.uri],
                  }))
                : [],
              hint: group.values.hint,
              label: group.values.label,
              layout: group.values.layout
                ? {
                    ...removeKeys(group.values.layout, [
                      "__typename",
                      "displayName",
                    ]),
                    parentId: group.values.layout.parentId || undefined,
                    parentName: group.values.layout.parentName || undefined,
                    parentProviderId:
                      group.values.layout.parentProviderId || undefined,
                  }
                : undefined,
              links: group.values.links
                ? group.values.links.map((link) => ({
                    ...removeKeys(link, ["__typename"]),
                    fieldUri: newFieldsUri[link.fieldUri],
                  }))
                : undefined,
              maxRepeat: group.values.maxRepeat || undefined,
              minRepeat: group.values.minRepeat || undefined,
              repeatPrompt: group.values.repeatPrompt || undefined,
              repeatable: group.values.repeatable,
              templateType: group.values.templateType || undefined,
              type: group.values.type,
            },
          },
        }).catch(console.error),
      ),
    ).catch(console.error);
  };

  return [duplicateGroups];
};

export const useDuplicateFields = () => {
  const [updateField] = useUpdateField();

  const duplicateFields = async ({
    newFormUri,
    newFieldsUri,
    newGroupsUri,
    newSectionsUri,
    fieldsToDuplicate,
  }: {
    newFormUri: string;
    newGroupsUri: { [key: string]: string };
    newFieldsUri: { [key: string]: string };
    newSectionsUri: { [key: string]: string };
    fieldsToDuplicate: ReturnType<typeof useLoadFields>["result"];
  }) => {
    if (!fieldsToDuplicate?.length) return;
    await Promise.allSettled(
      fieldsToDuplicate.map((newField) =>
        updateField({
          variables: {
            formUri: newFormUri,
            sectionUri: newSectionsUri[newField.values.sectionUri],
            uri: newFieldsUri[newField.uri],
            groupUri: newField.values.groupUri
              ? newGroupsUri[newField.values.groupUri]
              : undefined,
            order: newField.values.order,
            fields: {
              type: newField.values.type,
              label: newField.values.label,
              hint: newField.values.hint,
              description: newField.values.description || undefined,
              options: newField.values.options.map((option) =>
                removeKeys(option, ["__typename"]),
              ),
              mandatory: newField.values.mandatory,
              allowCustomResponse: newField.values.allowCustomResponse,
              links: newField.values.links
                ? newField.values.links.map((link) => ({
                    ...removeKeys(link, ["__typename"]),
                    fieldUri: newFieldsUri[link.fieldUri],
                  }))
                : undefined,
              layout: newField.values.layout
                ? (removeEmptyValues(
                    removeKeys(newField.values.layout, [
                      "__typename",
                      "displayName",
                    ]),
                  ) as Gql.UpdateFieldMutationVariables["fields"]["layout"])
                : undefined,
              field: newField.values.field
                ? {
                    ...removeKeys(newField.values.field, [
                      "__typename",
                      "displayName",
                      "details",
                    ]),
                    possibleValues:
                      newField.values.field.possibleValues || undefined,
                  }
                : undefined,
            },
          },
        }).catch(console.error),
      ),
    ).catch(console.error);
  };

  return [duplicateFields];
};

export const generateNewUris = (
  oldUris: string[] | undefined,
  namespace: Namespace<"Smart", "Field" | "Section" | "Group">,
) => {
  if (!oldUris?.length) return {};
  const uniqueOldUris = [...new Set(oldUris)];
  const newUriMap: { [key: string]: string } = {};
  for (const oldUri of uniqueOldUris) {
    newUriMap[oldUri] = namespace.generateUri();
  }
  return newUriMap;
};

export const useDuplicateForm = ({ formUri }: { formUri: string }) => {
  const [duplicateGqlForm] = useDuplicateGqlForm();
  const [duplicateGroups] = useDuplicateGroups();
  const [duplicateFields] = useDuplicateFields();
  const [duplicateSections] = useDuplicateSections();
  const [duplicateMatterTypes] = useDuplicateMatterTypes();
  const { result: team } = useLoadTeam({});
  const { result: formToDuplicate } = useLoadForm({ uri: formUri });
  const matterTypesToDuplicate = formToDuplicate?.values.matterTypes || [];
  const { result: sectionsToDuplicate } = useLoadSections({
    formUri,
  });
  const { result: fieldsToDuplicate } = useLoadFields({ formUri });
  const { result: groupsToDuplicate } = useLoadGroups({ formUri });

  const newSectionsUri = generateNewUris(
    sectionsToDuplicate?.map((section) => section.values.uri),
    SectionNS,
  );

  const newFieldsUri = generateNewUris(
    fieldsToDuplicate?.map((field) => field.values.uri),
    FieldNS,
  );

  const newGroupsUri = generateNewUris(
    groupsToDuplicate?.map((group) => group.values.uri),
    GroupNS,
  );

  const newFormUri = FormNS.generateUri();

  const duplicateForm = async ({ newFormTitle }: { newFormTitle: string }) => {
    await duplicateGqlForm({
      formToDuplicate,
      newFormUri,
      newFormTitle,
      newTeamUri: team?.uri || "",
    });

    await duplicateMatterTypes({
      newFormUri,
      matterTypesToDuplicate,
    });

    await duplicateSections({
      newFormUri,
      newSectionsUri,
      newFieldsUri,
      sectionsToDuplicate,
    });

    await duplicateGroups({
      newFormUri,
      newFieldsUri,
      newGroupsUri,
      newSectionsUri,
      groupsToDuplicate,
    });

    await duplicateFields({
      newFormUri,
      newFieldsUri,
      newGroupsUri,
      newSectionsUri,
      fieldsToDuplicate,
    });

    return newFormUri;
  };

  return [duplicateForm];
};
