import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";

import {
  Button,
  ButtonRow,
  IconButton,
  Label,
  Typeahead,
} from "@smart/itops-components-dom";

import { GqlField, GqlSection } from "./types";

const HeadingLabel = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1.8rem;
  padding: 2rem 0.4rem 0.3rem;
  border-top: 1px solid ${(props) => props.theme.palette.disabled.base};

  & > .text {
    position: absolute;
    top: -0.8rem;
    left: 1.6rem;
    background: var(--background);
    width: fit-content;
    max-width: 90%;
    padding: 0 0.8rem;
    font-size: ${(props) => props.theme.fontSize.xSmall};
    margin: 0;
  }
`;

type CreateLinkProps = {
  availableFields: GqlField[];
  sections: GqlSection[];
  create: (fieldUri: string) => void;
};

const CreateLink = ({ availableFields, sections, create }: CreateLinkProps) => {
  const [isCreating, setIsCreating] = useState(false);

  const searchableFields = useMemo(() => {
    const sectionTitles = sections.reduce<Record<string, string>>(
      (titles, s) => ({
        ...titles,
        [s.uri]: s.values.title,
      }),
      {},
    );

    return availableFields.map((f) => ({
      ...f.values,
      sectionTitle: sectionTitles[f.values.sectionUri],
    }));
  }, [availableFields, sections]);

  const useSearchFields = ({ label }: { label: string }) => ({
    result: searchableFields.filter((v) => {
      const searchText = label.toLocaleLowerCase();
      return (
        v.label?.toLocaleLowerCase().includes(searchText) ||
        v.sectionTitle?.toLocaleLowerCase().includes(searchText)
      );
    }),
  });

  const onCancel = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setIsCreating(false);
  };

  const onAddConditionLink = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setIsCreating(true);
  };

  const onCreate = (fieldUri: string | undefined) => {
    if (!fieldUri) return;

    create(fieldUri);
    setIsCreating(false);
  };

  return isCreating ? (
    <HeadingLabel>
      <div className="text">Add linked field</div>
      <Label name="search" text="Search For Fields">
        <Typeahead
          selected={undefined}
          onSelect={(field) => onCreate(field?.uri)}
          hook={useSearchFields}
          idKey="uri"
          input={(s) => s.label}
          display={(s) => s.label}
          details={(s) => s.sectionTitle}
          searchKey="label"
          locators={{}}
        />
      </Label>
      <ButtonRow>
        <Button type="button" palette="danger" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>
    </HeadingLabel>
  ) : (
    <IconButton
      name="add"
      text="Add condition"
      size={16}
      onClick={onAddConditionLink}
    />
  );
};

export { CreateLink, CreateLinkProps };
