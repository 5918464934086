import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { SchemeColor, fromSchemeColor } from "../../theme";
import { Toggle } from "../toggle";

type Divider = "top" | "bottom";

const ItemWrapper = styled.button<{
  schemeColor?: SchemeColor;
  divider?: Divider;
}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.2rem;
  width: 100%;

  background: none;
  border: 0;
  border-${(props) => props.divider}: 1px solid ${(props) => props.theme.scheme.grey.r15};
  color: ${(props) => fromSchemeColor(props.schemeColor, "inherit")(props)};
  cursor: pointer;
  padding: 1rem 1.6rem;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: ${(props) => props.theme.scheme.grey.r5};
  }

  .context-menu-item-checkbox {
    height: 1.6rem;
    width: 1.6rem;

    background-color: ${(props) => props.theme.scheme.grey.r10};
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.4rem;
    color: ${(props) => props.theme.scheme.grey.r10};

    display: flex;
    align-items: center;
    justify-content: center;

    &[aria-checked="true"] {
      background-color: ${(props) => props.theme.scheme.blue.r100};
      border-color: ${(props) => props.theme.scheme.blue.r100};
      color: ${(props) => props.theme.scheme.grey.r0};
    }
  }

  .context-menu-item-toggle {
    margin-left: -0.3rem;
  }

  .context-menu-item-text {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 350;
    margin: 0;
  }

  .sub-menu-item {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export type ContextMenuItemProps = {
  icon?: IconDetail;
  iconOverride?: ReactNode;
  text: string;
  onClick?: () => void;
  keepOpen?: boolean;
  schemeColor?: SchemeColor;
  divider?: Divider;
  disabled?: boolean;
  hidden?: boolean;
  checked?: boolean;
  toggled?: boolean;
  subMenuItems?: ContextMenuItemProps[];
  isSubMenuOpen?: boolean;
};

export const ContextMenuItem = ({
  icon,
  iconOverride,
  text,
  onClick,
  closeMenu,
  openSubMenu,
  keepOpen,
  schemeColor,
  divider,
  disabled,
  hidden,
  checked,
  toggled,
  subMenuItems,
  isSubMenuOpen,
}: ContextMenuItemProps & {
  closeMenu: () => void;
  openSubMenu: (items: ContextMenuItemProps[] | null) => void;
}) =>
  hidden ? null : (
    <ItemWrapper
      disabled={disabled}
      divider={divider}
      schemeColor={schemeColor}
      onMouseEnter={() => openSubMenu(subMenuItems || null)}
      onClick={(e) => {
        e.stopPropagation();
        if (subMenuItems?.length) {
          openSubMenu(isSubMenuOpen ? null : subMenuItems);
        } else {
          if (!keepOpen) closeMenu();
          if (onClick) onClick();
        }
      }}
      role="option"
    >
      {checked !== undefined && (
        <div
          className="context-menu-item-checkbox"
          role="checkbox"
          aria-checked={checked}
        >
          <Icon size={12} stroke={3} library="lucide" name="Check" />
        </div>
      )}
      {toggled !== undefined && (
        <Toggle
          className="context-menu-item-toggle"
          toggled={toggled}
          size={2}
          onClick={() => {}}
        />
      )}
      {icon && !iconOverride && (
        <Icon className="context-menu-item-icon" size={16} {...icon} />
      )}
      {iconOverride}
      <p className="context-menu-item-text">{text}</p>
      {subMenuItems?.length && (
        <div className="sub-menu-item">
          <Icon library="lucide" name="ChevronRight" />
        </div>
      )}
    </ItemWrapper>
  );
