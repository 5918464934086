import { useNavigate } from "react-router-dom";

import { extractId } from "@smart/itops-types-basic";
import { useLoadFormSubmissions } from "@smart/manage-gql-client-dom";

import { FormTabProps } from "./types";
import { SubmissionList } from "../../submissions";

const FormSubmissions = ({ form }: FormTabProps) => {
  const submissions = useLoadFormSubmissions({ formUri: form.uri });
  const navigate = useNavigate();

  return (
    <SubmissionList
      submissions={submissions.result}
      result={submissions}
      select={(uri) => navigate(`/submissions/${extractId(uri)}`)}
    />
  );
};

export { FormSubmissions };
