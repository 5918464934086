import { Transforms } from "slate";
import { useSlate } from "slate-react";

import { useDebounce } from "@smart/itops-hooks-dom";
import { serialize } from "@smart/itops-serialisation-basic";

export type TestEditableProps = {
  dataTestId?: string;
  readOnly?: boolean;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  autoFocus?: boolean;
};

export const TestEditable = ({ dataTestId, ...rest }: TestEditableProps) => {
  const editor = useSlate();
  const onChange = useDebounce(
    (v: string) => Transforms.insertText(editor, v, { at: [0, 0] }),
    0,
  );

  return (
    <div>
      <textarea
        data-testid={`${dataTestId}-textarea`}
        defaultValue={serialize(editor.children)}
        onChange={(e) => onChange(e.currentTarget.value)}
        {...rest}
      />
    </div>
  );
};
