import { Gql, TypedTypePolicies } from "@smart/manage-gql-operations-dom";

import { mergeItems, mergeUpdated } from "../cache";

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      fields: {
        merge: mergeItems("fields"),
      },
      form: {
        read: (existingData, { args, toReference }) =>
          existingData || toReference({ __typename: "Form", uri: args?.uri }),
      },
      forms: {
        merge: mergeItems("forms"),
      },
      sections: {
        merge: mergeItems("sections"),
      },
      submission: {
        read: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: "Submission", uri: args?.uri }),
      },
      submissions: {
        merge: mergeItems("submissions"),
      },
    },
  },
  Operation: {
    keyFields: ["operationId"],
  },
  Field: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("fieldFields"),
      },
    },
  },
  FieldOption: {
    keyFields: false,
  },
  FieldValues: {
    keyFields: false,
  },
  FieldLink: {
    keyFields: false,
  },
  Form: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("formFields"),
      },
    },
  },
  FormValues: {
    keyFields: false,
  },
  Group: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("groupFields"),
      },
    },
  },
  GroupValues: {
    keyFields: false,
  },
  MatterField: {
    keyFields: ["name", "possibleValues"],
  },
  MatterLayout: {
    keyFields: ["id", "parentId"],
  },
  MatterTypeGroup: {
    keyFields: false,
  },
  MatterType: {
    keyFields: ["id"],
  },
  MatterTypeWithRepresentatives: {
    keyFields: ["id"],
  },
  Response: {
    /**
     * We can't normalise Response because the response cache will be updated before
     * the merge function runs in parent type, e.g Submission, so that the responses
     * of the existing submission passed into the function has already been updated.
     * (https://github.com/apollographql/apollo-client/issues/9502)
     * The type level merge function for Response also not triggered when a submission
     * updates. The field level (e.g the value field of the response) merge function
     * does trigger, however there's no access to the updatedAt field in that function
     * to determine whether the value should be updated.
     */
    keyFields: false,
  },
  Section: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("sectionFields"),
      },
    },
  },
  SectionValues: {
    keyFields: false,
  },
  Slug: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("slugFields"),
      },
    },
  },
  SlugValues: {
    keyFields: false,
  },
  Submission: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("submissionFields"),
      },
      responses: {
        merge: (existing, incoming) => {
          const incomingResponses = incoming.map(
            (res: Gql.ResponseFieldsFragment) => {
              const existingResponse = existing?.find(
                (ex: Gql.ResponseFieldsFragment) => ex.uri === res.uri,
              );
              if (!existingResponse) return res;

              const isExistingNewer =
                existingResponse.updatedAt &&
                new Date(existingResponse.updatedAt).getTime() -
                  new Date(res.updatedAt).getTime() >=
                  0;
              return isExistingNewer ? existingResponse : res;
            },
          );

          const newResponses =
            existing?.filter(
              (ex: Gql.ResponseFieldsFragment) =>
                !incoming.some(
                  (res: Gql.ResponseFieldsFragment) => res.uri === ex.uri,
                ),
            ) || [];

          return [...incomingResponses, ...newResponses];
        },
      },
    },
  },
  SubmissionValues: {
    keyFields: false,
  },
  Team: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("teamFields"),
      },
    },
  },
  TeamValues: {
    keyFields: false,
  },
  User: {
    keyFields: ["uri"],
  },
  AddressPrediction: {
    keyFields: ["placeId"],
  },
  AddressDetails: {
    keyFields: false,
  },
  PassportDetails: {
    keyFields: false,
  },
  DeathDetails: {
    keyFields: false,
  },
  CitizenshipDetails: {
    keyFields: false,
  },
  UtbmsDetails: {
    keyFields: false,
  },
  IdentificationDetails: {
    keyFields: false,
  },
  Address: {
    keyFields: false,
  },
  Phone: {
    keyFields: false,
  },
  Person: {
    keyFields: false,
  },
  Company: {
    keyFields: false,
  },
  ContactId: {
    keyFields: false,
  },
  GroupOfPeople: {
    keyFields: false,
  },
  Contact: {
    keyFields: false,
  },
  File: {
    keyFields: false,
  },
  Recipient: {
    keyFields: false,
  },
  Tracker: {
    keyFields: ["uri"],
  },
  LinkParameter: {
    keyFields: ["uri"],
  },
  SubmissionLinkHash: {
    keyFields: ["uri"],
  },
  AnalyticsTool: {
    keyFields: false,
  },
  DefaultClientMessage: {
    keyFields: false,
  },
  Setting: {
    keyFields: ["uri"],
  },
  MatterQuestionnaireStatus: {
    keyFields: ["id"],
  },
  SendMatterQuestionnaireResponse: {
    keyFields: false,
  },
  FamilyProAuthorizationState: {
    keyFields: false,
  },
  FamilyProTemplate: {
    keyFields: false,
  },
  Role: {
    keyFields: false,
  },
  AIUserFeedback: {
    keyFields: ["uri"],
    fields: {
      values: {
        merge: mergeUpdated("aiUserFeedbackFields"),
      },
    },
  },
  AIUserFeedbackValues: {
    keyFields: false,
  },
  TimeOfDay: {
    keyFields: false,
  },
  Availability: {
    keyFields: false,
  },
  StaffDetails: {
    keyFields: false,
  },
  StaffBusySlots: {
    keyFields: false,
  },
  BankAccountIdentifier: {
    keyFields: false,
  },
  BankAccount: {
    keyFields: false,
  },
  Payment: {
    keyFields: false,
  },
  FileItem: {
    keyFields: false,
  },
  AIFillSettings: {
    keyFields: false,
  },
};
