import { PermissionError } from "@smart/itops-utils-basic";

import { rules } from "./rules";
import { Action, Evaluator, Resource, User } from "./types";

const checkPermission = (
  user: User,
  action: Action,
  resource: Resource,
): boolean => {
  const evaluator = rules[resource.type] as Evaluator;

  return evaluator(user, action, resource);
};

export type PermissionChecker = {
  can: (action: Action, resource: Resource) => boolean;
  assertCan: (action: Action, resource: Resource) => void;
};

export const getPermissionChecker = (user: User): PermissionChecker => ({
  can: (action, resource): boolean => checkPermission(user, action, resource),
  assertCan: (action, resource): void => {
    if (!checkPermission(user, action, resource)) {
      throw new PermissionError(
        action,
        { type: resource.type, uri: resource.value?.uri },
        user.userUri,
      );
    }
  },
});
