import {
  contacts as ContactsNS,
  common,
  communicate as CommunicateNS,
  correspondence as CorrespondenceNS,
  files as FilesNS,
  host as HostNS,
  layouts as LayoutsNS,
  matters as MattersNS,
  relationships as RelationshipsNS,
  roles as RolesNS,
  staff as StaffNS,
} from "@smokeballdev/smokeball-client-sdk";

export type Client = common.Api;
export type Context = common.Context;
export type Matter = MattersNS.Matter;
export type LayoutMatter = LayoutsNS.LayoutMatter;
export type LayoutMatterItem = LayoutsNS.LayoutMatterItem;
/**
 * The company contacts returned by the SDK have staff and directors
 * instead of staffIds and directorIds at the moment.
 * Should be removed once SDK returns the correct data.
 */
export type Contact = Omit<ContactsNS.Contact, "company"> & {
  company?: ContactsNS.Company & {
    staff?: string[];
    directors?: string[];
    email?: string;
  };
};
export type CreateContactRequest = {
  person?: ContactsNS.Person;
  company?: ContactsNS.Company & { email?: string };
  groupOfPeople?: ContactsNS.GroupOfPeople;
};
export type UpdateContactRequest = {
  id: string;
  person?: ContactsNS.Person;
  company?: ContactsNS.Company & { email?: string };
};
export type Role = RolesNS.Role;
export type Roles = RolesNS.Roles;
export type AddRoleRequest = RolesNS.AddRoleRequest;
export type UpdateRoleRequest = RolesNS.UpdateRoleRequest;
export type UpdateLayoutMatterItemRequest = {
  itemId?: string;
  layoutId?: string;
} & Omit<LayoutsNS.UpdateLayoutMatterItemRequest, "itemId">;
export type EmailRequest = CorrespondenceNS.EmailRequest & {
  bodyText?: string;
};
export type LetterRequest = CorrespondenceNS.LetterRequest & {
  bodyContentType?: "html";
};
export type DocumentRequest = CorrespondenceNS.DocumentRequest;
export type FormRequest = CorrespondenceNS.FormRequest;
export type MessageRequest = CommunicateNS.MessageRequest;
export type Staff = StaffNS.Staff;
export type Version = HostNS.Version;
export type Relationships = RelationshipsNS.Relationships;
export type Relationship = RelationshipsNS.Relationship;
export type RoleRelationships = Relationships & {
  roleId: string;
};
export type AddRelationshipRequest = RelationshipsNS.AddRelationshipRequest;
export type UpdateRelationshipRequest =
  RelationshipsNS.UpdateRelationshipRequest;
export type FileRequest = FilesNS.FileRequest;

export type Change = "matters" | "layouts" | "contacts" | "staff";
type Observe = Change[];

export type UseSmokeballAppOptions = {
  observe: Observe;
};

export type UseSDKApiOptions = {
  client: Client | null;
  hostType: "windows" | "web" | "local" | undefined;
  isInit: boolean;
  observe?: Observe;
};

export type UseLocalApiOptions = {
  context: Context;
  observe?: Observe;
};

export type LocalSmokeballAppContext = {
  region: { code: string; name: string };
  firmId?: string;
  userId?: string;
  matterId?: string;
  matters: Matter[];
  layoutMatters: LayoutMatter[];
  relationships: RoleRelationships[];
  roles: Role[];
  contacts: Contact[];
  staff: Staff[];
};

export const { MatterTabs } = HostNS;

export type WebNavigationTarget = "intake-form-builder";
