import {
  LookupOptions,
  ResponseSubmitter,
  SectionItem,
  TeamItem,
} from "@smart/bridge-intake-components-dom";
import { FormAIFillSettings } from "@smart/bridge-types-basic";
import { delay, v4 } from "@smart/itops-utils-basic";
import { CachedGql } from "@smart/manage-cached-gql-operations-dom";
import {
  useLoadForm,
  useLoadSections,
  useLoadGroups,
  useLoadFields,
  useLoadTeam,
  useLoadAddressPredictions,
  useLazyLoadAddressDetails,
  useLoadSetting,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

export type FormDisplayProps = {
  loading: boolean;
  exists: boolean;
  matterTypes: CachedGql.MatterTypeFieldsFragment[];
  form: {
    team: TeamItem;
    response?: string;
    category?: string;
    aiFillSettings?: FormAIFillSettings;
  };
  sections: SectionItem[];
  sectionValues: Gql.SectionFieldsFragment["values"][];
  groups: Gql.GroupFieldsFragment["values"][];
  fields: Gql.FieldFieldsFragment["values"][];
  submission: { uri: string; responses: Record<string, any> };
  submitResponses: ResponseSubmitter;
  lookup: LookupOptions;
};

export const useFormForDisplay = ({
  loading,
  form,
  team,
  setting,
  sections,
  groups,
  fields,
}: {
  loading: boolean;
  form?: Gql.FormFieldsFragment | null;
  team?: Gql.TeamFieldsFragment | null;
  setting?: Gql.SettingFieldsFragment | null;
  sections?: Gql.SectionFieldsFragment[] | null;
  groups?: Gql.GroupFieldsFragment[] | null;
  fields?: Gql.FieldFieldsFragment[] | null;
}): FormDisplayProps => ({
  loading,
  exists: !!form,
  matterTypes: form?.values.matterTypes || [],
  form: {
    team: team?.values || { name: "Team" },
    response:
      form?.values.response ||
      setting?.defaultClientMessage?.confirmation ||
      "",
    category: form?.values.category,
    aiFillSettings: form?.values.aiFillSettings
      ? {
          allowAiFill: form?.values.aiFillSettings?.allowAiFill || undefined,
          sectionTitle: form?.values.aiFillSettings?.sectionTitle || undefined,
          fileUploadLabel:
            form?.values.aiFillSettings?.fileUploadLabel || undefined,
          fileUploadDescription:
            form?.values.aiFillSettings?.fileUploadDescription || undefined,
        }
      : undefined,
  },
  sections: (sections || []).map((s) => ({
    uri: s.uri,
    order: s.values.order,
    title: s.values.title,
    description: s.values.description,
    hasRequired: !!fields?.find(
      (f) => f.values.sectionUri === s.uri && f.values.mandatory,
    ),
    links: s.values.links,
  })),
  sectionValues: (sections || []).map((s) => s.values),
  groups: (groups || []).map((g) => g.values),
  fields: (fields || []).map((f) => {
    const providerId = f.values.layout?.providerId;
    const fieldName = f.values.field?.name;
    if (
      providerId === "MatterTypeProvider" &&
      fieldName === "matterType/name"
    ) {
      const matterTypeNames = form?.values.matterTypes
        .filter((mt) => setting?.areasOfLaw?.includes(mt.category))
        .map((mt) => mt.name);

      return matterTypeNames?.length
        ? {
            ...f.values,
            options: f.values.options.filter(({ value }) =>
              matterTypeNames.includes(value),
            ),
          }
        : f.values;
    }

    if (
      providerId === "MatterTypeProvider" &&
      fieldName === "matterType/location"
    ) {
      return setting?.locations?.length
        ? {
            ...f.values,
            options: f.values.options.filter(({ value }) =>
              setting?.locations?.includes(value),
            ),
          }
        : f.values;
    }

    return f.values;
  }),
  submission: { uri: "", responses: {} },
  submitResponses: async () => ({ operationIds: [v4()], submissionStatus: {} }),
  lookup: {
    addressLookup: {
      search: useLoadAddressPredictions,
      load: useLazyLoadAddressDetails(),
    },
    fileLookup: {
      load: async ({ fileNames }: { fileNames: string[] }) =>
        fileNames.map((fileName) => ({
          key: fileName,
          uploadUrl: "#",
          downloadUrl: "#",
        })),
      upload: async ({
        onProgress,
      }: {
        onProgress: (progress: number) => void;
      }) => {
        onProgress(5);
        await delay(100);
        onProgress(20);
        await delay(200);
        onProgress(60);
        await delay(200);
        onProgress(80);
        await delay(200);
      },
    },
  },
});

export const useLoadFormForDisplay = ({
  formUri,
  submissionUri,
}: {
  formUri?: string;
  submissionUri?: string;
}) => {
  const { loading: formLoading, result: form } = useLoadForm({
    uri: formUri || "",
    submissionUri,
  });
  const { loading: sectionLoading, result: sections } = useLoadSections({
    formUri: formUri || "",
    submissionUri,
  });
  const { loading: groupLoading, result: groups } = useLoadGroups({
    formUri: formUri || "",
    submissionUri,
  });
  const { loading: fieldLoading, result: fields } = useLoadFields({
    formUri: formUri || "",
    submissionUri,
  });
  const { loading: teamLoading, result: team } = useLoadTeam({});
  const { loading: settingLoading, result: setting } = useLoadSetting({});

  return useFormForDisplay({
    loading:
      formLoading ||
      sectionLoading ||
      groupLoading ||
      fieldLoading ||
      teamLoading ||
      settingLoading,
    form,
    team,
    setting,
    sections,
    fields,
    groups,
  });
};
