import { FC } from "react";

import { EditorElement } from "@smart/itops-serialisation-basic";

import { LinkPopup } from "./link";
import { PlaceholderPopup } from "./placeholder";
import { EditorModal, ModalOption } from "../hooks";

const popups: Record<ModalOption, FC<{ element?: EditorElement }>> = {
  link: LinkPopup,
  placeholder: PlaceholderPopup,
};

export const Popup = ({ option, element }: EditorModal) => {
  const PopupComponent = popups[option];

  return <PopupComponent element={element} />;
};
