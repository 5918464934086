import { MouseEvent } from "react";

import { BaseInput, BaseInputProps } from "./base";

export type SearchInputProps = Omit<
  BaseInputProps,
  "leftIcon" | "rightButtonIcon" | "onRightButtonClick"
> & {
  onClear: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const SearchInput = (props: SearchInputProps) => (
  <BaseInput
    {...props}
    leftIcon="regularMagnifyingGlass"
    rightButtonIcon={props.value ? "regularXmark" : undefined}
    onRightButtonClick={props.onClear}
  />
);
