import styled from "@emotion/styled";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  WeekDay,
  buildTimeOfDayRange,
  calendarWeekLabel,
  formatTimeOfDay,
  timeOfDayInMinutes,
} from "@smart/bridge-intake-components-dom";
import { TimeOfDay } from "@smart/bridge-types-basic";
import { CheckboxItem, Combobox } from "@smart/itops-ui-dom";

const ItemContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  --background: ${(props) => props.theme.scheme.grey.r15};
  background: var(--background);
  border-radius: 0.5rem;
  gap: 1rem;
  margin: 0.4rem 0;
  padding: 0.4rem 1rem;
  color: ${(props) =>
    props.disabled ? props.theme.scheme.grey.r40 : "inherit"};
`;

const TimeRangeContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const CheckboxItemContainer = styled.div`
  margin: 0 1rem;
  font-size: ${(props) => props.theme.fontSize.base};
`;

const ComboboxContainer = styled.div`
  width: 12.8rem;
`;

export type AvailableDayProps = {
  id: string;
  day: number;
  fromTime: TimeOfDay;
  toTime: TimeOfDay;
  enabled: boolean;
};

export type AvailabilityItemProps = {
  dailyAvailability: AvailableDayProps;
  onChange: ({
    day,
    fromTime,
    toTime,
    enabled,
  }: {
    day: number;
    fromTime: TimeOfDay | null;
    toTime: TimeOfDay | null;
    enabled: boolean;
  }) => void;
};

export const AvailabilityItem = ({
  dailyAvailability: { id, day, fromTime, toTime, enabled },
  onChange,
}: AvailabilityItemProps) => {
  const formMethods = useForm<{
    day: number;
    fromTime: TimeOfDay | null;
    toTime: TimeOfDay | null;
    enabled: boolean;
  }>({
    defaultValues: {
      day,
      fromTime,
      toTime,
      enabled,
    },
  });
  const timeRange = buildTimeOfDayRange();
  const fromTimeRange = timeRange.slice(0, timeRange.length - 1);
  const toTimeRange = timeRange.slice(1);

  const { control, handleSubmit, setValue, watch } = formMethods;
  const availabilityEnabled = watch("enabled");
  const fromTimeValue = watch("fromTime");
  const toTimeValue = watch("toTime");

  const onSubmit = handleSubmit((values) => onChange(values));

  useEffect(() => {
    if (!availabilityEnabled) {
      setValue("fromTime", null);
      setValue("toTime", null);
    } else {
      setValue("fromTime", fromTime);
      setValue("toTime", toTime);
    }
  }, [availabilityEnabled]);

  useEffect(() => {
    if (!fromTimeValue || !toTimeValue) return;

    if (timeOfDayInMinutes(fromTimeValue) >= timeOfDayInMinutes(toTimeValue)) {
      const startTimeIndex = timeRange.findIndex(
        (t) =>
          t.hour === fromTimeValue.hour && t.minute === fromTimeValue.minute,
      );
      setValue(
        "toTime",
        timeRange[Math.min(timeRange.length - 1, startTimeIndex + 1)],
      );
      onSubmit().catch(console.error);
    }
  }, [fromTimeValue]);

  useEffect(() => {
    if (!fromTimeValue || !toTimeValue) return;

    if (timeOfDayInMinutes(toTimeValue) <= timeOfDayInMinutes(fromTimeValue)) {
      const endTimeIndex = timeRange.findIndex(
        (t) => t.hour === toTimeValue.hour && t.minute === toTimeValue.minute,
      );
      setValue("fromTime", timeRange[Math.max(0, endTimeIndex - 1)]);
      onSubmit().catch(console.error);
    }
  }, [toTimeValue]);

  return (
    <FormProvider {...formMethods}>
      <ItemContainer
        data-testid={`appointment-availability-${day}`}
        disabled={!availabilityEnabled}
      >
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <CheckboxItemContainer id={field.name}>
              <CheckboxItem
                id="availability-enabled"
                value={id}
                label={calendarWeekLabel[day as WeekDay]}
                checked={field.value}
                onChange={async (checked) => {
                  field.onChange(checked);
                  await onSubmit();
                }}
              />
            </CheckboxItemContainer>
          )}
        />
        <TimeRangeContainer>
          <Controller
            control={control}
            name="fromTime"
            render={({ field }) => (
              <ComboboxContainer id={field.name}>
                <Combobox
                  id={`${field.name}-${id}`}
                  title="From time"
                  options={fromTimeRange}
                  getOptionKey={(o) => timeOfDayInMinutes(o)}
                  getOptionLabel={(o) => formatTimeOfDay(o)}
                  isOptionEqualToValue={(o, v) =>
                    timeOfDayInMinutes(o) === timeOfDayInMinutes(v)
                  }
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await onSubmit();
                  }}
                  size="base"
                  disabled={!availabilityEnabled}
                  disableClearable={availabilityEnabled}
                  placeholder="Start"
                />
              </ComboboxContainer>
            )}
          />
          <Controller
            control={control}
            name="toTime"
            render={({ field }) => (
              <ComboboxContainer id={field.name}>
                <Combobox
                  id={`${field.name}-${id}`}
                  title="To time"
                  options={toTimeRange}
                  getOptionKey={(o) => timeOfDayInMinutes(o)}
                  getOptionLabel={(o) => formatTimeOfDay(o)}
                  isOptionEqualToValue={(o, v) =>
                    timeOfDayInMinutes(o) === timeOfDayInMinutes(v)
                  }
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await onSubmit();
                  }}
                  size="base"
                  disabled={!availabilityEnabled}
                  disableClearable={availabilityEnabled}
                  placeholder="End"
                />
              </ComboboxContainer>
            )}
          />
        </TimeRangeContainer>
      </ItemContainer>
    </FormProvider>
  );
};
