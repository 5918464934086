/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlStaffDetailsQueryVariables = Types.Exact<{
  staffIds: Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"];
}>;

export type GqlStaffDetailsQuery = {
  __typename: "Query";
  staffDetails?: Array<{
    __typename: "StaffDetails";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    busySlots: Array<{
      __typename: "StaffBusySlots";
      id: string;
      startTime: string;
      endTime: string;
      timezone?: string | null;
    }>;
  }> | null;
};

export const StaffDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "staffDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "staffIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "staffDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "staffIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "staffIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "staffDetailsFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "staffBusySlotsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StaffBusySlots" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "staffDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StaffDetails" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "middleName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "busySlots" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "staffBusySlotsFields" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlStaffDetailsQuery,
  GqlStaffDetailsQueryVariables
>;
