import { useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import {
  EditorElement,
  isEditorElementType,
} from "@smart/itops-serialisation-basic";

import {
  buttonGroupStyles,
  fieldStyles,
  inputStyles,
  labelStyles,
} from "./class-names";
import { Button } from "../../button";
import { Select } from "../../input";
import { RadioButtons } from "../../radio";
import { useEditorContext } from "../hooks";

export const PlaceholderPopup = ({ element }: { element?: EditorElement }) => {
  const editor = useSlate();
  const { setModal, placeholders } = useEditorContext();
  const [displayText, setDisplayText] = useState(() => {
    if (isEditorElementType("placeholder")(element)) return element.displayText;
    if (editor.selection) return Editor.string(editor, editor.selection);
    return "";
  });
  const [casing, setCasing] = useState<"titleCase" | "lowercase">("titleCase");
  const [placeholder, setPlaceholder] = useState(() => {
    if (isEditorElementType("placeholder")(element)) return element.name;
    return placeholders?.keys[0];
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!placeholder || !placeholders) return;

        const details = placeholders.details[placeholder];
        if (!details) return;

        const updated: EditorElement<"placeholder"> = {
          type: "placeholder",
          name: placeholder,
          isLink: details.isLink,
          displayText: details.isLink ? displayText : details.label,
          casing: casing === "lowercase" ? "lowercase" : undefined,
          children: [{ text: "" }],
        };

        if (element && isEditorElementType("placeholder")(element)) {
          Transforms.setNodes(editor, updated, {
            match: (n) => n === element,
          });
        } else {
          if (editor.selection && Range.isExpanded(editor.selection)) {
            Transforms.delete(editor, {
              at: editor.selection,
            });
          }

          Transforms.insertNodes(editor, updated);
        }

        setModal(undefined);
        ReactEditor.focus(editor);
      }}
    >
      <div className="px-4 pt-6 pb-1">
        <div className={fieldStyles}>
          <label className={labelStyles}>Type</label>
          <Select
            value={placeholder}
            options={
              placeholders?.keys.map((key) => ({
                label: placeholders.details[key].label,
                value: key,
              })) || []
            }
            onChange={(value) => {
              setPlaceholder(value);
              if (placeholders?.details[value].isLink && !displayText) {
                setDisplayText(placeholders.details[value].defaultValue || "");
              }
            }}
          />
        </div>
        {placeholder && placeholders?.details[placeholder]?.isLink && (
          <div className={fieldStyles}>
            <label className={labelStyles}>Display Text</label>
            <input
              type="text"
              onChange={(e) => setDisplayText(e.currentTarget.value)}
              value={displayText}
              className={inputStyles}
            />
          </div>
        )}
        {placeholder && placeholders?.details[placeholder]?.lowerable && (
          <div className={fieldStyles}>
            <RadioButtons
              options={[
                { label: "Title Case", value: "titleCase" },
                { label: "Lowercase", value: "lowercase" },
              ]}
              value={casing}
              onChange={(value) =>
                setCasing(value as "titleCase" | "lowercase")
              }
            />
          </div>
        )}
      </div>
      <div className={buttonGroupStyles}>
        <Button
          text="Cancel"
          variant="secondarySubtle"
          onClick={() => {
            setModal(undefined);
            ReactEditor.focus(editor);
          }}
          className="mr-4"
        />
        <Button text={element ? "Update" : "Add"} type="submit" />
      </div>
    </form>
  );
};
