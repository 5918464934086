export const resolvePromiseWithDelay = <T extends any>(
  value: T,
  delay = 0,
): Promise<T> =>
  new Promise((res) => {
    setTimeout(() => res(value), delay);
  });

export const resolveSettled = async <T>(
  input: Promise<PromiseSettledResult<Awaited<T>>[]>,
): Promise<{
  fulfilled: T[];
  rejected: any[];
}> => {
  const fulfilled: T[] = [];
  const rejected: any[] = [];

  const items = await input;
  for (const item of items) {
    if (item.status === "fulfilled") fulfilled.push(item.value);
    if (item.status === "rejected") rejected.push(item.reason);
  }

  return {
    fulfilled,
    rejected,
  };
};

export const waitSettledThenThrow = async <T>(
  promises: Promise<T>[],
): Promise<T[]> => {
  const { fulfilled, rejected } = await resolveSettled(
    Promise.allSettled(promises),
  );

  if (rejected.length) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw rejected;
  } else {
    return fulfilled;
  }
};
