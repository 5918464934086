/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterFilesQueryVariables = Types.Exact<{
  matterId: Types.Scalars["String"]["input"];
  folderId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GqlMatterFilesQuery = {
  __typename: "Query";
  matterFiles: Array<{
    __typename: "FileItem";
    id: string;
    type: Types.GqlFileItemType;
    name: string;
    fileExtension?: string | null;
    parentId?: string | null;
    sizeBytes?: number | null;
  }>;
};

export const MatterFilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "matterFiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "folderId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "matterFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "folderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "folderId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "fileItemFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "fileItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FileItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "fileExtension" } },
          { kind: "Field", name: { kind: "Name", value: "parentId" } },
          { kind: "Field", name: { kind: "Name", value: "sizeBytes" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlMatterFilesQuery, GqlMatterFilesQueryVariables>;
