import { document } from "./document";
import { embed } from "./embed";
import { message } from "./message";
import { MessageTemplateOptions, MessageTemplate } from "./types";

export const buildMessageTemplate = (
  options: MessageTemplateOptions,
): MessageTemplate => {
  switch (options.format) {
    case "embed":
      return embed(options);
    case "document":
      return document(options);
    case "email":
    case "communicate":
    default: {
      return message(options);
    }
  }
};
