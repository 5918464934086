import styled from "@emotion/styled";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

const Radios = styled.div<{ short?: boolean; align?: string }>`
  display: flex;

  flex-direction: ${(props) => (props.short ? "row" : "column")};
  justify-content: ${(props) => props.align || "center"};
`;

const RadioWrapper = styled.label<{ squash?: boolean; readOnly?: boolean }>`
  position: relative;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  display: flex;
  flex-flow: row;
  align-items: center;

  margin: ${(props) => (props.squash ? "0.3rem" : "0.6rem")} 0;
  padding: ${(props) => (props.squash ? "0.4rem" : "0.8rem")} 0;
  padding-right: 3rem;

  &:focus {
    outline: none;
  }

  input {
    display: none;
  }

  input.custom-response-input {
    display: inline-block;
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};
    margin-left: 0.5rem;
    outline: none;
    font-size: ${(props) => props.theme.fontSize.base};

    &:focus {
      border-color: ${(props) => props.theme.palette.primary.base};
    }
  }

  .dot {
    position: relative;
    background: ${(props) => props.theme.palette.background.base};
    border: 4px solid ${(props) => props.theme.palette.background.base};
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.foreground.highlight};
    border-radius: 1.8rem;
    height: 1.8rem;
    width: 1.8rem;
    margin-right: 1rem;
    flex: 0 0 1.8rem;
  }

  input:checked ~ .dot {
    background: ${(props) => props.theme.palette.primary.base};
  }

  .label {
    position: relative;
    font-size: ${(props) => props.theme.fontSize.base};
  }

  &:hover .label,
  &:focus .label {
    text-decoration: ${(props) => (props.readOnly ? "none" : "underline")};
  }

  input:checked ~ .label {
    text-decoration: none;
  }
`;

type CustomResponseItemProps = {
  options: { label: string; value: string }[];
  value?: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  squash?: boolean;
};

const CustomResponseItem = ({
  options,
  value,
  onChange,
  onBlur,
  disabled,
  readOnly,
  squash,
}: CustomResponseItemProps) => {
  const isCustomResponse =
    value !== undefined && !options.find((option) => option.value === value);

  return (
    <RadioWrapper tabIndex={0} readOnly={readOnly} squash={squash}>
      <input
        type="radio"
        value="Other"
        checked={isCustomResponse}
        onChange={() => !isCustomResponse && !readOnly && onChange("")}
        disabled={disabled}
      />
      <div className="dot" />
      <div className="label">Other</div>
      {isCustomResponse && (
        <input
          aria-label="Other Value"
          className="custom-response-input"
          placeholder="Please specify"
          type="text"
          value={value || ""}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </RadioWrapper>
  );
};

const RadioItem = ({
  tabIndex,
  readOnly,
  squash,
  value,
  checked,
  disabled,
  label,
  onChange,
}: {
  tabIndex?: number;
  readOnly?: boolean;
  squash?: boolean;
  value: string;
  checked: boolean;
  disabled?: boolean;
  label: string;
  onChange: (value: string) => void;
}) => (
  <RadioWrapper tabIndex={tabIndex} readOnly={readOnly} squash={squash}>
    <input
      type="radio"
      value={value}
      checked={checked}
      onChange={(e) => !readOnly && onChange(e.currentTarget.value)}
      disabled={disabled}
    />
    <div className="dot" />
    <div className="label">{label}</div>
  </RadioWrapper>
);

type RadioProps = {
  options: { label: string; value: string }[];
  selectedValue: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  allowCustomResponse?: boolean | null;
  disabled?: boolean;
  readOnly?: boolean;
  squash?: boolean;
  align?: string;
  shortMax?: number;
};

const Radio = ({
  options,
  selectedValue,
  onChange,
  onBlur,
  allowCustomResponse,
  disabled,
  readOnly,
  squash,
  align,
  shortMax = 2,
}: RadioProps) => (
  <Radios
    align={align}
    short={!allowCustomResponse && options.length <= shortMax}
  >
    {options.map(({ label, value }) => (
      <RadioItem
        key={value}
        tabIndex={0}
        readOnly={readOnly}
        squash={squash}
        value={value}
        checked={selectedValue === value}
        onChange={onChange}
        disabled={disabled}
        label={label}
      />
    ))}
    {allowCustomResponse && (
      <CustomResponseItem
        options={options}
        value={selectedValue}
        onChange={onChange}
        onBlur={() => onBlur && onBlur()}
        disabled={disabled}
        squash={squash}
      />
    )}
  </Radios>
);

type RadioFieldProps<V extends FieldValues> = {
  options: { label: string; value: string }[];
  allowCustomResponse?: boolean | null;
  disabled?: boolean;
  readOnly?: boolean;
  squash?: boolean;
  align?: string;
  shortMax?: number;
} & Pick<UseControllerProps<V>, "name" | "control" | "rules">;

const RadioField = <V extends FieldValues>({
  control,
  name,
  rules,
  options,
  allowCustomResponse,
  disabled,
  readOnly,
  squash,
  align,
  shortMax,
}: RadioFieldProps<V>) => {
  const { field } = useController({
    control,
    name,
    rules,
  });

  return (
    <Radio
      align={align}
      options={options}
      onChange={(value) => {
        field.onChange(value);
        setTimeout(field.onBlur, 0);
      }}
      selectedValue={field.value}
      onBlur={field.onBlur}
      allowCustomResponse={allowCustomResponse}
      disabled={disabled}
      readOnly={readOnly}
      squash={squash}
      shortMax={shortMax}
    />
  );
};

export { Radio, RadioProps, RadioField, RadioItem, RadioFieldProps };
