import { User, UserRole } from "./types";

const SMOKEBALL_EMAIL_DOMAINS = ["smokeball.com", "smokeball.com.au"];

export const isSmokeballEmployee = (user: User): boolean =>
  SMOKEBALL_EMAIL_DOMAINS.includes(user.email.split("@")[1]);

export const hasRole = (user: User, role: UserRole) =>
  role && user.roles ? user.roles.split(",").includes(role) : false;

export const INTAKE_TRACKER_SOURCE = "intake";
export const allowedIntakeTrackingActions = ["intake-form-opened"];
