import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useHistory } from "@smart/itops-hooks-dom";

import { IconButton } from "./icon";

type CloseButtonProps = {
  search?: boolean;
  previous?: RegExp;
};

const CloseButton = ({ search, previous }: CloseButtonProps) => {
  const history = useHistory();
  const navigate = useNavigate();

  const cancel = useCallback(() => {
    if (search) {
      navigate(history.current?.pathname || "/", { replace: true });
    } else {
      const parentPath = (history.current?.pathname || "/")
        .split("/")
        .slice(0, 2)
        .join("/");
      if (
        history.previous?.pathname === parentPath ||
        (previous && history.previous?.pathname.match(previous))
      ) {
        navigate(-1);
      } else {
        navigate(parentPath);
      }
    }
  }, [search, history]);

  return <IconButton name="cancel" palette="danger" onClick={cancel} />;
};

export { CloseButton, CloseButtonProps };
