import { Placement } from "@floating-ui/react";

import { loadEnv } from "@smart/bridge-env-dom";
import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { ContextMenu, Tooltip } from "@smart/itops-sb-design-system-dom";
import { Region } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  submissionLink,
  useCopySubmissionLink,
} from "@smart/manage-gql-client-dom";
import { useToast } from "@smart/manage-hooks-dom";

import { useCreateDocFromSubmission } from "./create-doc-from-submission";

type ActionsProps = {
  onView?: (matterSubmission: MatterSubmission) => void;
  onEdit?: (matterSubmission: MatterSubmission) => void;
  onRemind: (matterSubmission: MatterSubmission) => void;
  onCancel: (matterSubmission: MatterSubmission) => void;
  onDelete: (matterSubmission: MatterSubmission) => void;
  onGenerateQRCode: (matterSubmission: MatterSubmission) => void;
  matterSubmission?: MatterSubmission;
  tooltipPlacement?: Placement;
  tooltipText?: string;
  id?: string;
  className?: string;
  variant?: "default" | "subtle";
  size?: "default" | "small";
};

const isInternalUse = (submission: MatterSubmission) =>
  submission.status === "draft" &&
  submission.rawSubmission.values?.communicationMethod === "internalUse";

export const ActionsMenu = ({
  id,
  onView,
  onEdit,
  onRemind,
  onCancel,
  onDelete,
  onGenerateQRCode,
  matterSubmission,
  tooltipPlacement,
  tooltipText,
  className,
  variant,
  size,
}: ActionsProps) => {
  const [copySubmissionLink] = useCopySubmissionLink();
  const { setToasts } = useToast();
  const { wordGenerationIntake } = useFeatureFlags();
  const { createDocument, createFormDocument, createLetterDocument } =
    useCreateDocFromSubmission(matterSubmission);

  if (!matterSubmission) return null;

  const isUsRegion = (loadEnv("Region") as Region) === "nova";

  const isActionable =
    matterSubmission.status === "active" || isInternalUse(matterSubmission);

  const isFamilyProQuestionnaire =
    matterSubmission.formUri === familyProQuestionnaireFormUri;

  const onCopyLink = (submission: MatterSubmission) => {
    const setToast = () => {
      setToasts([
        {
          text: "Link successfully copied",
          width: "medium",
        },
      ]);
    };
    if (submission.formUri === familyProQuestionnaireFormUri) {
      setToast();
    } else {
      copySubmissionLink({ variables: { uri: submission.uri } })
        .then(setToast)
        .catch((err) => console.error(err));
    }
  };

  const items = [
    {
      label: "View",
      onClick: () => onView && onView(matterSubmission),
      hidden: isFamilyProQuestionnaire || !onView,
    },
    {
      label: isInternalUse(matterSubmission) ? "Send" : "Send Reminder",
      onClick: () => onRemind(matterSubmission),
      hidden: !isActionable,
    },
    {
      label: "Copy Link",
      onClick: async () => {
        const link = isFamilyProQuestionnaire
          ? matterSubmission.externalSubmissionLink
          : submissionLink(matterSubmission.rawSubmission);
        if (link) {
          await navigator.clipboard.writeText(link);
          onCopyLink(matterSubmission);
        }
      },
      hidden: !isActionable && !isFamilyProQuestionnaire,
    },
    {
      label: "Resend",
      onClick: () => onRemind(matterSubmission),
      hidden:
        !isFamilyProQuestionnaire || matterSubmission.status !== "completed",
    },
    {
      label: "Show QR Code",
      onClick: () => onGenerateQRCode(matterSubmission),
      hidden: matterSubmission.status !== "active",
    },
    {
      label: "Edit Responses",
      onClick: () => onEdit && onEdit(matterSubmission),
      hidden: !isActionable || !onEdit,
      disabled: matterSubmission.syncStatus === "pendingContactReview",
    },
    {
      label: "Open in Word",
      hidden:
        !wordGenerationIntake ||
        matterSubmission.status !== "completed" ||
        (!createDocument && !createFormDocument && !createLetterDocument),
      subMenu: [
        {
          label: "Blank",
          onClick: createDocument,
          hidden: !createDocument,
        },
        {
          label: "Form",
          onClick: createFormDocument,
          hidden: !isUsRegion || !createFormDocument,
        },
        {
          label: "Letter",
          onClick: createLetterDocument,
          hidden: !createLetterDocument,
        },
      ],
    },
    {
      label: "Close Form",
      onClick: () => onCancel(matterSubmission),
      hidden: !isActionable || isFamilyProQuestionnaire,
    },
    {
      label: "Delete Form",
      onClick: () => onDelete(matterSubmission),
      hidden: !isActionable || isFamilyProQuestionnaire,
    },
  ];

  return (
    <Tooltip placement={tooltipPlacement} text={tooltipText} offsetBy={10}>
      <ContextMenu
        button={{
          className:
            size === "small" ? "w-[2rem] h-[2rem]" : "w-[4rem] h-[4rem]",
          variant:
            variant === "subtle" ? "secondarySubtle" : "secondaryDefault",
          leftIcon: "solidEllipsis",
          disabled: items.every((item) => item.hidden),
        }}
        placement="bottom-end"
        offsetBy={5}
        items={items}
        id={id}
        className={className}
      />
    </Tooltip>
  );
};
