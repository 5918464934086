import styled from "@emotion/styled";
import { position, transparentize } from "polished";

const CornerBannerWrapper = styled.div`
  .banner {
    ${position("absolute", 0, 0, "auto", "auto")}
    overflow: hidden;
    white-space: nowrap;
    width: 150px;
    height: 150px;
    z-index: 1;

    .content {
      background-color: ${(props) => props.theme.palette.secondary.base};
      border: 1px solid ${(props) => props.theme.palette.primary.accent};
      color: ${(props) => props.theme.palette.background.base};
      box-shadow: 0 0 4px 1px
        ${(props) => transparentize(0.4, props.theme.palette.foreground.accent)};
      ${position("absolute")}
      width: 220px;
      display: block;
      margin: 1px 0;
      padding: 10px 0px;
      text-align: center;
      text-decoration: none;
      text-shadow: 0 0 5px
        ${(props) => transparentize(0.4, props.theme.palette.foreground.base)};
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: 600;
      left: -10px;
      top: 35px;
      transform: rotate(45deg);
    }
  }

  &:hover .tooltip {
    visibility: visible;
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    top: 40px;
    right: 50px;
    color: black;
    text-align: center;
    border-radius: 10rem;
    padding: 10px;
    z-index: 1;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 0.8rem 0.2rem
      ${(props) => transparentize(0.6, props.theme.palette.foreground.accent)};
    font-size: ${(props) => props.theme.fontSize.small};
  }
`;

type CornerBannerProps = {
  content: string;
  title?: string;
};

const CornerBanner = ({ content, title }: CornerBannerProps) => (
  <CornerBannerWrapper>
    <div className="banner">
      <div className="content">{content}</div>
    </div>
    {title && <span className="tooltip">{title}</span>}
  </CornerBannerWrapper>
);

export { CornerBannerProps, CornerBanner };
