import { FetchPolicy, useApolloClient, useMutation } from "@apollo/client";

import { TrackerNS, UserNS } from "@smart/bridge-types-basic";
import { extractId } from "@smart/itops-types-basic";
import { sortField, toSubdomain, v4 } from "@smart/itops-utils-basic";
import {
  Gql,
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation, updateAfterDelete } from "../cache";

export const useCreateSubmission = () =>
  useMutation(mutationDocuments.createSubmission, {
    update: (cache, { data }, { variables }) => {
      if (!data?.createSubmission || !variables) return;

      if (variables?.matterId) {
        const variableSets = [
          { matterId: variables.matterId },
          {
            matterId: variables.matterId,
            status: "active",
          },
        ] as const;
        variableSets.forEach((set) => {
          cache.updateQuery(
            {
              query: queryDocuments.matterSubmissions,
              variables: set,
            },
            (ex) => ({
              matterSubmissions: [
                data.createSubmission,
                ...(ex?.matterSubmissions || []),
              ],
              __typename: "Query" as const,
            }),
          );
        });
      }

      cache.updateQuery(
        {
          query: queryDocuments.submissions,
        },
        (ex) => ({
          submissions: [data.createSubmission, ...(ex?.submissions || [])],
          __typename: "Query" as const,
        }),
      );

      cache.updateQuery(
        {
          query: queryDocuments.formSubmissions,
          variables: {
            formUri: variables.formUri,
          },
        },
        (ex) => ({
          formSubmissions: [
            data.createSubmission,
            ...(ex?.formSubmissions || []),
          ],
          __typename: "Query" as const,
        }),
      );
    },
  });

export const useLoadSubmissions = useQueryFactory(queryDocuments.submissions, {
  map: ({ submissions }) =>
    sortField(
      submissions.filter((s) => !s.values.deleted),
      { key: (f) => f.values.updatedAt, dir: "desc" },
    ),
});

export const mapToMatterSubmission = (
  submission: Gql.SubmissionFieldsFragment,
) => ({
  uri: submission.uri,
  label: submission.values.label,
  formTitle: submission.form.values.title,
  formUri: submission.form.uri,
  createdAt: submission.values.createdAt,
  updatedAt: submission.values.updatedAt,
  questions: submission.form.fields.length,
  responses: submission.responses,
  formFields: submission.form.fields,
  status: submission.values.status,
  syncStatus: submission.values.syncStatus,
  recipient: submission.values.recipient,
  trackers: submission.trackers,
  rawSubmission: submission,
  sentAt: (submission.trackers && submission.trackers[0]?.sentAt) || undefined,
});

export const useLoadMatterSubmissions = useQueryFactory(
  queryDocuments.matterSubmissions,
  {
    map: ({ matterSubmissions }) =>
      matterSubmissions
        .filter((s) => !s.values.deleted)
        .map(mapToMatterSubmission),
  },
);

export type MatterSubmission = NonNullable<
  ReturnType<typeof useLoadMatterSubmissions>["result"]
>[number] & {
  externalSubmissionLink?: string;
  externalSubmissionEmbedUrl?: string;
  externalSubmissionType?: string;
};

export const useLoadFormSubmissions = useQueryFactory(
  queryDocuments.formSubmissions,
  {
    map: ({ formSubmissions }) =>
      sortField(
        formSubmissions.filter((s) => !s.values.deleted),
        { key: (f) => f.values.updatedAt, dir: "desc" },
      ),
  },
);

export const useQuerySubmission = (fetchPolicy?: FetchPolicy) => {
  const apollo = useApolloClient();

  return (variables: Gql.SubmissionQueryVariables) =>
    apollo
      .query({ query: queryDocuments.submission, variables, fetchPolicy })
      .then((result) => result.data.submission);
};

export const useLoadSubmission = useQueryFactory(queryDocuments.submission, {
  map: ({ submission }) => submission,
});

export const submissionLink = (sub: Gql.SubmissionFieldsFragment) =>
  sub.form.slug?.values
    ? toSubdomain(
        undefined,
        `/${sub.form.slug.values.value}/${extractId(sub.uri)}`,
      )
    : undefined;

export const useDeleteSubmission = () =>
  useMutation(mutationDocuments.deleteSubmission, {
    optimisticResponse: optimisticOperation("deleteSubmission"),
    update: updateAfterDelete("deleteSubmission", "Submission"),
  });

type User = {
  email: string;
  firstName: string;
  lastName: string;
  userUri: string;
};

const buildTracker = ({
  user,
  submissionUri,
}: {
  user?: User;
  submissionUri: string;
}) => ({
  __typename: "Tracker" as const,
  uri: TrackerNS.generateUri(),
  operationId: v4(),
  submissionUri,
  method: null,
  sentAt: new Date().toISOString(),
  action: "saved-to-matter",
  userUri: user?.userUri || UserNS.generateUri(),
  user: {
    // Generate new uri so it won't affect the original user entity
    uri: UserNS.generateUri(),
    __typename: "User" as const,
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    initials: "",
    picture: "",
    latestLoginAt: "",
  },
});

export const useUpdateSubmission = (options?: { user?: User }) =>
  useMutation(mutationDocuments.updateSubmission, {
    update: (cache, { data }) => {
      if (data?.updateSubmission) {
        cache.updateQuery(
          {
            query: queryDocuments.submissions,
          },
          (ex) => {
            const exists = ex?.submissions.find(
              (s) => s.uri === data.updateSubmission.uri,
            );

            if (exists)
              return data.updateSubmission.values.syncStatus === "synced"
                ? {
                    ...ex!,
                    submissions: ex!.submissions.map((s) => {
                      if (s.uri !== data.updateSubmission.uri) return s;
                      return {
                        ...exists,
                        trackers: [
                          buildTracker({
                            user: options?.user,
                            submissionUri: exists.uri,
                          }),
                          ...(exists.trackers || []),
                        ],
                      };
                    }),
                  }
                : ex;

            return {
              submissions: [data.updateSubmission, ...(ex?.submissions || [])],
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useSendSubmission = () =>
  useMutation(mutationDocuments.sendSubmission, {
    update: (cache, { data }, { variables }) => {
      if (data?.sendSubmission && variables) {
        const { uri } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Submission", uri }),
          fields: {
            values: (existing) => ({
              ...existing,
              ...data.sendSubmission.values,
              status: "active",
            }),
          },
        });
      }
    },
  });

export const useSyncSubmission = () =>
  useMutation(mutationDocuments.syncSubmission);

export const useCopySubmissionLink = () =>
  useMutation(mutationDocuments.copySubmissionLink);
