import { sortField } from "@smart/itops-utils-basic";

import { GqlField, GqlGroup } from "../types";

export const getOrderedSectionItems = ({
  sectionUri,
  fields,
  groups,
}: {
  sectionUri: string;
  fields: GqlField[];
  groups: GqlGroup[];
}) => {
  const sectionFields = fields.filter(
    (f) => f.values.sectionUri === sectionUri,
  );

  const sectionGroups = groups.filter(
    (g) => g.values.sectionUri === sectionUri,
  );

  return sortField(
    [
      ...(sectionFields || [])
        .filter((f) => !f.values.groupUri)
        .map((f) => f.values),
      ...(sectionGroups || []).map((g) => ({
        ...g.values,
        fields: sortField(
          sectionFields
            ?.filter((f) => f.values.groupUri === g.uri)
            .map((f) => f.values) || [],
          { key: "order", dir: "asc" },
        ),
      })),
    ],
    { key: "order", dir: "asc" },
  );
};
