import { useState } from "react";

import { FailureMessage, Modal } from "@smart/itops-sb-design-system-dom";
import { buildLogProps, isElementOf } from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

export type SubmissionActionCancelProps = {
  selected?: MatterSubmission;
  onConfirm: (submission: MatterSubmission) => Promise<void>;
  onClose: () => void;
  onViewClose: () => void;
};

const isCancellableStatus = isElementOf([
  "loading",
  "loaded",
  "failedToLoad",
] as const);

export const SubmissionActionCancel = ({
  selected,
  onConfirm,
  onClose,
  onViewClose,
}: SubmissionActionCancelProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();

  const formTitle = selected?.formTitle || "this form";
  const shouldPrompt = !isCancellableStatus(selected?.syncStatus);

  const onSubmit = async () => {
    setHasError(undefined);
    setIsLoading(true);
    try {
      if (!selected) throw new Error(`Please select a form`);

      await onConfirm(selected);
      onClose();
      onViewClose();
    } catch (e) {
      const { logMessage, errorProps } = buildLogProps(e);
      setHasError(`${logMessage} - ${errorProps.message}`);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      header={{
        icon: "solidXmark",
        text: "Close Form",
        onClose: () => onClose(),
      }}
      open={!!selected}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            type: "reset",
            onClick: () => onClose(),
          },
          {
            text: "Yes, close",
            type: "submit",
            onClick: () => onSubmit(),
          },
        ],
      }}
      closeOptions={{
        escapeKey: false,
        clickOutside: false,
      }}
      loading={isLoading}
    >
      {shouldPrompt ? (
        <p>
          Responses have been entered into &quot;{formTitle}&quot;. Closing it
          will prevent the client from entering additional responses. Any
          responses not saved to the matter will be lost.
        </p>
      ) : (
        <p>
          Closing &quot;{formTitle}&quot; will prevent the client from entering
          responses.
        </p>
      )}
      <p>Are you sure you want to close it?</p>
      {hasError && <FailureMessage action="close form" title={hasError} />}
    </Modal>
  );
};
