import { clsx } from "clsx";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { Alert } from "@smart/itops-sb-design-system-dom";

export type Toast = {
  text: string;
  palette?: "default" | "success" | "warning" | "destructive" | "branding";
  width?: "small" | "medium" | "maximum";
};

type ToastContext = {
  toasts: Toast[];
  setToasts: (toasts: Toast[]) => void;
};

const toastContext = createContext<ToastContext | undefined>(undefined);

const useToast = () => {
  const context = useContext(toastContext);
  if (!context) throw new Error(`Toast context not available`);

  return context;
};

const ToastContextProvider = toastContext.Provider;

type ToastProviderProps = {
  children: ReactNode;
};

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (!toasts.length) return undefined;

    const timer = setTimeout(() => setToasts([]), 5000);
    return () => clearTimeout(timer);
  }, [toasts]);

  return (
    <ToastContextProvider value={{ toasts, setToasts }}>
      {children}
      {!!toasts.length && (
        <div className="fixed flex flex-col z-modal items-center w-full bottom-16">
          {toasts.map((item, index) => {
            const wrapperStyles = clsx(
              item.width === "small" && "w-[40rem]",
              item.width === "medium" && "w-[80rem]",
              item.width === "maximum" && "w-[140rem]",
            );
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={wrapperStyles}>
                <Alert
                  variant="toast"
                  content={item.text}
                  onClose={() =>
                    setToasts((currentToasts) =>
                      currentToasts.filter((_, i) => i !== index),
                    )
                  }
                  palette={item.palette || "success"}
                />
              </div>
            );
          })}
        </div>
      )}
    </ToastContextProvider>
  );
};

export { ToastProvider, useToast };
