import { clsx } from "clsx";
import { forwardRef, InputHTMLAttributes, MouseEvent, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { getWrapperClassNames, inputClassNames } from "./class-names";
import { Icon } from "../icon";
import { IconName } from "../icon/available-icons";

export type BaseInputProps = {
  label?: string;
  mandatory?: boolean;
  leftIcon?: IconName;
  message?: string;
  error?: boolean;
  className?: string;
  rightButtonIcon?: IconName;
  dataTestId?: string;
  onRightButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  contentOverride?: ReactNode;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "type"
  | "placeholder"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "onKeyDown"
  | "readOnly"
  | "inputMode"
  | "formNoValidate"
  | "minLength"
  | "maxLength"
  | "pattern"
  | "autoComplete"
>;

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      id,
      leftIcon,
      rightButtonIcon,
      error,
      message,
      disabled,
      dataTestId,
      className,
      onRightButtonClick,
      contentOverride,
      ...inputProps
    }: BaseInputProps,
    ref,
  ) => (
    <div
      className={twMerge(
        clsx(getWrapperClassNames({ error, disabled }), className),
      )}
      aria-disabled={disabled}
      data-testid={dataTestId}
    >
      {leftIcon && (
        <Icon className="w-[1.5rem] h-[1.5rem] px-2" name={leftIcon} />
      )}
      {contentOverride ? (
        <div className="flex-1 min-h-[2.5rem]">{contentOverride}</div>
      ) : (
        <input
          id={id}
          disabled={disabled}
          ref={ref}
          aria-invalid={error}
          aria-errormessage={error ? message : undefined}
          className={inputClassNames}
          data-testid={`${dataTestId || "text-field"}-input`}
          {...inputProps}
        />
      )}

      {rightButtonIcon && (
        <button
          className="flex items-center gap-1 p-1 focus-visible:outline-none disabled:cursor-not-allowed"
          type="button"
          disabled={disabled}
          onClick={onRightButtonClick}
          data-testid={`${dataTestId || "base-input"}-button`}
        >
          <Icon className="w-[1.5rem] h-[1.5rem]" name={rightButtonIcon} />
        </button>
      )}
    </div>
  ),
);
