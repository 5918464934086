export const removeCurlyBraces = (str: string) => str.replaceAll(/[{}]/g, "");

export const appendFormLink = (template: string) => {
  const text = template.replace(/\{formLink\|([^}>]*)\}/g, "{formLink|>$1}");
  const hasFormLink = !!text.match(/\{formLink\|>[^}]*\}/);
  return hasFormLink ? text : `${text}\n\n{formLink|>Open form}`;
};

export const appendDownloadFormLink = (template: string) => {
  const text = template.replace(
    /\{downloadFormLink\|([^}>]*)\}/g,
    "{downloadFormLink|>$1}",
  );
  const hasFormLink = !!text.match(/\{downloadFormLink\|>[^}]*\}/);
  return hasFormLink
    ? text
    : `${text}\n\n{downloadFormLink|>Click here to save a copy of your form}`;
};
