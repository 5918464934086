import { rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn } from "@tanstack/react-table";
import { useState } from "react";
import { z } from "zod";

import { SubmissionStatus } from "@smart/bridge-types-basic";
import { useStorage } from "@smart/itops-hooks-dom";
import { TableProps } from "@smart/itops-ui-dom";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import { TabRowValue } from "./cells";
import { MatterSpecificForm } from "./matter-specific-forms-modal";
import { NoFormsModal } from "./no-forms-modal";
import { TabListStatus } from "./status";
import { TabListTable } from "./table";
import { TabListToolbar } from "./toolbar";

const filterSchema = z.string();
const statusesSchema = z.array(z.string());
const fuzzyFilter: FilterFn<TabRowValue> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: 2 });
  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

export type TabListContainerProps = {
  submissions: MatterSubmission[];
  matterSpecificForms: MatterSpecificForm[] | undefined;
  hasForms: boolean;
  loading: boolean;
  onView: (submission: MatterSubmission) => void;
  onViewForm: (form: MatterSpecificForm) => void;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
  onRefetch: () => void;
  onShare: (share: "regular" | "internal" | undefined) => void;
  onGenerateQRCode: (submission: MatterSubmission) => void;
  onCreateMatterSpecificForm: (options: {
    filePath: string;
    fileName: string;
    fileId: string;
  }) => Promise<void>;
};

export const TabListContainer = ({
  submissions,
  matterSpecificForms,
  hasForms,
  loading,
  onView,
  onViewForm,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
  onRefetch,
  onShare,
  onGenerateQRCode,
  onCreateMatterSpecificForm,
}: TabListContainerProps) => {
  const [statuses, setStatuses] = useStorage({
    defaultValue: [],
    key: "submission-list-statuses",
    schema: statusesSchema,
    storage: "session",
  });
  const [filterValue, setFilterValue] = useStorage({
    defaultValue: "",
    key: "submission-list-filter",
    schema: filterSchema,
    storage: "session",
  });
  const [showNoFormsModal, setShowNoFormsModal] = useState(false);
  const globalFilter: TableProps<TabRowValue>["globalFilter"] = {
    state: filterValue,
    onChange: setFilterValue,
    fn: fuzzyFilter,
  };
  const filteredSubmissions = submissions?.filter((s) => {
    if (!statuses.length) return true;

    const isIncluded = statuses.includes(s.status);
    return s.status === "draft"
      ? isIncluded &&
          s.rawSubmission.values?.communicationMethod === "internalUse"
      : isIncluded;
  });

  return (
    <div className="bg-neutral-50 min-w-screen min-h-screen">
      <div className="m-auto p-6 pb-[4rem] max-w-screen-maximum">
        <div className="flex flex-col w-full bg-white rounded">
          <TabListToolbar
            globalFilter={globalFilter}
            statuses={statuses as SubmissionStatus[]}
            setStatuses={setStatuses}
            onShare={(share) => {
              if (hasForms) {
                onShare(share);
              } else {
                setShowNoFormsModal(true);
              }
            }}
            onCreateMatterSpecificForm={onCreateMatterSpecificForm}
          />
          <TabListTable
            submissions={submissions || []}
            filteredSubmissions={filteredSubmissions || []}
            matterSpecificForms={matterSpecificForms || []}
            loading={loading}
            globalFilter={globalFilter}
            onView={onView}
            onViewForm={onViewForm}
            onCancel={onCancel}
            onDelete={onDelete}
            onRemind={onRemind}
            onEdit={onEdit}
            onGenerateQRCode={onGenerateQRCode}
          />
        </div>
      </div>
      <TabListStatus onRefetch={onRefetch} refetching={loading} />
      <NoFormsModal
        open={showNoFormsModal}
        onSubmit={() => {
          setShowNoFormsModal(false);
          onRefetch();
        }}
      />
    </div>
  );
};
