import styled from "@emotion/styled";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  documentAutomation,
  leadManagement,
  migration,
} from "@smart/bridge-images-dom";
import { ButtonLink } from "@smart/itops-components-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Card, FailureMessage, Illustrations } from "@smart/itops-ui-dom";
import {
  useLoadMatterFile,
  useToggleSlug,
  useUpdateAIUserFeedback,
} from "@smart/manage-gql-client-dom";

import { GeneratingFeedback } from "./generating-feedback";
import { FormLoadingStatus } from "../../create-form/creation-status";
import { GqlForm } from "../../types";

export type GeneratingFormProp = {
  title: string;
  loadingStatus: FormLoadingStatus;
  creationStatus: string | null | undefined;
  aiUserFeedback?: GqlForm["aiUserFeedback"] | null;
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  updating?: boolean;
  form?: GqlForm | null;
};

const messageText: Record<string, string | undefined> = {
  fileUploaded: "Uploading your file...",
  processingFile: "Processing file...",
  "failedToGenerateQuestions-fileEncrypted": "Processing file...",
  generatingQuestions: "Generating form...",
  savingQuestions: "Saving form...",
  duplicating: "Duplicating form...",
};

const illustrations = [migration, documentAutomation, leadManagement];

const defaultIllustrationIndex = 2;

const activeIllustrationIndex: Record<string, number | undefined> = {
  fileUploaded: 0,
  processingFile: 1,
  "failedToGenerateQuestions-fileEncrypted": 1,
  generatingQuestions: 2,
  savingQuestions: 2,
  duplicating: 2,
};

const StatusWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    180deg,
    var(--background) 0%,
    var(--background) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 0 auto;
  padding: 4rem 0;

  display: flex;
  flex-flow: column;
  align-items: center;

  .generating-form-contents {
    flex: 1;
    padding: 1rem;
  }

  .generating-form-active-text {
    font-weight: bold;
  }

  .source-file {
    font-size: ${(props) => props.theme.fontSize.base};

    button {
      padding: 0;
    }
  }

  .close-card {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  h4 {
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 400;
    margin: 0.8rem 0;
  }
`;

export const GeneratingForm = ({
  title,
  loadingStatus,
  creationStatus,
  aiUserFeedback,
  updateAIUserFeedback,
  updating,
  form,
}: GeneratingFormProp) => {
  const { aiUserFeedback: feedbackFeatureFlag } = useFeatureFlags();
  const [updateSlug] = useToggleSlug();
  const { matters, files } = useSmokeballApp();
  const fileId = aiUserFeedback?.sourceFileIds?.[0] || "";
  const matterId = matters?.current?.id || "";
  const sourceFile = useLoadMatterFile(
    { matterId, fileId },
    { skip: !matterId || !fileId },
  );

  if (
    (loadingStatus === "created" || !loadingStatus) &&
    (!aiUserFeedback ||
      aiUserFeedback?.status ===
        (feedbackFeatureFlag ? "reviewed" : "completed"))
  )
    return null;

  const activeIllustration =
    creationStatus || aiUserFeedback
      ? (creationStatus && activeIllustrationIndex[creationStatus]) ||
        defaultIllustrationIndex
      : undefined;
  const activeText =
    aiUserFeedback?.statusText ||
    (creationStatus && messageText[creationStatus]) ||
    messageText.generatingQuestions;

  return (
    <StatusWrapper data-testid="generating-form-status">
      <Card
        breakPoint="readable"
        flow="row"
        align="center"
        loading={
          loadingStatus === "checking" ||
          aiUserFeedback?.status === "generating"
        }
      >
        <Illustrations
          illustrations={illustrations}
          active={activeIllustration}
        />
        <div className="generating-form-contents">
          <h2 className="generating-form-active-text">{activeText}</h2>
          {sourceFile.result && (
            <div className="source-file">
              <ButtonLink
                type="button"
                onClick={() =>
                  sourceFile.result?.id &&
                  files?.open({ id: sourceFile.result.id })
                }
              >
                {sourceFile.result.name}
                {sourceFile.result.fileExtension}
              </ButtonLink>
            </div>
          )}
          {loadingStatus !== "checking" && (
            <GeneratingFeedback
              title={title}
              aiUserFeedback={aiUserFeedback}
              updateAIUserFeedback={updateAIUserFeedback}
              updating={updating}
              updateSlug={updateSlug}
              form={form}
            />
          )}
          {loadingStatus === "fileEncrypted" && (
            <FailureMessage
              action="generate form"
              textOverride="Failed to generate questions with password protected document."
            />
          )}
          {loadingStatus === "timedOut" && (
            <FailureMessage
              action="generate form"
              textOverride="This is taking a long time. Please close the window and try again."
            />
          )}
        </div>
      </Card>
    </StatusWrapper>
  );
};
