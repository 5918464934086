import { FC } from "react";

import { FormDetails } from "./details";
import { FormMatterTypes } from "./matter-types";
import { FormQuestions } from "./questions";
import { FormSettings } from "./settings";
import { FormSubmissions } from "./submissions";
import { FormTabId, FormTabProps } from "./types";
import { FormDisplayMode } from "../types";

export const formTabTitles: Record<FormTabId<FormDisplayMode>, string> = {
  details: "Details",
  submissions: "Submissions",
  questions: "Questions",
  mattertypes: "Matter Types",
  settings: "Settings",
};

export const formTabDisplay: Record<
  FormTabId<FormDisplayMode>,
  FC<FormTabProps>
> = {
  details: FormDetails,
  submissions: FormSubmissions,
  questions: FormQuestions,
  mattertypes: FormMatterTypes,
  settings: FormSettings,
};

export * from "./types";
