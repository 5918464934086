import styled from "@emotion/styled";
import { transparentize } from "polished";

import { globalTeamUri } from "@smart/bridge-types-basic";
import { Avatar } from "@smart/itops-components-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { Slug } from "./slug";
import { FormTags } from "./tags";
import { FormDisplayMode, GqlForm } from "./types";

const Container = styled.div`
  position: relative;

  .actions {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
  }
`;

const FormItemWrapper = styled.button`
  display: grid;
  grid-template-columns: auto 12rem;
  grid-template-areas: "details updated";
  align-items: center;

  background: none;
  border: 0;
  text-align: left;
  width: 100%;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0.4rem;

  border-bottom: 1px dotted ${(props) => props.theme.palette.disabled.base};
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      transparentize(0.9, props.theme.palette.primary.accent)};
  }

  .details {
    grid-area: "details";
    padding: 1rem 0;

    .title {
      font-size: ${(props) => props.theme.fontSize.base};
      margin: 1rem 0 0;
    }
  }

  .updated {
    grid-area: "updated";

    display: flex;
    flex-flow: column;
    align-items: center;

    .time {
      font-size: ${(props) => props.theme.fontSize.xSmall};
      margin: 0;
    }
  }
`;

type FormItemProps = {
  form: GqlForm;
  mode: FormDisplayMode;
  select: (uri: string) => void;
};

const FormItem = ({ form, select }: FormItemProps) => {
  const { permission } = useUser();
  const canUpdate = permission?.can("update", {
    type: "slug",
    value: {
      uri: form.slug?.uri || "",
      formUri: form.uri,
      active: form.slug?.values.active || false,
    },
    parentForm: {
      uri: form.uri,
      teamUri: form.values.teamUri,
      active: form.values.active ?? undefined,
    },
  });

  return (
    <Container>
      <FormItemWrapper onClick={() => select(form.uri)}>
        <div className="details">
          <FormTags form={form} />
          <p className="title">{form.values.title}</p>
        </div>
        {form.values.teamUri !== globalTeamUri && (
          <div className="updated">
            <Avatar {...form.values.updatedBy} />
          </div>
        )}
      </FormItemWrapper>
      {form.values.teamUri === globalTeamUri && canUpdate && (
        <div className="actions">
          <Slug formUri={form.uri} active={form.slug?.values.active || false} />
        </div>
      )}
    </Container>
  );
};

export { FormItem, FormItemProps };
