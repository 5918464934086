import { FormNS } from "@smart/bridge-types-basic";
import { CloseButton, Page } from "@smart/itops-components-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

import { EditForm } from "../components";

const FormCreate = () => {
  const { actingTeam } = useManageFormsContext();

  return (
    <Page text="Create a Form" right={<CloseButton />}>
      <EditForm
        locators={
          actingTeam
            ? { uri: FormNS.generateUri(), teamUri: actingTeam.uri }
            : { uri: FormNS.generateUri() }
        }
        defaultValues={{
          title: "",
          response: "",
        }}
        submit="Create"
        cancel="Cancel"
      />
    </Page>
  );
};

export { FormCreate };
