import styled from "@emotion/styled";

import { buildMessageTemplate } from "@smart/bridge-templates-basic";
import { extractPrefixedTeamId } from "@smart/bridge-types-basic";
import { List, useColumns } from "@smart/itops-smokeball-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { buildDisplayName, toSubdomain } from "@smart/itops-utils-basic";
import { Toast } from "@smart/manage-hooks-dom";

import { Staff as StaffCell } from "./cells";
import { EmbedType } from "./embed-options";
import { LinkTableActions } from "./link-table-actions";
import { LinkItemType, Staff, SubmissionLink } from "./types";

const LinksTableWrapper = styled.div`
  padding-top: 1rem;
`;

type LinksTableProps = {
  links: SubmissionLink[] | undefined;
  forms: { uri: string; values: { title: string } }[];
  staff: Staff[];
  user?: Staff;
  loading?: boolean;
  onEdit: (linkHash: SubmissionLink) => void;
  onDelete: (linkHash: SubmissionLink) => void;
  onCopy: ({ code, toast }: { code: string; toast: Toast }) => Promise<void>;
  onGenerateQRCode: (link: string) => void;
};

export const LinksTable = ({
  links,
  loading,
  forms,
  staff,
  user,
  onEdit,
  onDelete,
  onCopy,
  onGenerateQRCode,
}: LinksTableProps) => {
  const onCopyAction = (linkHash: SubmissionLink, type: EmbedType) => {
    const link = toSubdomain(undefined, `/create/${extractId(linkHash.uri)}`);

    onCopy({
      code:
        type === "url"
          ? link
          : buildMessageTemplate({
              link,
              format: "embed",
              category: "lead",
              matterType: { name: "" },
              form: { title: "" },
              embedOption: type,
            }).html,
      toast: {
        text: `${type === "url" ? "Link" : "Code"} copied and ready to be used`,
      },
    }).catch(console.error);
  };

  const onGenerateQRCodeAction = (linkHash: SubmissionLink) => {
    const link = toSubdomain(undefined, `/create/${extractId(linkHash.uri)}`);
    onGenerateQRCode(link);
  };

  const columns = useColumns<LinkItemType>((c) => [
    c.accessor("formTitle", {
      header: "Name",
      size: 200,
      meta: { variableWidth: true, showTooltip: true },
    }),
    c.accessor("staff", {
      header: "Staff",
      size: 200,
      cell: StaffCell,
    }),
    c.display({
      id: "actions",
      meta: { action: true },
      size: 65,
      cell: LinkTableActions({
        onEdit,
        onDelete,
        onCopy: (linkHash, options) =>
          onCopyAction(linkHash, options?.copyType || "url"),
        onGenerateQRCode: onGenerateQRCodeAction,
      }),
    }),
  ]);

  const linksData =
    links
      ?.map((link) => {
        const formTitle =
          forms.find((form) => form.uri === link.formUri)?.values.title || "";
        const availableStaff = staff.find((s) => s.userId === user?.userId)
          ? staff
          : [...staff, user];
        const notifiedStaff = availableStaff.filter(
          (s) =>
            s &&
            (link.notifiedUserUris || [link.userUri])
              ?.map((uri) => extractPrefixedTeamId(uri, "sb").id)
              .includes(s.userId),
        );
        return {
          formTitle,
          staff: notifiedStaff
            .map((s) => buildDisplayName(s, s!.userId))
            .join(", "),
          link,
        };
      })
      .filter((l) => l.formTitle) || [];

  return (
    <LinksTableWrapper>
      <List
        columns={columns}
        data={linksData.sort((a, b) => a.formTitle.localeCompare(b.formTitle))}
        loading={loading}
        columnResizing={false}
        small
      />
    </LinksTableWrapper>
  );
};
