import { FormEvent, useState } from "react";

import { useStatus } from "@smart/itops-hooks-dom";
import {
  useAddFormMatterType,
  useUpdateField,
  useUpdateForm,
  useUpdateGroup,
  useUpdateSection,
} from "@smart/manage-gql-client-dom";

import { useImportProcess } from "./process";
import { ImportedForm } from "./schema";

export type ImportUpdateProps = ReturnType<typeof useImportProcess> & {
  importedForm: ImportedForm;
  teamUri?: string;
};

export const useImportUpdate = ({
  importedForm,
  matterTypeResult,
  fieldResult,
  teamUri,
}: ImportUpdateProps) => {
  const [status, setStatus] = useStatus();
  const [result, setResult] = useState<{
    uri: string | undefined;
    form: unknown | undefined;
    matterTypes: (unknown | undefined)[];
    sections: (unknown | undefined)[];
    fields: (unknown | undefined)[];
    groups: (unknown | undefined)[];
  }>();

  const [updateForm] = useUpdateForm();
  const [addFormMatterType] = useAddFormMatterType();
  const [updateSection] = useUpdateSection();
  const [updateField] = useUpdateField();
  const [updateGroup] = useUpdateGroup();

  return {
    status,
    result,
    onSubmit: async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!matterTypeResult || !fieldResult) return;

      setStatus("loading");
      let uri;
      let form;
      try {
        const { data } = await updateForm({
          variables: {
            uri: importedForm.form.uri,
            teamUri,
            fields: {
              title: importedForm.form.title,
              category: importedForm.form.category,
              response: importedForm.form.response,
            },
          },
        });
        uri = data?.updateForm.uri;
      } catch (formError) {
        form = formError;
      }

      const matterTypes = await Promise.allSettled(
        importedForm.form.matterTypes?.map((matterType) =>
          addFormMatterType({
            variables: { formUri: importedForm.form.uri, matterType },
          }),
        ) || [],
      );

      const sections = await Promise.allSettled(
        importedForm.sections.map((section) =>
          updateSection({
            variables: {
              uri: section.uri,
              formUri: section.formUri,
              order: section.order,
              fields: {
                title: section.title,
                links: section.links,
              },
            },
          }),
        ),
      );

      const groups = await Promise.allSettled(
        importedForm.groups.map((group) =>
          updateGroup({
            variables: {
              uri: group.uri,
              formUri: group.formUri,
              sectionUri: group.sectionUri,
              order: group.order,
              fields: {
                label: group.label,
                allowedRepeatable: group.allowedRepeatable,
                layout: group.layout,
                field: group.field,
                links: group.links,
                repeatable: group.repeatable,
                type: group.type,
                hint: group.hint,
              },
            },
          }),
        ),
      );

      const fields = await Promise.allSettled(
        importedForm.fields.map((field) => {
          const thisField = fieldResult[field.uri];

          return updateField({
            variables: {
              uri: field.uri,
              formUri: field.formUri,
              sectionUri: field.sectionUri,
              groupUri: field.groupUri,
              order: field.order,
              fields: {
                label: field.label,
                hint: field.hint,
                type: field.type,
                mandatory: field.mandatory,
                options: field.options,
                allowCustomResponse: field.allowCustomResponse || undefined,
                links: field.links,
                layout: thisField?.layout
                  ? {
                      id: thisField.layout.id,
                      name: thisField.layout.name,
                      providerId: thisField.layout.providerId,
                    }
                  : undefined,
                field: thisField?.field
                  ? {
                      name: thisField.field.name,
                      type: thisField.field.type,
                      possibleValues:
                        thisField.field.possibleValues || undefined,
                    }
                  : undefined,
              },
            },
          });
        }),
      );

      setStatus("success");
      setResult({
        uri,
        form,
        matterTypes: matterTypes.map((matterType) =>
          matterType.status === "rejected" ? matterType.reason : undefined,
        ),
        sections: sections.map((section) =>
          section.status === "rejected" ? section.reason : undefined,
        ),
        fields: fields.map((field) =>
          field.status === "rejected" ? field.reason : undefined,
        ),
        groups: groups.map((group) =>
          group.status === "rejected" ? group.reason : undefined,
        ),
      });
    },
  };
};
