import styled from "@emotion/styled";

import {
  FieldItem,
  GroupItem,
  intakeTheme,
  renderOrderedItems,
  SectionItem,
  SummaryField,
  SummaryGroup,
  SummarySection,
  useOrderedSections,
  useVisibility,
} from "@smart/bridge-intake-components-dom";
import { Loading, ThemeProvider } from "@smart/itops-components-dom";
import { fromEntries, jsonParseOrReturn } from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

const SubmissionViewResponsesWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  position: relative;

  background-color: ${(props) => props.theme.palette.background.highlight};

  .tab-view-responses-page {
    background-color: ${(props) => props.theme.palette.background.base};
    max-width: ${(props) => props.theme.breakPoints.readable}px;
    width: 90%;
    min-height: 100%;
    margin: 0 auto;
    padding: 1rem 5rem;
  }
`;

export type SubmissionViewResponsesProps = {
  loading?: boolean;
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  responseList: MatterSubmission["responses"];
};

export const SubmissionViewResponses = ({
  loading,
  sections,
  groups,
  fields,
  responseList,
}: SubmissionViewResponsesProps) => {
  const responses = fromEntries(
    responseList.map((r) => [r.fieldUri, jsonParseOrReturn(r.value)]),
  );
  const visibility = useVisibility({ sections, groups, fields, responses });
  const orderedSections = useOrderedSections({
    sections,
    groups,
    fields,
    visibility,
  });

  return (
    <SubmissionViewResponsesWrapper>
      <div className="tab-view-responses-page">
        {loading ? (
          <Loading />
        ) : (
          <ThemeProvider theme={intakeTheme}>
            {orderedSections.map(({ orderedItems, section }) => (
              <SummarySection key={section.uri} section={section}>
                {renderOrderedItems({
                  orderedItems,
                  responses,
                  renderGroup: (props) => <SummaryGroup {...props} />,
                  renderField: (props) => <SummaryField {...props} />,
                })}
              </SummarySection>
            ))}
          </ThemeProvider>
        )}
      </div>
    </SubmissionViewResponsesWrapper>
  );
};
