import { css, Global, Theme } from "@emotion/react";

const scrollCss = (theme: Theme) => css`
  ::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: ${theme?.palette?.background?.accent};
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme?.palette?.foreground?.highlight};
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme?.palette?.foreground?.accent};
  }

  body {
    color: ${theme?.scheme?.grey.r100};
  }
`;

const SmokeballStyles = () => <Global styles={[scrollCss]} />;

export { SmokeballStyles };
