import { ReactNode } from "react";

import { BaseInput, BaseInputProps } from "./base";
import { Label } from "../label";

export type TextFieldInputProps = Omit<
  BaseInputProps,
  "leftIcon" | "rightButtonIcon" | "onRightButtonClick" | "message"
> & { message?: string | ReactNode };

export const TextFieldInput = (props: TextFieldInputProps) => (
  <Label
    text={props.label}
    mandatory={props.mandatory}
    disabled={props.disabled}
    name={props.id}
    error={!!props.error}
    message={props.message}
  >
    <BaseInput
      {...props}
      message={typeof props.message === "string" ? props.message : undefined}
    />
  </Label>
);
