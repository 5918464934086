import { clsx } from "clsx";

type SpinnerProps = {
  size: "regular";
  color: "primary" | "secondary" | "inverted";
};

export const Spinner = ({ size, color }: SpinnerProps) => {
  const sizes = {
    regular: "w-[1.6rem] h-[1.6rem] min-w-[1.6rem] min-h-[1.6rem]",
  };
  const borderSizes = {
    regular: "border-[0.2rem]",
  };
  const colors = {
    primary: "border-blue-560/40 border-t-blue-560",
    secondary: "border-neutral-850/40 border-t-neutral-850",
    inverted: "border-white/40 border-t-white",
  };

  return (
    <div
      className={clsx(
        sizes[size],
        borderSizes[size],
        colors[color],
        "border-solid",
        "rounded-full",
        "animate-spin",
      )}
    />
  );
};
