import { SubmissionNS } from "@smart/bridge-types-basic";
import { GqlStatusOverlay, Overlay } from "@smart/itops-components-dom";
import { useParamsWithDefault } from "@smart/itops-hooks-dom";
import {
  mapToMatterSubmission,
  useLoadSubmission,
} from "@smart/manage-gql-client-dom";

import { Submission as SubmissionView } from "../../components/embedded";

const Submission = () => {
  const params = useParamsWithDefault((p) => ({
    id: p.id || "",
  }));
  const submission = useLoadSubmission({
    uri: SubmissionNS.generateUri(params.id),
  });

  if (!submission.result && !submission.loading)
    return <Overlay icon="warning" subHeading="Form not available" />;

  return (
    <>
      {submission.result && (
        <SubmissionView submission={mapToMatterSubmission(submission.result)} />
      )}
      <GqlStatusOverlay {...submission} />
    </>
  );
};

export { Submission };
