import { createContext, ReactNode, useContext } from "react";

import { SmokeballAppClient, useSDKClient, hasSDKClient } from "./client";
import { useLocalClient } from "./local";
import { UseSmokeballAppOptions } from "./types";

const unavailableContext = {
  hostType: undefined,
  auth: undefined,
  communicate: undefined,
  contacts: undefined,
  context: undefined,
  correspondence: undefined,
  layouts: undefined,
  matters: undefined,
  relationships: undefined,
  roles: undefined,
  host: undefined,
  staff: undefined,
  files: undefined,
};

export const setupSmokeballApp = (
  options?: UseSmokeballAppOptions,
): SmokeballAppClient => {
  if (hasSDKClient()) {
    return useSDKClient(options);
  }

  if (isLocalSB) {
    return useLocalClient(options);
  }

  return unavailableContext;
};

const SmokeballAppContext =
  createContext<SmokeballAppClient>(unavailableContext);

export const useSmokeballApp = () => useContext(SmokeballAppContext);

export type SmokeballProviderProps = UseSmokeballAppOptions & {
  children: ReactNode;
};

export const SmokeballProvider = ({
  children,
  observe,
}: SmokeballProviderProps) => {
  const value = setupSmokeballApp({ observe });

  return (
    <SmokeballAppContext.Provider value={value}>
      {children}
    </SmokeballAppContext.Provider>
  );
};

export {
  getLocalSmokeballAppContext,
  setLocalSmokeballAppContext,
  publishChange,
} from "./local";

export * from "./types";
