import styled from "@emotion/styled";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { Icon, IconButton, Label } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { useLoadFields, useLoadSections } from "@smart/manage-gql-client-dom";

import { CreateLink } from "./create-link";
import { LinkSection } from "./section";
import {
  MAX_FIELD_LINKS,
  getFieldValuesByUri,
  getInitialValue,
  getSectionOrder,
} from "./utils";
import { FormDisplayMode } from "../forms/types";
import { SectionSchema } from "../shared-schemas";

const Wrapper = styled.div`
  position: relative;

  .collapse {
    position: absolute;
    top: -2.6rem;
    right: 0;
  }
`;

const OpenIconButton = styled(IconButton)`
  margin-left: 0;
  color: inherit;
`;

type SectionLinksProps = {
  mode: FormDisplayMode;
  uri: string;
  formUri: string;
};

const SectionLinks = ({ mode, uri, formUri }: SectionLinksProps) => {
  const { control } = useFormContext<UpdateHookValues<SectionSchema>>();
  const sections = useLoadSections({ formUri });
  const fields = useLoadFields({ formUri });
  const links = useFieldArray({ control, name: "links" });
  const [open, setOpen] = useState(false);

  if (!sections.result?.length || !fields.result?.length) return null;

  const availableFields = fields.result?.filter(
    (f) =>
      f.values.type !== "info" &&
      f.values.sectionUri !== uri &&
      !links.fields.find((linkedField) => linkedField.fieldUri === f.uri),
  );

  const isOrderRight = (fieldUri: string): boolean => {
    if (!fieldUri) return true;

    const currentSectionOrder = getSectionOrder(uri, sections.result) || "";
    const linkedSectionOrder =
      getSectionOrder(
        getFieldValuesByUri(fieldUri, fields.result)?.sectionUri,
        sections.result,
      ) || "";

    return currentSectionOrder >= linkedSectionOrder;
  };

  if (mode === "view" && links.fields.length === 0) return null;

  if (!open) {
    return (
      <OpenIconButton
        text="Conditions"
        name="angleRight"
        size={16}
        onClick={() => setOpen(true)}
      />
    );
  }

  return (
    <Wrapper>
      <Label name="conditions" text="Conditions">
        {links.fields.map((fieldLink, i) => (
          <LinkSection
            mode={mode}
            key={fieldLink.id}
            index={i}
            fieldValues={getFieldValuesByUri(fieldLink.fieldUri, fields.result)}
            onRemove={() => links.remove(i)}
            orderWarning={!isOrderRight(fieldLink.fieldUri)}
          />
        ))}
        {mode === "edit" && links.fields.length < MAX_FIELD_LINKS && (
          <CreateLink
            availableFields={availableFields}
            sections={sections.result}
            create={(fieldUri) =>
              links.append({
                fieldUri,
                value: getInitialValue(
                  getFieldValuesByUri(fieldUri, fields.result),
                ),
                condition: "equalTo",
                hide: false,
              })
            }
          />
        )}
      </Label>
      <IconButton
        className="collapse"
        icon={<Icon name="angleRight" rotate={90} size={14} />}
        onClick={() => setOpen(false)}
      />
    </Wrapper>
  );
};

export { SectionLinks, SectionLinksProps };
