import { useMutation } from "@apollo/client";

import { ResponseNS } from "@smart/bridge-types-basic";
import { extractId } from "@smart/itops-types-basic";
import { v4 } from "@smart/itops-utils-basic";
import { Gql, mutationDocuments } from "@smart/manage-gql-operations-dom";

export const useUpdateResponse = () =>
  useMutation(mutationDocuments.updateResponse, {
    update: (cache, _, { variables }) => {
      if (variables) {
        const { submissionUri, fieldUri, value } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Submission", uri: submissionUri }),
          fields: {
            values: (existing) => ({ ...existing, syncStatus: "notSynced" }),
            responses: (existing = []) => {
              const uri = ResponseNS.generateUri(
                extractId(submissionUri),
                extractId(fieldUri),
              );
              const isExisting = !!existing.find(
                (ex: Gql.ResponseFieldsFragment) => ex.uri === uri,
              );
              const updatedAt = new Date().toISOString();

              if (isExisting)
                return existing.map((ex: Gql.ResponseFieldsFragment) =>
                  ex.uri === uri ? { ...ex, updatedAt, value } : ex,
                );

              const newResponse = {
                uri,
                fieldUri,
                value,
                syncStatus: "notSynced",
                existingItemIds: null,
                updatedAt,
                operationId: v4(),
                __typename: "Response",
              };
              return [...existing, newResponse];
            },
          },
        });
      }
    },
  });

export const useUpdateResponseSyncStatus = () =>
  useMutation(mutationDocuments.updateResponseSyncStatus, {
    update: (cache, _, { variables }) => {
      if (variables) {
        const { uri, syncStatus } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Response", uri }),
          fields: {
            values: (existing) => ({ ...existing, syncStatus }),
          },
        });
      }
    },
  });

export const useUpdateResponseExistingItemIds = () =>
  useMutation(mutationDocuments.updateResponseExistingItemIds);
