import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { useCallback, useState } from "react";

import { useIsMounted, useStatus } from "@smart/itops-hooks-dom";
import {
  buildErrorCode,
  createDownloadUrl,
  delay,
  downloadFileUrl,
} from "@smart/itops-utils-basic";
import { queryDocuments } from "@smart/manage-gql-operations-dom";

export type ExportFormProps = {
  formUri: string;
};

export const useExportForm = ({ formUri }: ExportFormProps) => {
  const { isMounted } = useIsMounted();
  const [status, setStatus] = useStatus();
  const [errorCode, setErrorCode] = useState<string>();

  const [formQuery] = useLazyQuery(queryDocuments.form);
  const [sectionQuery] = useLazyQuery(queryDocuments.sections);
  const [fieldQuery] = useLazyQuery(queryDocuments.fields);
  const [groupsQuery] = useLazyQuery(queryDocuments.groups);

  return {
    status,
    errorCode,
    triggerExport: useCallback(async () => {
      try {
        if (isMounted()) setStatus("loading");
        const [formQ, sectionsQ, fieldsQ, groupsQ] = await Promise.all([
          formQuery({ variables: { uri: formUri } }),
          sectionQuery({ variables: { formUri } }),
          fieldQuery({ variables: { formUri } }),
          groupsQuery({ variables: { formUri } }),
        ]);

        const form = formQ.data?.form?.values;

        const sections = sectionsQ.data?.sections
          .map((s) => s.values)
          .filter((s) => !s.deleted);
        const fields = fieldsQ.data?.fields
          .map((f) => f.values)
          .filter((f) => !f.deleted);
        const groups = groupsQ.data?.groups
          .map((g) => g.values)
          .filter((g) => !g.deleted);

        const fileName = form?.title
          .replace(/\s+/g, "-")
          .replace(/[^\wd-]+/, "")
          .toLocaleLowerCase();
        const contentsUrl = createDownloadUrl(
          { form, sections, groups, fields },
          (key, value) => {
            if (key === "__typename") return undefined;
            if (value === null) return undefined;
            return value;
          },
        );
        downloadFileUrl(
          contentsUrl,
          `${fileName}.${format(new Date(), "yyyy-MM-dd")}.json`,
        );

        if (isMounted()) setStatus("success");
        await delay(3000);
        if (isMounted()) setStatus("initial");
      } catch (error) {
        setStatus("error");
        setErrorCode(buildErrorCode(error));
      }
    }, [formUri]),
  };
};
