import { ApolloCache, useLazyQuery, useMutation } from "@apollo/client";

import { track } from "@smart/bridge-metrics-dom";
import { sortField } from "@smart/itops-utils-basic";
import {
  Gql,
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation, updateAfterDelete } from "../cache";

const updateLinksAfterFieldDelete =
  <D extends Gql.FieldsQuery & Gql.GroupsQuery & Gql.SectionsQuery>({
    cache,
    formUri,
    deletedFieldUri,
  }: {
    cache: ApolloCache<any>;
    formUri: string;
    deletedFieldUri: string;
  }) =>
  (__typename: string, queryKey: "groups" | "sections" | "fields") => {
    const queryFields = cache.readQuery({
      query: queryDocuments[queryKey],
      variables: {
        formUri,
      },
    }) as D;

    const linkedItems = queryFields[queryKey].filter(
      (item) =>
        !item.values.deleted &&
        item.uri !== deletedFieldUri &&
        !!item.values.links?.find((l) => l.fieldUri === deletedFieldUri),
    );

    linkedItems.forEach(({ uri }) =>
      cache.modify({
        id: cache.identify({ __typename, uri }),
        fields: {
          values: (ex) => ({
            ...ex,
            links: ex.links?.filter((l: any) => l.fieldUri !== deletedFieldUri),
          }),
        },
      }),
    );
  };

export const useDeleteField = () =>
  useMutation(mutationDocuments.deleteField, {
    optimisticResponse: optimisticOperation("deleteField"),
    update: (cache, { data }, { variables }) => {
      if (variables) {
        const { uri: deletedFieldUri } = variables;

        const updateLinksFn = updateLinksAfterFieldDelete({
          cache,
          formUri: variables?.formUri || "",
          deletedFieldUri,
        });

        updateLinksFn("Field", "fields");
        updateLinksFn("Group", "groups");
        updateLinksFn("Section", "sections");
      }

      updateAfterDelete("deleteField", "Field")(cache, { data }, { variables });
    },
    refetchQueries: [queryDocuments.fields],
  });

export const useUpdateField = () =>
  useMutation(mutationDocuments.updateField, {
    onCompleted: ({ updateField }) => {
      track("Field Updated", {
        fieldUri: updateField.uri,
        sectionUri: updateField.values.sectionUri,
        formUri: updateField.values.formUri,
        type: updateField.values.type,
      });
    },
    optimisticResponse: (input): Gql.UpdateFieldMutation => ({
      __typename: "Mutation",
      updateField: {
        __typename: "Field",
        uri: input.uri,
        values: {
          __typename: "FieldValues",
          uri: input.uri,
          sectionUri: input.sectionUri,
          formUri: input.formUri,
          groupUri: input.groupUri || null,
          order: input.order,
          type: input.fields.type,
          label: input.fields.label,
          description: input.fields.description || "",
          hint: input.fields.hint,
          options: input.fields.options.map((option) => ({
            __typename: "FieldOption",
            label: option.label,
            value: option.value,
          })),
          mandatory: input.fields.mandatory,
          allowCopyFromFieldUri: input.fields.allowCopyFromFieldUri || null,
          allowCustomResponse: input.fields.allowCustomResponse || null,
          layout: input.fields.layout
            ? {
                __typename: "MatterLayout",
                id: input.fields.layout.id,
                name: input.fields.layout.name,
                providerId: input.fields.layout.providerId,
                displayName: input.fields.layout.parentName
                  ? `${input.fields.layout.parentName}/${input.fields.layout.name}`
                  : input.fields.layout.name,
                parentId: input.fields.layout.parentId || null,
                parentName: input.fields.layout.parentName || null,
                parentProviderId: input.fields.layout.parentProviderId || null,
              }
            : null,
          field: input.fields.field
            ? {
                __typename: "MatterField",
                name: input.fields.field.name,
                type: input.fields.field.type,
                possibleValues: input.fields.field.possibleValues || null,
                details: input.fields.field.name.includes("/")
                  ? input.fields.field.name.slice(
                      0,
                      input.fields.field.name.lastIndexOf("/"),
                    )
                  : "",
                displayName: input.fields.field.name.slice(
                  input.fields.field.name.lastIndexOf("/") + 1,
                ),
              }
            : null,
          links: (input.fields.links || []).map((link) => ({
            __typename: "FieldLink",
            condition: link.condition,
            fieldUri: link.fieldUri,
            value: link.value,
            hide: link.hide,
          })),
          availableStaffIds: input.fields.availableStaffIds || null,
          duration: input.fields.duration || null,
          availability: input.fields.availability
            ? input.fields.availability.map((a) => ({
                __typename: "Availability",
                day: a.day,
                fromTime: {
                  __typename: "TimeOfDay",
                  hour: a.fromTime.hour,
                  minute: a.fromTime.minute,
                },
                toTime: {
                  __typename: "TimeOfDay",
                  hour: a.toTime.hour,
                  minute: a.toTime.minute,
                },
                enabled: a.enabled,
              }))
            : null,
          timezone: input.fields.timezone || null,
          bufferTime: input.fields.bufferTime || null,
          minimumNotice: input.fields.minimumNotice || null,
          meetingType: input.fields.meetingType || null,
          payment: input.fields.payment
            ? {
                __typename: "Payment",
                amountInCents: input.fields.payment.amountInCents,
                bankAccount: input.fields.payment.bankAccount
                  ? {
                      __typename: "BankAccountIdentifier",
                      ...input.fields.payment.bankAccount,
                    }
                  : undefined,
              }
            : null,
          updatedAt: new Date().toISOString(),
          deleted: false,
          operationId: "",
        },
      },
    }),
    update: (cache, { data }, { variables }) => {
      if (data?.updateField && variables) {
        cache.updateQuery(
          {
            query: queryDocuments.fields,
            variables: {
              formUri: variables.formUri,
              sectionUri: variables.sectionUri,
            },
          },
          (ex): Gql.FieldsQuery | null => {
            const exists = ex?.fields.find(
              (s) => s.uri === data.updateField.uri,
            );
            if (exists) return ex;
            return {
              fields: [data.updateField, ...(ex?.fields || [])],
              __typename: "Query",
            };
          },
        );

        cache.updateQuery(
          {
            query: queryDocuments.fields,
            variables: {
              formUri: variables.formUri,
            },
          },
          (ex): Gql.FieldsQuery | null => {
            const exists = ex?.fields.find(
              (s) => s.uri === data.updateField.uri,
            );
            if (exists) return ex;
            return {
              fields: [data.updateField, ...(ex?.fields || [])],
              __typename: "Query",
            };
          },
        );
      }
    },
  });

export const useUpdateFieldOrder = (shouldRefetch: boolean = true) =>
  useMutation(mutationDocuments.updateFieldOrder, {
    optimisticResponse: optimisticOperation("updateFieldOrder"),
    update: (cache, _, { variables }) => {
      if (variables) {
        const { uri, sectionUri, order } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Field", uri }),
          fields: {
            values: (existing) => ({ ...existing, order }),
          },
        });

        cache.modify({
          id: cache.identify({ __typename: "Section", uri: sectionUri }),
          fields: {
            fields: (existing, { readField }) =>
              sortField(existing, {
                dir: "asc",
                key: (i) => readField("order", i),
              }),
          },
        });
      }
    },
    refetchQueries: shouldRefetch ? [queryDocuments.fields] : undefined,
  });

export const useLoadFields = useQueryFactory(queryDocuments.fields, {
  map: ({ fields }) =>
    sortField(
      fields.filter((f) => !f.values.deleted),
      { key: (f) => f.values.order, dir: "asc" },
    ),
});

export const useLazyLoadFields = () => {
  const [load] = useLazyQuery(queryDocuments.fields);

  return async (variables: { formUri: string; sectionUri?: string }) => {
    const result = await load({ variables });
    return result.data?.fields.filter((f) => !f.values.deleted);
  };
};
