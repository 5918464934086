import { TSection, TField, TGroup } from "@smart/bridge-resources-basic";
import {
  checkHasResponse,
  Country,
  FieldType,
  PaymentResponse,
  ResponseSyncStatus,
  UploadedFile,
} from "@smart/bridge-types-basic";
import {
  isElementOf,
  jsonParseOrReturn,
  logError,
  setNestedValue,
} from "@smart/itops-utils-basic";

import {
  convertLayoutAddressToSB,
  convertRoleAddressToSB,
} from "./address-helpers";
import { getMatchingContacts } from "./contact-helpers";
import {
  basicLayoutProviderId,
  checkboxLayoutType,
  consolidatedCheckboxesLayoutType,
  cuiLayoutId as getCuiLayoutId,
  defaultClientRoleId,
  matterTypeProviderId,
  roleProviderId,
  SBContactType,
  SBContact,
  MatchingContact,
  IntakeContact,
  createNewContactPlaceholder,
  clientSolicitorIds,
  LayoutField,
  IntakeMatterEvent,
} from "./types";
import {
  convertDateToSB,
  convertCurrencyToSB,
  convertPhoneNumberToSB,
  getContactInfo,
  getClientRoleName,
  buildLayoutFieldName,
  getLayoutFieldName,
} from "./utils";

export const convertRoleValueToSB = ({
  name,
  type,
  value,
  country,
}: {
  name: string;
  type: FieldType;
  value: any;
  country: Country;
}): any => {
  try {
    switch (type) {
      case "address": {
        if (!value) return value;
        return convertRoleAddressToSB(value, country);
      }
      case "phoneNumber":
        return convertPhoneNumberToSB(country, value);
      case "date":
        return convertDateToSB(value);
      case "currency":
        return convertCurrencyToSB(value);
      case "number":
        return value.toString();
      default:
        return value;
    }
  } catch (e) {
    console.warn("Converting role value failed");
    logError(e, { name, type, value });
  }
  return undefined;
};

export const convertLayoutValueToSB = ({
  layoutField: { name, type: layoutType, possibleValues },
  type,
  value,
  country,
}: {
  layoutField: NonNullable<TField["field"]>;
  type: FieldType;
  value: any;
  country: Country;
}): Record<string, any> => {
  try {
    switch (type) {
      case "address":
        return convertLayoutAddressToSB(name, value, country);
      case "phoneNumber":
        return { [name]: value?.number };
      case "date":
        return { [name]: convertDateToSB(value) };
      case "currency":
        return { [name]: convertCurrencyToSB(value) };
      case "number":
        return { [name]: value.toString() };
      case "choice":
      case "checkbox": {
        if (layoutType !== consolidatedCheckboxesLayoutType)
          return { [name]: value };

        const valueToUse = Array.isArray(value) ? value : [value];
        return possibleValues!.reduce(
          (aggr, possibleValue) => ({
            ...aggr,
            [`${name}/${possibleValue}`]: valueToUse.includes(possibleValue)
              ? "True"
              : "False",
          }),
          {},
        );
      }
      case "yesNo":
        if (layoutType === checkboxLayoutType)
          return { [name]: value === "yes" ? "true" : "false" };

        return { [name]: value };
      default:
        return { [name]: value };
    }
  } catch (e) {
    console.warn("Converting layout value failed");
    logError(e, { name, type, value });
  }

  return {};
};

export type ContactResponses = Record<SBContactType, Record<string, any>> & {
  hasRelation?: boolean;
  group?: Record<SBContactType, Record<string, any>>[];
  existingContactIds?: (string | undefined)[];
  isReviewed?: boolean;
};

export type RoleContactResponses = Record<string, ContactResponses>;

type ResponseUriMap = Record<string, Set<string>>;

export type GroupedResponses = {
  matterType: { name?: string; location?: string; referralType?: string };
  layouts: Record<string, Record<string, any>>;
  roles: RoleContactResponses;
  files: Record<string, UploadedFile[]>;
  layoutContacts: Record<
    string,
    (Partial<Record<SBContactType, Record<string, any>>> & {
      isReviewed?: boolean;
      existingContactId?: string;
    })[]
  >;
  relationships: Record<string, RoleContactResponses>;
  events: Record<string, IntakeMatterEvent | IntakeMatterEvent[]>;
  payments: Record<string, PaymentResponse>;
  responseUris: {
    files: ResponseUriMap;
    layouts: ResponseUriMap;
    roles: ResponseUriMap;
    layoutContacts: ResponseUriMap;
    relationships: ResponseUriMap;
    events: ResponseUriMap;
    payments: ResponseUriMap;
  };
};

type FieldResponse = {
  field: TField;
  value: any;
  updatedAt: number;
  group?: TGroup;
  uri: string;
  existingItemIds?: string;
  syncStatus?: ResponseSyncStatus;
};

type LayoutParams = {
  layoutUpdatedTime: Record<string, number>;
  layoutId: string;
  layoutValue: any;
};

const formatResponseValue = (response: FieldResponse) =>
  response.group && Array.isArray(response.value)
    ? response.value[0]
    : response.value;

const isLayoutUpdatable = (
  fieldResponse: FieldResponse,
  { layoutId, layoutUpdatedTime, layoutValue }: LayoutParams,
) =>
  !layoutUpdatedTime[`${layoutId}-${fieldResponse.field.field?.name}`] ||
  (fieldResponse.updatedAt >
    layoutUpdatedTime[`${layoutId}-${fieldResponse.field.field?.name}`] &&
  Array.isArray(layoutValue)
    ? layoutValue.some((v) => checkHasResponse(fieldResponse.field.type, v))
    : checkHasResponse(fieldResponse.field.type, layoutValue));

const getLayoutItemsToUpdate = ({
  fieldResponse,
  layoutId,
  layoutUpdatedTime,
  country,
  layoutFields,
}: {
  fieldResponse: FieldResponse;
  layoutId: string;
  layoutUpdatedTime: Record<string, number>;
  country: Country;
  layoutFields: LayoutField[];
}): Record<string, any> | null => {
  const isGroupResponse =
    !!fieldResponse.group && Array.isArray(fieldResponse.value);
  const originalLayoutFieldName = getLayoutFieldName(
    fieldResponse.field.field!,
    layoutFields,
    fieldResponse.field.type,
  );

  if (!originalLayoutFieldName) return null;

  if (
    !isLayoutUpdatable(fieldResponse, {
      layoutId,
      layoutUpdatedTime,
      layoutValue: fieldResponse.value,
    })
  )
    return null;

  const responseValueAsArray: any[] = isGroupResponse
    ? fieldResponse.value
    : [fieldResponse.value];
  const layoutItems: Record<string, any> = {};

  responseValueAsArray.forEach((value, index: number) => {
    const layoutFieldName = buildLayoutFieldName({
      name: originalLayoutFieldName,
      index,
    });
    if (!layoutItems[layoutFieldName]) {
      Object.assign(
        layoutItems,
        convertLayoutValueToSB({
          layoutField: {
            ...fieldResponse.field.field!,
            name: layoutFieldName,
          },
          type: fieldResponse.field.type,
          value,
          country,
        }),
      );
    }
  });

  return layoutItems;
};

const updateResponseUriMap = ({
  responseUris,
  map,
  key,
  uri,
}: {
  responseUris: GroupedResponses["responseUris"];
  map: keyof GroupedResponses["responseUris"];
  key: string;
  uri: string;
}) => {
  if (!responseUris[map]) {
    responseUris[map] = { [key]: new Set([uri]) };
  } else if (!responseUris[map][key]) {
    responseUris[map][key] = new Set([uri]);
  } else {
    responseUris[map][key].add(uri);
  }
};

const processLayoutContact = (
  response: FieldResponse,
  current: GroupedResponses["layoutContacts"],
  country: Country,
  responseUris: GroupedResponses["responseUris"],
) => {
  // Also skip "Role" field of "company/contact" as it is reserved for adding contact to a company
  if (
    response.group?.type !== "layoutContact" ||
    !response.field.groupUri ||
    !response.field.field ||
    response.group.field?.name === "company/contact"
  )
    return undefined; // return undefined to let function "groupReponse" process further

  const [contactType, key] = getContactInfo(response.field.field.name || "");
  if (contactType !== "company" && contactType !== "person") return undefined;

  current[response.field.groupUri] = current[response.field.groupUri] || [];

  const parsedExistingItemIds = jsonParseOrReturn(
    response.existingItemIds,
  ) as string[];
  const existingContactIds = Array.isArray(parsedExistingItemIds)
    ? parsedExistingItemIds
    : [];
  const responseAsArray = Array.isArray(response.value)
    ? response.value
    : [response.value];
  responseAsArray.forEach((v: any, index: number) => {
    if (!current[response.field.groupUri!][index]) {
      current[response.field.groupUri!].push({
        person: {},
        company: {},
      });
    }
    setNestedValue(
      current[response.field.groupUri!][index][contactType],
      key,
      convertRoleValueToSB({
        name: response.field.field!.name,
        type: response.field.type,
        value: v,
        country,
      }),
    );
    current[response.field.groupUri!][index].isReviewed =
      response.syncStatus === "reviewed";
    current[response.field.groupUri!][index].existingContactId =
      existingContactIds[index] !== createNewContactPlaceholder
        ? existingContactIds[index]
        : undefined;
  });

  updateResponseUriMap({
    responseUris,
    map: "layoutContacts",
    key: response.field.groupUri,
    uri: response.uri,
  });

  return current;
};

const processMatterTypeProvider = ({
  response,
  current,
}: {
  response: FieldResponse;
  current: GroupedResponses["matterType"];
}) => {
  if (!response.field.layout || !response.field.field) return current;

  const [group, name] = response.field.field.name.split("/");

  if (
    response.field.layout.providerId === matterTypeProviderId &&
    group === "matterType" &&
    isElementOf(["name", "location", "referralType"] as const)(name)
  ) {
    current[name] = response.value;
  }

  return current;
};

const processLayoutProvider = async ({
  response,
  current,
  updatedTime,
  country,
  responseUris,
  getLayoutFields,
}: {
  response: FieldResponse;
  current: GroupedResponses["layouts"];
  updatedTime: Record<string, number>;
  country: Country;
  responseUris: GroupedResponses["responseUris"];
  getLayoutFields: ({
    layoutId,
    providerId,
  }: {
    layoutId: string;
    providerId: string;
  }) => Promise<LayoutField[]>;
}) => {
  if (
    response.field.layout?.providerId === matterTypeProviderId ||
    !response.field.layout ||
    !response.field.field
  )
    return current;

  const cuiLayoutId = getCuiLayoutId(response.field.layout);
  const basicLayoutId =
    response.field.layout.providerId === basicLayoutProviderId
      ? response.field.layout.id
      : undefined;
  const layoutId = cuiLayoutId || basicLayoutId;

  if (!layoutId) return current;

  current[layoutId] = current[layoutId] || {};

  const layoutFields = await getLayoutFields({
    layoutId,
    providerId: response.field.layout.providerId,
  });

  const layoutItemsToUpdate = getLayoutItemsToUpdate({
    fieldResponse: response,
    layoutId,
    layoutUpdatedTime: updatedTime,
    country,
    layoutFields,
  });

  if (layoutItemsToUpdate) {
    Object.assign(current[layoutId], layoutItemsToUpdate);
    updatedTime[`${layoutId}-${response.field.field.name}`] =
      response.updatedAt;
  }

  updateResponseUriMap({
    responseUris,
    map: "layouts",
    key: layoutId,
    uri: response.uri,
  });

  return current;
};

const updateRoleContactResponses = ({
  response,
  contactResponses,
  updatedTime,
  country,
}: {
  response: FieldResponse;
  contactResponses: ContactResponses;
  updatedTime: Record<SBContactType, Record<string, number>>;
  country: Country;
}) => {
  if (!response.field.field) return contactResponses;

  const [contactType, key] = getContactInfo(response.field.field.name);

  if (response.group?.repeatable && Array.isArray(response.value)) {
    if (!contactResponses?.group) {
      contactResponses.group = [{ person: {}, company: {} }];
    }

    response.value.forEach((v, index) => {
      const currentRoleGroup = contactResponses.group!;
      if (currentRoleGroup.length === index) {
        currentRoleGroup.push({
          person: {},
          company: {},
        });
      }

      const contactInGroup = currentRoleGroup[index];
      setNestedValue(
        contactInGroup[contactType],
        key,
        convertRoleValueToSB({
          name: response.field.field!.name,
          type: response.field.type,
          value: v,
          country,
        }),
      );
    });
  } else {
    const roleValue = formatResponseValue(response);
    const isGroupContact = !!contactResponses.group;
    const isLatestResponse =
      !updatedTime[contactType][key] ||
      (response.updatedAt > updatedTime[contactType][key] &&
        checkHasResponse(response.field.type, roleValue));

    // If the role has multiple contacts, ignore individual layout
    if (!isGroupContact && isLatestResponse) {
      setNestedValue(
        contactResponses[contactType],
        key,
        convertRoleValueToSB({
          name: response.field.field.name,
          type: response.field.type,
          value: roleValue,
          country,
        }),
      );
      updatedTime[contactType][key] = response.updatedAt;
    }
  }

  contactResponses.hasRelation =
    response.group?.field?.name === "company/contact";
  contactResponses.isReviewed = response.syncStatus === "reviewed";

  if (
    response.existingItemIds &&
    !contactResponses.existingContactIds?.length
  ) {
    const parsed = jsonParseOrReturn(response.existingItemIds) as string[];
    contactResponses.existingContactIds = Array.isArray(parsed)
      ? parsed.map((id) =>
          id && id !== createNewContactPlaceholder ? id : undefined,
        )
      : undefined;
  }

  return contactResponses;
};

const processRoleProvider = ({
  response,
  current,
  updatedTime,
  country,
  checkSkip,
  roleIdOverride,
  responseUris,
}: {
  response: FieldResponse;
  current: GroupedResponses["roles"];
  updatedTime: Record<string, Record<SBContactType, Record<string, number>>>;
  country: Country;
  checkSkip?: (field: FieldResponse["field"]) => boolean;
  roleIdOverride?: string;
  responseUris: GroupedResponses["responseUris"];
}) => {
  if (
    response.field.layout?.providerId !== roleProviderId ||
    !response.field.field ||
    response.field.layout.parentId ||
    checkSkip?.(response.field)
  )
    return current;

  const roleId = roleIdOverride || response.field.layout!.id;

  current[roleId] = current[roleId] || {
    person: {},
    company: {},
  };
  updatedTime[roleId] = updatedTime[roleId] || {
    person: {},
    company: {},
  };

  current[roleId] = updateRoleContactResponses({
    response,
    contactResponses: current[roleId],
    updatedTime: updatedTime[roleId],
    country,
  });

  updateResponseUriMap({
    responseUris,
    map: "roles",
    key: roleId,
    uri: response.uri,
  });

  return current;
};

const processRelationshipRoleProvider = ({
  response,
  current,
  updatedTime,
  country,
  responseUris,
}: {
  response: FieldResponse;
  current: GroupedResponses["relationships"];
  updatedTime: Record<
    string,
    Record<string, Record<SBContactType, Record<string, number>>>
  >;
  country: Country;
  responseUris: GroupedResponses["responseUris"];
}) => {
  if (
    response.field.layout?.providerId !== roleProviderId ||
    !response.field.field ||
    !response.field.layout.parentId ||
    response.field.layout.parentProviderId !== roleProviderId
  )
    return current;

  const { id: relationshipRoleId, parentId: roleId } = response.field.layout;

  current[roleId] = current[roleId] || {
    [relationshipRoleId]: { person: {}, company: {} },
  };
  current[roleId][relationshipRoleId] = current[roleId][relationshipRoleId] || {
    person: {},
    company: {},
  };

  updatedTime[roleId] = updatedTime[roleId] || {
    [relationshipRoleId]: { person: {}, company: {} },
  };
  updatedTime[roleId][relationshipRoleId] = updatedTime[roleId][
    relationshipRoleId
  ] || { person: {}, company: {} };

  current[roleId][relationshipRoleId] = updateRoleContactResponses({
    response,
    contactResponses: current[roleId][relationshipRoleId],
    updatedTime: updatedTime[roleId][relationshipRoleId],
    country,
  });

  updateResponseUriMap({
    responseUris,
    map: "relationships",
    key: `${roleId}/${relationshipRoleId}`,
    uri: response.uri,
  });

  return current;
};

export const groupResponse = async ({
  isFormCategoryDifferent,
  formMatterTypeRepresentatives,
  sections,
  fieldResponses,
  country,
  getLayoutFields,
}: {
  isFormCategoryDifferent: boolean;
  formMatterTypeRepresentatives?: string[];
  sections: TSection[];
  fieldResponses: FieldResponse[];
  country: Country;
  getLayoutFields: ({
    layoutId,
    providerId,
  }: {
    layoutId: string;
    providerId: string;
  }) => Promise<LayoutField[]>;
}): Promise<GroupedResponses> => {
  const layoutUpdatedTime: Record<string, number> = {};
  const roleUpdatedTime: Record<
    string,
    Record<"person" | "company", Record<string, number>>
  > = {};
  const relationshipsUpdatedTime: Record<
    string,
    Record<string, Record<"person" | "company", Record<string, number>>>
  > = {};

  const grouped = await fieldResponses.reduce(
    async (agg, r) => {
      const resolvedAgg = await agg;
      if (!r.field) return resolvedAgg;

      const {
        matterType,
        layouts,
        relationships,
        roles,
        files,
        layoutContacts,
        events,
        payments,
        responseUris,
      } = resolvedAgg;

      if (r.field.type === "file") {
        // For backwards compatibility
        const uploadedFiles = (r.value as Omit<UploadedFile, "uploadStatus">)
          ?.downloadUrl
          ? [
              {
                ...(r.value as Omit<UploadedFile, "uploadStatus">),
                uploadStatus: "uploaded" as const,
              },
            ]
          : (r.value as UploadedFile[])
              ?.flat()
              .filter((f) => f?.uploadStatus === "uploaded");
        if (!uploadedFiles?.length) return resolvedAgg;

        updateResponseUriMap({
          responseUris,
          map: "files",
          key: r.field.uri,
          uri: r.uri,
        });

        return {
          ...resolvedAgg,
          files: {
            ...files,
            [r.field.uri]: uploadedFiles,
          },
        };
      }

      if (r.field.type === "appointment") {
        updateResponseUriMap({
          responseUris,
          map: "events",
          key: r.field.uri,
          uri: r.uri,
        });

        return {
          ...resolvedAgg,
          events: {
            ...events,
            [r.field.uri]: r.value,
          },
        };
      }

      if (r.field.type === "payment") {
        updateResponseUriMap({
          responseUris,
          map: "payments",
          key: r.field.uri,
          uri: r.uri,
        });

        return {
          ...resolvedAgg,
          payments: {
            ...payments,
            [r.field.uri]: r.value,
          },
        };
      }

      if (isFormCategoryDifferent) {
        const formClientRoleName = getClientRoleName(
          sections,
          fieldResponses.map(({ field }) => field),
          formMatterTypeRepresentatives,
        );

        return {
          ...resolvedAgg,
          roles: processRoleProvider({
            roleIdOverride:
              r.field.layout?.id === formClientRoleName
                ? defaultClientRoleId
                : undefined,
            checkSkip: ({ layout }) =>
              ![formClientRoleName, defaultClientRoleId].includes(layout?.id),
            response: r,
            current: roles,
            updatedTime: roleUpdatedTime,
            country,
            responseUris,
          }),
        };
      }

      const updatedLayoutContacts = processLayoutContact(
        r,
        layoutContacts,
        country,
        responseUris,
      );
      if (updatedLayoutContacts) {
        return {
          ...resolvedAgg,
          layoutContacts: updatedLayoutContacts,
        };
      }

      return {
        ...resolvedAgg,
        matterType: processMatterTypeProvider({
          response: r,
          current: matterType,
        }),
        layouts: await processLayoutProvider({
          response: r,
          current: layouts,
          updatedTime: layoutUpdatedTime,
          country,
          responseUris,
          getLayoutFields,
        }),
        roles: processRoleProvider({
          response: r,
          current: roles,
          updatedTime: roleUpdatedTime,
          country,
          responseUris,
        }),
        relationships: processRelationshipRoleProvider({
          response: r,
          current: relationships,
          updatedTime: relationshipsUpdatedTime,
          country,
          responseUris,
        }),
      };
    },
    Promise.resolve({
      matterType: {} as GroupedResponses["matterType"],
      layouts: {} as GroupedResponses["layouts"],
      roles: {} as GroupedResponses["roles"],
      files: {} as GroupedResponses["files"],
      layoutContacts: {} as GroupedResponses["layoutContacts"],
      relationships: {} as GroupedResponses["relationships"],
      events: {} as GroupedResponses["events"],
      payments: {} as GroupedResponses["payments"],
      responseUris: {
        files: {},
        roles: {},
        layouts: {},
        layoutContacts: {},
        relationships: {},
      } as GroupedResponses["responseUris"],
    }),
  );

  return grouped;
};

const combineMatchingContacts = (
  current: MatchingContact[],
  incoming: MatchingContact[],
) =>
  incoming.forEach((mc) => {
    if (!current.find((contact) => contact.id === mc.id)) {
      current.push(mc);
    }
  });

export const lookupMatchingContacts = async ({
  input,
  search,
}: {
  input: IntakeContact;
  search: (searchTerms: {
    names?: string[];
    emails?: string[];
    phones?: string[];
  }) => Promise<SBContact[]>;
}): Promise<MatchingContact[]> => {
  const { person, company } = input;
  if (!person && !company) return [];

  const wildcard = /^[*]+$/;
  const searchTermName =
    person?.firstName || person?.lastName
      ? `*${person.firstName || ""}*${person.lastName || ""}*`
      : `*${company?.name || ""}*`;
  const searchTermEmail = person?.email || company?.email;
  const searchTermPhone = person?.phone
    ? `${person?.phone?.areaCode || ""}${person?.phone?.number || ""}`
    : `${company?.phone?.areaCode || ""}${company?.phone?.number || ""}`;
  const searchTermCell = `${person?.cell?.areaCode || ""}${person?.cell?.number || ""}`;

  let possibleMatches: MatchingContact[] = [];
  let searchResult: SBContact[] = [];

  if (!searchTermName.match(wildcard) && searchTermEmail) {
    searchResult = await search({
      names: [searchTermName],
      emails: [searchTermEmail],
    });
    possibleMatches = getMatchingContacts(input, searchResult);
    const exactMatch = possibleMatches.find(
      (match) => match.matchType === "exact",
    );
    if (exactMatch) return [exactMatch];
  }

  if (!searchTermName.match(wildcard)) {
    searchResult = await search({
      names: [searchTermName],
    });
    combineMatchingContacts(
      possibleMatches,
      getMatchingContacts(input, searchResult),
    );
  }

  if (searchTermEmail) {
    searchResult = await search({
      emails: [searchTermEmail.trim()],
    });
    combineMatchingContacts(
      possibleMatches,
      getMatchingContacts(input, searchResult),
    );
  }

  if (searchTermPhone) {
    searchResult = await search({
      phones: [searchTermPhone.replaceAll(" ", "").replaceAll("-", "").trim()],
    });
    combineMatchingContacts(
      possibleMatches,
      getMatchingContacts(input, searchResult),
    );
  }

  if (searchTermCell) {
    searchResult = await search({
      phones: [searchTermCell.replaceAll(" ", "").replaceAll("-", "").trim()],
    });
    combineMatchingContacts(
      possibleMatches,
      getMatchingContacts(input, searchResult),
    );
  }

  return possibleMatches;
};

export const createFilterClientSolicitor =
  (clientRoleName: string | undefined) =>
  (fieldResponse: {
    field: {
      layout?: {
        id: string;
        parentId?: string;
      };
    };
  }) => {
    if (!clientRoleName) return true;

    const isClientSolicitor =
      fieldResponse.field.layout?.parentId === clientRoleName &&
      clientSolicitorIds.includes(fieldResponse.field.layout.id);
    return !isClientSolicitor;
  };
