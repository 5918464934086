import styled from "@emotion/styled";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { useRef, useState } from "react";
import { UseFieldArrayReturn, UseFormRegister } from "react-hook-form";

import { Icon, IconButton, Input, Label } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { fromEntries } from "@smart/itops-utils-basic";

import { DraggableOptionItem, PermittedOperations } from "./option";
import { FieldSchema, GroupSchema } from "../shared-schemas";

const OptionValueContainer = styled.div`
  display: flex;
  position: relative;

  .errorLabel {
    position: absolute;
    left: 0.8rem;
    top: 0.4rem;
    font-size: ${(props) => props.theme.fontSize.xSmall};
    color: ${(props) => props.theme.palette.danger.base};
  }

  .errorIcon {
    position: absolute;
    top: 2.6rem;
    right: 1rem;
    color: ${(props) => props.theme.palette.danger.base};
  }
`;

type FieldOptionsProps<T extends "options" | `fields.${number}.options`> = {
  options: T extends "options"
    ? UseFieldArrayReturn<UpdateHookValues<FieldSchema>, "options">
    : UseFieldArrayReturn<
        UpdateHookValues<GroupSchema>,
        `fields.${number}.options`
      >;
  register: T extends "options"
    ? UseFormRegister<UpdateHookValues<FieldSchema>>
    : UseFormRegister<UpdateHookValues<GroupSchema>>;
  permittedOperations?: PermittedOperations;
  registeredName?: T;
  className?: string;
};

const FieldOptions = <T extends "options" | `fields.${number}.options`>({
  options,
  register,
  permittedOperations = {},
  registeredName = "options" as T,
  className,
}: FieldOptionsProps<T>) => {
  const [invalidOptionIds, setInvalidOptionIds] = useState<string[]>([]);
  const localOptionValues = useRef(
    fromEntries(options.fields.map((f) => [f.id, f.value])),
  );

  const finalPermittedOperations = {
    add: true,
    remove: true,
    changeLabel: true,
    changeValue: true,
    changeOrder: true,
    ...permittedOperations,
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    options.swap(source.index, destination.index);
  };

  const isSameString = (existing: string, updated: string): boolean =>
    !!existing && existing.toLocaleLowerCase() === updated.toLocaleLowerCase();

  const isValueAlreadyExisted = (forOptionId: string): boolean =>
    !!Object.keys(localOptionValues.current).find(
      (id) =>
        isSameString(
          localOptionValues.current[id],
          localOptionValues.current[forOptionId],
        ) && id !== forOptionId,
    );

  const updateInvalidOptionIds = (currentOptionId: string) => {
    let updatedInvalidIds = [...invalidOptionIds];

    if (
      isValueAlreadyExisted(currentOptionId) &&
      !updatedInvalidIds.includes(currentOptionId)
    ) {
      updatedInvalidIds.push(currentOptionId);
    }

    updatedInvalidIds = updatedInvalidIds.filter((id) =>
      isValueAlreadyExisted(id),
    );

    setInvalidOptionIds(updatedInvalidIds);
  };

  const renderOption = (index: number) => (
    <>
      <Input
        {...(register as UseFormRegister<any>)(
          `${registeredName}.${index}.label`,
        )}
        data-testid={`${registeredName}.${index}.label`}
        placeholder="Label"
        disabled={!finalPermittedOperations.changeLabel}
      />
      <OptionValueContainer>
        {invalidOptionIds.includes(options.fields[index].id) && (
          <>
            <div className="errorLabel">Value already exists!</div>
            <Icon name="warning" className="errorIcon" />
          </>
        )}
        <Input
          {...(register as UseFormRegister<any>)(
            `${registeredName}.${index}.value`,
            {
              onChange: ({ currentTarget }) => {
                const currentOptionId = options.fields[index].id;

                localOptionValues.current[currentOptionId] =
                  currentTarget.value;
                updateInvalidOptionIds(currentOptionId);
              },
            },
          )}
          data-testid={`${registeredName}.${index}.value`}
          disabled={!finalPermittedOperations.changeValue}
          placeholder="Value"
        />
      </OptionValueContainer>
    </>
  );

  return (
    <Label className={className} name="options" text="Options">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="draggable-field-option-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {options.fields.map((o, i) => (
                <DraggableOptionItem
                  key={o.id}
                  id={o.id}
                  index={i}
                  onRemove={() => options.remove(i)}
                  render={renderOption}
                  permittedOperations={finalPermittedOperations}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {finalPermittedOperations.add && (
        <IconButton
          name="add"
          text="Add choice"
          size={16}
          onClick={() => options.append({ label: "", value: "" })}
        />
      )}
    </Label>
  );
};

export { FieldOptions, FieldOptionsProps };
