import { buildDisplayName, formatTime } from "@smart/itops-utils-basic";

import {
  GqlTracker,
  getHistoryEventType,
  historyEventTypeLabel,
} from "../view/helpers";

export type SubmissionHistoryProps = {
  trackers: (GqlTracker | null)[];
};

const submissionViewHistoryEvent = (tracker: GqlTracker | null) => {
  if (!tracker) return null;
  const eventType = getHistoryEventType(tracker);
  if (!eventType) return null;

  return (
    <div
      className="p-[0.8rem] rounded bg-neutral-20"
      key={tracker.uri}
      data-testid="history-event"
    >
      <h4 className="text-ui font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
        {historyEventTypeLabel[eventType]}
      </h4>
      {tracker.user && (
        <p className="text-caption text-neutral-600 overflow-hidden text-ellipsis whitespace-nowrap">
          by {buildDisplayName(tracker.user, "team member")}
        </p>
      )}
      {tracker.sentAt && (
        <p className="text-caption text-neutral-600 overflow-hidden text-ellipsis whitespace-nowrap">
          {formatTime(tracker.sentAt)}
        </p>
      )}
    </div>
  );
};

export const SubmissionHistory = ({ trackers }: SubmissionHistoryProps) => (
  <div className="w-[30rem] px-[3.2rem] py-[1.6rem] flex flex-col gap-small overflow-auto relative">
    <h2 className="text-paragraph font-semibold">History</h2>
    <div className="flex flex-col gap-small">
      {trackers.map(submissionViewHistoryEvent)}
    </div>
  </div>
);
