import styled from "@emotion/styled";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  appointmentDurations,
  bufferTimes,
  maxTimeOfDay,
  minTimeOfDay,
  minimumNoticesInMinute,
} from "@smart/bridge-intake-components-dom";
import {
  MeetingType,
  TimeOfDay,
  meetingTypeLabel,
  meetingTypeValue,
} from "@smart/bridge-types-basic";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Combobox, FieldGroup, FieldList } from "@smart/itops-ui-dom";
import {
  TimeZone,
  buildTimeZoneList,
  localTimeZoneCode,
  removeKeysRecursively,
} from "@smart/itops-utils-basic";

import { AvailabilityItem } from "./availability-item";
import { GqlFieldValues, OnUpdateField } from "../../types";
import { formatDuration } from "../../utils";

const FieldListContainer = styled(FieldList)`
  gap: 1rem;
  margin-bottom: 1rem;
`;

const SelectionFieldGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .select-duration {
    width: 18rem;
  }

  .select-buffer-time {
    width: 21rem;
  }

  .select-minimum-notice {
    width: 18rem;
  }

  .select-time-zone {
    width: 32rem;
  }

  .select-meeting-type {
    width: 22rem;
  }

  .meeting-type-field {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .info {
      margin-top: 0.6rem;
      font-size: ${(props) => props.theme.fontSize.small};
    }
  }
`;

type AppointmentProps = {
  onUpdateField: OnUpdateField;
  field: GqlFieldValues;
};

export const Appointment = ({
  field: fieldValues,
  onUpdateField,
}: AppointmentProps) => {
  const {
    availableStaffIds,
    availability,
    duration,
    bufferTime,
    minimumNotice,
    timezone,
    meetingType,
  } = fieldValues;

  const { staff } = useSmokeballApp();

  const allStaff = staff?.current?.filter((s) => s.enabled) || [];

  const buildFullname = ({
    firstName,
    middleName,
    lastName,
  }: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }) => [firstName, middleName, lastName].filter(Boolean).join(" ");
  const timeZoneOptions = buildTimeZoneList().map((tz) => ({
    ...tz,
    label: tz.label.split("/").pop() || "",
  }));
  const localTimeZone = timeZoneOptions.find(
    (tz) => tz.tzCode === localTimeZoneCode,
  );
  const timezoneValue = timeZoneOptions.find((tz) => tz.tzCode === timezone);

  const formMethods = useForm<{
    availableStaff: { id: string; fullName: string }[];
    duration: number;
    availability?:
      | {
          day: number;
          fromTime: TimeOfDay;
          toTime: TimeOfDay;
          enabled: boolean;
        }[]
      | null;
    bufferTime?: number | null;
    minimumNotice?: number | null;
    meetingType: MeetingType;
    timezone?: TimeZone;
  }>({
    defaultValues: {
      availableStaff: allStaff
        .filter((s) => availableStaffIds?.includes(s.id))
        .map((s) => ({
          id: s.id,
          fullName: buildFullname(s),
        })),
      duration: duration || 15,
      availability: availability?.map((a) =>
        removeKeysRecursively(a, ["__typename"]),
      ),
      bufferTime,
      minimumNotice,
      meetingType: meetingType || "inPerson",
      timezone: timezoneValue || localTimeZone,
    },
  });

  const { control, handleSubmit, watch } = formMethods;

  const submit = handleSubmit(async (values) => {
    await onUpdateField({
      field: fieldValues,
      updated: {
        availableStaffIds: values.availableStaff.map((s) => s.id),
        duration: values.duration,
        availability: values.availability || undefined,
        bufferTime: values.bufferTime || undefined,
        minimumNotice: values.minimumNotice || undefined,
        meetingType: values.meetingType,
        timezone: values.timezone?.tzCode || localTimeZoneCode,
      },
    });
  });

  const availabilityOptions = useFieldArray({ control, name: "availability" });
  const selectedMeetingType = watch("meetingType");

  return (
    <FormProvider {...formMethods}>
      <FieldListContainer size="emphasis">
        <Controller
          control={control}
          name="availableStaff"
          render={({ field, fieldState }) => (
            <FieldGroup
              id="availableStaff"
              label=""
              error={fieldState.error?.message}
            >
              <div data-testid="appointment-available-staff">
                <Combobox
                  id={field.name}
                  title="Available team members"
                  placeholder="Select team members"
                  options={allStaff.map((s) => ({
                    id: s.id,
                    fullName: buildFullname(s),
                  }))}
                  empty="No team members available"
                  getOptionKey={(o) => o.id}
                  getOptionLabel={(o) => o.fullName}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  multiple
                  selectAll
                />
              </div>
            </FieldGroup>
          )}
        />
        <SelectionFieldGroup>
          <Controller
            control={control}
            name="duration"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-duration">
                <Combobox
                  className="select-duration"
                  id={field.name}
                  tooltip="Meeting duration"
                  title="Duration"
                  options={appointmentDurations}
                  empty="No options"
                  getOptionLabel={(d) => `${d} minutes`}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  disableClearable
                  placeholder="Intervals"
                  icon={{ library: "fontastic", name: "clock" }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="bufferTime"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-buffer-time">
                <Combobox
                  className="select-buffer-time"
                  id={field.name}
                  tooltip="Add time before or after a booked appointment"
                  title="Buffer time"
                  options={bufferTimes}
                  empty="No options"
                  getOptionLabel={(d) => `${d} minutes`}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  placeholder="Buffer"
                  icon={{ library: "fontastic", name: "clock" }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="minimumNotice"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-minimum-notice">
                <Combobox
                  className="select-minimum-notice"
                  id={field.name}
                  tooltip="Set the minimum amount of notice that is required"
                  title="Minimum notice"
                  options={minimumNoticesInMinute}
                  empty="No options"
                  getOptionLabel={(d) => formatDuration(d)}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  placeholder="Min. notice"
                  icon={{ library: "fontastic", name: "clock" }}
                />
              </div>
            )}
          />
        </SelectionFieldGroup>
        <div id="availability" data-testid="appointment-availability">
          {availabilityOptions.fields.map((o, index) => (
            <AvailabilityItem
              key={o.id}
              dailyAvailability={o}
              onChange={async (item) => {
                availabilityOptions.update(index, {
                  ...item,
                  fromTime: item.fromTime || minTimeOfDay,
                  toTime: item.toTime || maxTimeOfDay,
                });
                await submit();
              }}
            />
          ))}
        </div>
        <SelectionFieldGroup>
          <Controller
            control={control}
            name="timezone"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-time-zone">
                <Combobox
                  className="select-time-zone"
                  id={field.name}
                  tooltip="Automatically detect and show the times in my client’s time zone based on my set timezone"
                  title="Timezone"
                  options={timeZoneOptions}
                  empty="No options"
                  getOptionLabel={(tz) => tz.label}
                  getOptionKey={(tz) => tz.tzCode}
                  isOptionEqualToValue={(o, v) => o.tzCode === v.tzCode}
                  groupBy={(tz) => tz.region}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  placeholder="Timezone"
                  icon={{ library: "fontastic", name: "clock" }}
                  size="emphasis"
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="meetingType"
            render={({ field, fieldState }) => (
              <div
                className="meeting-type-field"
                data-testid="appointment-meeting-type"
              >
                <Combobox
                  className="select-meeting-type"
                  id={field.name}
                  tooltip="Type of meeting"
                  title="Meeting type"
                  options={meetingTypeValue}
                  empty="No options"
                  getOptionLabel={(v) => meetingTypeLabel[v]}
                  error={!!fieldState.error}
                  {...field}
                  onChange={async (_, v) => {
                    field.onChange(v);
                    await submit();
                  }}
                  disableClearable
                  placeholder="Meeting type"
                  icon={{ library: "fontastic", name: "clock" }}
                />
                {selectedMeetingType === "telephoneConsult" && (
                  <div className="info">
                    A mandatory phone number field should be added to the form
                  </div>
                )}
              </div>
            )}
          />
        </SelectionFieldGroup>
      </FieldListContainer>
    </FormProvider>
  );
};
