import styled from "@emotion/styled";

import {
  FieldComponentType,
  fieldFallbackLabel,
} from "@smart/bridge-intake-components-dom";
import { IconDetail } from "@smart/itops-icons-dom";
import { ContextMenu, UIKey } from "@smart/itops-ui-dom";

import { fieldColor, fieldIcon, fieldTemplates } from "../../constants";
import {
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
} from "../../types";
import { MappedFieldPathDisplay } from "../mapped-field-path-display";

const FieldTypeWrapper = styled.div<{ uiKey: UIKey }>`
  display: flex;
  align-items: center;
  gap: 1rem;

  .field-type-icon {
    background-color: ${(props) => props.theme.scheme[props.uiKey].r10};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.scheme[props.uiKey].r100};
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .field-type-text {
    color: ${(props) => props.theme.scheme.grey.r100};
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 600;
  }
`;

const FieldTypeDropdown = styled(ContextMenu)<{ uiKey: UIKey }>`
  .change-field-type-button {
    border: 0;
    padding: 0;
    gap: 1rem;

    svg {
      height: 3.6rem;
      width: 3.6rem;
      padding: 0.8rem;
      background-color: ${(props) => props.theme.scheme[props.uiKey].r10};
      border-radius: 0.5rem;
      color: ${(props) => props.theme.scheme[props.uiKey].r100};
    }
  }
`;

type FieldTypeIndicatorProps = {
  label: string;
  icon: IconDetail;
  uiKey: UIKey;
  layout?: GqlFieldValues["layout"];
  matterField?: GqlFieldValues["field"];
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onChangeFieldType: (newFieldType: FieldComponentType) => Promise<void>;
  excludingFieldTemplates: FieldTemplate[];
};

const FieldTypeIndicator = ({
  label,
  icon,
  uiKey,
  matterField,
  layout,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  groupMatterField,
  groupLayout,
  onChangeFieldType,
  excludingFieldTemplates,
}: FieldTypeIndicatorProps) => (
  <FieldTypeWrapper uiKey={uiKey}>
    <FieldTypeDropdown
      uiKey={uiKey}
      id="change-field-type"
      className="change-field-type"
      placement="bottom-start"
      fallbackPlacements={["bottom-start"]}
      button={{
        icon: { ...icon },
        right: <div className="field-type-text">{label}</div>,
        className: "change-field-type-button",
      }}
      items={fieldTemplates
        .filter(
          (ft) =>
            ft.type !== "group" &&
            !excludingFieldTemplates.find(({ type }) => type === ft.type),
        )
        .map((template) => ({
          text: fieldFallbackLabel[template.type],
          icon: {
            ...fieldIcon[template.type],
            color: fieldColor[template.type],
          },
          onClick: async () => onChangeFieldType(template.type),
        }))}
    />

    {matterField && layout && (
      <MappedFieldPathDisplay
        field={matterField}
        layout={layout}
        matterLayouts={matterLayouts}
        matterTypes={matterTypes}
        loadMatterFields={loadMatterFields}
        groupMatterField={groupMatterField}
        groupLayout={groupLayout}
      />
    )}
  </FieldTypeWrapper>
);

export { FieldTypeIndicator, FieldTypeIndicatorProps };
