import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import { createContext, useContext } from "react";

import { typePolicies } from "./policy";

const cachedClientContext = createContext<
  ApolloClient<NormalizedCacheObject> | undefined
>(undefined);

export const buildCachedClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: new HttpLink({ uri: `/cached/cachedGql`, useGETForQueries: true }),
    connectToDevTools: true,
  });

export const useCachedClient = () => useContext(cachedClientContext);

export const CachedApolloProvider = cachedClientContext.Provider;
