import { OrderedSection } from "@smart/bridge-intake-components-dom";
import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import {
  MatterSubmission,
  useLoadSubmission,
  useUpdateAIUserFeedback,
  useUpdateResponse,
  useUpdateSubmission,
  useUploadFiles,
} from "@smart/manage-gql-client-dom";
import { useLoadFormForDisplay } from "@smart/manage-hooks-dom";

import { EditResponses } from "./edit-responses";
import { ViewResponses } from "./view-responses";
import { SubmissionTabOptions } from "../../types";

type TabViewProps = {
  tab: SubmissionTabOptions;
  orderedSections: OrderedSection[];
  responses: Record<string, any>;
  submission: ReturnType<typeof useLoadSubmission>;
  updateResponse: ReturnType<typeof useUpdateResponse>[0];
  updateSubmission: ReturnType<typeof useUpdateSubmission>[0];
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  uploadFiles: ReturnType<typeof useUploadFiles>[0];
  displayProps: ReturnType<typeof useLoadFormForDisplay>;
  matterSubmission?: MatterSubmission;
  loading: boolean;
  navigateTo: (destination: SubmissionTabOptions | "home") => void;
  staffDetails: StaffDetails[];
};

export const Tab = ({
  tab,
  submission,
  updateResponse,
  updateSubmission,
  updateAIUserFeedback,
  uploadFiles,
  displayProps,
  matterSubmission,
  orderedSections,
  responses,
  loading,
  navigateTo,
  staffDetails,
}: TabViewProps) => {
  switch (tab) {
    case "edit":
      return (
        <EditResponses
          staffDetails={staffDetails}
          matterSubmission={matterSubmission}
          updateSubmission={updateSubmission}
          updateResponse={updateResponse}
          updateAIUserFeedback={updateAIUserFeedback}
          displayProps={displayProps}
          uploadFiles={uploadFiles}
          submission={submission}
          navigateTo={navigateTo}
          loading={loading}
        />
      );

    case "view":
      return (
        <ViewResponses
          matterSubmission={matterSubmission}
          orderedSections={orderedSections}
          responses={responses}
          loading={loading}
          trackersToDisplay={
            submission.result?.trackers?.filter(
              (tracker) =>
                tracker?.action !== "submission-created" ||
                tracker.method === "internalUse",
            ) || []
          }
        />
      );

    default:
      return null;
  }
};
