/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlStaffBusySlotsFieldsFragment = {
  __typename: "StaffBusySlots";
  id: string;
  startTime: string;
  endTime: string;
  timezone?: string | null;
};

export const StaffBusySlotsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "staffBusySlotsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StaffBusySlots" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlStaffBusySlotsFieldsFragment, unknown>;
