import { PropsWithChildren } from "react";
import { FieldValues, FormProvider } from "react-hook-form";

import {
  UpdateHookProps,
  UpdateHookResult,
  UpdateProps,
  useUpdate,
  Updatable,
  AfterSubmitAction,
} from "@smart/itops-hooks-dom";

import { Form, StyledForm } from "./standard";
import { ButtonRow, Button } from "../buttons";

export type Mode = "view" | "edit" | "create";

export type ValidationErrors = Record<string, { message?: string }>;

type UpdateFactoryProps<D, V extends Updatable, S extends FieldValues> = {
  factoryProps: UpdateHookProps<D, V, S>;
  form?: StyledForm;
  render: (
    props: Omit<UpdateHookResult<D, V, S>, "onCancel" | "onSubmit"> & {
      mode: Mode;
    },
  ) => JSX.Element;
};

type FormComponentProps = {
  submit?: string;
  cancel?: string;
  submitWithAction?: string;
  afterSubmitActionType?: AfterSubmitAction;
  mode?: Mode;
};

export type UpdateFormProps<
  D,
  V extends Updatable,
  S extends FieldValues,
> = PropsWithChildren<UpdateProps<D, V, S> & FormComponentProps>;

export const updateFormFactory =
  <D, V extends Updatable, S extends FieldValues>({
    factoryProps,
    form,
    render,
  }: UpdateFactoryProps<D, V, S>) =>
  ({
    mode = "edit",
    children,
    submit,
    submitWithAction,
    afterSubmitActionType,
    cancel,
    ...props
  }: UpdateFormProps<D, V, S>) => {
    const { onCancel, onSubmit, ...hookProps } = useUpdate({
      ...factoryProps,
      ...props,
    });
    const FormEl = form || Form;

    return (
      <FormProvider {...hookProps.form}>
        <FormEl onSubmit={onSubmit}>
          {render({ ...hookProps, mode })}
          {children}
          {(submit || cancel) && (
            <ButtonRow>
              {submitWithAction && afterSubmitActionType && (
                <Button name={afterSubmitActionType} type="submit">
                  {submitWithAction}
                </Button>
              )}
              {submit && <Button type="submit">{submit}</Button>}
              {cancel && (
                <Button type="button" onClick={onCancel} palette="danger">
                  {cancel}
                </Button>
              )}
            </ButtonRow>
          )}
        </FormEl>
      </FormProvider>
    );
  };
