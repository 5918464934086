import { useLoadSetting, useUpdateSetting } from "@smart/manage-gql-client-dom";

import { MessageContainer } from "./message-container";

const ClientMessages = () => {
  const setting = useLoadSetting({});
  const [update] = useUpdateSetting();

  return (
    <MessageContainer
      setting={setting.result?.defaultClientMessage || {}}
      update={async (defaultClientMessage) => {
        await update({
          variables: {
            fields: {
              defaultClientMessage,
            },
          },
        });
      }}
      loading={setting.loading}
    />
  );
};

export { ClientMessages };
