import { deleteButtonFactory } from "@smart/itops-components-dom";
import { useDeleteForm } from "@smart/manage-gql-client-dom";

const DeleteForm = deleteButtonFactory({
  text: "Delete Form",
  confirmText: "Are you sure?",
  mutation: useDeleteForm,
  next: () => `/forms`,
});

export { DeleteForm };
