import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { IconButton, Page } from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { useLoadTeamForms } from "@smart/manage-gql-client-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

import { FormList, SwitchFirm } from "../components";

const Forms = () => {
  const navigate = useNavigate();
  const { formImportExport } = useFeatureFlags();
  const { canActAsFirm, actingTeam, isActing } = useManageFormsContext();
  const forms = useLoadTeamForms({ teamUri: actingTeam?.uri });

  const create = () => navigate(`/forms/create`);

  return (
    <Page
      text={isActing ? "Firm forms" : "Your Forms"}
      right={
        <>
          {canActAsFirm && <SwitchFirm />}
          {formImportExport && (
            <IconButton
              name="upload"
              text="Import Form"
              onClick={() => navigate("/forms/import")}
            />
          )}
          <IconButton name="add" text="Create Form" onClick={create} />
        </>
      }
    >
      <FormList
        forms={forms.result}
        create={create}
        select={(uri) => navigate(`/forms/${extractId(uri)}`)}
        result={forms}
      />
    </Page>
  );
};

export { Forms };
