import {
  smokeballFull,
  smokeball,
  triconveyFull,
  triconvey,
  practiceevolveFull,
  practiceevolve,
  smart,
} from "@smart/bridge-images-dom";
import { isElementOf, entriesOf } from "@smart/itops-utils-basic";

export const providerKeys = [
  "Smokeball",
  "TriConvey",
  "PracticeEvolve",
] as const;

export type ProviderKey = (typeof providerKeys)[number];

export type ProviderFields = {
  logo: string;
  icon: string;
  label: string;
};

export type Provider<K extends ProviderKey> = ProviderFields & { key: K };

export const providerOptions: { [k in ProviderKey]: Provider<k> } = {
  Smokeball: {
    key: "Smokeball",
    logo: smokeballFull,
    icon: smokeball,
    label: "Smokeball",
  },
  TriConvey: {
    key: "TriConvey",
    logo: triconveyFull,
    icon: triconvey,
    label: "triConvey",
  },
  PracticeEvolve: {
    key: "PracticeEvolve",
    logo: practiceevolveFull,
    icon: practiceevolve,
    label: "Practice Evolve",
  },
};

export const fallbackProvider: ProviderFields = {
  logo: smart,
  icon: smart,
  label: "MatterIntake",
};

export const isProviderKey = isElementOf(providerKeys);

export function loadProvider(key: any, fallback: true): ProviderFields;
export function loadProvider(
  key: any,
  fallback: false,
): ProviderFields | undefined;
export function loadProvider(key: any): ProviderFields | undefined;
export function loadProvider(
  key: any,
  fallback?: boolean,
): ProviderFields | undefined {
  if (isProviderKey(key)) {
    return providerOptions[key];
  }
  return fallback ? fallbackProvider : undefined;
}

export const providerArray = entriesOf(providerOptions);
