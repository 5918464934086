import { isElementOf } from "@smart/itops-utils-basic";
import { Gql } from "@smart/manage-gql-operations-dom";

import { FormDisplayMode } from "../types";

export const formTabs = {
  view: [
    "details",
    "submissions",
    "questions",
    "mattertypes",
    "settings",
  ] as const,
  edit: [
    "details",
    "submissions",
    "questions",
    "mattertypes",
    "settings",
  ] as const,
  create: [
    "details",
    "submissions",
    "questions",
    "mattertypes",
    "settings",
  ] as const,
};
export type FormTabId<T extends FormDisplayMode> = (typeof formTabs)[T][number];

export type FormTabProps = {
  form: Gql.FormFieldsFragment;
  mode: FormDisplayMode;
};

export const isFormTab = (mode: FormDisplayMode, tab?: string) =>
  isElementOf(formTabs[mode])(tab);

export const defaultTab = "details";

export const defaultFormTab = (
  mode: FormDisplayMode,
  tab?: string,
): FormTabId<typeof mode> =>
  isFormTab(mode, tab) ? (tab as FormTabId<typeof mode>) : defaultTab;
