const chars = {
  min: "a",
  max: "z",
  special: ":",
};
const codes = {
  min: chars.min.charCodeAt(0),
  max: chars.max.charCodeAt(0),
  special: chars.special.charCodeAt(0),
};

const rankField = new RegExp(
  `^[${chars.min}-${chars.max}${chars.special}]*[${chars.min}-${chars.max}]$`,
);

export type RankInput = { after?: string; before?: string };

const numBetween = (start: number, end: number) =>
  start +
  (start === codes.min && end - start <= 1 ? 0 : Math.ceil((end - start) / 2));

const isBetween = (input: RankInput, output: string) =>
  output.length &&
  !output.endsWith(":") &&
  (!input.after || output > input.after) &&
  (!input.before || output < input.before);

export const charBetween = (afterNum: number, beforeNum?: number) => {
  if (beforeNum === codes.special || beforeNum === codes.min)
    return chars.special;

  if (beforeNum === afterNum + 1) return String.fromCharCode(afterNum);

  const checkedAfter = afterNum === codes.special ? codes.min : afterNum;
  const betweenNum = numBetween(checkedAfter, beforeNum || codes.max);
  return String.fromCharCode(betweenNum);
};

export const rankBetween = (input: RankInput): string => {
  const after = input.after || "";
  const before = input.before || "";

  if (after && !after.match(rankField))
    throw new Error(`invalid after field: ${after}`);
  if (before && !before.match(rankField))
    throw new Error(`invalid before field: ${before}`);
  if (after && before && after === before)
    throw new Error(`after cannot equal before: ${after}`);
  if (after && before && after > before)
    throw new Error(`out of order: ${after} > ${before}`);

  let i = 0;
  while (after[i] && after[i] === before[i]) {
    i += 1;
  }

  let output = after.slice(0, i);

  while (!isBetween({ after, before }, output)) {
    const afterNum = after.charCodeAt(i) || codes.min;
    const beforeNum = before.charCodeAt(i);
    const char = charBetween(afterNum, beforeNum);
    output = `${output}${char}`;

    i += 1;
  }

  return output;
};
