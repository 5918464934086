import { format, isValid } from "date-fns";

import {
  Country,
  FieldType,
  GroupType,
  LinkConditionType,
} from "@smart/bridge-types-basic";
import {
  entriesOf,
  fromEntries,
  getNestedValue,
  groupBy,
  isNullOrUndefined,
  prettyJsonStringify,
  resolveSettled,
  waitSettledThenThrow,
} from "@smart/itops-utils-basic";

import {
  convertLayoutAddressFromSB,
  convertRoleAddressFromSB,
  isEmptySBAddress,
} from "./address-helpers";
import {
  addressRegex,
  basicLayoutProviderId,
  checkboxesLayoutRegex,
  consolidatedCheckboxesLayoutType,
  cuiLayoutId,
  defaultClientRoleId,
  Field,
  matterTypeProviderId,
  roleProviderId,
  SBContact,
  Section,
} from "./types";
import {
  convertPhoneNumberFromSB,
  getClientRoleName,
  getContactInfo,
} from "./utils";

type TSBLayoutItem = {
  value?: string | undefined;
  key: string;
};

type TSBConsolidatedItem = { key: string; value?: string | object | undefined };

export const checkIfMatch = (fieldName: string | undefined, key: string) => {
  if (!fieldName) return false;

  /*
   With "key" we currently only support the "right-most" index. Which means indexes other than the right-most one must be 0.
  */
  const reverse = (keyString: string) =>
    keyString.split("/").reverse().join("/");

  const strippedKey = reverse(
    reverse(key)
      .replace(/\[[0-9]*\]/i, "")
      .replaceAll(/\[0*\]/g, ""),
  );
  const strippedFieldName = fieldName.replaceAll(/\[[0-9]*\]/g, "");

  return !strippedFieldName.startsWith("Matter/") &&
    strippedKey.startsWith("Matter/")
    ? `Matter/${strippedFieldName}` === strippedKey
    : strippedFieldName === strippedKey;
};

export const isIndexedField = (
  fieldName: string | undefined,
  parentIncluded?: boolean,
): boolean =>
  !!fieldName &&
  (parentIncluded ? /\[[0-9].*\]/g : /\[[0-9].*\]$/g).test(fieldName);

export const consolidateLayoutItems = (
  items: TSBLayoutItem[],
  consolidatedCheckboxKeys: Record<string, true>,
) => {
  const added: Record<
    string,
    { key: string; value: Record<string, string> | string[] }
  > = {};
  const consolidated: TSBConsolidatedItem[] = [];

  for (const item of items) {
    const addressMatch = item.key.match(addressRegex);

    if (addressMatch && addressMatch[1] && item.value) {
      let addressName = item.key.replace(addressMatch[1], "");
      if (!addressName.startsWith("Matter/"))
        addressName = `Matter/${addressName}`;

      const key = addressMatch[1].slice(1);
      const value = (added[addressName]?.value || {}) as Record<string, string>;
      value[key] = item.value;
      added[addressName] = {
        key: addressName,
        value,
      };
    } else if (consolidatedCheckboxKeys[item.key]) {
      const match = item.key.match(checkboxesLayoutRegex);
      if (match) {
        const name = item.key.replace(match[1], "");
        const key = match[1].slice(1);
        const value = (added[name]?.value || []) as string[];
        added[name] = {
          key: name,
          value: item.value === "True" ? [...value, key] : value,
        };
      }
      consolidated.push(item);
    } else {
      consolidated.push(item);
    }
  }
  consolidated.push(...Object.values(added));

  return consolidated;
};

export const convertRoleValueFromSB = (
  type: FieldType,
  value: string | Record<string, any>,
  country: Country,
) => {
  switch (type) {
    case "address": {
      if (typeof value === "string") return { formattedAddress: value };
      if (isEmptySBAddress(value)) return undefined;
      return convertRoleAddressFromSB(value, country);
    }
    case "phoneNumber":
      return convertPhoneNumberFromSB(country, value);
    case "date": {
      const date = new Date(value.toString());
      return isValid(date) ? format(date, "yyyy-MM-dd") : "";
    }
    case "currency":
      return { value, float: parseFloat(value.toString()) };
    case "number":
      return parseInt(value.toString(), 10);
    case "choice":
      return Array.isArray(value) ? value[0] : value;
    default:
      return value;
  }
};

export const convertLayoutValueFromSB = (
  type: FieldType,
  value: string | Record<string, any>,
  country: Country,
) => {
  switch (type) {
    case "address": {
      if (typeof value === "string") return { formattedAddress: value };
      if (isEmptySBAddress(value)) return undefined;
      return convertLayoutAddressFromSB(value, country);
    }
    case "phoneNumber":
      return convertPhoneNumberFromSB(country, value);
    case "date": {
      const date = new Date(value.toString());
      return isValid(date) ? format(date, "yyyy-MM-dd") : "";
    }
    case "currency":
      return { value, float: parseFloat(value.toString()) };
    case "number":
      return parseInt(value.toString(), 10);
    case "choice":
      return Array.isArray(value) ? value[0] : value;
    default:
      return value;
  }
};

type MatterType = {
  id: string;
  name: string;
  category: string;
  location: string;
};

type Matter = {
  id: string;
  referralType?: string;
};

export const convertMatterInfoValueFromSB = (
  name: string,
  matterType: MatterType,
  matter: Matter,
) => {
  switch (name) {
    case "matterType/name":
      return matterType.name;
    case "matterType/location":
      return matterType.location;
    case "matterType/referralType":
      return matter.referralType;
    default:
      return undefined;
  }
};

export type Layout = {
  itemId: string;
  values: TSBLayoutItem[];
  layoutDesign: { id: string };
};

type Group = {
  uri: string;
  repeatable?: boolean | null;
  type: GroupType;
  layout?: {
    providerId: string;
    id: string;
    parentId?: string | null;
    parentProviderId?: string | null;
  } | null;
  field?: { name: string; type: string } | null;
  links?:
    | { condition: LinkConditionType; fieldUri: string; value: string }[]
    | null;
};

export type Role = {
  name: string;
  isClient?: boolean;
  contactId?: string | null;
  relationshipRoleName?: string;
};

type LoggerFn = (...args: any[]) => Promise<any>;
type ErrorLoggerFn = (error: unknown, parent?: any) => Promise<any>;

type ConvertMatterFieldsOptions = {
  isFormCategoryDifferent: boolean;
  recipientContactId?: string;
  formMatterTypeRepresentatives?: string[];
  logger?: {
    fatal: ErrorLoggerFn;
    error: ErrorLoggerFn;
    warn: LoggerFn;
    info: LoggerFn;
    debug: LoggerFn;
  };
  country: Country;
  matterType: MatterType;
  matter: Matter;
  layouts: Layout[];
  sections: Section[];
  fields: Field[];
  roles: Role[];
  groups?: Group[];
  getContact: (contactId: string) => Promise<SBContact | undefined>;
  getLayoutContacts: (
    layoutItemId: string,
  ) => Promise<{ key: string; contact: SBContact }[]>;
  getContactRelation: (contactId: string) => Promise<SBContact[]>;
};

type Reponse = { fieldUri: string; value: any };

const getGroup = (
  field: Field,
  groups: Group[] | undefined,
): Group | undefined => groups?.find((g) => g.uri === field.groupUri);

// Rather than checking all of the provider fields here,
// we can just check if the field starts with "person/" or "company/"
const isLayoutContactField = (
  field: Field,
  groups: Group[] | undefined,
): boolean =>
  getGroup(field, groups)?.type === "layoutContact" &&
  (!!field.field?.name.startsWith("person/") ||
    !!field.field?.name.startsWith("company/"));

const addContactFieldResponse = ({
  field,
  contact,
  country,
  responses,
}: {
  field: Field;
  contact: SBContact;
  country: Country;
  responses: Reponse[];
}) => {
  const [contactType, key] = getContactInfo(field.field?.name || "");

  const value = contactType && key && getNestedValue(contact[contactType], key);
  if (value) {
    const responseValue = convertRoleValueFromSB(field.type, value, country);
    const existing = responses.find((r) => r.fieldUri === field.uri);

    if (existing) {
      existing.value = field.groupUri ? [responseValue] : responseValue;
    } else {
      responses.push({
        fieldUri: field.uri,
        value: field.groupUri ? [responseValue] : responseValue,
      });
    }
  }
};

const addMultipleContactsToResponse = ({
  field,
  groups,
  contacts,
  country,
  responses,
}: {
  field: Field;
  contacts: SBContact[];
  groups?: Group[];
  country: Country;
  responses: Reponse[];
}) => {
  if (contacts.length === 0) return;

  const group = getGroup(field, groups);

  // If the group is not repeatable, add only the first contact to responses
  if (!group?.repeatable) {
    addContactFieldResponse({
      field,
      contact: contacts[0],
      country,
      responses,
    });

    return;
  }

  const [contactType, key] = getContactInfo(field.field?.name || "");

  const responseValue = [];
  for (const contact of contacts) {
    const value =
      contactType && key && getNestedValue(contact[contactType], key);
    responseValue.push(
      isNullOrUndefined(value)
        ? null
        : convertRoleValueFromSB(
            field.type,
            value as string | Record<string, any>,
            country,
          ),
    );
  }

  if (responseValue.length > 0) {
    const existing = responses.find((r) => r.fieldUri === field.uri);
    if (existing && Array.isArray(existing.value)) {
      existing.value.push(...responseValue);
    } else {
      responses.push({
        fieldUri: field.uri,
        value: responseValue,
      });
    }
  }
};

const convertMatterInfo = ({
  input: { fields, matterType, matter },
  responses,
}: {
  input: Pick<ConvertMatterFieldsOptions, "fields" | "matterType" | "matter">;
  responses: Reponse[];
}) => {
  fields
    .filter((f) => f.layout?.providerId === matterTypeProviderId)
    .forEach((f) => {
      const value =
        f.field &&
        convertMatterInfoValueFromSB(f.field.name, matterType, matter);
      if (value) {
        responses.push({
          fieldUri: f.uri,
          value: f.groupUri ? [value] : value,
        });
      }
    });
};

const convertRoles = async ({
  input: {
    matchLayoutId,
    roles,
    fields,
    groups,
    getContact,
    logger,
    country,
    recipientContactId,
  },
  responses,
}: {
  input: Pick<
    ConvertMatterFieldsOptions,
    | "recipientContactId"
    | "roles"
    | "fields"
    | "groups"
    | "getContact"
    | "logger"
    | "country"
  > & {
    matchLayoutId?: (layoutId: string, roleName: string) => boolean;
  };
  responses: Reponse[];
}) => {
  const matchLayoutIdFn =
    matchLayoutId || ((layoutId, roleName) => layoutId === roleName);
  const repeatableGroupMap = groups
    ? fromEntries(groups.map((g) => [g.uri, !!g.repeatable]))
    : {};

  const getFirstPersonContactId = (people: { id: string }[] | string[]) =>
    typeof people[0] === "string" ? people[0] : people[0].id;

  const convertRole = async ({
    role,
    index,
    sameNameRoleCount,
  }: {
    role: Role;
    index: number;
    sameNameRoleCount: number;
  }) => {
    const roleFields = fields.filter(
      (f) =>
        f.layout?.providerId === roleProviderId &&
        (matchLayoutIdFn(f.layout.id, role.name) ||
          (f.layout.id === defaultClientRoleId && role.isClient)) &&
        !isLayoutContactField(f, groups) &&
        (!role.relationshipRoleName ||
          role.relationshipRoleName === f.layout.parentId),
    );

    const isHandlingMultiClients =
      role.isClient &&
      sameNameRoleCount > 1 &&
      recipientContactId &&
      roleFields.every((f) => !f.groupUri || !repeatableGroupMap[f.groupUri]);

    if (isHandlingMultiClients && role.contactId !== recipientContactId) return;
    if (!roleFields.length || !role.contactId) return;

    const roleContact = await getContact(role.contactId);
    if (role.contactId && !roleContact)
      throw new Error(`Could not load contact ${role.contactId}`);

    const contact = roleContact?.groupOfPeople?.people
      ? await getContact(
          getFirstPersonContactId(roleContact.groupOfPeople.people),
        )
      : roleContact;

    await logger?.debug(
      "Converting role:",
      prettyJsonStringify({
        index,
        role,
        contact,
        roleFields,
      }),
    );

    if (!contact) return;

    for (const field of roleFields) {
      // Response for repeatable group's field should be an array
      if (field.groupUri && repeatableGroupMap[field.groupUri]) {
        addMultipleContactsToResponse({
          field,
          groups,
          contacts: [contact],
          country,
          responses,
        });
      } else {
        const isFirstRoleOfSameName = index === 0;
        if (isHandlingMultiClients || isFirstRoleOfSameName) {
          addContactFieldResponse({ field, contact, country, responses });
        }
      }
    }

    await logger?.debug(
      "Converted role:",
      prettyJsonStringify({
        role,
        responses,
      }),
    );
  };

  // Check for existing field values in roles
  await waitSettledThenThrow(
    // Roles with the same name are processed sequentially to preserve the order in the responses
    entriesOf(
      groupBy(roles, (role) =>
        role.relationshipRoleName
          ? `${role.relationshipRoleName}-${role.name}`
          : role.name,
      ),
    ).map(async ([, rolesWithSameName]) => {
      for (const [index, role] of rolesWithSameName.entries()) {
        await convertRole({
          role,
          index,
          sameNameRoleCount: rolesWithSameName.length,
        });
      }
    }),
  );
};

const convertCompanyContact = async ({
  input: { roles, fields, groups, getContactRelation, logger, country },
  responses,
}: {
  input: Pick<
    ConvertMatterFieldsOptions,
    "roles" | "fields" | "groups" | "getContactRelation" | "logger" | "country"
  >;
  responses: Reponse[];
}) => {
  const companyContactGroups = groups?.filter(
    (g) => g.type === "layoutContact" && g.field?.name === "company/contact",
  );
  if (!companyContactGroups?.length) return;

  await waitSettledThenThrow(
    companyContactGroups.map(async (group) => {
      const role = roles.find(
        (r) =>
          r.name === group.layout?.id &&
          (!r.relationshipRoleName ||
            r.relationshipRoleName === group.layout.parentId),
      );
      if (role?.contactId) {
        const relatedContacts = await getContactRelation(role.contactId);
        const groupFields = fields.filter((f) => f.groupUri === group.uri);

        groupFields.forEach((field) =>
          addMultipleContactsToResponse({
            field,
            groups,
            country,
            contacts: relatedContacts,
            responses,
          }),
        );
      }
    }),
  );

  await logger?.debug(
    "Converted company contatc:",
    prettyJsonStringify({
      roles,
      responses,
    }),
  );
};

const convertLayoutContacts = async ({
  input: { fields, groups, layouts, getLayoutContacts, logger, country },
  responses,
}: {
  input: Pick<
    ConvertMatterFieldsOptions,
    "fields" | "groups" | "layouts" | "logger" | "country" | "getLayoutContacts"
  >;
  responses: Reponse[];
}) => {
  const layoutContactFields = fields.filter((f) =>
    isLayoutContactField(f, groups),
  );
  if (!layoutContactFields.length) {
    await logger?.debug("No layout contact fields found.");
    return;
  }

  const layoutContactGroups =
    groups?.filter((g) => g.type === "layoutContact" && g.layout && g.field) ||
    [];

  await waitSettledThenThrow(
    layouts
      .filter(
        (l) =>
          !!layoutContactGroups.find((g) => g.layout?.id === l.layoutDesign.id),
      )
      .map(async ({ values, layoutDesign, itemId }) => {
        const layoutContacts = await getLayoutContacts(itemId);

        await logger?.debug(
          "Converting layout contacts:",
          prettyJsonStringify({
            layoutDesign,
            values,
            itemId,
            layoutContacts,
          }),
        );

        layoutContactFields.forEach((f) => {
          const group = getGroup(f, groups);
          const matchedLayoutContacts =
            group?.layout && group?.field && layoutContacts
              ? layoutContacts.filter(
                  (lc) =>
                    lc.key.includes(group.field!.name) &&
                    layoutDesign.id === group.layout!.id,
                )
              : [];

          if (matchedLayoutContacts.length) {
            addMultipleContactsToResponse({
              field: f,
              groups,
              contacts: matchedLayoutContacts
                .sort((a, b) => a.key.localeCompare(b.key))
                .map(({ contact }) => contact),
              country,
              responses,
            });
          }
        });
      }),
  );

  await logger?.debug(
    "Converted layout contacts:",
    prettyJsonStringify({
      responses,
    }),
  );
};

const convertLayouts = async ({
  input: { fields, groups, layouts, logger, country },
  responses,
  consolidatedCheckboxKeys,
}: {
  input: Pick<
    ConvertMatterFieldsOptions,
    "fields" | "groups" | "layouts" | "logger" | "country"
  >;
  responses: Reponse[];
  consolidatedCheckboxKeys: Record<string, true>;
}) => {
  for (const { values, layoutDesign, itemId } of layouts) {
    for (const { key, value } of consolidateLayoutItems(
      values,
      consolidatedCheckboxKeys,
    )) {
      await logger?.debug(
        "Converting matter field:",
        prettyJsonStringify({
          key,
          value,
          layoutDesign,
          values,
          itemId,
        }),
      );

      if (value) {
        fields
          .filter(
            (f) =>
              ((f.layout?.providerId === basicLayoutProviderId &&
                f.layout?.id === layoutDesign.id) ||
                (f.layout && cuiLayoutId(f.layout) === layoutDesign.id)) &&
              checkIfMatch(f.field?.name, key) &&
              !isLayoutContactField(f, groups),
          )
          .forEach((f) => {
            const fieldGroup = getGroup(f, groups);
            const responseValue = convertLayoutValueFromSB(
              f.type,
              value,
              country,
            );
            if (fieldGroup?.repeatable) {
              const existingResponse = responses.find(
                (r) => r.fieldUri === f.uri,
              );
              if (!existingResponse) {
                responses.push({
                  fieldUri: f.uri,
                  value: [responseValue],
                });
              } else {
                existingResponse.value = [
                  ...existingResponse.value,
                  responseValue,
                ];
              }
            } else {
              responses.push({
                fieldUri: f.uri,
                value: fieldGroup ? [responseValue] : responseValue,
              });
            }
          });
      }
    }
  }

  await logger?.debug(
    "Converted layout responses:",
    prettyJsonStringify({
      responses,
    }),
  );
};

export const convertMatterFields = async ({
  isFormCategoryDifferent,
  recipientContactId,
  formMatterTypeRepresentatives,
  logger,
  country,
  matterType,
  matter,
  layouts,
  sections,
  fields,
  roles,
  groups,
  getContact,
  getLayoutContacts,
  getContactRelation,
}: ConvertMatterFieldsOptions) => {
  const responses: { fieldUri: string; value: any }[] = [];

  const consolidatedCheckboxKeys = fields.reduce((keys, { field }) => {
    if (field?.type !== consolidatedCheckboxesLayoutType) return keys;
    return {
      ...keys,
      ...(field.possibleValues || []).reduce(
        (aggr, value) => ({
          ...aggr,
          [`${field?.name}/${value}`]: true,
        }),
        {},
      ),
    };
  }, {});

  convertMatterInfo({
    input: {
      fields,
      matterType,
      matter,
    },
    responses,
  });

  const getConvertingPromises = () => {
    if (isFormCategoryDifferent) {
      const formClientRoleName = getClientRoleName(
        sections,
        fields,
        formMatterTypeRepresentatives,
      );

      return [
        convertRoles({
          input: {
            recipientContactId,
            matchLayoutId: (layoutId) =>
              !!layoutId && layoutId === formClientRoleName,
            logger,
            roles: roles.filter(({ isClient }) => isClient),
            fields,
            groups,
            country,
            getContact,
          },
          responses,
        }),
      ];
    }

    return [
      convertLayouts({
        input: {
          fields,
          groups,
          layouts,
          logger,
          country,
        },
        responses,
        consolidatedCheckboxKeys,
      }),
      convertLayoutContacts({
        input: {
          fields,
          groups,
          layouts,
          getLayoutContacts,
          logger,
          country,
        },
        responses,
      }),
      convertRoles({
        input: {
          recipientContactId,
          logger,
          roles,
          fields,
          groups,
          country,
          getContact,
        },
        responses,
      }),
      convertCompanyContact({
        input: {
          roles,
          fields,
          groups,
          country,
          getContactRelation,
        },
        responses,
      }),
    ];
  };

  const { rejected } = await resolveSettled(
    Promise.allSettled(getConvertingPromises()),
  );

  return {
    responses: responses.filter(({ value }) => !isNullOrUndefined(value)),
    rejected,
  };
};
