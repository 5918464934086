import { Control, Controller, useController } from "react-hook-form";

import { loadLocale } from "@smart/itops-locale-dom";
import {
  Combobox,
  FieldGroup,
  FieldList,
  RadioButtonOptionItem,
  RadioButtons,
  TextInput,
} from "@smart/itops-ui-dom";
import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { CreatingFormOptions, EditingFormInfo } from "../form";
import { UploadQuestions } from "../upload";

type FormFieldsListProps = {
  control: Control<CreatingFormOptions> | Control<EditingFormInfo>;
  formName?: boolean;
  fileUpload?: boolean;
  locations?: string[];
  matterTypeOptions?: { category: string; name: string }[];
  formCategoryOptions?: {
    value: "lead" | "matter";
    label: string;
  }[];
  creationTypeOptions?: RadioButtonOptionItem[];
  aiWordSupport?: boolean;
};
export const FormFieldsList = ({
  control: ctrl,
  formName,
  fileUpload,
  locations,
  matterTypeOptions,
  formCategoryOptions,
  creationTypeOptions,
  aiWordSupport,
}: FormFieldsListProps) => {
  const control = ctrl as Control<CreatingFormOptions | EditingFormInfo>;
  const { terms } = loadLocale();

  const { field: locationsField } = useController({
    control,
    name: "locations",
  });
  const { field: matterTypeGroupsField } = useController({
    control,
    name: "matterTypeGroups",
  });
  const { field: formCategoryField } = useController({
    control,
    name: "formCategory",
  });
  const supportedFileExtensions = [".png", ".jpg", ".jpeg", ".pdf"];
  if (aiWordSupport) {
    supportedFileExtensions.push(".doc", ".docx");
  }

  return (
    <FieldList size="emphasis">
      {!!formCategoryOptions && (
        <Controller
          control={control}
          name="formCategory"
          render={({ field, fieldState }) => (
            <FieldGroup
              id={field.name}
              label="Form type"
              error={fieldState.error?.message}
              required
            >
              <RadioButtons
                id={field.name}
                error={!!fieldState.error?.message}
                direction="row"
                box
                options={formCategoryOptions}
                {...field}
                onChange={(value) => {
                  matterTypeGroupsField.onChange([]);
                  formCategoryField.onChange(value);
                }}
              />
            </FieldGroup>
          )}
        />
      )}
      {!!formName && (
        <Controller
          control={control}
          name="formName"
          render={({ field, fieldState }) => (
            <FieldGroup
              id={field.name}
              label="Form name"
              hint="Enter a unique name for your new form"
              error={fieldState.error?.message}
              required
            >
              <TextInput
                id={field.name}
                error={!!fieldState.error}
                {...field}
              />
            </FieldGroup>
          )}
        />
      )}
      {!!locations && (
        <Controller
          control={control}
          name="locations"
          render={({ field, fieldState }) => (
            <FieldGroup
              id={field.name}
              label={terms.state.title}
              error={fieldState.error?.message}
              required
            >
              <Combobox
                id={field.name}
                title={terms.state.title}
                options={locations}
                maxItems={3}
                error={!!fieldState.error}
                {...field}
                onChange={(_, newLocations) => {
                  const shouldRemove =
                    !newLocations?.length ||
                    locationsField.value.length < newLocations.length;
                  if (shouldRemove) {
                    matterTypeGroupsField.onChange([]);
                  } else {
                    matterTypeGroupsField.onChange(
                      matterTypeGroupsField.value
                        .map((group) => {
                          const updatedTypes = group.matterTypes.filter(
                            (matterType) =>
                              newLocations.includes(matterType.location),
                          );
                          return updatedTypes.length
                            ? { ...group, matterTypes: updatedTypes }
                            : undefined;
                        })
                        .filter(isNotNullOrUndefined),
                    );
                  }
                  field.onChange(newLocations);
                }}
                multiple
                selectAll
              />
            </FieldGroup>
          )}
        />
      )}
      {!!matterTypeOptions && (
        <Controller
          control={control}
          name="matterTypeGroups"
          render={({ field, fieldState }) => (
            <FieldGroup
              id={field.name}
              label="Matter types"
              error={fieldState.error?.message}
              required
            >
              <Combobox
                id={field.name}
                title="Matter types"
                options={matterTypeOptions}
                maxItems={3}
                empty="No matter types available"
                getOptionKey={(o) => `${o.category}-${o.name}`}
                getOptionLabel={(o) => o.name}
                getOptionDisabled={(o) => o.name === "Loading..."}
                isOptionEqualToValue={(o, v) =>
                  o.name === v.name && o.category === v.category
                }
                groupBy={(o) => o.category}
                error={!!fieldState.error}
                {...field}
                onChange={(_, v) => field.onChange(v)}
                multiple
              />
            </FieldGroup>
          )}
        />
      )}
      {!!creationTypeOptions && (
        <Controller
          control={control}
          name="creationType"
          render={({ field, fieldState }) => (
            <FieldGroup
              label="Form building options"
              id={field.name}
              error={fieldState.error?.message}
              required
            >
              <RadioButtons
                id={field.name}
                error={!!fieldState.error?.message}
                box
                options={creationTypeOptions}
                {...field}
              />
            </FieldGroup>
          )}
        />
      )}
      {!!fileUpload && (
        <Controller
          control={control}
          name="filePath"
          render={({ field, fieldState }) => (
            <FieldGroup
              id={field.name}
              label="Upload existing document"
              error={fieldState.error?.message}
              required
            >
              <UploadQuestions
                name={field.name}
                onChange={field.onChange}
                error={fieldState.error?.message}
                supportedFileExtensions={supportedFileExtensions}
              />
            </FieldGroup>
          )}
        />
      )}
    </FieldList>
  );
};
