import styled from "@emotion/styled";
import { transparentize } from "polished";
import { useEffect, useState } from "react";
import { Control, Path, PathValue, useController } from "react-hook-form";

import { useChangedEffect, useDebouncedValue } from "@smart/itops-hooks-dom";
import { Icon } from "@smart/itops-icons-dom";
import { TextInput as NewInput, Progress } from "@smart/itops-ui-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { Input as OldInput } from "./input";
import { IconButton } from "../buttons";
import { ClickOutsideWrapper } from "../click-outside";
import { Spinner } from "../spinner";

const TypeaheadWrapper = styled.div<{
  shouldShowResults: boolean;
  newUI: boolean;
}>`
  .selected {
    display: flex;
    align-items: center;
    position: relative;

    .selected-wrapper {
      width: 100%;

      input {
        padding-right: 1rem;
      }
    }

    .clear {
      ${(props) => (props.newUI ? "margin: 0.5rem;" : "margin-right: 0.6rem;")}
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.4rem;
      background: none;
      border: 0;

      cursor: pointer;
      color: ${(props) => props.theme.palette.foreground.accent};
    }
  }

  .item {
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;

    .item-display {
      font-size: ${(props) => props.theme.fontSize.base};
      padding: 1rem;
      margin: 0;
    }

    .details {
      padding: 0 1rem 1rem;

      span {
        position: relative;
        font-size: ${(props) => props.theme.fontSize.xSmall};
        font-weight: bold;
        margin-right: 0.6rem;
        padding-right: 0.6rem;
      }

      span:not(:last-child):after {
        content: "";
        position: absolute;
        display: inline-block;
        background: ${(props) => props.theme.palette.foreground.accent};
        width: 1px;
        height: 2.2rem;
        right: 0;
        top: -3px;
      }
    }
  }

  .results {
    display: ${(props) => (props.shouldShowResults ? "block" : "none")};
    background: var(--background);
    border-radius: 3px;
    box-shadow: 1px 1px 3px
      ${(props) => transparentize(0.4, props.theme.palette.disabled.base)};

    position: absolute;
    width: ${(props) => (props.newUI ? "93%" : "98%")};
    top: ${(props) => (props.newUI ? "12.5rem" : "4rem")};
    border-radius: ${(props) => (props.newUI ? "0.8rem" : 0)};
    z-index: 99;

    max-height: ${(props) => (props.newUI ? "13rem" : "20rem")};
    overflow: auto;
  }
`;

const ResultItem = styled.button<{ parentId?: string }>`
  cursor: pointer;

  border: 0;
  background: none;
  padding: ${(props) => (props.parentId ? "0 0 0 1rem" : "0.5rem 1rem")};
  margin: 0;
  width: 100%;
  text-align: left;

  p.item-display:first-of-type {
    font-size: ${(props) =>
      props.parentId ? props.theme.fontSize.small : props.theme.fontSize.base};
    padding: ${(props) => (props.parentId ? "0 1rem 0.5rem 1rem" : "0")};
    margin: 0;
  }

  .details {
    padding-left: 1.5rem;

    span {
      position: relative;
      font-size: ${(props) => props.theme.fontSize.xSmall};
      font-weight: bold;
      margin-right: 0.6rem;
      padding-right: 0.6rem;
    }

    span:not(:last-child):after {
      content: "";
      position: absolute;
      display: inline-block;
      background: ${(props) => props.theme.palette.foreground.accent};
      width: 1px;
      height: 2.2rem;
      right: 0;
      top: -3px;
    }
  }
`;

type TypeaheadKey<V extends {}, R> = {
  [K in Path<V>]: PathValue<V, K> extends R | undefined ? K : never;
}[Path<V>];

type TypeaheadFieldProps<
  V extends {},
  I extends {},
  S extends keyof I,
  R extends {},
> = {
  id?: string;
  control: Control<V>;
  name: TypeaheadKey<V, Partial<R> | null | undefined>;
  hook: (input: I) => { result?: R[]; loading?: boolean };
  idKey: keyof R;
  parentidKey?: keyof R;
  input: (result: R) => string;
  display: (result: R) => string;
  details?: (result: R) => string;
  searchKey?: S;
  filter?: (search: string) => (item: R) => boolean;
  locators: Omit<I, S>;
  clearOnChangeLocatorKeys?: (keyof Omit<I, S>)[];
  delay?: number;
  interaction?: "disabled" | "readOnly";
  showSelectedDetails?: boolean;
};

type TypeaheadProps<
  I extends {},
  S extends keyof I,
  R extends Record<string, any>,
> = {
  id?: string;
  selected: R | undefined | null;
  onSelect: (selected: R | null) => void;
  onInput?: (value: string) => void;
  onClear?: () => void;
  hook: (input: I) => { result?: R[]; loading?: boolean };
  idKey: keyof R;
  parentidKey?: keyof R;
  input: (result: R) => string;
  display: (result: R) => string;
  details?: (result: R) => string;
  searchKey?: S;
  filter?: (search: string) => (item: R) => boolean;
  locators: Omit<I, S>;
  clearOnChangeLocatorKeys?: (keyof Omit<I, S>)[];
  delay?: number;
  interaction?: "disabled" | "readOnly";
  showSelectedDetails?: boolean;
  newUI?: boolean;
};

type UserAction = "search" | "clear" | "select";

const Typeahead = <
  I extends {},
  S extends keyof I,
  R extends Record<string, any>,
>({
  id,
  selected,
  onSelect,
  onInput,
  onClear: onClearCallback,
  hook,
  idKey,
  parentidKey,
  input,
  display,
  details,
  searchKey,
  filter,
  locators,
  clearOnChangeLocatorKeys = [],
  delay,
  interaction,
  showSelectedDetails = true,
  newUI,
}: TypeaheadProps<I, S, R>) => {
  const [resultsVisible, setResultsVisible] = useState(false);
  const [value, setValue] = useState(selected ? input(selected) : "");
  const [lastUserAction, setLastUserAction] = useState<UserAction>();
  const debounced = useDebouncedValue(value, delay || 300);
  const { result, loading } = hook({
    ...locators,
    ...(searchKey ? { [searchKey]: debounced } : {}),
  } as I);
  const cached = filter ? result?.filter(filter(value)) : result;

  const setInputValue = (inputValue: string) => {
    setValue(inputValue);
    onInput?.(inputValue);
  };
  const onClear = () => {
    onSelect(null);
    setInputValue("");
    setLastUserAction("clear");
    onClearCallback?.();
  };

  useEffect(() => {
    if (selected) {
      setInputValue(input(selected));
    } else {
      setInputValue("");
    }
  }, [selected]);

  useChangedEffect(
    () => {
      if (clearOnChangeLocatorKeys.length) {
        onClear();
      }
    },
    clearOnChangeLocatorKeys.map((key) => locators[key]),
  );

  const Input = newUI ? NewInput : OldInput;

  return (
    <ClickOutsideWrapper onClickOutside={() => setResultsVisible(false)}>
      <TypeaheadWrapper
        // We can't use styled component to style this otherwise we can't infer the props types
        // This class name is for other components to target this wrapper
        className="typeahead-wrapper"
        shouldShowResults={resultsVisible}
        newUI={!!newUI}
        onBlur={(e) => {
          if (
            !e.currentTarget.contains(e.relatedTarget) &&
            selected &&
            lastUserAction !== "clear"
          )
            setInputValue(input(selected));
        }}
      >
        <div className="item selected">
          <div className="selected-wrapper">
            <Input
              id={id}
              aria-label="Search"
              value={value}
              onChange={(e) => {
                setInputValue(e.currentTarget.value);
                setLastUserAction("search");
              }}
              onFocus={() => setResultsVisible(true)}
              // We can't use onBlur because it will trigger when the result
              // is clicked and prevent the onClick callback to fire
              onKeyDown={({ key }) => {
                if (key === "Tab") setResultsVisible(false);
              }}
              disabled={interaction === "disabled"}
              readOnly={interaction === "readOnly"}
              right={
                interaction !== "disabled" &&
                (!!selected || !!value) && (
                  <IconButton
                    name="X"
                    library="lucide"
                    className="clear"
                    aria-label="Clear"
                    onClick={onClear}
                    tabIndex={-1}
                    size={15}
                  />
                )
              }
            />

            {details && showSelectedDetails && value && (
              <div className="details">
                {!!selected && <span>{details(selected)}</span>}
                {specialChars.nbsp}
              </div>
            )}
          </div>
          {interaction !== "disabled" && (!!selected || !!value) && !newUI && (
            <button
              aria-label="Clear"
              className="clear"
              type="button"
              onClick={onClear}
              tabIndex={-1}
            >
              <Icon name="cancelCircle" />
            </button>
          )}
        </div>
        <div className="results">
          {(!selected || value !== input(selected)) &&
            cached?.map((r) => {
              const renderedDetails = details && details(r);

              return (
                <ResultItem
                  key={`${r[idKey]}-${parentidKey ? r[parentidKey] : ""}`}
                  parentId={parentidKey ? r[parentidKey] : undefined}
                  type="button"
                  onClick={() => {
                    onSelect(r);
                    setInputValue(input(r));
                    setLastUserAction("select");
                  }}
                  aria-label={display(r)}
                  tabIndex={-1}
                >
                  <p className="item-display">{display(r)}</p>
                  {renderedDetails && (
                    <div className="details">
                      <span>{renderedDetails}</span>
                    </div>
                  )}
                </ResultItem>
              );
            })}

          {loading && cached && (
            <div className="item">
              <p className="item-display">{specialChars.nbsp}</p>
              <div className="details" />
            </div>
          )}
          {loading &&
            (newUI ? (
              <Progress indeterminate size={0.6} />
            ) : (
              <Spinner size={3} className="spinner" />
            ))}
        </div>
      </TypeaheadWrapper>
    </ClickOutsideWrapper>
  );
};

const TypeaheadField = <
  V extends {},
  I extends {},
  S extends keyof I,
  R extends {},
>(
  props: TypeaheadFieldProps<V, I, S, R>,
) => {
  const { control, name, ...otherProps } = props;
  const { field } = useController({ control, name });

  return (
    <Typeahead
      selected={field.value}
      onSelect={field.onChange}
      {...otherProps}
    />
  );
};

export { Typeahead, TypeaheadField, TypeaheadFieldProps };
