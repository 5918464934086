import styled from "@emotion/styled";
import { DraggableLocation } from "@hello-pangea/dnd";
import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import { TField } from "@smart/bridge-resources-basic";
import {
  FieldNS,
  groupTemplates,
  groupTemplateTypeLabel,
  groupTypeLabel,
} from "@smart/bridge-types-basic";
import {
  DragNDropList,
  IconButton,
  Input,
  Label,
  Select,
  Rule,
  updateFormFactory,
} from "@smart/itops-components-dom";
import { FormEditor } from "@smart/itops-editor-dom";
import { useReorder } from "@smart/itops-hooks-dom";
import { rankBetween, removeKeys } from "@smart/itops-utils-basic";
import {
  useLoadFields,
  useLoadForm,
  useUpdateFieldOrder,
  useUpdateGroupWithFields,
} from "@smart/manage-gql-client-dom";

import { FieldForm } from "./form";
import { GroupField } from "./group-field";
import { GroupLinks } from "../links";
import { groupSchema } from "../shared-schemas";

const GroupRule = styled(Rule)`
  margin: 1rem 0 1rem 0;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  .repeatable-checkbox {
    width: initial;
    margin: 1.2rem 0.8rem 1.2rem 1.2rem;
  }

  .label {
    font-size: ${(props) => props.theme.fontSize.xSmall};
  }
`;

const RepeatableWrapper = styled.div`
  display: flex;

  .repeat-prompt {
    margin-left: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;
`;

type Item = {
  uri: string;
  values: {
    order: string;
    formUri: string;
    sectionUri: string;
  };
};

export const buildHandleMove =
  ({
    fields,
    groupFields,
    onMove,
    move,
  }: {
    fields: Item[] | undefined;
    groupFields: { uri: string }[];
    onMove: (props: { index: number; item: Item; items: Item[] }) => void;
    move: (indexA: number, indexB: number) => void;
  }) =>
  ({
    item,
    destination,
    source,
  }: {
    index: number;
    item: TField;
    destination: DraggableLocation;
    source: DraggableLocation;
  }) => {
    if (fields) {
      const destFieldIndex = fields.findIndex(
        (f) => f.uri === groupFields[destination.index].uri,
      );
      onMove({
        index: destFieldIndex,
        item: { uri: item.uri, values: item },
        items: fields,
      });
      move(source.index, destination.index);
    }
  };

const EditGroup = updateFormFactory({
  factoryProps: {
    mutation: useUpdateGroupWithFields,
    schema: groupSchema,
    converter: (input) => ({
      ...input,
      templateType: input.templateType || undefined,
      links: input.links || undefined,
      layout: input.layout
        ? {
            id: input.layout.id,
            providerId: input.layout.providerId,
            name: input.layout.name,
            parentId: input.layout.parentId || undefined,
            parentName: input.layout.parentName || undefined,
            parentProviderId: input.layout.parentProviderId || undefined,
          }
        : undefined,
      field: input.field
        ? {
            name: input.field.name,
            type: input.field.type,
            possibleValues: input.field.possibleValues || undefined,
          }
        : undefined,
      fields:
        input.fields?.map((f) => ({
          ...removeKeys(f, ["roleFieldName"]),
          layout: f.layout
            ? {
                id: f.layout.id,
                providerId: f.layout.providerId,
                name: f.layout.name,
                parentId: f.layout.parentId || undefined,
                parentName: f.layout.parentName || undefined,
                parentProviderId: f.layout.parentProviderId || undefined,
              }
            : undefined,
          field: f.field
            ? {
                name: f.field.name,
                type: f.field.type,
                possibleValues: f.field.possibleValues || undefined,
              }
            : undefined,
        })) || undefined,
    }),
  },
  form: FieldForm,
  render: ({
    mode = "create",
    locators,
    form: {
      control,
      register,
      setValue,
      getValues,
      watch,
      formState: { errors },
    },
    result: { loading },
  }) => {
    const loadedForm = useLoadForm({ uri: locators.formUri });
    const loadedFields = useLoadFields({
      formUri: locators.formUri,
      sectionUri: locators.sectionUri,
    });
    const { onMove } = useReorder({
      mutation: () => useUpdateFieldOrder(false),
      extraFields: {
        formUri: locators.formUri,
        sectionUri: locators.sectionUri,
      },
    });
    const groupFields = useFieldArray({ control, name: "fields" });
    const type = watch("type");
    const selectedTemplateType = watch("templateType");
    const fields = watch("fields");
    const allowedRepeatable = watch("allowedRepeatable");
    const repeatable = watch("repeatable");
    const groupLayoutField = getValues("field");

    useEffect(() => {
      if (mode !== "create") return;

      const currentTemplate = groupTemplates.find(
        (t) => t.type === selectedTemplateType,
      );

      if (!currentTemplate) {
        groupFields.remove();
      } else {
        const fieldsFromTemplate = currentTemplate.fields.map((f) => ({
          uri: FieldNS.generateUri(),
          label: f.label,
          hint: f.hint,
          type: f.type,
          order: "",
          options: f.options,
          mandatory: f.mandatory,
        }));

        fieldsFromTemplate.reduce((prevOrder, _, index) => {
          const fieldOrder = rankBetween({ after: prevOrder });

          fieldsFromTemplate[index].order = fieldOrder;
          return fieldOrder;
        }, locators.order);

        groupFields.replace(fieldsFromTemplate);
      }
    }, [selectedTemplateType]);

    const handleMove = buildHandleMove({
      fields: loadedFields.result,
      groupFields: groupFields.fields,
      onMove,
      move: groupFields.move,
    });
    const disabled = mode === "view";

    const groupFieldLayout = groupFields.fields.length
      ? groupFields.fields[0].layout
      : undefined;

    return (
      <>
        <Label
          name="label"
          text="Group label"
          error={errors.label?.message}
          loading={loading}
        >
          <Input {...register("label")} disabled={disabled} />
        </Label>
        {mode === "create" ? (
          <Label name="templateType" text="Template type">
            <Select {...register("templateType")}>
              {groupTemplates.map((t) => (
                <option key={t.type} value={t.type}>
                  {groupTemplateTypeLabel[t.type]}
                </option>
              ))}
            </Select>
          </Label>
        ) : (
          <Label name="type" text="Type">
            <Input value={type ? groupTypeLabel[type] : ""} readOnly />
          </Label>
        )}
        <Label name="hint" text="Hint" error={errors.hint?.message}>
          <FormEditor control={control} name="hint" disabled={disabled} />
        </Label>
        <Label name="fields" text="Fields">
          <DragNDropList
            id="draggable-group-field-list"
            items={groupFields.fields as unknown as TField[]}
            itemKey="uri"
            render={GroupField}
            onMove={handleMove}
            renderProps={{
              watch,
              register,
              setValue,
              type,
              control,
              removeField: groupFields.remove,
              groupFieldLength: groupFields.fields.length,
              fields,
              matterTypes: loadedForm.result?.values.matterTypes,
              mode,
            }}
          />
          <ButtonGroup>
            <IconButton
              name="add"
              text="Add new field to group"
              onClick={() =>
                groupFields.append({
                  uri: FieldNS.generateUri(),
                  order: rankBetween({
                    after: fields[fields.length - 1].order,
                  }),
                  type: "text",
                  label: "",
                  hint: "",
                  options: [],
                  mandatory: false,
                  layout: groupFieldLayout,
                })
              }
            />
            {type === "layoutContact" && groupLayoutField?.displayName && (
              <IconButton
                name="add"
                text="Add new role field to group"
                onClick={() =>
                  groupFields.append({
                    uri: FieldNS.generateUri(),
                    order: rankBetween({
                      after: fields[fields.length - 1].order,
                    }),
                    type: "text",
                    label: "",
                    hint: "",
                    options: [],
                    mandatory: false,
                    layout: groupFieldLayout,
                    roleFieldName: groupLayoutField.displayName!,
                  })
                }
              />
            )}
          </ButtonGroup>
        </Label>
        <GroupLinks mode={mode} uri={locators.uri} formUri={locators.formUri} />
        {allowedRepeatable && (
          <RepeatableWrapper>
            <Label name="repeatable" text="">
              <OptionsContainer>
                <Input
                  className="repeatable-checkbox"
                  {...register("repeatable")}
                  type="checkbox"
                  aria-label="repeatable group"
                  disabled={disabled}
                />
                <span className="label">Repeatable</span>
              </OptionsContainer>
            </Label>
            {repeatable && (
              <Label
                className="repeat-prompt"
                name="repeatPrompt"
                text="Repeat Prompt"
              >
                <Input {...register("repeatPrompt")} disabled={disabled} />
              </Label>
            )}
          </RepeatableWrapper>
        )}
        <GroupRule />
      </>
    );
  },
});

export { EditGroup };
