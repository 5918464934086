import clsx from "clsx";
import { ReactNode } from "react";

import { Avatar, Icon, Skeleton } from "@smart/itops-sb-design-system-dom";

export const selectedClassNames =
  "bg-cyan-140 relative after:content-[''] after:h-[2rem] after:border-cyan-540 after:absolute after:left-[-0.1rem] after:top-[0.9rem] after:border-r-4 after:rounded-r";

type NavItemProps = {
  section: {
    uri: string;
    values?: {
      title: string;
    };
  };
  index: number | ReactNode;
  currentSectionUri: string;
  setCurrentSectionUri: (uri: string) => void;
  loading: boolean;
  isDraggingItem: boolean;
  setDroppedOnSectionUri: (sectionUri: string | undefined) => void;
  setIsValidSection: (isValid: boolean) => void;
};

const NavItem = ({
  section,
  index,
  currentSectionUri,
  setCurrentSectionUri,
  loading,
  isDraggingItem,
  setDroppedOnSectionUri,
  setIsValidSection,
}: NavItemProps) => {
  const isCurrent = section.uri === currentSectionUri;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        "flex items-center gap-medium h-[4rem] px-[1.6rem] py-[0.4rem] rounded border border-transparent",
        !loading &&
          clsx(
            "hover:bg-cyan-140 cursor-pointer",
            isCurrent && selectedClassNames,
            !isCurrent && isDraggingItem && "hover:border-blue-560 ",
          ),
      )}
      data-testid={`nav-item-${section.uri}`}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={() => isDraggingItem && setIsValidSection(!isCurrent)}
      onMouseDown={() => setCurrentSectionUri(section.uri)}
      onMouseUp={() => {
        if (isCurrent || !isDraggingItem) return;
        setDroppedOnSectionUri(section.uri);
      }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="w-[3.2rem] h-[3.2rem]">
            {typeof index === "number" ? (
              <Avatar text={String(index + 1)} size={32} active={isCurrent} />
            ) : (
              index
            )}
          </div>
          <p
            className={clsx(
              "flex-1 text-ui text-neutral-600 font-semibold overflow-hidden text-ellipsis whitespace-nowrap",
              isCurrent && "text-neutral-850",
            )}
          >
            {section.values?.title || "Section"}
          </p>
          {isCurrent && (
            <Icon name="regularArrowRight" dataTestId="current-section-arrow" />
          )}
        </>
      )}
    </div>
  );
};

export { NavItem, NavItemProps };
