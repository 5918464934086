/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlContactsQueryVariables = Types.Exact<{
  names?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
  emails?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
  phones?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type GqlContactsQuery = {
  __typename: "Query";
  contacts: Array<{
    __typename: "Contact";
    id: string;
    person?: {
      __typename: "Person";
      title?: string | null;
      nameSuffix?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      email?: string | null;
      notes?: string | null;
      occupation?: string | null;
      gender?: string | null;
      maritalStatus?: string | null;
      birthDate?: string | null;
      birthPlace?: string | null;
      birthState?: string | null;
      birthCountry?: string | null;
      identificationNumberType?: string | null;
      identificationNumber?: string | null;
      residentialAddress?: {
        __typename: "Address";
        buildingLevel?: string | null;
        unitNumber?: string | null;
        unitType?: string | null;
        streetNumber?: string | null;
        streetName?: string | null;
        streetType?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locality?: string | null;
        county?: string | null;
        country?: string | null;
      } | null;
      mailingAddress?: {
        __typename: "Address";
        buildingLevel?: string | null;
        unitNumber?: string | null;
        unitType?: string | null;
        streetNumber?: string | null;
        streetName?: string | null;
        streetType?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locality?: string | null;
        county?: string | null;
        country?: string | null;
      } | null;
      phone?: {
        __typename: "Phone";
        areaCode?: string | null;
        number?: string | null;
      } | null;
      cell?: {
        __typename: "Phone";
        areaCode?: string | null;
        number?: string | null;
      } | null;
      passportDetails?: {
        __typename: "PassportDetails";
        passportNumber?: string | null;
        passportIssueDate?: string | null;
        passportExpireDate?: string | null;
        passportIssueCountry?: string | null;
        passportIssueAuthority?: string | null;
      } | null;
      deathDetails?: {
        __typename: "DeathDetails";
        deathDate?: string | null;
        deathPlace?: string | null;
        deathState?: string | null;
        deathCounty?: string | null;
        deathCountry?: string | null;
      } | null;
      citizenshipDetails?: {
        __typename: "CitizenshipDetails";
        countryOfCitizenship?: string | null;
        nationality?: string | null;
        languageOfInterpreter?: string | null;
      } | null;
      utbmsDetails?: {
        __typename: "UtbmsDetails";
        ledesClientId?: string | null;
      } | null;
      identificationDetails?: {
        __typename: "IdentificationDetails";
        alienNumber?: string | null;
        registrationAuthority?: string | null;
        registrationNumber?: string | null;
        driversLicenseNumber?: string | null;
        driversLicenseState?: string | null;
        medicareNumber?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      name?: string | null;
      type?: string | null;
      email?: string | null;
      notes?: string | null;
      abn?: string | null;
      companyNumberType?: string | null;
      companyNumber?: string | null;
      phone?: {
        __typename: "Phone";
        areaCode?: string | null;
        number?: string | null;
      } | null;
      fax?: {
        __typename: "Phone";
        areaCode?: string | null;
        number?: string | null;
      } | null;
      businessAddress?: {
        __typename: "Address";
        buildingLevel?: string | null;
        unitNumber?: string | null;
        unitType?: string | null;
        streetNumber?: string | null;
        streetName?: string | null;
        streetType?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locality?: string | null;
        county?: string | null;
        country?: string | null;
      } | null;
      mailingAddress?: {
        __typename: "Address";
        buildingLevel?: string | null;
        unitNumber?: string | null;
        unitType?: string | null;
        streetNumber?: string | null;
        streetName?: string | null;
        streetType?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locality?: string | null;
        county?: string | null;
        country?: string | null;
      } | null;
    } | null;
    groupOfPeople?: {
      __typename: "GroupOfPeople";
      people: Array<{ __typename: "ContactId"; id: string }>;
    } | null;
  }>;
};

export const ContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "contacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "names" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "emails" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phones" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "contacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "names" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "names" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "emails" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "emails" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phones" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phones" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "contactFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "addressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "buildingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "unitNumber" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "streetNumber" } },
          { kind: "Field", name: { kind: "Name", value: "streetName" } },
          { kind: "Field", name: { kind: "Name", value: "streetType" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine1" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine2" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "zipCode" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "county" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "phoneFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Phone" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "areaCode" } },
          { kind: "Field", name: { kind: "Name", value: "number" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "contactFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Contact" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "person" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "nameSuffix" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "middleName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "residentialAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "addressFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mailingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "addressFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "phoneFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cell" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "phoneFields" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                { kind: "Field", name: { kind: "Name", value: "occupation" } },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maritalStatus" },
                },
                { kind: "Field", name: { kind: "Name", value: "birthDate" } },
                { kind: "Field", name: { kind: "Name", value: "birthPlace" } },
                { kind: "Field", name: { kind: "Name", value: "birthState" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "birthCountry" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "identificationNumberType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "identificationNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "passportDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportIssueDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportExpireDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportIssueCountry" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportIssueAuthority" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deathDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deathDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deathPlace" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deathState" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deathCounty" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deathCountry" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citizenshipDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryOfCitizenship" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nationality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "languageOfInterpreter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "utbmsDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ledesClientId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "identificationDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "alienNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationAuthority" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "driversLicenseNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "driversLicenseState" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medicareNumber" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "phoneFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fax" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "phoneFields" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "businessAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "addressFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mailingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "addressFields" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "abn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyNumberType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyNumber" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "groupOfPeople" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "people" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlContactsQuery, GqlContactsQueryVariables>;
