import { TField, TGroup, TSection } from "@smart/bridge-resources-basic";
import { FieldType, validateConditionLink } from "@smart/bridge-types-basic";
import { fromEntries } from "@smart/itops-utils-basic";

type ResponsesType = {
  field: {
    uri: string;
    sectionUri: string;
    groupUri?: string | undefined | null;
  };
};

type CreateHiddenFieldsMapsProps = {
  sections: Pick<TSection, "uri" | "links">[];
  groups: Pick<TGroup, "uri" | "links" | "repeatable">[];
  fields: {
    uri: string;
    type: FieldType;
    links?: TField["links"] | null;
    groupUri?: string | null;
  }[];
  responses: Record<string, any>;
};

const createHiddenMaps = ({
  items,
  responses,
  fieldsMap,
  groups,
}: {
  items: Array<{
    uri: string;
    links?: TField["links"] | TSection["links"] | TGroup["links"] | null;
  }>;
  responses: Record<string, any>;
  fieldsMap: Record<string, Pick<TField, "uri" | "type" | "groupUri">>;
  groups: Pick<TGroup, "uri" | "links" | "repeatable">[];
}) =>
  items.filter((item) =>
    (
      item.links?.map((link) => {
        const field = fieldsMap[link.fieldUri];
        return field
          ? validateConditionLink({
              conditionLink: link,
              fieldValue: responses[link.fieldUri],
              fieldType: field.type,
              group: groups?.find(
                (g) => field.groupUri && g.uri === field.groupUri,
              ),
            })
          : true;
      }) || [true]
    ).includes(false),
  );

export const createFilterHiddenFields = ({
  sections,
  groups,
  fields,
  responses,
}: CreateHiddenFieldsMapsProps) => {
  const fieldsMap = fromEntries(
    fields.map((f) => [f.uri, { ...f, groupUri: f.groupUri || undefined }]),
  );
  const hiddenSectionsMap = fromEntries(
    createHiddenMaps({ items: sections, responses, fieldsMap, groups }).map(
      (s) => [s.uri, s],
    ),
  );

  const hiddenGroupsMap = fromEntries(
    createHiddenMaps({ items: groups, responses, fieldsMap, groups }).map(
      (g) => [g.uri, g],
    ),
  );

  const hiddenFieldsMap = fromEntries(
    createHiddenMaps({ items: fields, responses, fieldsMap, groups }).map(
      (f) => [f.uri, f],
    ),
  );
  return (response: ResponsesType) =>
    !hiddenFieldsMap[response.field.uri] &&
    !hiddenSectionsMap[response.field.sectionUri] &&
    !hiddenGroupsMap[response.field.groupUri || ""];
};
