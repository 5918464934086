import { useNavigate } from "react-router-dom";

import { FormNS, globalTeamUri } from "@smart/bridge-types-basic";
import { CloseButton, Page } from "@smart/itops-components-dom";
import { useParamsWithDefault } from "@smart/itops-hooks-dom";
import { useLoadForm } from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { defaultFormTab, FormTags, FormView } from "../components";

const rootPath = {
  firm: "forms",
  global: "library",
};

type FormProps = {
  type?: "firm" | "global";
};

const Form = ({ type = "firm" }: FormProps) => {
  const navigate = useNavigate();
  const { permission, user } = useUser();

  const getMode = () =>
    permission?.can("update", {
      type: "form",
      value: {
        uri: "",
        teamUri: type === "global" ? globalTeamUri : user?.teamUri || "",
      },
    })
      ? "edit"
      : "view";
  const mode = getMode();

  const params = useParamsWithDefault((p) => ({
    id: p.id || "",
    tab: defaultFormTab(mode, p.tab),
  }));
  const form = useLoadForm({ uri: FormNS.generateUri(params.id) });

  return (
    <Page
      top={<FormTags form={form.result} />}
      text={form.result?.values.title}
      right={<CloseButton />}
    >
      <FormView
        mode={mode}
        form={form.result}
        result={form}
        selectedTab={params.tab}
        selectTab={(tab) =>
          navigate(`/${rootPath[type]}/${params.id}/${tab}`, { replace: true })
        }
      />
    </Page>
  );
};

export { Form };
