/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUpdateAiUserFeedbackMutationVariables = Types.Exact<{
  uri: Types.Scalars["ID"]["input"];
  status?: Types.InputMaybe<Types.GqlAiUserFeedbackStatus>;
  statusText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  feedback?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  rating?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type GqlUpdateAiUserFeedbackMutation = {
  __typename: "Mutation";
  updateAiUserFeedback: {
    __typename: "AIUserFeedback";
    uri: string;
    values: {
      __typename: "AIUserFeedbackValues";
      uri: string;
      formUri?: string | null;
      type: string;
      sourceFileIds?: Array<string> | null;
      status: Types.GqlAiUserFeedbackStatus;
      statusText?: string | null;
      output?: string | null;
      feedback?: string | null;
      rating?: number | null;
      updatedAt?: string | null;
    };
  };
};

export const UpdateAiUserFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateAiUserFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uri" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AIUserFeedbackStatus" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "statusText" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "feedback" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rating" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAiUserFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "statusText" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "statusText" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "feedback" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "feedback" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rating" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rating" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "aiUserFeedbackFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "aiUserFeedbackFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AIUserFeedback" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "formUri" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sourceFileIds" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "statusText" } },
                { kind: "Field", name: { kind: "Name", value: "output" } },
                { kind: "Field", name: { kind: "Name", value: "feedback" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUpdateAiUserFeedbackMutation,
  GqlUpdateAiUserFeedbackMutationVariables
>;
