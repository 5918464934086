import { createContext, useContext } from "react";

import { TTeam } from "@smart/bridge-resources-basic";

export type ManageFormsContext = {
  canActAsFirm: boolean;
  actingFirmId: string;
  actingTeam: TTeam | undefined;
  setActingAsFirm: (team: TTeam | undefined) => void;
  clearActingFirm: () => void;
  isActing: boolean;
};

const formsContext = createContext<ManageFormsContext | undefined>(undefined);

export const useManageFormsContext = () => {
  const context = useContext(formsContext);
  if (!context) throw new Error(`Forms context not available`);

  return context;
};

export const ManageFormsProvider = formsContext.Provider;
