import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { Spinner } from "../spinner";

const SelectionListWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.small};

  .table {
    display: block;
    background: var(--background);
    max-height: 24rem;
    overflow: auto;

    thead th {
      position: sticky;
      top: 0;
      background: var(--background);
      padding: 1.2rem 0 1rem 0;
      border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};
      width: 100%;
    }

    th.select-column {
      padding: 0 2rem;
    }

    td {
      padding: 0.4rem;
    }
  }

  .spinner-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 1rem;
  }
`;

type ListColumn<R extends {}> = {
  key: string & number & keyof R;
  header?: string;
};

type SelectionListProps<I extends {}, S extends keyof I, R extends {}> = {
  hook: (input: I) => { result?: R[]; loading?: boolean };
  idKey: keyof R;
  columns: ListColumn<R>[];
  filter?: (item: R) => boolean;
  locators: Omit<I, S>;
  noDataText?: string;
  onChange: (selectedItems: R[]) => void;
};

const SelectionList = <
  I extends {},
  S extends keyof I,
  R extends Record<string, any>,
>({
  hook,
  idKey,
  columns,
  filter,
  locators,
  noDataText,
  onChange,
}: SelectionListProps<I, S, R>) => {
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const { result, loading } = hook({
    ...locators,
  } as I);
  const cached = filter ? result?.filter(filter) : result;

  const updateSelectedValues = (value: any) =>
    setSelectedValues(
      selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value],
    );

  const isAllSelected = cached && cached.length === selectedValues.length;

  const onSelectAll = (selected: boolean) =>
    selected
      ? cached && setSelectedValues(cached.map((r) => r[idKey]))
      : setSelectedValues([]);

  useEffect(() => {
    onChange(
      cached ? cached.filter((r) => selectedValues.includes(r[idKey])) : [],
    );
  }, [selectedValues]);

  return (
    <SelectionListWrapper>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner size={3} />
        </div>
      )}
      {!loading &&
        (cached && cached.length > 0 ? (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th className="select-column" aria-label="select all">
                    <input
                      checked={isAllSelected}
                      type="checkbox"
                      onChange={(e) => onSelectAll(e.target.checked)}
                    />
                  </th>
                  {columns.map((column) => (
                    <th key={column.key}>{column.header || ""}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {cached?.map((r) => (
                  <tr
                    key={`${r[idKey]}`}
                    onClick={(e) => {
                      e.preventDefault();
                      updateSelectedValues(r[idKey]);
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(r[idKey])}
                        onChange={(e) => {
                          e.preventDefault();
                          updateSelectedValues(r[idKey]);
                        }}
                        aria-label={`checkbox-${r[idKey]}`}
                      />
                    </td>
                    {columns.map((column) => (
                      <td key={r[column.key]} align="left">
                        {r[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>{noDataText || "No data available"}</div>
        ))}
    </SelectionListWrapper>
  );
};

export { SelectionList, SelectionListProps };
