import { formCategoryLabel } from "@smart/bridge-types-basic";
import { Tags } from "@smart/itops-components-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { GqlForm } from "./types";

type FormTagsProps = {
  form: GqlForm | null | undefined;
};

const FormTags = ({ form }: FormTagsProps) => (
  <Tags
    tags={
      form
        ? [
            form.values.matterTypes
              .map((m) => `${m.name} ${m.location}`)
              .join(", ") || specialChars.enDash,
            formCategoryLabel[form.values.category],
            form.slug?.values.active ? "Active" : "Inactive",
          ]
        : []
    }
    titles={["Display Type", "Category", "Updates"]}
  />
);

export { FormTags, FormTagsProps };
