import { useEffect, useState } from "react";

import { logError, SDKError } from "@smart/itops-utils-basic";

import { Matter, UseSDKApiOptions } from "../types";

export const useMatters = ({ client, isInit, observe }: UseSDKApiOptions) => {
  const [loading, setLoading] = useState(true);
  const [currentMatter, setCurrentMatter] = useState<Matter>();
  const shouldObserve = observe?.includes("matters");

  useEffect(() => {
    if (isInit && client && shouldObserve && client.context?.matterId) {
      setLoading(true);
      client.matters
        .get(client.context.matterId)
        .then((matter) => {
          setCurrentMatter(matter);
          setLoading(false);
        })
        .catch((err) =>
          logError(
            new SDKError(err, {
              action: "matters.get",
              input: { matterId: client.context.matterId },
            }),
          ),
        );
      client.matters.observe(setCurrentMatter);
    }
  }, [isInit, client?.context?.matterId, shouldObserve]);

  if (!isInit || !client) return undefined;

  return {
    loading,
    get: async (matterId?: string) => {
      try {
        return await client.matters.get(matterId);
      } catch (error) {
        throw new SDKError(error, {
          action: "matters.get",
          input: { matterId },
        });
      }
    },
    current: currentMatter,
  };
};
