import styled from "@emotion/styled";
import { useState } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { tagItems } from "@smart/itops-smokeball-components-dom";

import { AreaInfo } from "../../types";

const AreaSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .search {
    padding: 0 1.5rem;

    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid ${(props) => props.theme.palette.disabled.base};
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }
  }

  .selectable-areas {
    flex: 1;
    padding: 0.5rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};
    overflow-y: auto;

    > div {
      color: ${(props) => props.theme.palette.foreground.base};
    }
  }

  .selected-areas {
    display: flex;
    max-height: 11rem;
    font-size: ${(props) => props.theme.fontSize.base};
    border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};

    .area-name {
      min-width: 10rem;
      max-width: 10rem;
      margin: 0 1.5rem;
      padding: 0.5rem 0;
    }

    .areas {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding: 0.5rem 0;
      overflow-y: auto;

      .selected {
        display: flex;
        align-items: center;
        max-height: 3rem;
        border: 1px solid ${(props) => props.theme.palette.disabled.base};
        border-radius: 5px;
        padding: 0.4rem;

        .deselect {
          margin: 0 0 0 0.5rem;
          padding: 0;
        }
      }
    }
  }
`;

type AreaSelectProps = {
  name: string;
  areas: AreaInfo[];
  selected: AreaInfo[];
  empty: string;
  onToggle: (value: AreaInfo) => void;
  onToggleAll: (select: boolean) => void;
};

const AreaSelect = ({
  name,
  areas,
  empty,
  selected,
  onToggle,
  onToggleAll,
}: AreaSelectProps) => {
  const [search, setSearch] = useState<string>("");
  const searchText = search?.trim();

  return (
    <AreaSelectWrapper>
      <div className="search">
        <input
          data-testid="search-areas"
          type="search"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="selectable-areas">
        {tagItems({
          name,
          options: searchText
            ? areas.filter((a) =>
                a.displayName
                  .toLocaleLowerCase()
                  .includes(searchText.toLocaleLowerCase()),
              )
            : areas,
          keys: (area) => area.name,
          selected,
          onToggle,
          onToggleAll,
          isFiltering: !!searchText,
          render: (area) => area.displayName,
          empty,
        })}
      </div>
      {!!selected.length && (
        <div className="selected-areas" data-testid="selected-areas">
          <div className="area-name">Selected {name}:</div>
          <div className="areas">
            {selected.map((s) => (
              <div className="selected" key={s.name}>
                <span>{s.displayName}</span>
                <IconButton
                  className="deselect"
                  size={11}
                  name="cancel"
                  palette="foreground"
                  onClick={() => onToggle(s)}
                  data-testid={`deselect ${s.name}`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </AreaSelectWrapper>
  );
};

export { AreaSelect };
