import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

import { intakeTheme } from "@smart/bridge-intake-components-dom";

import { Card, CardProps } from "./card";
import { CornerBanner } from "./corner-banner";
import { Header } from "./header";
import { HeroSection } from "./hero-section";

const SampleWebpageWrapper = styled.div`
  .page-content {
    .content {
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 1rem;
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
  }
`;

type SampleWebpageProps = {};

const SampleWebpage = ({ children }: PropsWithChildren<SampleWebpageProps>) => {
  const sampleMenuItems = ["Home", "Services", "About"];
  const cards: CardProps[] = [
    {
      title: "Lorem ipsum dolor sit",
      buttonLabel: "Neque porro",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ligula velit, vehicula sed augue eu, sodales efficitur orci. In hac habitasse platea dictumst. Maecenas lacinia pharetra dui, nec rutrum massa vehicula in. Quisque faucibus justo a lacus tristique cursus. Nunc scelerisque neque a enim facilisis vehicula. Vivamus mi leo, viverra in semper ut, ultricies at enim. Etiam non arcu quis dui mattis rutrum. Aliquam erat volutpat.",
    },
    {
      title: "Suspendisse feugiat dolor",
      buttonLabel: "Neque porro",
      content:
        "Suspendisse feugiat dolor libero. Nam eget malesuada magna. Donec consectetur, mi et finibus interdum, lectus sem maximus orci, vel ultricies nisl nibh ut ligula. Nunc porta mauris nisi, et feugiat metus pellentesque lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In diam dolor, tempus eu justo at, elementum rhoncus tortor. Pellentesque a fringilla tellus. Nunc faucibus in libero ut placerat.",
    },
    {
      title: "Duis nec pharetra",
      buttonLabel: "Neque porro",
      content:
        "Vivamus vehicula et elit id faucibus. Duis nec pharetra neque. Pellentesque non ex vitae risus tincidunt cursus sed ac mi. Nulla eget dignissim nibh, eget tincidunt dolor. Fusce dapibus dui vitae lacinia tristique. Sed id tristique lectus. Sed ac dui ornare, feugiat nunc vel, molestie lacus. Mauris condimentum viverra dapibus.",
    },
    {
      title: "Duis nec pharetra neque",
      buttonLabel: "Neque porro",
      content:
        "Vivamus vehicula et elit id faucibus. Duis nec pharetra neque. Pellentesque non ex vitae risus tincidunt cursus sed ac mi. Nulla eget dignissim nibh, eget tincidunt dolor. Fusce dapibus dui vitae lacinia tristique. Sed id tristique lectus. Sed ac dui ornare, feugiat nunc vel, molestie lacus. Mauris condimentum viverra dapibus.",
    },
  ];

  return (
    <ThemeProvider theme={intakeTheme}>
      <SampleWebpageWrapper>
        <CornerBanner
          content="Preview"
          title="Responses will not be saved in preview mode"
        />
        <Header menuItems={sampleMenuItems} />
        <div className="page-content">
          <HeroSection
            title="Neque porro quisquam?"
            content="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
            buttonLabel="Suspendisse"
          />
          <section className="content" id="content">
            <ul>
              {cards.map((cardProp) => (
                <Card
                  key={cardProp.title}
                  title={cardProp.title}
                  image={cardProp.image}
                  content={cardProp.content}
                  buttonLabel={cardProp.buttonLabel}
                />
              ))}
            </ul>
          </section>
        </div>
        {children}
      </SampleWebpageWrapper>
    </ThemeProvider>
  );
};

export { SampleWebpageProps, SampleWebpage };
