import { useNavigate } from "react-router-dom";

import {
  ButtonRow,
  CloseButton,
  Icon,
  IconButton,
  Rule,
  Title,
} from "@smart/itops-components-dom";
import {
  useLoadFields,
  useLoadGroupsWithFields,
} from "@smart/manage-gql-client-dom";

import { DeleteSection } from "./delete";
import { EditSection } from "./edit";
import { GqlSection } from "./types";
import { FieldList } from "../fields";
import { FormDisplayMode } from "../forms/types";

type SectionViewProps = {
  mode: FormDisplayMode;
  section: GqlSection;
};

const SectionView = ({
  mode,
  section: {
    uri,
    values: { formUri, order, title, links },
  },
}: SectionViewProps) => {
  const fields = useLoadFields({ formUri, sectionUri: uri });
  const groups = useLoadGroupsWithFields({ formUri, sectionUri: uri });
  const navigate = useNavigate();
  const collapse = () => navigate("./", { replace: true });

  return (
    <>
      <Title
        left={
          <IconButton
            icon={<Icon name="angleRight" rotate={90} />}
            onClick={collapse}
          />
        }
        text={title}
        size={0.6}
        right={<CloseButton search />}
      />
      <EditSection
        mode={mode}
        locators={{ uri, formUri, order }}
        defaultValues={{ title, links: links || undefined }}
        debounce
      />
      {mode === "edit" && (
        <ButtonRow>
          <DeleteSection variables={{ uri, formUri }} />
        </ButtonRow>
      )}
      <Rule />
      <FieldList
        mode={mode}
        formUri={formUri}
        sectionUri={uri}
        fields={fields.result || []}
        groups={groups.result || []}
      />
    </>
  );
};

export { SectionView, SectionViewProps };
