import { zone } from "@smart/bridge-resources-basic";
import { buildCloudfrontDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";

export const cdn = buildCloudfrontDef({
  zone,
  namespace: buildNamespace({ system, entity: "manage" }),
  subdomain: "app",
  fallbackRoute: "index.html",
});
