import styled from "@emotion/styled";

export const FullButtonRow = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.fontSize.base};
  gap: 1rem;
  margin: 0.6rem 0;
  width: 100%;

  & > button {
    flex: 1;
  }
`;

export const ButtonRow = styled.div<{
  align?: "center" | "flex-start" | "flex-end";
}>`
  display: flex;
  flex-flow: row;
  font-size: ${(props) => props.theme.fontSize.base};
  gap: 1rem;
  align-items: center;
  justify-content: ${(props) => props.align || "center"};

  margin: 1.2rem 0;
  width: 100%;
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
`;
