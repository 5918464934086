import { Skeleton } from "../skeleton";

export const CellSkeleton = () => {
  const baseStyles = "block rounded w-3/4 h-3/4 overflow-hidden";

  return (
    <span className={baseStyles}>
      <Skeleton />
    </span>
  );
};
