import { ApolloError } from "@apollo/client";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  IconButton,
  InlineForm,
  Input,
  Label,
  Spinner,
  Modal,
} from "@smart/itops-components-dom";
import {
  useLazyLoadTeams,
  useUpdateFormOwnership,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

type AssignFormFirmProps = {
  formUri: string;
  title: string;
  loading: boolean;
  error: ApolloError | undefined;
  data: Gql.TeamsQuery | undefined;
  close: () => void;
};

const AssignFormFirm = ({
  formUri,
  title,
  error,
  loading,
  data,
  close,
}: AssignFormFirmProps) => {
  const [update, updating] = useUpdateFormOwnership();

  if (loading || updating.loading) return <Spinner size={4} />;
  if (error) return <p>{error.message}</p>;
  if (!data) return null;

  if (!data.teams) return <p>Firm Not Found</p>;

  return (
    <>
      <p>
        Assign &quot;{title}&quot; to &quot;{data.teams.values.name}&quot;?
      </p>
      <p>
        <Button
          type="button"
          onClick={async () => {
            await update({
              variables: { uri: formUri, teamUri: data.teams?.uri! },
            });
            close();
          }}
        >
          Assign
        </Button>
      </p>
    </>
  );
};

type AssignFormProps = { formUri: string; title: string };

const AssignForm = ({ formUri, title }: AssignFormProps) => {
  const [open, setOpen] = useState(false);
  const [loadTeam, { data, error, loading }] = useLazyLoadTeams();
  const { register, handleSubmit } = useForm({
    defaultValues: { teamId: "" },
  });

  return (
    <>
      <IconButton
        name="building"
        text="Assign Form"
        onClick={() => setOpen(true)}
      />
      {open && (
        <Modal
          position="fixed"
          marginBottom={10}
          heading="Assign to Firm"
          showCloseButton
          onClose={() => setOpen(false)}
        >
          <InlineForm
            onSubmit={handleSubmit(async ({ teamId }) =>
              loadTeam({ variables: { teamId } }),
            )}
          >
            <Label name="teamId" text="Team Id" loading={loading} mandatory>
              <Input {...register("teamId")} autoFocus />
            </Label>
            <Button type="submit">Search</Button>
          </InlineForm>
          <AssignFormFirm
            formUri={formUri}
            title={title}
            loading={loading}
            error={error}
            data={data}
            close={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export { AssignForm };
