import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { alertBannerSectionPalettes, alertToastPalettes } from "./class-names";
import { AlertProps } from "./types";
import { Button } from "../button";
import { Icon } from "../icon";
import { Link as LinkComponent } from "../link";

export const AlertBannerToast = ({
  variant,
  palette = "default",
  content,
  elaboration,
  action,
  onClose,
}: AlertProps) => {
  const isToast = variant === "toast";
  const palettes = isToast ? alertToastPalettes : alertBannerSectionPalettes;

  return (
    <div
      className={twMerge(
        clsx(
          palettes[palette].background,
          "flex items-start gap-[0.8rem] px-[1.6rem] pt-[0.6rem] pb-[1.4rem] readable:px-[4rem] readable:items-center readable:py-[0.8rem]",
          isToast && "rounded shadow-modal readable:px-[1.6rem]",
        ),
      )}
    >
      <span className="w-[2.4rem] h-[2rem] mt-[0.5rem] inline-flex justify-center items-center readable:mt-0">
        <Icon
          className={palettes[palette].iconColor}
          name={palettes[palette].iconName}
        />
      </span>
      <div className="pt-[0.5rem] justify-between flex-1 readable:flex readable:pt-0">
        <div
          className={clsx("text-ui", isToast && palettes[palette].iconColor)}
        >
          {content}
        </div>
        <div className="flex gap-[1.6rem] mt-[1.4rem] readable:mt-0">
          {elaboration && (
            <LinkComponent
              variant={
                isToast
                  ? (palettes as typeof alertToastPalettes)[palette]
                      .actionVariant
                  : "default"
              }
              text={elaboration.text}
              onClick={elaboration.onClick}
            />
          )}
          {action && (
            <LinkComponent
              variant={
                isToast
                  ? (palettes as typeof alertToastPalettes)[palette]
                      .actionVariant
                  : "subtle"
              }
              text={action.text}
              onClick={action.onClick}
            />
          )}
        </div>
      </div>
      <Button
        variant={
          isToast
            ? (palettes as typeof alertToastPalettes)[palette]
                .closeButtonVariant
            : "secondarySubtle"
        }
        leftIcon="solidXmarkLarge"
        onClick={onClose}
      />
    </div>
  );
};
