import QRCode from "react-qr-code";

import { Modal } from "@smart/itops-sb-design-system-dom";

export type GenerateQRCodeProps = {
  link: string | undefined;
  onClose: () => void;
  onCopied?: () => void;
};

export const GenerateQRCode = ({
  link,
  onClose,
  onCopied,
}: GenerateQRCodeProps) => {
  const copyQRCode = async () => {
    try {
      const qrCodeEl = document.getElementById("submission-link-qr-code");
      if (!qrCodeEl) throw new Error("Can't find the generated QR code image.");

      const qrCodeData = new XMLSerializer().serializeToString(qrCodeEl);

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const qrCodeImage = new Image();
      qrCodeImage.onload = () => {
        canvas.width = qrCodeImage.width;
        canvas.height = qrCodeImage.height;
        context?.drawImage(qrCodeImage, 0, 0);

        canvas.toBlob(
          async (blob) => {
            try {
              if (blob) {
                await navigator.clipboard.write([
                  new ClipboardItem({ [blob.type]: blob }),
                ]);
                if (onCopied) onCopied();
              }
            } catch (error) {
              console.error("Error writing image data to clipboard: ", error);
            }
          },
          "image/png",
          1,
        );
      };
      qrCodeImage.src = `data:image/svg+xml;base64,${btoa(qrCodeData)}`;
    } catch (error) {
      console.error("Error copying QR code image to clipboard: ", error);
    }
  };

  return (
    <Modal
      header={{
        iconBgColor: "bg-cyan-140",
        icon: "solidQrcode",
        text: "QR Code",
      }}
      size="small"
      open={!!link}
      onClose={onClose}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            type: "reset",
            onClick: () => onClose(),
          },
          {
            text: "Copy",
            type: "submit",
            onClick: () => copyQRCode(),
          },
        ],
      }}
    >
      {!!link && (
        <QRCode id="submission-link-qr-code" value={link} size={320} />
      )}
    </Modal>
  );
};
