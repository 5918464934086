export const dropdownBase =
  "bg-white rounded shadow-dropdownMenu min-w-[19rem] overflow-hidden z-10 py-[0.8rem]";

export const partBase =
  "flex flex-nowrap items-center gap-medium w-full cursor-pointer px-[1.6rem] py-4 justify-between";

export const partActive =
  "active:bg-cyan-40 active:border-l-2 active:border-l-blue-660";

export const partHover = "hover:bg-neutral-50";

export const partFocus =
  "focus:bg-neutral-50 focus:border-l-2 focus:border-l-blue-660";

export const partDisabled = "opacity-40 text-neutral-400 cursor-not-allowed";

export const partForceActive = "bg-cyan-40 border-l-2 border-l-blue-660";
