import styled from "@emotion/styled";
import { transparentize } from "polished";
import { ReactNode } from "react";

const FakeIframeWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: ${(props) =>
    transparentize(0.3, props.theme.palette.foreground.base)};
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    background: var(--background);
    border-radius: 3px;
    overflow: auto;
    width: 90%;
    height: 90%;
  }
`;

export const FakeIframe = ({ children }: { children: ReactNode }) => (
  <FakeIframeWrapper>
    <div className="modal">{children}</div>
  </FakeIframeWrapper>
);
