import { clsx } from "clsx";

export const tableStyles = "border-collapse min-w-full text-ui";

export const getHeaderCellClassNames = ({
  sortable,
  disabled,
}: {
  sortable?: boolean;
  disabled?: boolean;
}) => {
  const baseStyles = "border-neutral-100 font-inter font-semibold px-6 py-4";
  const hoverStyles = "hover:bg-neutral-50";
  const disabledStyles = "bg-white text-neutral-400";

  if (disabled) {
    return clsx(baseStyles, disabledStyles);
  }

  return clsx(
    baseStyles,
    sortable && hoverStyles,
    sortable && "cursor-pointer",
  );
};

export const getDataRowClassNames = ({ selected }: { selected?: boolean }) => {
  const baseStyles = "hover:bg-neutral-50";
  const selectedStyles = "bg-neutral-50";

  return clsx(baseStyles, selected && selectedStyles);
};

export const getDataCellClassNames = ({
  maxWidth,
  minWidth,
  width,
  action,
  selectable,
  loading,
  disabled,
}: {
  maxWidth?: number;
  minWidth?: number;
  width?: number;
  action?: boolean;
  selectable?: boolean;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const baseStyles = "border-neutral-100 border-t p-0";
  const actionStyles = "overflow-hidden text-ellipsis whitespace-nowrap";
  const disabledStyles = "bg-white text-neutral-400";

  if (disabled) {
    return clsx(baseStyles, disabledStyles);
  }

  return clsx(
    baseStyles,
    action && actionStyles,
    action && selectable && "cursor-pointer",
    maxWidth && `max-w-[${maxWidth}px]`,
    loading && minWidth && `min-w-[${minWidth}px]`,
    !loading && width && `w-[${maxWidth}px]`,
  );
};

export const getCellWrapperClassNames = ({
  selected,
}: {
  selected?: boolean;
}) => {
  const baseStyles = "w-full h-full px-6 py-4";

  return clsx(baseStyles, selected && "border-l-[2px] border-blue-560");
};
