import { useLazyQuery } from "@apollo/client";

import { queryDocuments } from "@smart/manage-gql-operations-dom";

export const useLazyLoadMatterRoles = () => {
  const [load] = useLazyQuery(queryDocuments.matterRoles);

  return async (variables: { matterId: string }) => {
    const result = await load({ variables });
    return result.data?.matterRoles;
  };
};
