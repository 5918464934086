import { Modal } from "@smart/itops-smokeball-components-dom";
import { FailureMessage } from "@smart/itops-ui-dom";

export type DeleteLinkProps = {
  deleteModal: [boolean, (v: boolean) => void];
  update: () => Promise<void>;
  onReset: () => void;
  loading?: boolean;
  error?: boolean;
};

export const DeleteLinkModal = ({
  deleteModal,
  update,
  onReset,
  loading,
  error,
}: DeleteLinkProps) => (
  <Modal
    title=" "
    subtitle="Delete Link?"
    subtitleIcon="information"
    open={deleteModal}
    onReset={onReset}
    buttons={[
      {
        text: "Delete Link",
        type: "submit",
        disabled: loading,
      },
      {
        text: "Cancel",
        type: "reset",
        palette: "primary",
        disabled: loading,
      },
    ]}
    closeOptions={{
      closeButton: false,
      escapeKey: false,
      clickOutside: false,
    }}
    onSubmit={update}
    loading={loading}
    error={error && <FailureMessage action="delete link" />}
  >
    <p>
      Deleting this link will prevent clients from being able to access the
      form.
    </p>
    <p>Are you sure you want to delete it?</p>
  </Modal>
);
