import { useState } from "react";

import { useManageFormsContext } from "@smart/manage-hooks-dom";

import { ImportDisplay } from "./display";
import { ImportedForm } from "./schema";
import { ImportUpload } from "./upload";

const ImportForm = () => {
  const [importedForm, setImportedForm] = useState<ImportedForm>();
  const { actingTeam } = useManageFormsContext();

  return (
    <>
      <ImportUpload
        importedForm={importedForm}
        setImportedForm={setImportedForm}
      />
      {importedForm && (
        <ImportDisplay importedForm={importedForm} teamUri={actingTeam?.uri} />
      )}
    </>
  );
};

export { ImportForm };

export * from "./process";
