import styled from "@emotion/styled";
import {
  autoUpdate,
  arrow,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingArrow,
} from "@floating-ui/react";
import { useRef, useState } from "react";

import { Icon } from "@smart/itops-icons-dom";

const ErrorDisplay = styled.div`
  background-color: ${(props) => props.theme.scheme.red.r20};
  color: ${(props) => props.theme.scheme.red.r100};
  margin-left: 0.6rem;
  padding: 0.4rem;
  border-radius: 100%;
`;

const ErrorContent = styled.div`
  background: ${(props) => props.theme.scheme.grey.r90};
  border-radius: 5px;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.scheme.grey.r5};
  font-weight: 400;
  line-height: 1.4;
  max-width: min(30rem, 96%);
  padding: ${(props) => props.theme.baseUnit * 0.4}rem
    ${(props) => props.theme.baseUnit * 0.8}rem;
  z-index: 1;

  svg {
    fill: ${(props) => props.theme.scheme.grey.r90};
  }

  p {
    margin: ${(props) => props.theme.baseUnit * 0.4}rem 0;
    white-space: normal;
  }
`;

export const EditableTextError = ({ error }: { error: string }) => {
  const arrowRef = useRef(null);
  const [open, onOpenChange] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(6),
      shift({ padding: 5 }),
      flip({ fallbackPlacements: ["right", "left", "top"] }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <ErrorDisplay
        data-testid="editable-error"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Icon library="lucide" name="AlertCircle" size={15} />
      </ErrorDisplay>
      {open && (
        <ErrorContent
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} tipRadius={2} />
          <p>{error}</p>
        </ErrorContent>
      )}
    </>
  );
};
