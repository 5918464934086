import { useState } from "react";

import { useAsync } from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Contact, Role } from "@smart/itops-smokeball-app-dom/src/types";
import { buildDisplayName } from "@smart/itops-utils-basic";
import { useLazyLoadMatterRoles } from "@smart/manage-gql-client-dom";

export type RoleContact = Contact & { email?: string | null; role: Role };

export type RoleContacts = {
  client: RoleContact | undefined;
  roleContacts: RoleContact[] | undefined;
  isLoading: boolean;
};

export const buildRoleContactName = (
  roleContact: RoleContact,
  option?: { hideRole?: boolean },
) => {
  let namePart;
  if (roleContact.person && Object.keys(roleContact.person).length > 0) {
    namePart = buildDisplayName(roleContact.person, roleContact.id);
  } else if (roleContact.company) {
    namePart = roleContact.company.name || roleContact.id;
  }

  return [namePart, !option?.hideRole && roleContact.role.name]
    .filter(Boolean)
    .join(" - ");
};

export const useRoleContacts = (): RoleContacts => {
  const [isLoading, setIsLoading] = useState(false);
  const [roleContacts, setRoleContacts] = useState<RoleContact[]>();
  const [client, setClient] = useState<RoleContact>();
  const getRoles = useLazyLoadMatterRoles();

  const { roles, contacts, hostType, context } = useSmokeballApp();

  useAsync(
    async (info) => {
      /**
       * Use the API instead for the "web" host as the SDK is not fully functional.
       * Refer to https://smokeball.atlassian.net/browse/LINK-1662 for more details.
       */
      if (
        (!roles && hostType !== "web") ||
        !contacts?.current ||
        !context?.matterId
      )
        return;

      setIsLoading(true);

      const existingRoles =
        hostType === "web"
          ? await getRoles({ matterId: context?.matterId })
          : (await roles!.get()).roles;
      const existingRoleContacts = (existingRoles || [])
        .flatMap((role) => {
          const contact = contacts.current!.find(
            (c) => c.id === role.contactId,
          );
          if (!contact) return [];
          /**
           * groupOfPeople.people in smokeball version 8.6 is { id: string }[]
           * and it changes to string[] in version 8.8
           * The check should be removed once all clients have upgraded to 8.8
           */
          const groupContactIds =
            (contact.groupOfPeople?.people?.map((p: any) =>
              typeof p === "string" ? p : p.id,
            ) as string[] | undefined) || [];
          const contactIds = [
            contact.id,
            ...(role.representativeIds || []).filter(Boolean),
            ...groupContactIds,
          ];

          return contacts
            .current!.filter((c) => contactIds.some((id) => id === c.id))
            .map((c) => ({
              ...c,
              email: c.person?.email || c.company?.email,
              role,
            }));
        })
        .sort((a, b) => a.lastUpdated - b.lastUpdated) as RoleContact[];
      const filteredExistingRoleContacts = existingRoleContacts.some(
        (c) => !!c.person,
      )
        ? existingRoleContacts.filter((c) => !!c.person)
        : existingRoleContacts;

      if (info.mounted) {
        setRoleContacts(filteredExistingRoleContacts);
        setClient(
          filteredExistingRoleContacts.find(
            (c) => c.role.isClient && (c.person || c.company),
          ),
        );
        setIsLoading(false);
      }
    },
    [contacts?.current],
  );

  return { client, roleContacts, isLoading };
};
