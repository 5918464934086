import { Editor, Element, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { EditorElement } from "@smart/itops-serialisation-basic";

export const createLinkNode = (
  text: string,
  url: string,
): EditorElement<"link"> => ({
  type: "link",
  url,
  children: [{ text }],
});

export const insertLink = (editor: Editor, text: string, url: string) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(text, url);

  if (selection && Range.isExpanded(selection)) {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.delete(editor, {
      at: selection,
    });
    Transforms.insertText(editor, text);
  } else {
    Transforms.insertNodes(editor, link);
  }
};

export const editLink = (
  editor: Editor,
  element: EditorElement<"link">,
  text: string,
  url: string,
) => {
  if (!url) return;

  const link = createLinkNode(text, url);

  Transforms.setNodes(editor, link, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });

  const path = ReactEditor.findPath(editor, element.children[0]);
  Transforms.delete(editor, { at: path });
  Transforms.insertText(editor, text, { at: path });
};

export const removeLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};
