import styled from "@emotion/styled";
import { transparentize } from "polished";
import { ReactNode } from "react";
import { FieldErrors, useFormContext } from "react-hook-form";

import { Icon, Spinner } from "@smart/itops-components-dom";
import { getValueByNestedKeys } from "@smart/itops-utils-basic";

const HorizontalFieldGroup = styled.fieldset`
  border: 0;
  background: none;
  margin: 0;
  padding: 0;

  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: 1rem;

  label {
    margin-bottom: -0.6rem;
  }
`;

const FieldGroup = styled.fieldset<{ grow?: boolean }>`
  border: 0;
  background: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: max-content auto;
  align-items: flex-start;
  row-gap: 1.2rem;

  ${(props) => (props.grow ? "flex: 1;" : "")}

  padding-bottom: 1.2rem;
`;

export const FieldGroupSeparator = styled.hr`
  width: 100%;
  margin: 0.5rem 0;
  grid-column: 1/-1;
  border: 1px solid ${(props) => props.theme.palette.background.highlight};
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.base};
  grid-column: 1;
`;

export const FieldContentWrapper = styled.div<{ grow?: boolean }>`
  display: flex;
  grid-column: 2;
  ${(props) => (props.grow ? "height: 100%;" : "")}
`;

export const FieldWrapper = styled.div<{ transparent?: boolean }>`
  width: 100%;
  position: relative;
  background: ${(props) =>
    props.transparent ? "none" : props.theme.palette.background.base};
  border: 1px solid
    ${(props) =>
      props.transparent ? "none" : props.theme.palette.background.highlight};
  border-radius: 2px;
  color: ${(props) => props.theme.palette.foreground.base};
  min-width: 10rem;

  input,
  select {
    padding: 0.6rem;
    width: 100%;
  }

  .spinner {
    position: absolute;
    top: 0.5rem;
    right: 2.5rem;
  }

  *::placeholder {
    color: #888;
  }

  select {
    padding-left: 0.2rem;
  }

  &:focus-within {
    border-color: ${(props) => props.theme.palette.primary.base};
  }

  &[aria-invalid="true"] {
    border-color: ${(props) => props.theme.palette.danger.accent};
  }

  &[aria-disabled="true"] {
    background: ${(props) =>
      props.transparent
        ? "none"
        : transparentize(0.6, props.theme.palette.background.base)};
    cursor: not-allowed;
    color: ${(props) =>
      transparentize(0.5, props.theme.palette.foreground.base)};

    input,
    select {
      cursor: not-allowed;
    }
  }
`;

const ErrorWrapper = styled.span`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.base};
  grid-column: 2;
  margin: -0.5rem 0 0;
  color: ${(props) => props.theme.palette.danger.base};

  .exclamation-icon {
    margin-right: 0.5rem;
  }
`;

const LabelWrapper = styled.div`
  min-width: 8rem;
  padding: 0.45rem 0;
`;

export type FieldErrorProps = {
  name: string;
  error?: string;
  errorAction?: ReactNode;
};

export const FieldError = ({ name, error, errorAction }: FieldErrorProps) =>
  error ? (
    <ErrorWrapper id={`${name}-error`}>
      <Icon
        className="exclamation-icon"
        size={15}
        name="exclamationCircleFilled"
      />
      {error}
      {errorAction}
    </ErrorWrapper>
  ) : null;

const AsteriskWrapper = styled.span`
  color: ${(props) => props.theme.palette.danger.base};
  margin-right: 0.25rem;
`;

export const RequiredAsterisk = () => <AsteriskWrapper>*</AsteriskWrapper>;

type FieldProps = {
  text: string | ReactNode;
  name: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  children: ReactNode;
  grow?: boolean;
  transparent?: boolean;
  errorAction?: ReactNode;
  className?: string;
};

const Field = ({
  text,
  name,
  required,
  loading,
  disabled,
  children,
  grow,
  transparent,
  errorAction,
  className,
}: FieldProps) => {
  const context = useFormContext();
  const fieldError = getValueByNestedKeys<FieldErrors[string]>(
    name.split("."),
    context?.formState.errors,
  )?.message;

  return (
    <>
      <LabelWrapper>
        <Label
          htmlFor={name}
          aria-disabled={disabled}
          aria-invalid={!!fieldError}
          aria-errormessage={fieldError && `${name}-error`}
        >
          {text}
          {required && (
            <>
              {" "}
              <RequiredAsterisk />
            </>
          )}
        </Label>
      </LabelWrapper>
      <FieldContentWrapper grow={grow} className={className}>
        <FieldWrapper
          aria-invalid={!!fieldError}
          aria-disabled={disabled}
          transparent={transparent}
        >
          {children}
          {loading && <Spinner size={2} className="spinner" />}
        </FieldWrapper>
      </FieldContentWrapper>
      <FieldError
        name={name}
        error={fieldError?.toString()}
        errorAction={errorAction}
      />
    </>
  );
};

type InfoFieldProps = {
  text?: string;
  className?: string;
  children: ReactNode;
};

const InfoField = ({ text, className, children }: InfoFieldProps) => (
  <>
    <Label>{text}</Label>
    <FieldContentWrapper className={className}>{children}</FieldContentWrapper>
  </>
);

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.base};
  margin-top: -0.5rem;

  .info-icon {
    color: ${(props) => props.theme.palette.primary.base};
    margin-right: 0.3rem;
  }
`;

export {
  HorizontalFieldGroup,
  FieldGroup,
  FieldProps,
  Field,
  InfoFieldProps,
  InfoField,
  InfoWrapper,
};
