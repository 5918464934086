import { useController, useFormContext } from "react-hook-form";

import { linkConditionLabel } from "@smart/bridge-types-basic";
import { IconButton, Input, Select } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";

import { DropdownCheckbox } from "./dropdown-checkbox";
import { SwitchableValueSelect } from "./switchable-value-select";
import { GqlFieldValues } from "./types";
import {
  getApplicableConditions,
  LinkContainer,
  LinkValueContainer,
} from "./utils";
import { FormDisplayMode } from "../forms/types";
import { FieldSchema } from "../shared-schemas";

type LinkFieldProps = {
  mode: FormDisplayMode;
  index: number;
  fieldValues: GqlFieldValues | undefined;
  onRemove: () => void;
  orderWarning?: boolean;
};

const LinkField = ({
  mode,
  index,
  fieldValues,
  onRemove,
  orderWarning,
}: LinkFieldProps) => {
  const { control, register } = useFormContext<UpdateHookValues<FieldSchema>>();
  const valueFieldName = `links.${index}.value` as const;
  const conditionFieldName = `links.${index}.condition` as const;
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: valueFieldName });
  const disabled = mode === "view";

  const renderValueInput = () => {
    switch (fieldValues?.type) {
      case "text":
      case "number":
      case "date":
        return (
          <Input
            {...register(valueFieldName, {
              required: "Please enter a value",
            })}
            type={fieldValues?.type}
            maxLength={128}
            disabled={disabled}
          />
        );
      case "checkbox": {
        return (
          <DropdownCheckbox
            options={fieldValues.options}
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
          />
        );
      }
      case "choice":
      case "yesNo":
        return (
          <SwitchableValueSelect
            conditionFieldName={conditionFieldName}
            options={fieldValues.options}
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
          />
        );
      default:
        return (
          <Input
            {...register(valueFieldName, {
              required: "Please enter a value",
            })}
            maxLength={128}
            disabled={disabled}
          />
        );
    }
  };

  return fieldValues ? (
    <LinkContainer>
      <LinkValueContainer className="field-label">
        <Input placeholder={fieldValues.label} readOnly />
        {orderWarning && (
          <div className="warning-text">
            This condition link may appear after the question
          </div>
        )}
      </LinkValueContainer>
      <Select
        {...register(conditionFieldName)}
        className="condition"
        disabled={disabled}
      >
        {getApplicableConditions(fieldValues.type).map((v) => (
          <option key={v} value={v}>
            {linkConditionLabel[v]}
          </option>
        ))}
      </Select>
      <LinkValueContainer className="condition-value">
        {error && <p className="error">Please select a value</p>}
        {renderValueInput()}
      </LinkValueContainer>
      {mode === "edit" && (
        <IconButton
          name="cancel"
          palette="danger"
          size={10}
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        />
      )}
    </LinkContainer>
  ) : null;
};

export { LinkField, LinkFieldProps };
