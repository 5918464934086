import { z } from "zod";

import {
  fieldTypeValue,
  formCategorySchema,
  groupTypeValue,
  linkConditionValue,
} from "@smart/bridge-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const linkSchema = z.object({
  fieldUri: z.string(),
  condition: z.enum(linkConditionValue),
  value: z.string(),
  hide: z.boolean().optional().nullable(),
});

const fieldsSchema = z.object({
  uri: z.string(),
  formUri: z.string(),
  sectionUri: z.string(),
  groupUri: z.string().optional(),
  order: z.string(),
  label: z.string(),
  hint: z.string(),
  type: z.enum(fieldTypeValue),
  mandatory: z.boolean(),
  allowCustomResponse: z.boolean().optional(),
  options: z.array(z.object({ label: z.string(), value: z.string() })),
  links: z.array(linkSchema).optional(),
  layout: z
    .object({
      id: z.string(),
      providerId: z.string(),
      name: z.string(),
      parentId: z.string().optional(),
      parentName: z.string().optional(),
      parentProviderId: z.string().optional(),
    })
    .optional(),
  field: z
    .object({
      name: z.string(),
      type: z.string(),
      possibleValues: z.array(z.string()).optional(),
    })
    .optional(),
});

export const importedFormSchema = z.object({
  form: z.object({
    uri: z.string(),
    title: z.string(),
    category: z.preprocess(
      (val) => (formCategorySchema.safeParse(val).success ? val : "matter"),
      formCategorySchema,
    ),
    response: z.string(),
    matterTypes: z
      .array(
        z.object({
          id: z.string(),
          source: z.string(),
          name: z.string(),
          category: z.string(),
          location: z.string(),
          representativeOptions: z.array(z.string()),
        }),
      )
      .optional(),
  }),
  sections: z.array(
    z.object({
      uri: z.string(),
      formUri: z.string(),
      order: z.string(),
      title: z.string(),
      links: z.array(linkSchema).optional(),
    }),
  ),
  groups: z.array(
    z.object({
      uri: z.string(),
      formUri: z.string(),
      sectionUri: z.string(),
      order: z.string(),
      label: z.string(),
      hint: z.string(),
      type: z.enum(groupTypeValue),
      links: z.array(linkSchema).optional(),
      layout: z
        .object({
          id: z.string(),
          providerId: z.string(),
          name: z.string(),
          parentId: z.string().optional(),
          parentName: z.string().optional(),
          parentProviderId: z.string().optional(),
        })
        .optional(),
      field: z
        .object({
          name: z.string(),
          type: z.string(),
          possibleValues: z.array(z.string()).optional(),
        })
        .optional(),
      repeatable: z.boolean(),
      allowedRepeatable: z.boolean().optional(),
    }),
  ),
  fields: z.array(fieldsSchema),
});

export type ImportedForm = z.infer<typeof importedFormSchema>;

export const guardImportedForm = wrapSchema(importedFormSchema);
