import { clsx } from "clsx";
import { Editable, Slate } from "slate-react";

import { isTestEnv } from "@smart/itops-utils-basic";

import { Block, Leaf, TestEditable } from "./elements";
import {
  EditorContextProvider,
  EditorPlaceholders,
  useEditorState,
} from "./hooks";
import { Toolbar } from "./toolbar";
import { Label } from "../label";

const editorWrapperStyles =
  "border-neutral-200 border border-solid rounded text-ui";
const editableStyles =
  "flex flex-col gap-medium min-h-[25rem] w-full p-4 outline-none";

export type EditorProps<P extends string> = {
  initialTextValue?: string;
  placeholders?: EditorPlaceholders<P>;
  onValueChange?: (v: string) => void;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  label?: string;
  mandatory?: boolean;
  id?: string;
  error?: boolean;
  message?: string;
};

export const Editor = <P extends string>({
  initialTextValue,
  placeholders,
  onValueChange,
  onBlur,
  className,
  disabled,
  autoFocus,
  dataTestId,
  label,
  mandatory,
  id,
  error,
  message,
}: EditorProps<P>) => {
  const { editor, initialValue, onChange, onKeyDown, modal, setModal } =
    useEditorState({
      initialTextValue,
      onValueChange,
      autoFocus,
    });

  return (
    <Label
      text={label}
      mandatory={mandatory}
      disabled={disabled}
      name={id}
      error={!!error}
      message={message}
    >
      <div
        className={clsx(editorWrapperStyles, className)}
        data-testid={dataTestId}
        onBlur={onBlur}
      >
        <Slate
          editor={editor}
          initialValue={initialValue}
          onValueChange={onChange}
        >
          <EditorContextProvider
            value={{
              modal,
              setModal,
              placeholders,
            }}
          >
            <Toolbar />
            <Editable
              className={editableStyles}
              data-testid={dataTestId && `${dataTestId}-editable`}
              readOnly={disabled}
              renderElement={Block}
              renderLeaf={Leaf}
              onKeyDown={onKeyDown}
              placeholder=""
              spellCheck
            />
            {isTestEnv() && (
              <TestEditable
                dataTestId={dataTestId && `${dataTestId}-textarea`}
                disabled={disabled}
                onKeyDown={onKeyDown}
              />
            )}
          </EditorContextProvider>
        </Slate>
      </div>
    </Label>
  );
};

export * from "./displayer";
