import { z } from "zod";

import {
  activityEventDataTypeValue,
  activityTypeValue,
} from "@smart/bridge-types-basic";
import { buildApiGatewaySchemas } from "@smart/itops-constructs-basic";
import { undefinedOrEmptyObject } from "@smart/itops-utils-basic";

import { apiGateway } from "./api-gateway";

export const echoLambdaSchema = {
  input: z.any(),
  output: z.object({
    input: z.any(),
  }),
};

export const gqlLambdaSchema = {
  input: z.any(),
  output: z.any(),
};

export const meLambdaSchema = {
  input: undefinedOrEmptyObject,
  output: z.object({
    userUri: z.string(),
    teamUri: z.string().optional(),
    source: z.string().optional(),
    email: z.string(),

    firstName: z.string(),
    lastName: z.string(),
    roles: z.string(),
    initials: z.string().optional(),
    picture: z.string().optional(),

    teamName: z.string().optional(),
  }),
};

export const assetLambdaSchema = {
  input: z.object({
    key: z.string(),
    destination: z.enum(["Asset", "IntakeUploadedFiles"]).optional(),
  }),
  output: z.object({
    key: z.string(),
    downloadUrl: z.string(),
    uploadUrl: z.string(),
  }),
};

export const activityEventQueuerLambdaSchema = {
  input: z.array(
    z.object({
      type: z.enum(activityEventDataTypeValue),
      activity: z.enum(activityTypeValue),
      matterId: z.string(),
      formId: z.string().optional(),
      submissionId: z.string().optional(),
      fieldId: z.string().optional(),
      createdAt: z.string(),
    }),
  ),
  output: z.array(z.object({ operationId: z.string() })),
};

export const apiSchemas = buildApiGatewaySchemas(apiGateway, {
  asset: assetLambdaSchema,
  echo: echoLambdaSchema,
  gql: gqlLambdaSchema,
  me: meLambdaSchema,
  activityEventQueuer: activityEventQueuerLambdaSchema,
});
