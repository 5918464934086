import { useMemo } from "react";
import { z } from "zod";

import { archie } from "@smart/bridge-images-dom";
import {
  Locale,
  formCategoryLabel,
  formCategoryValue,
} from "@smart/bridge-types-basic";
import { RadioButtonOptionItem } from "@smart/itops-ui-dom";

export const creatingFormSchema = ({ terms }: Pick<Locale, "terms">) =>
  useMemo(
    () =>
      z.object({
        formCategory: z.enum(["lead", "matter"]),
        formName: z.string().min(1, "Please enter a form name"),
        locations: z
          .array(z.string())
          .min(1, `Please select one or more ${terms.state.plural}`),
        matterTypeGroups: z
          .array(
            z.object({
              name: z.string(),
              category: z.string(),
              matterTypes: z.array(
                z.object({
                  id: z.string(),
                  name: z.string(),
                  category: z.string(),
                  location: z.string(),
                  source: z.string(),
                  representativeOptions: z.array(z.string()),
                }),
              ),
            }),
          )
          .min(1, "Please select one or more matter types"),
        creationType: z.enum(["blank", "generate", "upload"]),
        filePath: z.string().optional(),
      }),
    [terms.state.plural],
  );

export type CreatingFormOptions = z.infer<
  ReturnType<typeof creatingFormSchema>
>;

export const creatingFormDefaults: CreatingFormOptions = {
  formCategory: "lead",
  formName: "",
  creationType: "generate",
  filePath: undefined,
  locations: [],
  matterTypeGroups: [],
};

export const formCategoryOptions = formCategoryValue.map((value) => ({
  value,
  label: formCategoryLabel[value],
}));

const Archie = () => (
  <img
    src={archie}
    alt="Archie"
    style={{ display: "block", width: "2.6rem" }}
  />
);

export const formCreationOptions = ({
  terms,
  isSmokeballProvider,
}: Pick<Locale, "terms"> & { isSmokeballProvider: boolean }) =>
  useMemo(
    (): RadioButtonOptionItem[] => [
      {
        value: "generate",
        label: `Generate using ${isSmokeballProvider ? "Smokeball" : ""} AI`,
        detail: <Archie />,
      },
      {
        value: "upload",
        label: "Upload and convert an existing form",
        detail: <Archie />,
      },
      {
        value: "blank",
        label: "Build custom form",
      },
    ],
    [terms.digitise.future, isSmokeballProvider],
  );

export const editingFormInfoSchema = ({ terms }: Pick<Locale, "terms">) =>
  useMemo(
    () =>
      z.object({
        formCategory: z.enum(["lead", "matter"]),
        locations: z
          .array(z.string())
          .min(1, `Please select one or more ${terms.state.plural}`),
        matterTypeGroups: z
          .array(
            z.object({
              name: z.string(),
              category: z.string(),
              matterTypes: z.array(
                z.object({
                  id: z.string(),
                  name: z.string(),
                  category: z.string(),
                  location: z.string(),
                  source: z.string(),
                  representativeOptions: z.array(z.string()),
                }),
              ),
            }),
          )
          .min(1, "Please select one or more matter types"),
      }),
    [terms.state.plural],
  );

export type EditingFormInfo = z.infer<ReturnType<typeof editingFormInfoSchema>>;
