import { useEffect, useState } from "react";

import { logError, SDKError } from "@smart/itops-utils-basic";

import { UseSDKApiOptions, Version, WebNavigationTarget } from "../types";

export const useHost = ({ client, isInit }: UseSDKApiOptions) => {
  const [version, setVersion] = useState<Version>();

  useEffect(() => {
    if (isInit && client) {
      client.host
        .version()
        .then(setVersion)
        .catch((err) => logError(new SDKError(err, { action: "version.get" })));
    }
  }, [isInit]);

  if (!isInit || !client) return undefined;

  return {
    close: () => {
      try {
        client.host.close();
      } catch (error) {
        throw new SDKError(error, { action: "host.close" });
      }
    },
    selectTab: (tab: number) => {
      try {
        client.host.selectTab(tab);
      } catch (error) {
        throw new SDKError(error, { action: "host.selectTab", input: { tab } });
      }
    },
    version,
    isEarlierThanVersion: ({
      major,
      minor,
    }: {
      major: number;
      minor: number;
    }) => {
      if (!version) return undefined;
      if (version.major === major) return version.minor < minor;
      return version.major < major;
    },
    open: (options: { url: string; title: string }) => {
      client.host.open(options);
    },
    webNavigate: (target: WebNavigationTarget) => {
      // Non-standard SDK api support navigating in Smokeball Web (Billing) - LINK-2202
      const navigationTargetTypeMapping: Record<WebNavigationTarget, string> = {
        "intake-form-builder": "intake",
      };
      window.parent.postMessage(
        {
          operation: "host.webNavigate",
          type: navigationTargetTypeMapping[target],
        },
        "*",
      );
    },
  };
};
