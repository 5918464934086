import { Overlay } from "@smart/itops-components-dom";

export const IntakeNotFound = () => (
  <Overlay
    icon="form"
    heading="Not Found"
    subHeading="Unfortunately, the page you're looking for could not be found. To find out more, please contact the sender."
    level="DEFAULT"
  />
);
