import { useState } from "react";

import { GroupNS } from "@smart/bridge-types-basic";
import { IconButton, Modal } from "@smart/itops-components-dom";
import { rankBetween } from "@smart/itops-utils-basic";

import { CreateGroupModal } from "./create-group-modal";

type CreateLayoutProps = {
  formUri: string;
  sectionUri: string;
  after?: string;
};

const CreateLayout = ({ formUri, sectionUri, after }: CreateLayoutProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        name="add"
        text="Add New Layout"
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <Modal position="fixed" marginBottom={10} heading="Add New Group">
          <CreateGroupModal
            locators={{
              formUri,
              sectionUri,
              uri: GroupNS.generateUri(),
              order: rankBetween({ after }),
            }}
            defaultValues={{
              hint: "",
              label: "",
            }}
            submit="Add"
            cancel="Cancel"
            mode="create"
            cancelled={() => setOpen(false)}
            submitted={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export { CreateLayout, CreateLayoutProps };
