import styled from "@emotion/styled";
import {
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "@hello-pangea/dnd";
import { transparentize } from "polished";
import { useEffect, useState } from "react";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { Icon } from "@smart/itops-icons-dom";
import {
  Button,
  ContextMenu,
  EditableText,
  ProgressOverlay,
} from "@smart/itops-ui-dom";

import { Field } from "./field";
import { Separator } from "./separator";
import { groupFieldsDroppableIdPrefix } from "../constants";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
  QuestionEditMode,
} from "../types";

type GroupCardProps = {
  group: GqlGroupValues;
  groupFields?: GqlFieldValues[] | null;
  isDragging?: boolean;
  onDeleteGroup: () => Promise<void>;
  onUpdateGroup: ({
    label,
    description,
    repeatable,
  }: {
    label?: string;
    description?: string;
    repeatable?: boolean;
  }) => Promise<void>;
  mode?: QuestionEditMode;
  editing?: boolean;
  loading?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  updatingItems: Record<string, boolean | undefined>;
  editingItemUri?: string;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  conditionActions: ConditionActions;
  onFieldTypeChange: OnFieldTypeChange;
  isDropAnimating: boolean;
  isValidSection: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

const Card = styled.div<{ current: boolean; shrinking?: boolean }>`
  background: var(--background);
  position: relative;

  padding: 0 2rem;
  border: 0.3rem solid
    ${(props) =>
      props.current
        ? props.theme.scheme.blue.r100
        : props.theme.scheme.grey.r30};
  border-radius: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  max-width: 80rem;
  width: 100%;

  .legend {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: ${(props) => props.theme.scheme.grey.r60};
    margin-top: -2.2rem;

    .group-title {
      background: var(--background);
      display: flex;
      align-items: center;
      gap: 0.3rem;
      max-width: 88%;
      padding: 0 0.6rem;
    }

    .group-action-wrapper {
      display: flex;
      padding-top: 0.35rem;

      .group-conditions {
        padding-left: 1rem;
        background: var(--background);
      }

      .group-actions {
        right: 2rem;
      }
    }
  }

  .description {
    color: ${(props) => props.theme.scheme.grey.r60};
    margin: 0 0.6rem;
  }

  .group-field-list {
    max-height: ${(props) => (props.shrinking ? "36rem" : "auto")};
    overflow: ${(props) => (props.shrinking ? "hidden" : "visible")};
    position: relative;

    .group-field-spacer {
      height: 1.4rem;
    }
  }

  .group-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2rem;
    overflow: hidden;
    border-radius: 1.5rem;
  }
`;

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--background);
  color: ${(props) => props.theme.scheme.grey.r60};
  border-radius: 100px;
  padding: 0.8rem;

  position: absolute;
  top: 2.6rem;
  left: -2.25rem;
`;

const ShrinkFade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15rem;
  background: linear-gradient(
    to top,
    ${(props) => props.theme.scheme.grey.r20},
    ${(props) => transparentize(1, props.theme.scheme.grey.r20)}
  );
  z-index: 10;
`;

const GroupFieldContainer = styled.div<{ shrinking?: boolean; index: number }>`
  position: relative;
  transition: margin-top 0.2s ease;
  margin-top: ${(props) => {
    if (!props.shrinking) return "0";
    return props.index ? "-12rem" : "-5rem";
  }};
  z-index: ${(props) => (props.shrinking ? props.index * -1 + 5 : "unset")};
`;

export const GroupCard = ({
  group,
  onDeleteGroup,
  editing,
  loading,
  onUpdateGroup,
  groupFields,
  updatingItems,
  onUpdateField,
  onDeleteField,
  editingItemUri,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  mode,
  isDragging,
  dragHandleProps,
  conditionActions,
  onFieldTypeChange,
  isDropAnimating,
  isValidSection,
  excludingFieldTemplates,
}: GroupCardProps) => {
  const {
    uri: groupUri,
    label: groupTitle = "",
    description,
    repeatable,
    field: groupMatterField,
    layout: groupLayout,
  } = group;
  const [isEditingTitle, setIsEditingTitle] = useState(editing);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [descriptionVisible, setDescriptionVisible] = useState(!!description);
  const [editedGroupTitle, setEditedGroupTitle] = useState(
    groupTitle || fieldFallbackLabel.group,
  );
  const [editedDescription, setEditedDescription] = useState<
    string | undefined
  >(description || "");
  const [isShrinking, setIsShrinking] = useState(false);
  useEffect(() => {
    setIsShrinking(!!isDragging);
  }, [isDragging]);

  useEffect(() => {
    setIsEditingTitle(editing);
  }, [editing]);

  return (
    <Card
      current={!!isEditingTitle || !!isEditingDescription || !!isDragging}
      shrinking={isShrinking}
    >
      <DragHandle
        className="handle"
        {...dragHandleProps}
        onMouseDown={() => setIsShrinking(true)}
        onMouseUp={() => setIsShrinking(false)}
      >
        <Icon library="lucide" name="GripVertical" size={24} />
      </DragHandle>
      <div className="legend">
        <div className="group-title">
          {repeatable && <Icon library="lucide" name="Repeat2" />}
          <EditableText
            text={editedGroupTitle}
            onChange={setEditedGroupTitle}
            onComplete={() => onUpdateGroup({ label: editedGroupTitle })}
            editing={isEditingTitle}
            onEditing={setIsEditingTitle}
            fontSize="subHeading"
            bold
          />
        </div>
        <div className="group-action-wrapper">
          {conditionActions.checkHasConditions(group.uri) && (
            <div className="group-conditions">
              <Button
                dataTestId="group-conditions-button"
                icon={{ library: "lucide", name: "Split", size: 16 }}
                kind="borderless"
                variant="highlightOrange"
                onClick={() => conditionActions.setEditingCondition(group)}
              />
            </div>
          )}
          <ContextMenu
            id="group-actions"
            className="group-actions"
            placement="bottom"
            tooltipText="Group settings"
            button={{
              kind: "borderless",
              icon: { library: "lucide", name: "SlidersHorizontal" },
              variant: "plain",
            }}
            items={[
              {
                text: "Description text",
                checked: descriptionVisible,
                onClick: async () => {
                  if (descriptionVisible) {
                    await onUpdateGroup({ description: "" });
                    setEditedDescription(undefined);
                  }
                  setDescriptionVisible(!descriptionVisible);
                },
              },
              {
                text: "Repeat fields",
                checked: !!repeatable,
                onClick: async () => {
                  await onUpdateGroup({ repeatable: !repeatable });
                },
              },
              {
                text: "Delete group",
                schemeColor: ["red", "r100"],
                icon: { library: "lucide", name: "Trash2" },
                onClick: onDeleteGroup,
                divider: "top",
              },
            ]}
          />
        </div>
      </div>
      {descriptionVisible && (
        <div className="description">
          <EditableText
            editing={isEditingDescription}
            text={editedDescription}
            onChange={setEditedDescription}
            onComplete={async () => {
              setIsEditingDescription(false);
              await onUpdateGroup({ description: editedDescription });
            }}
            placeholder="Description"
            fontSize="emphasis"
          />
        </div>
      )}
      <Droppable
        droppableId={`${groupFieldsDroppableIdPrefix}-${groupUri}`}
        type="GROUP"
      >
        {(provided) => (
          <div
            className="group-field-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Separator
              index={0}
              groupUri={groupUri}
              isFieldReordering={mode === "reordering"}
              isShrinking={isShrinking}
            />
            {groupFields?.length ? (
              groupFields.map((groupField, i) => (
                <GroupFieldContainer
                  key={groupField.uri}
                  shrinking={isShrinking}
                  index={i}
                  data-testid="group-field-container"
                >
                  <Draggable draggableId={groupField.uri} index={i}>
                    {(draggableProvided, snapshot) => (
                      <Field
                        index={i}
                        item={groupField}
                        loading={updatingItems[groupField.uri]}
                        shrinking={isShrinking}
                        onUpdateField={onUpdateField}
                        onDeleteField={onDeleteField}
                        draggableProvided={draggableProvided}
                        isDragging={snapshot.isDragging}
                        isEditing={groupField.uri === editingItemUri}
                        mode={mode}
                        matterLayouts={matterLayouts}
                        matterTypes={matterTypes}
                        loadMatterFields={loadMatterFields}
                        conditionActions={conditionActions}
                        groupLayout={groupLayout}
                        groupMatterField={groupMatterField}
                        onFieldTypeChange={onFieldTypeChange}
                        isDropAnimating={isDropAnimating}
                        isValidSection={isValidSection}
                        ignoreDraggableStyle={isDragging}
                        excludingFieldTemplates={excludingFieldTemplates}
                      />
                    )}
                  </Draggable>
                </GroupFieldContainer>
              ))
            ) : (
              <div className="group-field-spacer" />
            )}
            {provided.placeholder}
            {isShrinking && <ShrinkFade />}
          </div>
        )}
      </Droppable>
      {loading && (
        <div className="group-overlay">
          <ProgressOverlay position="bottom" size={0.3} indeterminate />
        </div>
      )}
    </Card>
  );
};
