import { ReactNode } from "react";

import { EditGroup } from "./edit-group";
import { GqlFieldValues, GqlGroupWithFields } from "./types";
import { FormDisplayMode } from "../forms/types";

type GroupItemProps = {
  mode?: FormDisplayMode;
  item: GqlGroupWithFields;
  children?: ReactNode;
};

const GroupItem = ({
  mode = "edit",
  children,
  item: {
    uri,
    values: {
      sectionUri,
      formUri,
      order,
      label,
      type,
      templateType,
      layout,
      field,
      hint,
      links,
      fields,
      repeatable,
      repeatPrompt,
      minRepeat,
      maxRepeat,
      allowedRepeatable,
    },
  },
}: GroupItemProps) => {
  const isRoleField = (groupfield: GqlFieldValues) =>
    type === "layoutContact" &&
    (groupfield.field?.name.startsWith("person/") ||
      groupfield.field?.name.startsWith("company/"));

  return (
    <EditGroup
      mode={mode}
      locators={{ uri, sectionUri, formUri, order }}
      defaultValues={{
        hint: hint || "",
        label: label || "",
        type,
        templateType: templateType || undefined,
        layout: layout || undefined,
        field: field || undefined,
        links: links || undefined,
        fields: fields
          ? fields.map((f) => ({
              ...f,
              roleFieldName: isRoleField(f)
                ? field?.displayName || undefined
                : undefined,
            }))
          : undefined,
        repeatable: repeatable || undefined,
        repeatPrompt: repeatPrompt || undefined,
        minRepeat: minRepeat !== null ? minRepeat : undefined,
        maxRepeat: maxRepeat !== null ? maxRepeat : undefined,
        allowedRepeatable: allowedRepeatable || undefined,
      }}
      debounce
    >
      {children}
    </EditGroup>
  );
};

export { GroupItem, GroupItemProps };
