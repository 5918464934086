/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlAddressFieldsFragment = {
  __typename: "Address";
  buildingLevel?: string | null;
  unitNumber?: string | null;
  unitType?: string | null;
  streetNumber?: string | null;
  streetName?: string | null;
  streetType?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locality?: string | null;
  county?: string | null;
  country?: string | null;
};

export const AddressFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "addressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "buildingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "unitNumber" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "streetNumber" } },
          { kind: "Field", name: { kind: "Name", value: "streetName" } },
          { kind: "Field", name: { kind: "Name", value: "streetType" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine1" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine2" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "zipCode" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "county" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlAddressFieldsFragment, unknown>;
