import styled from "@emotion/styled";
import { useState } from "react";

import { Input } from "@smart/itops-components-dom";

const EditLinkWrapper = styled.span`
  display: block;
  background-color: ${(props) => props.theme.palette.background.accent};
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  padding: 1rem;
`;

const EditLinkInput = styled(Input)`
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  margin-bottom: 0.5rem;
`;

const ActionsWrapper = styled.span`
  display: flex;
`;

const Button = styled.button<{ default?: boolean }>`
  margin: 0.4rem;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  background: ${(props) =>
    props.default
      ? props.theme.palette.primary.base
      : props.theme.palette.default.base};
  border: 1px solid
    ${(props) =>
      props.default
        ? props.theme.palette.primary.accent
        : props.theme.palette.default.accent};
  color: ${(props) =>
    props.default
      ? props.theme.palette.primary.overlay
      : props.theme.palette.default.overlay};

  display: flex;
  align-items: center;
`;

type EditLinkProps = {
  displayText?: string;
  linkUrl?: string;
  close: () => void;
  save: (text: string, url: string) => void;
};

export const EditLink = ({
  displayText = "",
  linkUrl = "",
  close,
  save,
}: EditLinkProps) => {
  const [text, setText] = useState(displayText);
  const [url, setUrl] = useState(linkUrl);
  const onSave = () => {
    if (text && url) {
      save(text, url);
      close();
    }
  };

  return (
    <EditLinkWrapper>
      <EditLinkInput
        placeholder="Display text"
        value={text}
        onChange={({ target }) => setText(target.value)}
      />
      <EditLinkInput
        placeholder="Link URL"
        value={url}
        onChange={({ target }) => setUrl(target.value)}
      />
      <ActionsWrapper>
        <Button onClick={close}>Cancel</Button>
        <Button default onClick={onSave}>
          Save
        </Button>
      </ActionsWrapper>
    </EditLinkWrapper>
  );
};
