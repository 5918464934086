import { clsx } from "clsx";

export type FolderInfo = {
  id: string;
  name: string;
  parentId: string | undefined | null;
};

type FolderPathDisplayProps = {
  currentFolder: FolderInfo;
  loadedFolders: Record<string, FolderInfo>;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
};

export const FolderPathDisplay = ({
  currentFolder,
  loadedFolders,
  setViewingFolderId,
}: FolderPathDisplayProps) => {
  const getFolders = () => {
    const folders: FolderInfo[] = [];

    folders.unshift(currentFolder);

    let { parentId } = currentFolder;
    while (parentId) {
      const parentFolder = loadedFolders[parentId];
      folders.unshift(parentFolder);
      parentId = parentFolder.parentId;
    }

    return folders;
  };

  return (
    <div className="flex flex-wrap items-center bg-gray-100 py-4 px-2">
      {getFolders().map((folder) => (
        <div
          key={folder.id}
          className="flex items-center after:content-slash text-neutral-700"
        >
          <button
            className={clsx(
              "cursor-pointer bg-none border-none ",
              currentFolder.id !== folder.id && "underline text-blue-560",
            )}
            type="button"
            onClick={() => setViewingFolderId(folder.id)}
          >
            {folder.id === "root" ? "Documents" : folder.name}
          </button>
        </div>
      ))}
    </div>
  );
};
