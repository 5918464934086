import { SDKError } from "@smart/itops-utils-basic";

import {
  AddRelationshipRequest,
  UpdateRelationshipRequest,
  UseSDKApiOptions,
} from "../types";

export const useRelationships = ({
  client,
  isInit,
  hostType,
}: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  // Temporary fix as the Web SDK has yet to support some APIs at this time
  const verifyIfSupported = ({ action }: { action: string }) => {
    if (hostType === "web")
      throw new SDKError("Unsupported Web SDK API", { action });
  };

  return {
    get: async (roleId: string) => {
      try {
        verifyIfSupported({ action: "relationships.get" });
        return await client.relationships.get(roleId);
      } catch (error) {
        throw new SDKError(error, { action: "relationships.get" });
      }
    },
    add: async (request: AddRelationshipRequest) => {
      try {
        verifyIfSupported({ action: "relationships.add" });
        return await client.relationships.add(request);
      } catch (error) {
        throw new SDKError(error, {
          action: "relationships.add",
          input: request,
        });
      }
    },
    update: async (request: UpdateRelationshipRequest) => {
      try {
        verifyIfSupported({ action: "relationships.update" });
        return await client.relationships.update(request);
      } catch (error) {
        throw new SDKError(error, {
          action: "relationships.update",
          input: request,
        });
      }
    },
  };
};
