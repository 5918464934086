import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import {
  FieldNS,
  FieldType,
  GroupType,
  LinkConditionType,
} from "@smart/bridge-types-basic";
import { DeepPartial } from "@smart/itops-utils-basic";
import {
  useLoadFields,
  useLoadForms,
  useLoadGroups,
  useLoadMatterLayouts,
  useLoadMatterTypes,
  useLoadSections,
  useQueryMatterFields,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

export type GqlForm = NonNullable<
  ReturnType<typeof useLoadForms>["result"]
>[number];

export type GqlSection = NonNullable<
  ReturnType<typeof useLoadSections>["result"]
>[number];

export type GqlGroup = NonNullable<
  ReturnType<typeof useLoadGroups>["result"]
>[number];

export type GqlField = NonNullable<
  ReturnType<typeof useLoadFields>["result"]
>[number];

export type GqlSectionValues = GqlSection["values"];

export type GqlFieldValues = GqlField["values"];

export type GqlGroupValues = GqlGroup["values"];

export type GqlGroupWithFieldValues = GqlGroupValues & {
  fields: GqlFieldValues[];
};

export type GqlSectionItem = GqlFieldValues | GqlGroupWithFieldValues;

export const isSectionItemField = (
  item: GqlSectionItem,
): item is GqlFieldValues => FieldNS.isUriMatch(item.uri);

export type EditableSection = { uri: string; values?: GqlSection["values"] };

export type UpdatedSection = {
  uri: string;
  title: string;
  description?: GqlSection["values"]["description"];
  links?: GqlSection["values"]["links"];
  order: string;
  created?: boolean;
  updated?: boolean;
  deleted?: boolean;
};

export type FieldTemplate = {
  type: FieldComponentType;
};

export type QuestionEditMode = "creating" | "updating" | "reordering" | "none";

export type FormInfo = {
  uri: string;
  formTitle: string;
};

export type NewFieldOptions = {
  uri: string;
  type: FieldType;
  label?: string;
  options?: Gql.UpdateFieldMutationVariables["fields"]["options"];
  layout?: Gql.UpdateFieldMutationVariables["fields"]["layout"];
  field?: Gql.UpdateFieldMutationVariables["fields"]["field"];
  allowCustomResponse?: boolean;
};

export type GroupOptions = {
  uri: string | undefined;
  type: GroupType;
  label?: string;
  layout?: Gql.UpdateGroupWithFieldsMutationVariables["fields"]["layout"];
  field?: Gql.UpdateGroupWithFieldsMutationVariables["fields"]["field"];
};

export type CreateMappedFields = (options: {
  newFields: NewFieldOptions[];
  beforePosition?: string;
  afterPosition?: string;
  destinationGroupUri?: string;
  group?: GroupOptions;
}) => Promise<void>;

export type LoadMatterFields = ReturnType<typeof useQueryMatterFields>;

export type MatterField = Awaited<ReturnType<LoadMatterFields>>[number];

export type ConditionActions = {
  checkHasConditions: (itemUri: string) => boolean;
  setEditingCondition: (
    item: GqlSectionValues | GqlGroupValues | GqlFieldValues,
  ) => void;
};

export type GqlFormCategory = GqlForm["formCategory"];

export type GqlMatterLayout = NonNullable<
  ReturnType<typeof useLoadMatterLayouts>["result"]
>[number];

export type GqlMatterType = Omit<
  NonNullable<ReturnType<typeof useLoadMatterTypes>["result"]>[number],
  "__typename"
>;

export type OnUpdateField = ({
  field,
  updated,
}: {
  field: GqlFieldValues;
  updated: DeepPartial<GqlFieldValues>;
}) => Promise<void>;

export type OnDeleteField = (field: GqlFieldValues) => Promise<void>;

export type UpdateCondition = (
  id: string,
  updatedValues: {
    condition?: LinkConditionType;
    value?: string;
    affectedItem?: GqlFieldValues | GqlGroupValues | GqlSectionValues;
    hide?: boolean;
  },
) => void;

export type ItemType = "Field" | "Section" | "Group";

export const itemTypeLabels: Record<ItemType, string> = {
  Field: "question",
  Group: "group",
  Section: "section",
};

export type OnFieldTypeChange = ({
  field,
  newFieldType,
}: {
  field: GqlFieldValues;
  newFieldType: FieldComponentType;
}) => Promise<void>;

export type DropAction =
  | "ADD_FIELD"
  | "CHANGE_SECTION_ITEM_ORDER"
  | "CHANGE_GROUP_FIELD_ORDER"
  | "MOVE_FIELD_OUT_OF_GROUP"
  | "MOVE_FIELD_INTO_GROUP"
  | "MOVE_ITEM_TO_SECTION";

export type UpdateAIFillSettings = (settings: {
  allowAiFill?: boolean;
  sectionTitle?: string;
  fileUploadLabel?: string;
  fileUploadDescription?: string;
}) => Promise<void>;
