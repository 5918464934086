import { useEffect, useState } from "react";

import { loadProvider } from "@smart/bridge-providers-dom";
import {
  SubmissionLinkHashNS,
  extractPrefixedTeamId,
} from "@smart/bridge-types-basic";
import { loadLocale } from "@smart/itops-locale-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Button } from "@smart/itops-smokeball-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { toSubdomain } from "@smart/itops-utils-basic";
import {
  useUpdateSubmissionLinkHash,
  useLoadTeamForms,
  useLoadGlobalForms,
  useLoadTeam,
  useLazyLoadFields,
  useLazyLoadMatterTypes,
  useLoadSubmissionLinkHashes,
  useDeleteSubmissionLinkHash,
  useLoadSetting,
} from "@smart/manage-gql-client-dom";
import { useToast, useUser } from "@smart/manage-hooks-dom";

import { ChangeSettings } from "./change-settings";
import { CreateLink } from "./create";
import { DeleteLinkModal } from "./delete";
import { LinksTable } from "./links-table";
import { SubmissionLink } from "./types";
import { GenerateQRCode } from "../../shared";

const GenerateHTML = () => {
  const { user } = useUser();
  const { staff } = useSmokeballApp();
  const { setToasts } = useToast();
  const [updateSubmissionLinkHash, submissionLinkHash] =
    useUpdateSubmissionLinkHash();
  const team = useLoadTeam({});
  const links = useLoadSubmissionLinkHashes({});
  const { result: settings } = useLoadSetting({});
  const teamForms = useLoadTeamForms({});
  const globalForms = useLoadGlobalForms({});
  const provider = loadProvider(team.result?.values.source, true);
  const loadFields = useLazyLoadFields();
  const [loadMatterTypes] = useLazyLoadMatterTypes();
  const generateLinkModal = useState(false);
  const changeStaffModal = useState(false);
  const [editingLink, setEditingLink] = useState<SubmissionLink>();
  const [deletingLink, setDeletingLink] = useState<SubmissionLink>();
  const [deleteLink, deleteLinkResult] = useDeleteSubmissionLinkHash();
  const [qrCodeLink, setQrCodeLink] = useState<string>();

  const teamFormResult =
    teamForms.result?.filter(
      ({ values }) =>
        !values.deleted && values.active && values.category === "lead",
    ) || [];
  const globalFormResult =
    globalForms.result?.filter(
      ({ values }) =>
        !values.deleted && values.active && values.category === "lead",
    ) || [];
  const staffResult = staff?.current?.filter((s) => s.enabled) || [];

  useEffect(() => {
    generateLinkModal[1](false);
    changeStaffModal[1](!!editingLink);
  }, [editingLink]);

  return (
    <>
      <p>{`Create and manage your web ${
        loadLocale().country === "US" ? "inquiry" : "enquiry"
      } links`}</p>
      <Button
        text="New Link"
        palette="primary"
        onClick={() => generateLinkModal[1](true)}
        small
        stretch={1}
      />
      <LinksTable
        loading={
          links.loading ||
          staff?.loading ||
          teamForms.loading ||
          globalForms.loading
        }
        links={
          links.data?.submissionLinkHashes.filter(
            (l) => !l.deleted,
          ) as SubmissionLink[]
        }
        forms={[...teamFormResult, ...globalFormResult]}
        staff={staffResult}
        user={
          user && {
            ...user,
            userId: extractPrefixedTeamId(user.userUri, "sb").id,
          }
        }
        onEdit={setEditingLink}
        onDelete={setDeletingLink}
        onCopy={async ({ code, toast }) => {
          await navigator.clipboard.writeText(code);
          setToasts([toast]);
        }}
        onGenerateQRCode={setQrCodeLink}
      />
      <CreateLink
        creatingModal={generateLinkModal}
        loading={
          staff?.loading ||
          teamForms.loading ||
          globalForms.loading ||
          submissionLinkHash.loading
        }
        areasOfLaw={settings?.areasOfLaw || []}
        locations={settings?.locations || []}
        teamName={team.result?.values.name || "Team"}
        teamForms={teamFormResult}
        globalForms={globalFormResult}
        productName={provider.label}
        staff={staffResult}
        user={
          user && {
            ...user,
            userId: extractPrefixedTeamId(user.userUri, "sb").id,
          }
        }
        onGenerate={async (fields) => {
          const link = await updateSubmissionLinkHash({
            variables: {
              uri: SubmissionLinkHashNS.generateUri(),
              fields,
            },
          });
          if (!link.data) return undefined;

          return toSubdomain(
            undefined,
            `/create/${extractId(link.data.generateLinkParameter.uri)}`,
          );
        }}
        onCopy={async ({ code, toast }) => {
          await navigator.clipboard.writeText(code);
          setToasts([toast]);
        }}
        loadFields={loadFields}
        loadMatterTypes={loadMatterTypes}
      />
      <ChangeSettings
        editModal={changeStaffModal}
        editingLink={editingLink}
        form={[...teamFormResult, ...globalFormResult].find(
          (f) => f.uri === editingLink?.formUri,
        )}
        staff={staffResult}
        user={
          user && {
            ...user,
            userId: extractPrefixedTeamId(user.userUri, "sb").id,
          }
        }
        onSave={async (fields) => {
          if (!editingLink) return;

          await updateSubmissionLinkHash({
            variables: {
              uri: editingLink.uri,
              fields,
            },
          });
        }}
        onDone={() => setEditingLink(undefined)}
      />
      <DeleteLinkModal
        deleteModal={[!!deletingLink, () => {}]}
        loading={deleteLinkResult.loading}
        error={!!deleteLinkResult.error}
        onReset={() => setDeletingLink(undefined)}
        update={async () => {
          if (!deletingLink) return;

          try {
            await deleteLink({ variables: { uri: deletingLink.uri } });
            setDeletingLink(undefined);
            setToasts([{ text: "Link successfully deleted" }]);
          } catch (error) {
            console.error(error);
          }
        }}
      />
      <GenerateQRCode
        link={qrCodeLink}
        onClose={() => setQrCodeLink(undefined)}
        onCopied={() => setToasts([{ text: "QR code copied!" }])}
      />
    </>
  );
};

export { GenerateHTML };
