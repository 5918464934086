import {
  azureOpenAiApiKeys,
  envKeys,
  googleMapApiKeys,
  launchDarklyKeys,
  slackApiKeys,
  smokeballApiKeys,
} from "@smart/bridge-env-types-basic";
import { transports } from "@smart/bridge-resources-basic";
import {
  buildSNSSQSDLQHandlerDef,
  buildSNSSQSHandlersDef,
} from "@smart/itops-constructs-basic";
import { keysOf } from "@smart/itops-utils-basic";

const lambdaDefs = {
  Backoff: {
    pkg: "@smart/manage-backoff-lambda",
    deployable: "Backoff",
    env: [],
  },
  Plan: {
    pkg: "@smart/manage-plan-processor-lambda",
    deployable: "Plan",
    env: [],
  },
  Requeue: {
    pkg: "@smart/manage-dead-letter-requeuer-lambda",
    deployable: "Requeue",
    env: [],
  },
  Migrate: {
    pkg: "@smart/manage-migration-processor-lambda",
    deployable: "Migrate",
    env: [...slackApiKeys, ...smokeballApiKeys, "SmokeballDefaultAccountId"],
  },
  QuestionGeneration: {
    pkg: "@smart/manage-question-generation-lambda",
    deployable: "QuestionGeneration",
    env: [
      ...launchDarklyKeys,
      ...smokeballApiKeys,
      ...azureOpenAiApiKeys,
      ...googleMapApiKeys,
      "SmokeballAiApiHost",
    ],
    // Dependencies for pdf2pic
    layerArns: [
      // https://github.com/rpidanny/gm-lambda-layer
      "arn:aws:lambda:<region>:175033217214:layer:graphicsmagick:2",
      // https://github.com/shelfio/ghostscript-lambda-layer
      "arn:aws:lambda:<region>:764866452798:layer:ghostscript:15",
    ],
    memorySize: 1024,
  },
} as const;

export const snsSqsLambdas = buildSNSSQSHandlersDef({
  envKeys,
  lambdaDefs,
  lambdaKeys: keysOf(lambdaDefs),
  queueDefs: transports,
});

export const dlqDequeuer = buildSNSSQSDLQHandlerDef({
  envKeys,
  queueDefs: transports,
  lambdaDef: {
    pkg: "@smart/manage-dead-letter-dequeuer-lambda",
    deployable: "dead-letter-dequeuer",
    env: [...slackApiKeys],
  },
});
