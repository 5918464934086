import { clsx } from "clsx";

import {
  badgeClassNames,
  BadgeTheme,
  badgeThemeClassNames,
} from "./class-names";
import { Icon, IconName } from "../icon";

export type BadgeProps = {
  label: string;
  theme?: BadgeTheme;
  isShade?: boolean;
  isRound?: boolean;
  iconLeft?: IconName;
  className?: string;
  dataTestId?: string;
  iconRight?: IconName;
};

export const Badge = ({
  label,
  theme = "neutral",
  isShade,
  isRound,
  iconLeft,
  iconRight,
  className,
  dataTestId,
}: BadgeProps) => (
  <div
    data-testid={dataTestId}
    className={clsx(
      badgeClassNames({ isRound }),
      badgeThemeClassNames[theme][isShade ? "shade" : "tint"],
      className,
    )}
  >
    {iconLeft && <Icon className="h-4 w-4" name={iconLeft} />}
    {label}
    {iconRight && <Icon className="h-4 w-4" name={iconRight} />}
  </div>
);
