import { useEffect, useState } from "react";

import { archie } from "@smart/bridge-images-dom";
import { defaultAIFillSettings, SectionNS } from "@smart/bridge-types-basic";
import {
  Button,
  DragNDropList,
  EditableText,
  Modal,
  Switch,
} from "@smart/itops-sb-design-system-dom";
import { rankBetween, sortField } from "@smart/itops-utils-basic";

import { MovableSection } from "./movable-section";
import {
  EditableSection,
  GqlForm,
  UpdateAIFillSettings,
  UpdatedSection,
} from "../../types";

type EditSectionsModalProps = {
  editSectionsModal: boolean;
  onClose: () => void;
  sections: EditableSection[];
  updateAllSections: (sections: UpdatedSection[]) => Promise<void>;
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

const EditSectionsModal = ({
  editSectionsModal,
  onClose,
  sections,
  updateAllSections,
  aiFillSettings,
  updateAIFillSettings,
}: EditSectionsModalProps) => {
  const [loading, setLoading] = useState(false);
  const [updatedSections, setUpdatedSections] = useState<UpdatedSection[]>([]);
  const [newSectionUri, setNewSectionUri] = useState<string>();
  const defaultTitle =
    aiFillSettings?.sectionTitle || defaultAIFillSettings.sectionTitle;
  const [editingTitle, setEditingTitle] = useState(defaultTitle);
  const [autofillToggle, setAutofillToggle] = useState(
    !!aiFillSettings?.allowAiFill,
  );

  useEffect(() => {
    setEditingTitle(defaultTitle);
    setAutofillToggle(!!aiFillSettings?.allowAiFill);
    if (editSectionsModal) {
      setNewSectionUri(undefined);
      setUpdatedSections(
        sortField(
          sections.map((s) => ({
            uri: s.uri,
            title: s.values?.title || "",
            order: s.values?.order || rankBetween({}),
            description: s.values?.description,
            links: s.values?.links,
            updated: !s.values,
            created: !s.values,
          })),
          { key: "order", dir: "asc" },
        ),
      );
    }
  }, [editSectionsModal]);

  const moveSection = (from: number, to: number) => {
    const afterIndex = from > to ? to - 1 : to;
    const newOrder = rankBetween({
      after: updatedSections[afterIndex]?.order,
      before: updatedSections[afterIndex + 1]?.order,
    });

    const newSections = [...updatedSections];
    newSections[from] = {
      ...newSections[from],
      order: newOrder,
      updated: true,
    };
    setUpdatedSections(sortField(newSections, { key: "order", dir: "asc" }));
  };

  return (
    <Modal
      loading={loading}
      header={{
        icon: "solidPenToSquare",
        iconBgColor: "bg-cyan-140",
        text: "Edit sections",
      }}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: "Save sections",
            variant: "primaryDefault",
            onClick: async () => {
              setLoading(true);
              await updateAllSections(updatedSections);
              if (aiFillSettings?.allowAiFill) {
                await updateAIFillSettings({
                  sectionTitle: editingTitle,
                  allowAiFill: autofillToggle,
                });
              }
              setLoading(false);
              onClose();
            },
          },
        ],
      }}
      open={editSectionsModal}
      onClose={() => {
        if (!loading) onClose();
      }}
    >
      <div>
        {aiFillSettings?.allowAiFill && (
          <div className="flex justify-between gap-small bg-neutral-50 rounded pl-[0.8rem] pr-[1.6rem] py-[1.1rem] mb-[0.8rem]">
            <div className="flex">
              <img className="w-[2.4rem]" src={archie} alt="Archie" />
              <EditableText
                text={editingTitle}
                onChange={setEditingTitle}
                onComplete={async () => {}}
                dataTestId="autofill-title"
                fontSize="ui"
                fontWeight="semibold"
              />
            </div>
            <Switch
              toggled={autofillToggle}
              disabled={loading}
              onClick={() => setAutofillToggle(!autofillToggle)}
              dataTestId="ai-fill-toggle-sections-modal"
            />
          </div>
        )}
        <DragNDropList
          id="draggable-sections"
          className="flex flex-col gap-small mb-[1.6rem]"
          items={updatedSections}
          itemKey="uri"
          render={MovableSection}
          renderProps={{
            numOfSections: updatedSections.length,
            moveUp: (index: number) => moveSection(index, index - 1),
            moveDown: (index: number) => moveSection(index, index + 1),
            setTitle: (index: number, title: string) => {
              const newSections = [...updatedSections];
              newSections[index] = {
                ...newSections[index],
                title,
                updated: true,
              };
              setUpdatedSections(newSections);
            },
            setDeleted: (index: number, deleted: boolean) => {
              const newSections = [...updatedSections];
              newSections[index].deleted = deleted;
              setUpdatedSections(newSections);
            },
            newSectionUri,
          }}
          onMove={({ source, destination }) =>
            moveSection(source.index, destination.index)
          }
        />
        <Button
          text="Add section"
          leftIcon="regularPlus"
          variant="primarySubtle"
          onClick={() => {
            const createdSectionUri = SectionNS.generateUri();
            setNewSectionUri(createdSectionUri);
            setUpdatedSections([
              ...updatedSections,
              {
                uri: createdSectionUri,
                title: "",
                order: rankBetween({
                  after: updatedSections[updatedSections.length - 1].order,
                }),
                updated: true,
                created: true,
              },
            ]);
          }}
        />
      </div>
    </Modal>
  );
};

export { EditSectionsModal };
