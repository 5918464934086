import { useState } from "react";

import { Rule } from "@smart/itops-components-dom";
import {
  Button,
  CollectionTags,
  Field,
  HorizontalFieldGroup,
  ItemTags,
} from "@smart/itops-smokeball-components-dom";
import { CachedGql } from "@smart/manage-cached-gql-operations-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

import { getUpdatedMatterTypeGroups } from "../shared/matter-type-helpers";

export type EditMatterTypesProps = {
  formUri: string;
  type: Gql.FormFieldsFragment["values"]["category"];
  matterTypes: Gql.FormFieldsFragment["values"]["matterTypes"];
  states: { name: string; displayName: string }[];
  categories: string[];
  readOnly?: boolean;
  loadGroups: (
    variables: CachedGql.MatterTypeGroupsQueryVariables,
  ) => Promise<CachedGql.MatterTypeGroupsQuery["matterTypeGroups"]>;
  update: (variables: {
    formUri: string;
    existing: Gql.FormFieldsFragment["values"]["matterTypes"];
    updated: Gql.FormFieldsFragment["values"]["matterTypes"];
  }) => Promise<any>;
};

export const EditMatterTypes = ({
  formUri,
  states,
  categories,
  type,
  matterTypes,
  readOnly,
  loadGroups,
  update,
}: EditMatterTypesProps) => {
  const [selectedLocations, setLocations] = useState(() =>
    states.filter((s) => !!matterTypes.find((mt) => mt.location === s.name)),
  );

  const [selectedGroups, setGroups] = useState(() =>
    matterTypes.reduce(
      (groups, matterType) => {
        let group = groups.find(
          (g) =>
            g.name === matterType.name && g.category === matterType.category,
        );
        if (!group) {
          group = {
            __typename: "MatterTypeGroup" as const,
            name: matterType.name,
            category: matterType.category,
            matterTypes: [],
          };
          groups.push(group);
        }
        group.matterTypes.push(matterType);

        return groups;
      },
      [] as CachedGql.MatterTypeGroupsQuery["matterTypeGroups"],
    ),
  );

  const toggleGroup = (
    item: CachedGql.MatterTypeGroupsQuery["matterTypeGroups"][number],
  ) => {
    setGroups((current) => {
      if (current.some((i) => i.name === item.name)) {
        return current.filter((i) => i.name !== item.name);
      }

      return [...current, item];
    });
  };

  const toggleLocation = async (location: {
    name: string;
    displayName: string;
  }) => {
    let isAdding = true;
    setLocations((current) => {
      if (current.find((c) => c.name === location.name)) {
        isAdding = false;
        return current.filter((i) => location.name !== i.name);
      }

      return [...current, location];
    });

    const updatedGroups = await getUpdatedMatterTypeGroups({
      isAdding,
      currentLocations: selectedLocations.map((l) => l.name),
      location: location.name,
      currentGroups: selectedGroups,
      loadGroups,
      type,
    });

    setGroups(updatedGroups);
  };

  return (
    <>
      <HorizontalFieldGroup>
        <Field name="locations" text="Locations" transparent>
          <ItemTags
            name="locations"
            readOnly={readOnly}
            selected={selectedLocations}
            options={states}
            onToggle={toggleLocation}
            onToggleAll={(show) => setLocations(show ? states : [])}
            render={(v) => v.displayName}
            keys={(v) => v.name}
            empty="No Locations Found"
          />
        </Field>
        <Field name="matterTypes" text="Matter Types" transparent>
          <CollectionTags
            name="matterTypes"
            displayMode={readOnly ? "readOnly" : "normal"}
            collections={
              selectedLocations.length
                ? categories.map((cat) => ({
                    id: cat,
                    label: cat,
                  }))
                : []
            }
            emptyCollections="No Categories Found"
            options={(category) =>
              loadGroups({
                category,
                type,
                locations: selectedLocations.map((l) => l.name),
              })
            }
            emptyOptions="No Matter Types Found"
            selected={selectedGroups}
            keys={(m) => m.name}
            render={(m) => m.name}
            onToggle={toggleGroup}
          />
        </Field>
      </HorizontalFieldGroup>
      <Rule />
      {!readOnly && (
        <Button
          text="Update Matter Types"
          palette="primary"
          onClick={() =>
            update({
              formUri,
              existing: matterTypes,
              updated: selectedGroups.flatMap((g) => g.matterTypes),
            })
          }
        />
      )}
    </>
  );
};
