/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlFieldsQueryVariables = Types.Exact<{
  formUri: Types.Scalars["ID"]["input"];
  sectionUri?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  submissionUri?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GqlFieldsQuery = {
  __typename: "Query";
  fields: Array<{
    __typename: "Field";
    uri: string;
    values: {
      __typename: "FieldValues";
      uri: string;
      formUri: string;
      sectionUri: string;
      groupUri?: string | null;
      operationId: string;
      deleted?: boolean | null;
      order: string;
      label: string;
      description?: string | null;
      hint: string;
      type: Types.GqlFieldType;
      mandatory: boolean;
      allowCustomResponse?: boolean | null;
      updatedAt: string;
      allowCopyFromFieldUri?: string | null;
      availableStaffIds?: Array<string> | null;
      duration?: number | null;
      timezone?: string | null;
      bufferTime?: number | null;
      minimumNotice?: number | null;
      meetingType?: Types.GqlMeetingType | null;
      options: Array<{
        __typename: "FieldOption";
        label: string;
        value: string;
      }>;
      layout?: {
        __typename: "MatterLayout";
        id: string;
        providerId: string;
        name: string;
        parentId?: string | null;
        parentName?: string | null;
        parentProviderId?: string | null;
        displayName?: string | null;
      } | null;
      field?: {
        __typename: "MatterField";
        name: string;
        type: string;
        possibleValues?: Array<string> | null;
        displayName?: string | null;
        details?: string | null;
      } | null;
      links?: Array<{
        __typename: "FieldLink";
        fieldUri: string;
        condition: Types.GqlLinkCondition;
        value: string;
        hide?: boolean | null;
      }> | null;
      availability?: Array<{
        __typename: "Availability";
        day: number;
        enabled: boolean;
        fromTime: { __typename: "TimeOfDay"; hour: number; minute: number };
        toTime: { __typename: "TimeOfDay"; hour: number; minute: number };
      }> | null;
      payment?: {
        __typename: "Payment";
        amountInCents?: number | null;
        bankAccount?: {
          __typename: "BankAccountIdentifier";
          id: string;
          type: number;
        } | null;
      } | null;
    };
  }>;
};

export const FieldsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "fields" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "formUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sectionUri" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "submissionUri" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fields" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "formUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "formUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sectionUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sectionUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "submissionUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "submissionUri" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "fieldFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "fieldFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Field" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "formUri" } },
                { kind: "Field", name: { kind: "Name", value: "sectionUri" } },
                { kind: "Field", name: { kind: "Name", value: "groupUri" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "hint" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "options" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "mandatory" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowCustomResponse" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "layout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "providerId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentProviderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "field" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "possibleValues" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "details" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldUri" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "hide" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowCopyFromFieldUri" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableStaffIds" },
                },
                { kind: "Field", name: { kind: "Name", value: "duration" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availability" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "day" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromTime" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hour" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "minute" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toTime" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hour" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "minute" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enabled" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "timezone" } },
                { kind: "Field", name: { kind: "Name", value: "bufferTime" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minimumNotice" },
                },
                { kind: "Field", name: { kind: "Name", value: "meetingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountInCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bankAccount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlFieldsQuery, GqlFieldsQueryVariables>;
