import styled from "@emotion/styled";

import { TooltipContainer } from "@smart/itops-components-dom";
import { IconName, Icon } from "@smart/itops-icons-dom";

const ExtendedButtonWrapper = styled.span<{ disabled?: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  display: block;
  padding: 0.5rem 0.4rem;
  margin: 0 0.5rem;
`;

const TooltipContent = styled.div`
  padding: 0.4rem;
  p {
    padding: 0;
    margin: 0.2rem 0;
    font-size: inherit;
  }
`;

const TooltipDownArrow = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;

  &::after,
  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent transparent transparent;
  }

  &::after {
    margin-left: -5px;
    border-width: 6px;
    border-color: ${(props) => props.theme.palette.background.base} transparent
      transparent;
  }

  &::before {
    margin-left: -6px;
    border-width: 7px;
    border-color: ${(props) => props.theme.palette.disabled.base} transparent
      transparent;
  }
`;

type ExtendedButtonProps = {
  label: string;
  iconName: IconName;
  disabled?: boolean;
  tooltipTexts?: string[];
  onClick: () => void;
};

const ExtendedButton = ({
  iconName,
  disabled,
  tooltipTexts,
  label,
  onClick,
}: ExtendedButtonProps) => (
  <ExtendedButtonWrapper
    disabled={disabled}
    onClick={onClick}
    aria-label={label}
  >
    <TooltipContainer
      className="extended-button-tooltip"
      content={
        tooltipTexts ? (
          <TooltipContent>
            {tooltipTexts?.map((text) => <p key={text}>{text}</p>)}
            <TooltipDownArrow />
          </TooltipContent>
        ) : null
      }
    >
      <Icon name={iconName} size={14} />
    </TooltipContainer>
  </ExtendedButtonWrapper>
);
export { ExtendedButtonProps, ExtendedButton };
