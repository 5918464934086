import { Outlet } from "react-router-dom";

import {
  defaultTheme,
  GlobalStyles,
  ThemeProvider,
} from "@smart/itops-components-dom";
import { ClientProvider, UserProvider } from "@smart/manage-hooks-dom";

import { Menu } from "../components";

const Main = () => (
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyles />
    <UserProvider>
      <ClientProvider>
        <Menu>
          <Outlet />
        </Menu>
      </ClientProvider>
    </UserProvider>
  </ThemeProvider>
);

export { Main };
