import styled from "@emotion/styled";
import { transparentize } from "polished";
import { Fragment, useMemo } from "react";

import { formatJsonStringValue, UploadedFile } from "@smart/bridge-types-basic";
import { Input, Label } from "@smart/itops-components-dom";
import { jsonParseOrReturn } from "@smart/itops-utils-basic";
import { useLoadFields, useLoadGroups } from "@smart/manage-gql-client-dom";

import { GqlSubmission } from "./types";
import { GqlField, GqlGroup } from "../fields";
import { GqlSection } from "../sections/types";

type FieldItem = {
  type: "field";
  field: GqlField;
};

type GroupItem = {
  type: "group";
  group?: GqlGroup;
  fields: GqlField[];
};

type FieldSet = { field: GqlField; value: string | undefined }[];

type SectionItem = FieldItem | GroupItem;

const formatFileField = (jsonString: string | undefined) => {
  const files = (
    (jsonParseOrReturn(jsonString) as UploadedFile[]) || []
  ).filter(({ uploadStatus }) => uploadStatus === "uploaded");

  return files.length ? (
    <div>
      {files.map(({ downloadUrl, fileName }) => (
        <a
          key={downloadUrl}
          className="file-field-response"
          target="_blank"
          href={downloadUrl}
          rel="noreferrer"
        >
          {fileName}
        </a>
      ))}
    </div>
  ) : (
    <span className="file-field-response">No file uploaded</span>
  );
};

const SubmissionSectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 80rem;
  width: 98%;

  h3 {
    border-bottom: 1px solid ${(props) => props.theme.palette.disabled.base};
    padding: 1rem 0;
    margin: 1rem 0;
    font-size: ${(props) => props.theme.fontSize.subHeading};
  }

  h4 {
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: normal;
    margin: 0;
  }

  h4.group-label {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 600;
    margin: 1.4rem 0;
  }

  .field-set {
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px dotted
      ${(props) => transparentize(0.5, props.theme.palette.disabled.base)};
  }

  .file-field-response {
    display: inline-block;
    font-size: ${(props) => props.theme.fontSize.base};
    padding: 1rem;
  }

  a.file-field-response {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.base};
  }
`;

type SubmissionSectionProps = {
  submission: GqlSubmission;
  section: GqlSection;
};

const SubmissionSection = ({ section, submission }: SubmissionSectionProps) => {
  const fields = useLoadFields({
    formUri: section.values.formUri,
    sectionUri: section.uri,
  });

  const groups = useLoadGroups({
    formUri: section.values.formUri,
    sectionUri: section.uri,
  });

  const items: SectionItem[] = useMemo(
    () =>
      fields.result
        ? fields.result.reduce((list: SectionItem[], field) => {
            if (field.values.groupUri) {
              const existingGroup = list.find(
                (i) =>
                  i.type === "group" && i.group?.uri === field.values.groupUri,
              ) as GroupItem;
              if (existingGroup) {
                existingGroup.fields?.push(field);
              } else {
                list.push({
                  type: "group",
                  group: groups.result?.find(
                    (g) => g.uri === field.values.groupUri,
                  ),
                  fields: [field],
                });
              }
            } else {
              list.push({
                type: "field",
                field,
              });
            }

            return list;
          }, [])
        : [],
    [groups, fields],
  );

  const renderField = (field: GqlField, value: string | undefined) => {
    const { type } = field.values;
    if (type === "info") return null;

    const displayField =
      type === "file" ? (
        formatFileField(value)
      ) : (
        <Input
          name={field.uri}
          value={formatJsonStringValue(value, type, field.values.options)}
          readOnly
        />
      );

    return (
      <Fragment key={field.uri}>
        <h4>{field.values.label}</h4>
        <Label name={field.uri}>{displayField}</Label>
      </Fragment>
    );
  };

  const renderGroupItem = (groupItem: GroupItem) => {
    const { group, fields: groupFields } = groupItem;
    if (!group) return null;

    const fieldSets = groupFields.reduce((sets: FieldSet[], f) => {
      const groupResponse = jsonParseOrReturn(
        submission.responses.find((r) => r.fieldUri === f.uri)?.value,
      );

      const groupValues = [groupResponse].flat();
      groupValues.forEach((value, index) => {
        if (!sets[index]) {
          sets.push([{ field: f, value: JSON.stringify(value) }]);
        } else {
          sets[index].push({ field: f, value: JSON.stringify(value) });
        }
      });

      return sets;
    }, []);

    return (
      <Fragment key={group.uri}>
        <h4 className="group-label">{group.values.label}</h4>
        {fieldSets.map((set, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${group.uri}-${index}`} className="field-set">
            {set.map(({ value, field }) => renderField(field, value))}
          </div>
        ))}
      </Fragment>
    );
  };

  const renderFieldItem = (fieldItem: FieldItem) => {
    const { field } = fieldItem;
    const value = submission.responses.find(
      (r) => r.fieldUri === field.uri,
    )?.value;

    return renderField(field, value);
  };

  return (
    <SubmissionSectionWrapper>
      <h3>{section.values.title}</h3>
      {items.map((item) =>
        item.type === "field" ? renderFieldItem(item) : renderGroupItem(item),
      )}
    </SubmissionSectionWrapper>
  );
};

export { SubmissionSection, SubmissionSectionProps };
