import { useMemo, useState } from "react";

import { getMatterTypeRepresentatives } from "@smart/bridge-smokeball-basic";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { entriesOf, fromEntries, groupBy } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLazyLoadContacts,
  useLoadFields,
  useLoadForm,
  useLoadGroups,
  useLoadSections,
  useLoadSubmission,
  useQuerySubmission,
  useUpdateResponseExistingItemIds,
  useUpdateResponseSyncStatus,
} from "@smart/manage-gql-client-dom";
import { useSyncToMatter, useToast } from "@smart/manage-hooks-dom";

import { ContactReview } from "./contact-review";
import { SubmissionViewContainer } from "./view-container";
import { useOpenFamilyProForm } from "../../shared/use-open-family-pro-form";

export type SubmissionViewProps = {
  selected?: MatterSubmission;
  onClose: () => void;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
  fullView?: boolean;
};

export const SubmissionView = ({
  selected,
  onClose,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
  fullView,
}: SubmissionViewProps) => {
  const { matters, roles, contacts, relationships } = useSmokeballApp();
  const { setToasts } = useToast();
  const { sync } = useSyncToMatter();
  const [updateResponseExistingItemIds] = useUpdateResponseExistingItemIds();
  const [updateResponseSyncStatus] = useUpdateResponseSyncStatus();
  const searchContacts = useLazyLoadContacts();

  const [rolesAndRelationships, setRolesAndRelationships] = useState<
    Record<string, { contactId: string }[]>
  >({});

  const form = useLoadForm({ uri: selected?.formUri || "" });
  const sections = useLoadSections({ formUri: selected?.formUri || "" });
  const groups = useLoadGroups({ formUri: selected?.formUri || "" });
  const fields = useLoadFields({ formUri: selected?.formUri || "" });
  const querySubmission = useQuerySubmission("network-only");
  const submission = useLoadSubmission(
    { uri: selected?.uri || "" },
    { pollInterval: selected?.syncStatus === "loading" ? 3000 : 60000 },
  );
  const formMatterTypeRepresentatives = useMemo(
    () =>
      form.result
        ? getMatterTypeRepresentatives(form.result.values.matterTypes)
        : undefined,
    [form.result],
  );

  useOpenFamilyProForm({
    selectedFormUri: selected?.formUri,
    externalSubmissionEmbedUrl: selected?.externalSubmissionEmbedUrl,
    callbackFn: onClose,
  });

  useAsync(async () => {
    const existingRoles = await roles?.get();
    const existingRelationships = await Promise.all(
      existingRoles?.roles?.map(async (role) => {
        const roleRelationships = await relationships?.get(role.id);
        return {
          roleId: role.id,
          relationships: roleRelationships?.relationships,
        };
      }) || [],
    );
    const rolesMap = groupBy(existingRoles?.roles || [], (r) => r.name);
    const relationshipsMap = entriesOf(rolesMap).reduce<
      Record<string, { contactId: string }[]>
    >((map, [roleName, rolesWithTheSameName]) => {
      const found = existingRelationships.find(
        (rs) => rs.roleId === rolesWithTheSameName[0].id,
      );
      const grouped = groupBy(found?.relationships || [], (rs) => rs.name);

      return {
        ...map,
        ...fromEntries(
          entriesOf(grouped).map(([relationshipName, groupedRelationships]) => [
            `${roleName}/${relationshipName}`,
            groupedRelationships,
          ]),
        ),
      };
    }, {});

    setRolesAndRelationships({
      ...rolesMap,
      ...relationshipsMap,
    });
  }, [roles, relationships]);

  if (!selected || selected.formUri === familyProQuestionnaireFormUri)
    return null;

  if (selected.syncStatus === "pendingContactReview") {
    return (
      <ContactReview
        loading={fields.loading || (submission.loading && !submission.data)}
        matter={matters?.current}
        rolesAndRelationships={rolesAndRelationships}
        onClose={onClose}
        submission={selected}
        sections={sections.result}
        groups={groups.result}
        fields={fields.result || []}
        formMatterTypeRepresentatives={formMatterTypeRepresentatives}
        searchContacts={searchContacts}
        updateResponseExistingItemIds={updateResponseExistingItemIds}
        updateResponseSyncStatus={updateResponseSyncStatus}
        querySubmission={querySubmission}
        sync={sync}
        onSuccess={() =>
          setToasts([
            {
              text: "Contacts successfully saved",
            },
          ])
        }
        onFailure={() =>
          setToasts([
            {
              text: "Failed to save contacts",
              palette: "destructive",
            },
          ])
        }
        openContact={contacts?.open}
      />
    );
  }

  return (
    <SubmissionViewContainer
      loading={
        sections.loading ||
        groups.loading ||
        fields.loading ||
        (submission.loading && !submission.data) ||
        selected.syncStatus === "loading"
      }
      fullView={fullView}
      sections={sections.result || []}
      groups={groups.result || []}
      fields={fields.result || []}
      submission={selected}
      responseList={submission.result?.responses || []}
      onCopyLink={async (link) => {
        await navigator.clipboard.writeText(link);
        setToasts([
          {
            text: `Link copied and ready to be used`,
          },
        ]);
      }}
      onClose={onClose}
      onCancel={onCancel}
      onDelete={onDelete}
      onRemind={onRemind}
      onEdit={onEdit}
      onConfirmSync={async (confirmed) => {
        const result = await sync({
          submission: confirmed,
          fields: fields.result || [],
          groups: groups.result || [],
          sections: sections.result || [],
          formMatterTypeRepresentatives,
          shouldReviewConflict: true,
        });
        setToasts([
          {
            text:
              result === "pendingContactReview"
                ? "Pending contact conflict review."
                : "Responses successfully saved",
          },
        ]);
      }}
    />
  );
};
