import { ApiResults, useApi } from "@smart/itops-hooks-dom";
import { apiGateway, apiSchemas } from "@smart/manage-resources-basic";

export const formsApi = useApi(
  apiGateway.prefix,
  apiGateway.lambdaKeys,
  apiGateway.lambdas,
  apiSchemas,
);

export type FormsApiResults = ApiResults<typeof formsApi>;
