import { useMutation } from "@apollo/client";

import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useToggleSlug = () =>
  useMutation(mutationDocuments.updateSlug, {
    update: (cache, { data }, { variables }) => {
      if (data?.updateSlug && variables) {
        cache.updateQuery(
          {
            query: queryDocuments.form,
            variables: { uri: variables.formUri },
          },
          (ex) => {
            if (!ex?.form) return undefined;

            return {
              form: {
                ...ex.form,
                values: {
                  ...ex.form.values,
                  active: data.updateSlug.values.active,
                },
                slug: data.updateSlug,
              },
              __typename: "Query" as const,
            };
          },
        );
        cache.updateQuery(
          {
            query: queryDocuments.slug,
            variables: { formUri: variables.formUri },
          },
          (ex) => {
            if (ex?.slug) return ex;

            return {
              slug: data.updateSlug,
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useLoadSlug = useQueryFactory(queryDocuments.slug, {
  map: ({ slug }) => slug,
});
