/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlGroupFieldsFragment = {
  __typename: "Group";
  uri: string;
  values: {
    __typename: "GroupValues";
    uri: string;
    formUri: string;
    sectionUri: string;
    operationId: string;
    deleted?: boolean | null;
    order: string;
    label?: string | null;
    hint?: string | null;
    description?: string | null;
    type: Types.GqlGroupType;
    templateType?: Types.GqlGroupTemplateType | null;
    repeatable?: boolean | null;
    repeatPrompt?: string | null;
    minRepeat?: number | null;
    maxRepeat?: number | null;
    updatedAt: string;
    allowedRepeatable?: boolean | null;
    layout?: {
      __typename: "MatterLayout";
      id: string;
      providerId: string;
      name: string;
      parentId?: string | null;
      parentName?: string | null;
      parentProviderId?: string | null;
      displayName?: string | null;
    } | null;
    field?: {
      __typename: "MatterField";
      name: string;
      type: string;
      possibleValues?: Array<string> | null;
      displayName?: string | null;
      details?: string | null;
    } | null;
    links?: Array<{
      __typename: "FieldLink";
      fieldUri: string;
      condition: Types.GqlLinkCondition;
      value: string;
      hide?: boolean | null;
    }> | null;
  };
};

export const GroupFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "groupFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Group" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "formUri" } },
                { kind: "Field", name: { kind: "Name", value: "sectionUri" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "hint" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "templateType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "layout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "providerId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentProviderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "field" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "possibleValues" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "details" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldUri" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "hide" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "repeatable" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "repeatPrompt" },
                },
                { kind: "Field", name: { kind: "Name", value: "minRepeat" } },
                { kind: "Field", name: { kind: "Name", value: "maxRepeat" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedRepeatable" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlGroupFieldsFragment, unknown>;
