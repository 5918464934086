import { contacts } from "@smokeballdev/smokeball-client-sdk";

import { UpdateContactRequest } from "@smart/itops-smokeball-app-dom";
import { RoleContact } from "@smart/manage-hooks-dom";

type SetEmailProps = {
  roleContact: RoleContact;
  email: string;
  update: (
    request: UpdateContactRequest,
  ) => Promise<contacts.Contact | undefined>;
};

export const setEmail = async ({
  roleContact,
  email,
  update,
}: SetEmailProps) => {
  const isPerson =
    !!roleContact.person && Object.keys(roleContact.person).length > 0;
  const isCompany =
    !!roleContact.company && Object.keys(roleContact.company).length > 0;

  const updatedContact = await update({
    id: roleContact.id,
    person: isPerson ? { email } : undefined,
    company: isCompany ? { email } : undefined,
  });
  if (
    (isPerson && updatedContact?.person?.email !== email) ||
    (isCompany && updatedContact?.company?.email !== email)
  )
    throw new Error(`Failed to update contact`);
};
