import { FieldFunctionOptions, Reference, StoreObject } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";

import { isNotNullOrUndefined } from "@smart/itops-utils-basic";
import { queryDocuments, QueryKey } from "@smart/manage-gql-operations-dom";

type MergeableKeys = {
  [K in QueryKey]: (typeof queryDocuments)[K] extends TypedDocumentNode<
    { [k in K]: { uri: string }[] },
    any
  >
    ? K
    : never;
}[QueryKey];

export const mergeItems =
  (query: MergeableKeys) =>
  (
    exRefs: Reference[],
    inRefs: Reference[],
    { cache, readField, toReference, variables }: FieldFunctionOptions,
  ) => {
    let currentRefs = exRefs;
    if (!currentRefs) {
      const cached = cache.readQuery({
        query: queryDocuments[query],
        variables: variables as {},
      }) as {
        [k: string]: StoreObject[];
      } | null;
      currentRefs =
        (cached &&
          cached[query]
            ?.map((i) => toReference(i))
            .filter(isNotNullOrUndefined)) ||
        [];
    }

    const newRefs = inRefs.filter(
      (i) =>
        !currentRefs.find((c) => readField("uri", c) === readField("uri", i)),
    );
    return [...currentRefs, ...newRefs];
  };
