import { useState } from "react";

import { SubmissionTrackingProps } from "@smart/bridge-metrics-dom";
import { PlaceholderData } from "@smart/itops-serialisation-basic";
import { Matter } from "@smart/itops-smokeball-app-dom";
import { MatterSubmission, useLoadSetting } from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import {
  EventLogger,
  RoleContact,
  RoleContacts,
  SubmissionForm,
} from "@smart/manage-hooks-dom";

import { TabAddContact } from "./add-contact";
import {
  TabCreateSubmission,
  TabCreateSubmissionForm,
  OnSendQuestionnaire,
} from "./create-submission";
import { TabSetEmail } from "../../shared";
import { PreviewProps } from "../preview";

export type TabCreateAndSendContainerProps = {
  matter: Matter;
  matterType: { name: string; location: string };
  matchedMatterType?: { id: string; location: string; name: string };
  setting?: ReturnType<typeof useLoadSetting>["result"];
  roleContacts: RoleContacts;
  isFamilyProAuthorized: boolean;
  isFamilyProFormShared: boolean;
  teamForms: SubmissionForm[];
  matterSpecificForms: SubmissionForm[];
  globalForms: SubmissionForm[];
  teamCategoryForms: SubmissionForm[];
  globalCategoryForms: SubmissionForm[];
  team: { name: string; phone: string };
  sharing: "regular" | "internal" | undefined;
  sharingFormUri?: string;
  Preview: (props: PreviewProps) => JSX.Element;
  logEvent: EventLogger;
  onSendQuestionnaire: OnSendQuestionnaire;
  onSetEmail: (props: {
    roleContact: RoleContact;
    email: string;
  }) => Promise<void>;
  onCreateContact: (props: {
    firstName: string;
    lastName: string;
    email: string;
    id?: string;
  }) => Promise<void>;
  onCreateSubmission: (
    detail: { uri: string; matter: Matter; aiFillFromMatterInfo: boolean },
    values: TabCreateSubmissionForm,
  ) => Promise<Gql.SubmissionFieldsFragment>;
  onEditSubmission: (submission: MatterSubmission) => void;
  onMessage: (
    values: TabCreateSubmissionForm,
    placeholderData: PlaceholderData,
    trackingInfo: SubmissionTrackingProps,
  ) => void;
  onClose: () => void;
};

export const TabCreateAndSendContainer = ({
  matter,
  matterType,
  matchedMatterType,
  setting,
  roleContacts,
  onSendQuestionnaire,
  onSetEmail,
  onCreateContact,
  onCreateSubmission,
  onEditSubmission,
  onMessage,
  isFamilyProAuthorized,
  isFamilyProFormShared,
  teamForms,
  matterSpecificForms,
  globalForms,
  teamCategoryForms,
  globalCategoryForms,
  Preview,
  team,
  sharing,
  sharingFormUri,
  logEvent,
  onClose,
}: TabCreateAndSendContainerProps) => {
  const [addOrUpdateEmail, onAddOrUpdateEmail] = useState<RoleContact>();

  const filterByPracticeAreas = (forms: SubmissionForm[]) =>
    setting?.areasOfLaw?.length
      ? forms.filter((f) =>
          f.matterTypes.some((mt) =>
            setting?.areasOfLaw?.includes(mt.category),
          ),
        )
      : forms;

  if (!sharing) return null;

  if (!roleContacts.roleContacts?.length)
    return (
      <TabAddContact
        onCreate={onCreateContact}
        visible={!!sharing}
        onClose={onClose}
      />
    );

  return (
    <>
      <TabCreateSubmission
        matter={matter}
        matterType={matterType}
        matchedMatterType={matchedMatterType}
        roleContacts={roleContacts}
        isFamilyProAuthorized={isFamilyProAuthorized}
        isFamilyProFormShared={isFamilyProFormShared}
        teamForms={filterByPracticeAreas(teamForms)}
        matterSpecificForms={filterByPracticeAreas(matterSpecificForms)}
        globalForms={filterByPracticeAreas(globalForms)}
        teamCategoryForms={filterByPracticeAreas(teamCategoryForms)}
        globalCategoryForms={filterByPracticeAreas(globalCategoryForms)}
        settingTemplate={
          setting?.defaultClientMessage?.[matter.isLead ? "lead" : "matter"] ||
          undefined
        }
        team={team}
        sharing={sharing}
        sharingFormUri={sharingFormUri}
        logEvent={logEvent}
        onSendQuestionnaire={onSendQuestionnaire}
        onCreateSubmission={onCreateSubmission}
        onEditSubmission={onEditSubmission}
        onMessage={onMessage}
        onClose={onClose}
        onAddOrUpdateEmail={onAddOrUpdateEmail}
        Preview={Preview}
      />
      <TabSetEmail
        selected={addOrUpdateEmail}
        onClose={() => onAddOrUpdateEmail(undefined)}
        onSetEmail={onSetEmail}
      />
    </>
  );
};
