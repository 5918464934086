import { useEffect } from "react";

export type DocumentVisibilityOptions = {
  onShow?: () => void;
  onHide?: () => void;
};

export const useDocumentVisibility = ({
  onShow,
  onHide,
}: DocumentVisibilityOptions) => {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible" && onShow) onShow();
      if (document.visibilityState === "hidden" && onHide) onHide();
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);
};
