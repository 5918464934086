import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { useTime } from "@smart/itops-hooks-dom";
import { Icon } from "@smart/itops-icons-dom";

type TabListStatusProps = {
  refetching: boolean;
  onRefetch: () => void;
};

const TabListStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.accent};
  border-top: solid 0.1em ${(props) => props.theme.palette.background.highlight};
  bottom: 0;
  padding-left: 0.7rem;

  .icon {
    background-color: ${(props) => props.theme.palette.background.accent};
    margin: 0;
    padding: 0;
  }
  .icon:focus {
    background-color: ${(props) => props.theme.palette.background.accent};
  }
  .date {
    font-size: ${(props) => props.theme.fontSize.base};
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-weight: 400;
  }
`;

export const TabListStatus = ({
  refetching,
  onRefetch,
}: TabListStatusProps) => {
  const [refreshDateTime, setRefreshDateTime] = useState(new Date());

  const refreshedAt = useTime(refreshDateTime, { lowercase: true });

  useEffect(() => {
    if (!refetching) {
      setRefreshDateTime(new Date());
    }
  }, [refetching]);

  return (
    <TabListStatusWrapper>
      <h3 className="date">Last refreshed {refreshedAt}</h3>
      <IconButton
        icon={
          <Icon className="icon" library="lucide" name="RefreshCw" size={15} />
        }
        className="icon"
        aria-label="Refresh Forms"
        onClick={() => {
          if (!refetching) {
            onRefetch();
          }
        }}
      />
    </TabListStatusWrapper>
  );
};
