import styled from "@emotion/styled";

import { Address, Phone, SBContact } from "@smart/bridge-smokeball-basic";
import { Country } from "@smart/bridge-types-basic";
import { loadDefaultCountry } from "@smart/itops-locale-dom";
import { specialChars } from "@smart/itops-utils-basic";

const formatPhoneNumber = (phoneNumber: Phone | null | undefined) => {
  if (!phoneNumber) return "";
  const areaCode = phoneNumber.areaCode ? `(${phoneNumber.areaCode}) ` : "";
  return `${areaCode}${phoneNumber.number || ""}`;
};

const formatAddressLine = (address: Address, country: Country) => {
  switch (country) {
    case "AU":
      return [
        [
          [address.unitNumber, address.streetNumber].filter(Boolean).join("/"),
          address.streetName,
          address.streetType,
        ]
          .filter(Boolean)
          .join(" "),
        [address.city, address.zipCode].filter(Boolean).join(" "),
      ]
        .filter(Boolean)
        .join(", ");
    case "US":
      return [
        address.addressLine1,
        address.addressLine2,
        address.county,
        address.city,
        address.zipCode,
      ]
        .filter(Boolean)
        .join(", ");
    case "GB":
      return [
        [
          [address.unitNumber, address.streetNumber].filter(Boolean).join("/"),
          address.streetName,
        ]
          .filter(Boolean)
          .join(" "),
        address.locality,
        address.city,
        [address.county, address.zipCode].filter(Boolean).join(" "),
      ]
        .filter(Boolean)
        .join(", ");
    default:
      return "";
  }
};

const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding-bottom: 1rem;

  .open-contact-button {
    margin-top: 1rem;
    color: ${(props) => props.theme.scheme.blue.r80};
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    align-self: flex-start;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContactDetails = ({
  contact,
  openContact,
}: {
  contact: Omit<SBContact, "id"> & { id?: string };
  openContact: ((id: string) => void) | undefined;
}) => {
  const isPerson = !!(contact.person && Object.keys(contact.person).length);
  const mobileNumber = formatPhoneNumber(contact.person?.cell);
  const homeNumber = formatPhoneNumber(contact.person?.phone);
  const companyNumber = formatPhoneNumber(contact.company?.phone);
  const email = contact.person?.email || contact.company?.email;
  const address =
    contact.person?.residentialAddress || contact.company?.businessAddress;
  const addressLine =
    address && formatAddressLine(address, loadDefaultCountry());
  const state = address?.state;
  const country = address?.country;

  return (
    <ContactDetailsWrapper>
      {isPerson ? (
        <>
          <div>Mob: {mobileNumber}</div>
          <div>Home: {homeNumber}</div>
        </>
      ) : (
        <div>Phone: {companyNumber}</div>
      )}
      <div>Email: {email || ""}</div>
      {addressLine && <div>{addressLine}</div>}
      {state && (
        <div>
          {specialChars.nbsp}
          {state}
        </div>
      )}
      {country && (
        <div>
          {specialChars.nbsp}
          {specialChars.nbsp}
          {country}
        </div>
      )}
      {contact.id && openContact && (
        <button
          type="button"
          className="open-contact-button"
          onClick={() => openContact(contact.id!)}
        >
          See all details
        </button>
      )}
    </ContactDetailsWrapper>
  );
};
