import {
  AvatarSize,
  AvatarStyle,
  getAvatarClassNames,
  getIconClassNames,
} from "./class-names";
import { Icon, IconName } from "../icon";

type AvatarProps = {
  style?: AvatarStyle;
  size: AvatarSize;
  text?: string;
  icon?: IconName;
  disabled?: boolean;
  active?: boolean;
  bgColor?: string;
};

export const Avatar = ({
  text,
  icon,
  size,
  style = "square",
  disabled,
  active,
  bgColor,
}: AvatarProps) => (
  <div
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    className={getAvatarClassNames({ size, style, disabled, active, bgColor })}
  >
    {icon ? (
      <Icon name={icon} className={getIconClassNames(size)} />
    ) : (
      <span>{text}</span>
    )}
  </div>
);
