import { useLazyQuery } from "@apollo/client";

import { removeEmptyValues } from "@smart/itops-utils-basic";
import { queryDocuments } from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useLoadAddressPredictions = useQueryFactory(
  queryDocuments.addressPredictions,
  {
    map: ({ addressPredictions }) =>
      addressPredictions.map((p) => ({
        place_id: p.placeId,
        description: p.description,
        structured_formatting: {
          main_text: p.mainText,
          secondary_text: p.secondaryText,
        },
      })),
  },
);

export const useLazyLoadAddressDetails = () => {
  const [load] = useLazyQuery(queryDocuments.addressDetails);

  return async (variables: { placeId: string; sessionKey?: string }) => {
    const result = await load({ variables });
    return result.data?.addressDetails
      ? removeEmptyValues(result.data.addressDetails)
      : undefined;
  };
};

export const useLoadAddressDetails = useQueryFactory(
  queryDocuments.addressDetails,
  {
    map: ({ addressDetails }) => removeEmptyValues(addressDetails),
  },
);
