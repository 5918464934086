/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlBankAccountFieldsFragment = {
  __typename: "BankAccount";
  typeDisplayName: string;
  type: number;
  paymentProvider: Types.GqlPaymentProvider;
  externalPaymentProvider: Types.GqlExternalPaymentProvider;
  name?: string | null;
  displayName?: string | null;
  location?: string | null;
  branchName?: string | null;
  canReceivePayments: boolean;
  identifier?: {
    __typename: "BankAccountIdentifier";
    id: string;
    type: number;
  } | null;
};

export const BankAccountFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "bankAccountFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BankAccount" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "identifier" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "typeDisplayName" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "paymentProvider" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "externalPaymentProvider" },
          },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "branchName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canReceivePayments" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlBankAccountFieldsFragment, unknown>;
