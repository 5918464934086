import { ReactNode } from "react";

import {
  sectionFallback,
  SectionItem,
} from "@smart/bridge-intake-components-dom";
import { Displayer } from "@smart/itops-sb-design-system-dom";

export type SummarySectionProps = {
  section: SectionItem;
  children?: ReactNode;
};

export const SummarySection = ({ section, children }: SummarySectionProps) => (
  <section className="flex flex-col gap-[2.4rem] border-solid border-b border-neutral-100 pb-[3rem] mb-[3rem]">
    <div className="text-h2 font-semibold font-poppins">
      <Displayer
        paragraphTag="h4"
        value={section.title}
        fallback={sectionFallback}
      />
    </div>
    {children}
  </section>
);
