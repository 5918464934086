import { useState } from "react";

import { loadLocale } from "@smart/itops-locale-dom";

import { AreaDisplay } from "./area-display";
import { EditAreasModal } from "../../shared/edit-areas-modal";
import { AreaInfo } from "../../types";

export type AreaEditorProps = {
  singular: string;
  plural: string;
  title: string;
  selected: AreaInfo[] | null | undefined;
  available: AreaInfo[] | null | undefined;
  save: (areas: string[]) => Promise<void>;
  loading?: boolean;
};

export const AreaEditor = ({
  singular,
  plural,
  title,
  selected,
  available,
  save,
  loading,
}: AreaEditorProps) => {
  const modal = useState(false);
  const { country } = loadLocale();
  const practising = country === "US" ? "practicing" : "practising";
  const [, setModal] = modal;

  return (
    <>
      <AreaDisplay
        heading={title}
        contents={selected?.map((area) => area.displayName)}
        empty={`No ${plural} selected`}
        onEdit={() => setModal(true)}
        loading={loading}
      />
      <EditAreasModal
        editAreasModal={modal}
        storedAreas={selected}
        availableAreas={available}
        save={save}
        loading={loading}
        requiredText={`Please select ${singular}.`}
        heading={`Select ${title}`}
        description={`Please select the ${plural} your firm is currently ${practising} in.`}
        name={plural}
        emptyText={`No ${plural} found`}
        disableSaveIfNoChange
      />
    </>
  );
};
