import { Icon } from "@smart/itops-icons-dom";
import { Button, EditableText } from "@smart/itops-ui-dom";

import { OptionContainer, OptionItemIndicator } from "./option-item";

export type OtherOptionItemProps = {
  type: "choice" | "checkbox";
  isShowingOtherOption?: boolean;
  removeOption: (index?: number) => void;
};

export const OtherOptionItem = ({
  type,
  isShowingOtherOption,
  removeOption,
}: OtherOptionItemProps) =>
  !isShowingOtherOption ? null : (
    <OptionContainer data-testid="other-option-item">
      <div className="option-drag-handle">
        <Icon
          library="lucide"
          name="GripVertical"
          className="option-drag-handle-icon"
        />
      </div>
      <OptionItemIndicator
        isRadio={type === "choice"}
        data-testid="select-icon-other"
      />
      <div className="option-item-edit">
        <EditableText
          text="Other"
          onChange={() => {}}
          onComplete={async () => {}}
          disabled
          fontSize="emphasis"
        />
      </div>
      <Button
        kind="borderless"
        icon={{ library: "lucide", name: "X" }}
        variant="plain"
        className="delete-button"
        dataTestId="delete-icon-other"
        onClick={() => removeOption()}
      />
    </OptionContainer>
  );
