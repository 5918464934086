import { TField } from "@smart/bridge-resources-basic";

export const validateForm = (fields: TField[], clientRole: string) => {
  const clientFieldNames = [
    "person/firstName",
    "person/lastName",
    "company/name",
  ];
  const mappedClientField = fields.find(
    (f) =>
      f.layout?.id === clientRole &&
      clientFieldNames.includes(f.field?.name || "") &&
      f.mandatory,
  );

  return !!mappedClientField;
};

export const findClientRoles = (
  matterTypes?: { representativeOptions: string[] }[],
) => {
  if (!matterTypes?.length) return [];
  return matterTypes[0].representativeOptions.filter((role) =>
    matterTypes.every((mt) => mt.representativeOptions.includes(role)),
  );
};
