import styled from "@emotion/styled";

import { IconButton } from "@smart/itops-components-dom";
import { buildDisplayName, formatTime } from "@smart/itops-utils-basic";

import {
  getHistoryEventType,
  GqlTracker,
  historyEventTypeLabel,
} from "./helpers";

export const SubmissionViewHistoryWrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.palette.background.highlight};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  width: ${(props) => (props.visible ? "30rem" : 0)};
  max-width: 40%;
  transition: width 0.4s ease;

  .history-header {
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    border-bottom: 1px solid
      ${(props) => props.theme.palette.background.highlight};

    h3 {
      flex: 1;
      font-weight: 500;
      font-size: ${(props) => props.theme.fontSize.emphasis};
      line-height: ${(props) => props.theme.fontSize.emphasis};
      margin: 0 0 0.5rem;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .history-list {
    flex: 1;
    overflow: hidden auto;

    .history-event {
      border-bottom: 1px solid
        ${(props) => props.theme.palette.background.highlight};
      margin: 1rem;
      padding-bottom: 0.8rem;

      h4.history-event-title {
        font-size: ${(props) => props.theme.fontSize.base};
        font-weight: bold;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p.history-event-actor,
      p.history-event-time {
        color: ${(props) => props.theme.palette.foreground.accent};
        font-size: ${(props) => props.theme.fontSize.base};
        line-height: 1.4;
        font-weight: 400;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

export type SubmissionViewHistoryProps = {
  trackers: (GqlTracker | null)[];
  visible: boolean;
  onClose: () => void;
};

const submissionViewHistoryEvent = (tracker: GqlTracker | null) => {
  if (!tracker) return null;
  const eventType = getHistoryEventType(tracker);
  if (!eventType) return null;

  return (
    <div
      key={tracker.uri}
      className="history-event"
      data-testid="history-event"
    >
      <h4 className="history-event-title">
        {historyEventTypeLabel[eventType]}
      </h4>
      {tracker.user && (
        <p className="history-event-actor">
          by {buildDisplayName(tracker.user, "team member")}
        </p>
      )}
      {tracker.sentAt && (
        <p className="history-event-time">{formatTime(tracker.sentAt)}</p>
      )}
    </div>
  );
};

export const SubmissionViewHistory = ({
  trackers,
  visible,
  onClose,
}: SubmissionViewHistoryProps) => (
  <SubmissionViewHistoryWrapper visible={visible}>
    <div className="history-header">
      <h3>History</h3>
      <IconButton
        name="cancel"
        palette="foreground"
        aria-label="Close History"
        size={10}
        onClick={onClose}
      />
    </div>
    <div className="history-list">
      {trackers.map(submissionViewHistoryEvent)}
    </div>
  </SubmissionViewHistoryWrapper>
);
