import { clsx } from "clsx";

export type BadgeVariant = "rectangle" | "round";

export type BadgeTheme =
  | "neutral"
  | "emphasis"
  | "highlight"
  | "branding"
  | "success"
  | "warning"
  | "destructive"
  | "information"
  | "pink"
  | "purple";

const base =
  "flex justify-center items-center gap-[0.4rem] px-[0.8rem] py-[0.2rem] w-fit font-semibold text-caption";

export const badgeClassNames = ({ isRound }: { isRound?: boolean }) =>
  clsx(base, isRound ? "rounded-[1rem]" : "rounded-small");

export const badgeThemeClassNames: Record<
  BadgeTheme,
  { tint: string; shade: string }
> = {
  neutral: { tint: "bg-white text-black", shade: "bg-neutral-500 text-white" },
  emphasis: {
    tint: "bg-neutral-50 text-black",
    shade: "bg-neutral-750 text-white",
  },
  highlight: {
    tint: "bg-cyan-140 text-black",
    shade: "bg-cyan-240 text-black",
  },
  branding: {
    tint: "bg-orange-200 text-black",
    shade: "bg-orange-500 text-white",
  },
  success: {
    tint: "bg-green-200 text-black",
    shade: "bg-green-400 text-black",
  },
  warning: {
    tint: "bg-yellow-250 text-black",
    shade: "bg-yellow-450 text-black",
  },
  destructive: {
    tint: "bg-red-150 text-black",
    shade: "bg-red-550 text-white",
  },
  information: {
    tint: "bg-blue-160 text-black",
    shade: "bg-blue-560 text-white",
  },
  pink: { tint: "bg-pink-150 text-black", shade: "bg-pink-450 text-white" },
  purple: {
    tint: "bg-purple-200 text-black",
    shade: "bg-purple-400 text-white",
  },
};
