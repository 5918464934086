/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlPhoneFieldsFragment = {
  __typename: "Phone";
  areaCode?: string | null;
  number?: string | null;
};

export const PhoneFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "phoneFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Phone" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "areaCode" } },
          { kind: "Field", name: { kind: "Name", value: "number" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlPhoneFieldsFragment, unknown>;
