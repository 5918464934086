import { FieldType } from "@smart/bridge-types-basic";
import { ContextMenu } from "@smart/itops-ui-dom";

type ActionsProps = {
  type: FieldType;
  descriptionVisible: boolean;
  mandatory: boolean;
  onToggleDescription: (visible: boolean) => void;
  onToggleMandatory: (mandatory: boolean) => void;
  onDelete: () => void;
};

export const Actions = ({
  type,
  descriptionVisible,
  mandatory,
  onToggleDescription,
  onToggleMandatory,
  onDelete,
}: ActionsProps) => (
  <ContextMenu
    id="field-actions"
    placement="bottom"
    tooltipText="Field settings"
    button={{
      kind: "borderless",
      icon: { library: "lucide", name: "SlidersHorizontal" },
      variant: "plain",
    }}
    items={[
      {
        text: "Description text",
        checked: descriptionVisible,
        onClick: () => onToggleDescription(!descriptionVisible),
      },
      {
        text: "Mandatory field",
        checked: mandatory,
        onClick: () => onToggleMandatory(!mandatory),
        hidden: type === "info",
      },
      {
        text: "Delete field",
        schemeColor: ["red", "r100"],
        icon: { library: "lucide", name: "Trash2" },
        onClick: onDelete,
        divider: "top",
      },
    ]}
  />
);
