import { useLoadSections } from "@smart/manage-gql-client-dom";

import { FormTabProps } from "./types";
import { SectionList } from "../../sections";

const FormQuestions = ({ mode, form }: FormTabProps) => {
  const sections = useLoadSections({ formUri: form.uri });

  return (
    <SectionList
      mode={mode}
      formUri={form.uri}
      sections={sections.result || []}
      result={sections}
    />
  );
};

export { FormQuestions };
