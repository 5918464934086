import styled from "@emotion/styled";
import { useState } from "react";

import { Icon } from "@smart/itops-components-dom";

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionItem = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-bottom: 1px solid transparent;
  padding: 0 1rem;

  &:last-of-type {
    border-bottom-color: ${(props) => props.theme.palette.disabled.base};
  }
`;

export const AccordionToggle = styled.button`
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.emphasis};
  font-weight: 500;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  outline: none;
`;

export const AccordionContent = styled.div`
  max-height: 600px;
  padding: 1rem 0;

  transition:
    max-height 0.25s ease-in-out,
    padding 0.25s ease-in-out;
  overflow: hidden;

  &[aria-hidden="true"] {
    max-height: 0;
    padding: 0;
  }
`;

export type AccordionProps<K extends string> = {
  keys: K[];
  initial?: K[];
  labels: Record<K, string>;
  views: Record<K, JSX.Element>;
};

export const Accordion = <K extends string>({
  keys,
  initial = [],
  labels,
  views,
}: AccordionProps<K>) => {
  const [selected, setSelected] = useState(initial);

  const toggleAccordion = (key: K) => {
    if (selected.includes(key)) {
      setSelected(selected.filter((item) => item !== key));
    } else {
      setSelected([...selected, key]);
    }
  };

  return (
    <AccordionWrapper>
      {keys.map((key) => (
        <AccordionItem key={key}>
          <AccordionToggle onClick={() => toggleAccordion(key)}>
            <span>{labels[key]}</span>
            <Icon
              name="angleRight"
              size={16}
              rotate={selected.includes(key) ? 270 : 90}
            />
          </AccordionToggle>
          <AccordionContent aria-hidden={!selected.includes(key)}>
            {views[key]}
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};
