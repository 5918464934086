import { useEffect, useRef, useState } from "react";

export const useWidth = <T extends HTMLElement = HTMLElement>() => {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return { width, ref };
};
