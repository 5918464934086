const baseLengthsByFinalPart = (finalPart: string) => {
  switch (finalPart) {
    case "au":
    case "uk":
      return 3;
    case "com":
    case "dev":
    default:
      return 2;
  }
};

export const toSubdomain = (subdomain: string | undefined, path?: string) => {
  const parts = window.location.host.split(".");
  const baseLength = baseLengthsByFinalPart(parts[parts.length - 1]);

  if (parts.length === baseLength && subdomain) {
    parts.unshift(subdomain);
  } else if (subdomain) {
    parts[0] = subdomain;
  } else {
    parts.shift();
  }
  return `${window.location.protocol}//${parts.join(".")}${path || ""}`;
};
