import styled from "@emotion/styled";
import { useState } from "react";

import { SBContact } from "@smart/bridge-smokeball-basic";
import { Icon, RadioItem } from "@smart/itops-components-dom";
import {
  AccordionContent,
  AccordionToggle,
} from "@smart/itops-smokeball-components-dom";
import { buildDisplayName } from "@smart/itops-utils-basic";

import { ContactDetails } from "./contact-details";

const ContactItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 500;

  .contact-details-toggle {
    position: relative;
    font-size: ${(props) => props.theme.fontSize.base};
    padding: 1.5rem 0;

    .toggle-icon {
      position: absolute;
      left: -1.3rem;
    }
  }

  .contact-details {
    padding: 0;
  }

  .select-contact {
    min-width: 23rem;
  }
`;

export const ContactItem = ({
  contact,
  isChecked,
  value,
  onChange,
  label,
  openContact,
}: {
  contact: Omit<SBContact, "id"> & { id?: string };
  isChecked: boolean;
  value: string;
  onChange: (value: string) => void;
  label: string;
  openContact: ((id: string) => void) | undefined;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const contactName =
    (contact.person && Object.keys(contact.person).length
      ? buildDisplayName(contact.person, contact.id || "")
      : contact.company?.name || contact.id) || "Intake contact";

  return (
    <ContactItemWrapper data-testid="contact-item">
      <div>
        <AccordionToggle
          className="contact-details-toggle"
          onClick={() => setIsExpanded(!isExpanded)}
          type="button"
        >
          <Icon
            className="toggle-icon"
            name="angleRight"
            size={10}
            rotate={isExpanded ? 90 : 0}
          />
          {contactName}
        </AccordionToggle>
        <AccordionContent className="contact-details" aria-hidden={!isExpanded}>
          <ContactDetails contact={contact} openContact={openContact} />
        </AccordionContent>
      </div>
      <div className="select-contact">
        <RadioItem
          value={value}
          checked={isChecked}
          onChange={onChange}
          label={label}
        />
      </div>
    </ContactItemWrapper>
  );
};
