export const buildDisplayName = (
  parts:
    | {
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      }
    | null
    | undefined,
  fallback: string,
) =>
  (parts
    ? [parts.firstName, parts.lastName].filter(Boolean).join(" ")
    : undefined) ||
  parts?.email ||
  fallback;

export const buildInitials = (
  parts:
    | {
        firstName?: string | null;
        lastName?: string | null;
        initials?: string | null;
      }
    | null
    | undefined,
  fallback: string,
) => {
  if (parts?.initials) return parts.initials;
  if (parts?.firstName || parts?.lastName)
    return [parts.firstName?.slice(0, 1), parts.lastName?.slice(0, 1)]
      .filter(Boolean)
      .join("");
  return fallback;
};

export const parseName = (name: string) => {
  const parts = name.split(" ");
  const firstName = parts.shift()!;
  const lastName = parts.pop();
  const middleName = parts.join(" ");

  return {
    firstName,
    middleName: middleName || undefined,
    lastName,
  };
};
