import { queryDocuments } from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useLoadBankAccounts = useQueryFactory(
  queryDocuments.bankAccounts,
  {
    map: ({ bankAccounts }) =>
      bankAccounts.filter((account) => account.canReceivePayments),
  },
);
