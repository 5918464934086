import { ThemeProvider } from "@emotion/react";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  IntakeForm,
  IntakeNotFound,
  intakeTheme,
} from "@smart/bridge-intake-components-dom";
import { FakeIframe, SampleWebpage } from "@smart/bridge-sample-webpage-dom";
import { FormNS, SubmissionNS } from "@smart/bridge-types-basic";
import { GlobalStyles, Loading } from "@smart/itops-components-dom";
import { useParamsWithDefault } from "@smart/itops-hooks-dom";
import {
  ClientProvider,
  useLoadFormForDisplay,
  UserProvider,
} from "@smart/manage-hooks-dom";

export const PreviewWrapper = () => (
  <ThemeProvider theme={intakeTheme}>
    <GlobalStyles />
    <UserProvider>
      <ClientProvider>
        <SampleWebpage>
          <FakeIframe>
            <Outlet />
          </FakeIframe>
        </SampleWebpage>
      </ClientProvider>
    </UserProvider>
  </ThemeProvider>
);

export const Preview = () => {
  const { formUri } = useParamsWithDefault((p) => ({
    formUri: p.id ? FormNS.generateUri(p.id) : "",
  }));
  const showConnectionBanner = useState(true);

  const { loading, exists, ...rest } = useLoadFormForDisplay({ formUri });
  const { aiWordSupport } = useFeatureFlags();

  if (loading) return <Loading />;

  if (!exists) return <IntakeNotFound />;

  return (
    <IntakeForm
      {...rest}
      submission={{ uri: SubmissionNS.generateUri(), responses: {} }}
      showConnectionBanner={showConnectionBanner}
      submitResponses={async () => undefined}
      loadResponses={async () => undefined}
      staffDetails={[]}
      aiWordSupport={aiWordSupport}
    />
  );
};
