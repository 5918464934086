import { useEffect, useState } from "react";

import { v4, extractCognitoToken } from "@smart/itops-utils-basic";

import { useAuth } from "./auth";
import { useCommunicate } from "./communicate";
import { useContacts } from "./contacts";
import { useCorrespondence } from "./correspondence";
import { useFiles } from "./files";
import { useHost } from "./host";
import { useLayouts } from "./layouts";
import { useMatters } from "./matters";
import { useRelationships } from "./relationships";
import { useRoles } from "./roles";
import { useStaff } from "./staff";
import { getLocalSmokeballAppContext } from "./utils";
import { SmokeballAppClient } from "../client";
import { UseSmokeballAppOptions } from "../types";

const sessionId = v4();

export const useLocalClient = (
  options?: UseSmokeballAppOptions,
): SmokeballAppClient => {
  const localContext = getLocalSmokeballAppContext();
  const [context, setContext] = useState<
    NonNullable<SmokeballAppClient["context"]>
  >({
    sessionId,
    firmId: localContext?.firmId || "",
    userId: localContext?.userId || "",
    matterId: localContext?.matterId,
    region: localContext?.region || { name: "", code: "" },
  });

  useEffect(() => {
    (async () => {
      const token = await useAuth().token();

      if (token) {
        const extracted = token ? await extractCognitoToken(token) : undefined;
        setContext({
          sessionId,
          firmId:
            (extracted as any)?.["custom:smokeball:firmId"] ||
            localContext?.firmId ||
            "",
          userId: extracted?.username || localContext?.userId || "",
          matterId: localContext?.matterId,
          region: { name: "", code: "" },
        });
      }
    })().catch(console.error);
  }, []);

  const localApiOptions = { context, observe: options?.observe };

  return {
    hostType: "local",
    context,
    communicate: useCommunicate(localApiOptions),
    correspondence: useCorrespondence(),
    matters: useMatters(localApiOptions),
    layouts: useLayouts(localApiOptions),
    contacts: useContacts(localApiOptions),
    relationships: useRelationships(),
    roles: useRoles(),
    auth: useAuth(),
    host: useHost(),
    staff: useStaff(localApiOptions),
    files: useFiles(localApiOptions),
  };
};

export * from "./utils";
