import styled from "@emotion/styled";
import { useMemo, useState } from "react";

import { SectionItem } from "@smart/bridge-intake-components-dom";
import { FormCategory, GeneratedFormSchema } from "@smart/bridge-types-basic";
import { useRefresh } from "@smart/itops-hooks-dom";
import {
  jsonParse,
  logError,
  rankBetween,
  sortField,
} from "@smart/itops-utils-basic";
import {
  useAddFormMatterType,
  useLoadStaffDetails,
  useQueryMatterFields,
  useRemoveFormMatterType,
  useToggleSlug,
  useUpdateForm,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { EditView } from "./edit-view";
import { FormHeading } from "./heading";
import { Preview } from "./preview";
import { FormInfo, UpdateAIFillSettings } from "./types";
import { FormLoadingStatus } from "../create-form/creation-status";
import { EditingFormInfo } from "../list-forms/form";
import {
  GqlField,
  GqlForm,
  GqlGroup,
  GqlMatterLayout,
  GqlMatterType,
  GqlSection,
  GqlSetting,
  GqlTeam,
} from "../types";

const EditFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background: ${(props) => props.theme.palette.background.accent};
`;

type ViewProps = {
  tab?: "preview" | "questions";
  fields?: GqlField[] | null;
  formUri: string;
  creationStatus?: string | null;
  sections?: GqlSection[] | null;
  team?: GqlTeam | null;
  setting?: GqlSetting | null;
  form?: GqlForm | null;
  groups?: GqlGroup[] | null;
  loading: {
    team: boolean;
    form: boolean;
    sections: boolean;
    groups: boolean;
    fields: boolean;
    matterTypes: boolean;
    matterLayouts: boolean;
  };
  canPreviewOnDraft?: boolean;
  previewOptions: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    previewResponses: [
      Record<string, any>,
      React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ];
    previewLastSection: [
      SectionItem | "summary" | undefined,
      React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
    ];
  };
  loadingStatus: FormLoadingStatus;
  matterTypes?: GqlMatterType[];
  matterLayouts?: GqlMatterLayout[];
  loadMatterFields: ReturnType<typeof useQueryMatterFields>;
  createForm: () => Promise<void>;
  updateAIFillSettings: UpdateAIFillSettings;
  canUpdate: boolean;
  setTab: React.Dispatch<React.SetStateAction<"questions" | "preview">>;
  teamForms?: FormInfo[];
  editFormLastSectionUri: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>,
  ];
  staffDetails: ReturnType<typeof useLoadStaffDetails>;
  intakeFormKey: string;
};

const buildQuestionsFromAIFeedback = (
  feedback?: GqlForm["aiUserFeedback"] | null,
  creationStatus?: string | null,
) =>
  useMemo(() => {
    if (
      feedback &&
      (feedback.status === "generating" ||
        feedback.status === "generated" ||
        (feedback.status === "completed" && !!creationStatus)) &&
      feedback.output
    ) {
      try {
        const updatedAt = new Date().toISOString();
        const generated = GeneratedFormSchema.parse(
          jsonParse(feedback.output, "parse generated output"),
        );

        let previousSection: string | undefined;
        const sections: GqlSection[] = [];
        const fields: GqlField[] = [];
        for (const section of sortField(generated.sections, {
          key: "index",
          dir: "asc",
        })) {
          const sectionOrder = rankBetween({ after: previousSection });
          previousSection = sectionOrder;

          sections.push({
            uri: section.uri,
            __typename: "Section",
            values: {
              uri: section.uri,
              formUri: feedback.formUri!,
              title: section.displayTitle || section.title,
              order: sectionOrder,
              updatedAt,
              operationId: "",
              __typename: "SectionValues",
            },
          });

          let previousField: string | undefined;
          for (const field of sortField(section.questions || [], {
            key: "index",
            dir: "asc",
          })) {
            const fieldOrder = rankBetween({ after: previousField });
            previousField = fieldOrder;

            fields.push({
              uri: field.uri,
              __typename: "Field",
              values: {
                uri: field.uri,
                sectionUri: section.uri,
                formUri: feedback.formUri!,
                order: fieldOrder,
                label: field.label,
                type: field.type,
                options:
                  field.options?.map((o) => ({
                    __typename: "FieldOption",
                    label: o,
                    value: o,
                  })) || [],
                hint: "",
                mandatory: false,
                updatedAt,
                operationId: "",
                layout: field.layout && {
                  __typename: "MatterLayout",
                  ...field.layout,
                },
                field: field.field && {
                  __typename: "MatterField",
                  ...field.field,
                },
                __typename: "FieldValues",
              },
            });
          }
        }

        return {
          sections,
          fields,
          groups: [],
        };
      } catch (e) {
        logError(e, feedback);
      }
    }

    return undefined;
  }, [creationStatus, feedback?.status, feedback?.output]);

const View = ({
  tab,
  fields,
  formUri,
  creationStatus,
  sections,
  team,
  setting,
  form,
  groups,
  loading,
  canPreviewOnDraft,
  previewOptions,
  loadingStatus,
  matterTypes,
  matterLayouts,
  loadMatterFields,
  createForm,
  updateAIFillSettings,
  canUpdate,
  setTab,
  teamForms,
  editFormLastSectionUri,
  staffDetails,
  intakeFormKey,
}: ViewProps) => {
  const feedbackQuestions = buildQuestionsFromAIFeedback(
    form?.aiUserFeedback,
    creationStatus,
  );
  const questions = feedbackQuestions || {
    sections,
    groups,
    fields,
  };

  switch (tab) {
    case "questions":
      return (
        <EditView
          formUri={formUri}
          form={form}
          loading={loading}
          loadingStatus={loadingStatus}
          matterTypes={matterTypes}
          matterLayouts={matterLayouts}
          loadMatterFields={loadMatterFields}
          createForm={createForm}
          updateAIFillSettings={updateAIFillSettings}
          canUpdate={canUpdate}
          setTab={setTab}
          teamForms={teamForms}
          editFormLastSectionUri={editFormLastSectionUri}
          {...questions}
        />
      );
    case "preview":
      return (
        <PreviewWrapper>
          <Preview
            team={team}
            setting={setting}
            form={form}
            formUri={formUri}
            canPreviewOnDraft={canPreviewOnDraft}
            previewOptions={previewOptions}
            previewLoading={
              loading.team ||
              loading.form ||
              loading.sections ||
              loading.groups ||
              loading.fields
            }
            staffDetails={staffDetails}
            intakeFormKey={intakeFormKey}
            {...questions}
          />
        </PreviewWrapper>
      );
    default:
      return null;
  }
};

type EditFormProps = {
  formUri: string;
  team?: GqlTeam | null;
  setting?: GqlSetting | null;
  form?: GqlForm | null;
  sections?: GqlSection[] | null;
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  title?: string;
  loading: {
    team: boolean;
    form: boolean;
    matterTypes: boolean;
    sections: boolean;
    groups: boolean;
    fields: boolean;
    matterLayouts: boolean;
  };
  loadingStatus: FormLoadingStatus;
  category: FormCategory | undefined;
  matterTypes?: GqlMatterType[];
  matterLayouts?: GqlMatterLayout[];
  teamForms?: FormInfo[];
  loadMatterFields: ReturnType<typeof useQueryMatterFields>;
  createForm: () => Promise<void>;
};

const EditFormV2 = ({
  formUri,
  team,
  setting,
  form,
  sections,
  groups,
  fields,
  title,
  loading,
  loadingStatus,
  category,
  matterTypes,
  matterLayouts,
  teamForms,
  loadMatterFields,
  createForm,
}: EditFormProps) => {
  const [intakeFormKey, refreshIntakeForm] = useRefresh();
  const previewErrorToggle = useState(false);
  const previewResponses = useState<Record<string, any>>({});
  const previewLastSection = useState<SectionItem | "summary" | undefined>();
  const editFormLastSectionUri = useState<string | undefined>();
  const { permission } = useUser();
  const [updateForm, { loading: updatingForm }] = useUpdateForm();
  const [updateSlug, { loading: updatingSlug }] = useToggleSlug();
  const [addFormMatterType] = useAddFormMatterType();
  const [removeFormMatterType] = useRemoveFormMatterType();
  const staffDetails = useLoadStaffDetails({
    staffIds: [
      ...new Set(
        fields?.flatMap((field) => field.values.availableStaffIds ?? []),
      ),
    ],
  });

  const clearIntakeForm = () => {
    previewResponses[1]({});
    previewLastSection[1](undefined);
    refreshIntakeForm();
  };

  const formTitle = form?.formTitle || title;
  const isFormActive = !!form?.active;
  const matterTypeNames =
    form?.matterTypeNames || matterTypes?.map((mt) => mt.name);
  const matterTypeLocations =
    form?.matterTypeLocations || matterTypes?.map((mt) => mt.location);

  const canUpdate =
    !form ||
    !permission ||
    permission?.can("update", { type: "form", value: form });

  const [tab, setTab] = useState<"questions" | "preview">(
    canUpdate ? "questions" : "preview",
  );

  let activeTab;
  if ((form || !loading.form) && permission) activeTab = tab;

  const canActivate = permission?.can("update", {
    type: "slug",
    value: {
      uri: form?.rawForm.slug?.uri || "",
      formUri,
      active: form?.rawForm.slug?.values.active ?? false,
    },
    parentForm: {
      uri: formUri,
      teamUri: form?.teamUri || "",
      active: form?.rawForm.slug?.values.active ?? undefined,
    },
  });

  const toggleFormActive = async () => {
    await updateSlug({
      variables: {
        formUri,
        fields: { active: !isFormActive },
      },
    });
  };

  const validateFormTitle = (updatedTitle: string): boolean =>
    !!updatedTitle &&
    !teamForms?.find(
      (f) =>
        f.uri !== formUri &&
        f.formTitle.toLocaleLowerCase() === updatedTitle.toLocaleLowerCase(),
    );

  const updateFormTitle = async (updatedTitle: string) => {
    if (!form) await createForm();

    const formCategory = form?.formCategory || category;
    if (formCategory) {
      await updateForm({
        variables: {
          uri: formUri,
          fields: {
            title: updatedTitle,
            category: formCategory,
            response: form?.rawForm.values.response || "",
          },
        },
      });
    }
  };

  const updateAIFillSettings: UpdateAIFillSettings = async (settings) => {
    if (form && Object.keys(settings).length) {
      await updateForm({
        variables: {
          uri: formUri,
          fields: {
            title: form.formTitle,
            category: form.formCategory,
            response: form.rawForm.values.response,
            aiFillSettings: settings,
          },
        },
      });
    }
  };

  const saveConfirmationMessage = async (value?: string) => {
    if (form) {
      await updateForm({
        variables: {
          uri: form.rawForm.uri,
          fields: {
            title: form.formTitle,
            category: form.formCategory,
            response: value || "",
          },
        },
      }).catch(console.error);
    }
  };

  const findMatterTypeDifference = ({
    originalMatterTypes,
    modifiedMatterTypes,
  }: {
    originalMatterTypes: GqlMatterType[];
    modifiedMatterTypes: GqlMatterType[];
  }) => {
    const matterTypesToAdd = modifiedMatterTypes.filter(
      (modifiedMatterType) =>
        !originalMatterTypes.some(
          (original) => original.id === modifiedMatterType.id,
        ),
    );

    const matterTypesToRemove = originalMatterTypes.filter(
      (originalMatterType) =>
        !modifiedMatterTypes.some(
          (modified) => modified.id === originalMatterType.id,
        ),
    );

    return { matterTypesToAdd, matterTypesToRemove };
  };

  const updateFormInfo = async (values: EditingFormInfo) => {
    if (!form) return;

    await updateForm({
      variables: {
        uri: form.rawForm.uri,
        fields: {
          title: form.formTitle,
          category: values.formCategory,
          response: form.rawForm.values.response,
        },
      },
    }).catch(console.error);

    const { matterTypesToAdd, matterTypesToRemove } = findMatterTypeDifference({
      originalMatterTypes:
        matterTypes?.map((mt) => ({
          category: mt.category,
          id: mt.id,
          location: mt.location,
          name: mt.name,
          representativeOptions: mt.representativeOptions,
          source: mt.source,
        })) || [],
      modifiedMatterTypes: values.matterTypeGroups.flatMap(
        (g) => g.matterTypes,
      ),
    });

    if (!matterTypesToAdd.length && !matterTypesToRemove.length) return;

    await Promise.allSettled(
      matterTypesToRemove.map((matterType) =>
        removeFormMatterType({
          variables: {
            formUri,
            matterType,
          },
        }),
      ),
    ).catch(console.error);

    await Promise.allSettled(
      matterTypesToAdd.map((matterType) =>
        addFormMatterType({
          variables: {
            formUri,
            matterType,
          },
        }),
      ),
    ).catch(console.error);
  };

  const disabled =
    loadingStatus === "checking" ||
    loadingStatus === "timedOut" ||
    form?.aiUserFeedback?.status === "generating" ||
    form?.aiUserFeedback?.status === "generated" ||
    (loading.form && !formTitle);

  return (
    <EditFormWrapper>
      <FormHeading
        formUri={formUri}
        formTitle={formTitle || ""}
        tab={activeTab}
        isMatterSpecific={!!form?.rawForm.values.matterId}
        isFormActive={isFormActive}
        setTab={setTab}
        canActivate={canActivate}
        disabled={disabled}
        updating={updatingForm || updatingSlug}
        loading={loading}
        canUpdate={canUpdate}
        matterTypeNames={matterTypeNames || []}
        matterTypeLocations={matterTypeLocations || []}
        onActiveToggleClick={toggleFormActive}
        onFormTitleUpdate={updateFormTitle}
        validateFormTitle={validateFormTitle}
        confirmationMessage={form?.rawForm.values.response || ""}
        saveConfirmationMessage={saveConfirmationMessage}
        defaultConfirmationMessage={setting?.defaultClientMessage?.confirmation}
        placeholderData={{
          firmName: team?.values.name,
          firmPhone: team?.values.phone,
          matterCategory: form?.formCategory,
        }}
        updateFormInfo={updateFormInfo}
        setting={setting}
        category={form?.formCategory || category}
        matterTypes={matterTypes || []}
        groups={groups}
        fields={fields}
        previewErrorToggle={previewErrorToggle}
        clearIntakeForm={clearIntakeForm}
        disableTabs={loadingStatus === "checking"}
      />
      <View
        tab={activeTab}
        fields={fields}
        formUri={formUri}
        creationStatus={form?.creationStatus}
        sections={sections}
        team={team}
        setting={setting}
        form={form}
        groups={groups}
        loading={loading}
        canPreviewOnDraft
        previewOptions={{
          previewErrorToggle,
          previewResponses,
          previewLastSection,
        }}
        loadingStatus={loadingStatus}
        matterTypes={matterTypes}
        matterLayouts={matterLayouts}
        loadMatterFields={loadMatterFields}
        createForm={createForm}
        updateAIFillSettings={updateAIFillSettings}
        canUpdate={canUpdate}
        setTab={setTab}
        teamForms={teamForms}
        editFormLastSectionUri={editFormLastSectionUri}
        staffDetails={staffDetails}
        intakeFormKey={intakeFormKey}
      />
    </EditFormWrapper>
  );
};

export { EditFormV2 };
