import styled from "@emotion/styled";
import { ReactNode } from "react";

import { useWidth } from "@smart/itops-hooks-dom";

import { Tooltip } from "../tooltip";

type HintContentProps = {
  children: ReactNode;
  maxWidth: number;
};

type HintProps = HintContentProps & {
  iconSize?: number;
  placement?: "top-start" | "top-end" | "bottom-start";
  offsetOptions?: {
    mainAxis: number;
    crossAxis: number;
  };
};

const HintWrapper = styled.div<{ isOverflow: boolean; maxWidth: number }>`
  padding: 0.6rem;
  white-space: ${(props) => (props.isOverflow ? "normal" : "nowrap")};
  width: ${(props) => (props.isOverflow ? `${props.maxWidth}px` : "auto")};
  overflow-x: hidden;
  font-weight: 400;

  p {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.fontSize.base};
    line-height: 1.6;
    margin: 0;
  }
`;

const HintContent = ({ children, maxWidth }: HintContentProps) => {
  const { width, ref } = useWidth<HTMLDivElement>();
  const isOverflow = !!(maxWidth && width && width >= maxWidth);

  return (
    <HintWrapper ref={ref} isOverflow={isOverflow} maxWidth={maxWidth}>
      {children}
    </HintWrapper>
  );
};

const Hint = ({
  placement,
  offsetOptions,
  iconSize,
  ...otherProps
}: HintProps) => (
  <Tooltip
    placement={placement}
    offsetOptions={offsetOptions}
    iconSize={iconSize}
  >
    <HintContent {...otherProps} />
  </Tooltip>
);

export { Hint, HintProps };
