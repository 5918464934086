import { FieldType } from "@smart/bridge-types-basic";
import { toSentenceCase } from "@smart/itops-utils-basic";

import {
  GqlFieldValues,
  GqlGroupValues,
  MatterField,
  NewFieldOptions,
} from "../types";

export const mapFieldType = (
  matterField: MatterField,
): FieldType | undefined => {
  switch (matterField.type) {
    case "Text":
    case "Number":
      return "text";
    case "Address":
      return "address";
    case "Phone":
      return "phoneNumber";
    case "Email":
      return "email";
    case "DateTime":
      return "date";
    case "Combobox":
    case "Checkbox":
      return "choice";
    case "Checkboxes":
      return "checkbox";
    default:
      console.warn(`Unknow matter field type ${matterField.type}`, matterField);
      return undefined;
  }
};

export const mapFieldOptions = ({
  possibleValues,
}: MatterField): Pick<NewFieldOptions, "options" | "allowCustomResponse"> => {
  if (!possibleValues) return {};
  return {
    options: possibleValues
      .filter((v) => !!v)
      .map((v) => ({ label: v, value: v })),
    allowCustomResponse: possibleValues.includes(""),
  };
};

export const buildMappedPathParts = ({
  layout,
  field,
  groupMatterField,
  matterFields,
}: {
  layout: NonNullable<GqlFieldValues["layout"]>;
  field: NonNullable<GqlFieldValues["field"]>;
  groupMatterField?: GqlGroupValues["field"] | null;
  matterFields: MatterField[];
}) => {
  const pathParts = [
    layout.parentName && toSentenceCase(layout.parentName),
    toSentenceCase(layout.displayName || layout.name),
  ];

  const addFieldPath = (
    parts: (string | null | undefined)[],
    inputField:
      | { displayName?: string | null; name: string }
      | null
      | undefined,
  ) => {
    if (!inputField) return;

    const matterField = matterFields.find(
      (mf) => mf.name.toLowerCase() === inputField.name.toLowerCase(),
    );
    const displayMatterField = matterField || inputField;
    const [firstPart] = displayMatterField.name.split("/");
    const fieldDisplayNameParts = (
      displayMatterField.displayName || displayMatterField.name
    )
      .split("/")
      .map((p: string) => toSentenceCase(p));

    if (firstPart === layout.name) fieldDisplayNameParts.shift();
    parts.push(...fieldDisplayNameParts);
  };

  addFieldPath(pathParts, groupMatterField);
  addFieldPath(pathParts, field);

  return pathParts;
};
