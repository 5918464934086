import styled from "@emotion/styled";

import { Hint } from "@smart/itops-components-dom";
import { Button } from "@smart/itops-smokeball-components-dom";

import { EmbedIcon, EmbedIconProps } from "./embed-icon";

const EmbedOptionsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 0.2rem;
`;

const EmbedOptionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;

  .option-info {
    h3 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.4rem;

      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: bold;
      margin: 0 0 0.4rem;
    }
  }
`;

export const embedTypes = ["url", "iframe", "button"] as const;

export type EmbedType = (typeof embedTypes)[number];

const options: Record<
  EmbedType,
  {
    title: string;
    description: string;
    button: string;
    selected: EmbedIconProps["selected"];
  }
> = {
  url: {
    title: "Link",
    description: "Directly share a link to this form.",
    button: "Copy Link",
    selected: [true, false, false, false, false],
  },
  iframe: {
    title: "Embedded",
    description: "Embed this form on your website.",
    button: "Copy Code",
    selected: [false, true, true, true, true],
  },
  button: {
    title: "'Contact Us' Button",
    description: "Add a 'Contact Us' button to your website.",
    button: "Copy Code",
    selected: [false, false, false, false, true],
  },
};

export type EmbedOptionProps = {
  disabled?: boolean;
  onCopy: (type: EmbedType) => Promise<void>;
};

export const EmbedOptions = ({ disabled, onCopy }: EmbedOptionProps) => (
  <EmbedOptionsWrapper>
    {embedTypes.map((type) => (
      <EmbedOptionWrapper key={type}>
        <EmbedIcon selected={options[type].selected} />
        <div className="option-info">
          <h3>
            <span>{options[type].title} </span>
            <Hint
              placement="top-start"
              offsetOptions={{ mainAxis: -5, crossAxis: 0 }}
              iconSize={16}
              maxWidth={300}
            >
              {options[type].description}
            </Hint>
          </h3>
          <Button
            small
            onClick={() => !disabled && onCopy(type)}
            text={options[type].button}
            data-testid={`copy-${type}`}
          />
        </div>
      </EmbedOptionWrapper>
    ))}
  </EmbedOptionsWrapper>
);
