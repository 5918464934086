/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterLayoutFieldsFragment = {
  __typename: "MatterLayout";
  id: string;
  providerId: string;
  name: string;
  parentId?: string | null;
  parentName?: string | null;
  parentProviderId?: string | null;
  displayName?: string | null;
};

export const MatterLayoutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "matterLayoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatterLayout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "providerId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "parentId" } },
          { kind: "Field", name: { kind: "Name", value: "parentName" } },
          { kind: "Field", name: { kind: "Name", value: "parentProviderId" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlMatterLayoutFieldsFragment, unknown>;
