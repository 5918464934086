import clsx from "clsx";
import { useMemo } from "react";

import { roleProviderId } from "@smart/bridge-smokeball-basic";
import { ProgressBar } from "@smart/itops-sb-design-system-dom";
import {
  isNotNullOrUndefined,
  partition,
  toSentenceCase,
  unique,
} from "@smart/itops-utils-basic";

import { hideField } from "./helpers";
import { PickerItem } from "./picker-item";
import { fieldComponentIcons } from "../../constants";
import { GqlMatterLayout, GqlMatterType, MatterField } from "../../types";
import { mapFieldType } from "../../utils";

const crumbClassNames =
  "enabled:text-blue-560 relative before:content-[''] before:absolute before:w-full before:bottom-[-1.2rem] before:border-y before:border-neutral-500 after:content-['/'] after:mx-[0.5rem]";

enum RoleFolders {
  Person = "person",
  Company = "company",
}

export type PickerSelectionComponent = {
  displayName: string;
  name: string;
  type: "layout" | "path" | "role";
};

export type PickerSelection = {
  layout: GqlMatterLayout | undefined;
  prefix: string[];
  fields: MatterField[];
  components: PickerSelectionComponent[];
};

export type PickerProps = {
  loading: boolean;
  fixedSelection?: PickerSelection;
  selection: PickerSelection;
  setSelection: (selection: PickerSelection) => void;
  matterTypes: GqlMatterType[];
  layouts: GqlMatterLayout[];
  fields: MatterField[];
  existingMappedFields: string[];
  multiSelect?: boolean;
  isLoadingMatterFields: boolean | undefined;
  isInGroup?: boolean;
};

export const Picker = ({
  loading,
  fixedSelection,
  selection,
  setSelection,
  matterTypes,
  layouts,
  fields,
  existingMappedFields,
  multiSelect = true,
  isLoadingMatterFields,
  isInGroup,
}: PickerProps) => {
  const roleComponentIndex = selection.components.findIndex(
    (c) => c.type === "role",
  );
  const isRoleComponentSelected = roleComponentIndex !== -1;
  const isRoleProviderLayout = selection.layout?.providerId === roleProviderId;
  const isRoleFolder = isRoleProviderLayout || isRoleComponentSelected;

  // If it is a role provider layout, the top folder is a Role folder.
  const isChildOfRoleFolder = (componentIndex: number) =>
    isRoleComponentSelected
      ? roleComponentIndex <= componentIndex
      : isRoleProviderLayout;

  const isInPersonFolder = (name: string) =>
    name.startsWith(RoleFolders.Person);
  const isInCompanyFolder = (name: string) =>
    name.startsWith(RoleFolders.Company);

  const shouldFolderBeDisabled = (folder: { name: string }): boolean => {
    /**
     * Only allow select either Company and Person fields if it's in a group or selecting a Role field's field.
     *
     * The following data should be checked if a role field has been selected:
     * 1. "selection": for current selection.
     * 2. "fixedSelection": for existing selected fields in the group.
     * 3. "existingMappedFields": for existing mapped fields in a "LayoutContact" group.
     */

    // Check if a Role component selected separately,
    // because a "layoutContact" group will be created automatically, if not exists.
    if (isRoleComponentSelected) {
      const isPersonMappedFieldsSelected =
        selection.fields.some((f) => isInPersonFolder(f.name)) ||
        existingMappedFields.some((name) => isInPersonFolder(name));
      const isCompanyMappedFieldsSelected =
        selection.fields.some((f) => isInCompanyFolder(f.name)) ||
        existingMappedFields.some((name) => isInCompanyFolder(name));

      return (
        (isInPersonFolder(folder.name) && isCompanyMappedFieldsSelected) ||
        (isInCompanyFolder(folder.name) && isPersonMappedFieldsSelected)
      );
    }

    // Check for normal group of role provider fields
    if (isRoleProviderLayout && isInGroup) {
      const isPersonMappedFieldsSelected =
        selection.fields.some((f) => isInPersonFolder(f.name)) ||
        !!fixedSelection?.prefix.includes(RoleFolders.Person);
      const isCompanyMappedFieldsSelected =
        selection.fields.some((f) => isInCompanyFolder(f.name)) ||
        !!fixedSelection?.prefix.includes(RoleFolders.Company);

      return (
        (isInPersonFolder(folder.name) && isCompanyMappedFieldsSelected) ||
        (isInCompanyFolder(folder.name) && isPersonMappedFieldsSelected)
      );
    }

    return existingMappedFields.length === 0 ? false : !!fixedSelection;
  };

  const { folders, checkboxes, crumbs } = useMemo(() => {
    if (!selection.layout) return { folders: [], checkboxes: [] };

    const sharedDetail = fields[0]?.details?.split("/") || [];
    while (
      sharedDetail?.length &&
      !fields.every((f) => f.details?.startsWith(sharedDetail.join("/")))
    ) {
      sharedDetail.pop();
    }

    let paths: string[] = [];
    selection.components.forEach((c) => {
      if (c.type === "path") {
        paths.push(c.name);
      } else {
        paths = [];
      }
    });

    // Remove shared details which are already in the fixed selection components
    const fixedComponents = fixedSelection
      ? [...fixedSelection.components]
      : [];
    const fixedPathComponents =
      fixedComponents.filter((c) => c.type === "path") || [];
    while (
      fixedPathComponents.length &&
      fixedPathComponents[0].name === sharedDetail[0]
    ) {
      sharedDetail.shift();
      fixedPathComponents.shift();
    }

    const fullPrefix = [...sharedDetail, ...paths].filter(Boolean).join("/");
    const availableFields = fields.filter(
      (f) =>
        f.details?.startsWith(fullPrefix) && !hideField(selection?.layout, f),
    );
    const [inFields, inFolders] = partition(
      availableFields,
      (f) => f.details === fullPrefix,
    );
    const [roleFields, regularFields] = partition(
      inFields,
      (f) => f.type === "Role",
    );

    const checkIfFolderShareDetailsWithRoleFields = (folder: MatterField) =>
      roleFields.some((r) => r.details && folder.details?.startsWith(r.name));

    /**
     * Filter out fields which share same details with role fields.
     * Those fields will be combined with Role's fields later.
     */
    const regularInFolders = inFolders.filter(
      (f) => !checkIfFolderShareDetailsWithRoleFields(f),
    );

    const folderPaths = unique(
      regularInFolders
        .map(
          (f) =>
            f.details?.replace(new RegExp(`^${fullPrefix}/`), "").split("/")[0],
        )
        .filter(isNotNullOrUndefined),
    );
    const folderComponents = folderPaths.map((path) => ({
      name: path,
      displayName: path,
      type: "path",
    }));
    const roleFieldComponents = (isRoleComponentSelected ? [] : roleFields).map(
      (r) => ({
        name: r.name,
        displayName: r.displayName || r.name,
        type: "role",
      }),
    );

    const visibleComponents = [...selection.components].filter(
      (component, index) =>
        component.type !== "path" ||
        component.type !== fixedComponents[index]?.type ||
        component.name !== fixedComponents[index]?.name ||
        index === fixedComponents.length - 1,
    );

    return {
      folders: [...folderComponents, ...roleFieldComponents],
      checkboxes: regularFields,
      crumbs: visibleComponents,
    };
  }, [
    fields,
    matterTypes,
    selection.layout,
    selection.components,
    fixedSelection?.components,
  ]);

  return (
    <div className="rounded bg-white flex-1">
      {loading && <ProgressBar overlay />}
      <div
        className="px-[2.4rem] py-[1.1rem] text-paragraph text-neutral-850 border-b border-neutral-100"
        data-testid="picker-crumbs"
      >
        <button
          className={crumbClassNames}
          type="button"
          disabled={!!fixedSelection}
          onClick={() =>
            setSelection({
              layout: undefined,
              prefix: [],
              fields: [],
              components: [],
            })
          }
        >
          Mapped fields
        </button>
        {crumbs &&
          crumbs.map((component, componentIndex) => (
            <button
              className={clsx(crumbClassNames, "last:after:hidden")}
              type="button"
              // eslint-disable-next-line react/no-array-index-key
              key={`${component.type}-${componentIndex}`}
              disabled={
                (!!fixedSelection && !isChildOfRoleFolder(componentIndex)) ||
                componentIndex === crumbs.length - 1
              }
              onClick={() => {
                if (componentIndex !== crumbs.length - 1) {
                  setSelection({
                    layout: selection.layout,
                    prefix: selection.prefix.slice(0, componentIndex),
                    fields:
                      isChildOfRoleFolder(componentIndex) && isInGroup
                        ? selection.fields
                        : [],
                    components: crumbs.slice(0, componentIndex + 1),
                  });
                }
              }}
            >
              {toSentenceCase(component.displayName)}
            </button>
          ))}
      </div>
      <div className="px-[2.4rem] pt-[1.6rem] pb-[2.4rem]">
        <div className="mb-[1.6rem]">
          Select which fields you want to include to map back to the matter in
          Smokeball
        </div>
        <div className="flex flex-col gap-small" data-testid="picker-fields">
          {selection.components.length ? (
            <>
              {folders.map((folder, folderIndex) => (
                <PickerItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${folder.type}-${folderIndex}`}
                  text={toSentenceCase(folder.displayName)}
                  icon="solidFolder"
                  iconColor="text-yellow-450"
                  disabled={shouldFolderBeDisabled(folder)}
                  onClick={() =>
                    setSelection({
                      layout: selection.layout,
                      prefix: [...selection.prefix, folder.name],
                      fields: isRoleFolder ? selection.fields : [],
                      components: [
                        ...selection.components,
                        {
                          displayName: folder.displayName,
                          name: folder.name,
                          type: folder.type === "role" ? "role" : "path",
                        },
                      ],
                    })
                  }
                />
              ))}
              {checkboxes.map((field) => {
                const existing = existingMappedFields.includes(field.name);
                return (
                  <PickerItem
                    key={field.name}
                    text={toSentenceCase(field.displayName || "")}
                    icon={
                      fieldComponentIcons[mapFieldType(field) || "text"].name
                    }
                    iconBgColor={
                      fieldComponentIcons[mapFieldType(field) || "text"].bgColor
                    }
                    checked={
                      selection.fields
                        .map((f) => f.name)
                        .includes(field.name) || existing
                    }
                    disabled={existing}
                    onClick={() => {
                      const updatedSelection = multiSelect
                        ? {
                            ...selection,
                            fields: selection.fields.includes(field)
                              ? selection.fields.filter((f) => f !== field)
                              : [...selection.fields, field],
                          }
                        : {
                            ...selection,
                            fields: [field],
                          };
                      setSelection(updatedSelection);
                    }}
                  />
                );
              })}
              {!fields.length &&
                !loading &&
                isLoadingMatterFields === false && (
                  <p className="mt-[5rem] text-center">
                    No mapped fields available for &quot;
                    {selection.layout?.displayName || "this selection"}&quot;.
                  </p>
                )}
            </>
          ) : (
            layouts.map((layout) => (
              <PickerItem
                key={[layout.parentId, layout.id].join("/")}
                text={layout.displayName || layout.name}
                disabled={!!fixedSelection}
                icon="solidFolder"
                iconColor="text-yellow-450"
                onClick={() =>
                  setSelection({
                    layout,
                    prefix: [],
                    fields: [],
                    components: [
                      ...selection.components,
                      {
                        displayName: layout.displayName || layout.name,
                        name: layout.name,
                        type: "layout",
                      },
                    ],
                  })
                }
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
