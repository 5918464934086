import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { FormNS, globalTeamUri } from "@smart/bridge-types-basic";
import { extractId } from "@smart/itops-types-basic";
import { FieldGroup, Modal, TextInput } from "@smart/itops-ui-dom";
import { useCopyForm, useUpdateForm } from "@smart/manage-gql-client-dom";

import { FormInfo } from "../edit-form-v2/types";
import { GqlForm } from "../types";

export type DuplicatingFormModalProps = {
  duplicatingForm: GqlForm | undefined | null;
  forms: FormInfo[];
  onClose: () => void;
  header?: string;
  description?: string;
};

export const DuplicatingFormModal = ({
  duplicatingForm,
  forms,
  onClose,
  header,
  description,
}: DuplicatingFormModalProps) => {
  const navigate = useNavigate();
  const [copyForm] = useCopyForm();
  const [updateForm] = useUpdateForm();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { formName: "" },
    resolver: zodResolver(
      z.object({
        formName: z
          .string()
          .min(1, "Please enter a form name")
          .refine(
            (value) => {
              const found = forms
                .filter((f) => f.uri === globalTeamUri)
                .find((f) => value === f.formTitle);
              return !found;
            },
            {
              message: "A form with this name already exists",
            },
          ),
      }),
    ),
  });

  const onSubmit = handleSubmit(async (values) => {
    if (!duplicatingForm) return;
    const newFormUri = FormNS.generateUri();

    await updateForm({
      variables: {
        uri: newFormUri,
        fields: {
          source: "duplicate",
          title: values.formName,
          creationStatus: "duplicating",
          category: duplicatingForm.formCategory,
          response: duplicatingForm.rawForm.values.response,
        },
      },
    }).catch(console.error);

    navigate(`/embedded/builder/${extractId(newFormUri)}`);

    await copyForm({
      variables: {
        uri: duplicatingForm.uri,
        newUri: newFormUri,
        newTitle: values.formName,
      },
    }).catch(console.error);
  });

  useEffect(() => {
    if (duplicatingForm?.formTitle)
      reset({ formName: `${duplicatingForm.formTitle} - Duplicate` });
  }, [duplicatingForm]);

  return (
    <form onSubmit={onSubmit}>
      <Modal
        onClose={onClose}
        open={!!duplicatingForm}
        loading={isSubmitting}
        header={{
          icon: { library: "lucide", name: "Copy", variant: "action" },
          text:
            header ||
            `Duplicate ${duplicatingForm && duplicatingForm.formCategory} form?`,
        }}
        footer={{
          left: [
            {
              text: "Cancel",
              variant: "cancel",
              onClick: onClose,
            },
          ],
          right: [
            {
              text: "Duplicate form",
              type: "submit",
              variant: "save",
            },
          ],
        }}
      >
        <p>
          {description ||
            `To duplicate "${duplicatingForm?.formTitle}", please enter a name for your new form.`}
        </p>
        <FieldGroup
          id="formName"
          label="Form name"
          hint="Enter a unique name for your new form"
          size="emphasis"
          error={errors.formName?.message}
          required
        >
          <TextInput
            id="formName"
            error={!!errors.formName}
            {...register("formName", { required: true })}
          />
        </FieldGroup>
      </Modal>
    </form>
  );
};
