import { convertToHtml, convertToText } from "@smart/itops-serialisation-basic";

import { defaultTemplate, MessageTemplateOptions } from "./types";
import { appendFormLink } from "./utils";

export const message = ({
  template,
  client,
  category,
  matterType,
  team,
  link,
  form,
  submissionCreatedAt,
  shouldAppendFormLink = true,
}: MessageTemplateOptions) => {
  const templateToUse = template || defaultTemplate[category];
  const clientName =
    client?.person?.firstName ||
    client?.company?.name ||
    client?.contactName ||
    "";
  const clientEmail = client?.person?.email || client?.company?.email || "";
  const firmName = team?.name || "our team";
  const firmPhone =
    typeof team?.phone === "string"
      ? team?.phone
      : `${team?.phone?.areaCode}${team?.phone?.number}`;

  const convertOptions = {
    template: shouldAppendFormLink
      ? appendFormLink(templateToUse)
      : templateToUse,
    data: {
      clientName,
      clientEmail,
      matterCategory: category,
      matterType: matterType.name,
      firmName,
      firmPhone: firmPhone || "",
      formLink: link,
      formCreatedAt: submissionCreatedAt || "",
      formTitle: form.title,
    },
  };

  return {
    link,
    html: convertToHtml(convertOptions),
    text: convertToText(convertOptions),
  };
};

export const getTemplate = (
  defaultClientMessage:
    | {
        matter?: string | null;
        lead?: string | null;
        reminder?: string | null;
      }
    | null
    | undefined,
  category: "matter" | "lead" | "reminder",
) => defaultClientMessage?.[category]?.trim() || undefined;
