import styled from "@emotion/styled";
import { useFloating } from "@floating-ui/react";
import { MouseEventHandler, useState } from "react";
import { Editor } from "slate";
import { useSlate } from "slate-react";

import { Icon } from "@smart/itops-icons-dom";
import { EditorElement } from "@smart/itops-serialisation-basic";

import { EditLink } from "./edit-link";
import { insertLink } from "./link-actions";

const LinkButtonWrapper = styled.span<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  display: block;
  padding: 0.5rem 0.4rem;
  margin: 0 0.5rem;
`;

const EditLinkWrapper = styled.div<{
  position: string;
  top: number;
  left: number;
}>`
  max-width: 30rem;
  z-index: 1;
  position: ${(props) => props.position};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const LinkButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const editor = useSlate();
  const { x, y, refs, strategy } = useFloating();

  const checkShouldDisable = () => {
    const { selection } = editor;
    if (!selection) return false;

    const [parentElement] = Editor.parent(editor, selection.focus.path);
    return (parentElement as EditorElement).type === "link";
  };
  const isDisabled = checkShouldDisable();

  const onMouseDown: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    if (isDisabled) return;

    const domSelection = window.getSelection();
    if (!domSelection) return;

    const rect = domSelection.getRangeAt(0).getBoundingClientRect();
    setIsOpen(true);
    refs.setReference({
      getBoundingClientRect: () => rect,
    });
  };

  return (
    <>
      <LinkButtonWrapper disabled={isDisabled} onMouseDown={onMouseDown}>
        <Icon name="link" size={14} />
      </LinkButtonWrapper>
      {isOpen && (
        <EditLinkWrapper
          ref={refs.setFloating}
          position={strategy}
          top={y ?? 0}
          left={x ?? 0}
        >
          <EditLink
            displayText={
              editor.selection
                ? Editor.string(editor, editor.selection)
                : undefined
            }
            close={() => setIsOpen(false)}
            save={(text, url) => insertLink(editor, text, url)}
          />
        </EditLinkWrapper>
      )}
    </>
  );
};

export { LinkButton };
