import styled from "@emotion/styled";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  DraggableItemProps,
  Icon,
  IconButton,
  Title,
} from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";

import { GqlSection } from "./types";

type SectionItemProps = {
  item: GqlSection;
  right?: JSX.Element;
};

const SectionItem = ({ item, right }: SectionItemProps) => {
  const navigate = useNavigate();
  const select = useCallback(
    () => navigate(`?section=${extractId(item.uri)}`, { replace: true }),
    [item],
  );

  return (
    <Title
      left={<IconButton name="angleRight" onClick={select} />}
      onClick={select}
      text={item.values.title}
      size={0.6}
      right={right}
    />
  );
};

const Wrapper = styled.div`
  .handle {
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type DraggableSectionItemProps = DraggableItemProps<GqlSection>;

const DraggableSectionItem = ({
  item,
  draggableProvided,
}: DraggableSectionItemProps) => (
  <Wrapper
    ref={draggableProvided?.innerRef}
    {...draggableProvided?.draggableProps}
  >
    <SectionItem
      item={item}
      right={
        <div {...draggableProvided?.dragHandleProps} className="handle">
          <Icon name="handle" />
        </div>
      }
    />
  </Wrapper>
);

export { DraggableSectionItem, DraggableSectionItemProps, SectionItem };
