import {
  GqlStatusOverlay,
  GqlStatusOverlayProps,
  LinkDisplay,
} from "@smart/itops-components-dom";
import { submissionLink, useLoadSections } from "@smart/manage-gql-client-dom";

import { SubmissionSection } from "./section";
import { GqlSubmission } from "./types";

type SubmissionViewProps = {
  submission: GqlSubmission | null | undefined;
  result: GqlStatusOverlayProps;
};

const SubmissionView = ({ submission, result }: SubmissionViewProps) => {
  const sections = useLoadSections({ formUri: submission?.form.uri || "" });

  return (
    <>
      {submission?.form.slug?.values.active && (
        <LinkDisplay
          instruction="Update This Submission:"
          link={submissionLink(submission)}
        />
      )}
      {submission &&
        sections.result?.map((section) => (
          <SubmissionSection
            key={section.uri}
            section={section}
            submission={submission}
          />
        ))}
      <GqlStatusOverlay {...result} />
    </>
  );
};

export { SubmissionView, SubmissionViewProps };
