import { fromZonedTime } from "date-fns-tz";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  familyProQuestionnaireFormUri,
  FormNS,
  questionnaireTypeToFamilyProFormUriMap,
  SubmissionNS,
  SubmissionStatus,
} from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";
import { useAsync, useFragment } from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { extractId } from "@smart/itops-types-basic";
import { removeKeys } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLoadMatterQuestionnaireStatus,
  useLoadMatterSubmissions,
  useLoadMatterType,
  useQuerySubmission,
  useLoadFamilyProAuthorizationState,
  useUpdateForm,
  useAddFormMatterType,
  useLoadMatterSpecificForms,
  useGenerateForm,
} from "@smart/manage-gql-client-dom";
import {
  familyProFormTitles,
  useAvailabilities,
  useAvailableFormCollections,
  useRoleContacts,
} from "@smart/manage-hooks-dom";

import { TabListContainer } from "./list-container";
import { MatterSpecificForm } from "./matter-specific-forms-modal";
import { Banner } from "../../shared";

type QuestionnaireStatus = ReturnType<
  typeof useLoadMatterQuestionnaireStatus
>["result"];

type QuestionnaireStatusValues = NonNullable<QuestionnaireStatus>["status"];

export type TabListProps = {
  onView: React.Dispatch<React.SetStateAction<MatterSubmission | undefined>>;
  onViewForm: (form: MatterSpecificForm) => void;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
  onShare: (share: "regular" | "internal" | undefined) => void;
  onGenerateQRCode: (submission: MatterSubmission) => void;
};

export const TabList = ({
  onView,
  onViewForm,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
  onShare,
  onGenerateQRCode,
}: TabListProps) => {
  const { matters } = useSmokeballApp();
  const { client } = useRoleContacts();
  const authorizationState = useLoadFamilyProAuthorizationState({});
  const { availableForFamilyPro } = useAvailabilities({
    matter: matters?.current,
    isFamilyProAuthorized: authorizationState.result?.isAuthorized ?? false,
  });
  const {
    loading: collectionsLoading,
    collections,
    refetch: refetchFormCollections,
  } = useAvailableFormCollections({ reloadOnVisible: true });
  const matterSpecificForms = useLoadMatterSpecificForms({
    matterId: matters?.current?.id || "",
  });

  const submissions = useLoadMatterSubmissions(
    { matterId: matters?.current?.id || "" },
    { reloadOnVisible: true },
  );
  const questionnairePartyA = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "a",
    },
    { reloadOnVisible: true, skip: !availableForFamilyPro },
  );
  const questionnairePartyB = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "b",
    },
    { reloadOnVisible: true, skip: !availableForFamilyPro },
  );

  const matterType = useLoadMatterType({
    matterTypeId: matters?.current?.matterTypeId || "",
  });

  const navigate = useNavigate();
  const [generateForm] = useGenerateForm();
  const [updateForm] = useUpdateForm();
  const [addFormMatterType] = useAddFormMatterType();

  const buildQuestionnaireSubmissions = (
    partyA: QuestionnaireStatus,
    partyB: QuestionnaireStatus,
  ) => {
    const buildQuestionnaireSubmission = (
      status: QuestionnaireStatus,
      party: "A" | "B",
    ) => {
      if (!status || (party === "B" && !status.link)) return undefined;

      const questionnaireStatusMap: Record<
        QuestionnaireStatusValues,
        SubmissionStatus
      > = {
        notsent: "draft",
        sent: "active",
        submitted: "completed",
      };

      return {
        uri: `${matters?.current?.id}-questionnaire-party-${party}`,
        status: questionnaireStatusMap[status.status] || "draft",
        formTitle: status.questionnaireType
          ? familyProFormTitles[
              questionnaireTypeToFamilyProFormUriMap[status.questionnaireType]
            ]
          : familyProFormTitles["family-pro-intake-enquiry"],
        formUri: familyProQuestionnaireFormUri,
        label: "",
        responses: [],
        questions: 0,
        createdAt: status.sent ? fromZonedTime(status.sent, "UTC") : "",
        updatedAt: status.updated ? fromZonedTime(status.updated, "UTC") : "",
        formFields: [],
        syncStatus: "synced",
        recipient: {
          __typename: "Recipient",
          contactName: `${client?.person?.firstName} ${client?.person?.lastName}`,
          email: client?.person?.email,
          contactId: client?.id,
        },
        trackers: [],
        rawSubmission: {
          form: {
            values: {
              matterTypes: [matterType.data?.matterType],
            },
          },
          values: {
            communicationMethod: status.sent ? undefined : "internalUse",
          },
        } as unknown as MatterSubmission["rawSubmission"],
        sentAt: status.sent || "",
        externalSubmissionLink: status.link,
        externalSubmissionEmbedUrl: status.embedUrl,
        externalSubmissionType: status.questionnaireType,
      } as MatterSubmission;
    };

    return [
      buildQuestionnaireSubmission(partyA, "A"),
      buildQuestionnaireSubmission(partyB, "B"),
    ].filter(Boolean) as MatterSubmission[];
  };

  const querySubmission = useQuerySubmission();
  const [{ intakeFormUri }, resetFragment] = useFragment(["intakeFormUri"]);
  useAsync(async () => {
    if (intakeFormUri) {
      const submission = await querySubmission({
        uri: SubmissionNS.generateUri(intakeFormUri),
      });
      if (submission)
        navigate(`/embedded/tab/${extractId(submission.uri)}?tab=view`);

      resetFragment();
    }
  }, [intakeFormUri]);

  useEffect(() => {
    onView((selected) => {
      if (selected) {
        const found = submissions.result?.find((s) => s.uri === selected.uri);
        if (found) return found;
      }

      return selected;
    });
  }, [submissions.result]);

  if (
    (matters?.loading && !matters.current) ||
    collectionsLoading ||
    matterType.loading ||
    authorizationState.loading ||
    (submissions.loading && !submissions.result) ||
    (questionnairePartyA.loading && !questionnairePartyA.result) ||
    (questionnairePartyB.loading && !questionnairePartyB.result)
  )
    return <Loading />;

  const submissionsToDisplay = availableForFamilyPro
    ? [
        ...(submissions.result || []),
        ...buildQuestionnaireSubmissions(
          questionnairePartyA.result,
          questionnairePartyB.result,
        ),
      ]
    : submissions.result || [];

  return (
    <>
      <Banner flagKey="tabBannerInfo" />
      <TabListContainer
        submissions={submissionsToDisplay}
        matterSpecificForms={matterSpecificForms.result}
        hasForms={collections.some((col) => col.options.length > 0)}
        loading={submissions.loading || matterSpecificForms.loading}
        onView={onView}
        onViewForm={onViewForm}
        onCancel={onCancel}
        onDelete={onDelete}
        onRemind={onRemind}
        onEdit={onEdit}
        onShare={onShare}
        onRefetch={() => {
          submissions.refetch().catch(console.error);
          matterSpecificForms.refetch().catch(console.error);
          questionnairePartyA.refetch().catch(console.error);
          questionnairePartyB.refetch().catch(console.error);
          refetchFormCollections().catch(console.error);
        }}
        onGenerateQRCode={onGenerateQRCode}
        onCreateMatterSpecificForm={async ({ filePath, fileName, fileId }) => {
          if (!matters?.current || !matterType.result) return;

          const formUri = FormNS.generateUri();
          const formsWithSameFileName = matterSpecificForms.result?.filter(
            (f) => f.title.toLowerCase().includes(fileName.toLowerCase()),
          );
          const formTitle = `${fileName}${formsWithSameFileName?.length ? formsWithSameFileName.length + 1 : ""}`;
          await updateForm({
            variables: {
              uri: formUri,
              fields: {
                title: formTitle,
                category: matters.current.isLead ? "lead" : "matter",
                source: "aiTemplate",
                creationStatus: "fileUploaded",
                matterId: matters.current.id,
                response: "",
              },
            },
          });
          await addFormMatterType({
            variables: {
              formUri,
              matterType: removeKeys(matterType.result, ["__typename"]),
            },
          });

          await generateForm({
            variables: {
              formUri,
              fields: {
                title: formTitle,
                matterTypeIds: [matterType.result.id],
                filePath,
                sourceFileId: fileId,
              },
            },
          });
          navigate(`/embedded/builder/${extractId(formUri)}`);
        }}
      />
    </>
  );
};
