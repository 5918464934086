import { envKeys, smokeballApiKeys } from "@smart/bridge-env-types-basic";
import { buildApiGatewayDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

const lambdaDefs = {
  cachedGql: {
    pkg: "@smart/manage-cached-gql-lambda",
    deployable: "cachedGql",
    method: "GET",
    authenticated: false,
    env: ["CdnPublicKey", "SmokeballDefaultAccountId", ...smokeballApiKeys],
  },
} as const;

export const cachedGateway = buildApiGatewayDef({
  envKeys,
  cdn,
  namespace: buildNamespace({ system, entity: "cached" }),
  prefix: "cached",
  authorized: false,
  authorizer: undefined,
  lambdaKeys: keysOf(lambdaDefs),
  lambdas: lambdaDefs,
  port: 9112,
});
