import { deleteButtonFactory } from "@smart/itops-components-dom";
import { useDeleteGroup } from "@smart/manage-gql-client-dom/src/hooks/groups";

const DeleteGroup = deleteButtonFactory({
  icon: "cancel",
  confirmText: "Are you sure?",
  mutation: useDeleteGroup,
});

export { DeleteGroup };
