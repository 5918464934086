import clsx from "clsx";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { IntakeForm, intakeTheme } from "@smart/bridge-intake-components-dom";
import {
  appendDownloadFormLink,
  defaultTemplate,
} from "@smart/bridge-templates-basic";
import { ThemeProvider } from "@smart/itops-components-dom";
import { ProgressCircle } from "@smart/itops-sb-design-system-dom";
import { useLoadStaffDetails } from "@smart/manage-gql-client-dom";
import {
  FormDisplayProps,
  registerCapturedEvent,
} from "@smart/manage-hooks-dom";

import { externalFormUris } from "../../types";

export type PreviewContainerProps = {
  loading: boolean;
  preview: FormDisplayProps;
  form?: { uri: string };
  submission: { uri: string; responses: Record<string, { value: any }> };
  className?: string;
};

const EmptyPreview = ({ children }: { children: ReactNode }) => (
  <div className="flex justify-center h-full items-center text-ui text-neutral-400">
    {children}
  </div>
);
const PreviewWrapper = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <>
    <div className="w-full mb-2 text-ui font-semibold">Preview</div>
    <div
      data-testid="preview-wrapper"
      className={twMerge(
        clsx(
          className,
          "relative w-full h-full bg-white border border-neutral-100 rounded",
        ),
      )}
    >
      {children}
    </div>
  </>
);
export const PreviewContainer = ({
  loading,
  preview,
  form,
  submission,
  className,
}: PreviewContainerProps) => {
  const staffDetails = useLoadStaffDetails({
    staffIds: [
      ...new Set(
        preview.fields.flatMap((field) => field.availableStaffIds ?? []),
      ),
    ],
  });
  const { aiWordSupport } = useFeatureFlags();

  if (!form)
    return (
      <PreviewWrapper className={className}>
        <EmptyPreview>No Form Selected</EmptyPreview>
      </PreviewWrapper>
    );

  if (externalFormUris.includes(form.uri))
    return (
      <PreviewWrapper className={className}>
        <EmptyPreview>No preview available</EmptyPreview>
      </PreviewWrapper>
    );

  if (loading || preview.loading || staffDetails.loading)
    return (
      <PreviewWrapper className={className}>
        <ProgressCircle description="Loading..." />
      </PreviewWrapper>
    );

  return (
    <PreviewWrapper
      className={className}
      {...registerCapturedEvent({
        form,
        submission,
        eventDataType: "scroll",
        eventType: "scroll",
        activityType: "send-submission",
      })}
    >
      {preview.exists && !!preview.sections.length && (
        <ThemeProvider theme={intakeTheme}>
          <IntakeForm
            disabled
            isPreview
            {...preview}
            form={{
              ...preview.form,
              response: appendDownloadFormLink(
                preview.form.response || defaultTemplate.response,
              ),
            }}
            submission={submission}
            loadResponses={async () => undefined}
            staffDetails={staffDetails.result || []}
            aiWordSupport={aiWordSupport}
          />
        </ThemeProvider>
      )}
    </PreviewWrapper>
  );
};
