import { useEffect, useRef } from "react";

import { DragNDropList } from "@smart/itops-components-dom";
import { useReorder } from "@smart/itops-hooks-dom";
import { sortField } from "@smart/itops-utils-basic";
import {
  useUpdateFieldOrder,
  useUpdateGroupOrder,
} from "@smart/manage-gql-client-dom";

import { CreateItem } from "./create-item";
import { GroupItem } from "./group-item";
import { DraggableFieldItem, FieldItem } from "./item";
import {
  FormField,
  GqlField,
  GqlGroup,
  GqlGroupWithFields,
  GroupFormField,
} from "./types";
import { FormDisplayMode } from "../forms/types";

const buildFormFieldItems = (
  fields: GqlField[],
  groups: GqlGroup[],
): FormField[] => {
  const formFields: (FormField & { order: string })[] = [];

  fields.forEach((field) => {
    if (!field.values.groupUri) {
      formFields.push({
        uri: field.uri,
        type: "single",
        key: `${field.uri}-${field.values.order}`,
        field,
        order: field.values.order,
        position: 0,
      });
    } else {
      const existingGroupFormField = formFields.find(
        (ff) => ff.uri === field.values.groupUri && ff.type === "group",
      );
      if (!existingGroupFormField) {
        const group = groups.find((g) => g.uri === field.values.groupUri);
        if (group) {
          formFields.push({
            uri: group.uri,
            type: "group",
            key: `${group.uri}-${group.values.order}`,
            group,
            order: group.values.order,
            position: 0,
          });
        }
      }
    }
  });

  return sortField(formFields, {
    key: "order",
    dir: "asc",
  }).map((f, index) => ({ ...f, position: index }));
};

type FieldListProps = {
  formUri: string;
  sectionUri: string;
  fields: GqlField[];
  groups: GqlGroupWithFields[];
  mode: FormDisplayMode;
};

const FieldList = ({
  formUri,
  sectionUri,
  fields,
  groups,
  mode,
}: FieldListProps) => {
  const { onMove, onGroupMove: onGroupFieldsMove } = useReorder({
    mutation: () => useUpdateFieldOrder(false),
    extraFields: { formUri, sectionUri },
  });
  const { onMove: onGroupMove } = useReorder({
    mutation: () => useUpdateGroupOrder(false),
    extraFields: { formUri, sectionUri },
  });

  const prevFieldCount = useRef<number>();
  useEffect(() => {
    const fieldCount = Object.keys(fields).length;
    // Scroll to bottom when new fields are added
    if (prevFieldCount.current && fieldCount > prevFieldCount.current) {
      window.scrollTo({ top: document.body.offsetHeight, behavior: "smooth" });
    }
    prevFieldCount.current = fieldCount;
  }, [fields]);

  const formFieldItems = buildFormFieldItems(fields, groups);

  const findOrderIndex = ({
    item,
    index,
    items,
  }: {
    item: FormField;
    index: number;
    items: FormField[];
  }): number => {
    const sourceIndex = items.findIndex((i) => i.key === item.key);
    const isMoveDown = sourceIndex < index;
    const destinationIndex = isMoveDown ? index - 1 : index;
    const destinationItem = items[destinationIndex];

    let fieldIndex = isMoveDown
      ? destinationItem.position + 1
      : destinationItem.position;
    if (destinationItem.type === "group" && isMoveDown) {
      fieldIndex =
        destinationItem.position +
        ((items[destinationIndex] as GroupFormField).group.values.fields
          ?.length || 0);
    }

    return fieldIndex;
  };

  const handleMoveItem = ({
    item,
    index,
    items,
  }: {
    item: FormField;
    index: number;
    items: FormField[];
  }) => {
    const moveToPosition = findOrderIndex({ item, index, items });

    if (item.type === "single") {
      onMove({ item: item.field, index: moveToPosition, items: fields });
    } else if (item.type === "group") {
      const reorderedItems = (item.group.values.fields || []).map((field) => ({
        uri: field.uri,
        order: field.order,
        values: field,
      }));

      onGroupFieldsMove({
        items: fields,
        index: moveToPosition,
        reorderedItems,
      });
      onGroupMove({ item: item.group, index: moveToPosition, items: fields });
    }
  };

  const fieldList =
    mode === "view" ? (
      formFieldItems.map((item) =>
        item.type === "single" ? (
          <FieldItem key={item.field.uri} mode="view" item={item.field} />
        ) : (
          <GroupItem key={item.group.uri} item={item.group} mode="view" />
        ),
      )
    ) : (
      <DragNDropList
        id="draggable-field-list"
        items={formFieldItems}
        itemKey="key"
        render={DraggableFieldItem}
        onMove={handleMoveItem}
      />
    );

  return (
    <>
      {fieldList}
      {mode === "edit" && (
        <CreateItem
          formUri={formUri}
          sectionUri={sectionUri}
          after={fields[fields.length - 1]?.values.order}
        />
      )}
    </>
  );
};

export { FieldList, FieldListProps };
