import { ReactNode } from "react";

import { archie2 } from "@smart/bridge-images-dom";
import { Icon, Switch, Tooltip } from "@smart/itops-sb-design-system-dom";

type AllowAIFillToggleProps = {
  toggled: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
  right?: ReactNode;
  label: string;
  tooltip: string;
  disabled?: boolean;
};

export const AllowAIFillToggle = ({
  toggled,
  loading,
  onClick,
  right,
  label,
  tooltip,
  disabled,
}: AllowAIFillToggleProps) => (
  <div className="flex justify-between">
    <div className="flex items-center gap-[0.4rem]">
      <img className="w-[2.4rem]" src={archie2} alt="Archie" />
      <span className="text-ui">{label}</span>
      <Tooltip
        className="flex items-center"
        text={tooltip}
        placement="bottom"
        offsetBy={7}
      >
        <Icon name="regularCircleInfo" />
      </Tooltip>
      {right}
    </div>
    <Switch
      toggled={toggled}
      onClick={onClick}
      dataTestId="ai-fill-toggle"
      disabled={disabled || loading}
    />
  </div>
);
