import styled from "@emotion/styled";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Button } from "@smart/itops-ui-dom";

const GetArchie = styled(Button)`
  padding: 0.3rem 1.15rem;
  border: 1px solid ${(props) => props.theme.scheme.orange.r80};
  color: ${(props) => props.theme.scheme.orange.r80};
  border-radius: 0.4rem;
`;

export const GetArchieButton = () => {
  const { host } = useSmokeballApp();
  const { archieFeature } = useFeatureFlags();
  return (
    archieFeature === "available" && (
      <GetArchie
        text="Get Archie"
        variant="cancel"
        type="reset"
        onClick={() => host?.selectTab(17)}
      />
    )
  );
};
