import { FieldNS } from "@smart/bridge-types-basic";
import { CreateForm } from "@smart/itops-components-dom";
import { rankBetween } from "@smart/itops-utils-basic";

import { EditField } from "./edit";

type CreateFieldProps = {
  formUri: string;
  sectionUri: string;
  after?: string;
  onCreate?: () => void;
  onDone?: () => void;
};

const CreateField = ({
  formUri,
  sectionUri,
  after,
  onCreate,
  onDone,
}: CreateFieldProps) => (
  <CreateForm
    form={EditField}
    buildLocators={() => ({
      formUri,
      sectionUri,
      uri: FieldNS.generateUri(),
      order: rankBetween({ after }),
    })}
    defaultValues={{
      hint: "",
      label: "",
      type: "text",
      options: [],
      links: [],
    }}
    create="Add New Field"
    submit="Save"
    submitWithAction="Save and Add"
    afterSubmitActionType="create-more"
    cancel="Cancel"
    onCreate={onCreate}
    onDone={onDone}
  />
);

export { CreateField, CreateFieldProps };
