/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlStaffDetailsFieldsFragment = {
  __typename: "StaffDetails";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  busySlots: Array<{
    __typename: "StaffBusySlots";
    id: string;
    startTime: string;
    endTime: string;
    timezone?: string | null;
  }>;
};

export const StaffDetailsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "staffDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StaffDetails" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "middleName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "busySlots" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "staffBusySlotsFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "staffBusySlotsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StaffBusySlots" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlStaffDetailsFieldsFragment, unknown>;
