import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

import {
  AnalyticsTool,
  UserNS,
  extractPrefixedTeamId,
} from "@smart/bridge-types-basic";
import { Hint, Icon, Input } from "@smart/itops-components-dom";
import {
  FieldContentWrapper,
  FieldGroup as FieldGroupComponent,
  ItemTags,
  Label,
  Modal as SmokeballModal,
} from "@smart/itops-smokeball-components-dom";
import { buildDisplayName, removeKeys } from "@smart/itops-utils-basic";

import { AnalyticsConfig } from "./analytics-config";
import { GenerateForm, SubmissionLink, Staff } from "./types";

const Modal = styled(SmokeballModal)`
  .heading {
    padding: 0 0 1rem 0;

    h2 {
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: 600;
      margin: 0;
    }
  }
`;

const FieldGroup = styled(FieldGroupComponent)`
  min-height: 25rem;
`;

const FieldWrapper = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.background.highlight};
  border-radius: 2px;

  input {
    padding: 0.6rem;
  }
`;

const StaffSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorWrapper = styled.span<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  align-items: center;
  grid-column: 2;
  margin: 1rem 0 0 0.2rem;

  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.palette.danger.base};

  .exclamation-icon {
    margin-right: 0.5rem;
  }
`;

const FieldLabel = styled(Label)`
  min-width: 6rem;
  padding-top: 0.6rem;
`;

export type ChangeSettingsProps = {
  loading?: boolean;
  editingLink?: SubmissionLink;
  form?: GenerateForm;
  staff: Staff[];
  user?: Staff;
  onSave: (fields: {
    formUri: string;
    slugUri: string;
    notifiedUserUris: string[];
    analyticsTools?: AnalyticsTool[];
  }) => Promise<void>;
  onDone: () => void;
  editModal: [boolean, (v: boolean) => void];
};

export const ChangeSettings = ({
  loading,
  editingLink,
  form,
  user,
  staff,
  onSave,
  onDone,
  editModal,
}: ChangeSettingsProps) => {
  const [selectedStaff, setSelectedStaff] = useState<Staff[]>([]);
  const [analyticsTools, setAnalyticsTools] = useState<
    AnalyticsTool[] | undefined
  >(undefined);
  const [error, setError] = useState<string>("");
  const isSaving = useRef(false);

  useEffect(() => {
    // To prevent re-rendering existing data
    if (isSaving.current) return;

    if (editingLink?.notifiedUserUris?.length) {
      const existingNotifiedStaff = editingLink.notifiedUserUris
        .map((userUri) =>
          staff.find(
            (s) => s.userId === extractPrefixedTeamId(userUri, "sb").id,
          ),
        )
        .filter(Boolean);
      setSelectedStaff(existingNotifiedStaff as Staff[]);
    } else {
      setSelectedStaff(user ? [user] : []);
    }

    if (editingLink?.analyticsTools?.length) {
      setAnalyticsTools(
        editingLink.analyticsTools.map((tool) =>
          removeKeys(tool, ["__typename"]),
        ) as AnalyticsTool[],
      );
    }
  }, [user, editingLink]);

  useEffect(() => {
    setError("");
  }, [selectedStaff]);

  if (!editingLink || !form) return null;

  return (
    <Modal
      title=" "
      loading={loading}
      open={editModal}
      onReset={onDone}
      buttons={[
        {
          text: "Update",
          palette: "primary",
          onClick: async () => {
            if (selectedStaff.length === 0) {
              setError("Please select a staff");
            } else {
              isSaving.current = true;
              await onSave({
                formUri: editingLink.formUri,
                slugUri: form.slug?.uri || "",
                notifiedUserUris: selectedStaff.map((s) =>
                  UserNS.generateUri("sb", s.userId),
                ),
                analyticsTools,
              });
              isSaving.current = false;
              onDone();
            }
          },
          disabled: loading,
        },
        {
          text: "Cancel",
          onClick: () => {
            setError("");
            onDone();
          },
        },
      ]}
    >
      <div className="heading">
        <h2>Change Settings</h2>
      </div>
      <FieldGroup>
        <FieldLabel htmlFor="form" aria-disabled={loading}>
          Form
        </FieldLabel>
        <FieldContentWrapper>
          <FieldWrapper>
            <Input readOnly value={form.values.title} />
          </FieldWrapper>
        </FieldContentWrapper>
        <FieldLabel htmlFor="staff" aria-disabled={loading}>
          <span>Staff</span>{" "}
          <Hint placement="bottom-start" iconSize={16} maxWidth={200}>
            Selected staff will be notified when this form is completed.
          </Hint>
        </FieldLabel>
        <FieldContentWrapper className="staff-selection">
          <StaffSelectionWrapper>
            <ItemTags
              name="staff"
              options={staff}
              readOnly={loading}
              keys={(s) => s.userId}
              render={(s) => buildDisplayName(s, s.userId)}
              selected={selectedStaff}
              onToggle={(item) => {
                setSelectedStaff((current) => {
                  if (current.some((i) => i.userId === item.userId)) {
                    return current.filter((i) => i.userId !== item.userId);
                  }

                  return [...current, item];
                });
              }}
              onToggleAll={(show) => setSelectedStaff(show ? staff : [])}
              empty="No Staff Available"
            />
            <ErrorWrapper id="notified-staff-error" visible={!!error}>
              <Icon
                className="exclamation-icon"
                size={15}
                name="exclamationCircleFilled"
              />
              {error}
            </ErrorWrapper>
          </StaffSelectionWrapper>
        </FieldContentWrapper>
        <AnalyticsConfig
          config={analyticsTools}
          setConfig={setAnalyticsTools}
        />
      </FieldGroup>
    </Modal>
  );
};
