import { SDKError } from "@smart/itops-utils-basic";

import { AddRoleRequest, UpdateRoleRequest, UseSDKApiOptions } from "../types";

export const useRoles = ({ client, isInit }: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  return {
    get: async () => {
      try {
        if (!client?.context?.matterId) return { roles: [] };

        return await client.roles.get();
      } catch (error) {
        throw new SDKError(error, { action: "roles.get" });
      }
    },
    add: async (request: AddRoleRequest) => {
      try {
        return await client.roles.add(request);
      } catch (error) {
        throw new SDKError(error, { action: "roles.add", input: request });
      }
    },
    update: async (request: UpdateRoleRequest) => {
      try {
        return await client.roles.update(request);
      } catch (error) {
        throw new SDKError(error, { action: "roles.update", input: request });
      }
    },
  };
};
