import { ApolloCache } from "@apollo/client";

export const updateAfterDelete =
  <K extends string>(key: K, __typename: string) =>
  (
    cache: ApolloCache<any>,
    { data }: { data?: { [k in K]: { createdAt: string } } | null },
    { variables }: { variables?: { uri: string } },
  ) => {
    if (data?.[key] && variables) {
      const { uri } = variables;
      cache.modify({
        id: cache.identify({ __typename, uri }),
        fields: {
          values: (ex) => ({
            ...ex,
            deleted: true,
            updatedAt: data[key].createdAt,
          }),
        },
      });
    }
  };
