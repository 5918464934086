import { Icon } from "../icon";
import { Tooltip } from "../tooltip";

export const EditableTextError = ({ error }: { error: string }) => (
  <Tooltip text={error} placement="bottom-start" offsetBy={6}>
    <div
      className="flex items-center bg-red-150 text-red-550 p-[0.4rem] rounded-full w-[2.4rem] h-[2.4rem]"
      data-testid="editable-error"
    >
      <Icon name="regularCircleExclamation" />
    </div>
  </Tooltip>
);
