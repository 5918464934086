import { Empty, Page } from "@smart/itops-components-dom";

const Dashboard = () => (
  <Page text="Dashboard">
    <Empty>
      <p className="empty-text">Nothing Here. Yet.</p>
    </Empty>
  </Page>
);

export { Dashboard };
