import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type ProgressBarProps = {
  loadingText?: string;
  detail?: string;
  overlay?: boolean;
  className?: string;
};

const overlayStyles = "block overflow-hidden absolute bottom-0 left-0 right-0";

const OverlayWrapper = ({ children }: { children: ReactNode }) => (
  <span className={overlayStyles}>{children}</span>
);

export const ProgressBar = ({
  loadingText,
  detail,
  overlay,
  className,
}: ProgressBarProps) => {
  const progressStyles =
    "relative block h-2 w-full overflow-hidden bg-neutral-100 rounded-card";

  const baseComponent = (
    <div>
      {loadingText && <div className="mb-1 font-semibold">{loadingText}</div>}
      <span className={twMerge(progressStyles, className)}>
        <span className="absolute h-full animate-scrolling left-0 w-[15%] bg-cyan-540" />
      </span>
      {detail && <div className="mt-1">{detail}</div>}
    </div>
  );

  return overlay ? (
    <OverlayWrapper>{baseComponent}</OverlayWrapper>
  ) : (
    baseComponent
  );
};
