import { ApolloProvider } from "@apollo/client";
import { ReactNode, useEffect, useState } from "react";

import {
  buildCachedClient,
  buildClient,
  buildLink,
  CachedApolloProvider,
} from "@smart/manage-gql-client-dom";

import { useUser } from "./user";

export type ClientProviderProps = { children: ReactNode };

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const { refresh, token, idToken } = useUser();
  const [client] = useState(() => buildClient({ token, idToken, refresh }));
  useEffect(() => {
    client.setLink(buildLink({ token, idToken, refresh }));
  }, [token, refresh]);
  const [cachedClient] = useState(() => buildCachedClient());

  return (
    <ApolloProvider client={client}>
      <CachedApolloProvider value={cachedClient}>
        {children}
      </CachedApolloProvider>
    </ApolloProvider>
  );
};
