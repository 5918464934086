import { clsx } from "clsx";

import { alertBannerSectionPalettes } from "./class-names";
import { AlertProps } from "./types";
import { Button } from "../button";
import { Icon } from "../icon";
import { Link as LinkComponent } from "../link";

export const AlertSection = ({
  palette = "default",
  heading,
  content,
  elaboration,
  action,
  onClose,
}: AlertProps) => (
  <div
    className={clsx(
      alertBannerSectionPalettes[palette].background,
      "flex gap-small p-[1.6rem] rounded relative overflow-hidden",
    )}
  >
    <div
      className={clsx(
        alertBannerSectionPalettes[palette].borderColor,
        "w-full h-[0.4rem] absolute top-0 left-0 readable:w-[0.4rem] readable:h-full",
      )}
    />
    <div className={clsx(heading ? "pt-[0.4rem]" : "mt-[-0.2rem]")}>
      <span className="w-[2.4rem] h-[2.4rem] inline-flex justify-center items-center">
        <Icon
          className={alertBannerSectionPalettes[palette].iconColor}
          name={alertBannerSectionPalettes[palette].iconName}
        />
      </span>
    </div>
    <div>
      {heading && (
        <div className="flex gap-[0.8rem] items-center mb-[0.8rem]">
          <h2
            className={clsx(
              alertBannerSectionPalettes[palette].iconColor,
              "flex-1 text-paragraph font-semibold",
            )}
          >
            {heading}
          </h2>
          {onClose && (
            <Button
              variant="secondarySubtle"
              leftIcon="solidXmarkLarge"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <div>
        <div className="text-ui">{content}</div>
        {(elaboration || action) && (
          <div className="flex gap-[1.6rem] mt-[1.6rem]">
            {elaboration && (
              <LinkComponent
                variant="default"
                text={elaboration.text}
                onClick={elaboration.onClick}
              />
            )}
            {action && (
              <LinkComponent
                variant="subtle"
                text={action.text}
                onClick={action.onClick}
              />
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);
