import styled from "@emotion/styled";
import { SortingFn } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  familyProQuestionnaireFormUri,
  PaymentResponse,
  submissionExtendedLabel,
  SubmissionStatus,
  submissionStatusLabel,
  submissionStatusRank,
  TrackerActionType,
} from "@smart/bridge-types-basic";
import {
  Badge,
  BadgeProps,
  ContextMenu,
  Icon,
  Tooltip,
} from "@smart/itops-sb-design-system-dom";
import { CellProps } from "@smart/itops-smokeball-components-dom";
import {
  entriesOf,
  formatTime,
  isNotNullOrUndefined,
  jsonParseOrReturn,
  specialChars,
} from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import { MatterSpecificForm } from "./matter-specific-forms-modal";
import { ActionsMenu } from "../../shared";

const FormTitleWrapper = styled.span`
  display: flex;
  align-items: center;

  .exclamation {
    color: ${(props) => props.theme.palette.danger.accent};
    margin-left: ${(props) => props.theme.baseUnit * 0.6}rem;
  }

  .payment-failed {
    margin-left: 1rem;
  }
`;

export type TabRowValue =
  | { submission: MatterSubmission; form: undefined }
  | { form: MatterSpecificForm; submission: undefined };

export const FormTitle = ({ row }: CellProps<TabRowValue>) => {
  const { submission } = row.original;
  const hasFailedPayment = useMemo(() => {
    const failedPaymentFields = submission?.formFields
      .filter((f) => f.values.type === "payment")
      .map((f) => {
        const response = jsonParseOrReturn(
          submission?.responses.find((r) => r.fieldUri === f.uri)?.value,
        ) as PaymentResponse | undefined;

        return (response?.charges &&
          entriesOf(response.charges).find(
            ([, { status }]) => status === "FAILED",
          ))?.[1];
      })
      .filter(isNotNullOrUndefined);

    const defaultErrorMessage =
      "An unexpected error occurred during transaction processing";
    return failedPaymentFields?.length
      ? failedPaymentFields.find(
          (charge) => charge.smokeballResponse?.failure?.message,
        )?.smokeballResponse?.failure?.message || defaultErrorMessage
      : undefined;
  }, [submission?.responses]);

  return (
    <FormTitleWrapper>
      {submission?.formTitle || row.original.form?.title}
      {submission?.syncStatus === "pendingContactReview" && (
        <Icon
          className="h-4 w-4"
          name="solidTriangleExclamation"
          dataTestId="pending-contact-review"
        />
      )}
      {hasFailedPayment && (
        <Tooltip text={hasFailedPayment}>
          <Badge
            className="w-fit p-5"
            label="Payment failed"
            theme="destructive"
            dataTestId="view-failed-payments"
          />
        </Tooltip>
      )}
    </FormTitleWrapper>
  );
};

export const Created = ({ row }: CellProps<TabRowValue>) =>
  row.original.submission?.createdAt
    ? formatTime(row.original.submission.createdAt)
    : specialChars.enDash;

export const Modified = ({ row }: CellProps<TabRowValue>) => {
  if (row.original.form) return specialChars.enDash;

  const isOpened =
    row.original.submission?.rawSubmission.values?.communicationMethod ===
      "internalUse" ||
    !!row.original.submission?.trackers?.find(
      (t) => (t?.action as TrackerActionType) === "intake-form-opened",
    );
  const getContent = () => {
    if (row.original.submission?.formUri === familyProQuestionnaireFormUri) {
      return row.original.submission?.updatedAt
        ? formatTime(row.original.submission.updatedAt)
        : specialChars.enDash;
    }
    return isOpened
      ? formatTime(row.original.submission?.updatedAt)
      : "Not opened";
  };

  return <span data-testid="last-modified">{getContent()}</span>;
};

export const statusSortingFn: SortingFn<TabRowValue> = (rowA, rowB) => {
  const rankA =
    submissionStatusRank[
      rowA.original.submission ? rowA.original.submission.status : "form"
    ];
  const rankB =
    submissionStatusRank[
      rowB.original.submission ? rowB.original.submission.status : "form"
    ];
  if (rankA > rankB) return 1;
  if (rankA < rankB) return -1;
  return 0;
};

const isInternalUse = (submission: MatterSubmission) =>
  submission.status === "draft" &&
  submission.rawSubmission.values?.communicationMethod === "internalUse";

const submissionStatusTheme: Record<SubmissionStatus, BadgeProps["theme"]> = {
  active: "information",
  completed: "success",
  cancelled: "highlight",
  draft: "highlight",
};

export const Status = ({ row }: CellProps<TabRowValue>) => {
  let props: BadgeProps = {
    label: submissionStatusLabel[row.original.submission?.status || "draft"],
    theme: submissionStatusTheme[row.original.submission?.status || "draft"],
  };
  if (row.original.form) {
    props = {
      label: "Draft",
      iconRight: "regularSparkles",
      theme: "branding",
    };
  } else if (isInternalUse(row.original.submission)) {
    props = {
      label: submissionExtendedLabel.internalUse || "",
      theme: "highlight",
    };
  }

  return <Badge {...props} />;
};

type SubmissionActionProps = Record<
  | "onView"
  | "onCancel"
  | "onDelete"
  | "onRemind"
  | "onEdit"
  | "onGenerateQRCode",
  (submission: MatterSubmission) => void
> &
  Record<"onViewForm", (form: MatterSpecificForm) => void>;

export const SubmissionAction =
  ({
    onView,
    onCancel,
    onDelete,
    onRemind,
    onEdit,
    onGenerateQRCode,
    onViewForm,
  }: SubmissionActionProps) =>
  ({ row }: CellProps<TabRowValue>) => {
    if (row.original.form)
      return (
        <ContextMenu
          button={{
            className: "w-[2rem] h-[2rem]",
            variant: "secondarySubtle",
            leftIcon: "solidEllipsis",
          }}
          placement="bottom-end"
          offsetBy={5}
          items={[
            {
              label: "View",
              onClick: () => onViewForm(row.original.form!),
            },
          ]}
          id="list-options"
          className="w-fit"
        />
      );

    const { submission } = row.original;

    return (
      <ActionsMenu
        onView={onView}
        onEdit={onEdit}
        onRemind={onRemind}
        onCancel={onCancel}
        onDelete={onDelete}
        onGenerateQRCode={onGenerateQRCode}
        matterSubmission={submission}
        id="list-options"
        variant="subtle"
        className="w-fit"
        size="small"
      />
    );
  };
