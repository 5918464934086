import {
  Empty,
  GqlStatusOverlay,
  GqlStatusOverlayProps,
} from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import { SubmissionItem } from "./item";
import { GqlSubmission } from "./types";

type SubmissionListProps = {
  submissions?: GqlSubmission[];
  select: (uri: string) => void;
  result: GqlStatusOverlayProps;
};

const SubmissionList = ({
  submissions,
  select,
  result,
}: SubmissionListProps) => (
  <>
    {mapOrEmpty(
      submissions,
      (submission) => (
        <SubmissionItem
          key={submission.uri}
          submission={submission}
          select={select}
        />
      ),
      <Empty>
        <p className="empty-text">This form currently has no submissions.</p>
      </Empty>,
    )}
    <GqlStatusOverlay {...result} />
  </>
);

export { SubmissionList, SubmissionListProps };
