import { RuleEvaluators } from "../types";
import { allowedIntakeTrackingActions, INTAKE_TRACKER_SOURCE } from "../utils";

export const tracker: RuleEvaluators["tracker"] = (
  user,
  action,
  { value: { teamUri }, source, trackingAction },
) => {
  if (
    source === INTAKE_TRACKER_SOURCE &&
    trackingAction &&
    allowedIntakeTrackingActions.includes(trackingAction)
  )
    return true;

  if (teamUri === user.teamUri) return true;

  return false;
};
