import { Modal } from "@smart/itops-ui-dom";

import { fieldIcon } from "../constants";
import { DropAction } from "../types";

export type MoveFieldConfirmationModalProps = {
  action: DropAction | undefined;
  onCancel: () => void;
  onConfirm: () => void;
};

export const MoveFieldConfirmationModal = ({
  action,
  onCancel,
  onConfirm,
}: MoveFieldConfirmationModalProps) => (
  <Modal
    open={!!action}
    onClose={onCancel}
    header={{
      icon: { ...fieldIcon.mapped, variant: "success" },
      text: "Move question with mapped field",
    }}
    footer={{
      left: [
        {
          text: "Cancel",
          variant: "cancel",
          onClick: onCancel,
        },
      ],
      right: [
        {
          text: "Continue",
          variant: "save",
          onClick: onConfirm,
        },
      ],
    }}
  >
    <p>
      The question has a mapped field incompatible with the destination
      location.
    </p>
    <p>
      Click &quot;Continue&quot; to move the question anyway without the mapped
      field.
    </p>
  </Modal>
);
