import { NetworkStatus } from "@apollo/client";

import {
  GqlStatusOverlay,
  GqlStatusOverlayProps,
} from "@smart/itops-components-dom";

import { EditTeam } from "./edit";
import { GqlTeam } from "./types";

type TeamViewProps = {
  team: GqlTeam;
  result: GqlStatusOverlayProps;
};

const TeamView = ({ team, result }: TeamViewProps) => (
  <>
    {result.networkStatus === NetworkStatus.ready && (
      <EditTeam
        locators={{}}
        defaultValues={{
          name: team?.values.name || "",
          address: team?.values.address || "",
          phone: team?.values.phone || "",
          picture: team?.values.picture || undefined,
        }}
        submit="Update Firm Details"
      />
    )}
    <GqlStatusOverlay {...result} />
  </>
);

export { TeamView, TeamViewProps };
