import { useCallback, useState } from "react";

import { buildErrorCode } from "@smart/itops-utils-basic";

import { useAsync } from "./async";
import { useIsMounted } from "./mounted";
import { useStatus } from "./status";

type UploadHookProps = {
  existing?: string;
};

type UploadFileProps = {
  url: string;
  file: File;
};

export const useUpload = ({ existing }: UploadHookProps) => {
  const { isMounted } = useIsMounted();
  const [status, setStatus] = useStatus("initial");
  const [errorCode, setErrorCode] = useState<string>();

  const [current, setCurrent] = useState<File | string | undefined>();
  useAsync(async () => {
    if (!existing) return;

    /**
     * If we cannot load the file, set the url directly
     * and let the component handle it.
     */
    try {
      const result = await fetch(existing);
      if (result.status === 200) {
        const blob = await result.blob();
        setCurrent(
          new File([blob], existing, {
            type:
              result.headers.get("content-type") ||
              result.headers.get("Content-Type") ||
              undefined,
          }),
        );
      } else {
        setCurrent(existing);
      }
    } catch (err) {
      setCurrent(existing);
    }
  }, [existing]);

  const upload = useCallback(async ({ url, file }: UploadFileProps) => {
    setStatus("loading");
    try {
      await fetch(url, {
        method: "PUT",
        body: file,
      });
      if (isMounted()) setStatus("success");
    } catch (error) {
      if (isMounted()) {
        setErrorCode(buildErrorCode(error));
        setStatus("error");
      }
    }
  }, []);

  return {
    errorCode,
    status,
    current,
    upload,
  };
};
