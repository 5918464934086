import { z } from "zod";

import { v4 } from "./uuid";
import { jsonParse, wrapSchema } from "./validation";

export const cognitoTokenSchema = z
  .object({
    sub: z.string(),
    device_key: z.string().optional(),
    token_use: z.enum(["access", "refresh", "id"]),
    auth_time: z.number(),
    iss: z.string(),
    exp: z.number(),
    iat: z.number(),
    username: z.string().optional(),
    scope: z.string().optional(),
    jti: z.string().optional(),
    client_id: z.string().optional(),
    aud: z.string().optional(),
    "cognito:username": z.string().optional(),
  })
  .passthrough();

export const guardCognitoToken = wrapSchema(cognitoTokenSchema);

export type CognitoTokenParts = z.infer<typeof cognitoTokenSchema>;

export type CognitoTokenOptions = Pick<
  CognitoTokenParts,
  "sub" | "device_key" | "username"
> &
  Partial<Pick<CognitoTokenParts, "token_use">> & {
    userPoolId: string;
    clientId: string;
    awsRegion: string;
    issuedAt?: number;
    expiresInMins?: number;
    extraFields?: {};
  };

export const buildJWT = (object: CognitoTokenParts) => {
  if (!isLocalServer) {
    throw new Error(`Can't build jwt in live environment`);
  }

  const asString = JSON.stringify(object);
  const asB64 = Buffer.from(asString, "utf8").toString("base64");

  return ["", asB64, ""].join(".");
};

export const buildCognitoToken = ({
  userPoolId,
  clientId,
  awsRegion,
  issuedAt,
  expiresInMins,
  token_use,
  extraFields,
  ...parts
}: CognitoTokenOptions) => {
  const iss = `https://cognito-idp.${awsRegion}.amazonaws.com/${userPoolId}`;
  const iat = Math.floor((issuedAt || Date.now()) / 1000);
  const exp = iat + (expiresInMins || 60) * 60;

  return buildJWT({
    ...parts,
    iss,
    iat,
    exp,
    auth_time: iat,
    scope: "aws.cognito.signin.user.admin",
    jti: v4(),
    client_id: clientId,
    aud: clientId,
    token_use: token_use || "access",
    ...extraFields,
  });
};

export const extractJWT = (token: string) => {
  const parts = token.replace(/^Bearer /, "").split(".");
  const decode = (part: string) => {
    if (typeof Buffer !== "undefined") {
      return Buffer.from(part, "base64").toString("utf8");
    }
    return window.atob(part);
  };
  return jsonParse(decode(parts[1]), "extract-jwt");
};

export const extractCognitoToken = async (token: string) => {
  const parsed = extractJWT(token);

  const parts = await guardCognitoToken(parsed, "extract-cognito-token");
  return parts;
};
