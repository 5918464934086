import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { getToggleClassNames } from "./class-names";
import { Icon } from "../icon";

export type SwitchProps = {
  text?: string;
  title?: string;
  toggled: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  dataTestId?: string;
};

export const Switch = ({
  text,
  title,
  toggled,
  onClick,
  disabled = false,
  className,
  error = false,
  dataTestId,
}: SwitchProps) => (
  <label
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    title={title}
    className={clsx(
      "group inline-flex gap-small items-center outline-none",
      disabled ? "cursor-not-allowed" : "cursor-pointer",
      className,
    )}
  >
    <input
      className="hidden"
      type="checkbox"
      disabled={disabled}
      checked={toggled}
      onChange={() => onClick()}
      data-testid={dataTestId || "switch-input"}
    />
    <div className={twMerge(getToggleClassNames({ toggled, disabled, error }))}>
      <Icon
        name="regularCheck"
        className={clsx(
          "w-[1.2rem] h-[1.2rem] text-white",
          disabled && "text-neutral-400",
        )}
      />
      <Icon
        name="regularXmark"
        className={clsx(
          "w-[1.2rem] h-[1.2rem] text-neutral-850",
          disabled && "text-neutral-400",
        )}
      />
      <div
        className={clsx(
          "transition-[left] duration-300 bg-white absolute rounded-full w-[1.6rem] h-[1.6rem] left-[0.1rem] top-[0.1rem]",
          toggled && "left-[1.7rem]",
        )}
      />
      <div
        className={twMerge(
          clsx(
            "hidden toggle-border absolute inset-[-0.4rem] border rounded-[10px] border-solid",
            !disabled && "group-focus:border-blue-560 group-focus:block",
            !disabled && error && "border-red-550 block",
          ),
        )}
      />
    </div>
    {text && (
      <span className={clsx("text-ui", disabled && "text-neutral-400")}>
        {text}
      </span>
    )}
  </label>
);
