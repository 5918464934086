import { compareHotkey } from "is-hotkey";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BaseEditor, Descendant, Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { deserialize, serialize } from "@smart/itops-serialisation-basic";

import {
  markOptions,
  marks,
  modalOptions,
  modals,
  toggleMark,
} from "./actions";
import { EditorModal } from "./context";
import { createCustomEditor } from "./editor";

export type UseEditorProps = {
  initialTextValue?: string;
  onValueChange?: (v: string) => void;
  autoFocus?: boolean;
};

export const useEditorState = ({
  initialTextValue,
  onValueChange,
  autoFocus,
}: UseEditorProps) => {
  const [modal, setModal] = useState<EditorModal>();

  const editor = useMemo(() => createCustomEditor(), []);
  const initialValue = useMemo(() => deserialize(initialTextValue), []);
  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    for (const option of markOptions) {
      const { hotkey } = marks[option];
      if (hotkey && compareHotkey(hotkey, e)) {
        toggleMark(editor, option)(e);
      }
    }
    for (const option of modalOptions) {
      const { hotkey } = modals[option];
      if (hotkey && compareHotkey(hotkey, e)) {
        e.preventDefault();
        setModal({ option });
      }
    }
  }, []);

  useEffect(() => {
    if (!initialTextValue) {
      editor.deleteBackward("block");
    }
  }, []);

  useEffect(() => {
    if (autoFocus) {
      Transforms.select(editor, Editor.end(editor, []));
    }
  }, [autoFocus]);

  return {
    editor,
    initialValue,
    modal,
    setModal,
    onKeyDown,
    onChange: (v: Descendant[]) => onValueChange?.(serialize(v)),
  };
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
  }
}

export * from "./actions";
export * from "./context";
export * from "./displayer";
