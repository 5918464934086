import {
  familyProIntakeEnquiryFormUri,
  familyProParentingAndPropertyExclCurrSitFormUri,
  familyProParentingAndPropertyFormUri,
  familyProParentingExclCurrSitFormUri,
  familyProParentingFormUri,
  familyProPropertyExclCurrSitFormUri,
  familyProPropertyFormUri,
  familyProQuestionnaireFormUri,
  SubmissionStatus,
} from "@smart/bridge-types-basic";
import { Colour, ColourKey } from "@smart/itops-components-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

export type GqlSubmission = Gql.SubmissionFieldsFragment;

export const statusColour: Record<SubmissionStatus, [ColourKey, keyof Colour]> =
  {
    draft: ["warning", "base"],
    active: ["active", "base"],
    completed: ["default", "highlight"],
    cancelled: ["default", "highlight"],
  };

export type HistoryEventType =
  | "created"
  | "sentViaCommunicate"
  | "sentViaEmail"
  | "completed"
  | "savedToMatter"
  | "matterCreated"
  | "opened"
  | "closed"
  | "modified";

export const historyEventColour: Record<
  HistoryEventType,
  [ColourKey, keyof Colour]
> = {
  created: ["primary", "base"],
  sentViaCommunicate: ["active", "accent"],
  sentViaEmail: ["active", "accent"],
  completed: ["success", "highlight"],
  savedToMatter: ["active", "accent"],
  matterCreated: ["active", "accent"],
  opened: ["active", "accent"],
  closed: ["disabled", "highlight"],
  modified: ["active", "accent"],
};

export const historyEventTypeLabel: Record<HistoryEventType, string> = {
  created: "Created",
  sentViaCommunicate: "Shared via Client Portal",
  sentViaEmail: "Shared via Email",
  completed: "Completed",
  savedToMatter: "Responses Saved",
  matterCreated: "Matter Created",
  opened: "Opened",
  closed: "Closed",
  modified: "Modified",
};

export type AreaInfo = {
  name: string;
  displayName: string;
};

export const externalFormUris = [
  familyProQuestionnaireFormUri,
  familyProIntakeEnquiryFormUri,
  familyProPropertyFormUri,
  familyProParentingFormUri,
  familyProParentingAndPropertyFormUri,
  familyProParentingExclCurrSitFormUri,
  familyProParentingAndPropertyExclCurrSitFormUri,
  familyProPropertyExclCurrSitFormUri,
];

export type SubmissionTabOptions = "view" | "edit";
