import {
  FloatingArrow,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { ReactNode, useRef, useState } from "react";

import { getTooltipClassNames, TooltipVariant } from "./class-names";

export type TooltipProps = {
  id?: string;
  children: ReactNode;
  className?: string;
  variant?: TooltipVariant;
  title?: string;
  text?: string;
  placement?: Placement;
  offsetBy?: number;
};

export const Tooltip = ({
  id,
  className,
  children,
  variant = "label",
  title,
  text,
  placement = "right",
  offsetBy = 0,
}: TooltipProps) => {
  const tooltipArrowRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltip = useFloating({
    placement,
    open: tooltipOpen,
    onOpenChange: setTooltipOpen,
    middleware: [flip(), arrow({ element: tooltipArrowRef }), offset(offsetBy)],
    whileElementsMounted: autoUpdate,
  });
  const tooltipInteractions = useInteractions([
    useHover(tooltip.context, { move: false }),
    useFocus(tooltip.context),
    useDismiss(tooltip.context),
    useRole(tooltip.context, { role: "tooltip" }),
  ]);

  const content =
    variant === "label" ? (
      <span className="text-ui">{text}</span>
    ) : (
      <>
        {title && <h4 className="text-paragraph font-semibold">{title}</h4>}
        <p className="text-ui">{text}</p>
      </>
    );

  return (
    <>
      <div
        id={id}
        className={className}
        ref={tooltip.refs.setReference}
        {...tooltipInteractions.getReferenceProps()}
      >
        {children}
      </div>
      {tooltipOpen && !!text && (
        <div
          className="text-black/90 z-10"
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          {...tooltipInteractions.getFloatingProps()}
        >
          <FloatingArrow
            ref={tooltipArrowRef}
            context={tooltip.context}
            tipRadius={2}
          />
          <div className={getTooltipClassNames({ variant, placement })}>
            {content}
          </div>
        </div>
      )}
    </>
  );
};
