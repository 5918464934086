import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { Icon } from "../icon";

type FailureMessageProps = {
  action: string;
  className?: string;
  textOverride?: string;
  contactName?: string;
  contactLink?: string;
  title?: string;
};

const ContactDisplay = ({ text, link }: { text?: string; link?: string }) =>
  link ? <a href={link}>contact {text}</a> : <span>contact {text}</span>;

export const FailureMessage = ({
  action,
  className,
  textOverride,
  contactName,
  contactLink,
  title,
}: FailureMessageProps) => (
  <div
    className={twMerge(
      clsx(className, "flex items-center whitespace-break-spaces text-red-550"),
    )}
    title={title}
    data-testid="failure-message"
  >
    <Icon
      className="self-start mr-4 mt-[0.3rem]"
      name="regularCircleExclamation"
    />
    <span>
      <span>{textOverride || `Could not ${action}.`}</span>{" "}
      {(contactName || !textOverride) && (
        <p>
          Please try again or{" "}
          <ContactDisplay text={contactName || "support"} link={contactLink} />.
        </p>
      )}
    </span>
  </div>
);
