import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";

import {
  useIdentify,
  useSmokeballFeatureFlagsContext,
} from "@smart/bridge-feature-flags-dom";
import { identifyTrackingUser } from "@smart/bridge-metrics-dom";
import {
  getPermissionChecker,
  PermissionChecker,
} from "@smart/bridge-permissions-basic";
import { loadProvider } from "@smart/bridge-providers-dom";
import { StatusOverlay } from "@smart/itops-components-dom";
import { combineStatus, Status } from "@smart/itops-hooks-dom";
import { ProviderInfo } from "@smart/itops-smokeball-components-dom";
import { UserErrorCode } from "@smart/itops-utils-basic";

import { formsApi, FormsApiResults } from "./api";
import { clearLocalToken, UserToken, useToken } from "./token";

export type UserContextOutput = {
  status: Status;
  errorCode?: UserErrorCode;
  user?: FormsApiResults["me"];
  permission?: PermissionChecker;
  refresh: () => Promise<void>;
  signOut: () => void;
};

export type UserContextValue = UserToken & UserContextOutput;

export const UserContext = createContext<UserContextValue>({
  token: "",
  idToken: "",
  expiresAt: 0,
  status: "initial",
  refresh: async () => {},
  signOut: () => {},
});

export const setupUser = ({
  isEmbeddedPage,
}: {
  isEmbeddedPage: boolean;
}): UserContextValue => {
  const { refresh, signOut, token, status, errorCode } = useToken({
    isEmbeddedPage,
  });
  const { identifyFeatureFlagUser } = useIdentify();
  const { setIdentity: setSBFeatureFlagsIdentity } =
    useSmokeballFeatureFlagsContext();
  const me = formsApi.me();

  useEffect(() => {
    if (token?.token)
      me.post({
        body: undefined,
        token: token.token,
        events: {
          onResult: ({ output }) => {
            identifyTrackingUser({
              source: output.source,
              user: { uri: output.userUri, email: output.email },
              team: output.teamUri
                ? { uri: output.teamUri, name: output.teamName || "" }
                : undefined,
            });
            identifyFeatureFlagUser({
              user: {
                uri: output.userUri,
                email: output.email,
              },
              team: {
                uri: output.teamUri,
                name: output.teamName,
                provider: output.source,
              },
            });
            setSBFeatureFlagsIdentity({
              team: {
                uri: output.teamUri,
                name: output.teamName,
                provider: output.source,
              },
            });
          },
          onError: () => {
            clearLocalToken();
            refresh().catch(console.error);
          },
        },
      }).catch(console.error);
  }, [token?.token, refresh]);

  return {
    refresh,
    signOut,
    status: combineStatus([me.status, status]),
    errorCode,
    user: me.result,
    permission: me.result && getPermissionChecker(me.result),
    token: token?.token || "",
    idToken: token?.idToken || "",
    expiresAt: token?.expiresAt || 0,
  };
};

export const useUser = () => useContext(UserContext);

export type UserProviderProps = {
  children: ReactNode;
  isEmbeddedPage?: boolean;
};

export const UserProvider = ({
  children,
  isEmbeddedPage = false,
}: UserProviderProps) => {
  const value = setupUser({ isEmbeddedPage });
  const provider = useMemo(
    () => loadProvider(value.user?.source),
    [value.user?.source],
  );

  if (value?.token)
    return (
      <UserContext.Provider value={value}>
        <ProviderInfo value={provider}>{children}</ProviderInfo>
      </UserContext.Provider>
    );

  return <StatusOverlay {...value} />;
};
