import { CloseButton, Page } from "@smart/itops-components-dom";

import { ImportForm } from "../components";

const FormImport = () => (
  <Page text="Import a Form" right={<CloseButton />}>
    <ImportForm />
  </Page>
);

export { FormImport };
