import styled from "@emotion/styled";
import { capitalCase } from "change-case";
import { useState } from "react";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { Button, Tooltip, Variant, variantStyle } from "@smart/itops-ui-dom";

import { AffectedItem } from "./affected-item";
import { DropdownField, InputField } from "./condition-field";
import { ConditionType } from "./condition-type";
import { ConditionValue } from "./condition-value";
import { getItemType } from "./utils";
import {
  GqlFieldValues,
  GqlGroupValues,
  GqlSectionValues,
  ItemType,
  UpdateCondition,
  itemTypeLabels,
} from "../../../types";
import { Condition } from "../conditions";

const ConditionBoxWrapper = styled.div`
  --background: ${(props) => props.theme.scheme.grey.r5};
  background: var(--background);
  border: 1px solid ${(props) => props.theme.scheme.grey.r25};
  border-radius: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.base};
  padding: 1.2rem 1.6rem 1.8rem;
  position: relative;
  margin-bottom: 1.6rem;

  &[aria-errormessage] {
    border-color: ${(props) => props.theme.scheme.red.r60};
  }

  .condition-error {
    display: block;
    position: absolute;
    left: -1.6rem;
    top: -1.6rem;
    min-width: 1rem;

    .error-text {
      display: none;
    }

    button {
      border-color: transparent;
    }
  }

  display: flex;
  flex-flow: column;
  gap: 1rem;

  .row {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    gap: 1rem;
  }
`;

const ConditionStatement = styled.div<{ variant?: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  flex: 0 0 6.5rem;
  font-weight: 600;
  border-radius: 0.8rem;
  border: 1px solid transparent;
  ${variantStyle}
  color: inherit;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type ConditionDataProps = {
  choice: string;
  result: string;
  component: string;
};

type ConditionBoxProps = {
  currentField: GqlFieldValues;
  condition: Condition;
  sections: GqlSectionValues[];
  groups: GqlGroupValues[];
  fields: GqlFieldValues[];
  error?: string;
  removeCondition: (id: string) => void;
  updateCondition: UpdateCondition;
};

export const ConditionBox = ({
  currentField,
  sections,
  groups,
  fields,
  condition,
  error,
  updateCondition,
  removeCondition,
}: ConditionBoxProps) => {
  const [itemType, setItemType] = useState<ItemType | undefined>(() =>
    getItemType(condition.affectedItem?.uri),
  );
  const [hideItem, setHideItem] = useState<boolean>(() => !!condition.hide);
  const getItemTypeLabel = (type: ItemType | undefined) =>
    type ? capitalCase(itemTypeLabels[type]) : "";

  return (
    <ConditionBoxWrapper
      data-testid={`condition-box-${condition.id}`}
      aria-invalid={!!error}
      aria-errormessage={error ? `${condition.id}-error` : undefined}
    >
      {error && (
        <Tooltip
          id={`${condition.id}-error`}
          className="condition-error"
          tooltipText={error}
        >
          <div className="error-text">{error}</div>
          <Button
            icon={{ library: "lucide", name: "AlertCircle" }}
            kind="borderless"
            variant="error"
          />
        </Tooltip>
      )}
      <div className="row">
        <ConditionStatement
          className="condition-box-statement"
          variant="highlightOrange"
        >
          If
        </ConditionStatement>
        <InputField
          label="Question"
          name="affecting-question"
          value={
            condition.affectingField.label ||
            fieldFallbackLabel[condition.affectingField.type]
          }
          width="l"
          readOnly
        />
        <ConditionType
          condition={condition}
          onChange={(conditionType) =>
            updateCondition(condition.id, { condition: conditionType })
          }
          clearConditionValue={() =>
            updateCondition(condition.id, { value: "" })
          }
        />
        <ConditionValue
          condition={condition}
          onChange={(value) => updateCondition(condition.id, { value })}
        />
      </div>
      <div className="row">
        <ConditionStatement
          className="condition-box-statement"
          variant="highlightPurple"
        >
          Then
        </ConditionStatement>
        <DropdownField
          label="Result"
          name="hide-show-item"
          options={["Show", "Hide"] as const}
          selected={hideItem ? "Hide" : "Show"}
          onChange={(action: "Show" | "Hide") => {
            const shouldHide = action === "Hide";
            updateCondition(condition.id, { hide: shouldHide });
            setHideItem(shouldHide);
          }}
        />
        <DropdownField
          label="Component"
          options={["Field", "Section", "Group"] as const}
          render={getItemTypeLabel}
          selected={itemType}
          onChange={(type) => {
            updateCondition(condition.id, {
              affectedItem: undefined,
            });
            setItemType(type);
          }}
        />
        <AffectedItem
          label={itemType ? capitalCase(itemTypeLabels[itemType]) : ""}
          itemType={itemType}
          sections={sections}
          groups={groups}
          fields={fields}
          currentField={currentField}
          affectedItem={condition.affectedItem}
          onChange={(affectedItem) => {
            updateCondition(condition.id, { affectedItem });
          }}
        />
        <Button
          text="Remove"
          icon={{ library: "lucide", name: "X" }}
          variant="remove"
          onClick={() => removeCondition(condition.id)}
        />
      </div>
    </ConditionBoxWrapper>
  );
};
