import { useState } from "react";

import { Icon, Spinner } from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import { Item, NotItem } from "./components";
import { ItemTagsProps, SubCollectionOption, tagItems } from "./items";
import { TagsProps, TagsWrapper } from "./wrapper";

type BaseCollection = {
  id: string;
  label: string;
};

type CollectionSet<V> = { loading: boolean; items: V[] };

const collectionItems = <V extends any | SubCollectionOption<V>>({
  set,
  ...rest
}: { set: CollectionSet<V | SubCollectionOption<V>> | null } & Omit<
  ItemTagsProps<V>,
  "options"
>) => {
  if (!set) return null;

  if (set.loading)
    return (
      <NotItem>
        <Spinner size={2} />
      </NotItem>
    );

  return tagItems({
    options: set.items,
    ...rest,
  });
};

export type CollectionTagProps<C extends BaseCollection, V> = Omit<
  TagsProps<V>,
  "readOnly"
> & {
  collections: C[];
  options: (collectionId: string) => Promise<(V | SubCollectionOption<V>)[]>;
  emptyCollections: string;
  emptyOptions: string;
  displayMode?: "normal" | "readOnly" | "collectionsOnly";
};

export const CollectionTags = <C extends BaseCollection, V extends any>({
  name,
  collections,
  options,
  selected,
  render,
  keys,
  override,
  onToggle,
  emptyCollections,
  emptyOptions,
  displayMode = "normal",
}: CollectionTagProps<C, V>) => {
  const [sets, updateSets] = useState<Record<
    string,
    CollectionSet<V | SubCollectionOption<V>> | null
  > | null>(null);

  const setShowOptions = (show: boolean) =>
    updateSets((current) => (show ? current || {} : null));

  const toggleSet = async (key: string) => {
    if (sets && sets[key]) {
      updateSets((current) => ({ ...current, [key]: null }));
    } else {
      updateSets((current) => ({
        ...current,
        [key]: { loading: true, items: [] },
      }));
      const items = await options(key);
      updateSets((current) =>
        current && current[key]
          ? { ...current, [key]: { loading: false, items } }
          : current,
      );
    }
  };

  const readOnly = displayMode === "readOnly";

  const renderedCollections = mapOrEmpty(
    collections,
    (collection) => (
      <div key={collection.id}>
        <Item data-testid={`tags-${name}-collection`}>
          <input
            type="checkbox"
            name={`collection-${name}`}
            value={`collection-${collection.id}`}
            checked={!!(sets && sets[collection.id])}
            onChange={() => toggleSet(collection.id)}
          />
          <Icon name="angleRightBold" className="arrow" size={8} />
          {collection.label}
        </Item>
        {collectionItems({
          set: sets && sets[collection.id],
          empty: emptyOptions,
          name,
          keys,
          render,
          onToggle,
          selected,
          readOnly,
          override,
        })}
      </div>
    ),
    <NotItem>{emptyCollections}</NotItem>,
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (displayMode === "collectionsOnly") return <>{renderedCollections}</>;

  return (
    <TagsWrapper
      name={name}
      keys={keys}
      render={render}
      onToggle={onToggle}
      selected={selected}
      showOptions={!!sets}
      setShowOptions={setShowOptions}
      readOnly={readOnly}
    >
      {renderedCollections}
    </TagsWrapper>
  );
};
