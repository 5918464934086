export const removeKeys = <T extends Object, K extends (keyof T)[]>(
  object: T,
  keys: K,
): Omit<T, (typeof keys)[number]> => {
  const shallowCopied = { ...object };
  keys.forEach((key) => {
    delete shallowCopied[key];
  });
  return shallowCopied;
};

type KeyOmit<T, K extends PropertyKey> = {
  [P in keyof T]: P extends K
    ? never
    : T[P] extends object
      ? KeyOmit<T[P], K>
      : T[P];
};

type MultipleKeysOmit<T, K extends PropertyKey[]> = K extends [
  infer Head,
  ...infer Tail,
]
  ? Head extends PropertyKey
    ? Tail extends PropertyKey[]
      ? KeyOmit<MultipleKeysOmit<T, Tail>, Head>
      : T
    : T
  : T;

export const removeKeysRecursively = <T, K extends (keyof any)[]>(
  object: T,
  keys: K,
): MultipleKeysOmit<T, K> => {
  if (typeof object !== "object" || object === null) {
    return object as MultipleKeysOmit<T, K>;
  }

  if (Array.isArray(object)) {
    return object.map((item) =>
      removeKeysRecursively(item, keys),
    ) as unknown as MultipleKeysOmit<T, K>;
  }

  const newObj: Partial<MultipleKeysOmit<T, K>> = {};

  for (const [key, value] of Object.entries(object)) {
    if (!keys.includes(key as keyof Object)) {
      newObj[key as keyof MultipleKeysOmit<T, K>] =
        typeof value === "object" && value !== null
          ? removeKeysRecursively(value, keys)
          : value;
    }
  }

  return newObj as MultipleKeysOmit<T, K>;
};

export const removeEmptyValues = <T extends Object>(
  object: T | null | undefined,
  emptyValues: (undefined | null)[] = [undefined, null],
): T extends Object ? Partial<T> : undefined => {
  if (!object) return undefined as any;
  return Object.entries(object).reduce(
    (removed, [key, value]) =>
      emptyValues.includes(value) ? removed : { ...removed, [key]: value },
    {},
  ) as any;
};

export const filterBy = <T extends Object>(
  object: T,
  filterFn: (key: keyof T, value: T[keyof T]) => boolean,
): Partial<T> =>
  Object.keys(object)
    .filter((key) => filterFn(key as keyof T, object[key as keyof T]))
    .reduce((aggr, key) => ({ ...aggr, [key]: object[key as keyof T] }), {});

export const getValueByNestedKeys = <T extends any>(
  keys: string[],
  object: Record<string, any> | undefined,
): T | undefined => {
  if (!object || !keys?.length) return undefined;

  let value = object;
  for (const key of keys) {
    if (value && typeof value === "object" && Object.hasOwn(value, key)) {
      value = value[key];
    } else {
      return undefined;
    }
  }
  return value as T;
};

export const removeEmptyStringValues = <T extends Object>(
  object: T | null | undefined,
): T extends Object ? Partial<T> : undefined => {
  if (!object) return undefined as any;
  return Object.entries(object).reduce(
    (removed, [key, value]) =>
      value === "" ? removed : { ...removed, [key]: value },
    {},
  ) as any;
};

export const getNestedValue = <T extends any>(
  obj: Record<string, any> | undefined,
  key: string,
): T | undefined => {
  if (!obj) return undefined;
  const pathSegments = key.split("/");
  let value = obj;

  for (const segment of pathSegments) {
    if (value && typeof value === "object") {
      value = value[segment];
    } else {
      return undefined;
    }
  }

  return value as T;
};

export const setNestedValue = (
  obj: Record<string, any> | undefined,
  key: string,
  value: any,
) => {
  if (!obj) return;
  const keys = key.split("/");
  let currentObj = obj;

  keys.forEach((currentKey, index) => {
    const isFinalKey = index === keys.length - 1;
    if (!isFinalKey) {
      if (!currentObj[currentKey]) {
        currentObj[currentKey] = {};
      }
      currentObj = currentObj[currentKey];
    } else {
      currentObj[currentKey] = value;
    }
  });
};
