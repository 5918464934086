import styled from "@emotion/styled";
import { transparentize } from "polished";

import { specialChars } from "@smart/itops-utils-basic";

import { SubmissionStatus } from "./status";
import { SubmissionTags } from "./tags";
import { GqlSubmission } from "./types";

const SubmissionItemWrapper = styled.button<{ completed: boolean }>`
  display: grid;
  grid-template-columns: auto 12rem;
  grid-template-areas: "details updated";
  align-items: center;

  background: none;
  border: 0;
  text-align: left;
  width: 100%;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0.4rem;

  border-bottom: 1px dotted ${(props) => props.theme.palette.disabled.base};
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      transparentize(0.9, props.theme.palette.primary.accent)};
  }

  .details {
    grid-area: "details";
    padding: 1rem 0;

    .title {
      font-size: ${(props) => props.theme.fontSize.base};
      margin: 1rem 0 0;
    }
  }

  .updated {
    grid-area: "updated";
    color: ${(props) =>
      props.completed
        ? props.theme.palette.success.base
        : props.theme.palette.disabled.base};

    display: flex;
    flex-flow: column;
    align-items: center;

    .time {
      font-size: ${(props) => props.theme.fontSize.xSmall};
      margin: 0;
    }
  }
`;

type SubmissionItemProps = {
  submission: GqlSubmission;
  select: (uri: string) => void;
};

const SubmissionItem = ({ submission, select }: SubmissionItemProps) => (
  <SubmissionItemWrapper
    onClick={() => select(submission.uri)}
    completed={submission.values.status === "completed"}
  >
    <div className="details">
      <SubmissionTags submission={submission} />
      <p className="title">{submission.summary || specialChars.enDash}</p>
    </div>
    <div className="updated">
      <SubmissionStatus submission={submission} />
    </div>
  </SubmissionItemWrapper>
);

export { SubmissionItem, SubmissionItemProps };
