import { useLazyQuery } from "@apollo/client";

import { queryDocuments } from "@smart/manage-gql-operations-dom";

export const useLazyLoadContacts = () => {
  const [load] = useLazyQuery(queryDocuments.contacts, {
    fetchPolicy: "no-cache",
  });

  return async (variables: {
    names?: string[];
    emails?: string[];
    phones?: string[];
  }) => {
    const result = await load({ variables });
    return result.data?.contacts;
  };
};
