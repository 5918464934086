/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterQuestionnaireStatusFieldsFragment = {
  __typename: "MatterQuestionnaireStatus";
  id: string;
  link?: string | null;
  embedUrl: string;
  status: Types.GqlMatterQuestionnaireStatusValues;
  submitted?: string | null;
  sent?: string | null;
  updated?: string | null;
  questionnaireType?: Types.GqlQuestionnaireType | null;
};

export const MatterQuestionnaireStatusFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "matterQuestionnaireStatusFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatterQuestionnaireStatus" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "link" } },
          { kind: "Field", name: { kind: "Name", value: "embedUrl" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "submitted" } },
          { kind: "Field", name: { kind: "Name", value: "sent" } },
          { kind: "Field", name: { kind: "Name", value: "updated" } },
          { kind: "Field", name: { kind: "Name", value: "questionnaireType" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlMatterQuestionnaireStatusFieldsFragment,
  unknown
>;
