import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Input } from "@smart/itops-components-dom";
import { insertLink, useEditorContext } from "@smart/itops-editor-dom";
import {
  Field,
  FieldGroup,
  Modal,
} from "@smart/itops-smokeball-components-dom";

const hyperlinkSchema = z.object({
  display: z.string().min(1, "Please enter display text"),
  url: z.string().min(1, "Please enter url"),
});

const CustomisedModal = styled(Modal)`
  .modal {
    height: 25rem;
    .content {
      overflow: visible;
      min-height: 10rem;

      fieldset {
        gap: 1rem;
      }
    }
  }
`;

export type HyperlinkModalProps = {
  hyperlinkModal: [boolean, (v: boolean) => void];
};

export const HyperlinkModal = ({
  hyperlinkModal: [show, showModal],
}: HyperlinkModalProps) => {
  const { editor } = useEditorContext();

  const form = useForm({
    defaultValues: { display: "", url: "" },
    resolver: zodResolver(hyperlinkSchema),
  });
  const { handleSubmit, reset, register } = form;

  const onSubmit = handleSubmit(({ display, url }) => {
    const fullUrl = url.match(/^https?:\/\//) ? url : `https://${url}`;
    insertLink(editor, display, fullUrl);
    reset({ display: "", url: "" });
    showModal(false);
  });

  return (
    <FormProvider {...form}>
      <CustomisedModal
        size="mobile"
        title=" "
        subtitle="Add Link"
        open={[show, () => showModal(false)]}
        buttons={[
          {
            text: "Cancel",
            type: "reset",
            onClick: () => {
              reset({ display: "", url: "" });
              showModal(false);
            },
          },
          {
            text: "Add link",
            palette: "primary",
            onClick: onSubmit,
          },
        ]}
        closeOptions={{
          closeButton: false,
        }}
      >
        <FieldGroup>
          <Field name="display" text="Display Text">
            <Input
              {...register("display")}
              id="displayText"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                }
              }}
              autoFocus
            />
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Field name="url" text="URL">
            <Input
              {...register("url")}
              id="url"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </Field>
        </FieldGroup>
      </CustomisedModal>
    </FormProvider>
  );
};
