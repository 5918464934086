/* eslint-disable */
import {
  MatterFieldFieldsFragmentDoc,
  GqlMatterFieldFieldsFragment,
} from "./matter-field-fields";
import {
  MatterLayoutFieldsFragmentDoc,
  GqlMatterLayoutFieldsFragment,
} from "./matter-layout-fields";
import {
  MatterTypeFieldsFragmentDoc,
  GqlMatterTypeFieldsFragment,
} from "./matter-type-fields";
import {
  MatterFieldsDocument,
  GqlMatterFieldsQuery,
  GqlMatterFieldsQueryVariables,
} from "./matter-fields";
import {
  MatterLayoutsDocument,
  GqlMatterLayoutsQuery,
  GqlMatterLayoutsQueryVariables,
} from "./matter-layouts";
import {
  MatterTypeCategoriesDocument,
  GqlMatterTypeCategoriesQuery,
  GqlMatterTypeCategoriesQueryVariables,
} from "./matter-type-categories";
import {
  MatterTypeGroupsDocument,
  GqlMatterTypeGroupsQuery,
  GqlMatterTypeGroupsQueryVariables,
} from "./matter-type-groups";
import {
  MatterTypeSearchDocument,
  GqlMatterTypeSearchQuery,
  GqlMatterTypeSearchQueryVariables,
} from "./matter-type-search";
import {
  MatterTypeDocument,
  GqlMatterTypeQuery,
  GqlMatterTypeQueryVariables,
} from "./matter-type";
import {
  MatterTypesDocument,
  GqlMatterTypesQuery,
  GqlMatterTypesQueryVariables,
} from "./matter-types";
import {
  RepeatableMatterFieldsDocument,
  GqlRepeatableMatterFieldsQuery,
  GqlRepeatableMatterFieldsQueryVariables,
} from "./repeatable-matter-fields";

export const queryKeys = [
  "matterFields",
  "matterLayouts",
  "matterTypeCategories",
  "matterTypeGroups",
  "matterTypeSearch",
  "matterType",
  "matterTypes",
  "repeatableMatterFields",
] as const;
export type QueryKey = (typeof queryKeys)[number];
export const queryDocuments = {
  matterFields: MatterFieldsDocument,
  matterLayouts: MatterLayoutsDocument,
  matterTypeCategories: MatterTypeCategoriesDocument,
  matterTypeGroups: MatterTypeGroupsDocument,
  matterTypeSearch: MatterTypeSearchDocument,
  matterType: MatterTypeDocument,
  matterTypes: MatterTypesDocument,
  repeatableMatterFields: RepeatableMatterFieldsDocument,
};

export const mutationKeys = [] as const;
export type MutationKey = (typeof mutationKeys)[number];
export const mutationDocuments = {};

export const subscriptionKeys = [] as const;
export type SubscriptionKey = (typeof subscriptionKeys)[number];
export const subscriptionDocuments = {};

export const fragments = {
  matterFieldFields: MatterFieldFieldsFragmentDoc,
  matterLayoutFields: MatterLayoutFieldsFragmentDoc,
  matterTypeFields: MatterTypeFieldsFragmentDoc,
};
export namespace Gql {
  export type MatterFieldFieldsFragment = GqlMatterFieldFieldsFragment;
  export type MatterLayoutFieldsFragment = GqlMatterLayoutFieldsFragment;
  export type MatterTypeFieldsFragment = GqlMatterTypeFieldsFragment;
  export type MatterFieldsQuery = GqlMatterFieldsQuery;
  export type MatterFieldsQueryVariables = GqlMatterFieldsQueryVariables;
  export type MatterLayoutsQuery = GqlMatterLayoutsQuery;
  export type MatterLayoutsQueryVariables = GqlMatterLayoutsQueryVariables;
  export type MatterTypeCategoriesQuery = GqlMatterTypeCategoriesQuery;
  export type MatterTypeCategoriesQueryVariables =
    GqlMatterTypeCategoriesQueryVariables;
  export type MatterTypeGroupsQuery = GqlMatterTypeGroupsQuery;
  export type MatterTypeGroupsQueryVariables =
    GqlMatterTypeGroupsQueryVariables;
  export type MatterTypeSearchQuery = GqlMatterTypeSearchQuery;
  export type MatterTypeSearchQueryVariables =
    GqlMatterTypeSearchQueryVariables;
  export type MatterTypeQuery = GqlMatterTypeQuery;
  export type MatterTypeQueryVariables = GqlMatterTypeQueryVariables;
  export type MatterTypesQuery = GqlMatterTypesQuery;
  export type MatterTypesQueryVariables = GqlMatterTypesQueryVariables;
  export type RepeatableMatterFieldsQuery = GqlRepeatableMatterFieldsQuery;
  export type RepeatableMatterFieldsQueryVariables =
    GqlRepeatableMatterFieldsQueryVariables;
}
