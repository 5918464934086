import styled from "@emotion/styled";
import { useMemo } from "react";

import { variantStyle, Variant } from "@smart/itops-ui-dom";
import { jsonParseOrReturn } from "@smart/itops-utils-basic";

import { ConditionLink } from "./condition-link";
import { getOptions } from "./condition-type";
import { ConditionItem } from "./utils";
import { Condition } from "../conditions";

const ConditionListItem = styled.div`
  margin: 0.6rem 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  .condition-operation {
    flex: 0;
    white-space: nowrap;
  }
`;

const ConditionRule = styled.div<{ variant?: Variant; shrink?: boolean }>`
  border-radius: 0.8rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;
  ${variantStyle}
  color: inherit;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 ${(props) => (props.shrink ? 1 : 0)} min-content;
`;

export type ConditionListItemProps = {
  condition: Condition;
  setCurrentField: (field: ConditionItem | null) => void;
};

export const AffectedBy = ({
  condition,
  setCurrentField,
}: ConditionListItemProps) => {
  const conditionOperation = useMemo(
    () =>
      getOptions(condition.affectingField.type).find(
        (option) => option.condition === condition.condition,
      )?.displayText,
    [condition.affectingField.type, condition.condition],
  );
  const conditionValue = useMemo(() => {
    const { type, options } = condition.affectingField;
    const getLabel = (v: string) =>
      options.find((o) => o.value === v)?.label || v;

    try {
      if (type === "checkbox") {
        const value = jsonParseOrReturn(condition.value);
        return value
          .map(getLabel)
          .join(condition.condition === "in" ? " or " : " and ");
      }

      if (type === "choice" && condition.condition !== "equalTo") {
        const value = jsonParseOrReturn(condition.value);
        return value.map(getLabel).join(" or ");
      }

      if (type === "choice") {
        return getLabel(condition.value);
      }
    } catch (e) {
      console.error("Error parsing condition value", e);
    }

    return condition.value;
  }, [
    condition.affectingField.type,
    condition.affectingField.options,
    condition.condition,
    condition.value,
  ]);

  return (
    <ConditionListItem data-testid="affected-by-condition">
      <ConditionLink
        field={condition.affectingField}
        setCurrentField={setCurrentField}
      />
      <span className="condition-operation">{conditionOperation}</span>
      <ConditionRule variant="highlightOrange" title={conditionValue} shrink>
        {conditionValue}
      </ConditionRule>
    </ConditionListItem>
  );
};

export const Effecting = ({
  condition,
  setCurrentField,
}: ConditionListItemProps) => (
  <ConditionListItem data-testid="effecting-condition">
    <ConditionRule variant="highlightPurple">
      {condition.hide ? "Hide" : "Show"}
    </ConditionRule>
    <ConditionLink
      field={condition.affectedItem}
      setCurrentField={setCurrentField}
    />
  </ConditionListItem>
);
