import styled from "@emotion/styled";
import {
  FloatingArrow,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { useEffect, useRef, useState } from "react";

import { ContextMenuItem, ContextMenuItemProps } from "./item";
import { Button, ButtonProps } from "../button";
import { TooltipContent } from "../tooltip";

const ContextMenuWrapper = styled.div<{ isSubMenu?: boolean }>`
  background: white;
  border-radius: 0.8rem;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  min-width: ${(props) => (props.isSubMenu ? "14rem" : "18rem")};

  overflow: hidden;
  z-index: 1;
`;

export type ContextMenuProps = {
  id?: string;
  button: Omit<ButtonProps, "onClick">;
  items: ContextMenuItemProps[];
  className?: string;
  tooltipText?: string;
  placement?: Placement;
  tooltipPlacement?: Placement;
  fallbackPlacements?: Placement[];
  offSetTooltip?: number;
};

export const ContextMenu = ({
  id,
  items,
  className,
  tooltipText,
  button,
  placement = "bottom",
  fallbackPlacements,
  tooltipPlacement,
  offSetTooltip = 0,
}: ContextMenuProps) => {
  const tooltipArrowRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [subMenu, setSubMenu] = useState<ContextMenuItemProps[] | null>(null);
  const menu = useFloating({
    placement,
    open: menuOpen,
    onOpenChange: setMenuOpen,
    middleware: [
      shift({ padding: 10 }),
      flip({ fallbackPlacements: fallbackPlacements || ["bottom", "top"] }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const subMenuFloating = useFloating({
    placement: "right-start",
    middleware: [offset(), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const menuInteractions = useInteractions([
    useDismiss(menu.context),
    useClick(menu.context),
  ]);
  const tooltip = useFloating({
    placement: tooltipPlacement ?? "right",
    open: tooltipOpen,
    onOpenChange: setTooltipOpen,
    middleware: [
      offset(offSetTooltip),
      flip({ fallbackPlacements: ["left", "top"] }),
      arrow({ element: tooltipArrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const tooltipInteractions = useInteractions([
    useHover(tooltip.context, { move: false }),
    useFocus(tooltip.context),
    useDismiss(tooltip.context),
    useRole(tooltip.context, { role: "tooltip" }),
  ]);

  const buttonRef = useMergeRefs([
    menu.refs.setReference,
    tooltip.refs.setReference,
  ]);

  const subMenuRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    if (!menuOpen) {
      setSubMenu(null);
    }
  }, [menuOpen]);

  return (
    <div
      className={className}
      data-testid={id ? `${id}-context-menu` : "context-menu"}
      ref={buttonRef}
      {...tooltipInteractions.getReferenceProps(
        menuInteractions.getReferenceProps(),
      )}
    >
      <Button
        {...button}
        onClick={() => {
          setTooltipOpen(false);
          setMenuOpen((v) => !v);
        }}
      />
      {tooltipOpen && !menuOpen && !!tooltipText && (
        <TooltipContent
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          {...tooltipInteractions.getFloatingProps()}
        >
          <FloatingArrow
            ref={tooltipArrowRef}
            context={tooltip.context}
            tipRadius={2}
          />
          <p>{tooltipText}</p>
        </TooltipContent>
      )}
      {menuOpen && (
        <ContextMenuWrapper
          ref={menu.refs.setFloating}
          style={menu.floatingStyles}
          {...menuInteractions.getFloatingProps()}
        >
          {items.map((item, index) => (
            <div
              ref={(el) => {
                subMenuRefs.current[index] = el;
              }}
              key={item.text}
            >
              <ContextMenuItem
                closeMenu={() => setMenuOpen(false)}
                openSubMenu={(subItems) => {
                  setSubMenu(subItems);
                  subMenuFloating.refs.setReference(subMenuRefs.current[index]);
                }}
                isSubMenuOpen={!!subMenu?.length}
                {...item}
              />
            </div>
          ))}
        </ContextMenuWrapper>
      )}
      {menuOpen && subMenu?.length && (
        <ContextMenuWrapper
          ref={subMenuFloating.refs.setFloating}
          style={subMenuFloating.floatingStyles}
          isSubMenu
        >
          {subMenu.map((subItem) => (
            <ContextMenuItem
              key={subItem.text}
              closeMenu={() => {
                setSubMenu(null);
                setMenuOpen(false);
              }}
              openSubMenu={() => {}}
              {...subItem}
            />
          ))}
        </ContextMenuWrapper>
      )}
    </div>
  );
};
