/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlSettingFieldsFragment = {
  __typename: "Setting";
  uri: string;
  operationId: string;
  teamUri: string;
  locations?: Array<string> | null;
  areasOfLaw?: Array<string> | null;
  updatedAt: string;
  defaultClientMessage?: {
    __typename: "DefaultClientMessage";
    matter?: string | null;
    lead?: string | null;
    reminder?: string | null;
    confirmation?: string | null;
  } | null;
};

export const SettingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "settingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Setting" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "teamUri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultClientMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "matter" } },
                { kind: "Field", name: { kind: "Name", value: "lead" } },
                { kind: "Field", name: { kind: "Name", value: "reminder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "confirmation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "locations" } },
          { kind: "Field", name: { kind: "Name", value: "areasOfLaw" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlSettingFieldsFragment, unknown>;
