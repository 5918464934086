type Resource = {
  type: string;
  uri?: string;
};

export class PermissionError extends Error {
  public action: string;

  public resource: Resource;

  public userUri: string;

  constructor(action: string, resource: Resource, userUri: string = "") {
    const userUriString = userUri && `(${userUri})`;
    const resourceUriString = resource.uri ? `(${resource.uri})` : "";
    super(
      `User${userUriString} cannot perform action ${action} on ${resource.type}${resourceUriString}`,
    );

    this.action = action;
    this.resource = resource;
    this.userUri = userUri;
  }
}
