import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Modal } from "@smart/itops-ui-dom";

import { EditOptions } from "./edit-options";
import { fieldIcon } from "../../constants";
import { GqlFieldValues, OnUpdateField } from "../../types";

type EditChoiceQuestionProps = {
  onUpdateField: OnUpdateField;
  field: GqlFieldValues;
};

export const EditChoice = ({
  field,
  onUpdateField,
}: EditChoiceQuestionProps) => {
  const { label, type, allowCustomResponse, options } = field;
  const formMethods = useForm<{
    allowCustomResponse?: boolean | null;
    options: { label: string; value: string }[];
  }>({
    defaultValues: {
      allowCustomResponse,
      options: options?.length ? options : [{ label: "", value: "" }],
    },
  });
  const { control, handleSubmit, setValue } = formMethods;

  const submit = handleSubmit(
    async ({
      allowCustomResponse: updatedAllowCustomResponse,
      options: updatedOptions,
    }) => {
      await onUpdateField({
        field,
        updated: {
          options: updatedOptions.map((option) => ({
            label: option.label,
            value: option.value,
          })),
          allowCustomResponse: updatedAllowCustomResponse,
        },
      });
    },
  );

  const [optionsModal, showOptionsModal] = useState(false);
  const fieldOptions = useFieldArray({ control, name: "options" });

  useEffect(() => {
    setValue("options", options);
    if (!options?.length) fieldOptions.append({ label: "", value: "" });
  }, [options]);

  const [isShowingOtherOption, setIsShowingOtherOption] =
    useState(!!allowCustomResponse);

  const addOption = async (option: "text" | "other") => {
    if (option === "text") {
      fieldOptions.append({ label: "", value: "" });
    } else {
      setIsShowingOtherOption(true);
      setValue("allowCustomResponse", true);
    }
    await submit();
  };

  const removeOption = async (index?: number) => {
    if (index !== undefined && index !== null) {
      fieldOptions.remove(index);
    } else {
      setIsShowingOtherOption(false);
      setValue("allowCustomResponse", false);
    }
    await submit();
  };

  const updateOption = async (index: number, text: string | undefined) => {
    const isMappedField = field.layout && field.field;
    const valueToUpdate =
      isMappedField &&
      field.field?.possibleValues?.includes(fieldOptions.fields[index].value)
        ? fieldOptions.fields[index].value || text || ""
        : text || "";
    fieldOptions.update(index, { label: text || "", value: valueToUpdate });
    await submit();
  };

  const moveOption = async (sourceIndex: number, destinationIndex: number) => {
    fieldOptions.move(sourceIndex, destinationIndex);
    await submit();
  };

  return (
    <>
      <EditOptions
        addOption={addOption}
        moveOption={moveOption}
        updateOption={updateOption}
        removeOption={removeOption}
        openOptionModal={() => showOptionsModal(true)}
        optionsFields={fieldOptions.fields}
        type={field.type as "choice" | "checkbox"}
        isShowingOtherOption={isShowingOtherOption}
      />
      <Modal
        dataTestId="edit-options-modal"
        header={{
          icon: fieldIcon[type],
          text: `Edit options for "${label}"`,
        }}
        open={optionsModal}
        onClose={() => showOptionsModal(false)}
      >
        <EditOptions
          addOption={addOption}
          moveOption={moveOption}
          updateOption={updateOption}
          removeOption={removeOption}
          optionsFields={fieldOptions.fields}
          type={field.type as "choice" | "checkbox"}
          isShowingOtherOption={isShowingOtherOption}
        />
      </Modal>
    </>
  );
};
