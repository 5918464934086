import { KeyboardEventHandler } from "react";
import { Control, Path, PathValue, useController } from "react-hook-form";

import { Editor, EditorProps } from "./editor";

type FormStringKey<V extends {}> = {
  [K in Path<V>]: PathValue<V, K> extends string ? K : never;
}[Path<V>];

type FormEditorProps<V extends {}> = {
  control: Control<V>;
  name: FormStringKey<V>;
  onBlur?: () => void;
  onKeyDown?: KeyboardEventHandler;
  disabled?: boolean;
  toolbarOptions?: EditorProps["toolbarOptions"];
  disabledHotkeys?: string[];
  autoFocus?: boolean;
};

const FormEditor = <V extends {}>({
  control,
  name,
  onBlur,
  onKeyDown,
  disabled,
  toolbarOptions,
  disabledHotkeys,
  autoFocus,
}: FormEditorProps<V>) => {
  const { field } = useController({ control, name });
  return (
    <Editor
      initialValue={field.value}
      onChange={field.onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      disabled={disabled}
      toolbarOptions={toolbarOptions}
      disabledHotkeys={disabledHotkeys}
      dataTestId={`editor-field-${name}`}
      autoFocus={autoFocus}
    />
  );
};

export { FormEditor, FormEditorProps };
