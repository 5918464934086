import { clsx } from "clsx";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type FieldListProps = {
  children: ReactNode;
  className?: string;
};
export const FieldList = ({ children, className }: FieldListProps) => (
  <div className={twMerge(clsx("flex flex-col gap-8 w-full", className))}>
    {children}
  </div>
);
