import styled from "@emotion/styled";

const SvgWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-radius: 5px;
  padding: 0.1rem 0.8rem;
  margin: 0 0.8rem;

  svg {
    path {
      fill: ${(props) => props.theme.palette.background.highlight};

      &.selected {
        fill: ${(props) => props.theme.palette.primary.base};
      }
    }
  }
`;

export type EmbedIconProps = {
  size?: number;
  selected: [boolean, boolean, boolean, boolean, boolean];
};

export const EmbedIcon = ({ size = 45, selected }: EmbedIconProps) => (
  <SvgWrapper>
    <svg width={size} height={size} viewBox="0 0 512 512">
      <path
        d="M68,64 L444,64 C446.209,64 448,65.791 448,68 L448,124 C448,126.209 446.209,128 444,128 L68,128 C65.791,128 64,126.209 64,124 L64,68 C64,65.791 65.791,64 68,64 z"
        className={selected[0] ? "selected" : ""}
      />
      <path
        d="M100,176 L412,176 C414.209,176 416,177.791 416,180 L416,236 C416,238.209 414.209,240 412,240 L100,240 C97.791,240 96,238.209 96,236 L96,180 C96,177.791 97.791,176 100,176 z"
        className={selected[1] ? "selected" : ""}
      />
      <path
        d="M100,272 L412,272 C414.209,272 416,273.791 416,276 L416,332 C416,334.209 414.209,336 412,336 L100,336 C97.791,336 96,334.209 96,332 L96,276 C96,273.791 97.791,272 100,272 z"
        className={selected[2] ? "selected" : ""}
      />
      <path
        d="M100,368 L252,368 C254.209,368 256,369.791 256,372 L256,428 C256,430.209 254.209,432 252,432 L100,432 C97.791,432 96,430.209 96,428 L96,372 C96,369.791 97.791,368 100,368 z"
        className={selected[3] ? "selected" : ""}
      />
      <path
        d="M322.4,368 L413.6,368 C414.925,368 416,369.791 416,372 L416,428 C416,430.209 414.925,432 413.6,432 L322.4,432 C321.075,432 320,430.209 320,428 L320,372 C320,369.791 321.075,368 322.4,368 z"
        className={selected[4] ? "selected" : ""}
      />
    </svg>
  </SvgWrapper>
);
