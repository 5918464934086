/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlSlugFieldsFragment = {
  __typename: "Slug";
  uri: string;
  values: {
    __typename: "SlugValues";
    uri: string;
    operationId: string;
    value: string;
    active: boolean;
    updatedAt: string;
    updatedBy: {
      __typename: "User";
      uri: string;
      email: string;
      firstName: string;
      lastName: string;
      initials?: string | null;
      picture?: string | null;
      latestLoginAt?: string | null;
    };
  };
};

export const SlugFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "slugFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Slug" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initials" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "picture" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestLoginAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlSlugFieldsFragment, unknown>;
