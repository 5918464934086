import { clsx } from "clsx";

type CircleSize = "small" | "medium" | "large";

const sizes: Record<CircleSize, string> = {
  small: "w-[4rem] h-[4rem]",
  medium: "w-[8rem] h-[8rem]",
  large: "w-[12.8rem] h-[12.8rem]",
};

type ProgressCircleProps = { description?: string; size?: CircleSize };

export const ProgressCircle = ({
  description,
  size = "large",
}: ProgressCircleProps) => (
  <div className="flex flex-col w-full h-full bg-white items-center justify-center z-modal gap-small">
    <div
      data-testid="loading-spinner"
      className={clsx(
        "rounded-full border-solid border-neutral-100 border-t-cyan-540 border-8 animate-spin",
        sizes[size],
      )}
    />
    {description && (
      <p className="text-caption text-neutral-850 font-semibold">
        {description}
      </p>
    )}
  </div>
);
