/**
 * The following customised split function is based on
 * the similar one from https://github.com/blakeembrey/change-case.
 *
 * The only modification here is to reserve apostrophes(').
 */
import { sentenceCase } from "change-case";

const SPLIT_LOWER_UPPER_RE = /([\p{Ll}\d])(\p{Lu})/gu;
const SPLIT_UPPER_UPPER_RE = /(\p{Lu})([\p{Lu}][\p{Ll}])/gu;
const DEFAULT_STRIP_REGEXP = /[^\p{L}\d']/giu;
const SPLIT_REPLACE_VALUE = "$1\0$2";

const splitWords = (input: string): string[] => {
  let result = input.trim();

  result = result
    .replace(SPLIT_LOWER_UPPER_RE, SPLIT_REPLACE_VALUE)
    .replace(SPLIT_UPPER_UPPER_RE, SPLIT_REPLACE_VALUE);

  result = result.replace(DEFAULT_STRIP_REGEXP, "\0");

  let start = 0;
  let end = result.length;

  while (result.charAt(start) === "\0") start += 1;
  if (start === end) return [];

  while (result.charAt(end - 1) === "\0") end -= 1;

  return result.slice(start, end).split(/\0/g);
};

export const toSentenceCase = (input: string): string =>
  sentenceCase(input, { split: splitWords });
