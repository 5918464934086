import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";

const UserDebug = () => {
  const { context } = useSmokeballApp();

  return (
    <dl>
      <dt>User</dt>
      <dd>{context?.userId}</dd>
      <dt>Team</dt>
      <dd>{context?.firmId}</dd>
    </dl>
  );
};

export { UserDebug };
