import { useState } from "react";

import { getMatterTypeRepresentatives } from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { Matter, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { mapEntries } from "@smart/itops-utils-basic";
import {
  convertToResponsesObject,
  useLoadFormForDisplay,
} from "@smart/manage-hooks-dom";

import { PreviewContainer } from "./preview-container";

export type PreviewProps = {
  formUri?: string;
  submissionUri: string;
  matter: Matter;
  matchedMatterType?: { id: string; name: string; location: string };
  recipientContactId?: string;
  className?: string;
};

export const Preview = ({
  formUri,
  submissionUri,
  matter,
  matchedMatterType,
  recipientContactId,
  className,
}: PreviewProps) => {
  const { layouts, roles, contacts, relationships } = useSmokeballApp();
  const previewProps = useLoadFormForDisplay({ formUri });
  const isFormCategoryDifferent =
    !!matter &&
    !!previewProps.form.category &&
    matter.isLead !== (previewProps.form.category === "lead");

  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<Record<string, any>>({});
  useAsync(async () => {
    if (!layouts || !contacts || !roles || !relationships || !previewProps.form)
      return;

    setLoading(true);

    const formMatterTypeRepresentatives = getMatterTypeRepresentatives(
      previewProps.matterTypes,
    );

    setResponses(
      await convertToResponsesObject({
        isFormCategoryDifferent,
        formMatterTypeRepresentatives,
        recipientContactId,
        layouts,
        contacts,
        roles,
        relationships,
        matterType: previewProps.matterTypes.find(
          (t) => t.id === matter.matterTypeId,
        ) || {
          __typename: "MatterType",
          id: matchedMatterType?.id || "",
          name: matchedMatterType?.name || "",
          category: "",
          location: matchedMatterType?.location || "",
          source: "",
          representativeOptions: [],
        },
        matter,
        sections: previewProps.sectionValues,
        fields: previewProps.fields,
        groups: previewProps.groups,
      }),
    );

    setLoading(false);
  }, [
    layouts,
    contacts,
    roles,
    relationships,
    previewProps.exists,
    previewProps.groups.length,
    previewProps.fields.length,
    recipientContactId,
  ]);

  return (
    <PreviewContainer
      className={className}
      loading={loading}
      preview={previewProps}
      form={formUri ? { uri: formUri } : undefined}
      submission={{
        uri: submissionUri,
        responses: mapEntries(responses, (value) => ({ value })),
      }}
    />
  );
};
