import { useState } from "react";

import { roleProviderId } from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { isNotNullOrUndefined, specialChars } from "@smart/itops-utils-basic";
import { CachedGql } from "@smart/manage-cached-gql-operations-dom";
import {
  useQueryMatterFields,
  useQueryMatterLayouts,
  useQueryMatterTypeSearch,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { ImportedForm } from "./schema";

type MatterTypeResult = {
  display: string;
  error: boolean;
  matterTypes?: CachedGql.MatterTypeFieldsFragment[];
};

type FieldResult = {
  display: string;
  error: boolean;
  layout?: CachedGql.MatterLayoutFieldsFragment;
  field?: Omit<CachedGql.MatterFieldFieldsFragment, "__typename">;
};

export const useImportProcess = ({
  importedForm,
}: {
  importedForm: ImportedForm;
}) => {
  const { user } = useUser();
  const [matterTypeResult, setMatterTypeResult] = useState<MatterTypeResult>();
  const [fieldResult, setFieldResult] =
    useState<Record<string, FieldResult | undefined>>();

  const queryMatterTypes = useQueryMatterTypeSearch();
  const queryMatterLayouts = useQueryMatterLayouts();
  const queryMatterFields = useQueryMatterFields();

  useAsync(async () => {
    if (!importedForm.form.matterTypes?.length) {
      setMatterTypeResult({ display: specialChars.enDash, error: false });
      setFieldResult({});
      return;
    }

    const matterTypes = (
      await Promise.all(
        importedForm.form.matterTypes.map(async (m) => {
          const search = await queryMatterTypes({
            type: importedForm.form.category,
            search: m.name,
            category: m.category,
            location: m.location,
          });
          return search.find(
            (t) => t.name.toLocaleLowerCase() === m.name.toLocaleLowerCase(),
          );
        }),
      )
    ).filter(isNotNullOrUndefined);

    setMatterTypeResult({
      error: !matterTypes.length,
      display: matterTypes.length
        ? matterTypes
            .map((m) => `${m.name} (${m.category} | ${m.location})`)
            .join(", ")
        : `No Matching Matter Types Found - no layouts will be linked`,
      matterTypes,
    });

    if (!matterTypes.length) {
      setFieldResult({});
      return;
    }

    const layouts = await queryMatterLayouts({
      matterTypeIds: matterTypes.map((m) => m.id),
    });
    if (!layouts) {
      setFieldResult({});
      return;
    }

    const processedFields = [];
    const processField = async (
      field: ImportedForm["fields"][number],
    ): Promise<[string, FieldResult]> => {
      if (!field.layout)
        return [field.uri, { error: false, display: specialChars.enDash }];

      const fieldLayout = layouts.find(
        (l) =>
          l.id === field.layout?.id && l.providerId === field.layout.providerId,
      );
      if (!fieldLayout)
        return [
          field.uri,
          {
            error: true,
            display: `Layout "${field.layout.name}" not found - field won't be linked`,
          },
        ];

      if (!field.field)
        return [
          field.uri,
          {
            error: false,
            display: `Layout "${fieldLayout.name}"`,
            layout: fieldLayout,
          },
        ];

      const fieldsResult = await queryMatterFields({
        matterLayoutId: fieldLayout.id,
        providerId: fieldLayout.providerId,
        teamUri: user?.teamUri || "",
      });
      const group = importedForm.groups.find((g) => g.uri === field.groupUri);
      let fieldField: FieldResult["field"] | undefined;

      if (group?.field?.type === "Role") {
        const roleFieldsResult = await queryMatterFields({
          matterLayoutId: "placeholder",
          providerId: roleProviderId,
          teamUri: user?.teamUri || "",
        });
        const isMatch = roleFieldsResult.some(
          ({ name }) => field.field?.name === name,
        );
        fieldField = isMatch ? field.field : undefined;
      } else {
        fieldField = fieldsResult.find((f) => f.name === field.field?.name);
      }

      if (!fieldField)
        return [
          field.uri,
          {
            error: true,
            display: `Layout "${fieldLayout.name}" - Field "${field.field.name}" not found`,
            layout: fieldLayout,
          },
        ];

      return [
        field.uri,
        {
          error: false,
          display: `Layout "${fieldLayout.name}" - Field "${fieldField.name}"`,
          layout: fieldLayout,
          field: fieldField,
        },
      ];
    };

    for (const field of importedForm.fields) {
      processedFields.push(await processField(field));
    }

    setFieldResult(Object.fromEntries(processedFields));
  }, [importedForm]);

  return {
    matterTypeResult,
    fieldResult,
  };
};
