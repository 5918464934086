import { Skeleton } from "../skeleton";
import { Tooltip } from "../tooltip";

export type MultipleTextLabelProps = {
  texts: string[];
  loading?: boolean;
  placeholder?: string;
  maxVisibleItems?: number;
};

export const MultipleTextLabel = ({
  texts,
  loading,
  placeholder,
  maxVisibleItems = 1,
}: MultipleTextLabelProps) => {
  const sorted = [...new Set(texts)].sort();
  const tooltip = sorted.join(", ");
  const visibleItems = sorted.splice(0, maxVisibleItems);

  return loading ? (
    <div className="relative">
      <div>Loading {placeholder || "items"}...</div>
      <div className="absolute inset-0 rounded">
        <Skeleton />
      </div>
    </div>
  ) : (
    <Tooltip
      text={sorted.length ? tooltip : undefined}
      placement="bottom-start"
      offsetBy={4}
    >
      <span className="flex items-center">
        <span className="overflow-hidden whitespace-nowrap text-ellipsis">
          {visibleItems.join(", ")}
        </span>
        {sorted.length ? (
          <span className="flex justify-center items-center font-semibold ml-[0.5rem] px-[0.5rem] border border-neutral-400 rounded-[0.4rem]">
            +{sorted.length}
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
};
