import { BeforeCapture, DropResult } from "@hello-pangea/dnd";
import { useRef, useState } from "react";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { roleProviderId } from "@smart/bridge-smokeball-basic";
import { FieldNS, GroupNS } from "@smart/bridge-types-basic";
import {
  rankBetween,
  removeEmptyValues,
  removeKeys,
} from "@smart/itops-utils-basic";
import { Gql } from "@smart/manage-gql-operations-dom";

import {
  fieldComponentNames,
  fieldComponentsDroppableId,
  groupFieldsDroppableIdPrefix,
  sectionItemContainerDroppablePrefix,
  sectionNavigationDroppableId,
} from "../constants";
import { useUpdateData } from "../hooks";
import { MappedFieldsOptions } from "../questions/mapped-fields-modal";
import {
  DropAction,
  GqlField,
  GqlFieldValues,
  GqlGroup,
  GqlGroupValues,
  GqlGroupWithFieldValues,
  GqlSectionItem,
  QuestionEditMode,
} from "../types";
import {
  extractDroppableFieldPosition,
  isFieldSeparatorDroppable,
  isGroupSeparatorDroppable,
} from "../utils";
import { getOrderedSectionItems } from "../utils/section-items";

export const useSectionItemOrder = ({
  sectionFields,
  sectionItems,
  addField,
  addGroup,
  changeFieldOrder,
  changeGroupOrder,
  updateFieldGroup,
  fields,
  groups,
  mergeUpdateField,
  updateGroup,
  droppedOnSectionUri,
  setDroppedOnSectionUri,
}: {
  sectionFields: GqlField[] | undefined;
  sectionItems: GqlSectionItem[];
  addField: ReturnType<typeof useUpdateData>["addField"];
  addGroup: ReturnType<typeof useUpdateData>["addGroup"];
  changeFieldOrder: ReturnType<typeof useUpdateData>["changeFieldOrder"];
  changeGroupOrder: ReturnType<typeof useUpdateData>["changeGroupOrder"];
  updateFieldGroup: ReturnType<typeof useUpdateData>["updateFieldGroup"];
  mergeUpdateField: ReturnType<typeof useUpdateData>["mergeUpdateField"];
  updateGroup: ReturnType<typeof useUpdateData>["updateGroup"];
  droppedOnSectionUri: string | undefined;
  setDroppedOnSectionUri: (sectionUri: string | undefined) => void;
  fields?: GqlField[] | null;
  groups?: GqlGroup[] | null;
}) => {
  const [mappedFieldsOptions, setMappedFieldsOptions] =
    useState<MappedFieldsOptions>();
  const [questionEditMode, setQuestionEditMode] =
    useState<QuestionEditMode>("none");
  const [pendingDropAction, setPendingDropAction] = useState<DropAction>();
  const refDropResult = useRef<DropResult>();

  const handleFieldDroppedOnGroup = async ({
    groupUri,
    type,
    beforePosition,
    afterPosition,
  }: {
    groupUri: string;
    type: FieldComponentType;
    beforePosition?: string;
    afterPosition?: string;
  }) => {
    const group = sectionItems.find((item) => item.uri === groupUri);
    const groupMappedField = sectionFields?.find(
      (sf) =>
        sf.values.groupUri === groupUri && sf.values.layout && sf.values.field,
    );
    const layout =
      group?.layout || groupMappedField?.values.layout || undefined;
    const groupPrefix = group?.field?.details?.split("/") || [];
    const groupFieldPrefix =
      groupMappedField?.values.field?.details?.split("/") || [];
    const prefix = groupPrefix.length ? groupPrefix : groupFieldPrefix;
    const groupPathComponents =
      group?.layout?.providerId === roleProviderId
        ? groupPrefix.map((path) => ({
            type: "path" as const,
            name: path,
            displayName: path,
          }))
        : [];

    const presetComponents =
      group?.layout && group?.field
        ? [
            ...groupPathComponents,
            {
              type: "role" as const,
              name: group.field.name,
              displayName: group.field.displayName || group.field.name,
            },
          ]
        : groupFieldPrefix.map((path) => ({
            displayName: path,
            name: path,
            type: "path" as const,
          }));

    const components = layout
      ? [
          {
            displayName: layout.displayName || layout.name,
            name: layout.name,
            type: "layout" as const,
          },
          ...presetComponents,
        ]
      : [];

    switch (type) {
      case "group":
        console.error("cannot place a group within a group");
        break;
      case "mapped":
        setMappedFieldsOptions({
          fixedSelection: layout
            ? {
                layout,
                prefix,
                fields: [],
                components,
              }
            : undefined,
          beforePosition,
          afterPosition,
          destinationGroupUri: groupUri,
        });
        break;
      default:
        await addField({
          type,
          groupUri,
          order: rankBetween({
            after: afterPosition,
            before: beforePosition,
          }),
        });
    }
  };

  const handleFieldDroppedOnForm = async ({
    type,
    beforePosition,
    afterPosition,
  }: {
    type: FieldComponentType;
    beforePosition?: string;
    afterPosition?: string;
  }) => {
    const order = rankBetween({
      after: afterPosition,
      before: beforePosition,
    });

    switch (type) {
      case "group":
        await addGroup(order);
        break;
      case "mapped":
        setMappedFieldsOptions({
          beforePosition,
          afterPosition,
        });
        break;
      default:
        await addField({ type, order });
    }
  };

  const getPositions = (items: { order: string }[], toPosition: number) => {
    const afterPosition =
      toPosition > 0 ? items[toPosition - 1]?.order : undefined;
    const beforePosition =
      toPosition < items.length ? items[toPosition]?.order : undefined;

    return { afterPosition, beforePosition };
  };

  const removeDroppableIdPrefix = (prefix: string, droppableId: string) =>
    droppableId.startsWith(prefix) ? droppableId.replace(`${prefix}-`, "") : "";

  const handleAddItem = async (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (!destination) return;

    const toPosition = extractDroppableFieldPosition(destination.droppableId);
    if (toPosition === -1) return;

    const fieldComponentType = draggableId
      .split("-")
      .pop() as FieldComponentType;
    if (!Object.keys(fieldComponentNames).includes(fieldComponentType)) return;

    if (
      source.droppableId === fieldComponentsDroppableId &&
      isGroupSeparatorDroppable(destination.droppableId)
    ) {
      const groupUri = destination.droppableId.split("|")[0];
      const group = sectionItems.find(
        (i) => i.uri === groupUri,
      ) as GqlGroupWithFieldValues;

      const { afterPosition, beforePosition } = getPositions(
        group.fields || [],
        toPosition,
      );
      await handleFieldDroppedOnGroup({
        groupUri,
        type: fieldComponentType,
        beforePosition,
        afterPosition,
      });
    } else if (
      source.droppableId === fieldComponentsDroppableId &&
      isFieldSeparatorDroppable(destination.droppableId)
    ) {
      const { afterPosition, beforePosition } = getPositions(
        sectionItems,
        toPosition,
      );
      await handleFieldDroppedOnForm({
        type: fieldComponentType,
        beforePosition,
        afterPosition,
      });
    }
  };

  const handleChangeFieldSection = async (
    fieldToBeMoved: GqlFieldValues,
    newSectionUri: string,
    newOrder: {
      afterPosition: string | undefined;
      beforePosition: string | undefined;
    },
  ) =>
    mergeUpdateField({
      current: fieldToBeMoved,
      incoming: {
        sectionUri: newSectionUri,
        order: rankBetween({
          after: newOrder.afterPosition,
          before: newOrder.beforePosition,
        }),
      },
    });

  const handleChangeGroupSection = async (
    groupToBeMoved: GqlGroupValues,
    newSectionUri: string,
    newOrder: {
      afterPosition: string | undefined;
      beforePosition: string | undefined;
    },
  ) => {
    const {
      uri,
      formUri,
      hint,
      label,
      type,
      layout,
      field,
      allowedRepeatable,
      description,
      maxRepeat,
      minRepeat,
      repeatable,
      templateType,
    } = groupToBeMoved;

    await updateGroup({
      variables: {
        uri,
        order: rankBetween({
          after: newOrder.afterPosition,
          before: newOrder.beforePosition,
        }),
        formUri,
        sectionUri: newSectionUri,
        fields: {
          hint,
          type,
          label,
          layout: layout
            ? (removeEmptyValues(
                removeKeys(layout, ["__typename", "displayName"]),
              ) as Gql.UpdateFieldMutationVariables["fields"]["layout"])
            : undefined,
          field: field ? removeKeys(field, ["__typename"]) : undefined,
          links: undefined,
          allowedRepeatable: allowedRepeatable || undefined,
          description: description || undefined,
          maxRepeat: maxRepeat || undefined,
          minRepeat: minRepeat || undefined,
          repeatable: repeatable || undefined,
          templateType: templateType || undefined,
        },
      },
    }).catch(console.error);
  };

  const onItemDroppedInSection = async (draggableId: string) => {
    if (!sectionFields || !droppedOnSectionUri || !sectionItems) return;

    const newOrder = getPositions(
      getOrderedSectionItems({
        sectionUri: droppedOnSectionUri,
        fields: fields || [],
        groups: groups || [],
      }),
      0,
    );

    if (GroupNS.isUriMatch(draggableId)) {
      const group = groups?.find((g) => g.uri === draggableId);
      if (group)
        await handleChangeGroupSection(
          group.values,
          droppedOnSectionUri,
          newOrder,
        );
    } else if (FieldNS.isUriMatch(draggableId)) {
      const field = sectionFields.find((f) => f.uri === draggableId);
      if (field)
        await handleChangeFieldSection(
          field.values,
          droppedOnSectionUri,
          newOrder,
        );
    }
    setDroppedOnSectionUri(undefined);
  };

  const getOrder = (
    destinationIndex: number,
    items: { order: string }[] | undefined | null,
  ) =>
    rankBetween({
      after: items?.[destinationIndex - 1]?.order,
      before: items?.[destinationIndex]?.order,
    });

  const isMappedField = (field: GqlField) =>
    !!field.values.layout && !!field.values.field;

  const handleChangingSectionItemOrder = async (dropResult: DropResult) => {
    const { source, destination } = dropResult;
    if (!destination) return;

    const uri = sectionItems[source.index]?.uri;
    const order = getOrder(destination.index, sectionItems);

    await (GroupNS.isUriMatch(uri)
      ? changeGroupOrder(uri, order)
      : changeFieldOrder(uri, order));
  };

  const handleChangingGroupFieldOrder = async (dropResult: DropResult) => {
    const { destination, source, draggableId } = dropResult;
    if (!destination) return;

    const field = sectionFields?.find((f) => f.uri === draggableId);
    const groupFields = (
      sectionItems?.find(
        (g) => g.uri === field?.values.groupUri,
      ) as GqlGroupWithFieldValues
    ).fields;

    /**
     * Fields in a group do not belong to any Droppables of type GROUP
     * so that number of Draggable items are equal to number of fields
     * whereas in a section, each field is a droppable of type GROUP
     * and the section itself is ALSO a droppable of type GROUP which makes
     * number of draggable items = number of fields + 1 (for section).
     * Hence, destination's index of a section's field is different from that
     * of a group's field. As a result, it has to re-adjust the destination's index.
     */
    const destinationIndex =
      source.index < destination.index
        ? destination.index + 1
        : destination.index;
    await changeFieldOrder(field?.uri, getOrder(destinationIndex, groupFields));
  };

  const handleChangingFieldGroup = async (
    dropResult: DropResult,
    shouldRemoveMappedLayout?: boolean,
  ) => {
    const { destination, draggableId } = dropResult;
    if (!destination) return;

    const field = sectionFields?.find((f) => f.uri === draggableId);
    if (!field) return;

    const groupUri = removeDroppableIdPrefix(
      groupFieldsDroppableIdPrefix,
      destination.droppableId,
    );
    if (!GroupNS.isUriMatch(groupUri)) return;

    const group = sectionItems?.find(
      (g) => g.uri === groupUri,
    ) as GqlGroupWithFieldValues;

    if (
      !shouldRemoveMappedLayout &&
      isMappedField(field) &&
      (group.type !== "custom" || group.repeatable)
    ) {
      setPendingDropAction("MOVE_FIELD_INTO_GROUP");
      refDropResult.current = dropResult;
    } else {
      await updateFieldGroup(
        field.uri,
        groupUri,
        getOrder(destination.index, group.fields),
        shouldRemoveMappedLayout,
      );
    }
  };

  const handleMovingGroupFieldToSection = async (
    dropResult: DropResult,
    shouldRemoveMappedLayout?: boolean,
  ) => {
    const { destination, draggableId } = dropResult;
    if (!destination) return;

    const field = sectionFields?.find((f) => f.uri === draggableId);
    if (!field) return;

    const group = sectionItems?.find(
      (g) => g.uri === field.values.groupUri,
    ) as GqlGroupWithFieldValues;

    if (
      !shouldRemoveMappedLayout &&
      isMappedField(field) &&
      (group.type !== "custom" || group.repeatable)
    ) {
      setPendingDropAction("MOVE_FIELD_OUT_OF_GROUP");
      refDropResult.current = dropResult;
    } else {
      await updateFieldGroup(
        field.uri,
        undefined,
        getOrder(destination.index, sectionItems),
        shouldRemoveMappedLayout,
      );
    }
  };

  const getAction = (dropResult: DropResult): DropAction | undefined => {
    const { destination, source } = dropResult;

    if (
      !sectionFields ||
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    )
      return undefined;

    if (
      isFieldSeparatorDroppable(destination.droppableId) ||
      isGroupSeparatorDroppable(destination.droppableId)
    )
      return "ADD_FIELD";

    const isGroupItem = ({ droppableId }: { droppableId: string }) =>
      droppableId.startsWith(groupFieldsDroppableIdPrefix);

    const isSectionItem = ({ droppableId }: { droppableId: string }) => {
      const extractedDroppableId = removeDroppableIdPrefix(
        sectionItemContainerDroppablePrefix,
        droppableId,
      );
      return (
        FieldNS.isUriMatch(extractedDroppableId) ||
        GroupNS.isUriMatch(extractedDroppableId)
      );
    };

    if (isSectionItem(source) && isSectionItem(destination))
      return "CHANGE_SECTION_ITEM_ORDER";

    if (isGroupItem(source) && isGroupItem(destination)) {
      return destination.droppableId === source.droppableId
        ? "CHANGE_GROUP_FIELD_ORDER"
        : "MOVE_FIELD_INTO_GROUP";
    }

    if (isGroupItem(source) && isSectionItem(destination))
      return "MOVE_FIELD_OUT_OF_GROUP";

    if (isSectionItem(source) && isGroupItem(destination))
      return "MOVE_FIELD_INTO_GROUP";

    if (
      isSectionItem(source) &&
      destination.droppableId === sectionNavigationDroppableId &&
      !!droppedOnSectionUri
    ) {
      return "MOVE_ITEM_TO_SECTION";
    }

    return undefined;
  };

  const onDragEnd = async (result: DropResult) => {
    setQuestionEditMode("none");

    switch (getAction(result)) {
      case "ADD_FIELD":
        await handleAddItem(result);
        break;
      case "CHANGE_SECTION_ITEM_ORDER":
        await handleChangingSectionItemOrder(result);
        break;
      case "CHANGE_GROUP_FIELD_ORDER":
        await handleChangingGroupFieldOrder(result);
        break;
      case "MOVE_FIELD_INTO_GROUP":
        await handleChangingFieldGroup(result);
        break;
      case "MOVE_FIELD_OUT_OF_GROUP":
        await handleMovingGroupFieldToSection(result);
        break;
      case "MOVE_ITEM_TO_SECTION":
        await onItemDroppedInSection(result.draggableId);
        break;
      default:
        break;
    }
  };

  const cancelDropAction = () => setPendingDropAction(undefined);

  const confirmDropAction = async () => {
    setPendingDropAction(undefined);
    if (!refDropResult.current) return;

    switch (pendingDropAction) {
      case "MOVE_FIELD_INTO_GROUP":
        await handleChangingFieldGroup(refDropResult.current, true);
        break;
      case "MOVE_FIELD_OUT_OF_GROUP":
        await handleMovingGroupFieldToSection(refDropResult.current, true);
        break;
      default:
        break;
    }
  };

  const onBeforeCapture = (result: BeforeCapture) => {
    const { draggableId } = result;
    if (draggableId.startsWith("field-component")) {
      setQuestionEditMode("creating");
    } else if (
      draggableId.match(FieldNS.uriRegex) ||
      draggableId.match(GroupNS.uriRegex)
    ) {
      setQuestionEditMode("reordering");
    }
  };

  return {
    onDragEnd,
    onBeforeCapture,
    mappedFieldsOptions,
    setMappedFieldsOptions,
    questionEditMode,
    pendingDropAction,
    cancelDropAction,
    confirmDropAction,
  };
};
