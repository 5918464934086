/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUploadFilesMutationVariables = Types.Exact<{
  fileNames:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
  fieldUri: Types.Scalars["ID"]["input"];
  submissionUri: Types.Scalars["ID"]["input"];
  formUri: Types.Scalars["ID"]["input"];
}>;

export type GqlUploadFilesMutation = {
  __typename: "Mutation";
  uploadFiles: Array<{
    __typename: "File";
    key: string;
    uploadUrl: string;
    downloadUrl: string;
  }>;
};

export const UploadFilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "uploadFiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fileNames" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fieldUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "submissionUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "formUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileNames" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fileNames" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fieldUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fieldUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "submissionUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "submissionUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "formUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "formUri" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUploadFilesMutation,
  GqlUploadFilesMutationVariables
>;
