import { Input, Label, updateFormFactory } from "@smart/itops-components-dom";
import { useUpdateSection } from "@smart/manage-gql-client-dom";

import { SectionLinks } from "../links";
import { sectionSchema } from "../shared-schemas";

const EditSection = updateFormFactory({
  factoryProps: {
    mutation: useUpdateSection,
    schema: sectionSchema,
    converter: (input) => ({
      ...input,
      links: input.links || undefined,
    }),
  },
  render: ({
    form: {
      register,
      formState: { errors },
    },
    locators,
    result: { loading },
    mode,
  }) => (
    <>
      <Label
        name="title"
        text="Title"
        error={errors.title?.message}
        loading={loading}
      >
        <Input {...register("title")} disabled={mode === "view"} />
      </Label>
      <SectionLinks mode={mode} formUri={locators.formUri} uri={locators.uri} />
    </>
  ),
});

export { EditSection };
