import { form } from "./form";
import { slug } from "./slug";
import { submission } from "./submission";
import { team } from "./team";
import { tracker } from "./tracker";
import { user } from "./user";
import { RuleEvaluators } from "../types";

export const rules: RuleEvaluators = {
  form,
  slug,
  submission,
  team,
  tracker,
  user,
};
