import styled from "@emotion/styled";

import { linkConditionValue } from "@smart/bridge-types-basic";

import { GqlField, GqlFieldType, GqlFieldValues, GqlSection } from "./types";

export const LinkContainer = styled.div`
  display: flex;
  flex-flow: row;
  padding-top: 0.8rem;

  input,
  select {
    margin-right: 1rem;
    border-bottom: 1px dotted
      ${(props) => props.theme.palette.foreground.accent};
    margin-bottom: 0.2rem;
  }

  .field-label {
    flex: 3;
  }

  .condition {
    flex: 1.5;
  }

  .condition-value {
    flex: 2;
  }
`;

export const LinkValueContainer = styled.div`
  display: flex;
  position: relative;

  .error {
    position: absolute;
    top: 0;
  }

  .warning-text {
    position: absolute;
    top: -0.4rem;
    left: 1rem;
    color: ${(props) => props.theme.palette.danger.base};
  }
`;

export const getApplicableConditions = (
  fieldType: GqlFieldType,
): readonly (typeof linkConditionValue)[number][] => {
  switch (fieldType) {
    case "checkbox":
      return ["equalTo", "in", "contain"];
    case "choice":
      return ["equalTo", "in"];
    case "text":
      return ["equalTo", "contain"];
    case "date":
      return [
        "equalTo",
        "greaterThan",
        "greaterThanOrEqualTo",
        "lessThan",
        "lessThanOrEqualTo",
      ];
    case "number":
      return [
        "equalTo",
        "notEqualTo",
        "greaterThan",
        "greaterThanOrEqualTo",
        "lessThan",
        "lessThanOrEqualTo",
      ];
    default:
      return ["equalTo"];
  }
};

export const getInitialValue = (
  fieldValues: GqlFieldValues | undefined,
): string => {
  switch (fieldValues?.type) {
    case "choice":
    case "yesNo":
      return fieldValues.options.length ? fieldValues.options[0].value : "";
    case "checkbox":
      return JSON.stringify([]);
    default:
      return "";
  }
};

export const getFieldValuesByUri = (
  fieldUri: string,
  fields: GqlField[] | undefined,
) => fields?.find((f) => f.uri === fieldUri)?.values;

export const getSectionOrder = (
  sectionUri: string | undefined,
  sections: GqlSection[] | undefined,
) =>
  sectionUri ? sections?.find((s) => s.uri === sectionUri)?.values.order : "";

export const MAX_FIELD_LINKS = 4;
