/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlAddressPredictionFieldsFragment = {
  __typename: "AddressPrediction";
  placeId: string;
  description: string;
  mainText: string;
  secondaryText: string;
};

export const AddressPredictionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "addressPredictionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AddressPrediction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "placeId" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "mainText" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryText" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlAddressPredictionFieldsFragment, unknown>;
