/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlSendMatterQuestionnaireMutationVariables = Types.Exact<{
  matterId: Types.Scalars["String"]["input"];
  clientName: Types.Scalars["String"]["input"];
  party: Types.GqlMatterQuestionnaireParty;
  email: Types.Scalars["String"]["input"];
  questionnaireType: Types.GqlQuestionnaireType;
  reset?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type GqlSendMatterQuestionnaireMutation = {
  __typename: "Mutation";
  sendMatterQuestionnaire: {
    __typename: "SendMatterQuestionnaireResponse";
    link: string;
    embedUrl: string;
  };
};

export const SendMatterQuestionnaireDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendMatterQuestionnaire" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "party" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MatterQuestionnaireParty" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "questionnaireType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "QuestionnaireType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendMatterQuestionnaire" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "party" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "party" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "questionnaireType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "questionnaireType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "reset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reset" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
                { kind: "Field", name: { kind: "Name", value: "embedUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlSendMatterQuestionnaireMutation,
  GqlSendMatterQuestionnaireMutationVariables
>;
