import { ReactNode } from "react";

import {
  fieldFallbackLabel,
  GroupItem,
} from "@smart/bridge-intake-components-dom";
import { Displayer } from "@smart/itops-sb-design-system-dom";
import { specialChars } from "@smart/itops-utils-basic";

export type SummaryGroupProps = {
  group: GroupItem;
  index: number;
  children?: ReactNode;
};

export const SummaryGroup = ({ group, index, children }: SummaryGroupProps) => (
  <fieldset className="bg-neutral-20 rounded border border-neutral-100 px-[2rem] py-[1rem]">
    <legend className="text-ui font-semibold ml-[-0.6rem] px-[1rem]">
      <Displayer
        paragraphTag="span"
        value={group.label || fieldFallbackLabel.group}
        hintElement={
          group.repeatable && (
            <span>
              {specialChars.nbsp}
              {index + 1}
            </span>
          )
        }
      />
    </legend>
    {children}
  </fieldset>
);
