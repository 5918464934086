import {
  GqlStatusOverlay,
  GqlStatusOverlayProps,
  Pill,
  PillRow,
} from "@smart/itops-components-dom";

import { formTabDisplay, FormTabId, formTabs, formTabTitles } from "./tabs";
import { FormDisplayMode, GqlForm } from "./types";

type FormViewProps = {
  mode: FormDisplayMode;
  form: GqlForm | null | undefined;
  result: GqlStatusOverlayProps;
  selectedTab: FormTabId<FormDisplayMode>;
  selectTab: (tab: FormTabId<FormDisplayMode>) => void;
};

const FormView = ({
  mode,
  form,
  result,
  selectedTab,
  selectTab,
}: FormViewProps) => {
  const FormTab = formTabDisplay[selectedTab];

  return (
    <>
      <PillRow>
        {formTabs[mode].map((t) => (
          <Pill
            key={t}
            text={formTabTitles[t]}
            selected={selectedTab === t}
            onClick={() => selectTab(t)}
          />
        ))}
      </PillRow>
      {form && <FormTab mode={mode} form={form} />}
      <GqlStatusOverlay {...result} />
    </>
  );
};

export { FormView, FormViewProps };
