/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlResponseFieldsFragment = {
  __typename: "Response";
  uri: string;
  fieldUri: string;
  value: string;
  updatedAt: string;
  operationId: string;
  syncStatus?: Types.GqlSyncStatus | null;
  existingItemIds?: string | null;
};

export const ResponseFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "responseFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Response" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "fieldUri" } },
          { kind: "Field", name: { kind: "Name", value: "value" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
          { kind: "Field", name: { kind: "Name", value: "existingItemIds" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlResponseFieldsFragment, unknown>;
