import {
  OrderedSection,
  renderOrderedItems,
} from "@smart/bridge-intake-components-dom";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import { SubmissionHistory } from "./submission-history";
import {
  SummaryField,
  SummaryGroup,
  SummarySection,
} from "./summary-components";
import { GqlTracker } from "../view/helpers";

type ViewResponsesProps = {
  trackersToDisplay: (GqlTracker | null)[];
  orderedSections: OrderedSection[];
  responses: Record<string, any>;
  matterSubmission: MatterSubmission | undefined;
  loading: boolean;
};

export const ViewResponses = ({
  trackersToDisplay,
  matterSubmission,
  orderedSections,
  responses,
  loading,
}: ViewResponsesProps) =>
  !matterSubmission ||
  matterSubmission.formUri === familyProQuestionnaireFormUri ||
  loading ? null : (
    <div className="flex flex-1 overflow-hidden">
      <div className="relative p-[5.6rem] flex-1 overflow-y-auto bg-neutral-50">
        <div className="max-w-[84.9rem] min-h-full mx-auto my-0">
          <div className="p-[3.2rem] rounded border border-neutral-100 bg-white">
            {orderedSections.map(({ orderedItems, section }) => (
              <SummarySection key={section.uri} section={section}>
                <div>
                  {renderOrderedItems({
                    orderedItems,
                    responses,
                    renderGroup: (props) => <SummaryGroup {...props} />,
                    renderField: (props) => <SummaryField {...props} />,
                  })}
                </div>
              </SummarySection>
            ))}
          </div>
        </div>
      </div>
      <SubmissionHistory trackers={trackersToDisplay} />
    </div>
  );
