import { useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";

import { layoutFieldTypeMap } from "@smart/bridge-smokeball-basic";
import { fieldTypeLabel, fieldTypeValue } from "@smart/bridge-types-basic";
import {
  Input,
  Label,
  Select,
  TypeaheadField,
  updateFormFactory,
} from "@smart/itops-components-dom";
import { FormEditor } from "@smart/itops-editor-dom";
import { useChangedEffect } from "@smart/itops-hooks-dom";
import {
  useLoadForm,
  useLoadMatterFields,
  useLoadMatterLayouts,
  useUpdateField,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { CopyField } from "./copy-field";
import { FieldForm } from "./form";
import { FieldOptions } from "./options";
import { FieldLinks } from "../links";
import { fieldSchema } from "../shared-schemas";

const EditField = updateFormFactory({
  factoryProps: {
    mutation: useUpdateField,
    schema: fieldSchema,
    converter: (input) => ({
      ...input,
      field: input.field
        ? {
            name: input.field.name,
            type: input.field.type,
            possibleValues: input.field.possibleValues || undefined,
          }
        : undefined,
      layout: input.layout
        ? {
            id: input.layout.id,
            providerId: input.layout.providerId,
            name: input.layout.name,
            parentId: input.layout.parentId || undefined,
            parentName: input.layout.parentName || undefined,
            parentProviderId: input.layout.parentProviderId || undefined,
          }
        : undefined,
      links: input.links || undefined,
    }),
  },
  form: FieldForm,
  render: ({ form, mode, locators, result: { loading } }) => {
    const { user } = useUser();
    const {
      control,
      formState: { errors },
      register,
      setValue,
      watch,
    } = form;
    const loaded = useLoadForm({ uri: locators.formUri });
    const options = useFieldArray({ control, name: "options" });
    const selectedType = watch("type");
    const selectedLayout = watch("layout");
    const selectedMatterField = useWatch({ name: "field", control });

    const isTypeWithChoices =
      selectedType === "choice" || selectedType === "checkbox";
    const isYesNo = selectedType === "yesNo";
    const hasPossibleValues = !!selectedMatterField?.possibleValues?.length;
    const isTypeAllowedCopyFrom =
      !isTypeWithChoices && !isYesNo && selectedType !== "info";

    useChangedEffect(() => {
      if (mode !== "edit" || selectedMatterField === undefined) return;

      if (selectedMatterField) {
        const fieldType = layoutFieldTypeMap[
          selectedMatterField!.type
        ].includes(selectedType)
          ? selectedType
          : "text";
        setValue("type", fieldType);
      }

      if (selectedMatterField?.possibleValues?.length) {
        const { possibleValues } = selectedMatterField;
        if (possibleValues && !possibleValues.includes("")) {
          setValue("allowCustomResponse", false);
        }
        options.replace(
          possibleValues
            .filter((value) => value && value.length > 0)
            .map((value) => ({ label: value, value })),
        );
      } else {
        setTimeout(() => options.remove(), 0);
      }
    }, [selectedMatterField?.name]);

    const populateYesNoOptions = () => {
      if (!isYesNo) return;

      const isValidOptions =
        options.fields.length === 2 &&
        ["yes", "no"].every((value) =>
          options.fields.some((option) => option.value === value),
        );
      if (isValidOptions) return;

      setValue("allowCustomResponse", false);
      options.replace([
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]);
    };

    useEffect(() => {
      if (mode !== "edit") return;
      if (!isTypeWithChoices && !isYesNo) {
        options.remove();
      } else if (isYesNo) {
        populateYesNoOptions();
      }
    }, [selectedType]);

    const disabled = mode === "view";
    const getFieldOptionPermissions = () => {
      if (disabled)
        return {
          add: false,
          remove: false,
          changeValue: false,
          changeLabel: false,
          changeOrder: false,
        };
      return isYesNo
        ? { add: false, remove: false, changeValue: false }
        : {
            add: !hasPossibleValues,
            remove: true,
            changeValue: !hasPossibleValues,
          };
    };

    return (
      <>
        <Label
          name="label"
          text="Label"
          error={errors.label?.message}
          loading={loading}
        >
          <Input {...register("label")} disabled={disabled} />
        </Label>
        <Label name="type" text="Type">
          <Select {...register("type")} disabled={disabled}>
            {fieldTypeValue.map((v) => (
              <option key={v} value={v}>
                {fieldTypeLabel[v]}
              </option>
            ))}
          </Select>
        </Label>
        <div className="hint-group">
          <Label name="hint" text="Hint" error={errors.hint?.message}>
            <FormEditor control={control} name="hint" disabled={disabled} />
          </Label>
          <div className="mandatory-custom">
            <Label name="mandatory" text="Mandatory">
              <Input
                {...register("mandatory")}
                type="checkbox"
                disabled={disabled}
              />
            </Label>
            {isTypeWithChoices && (
              <Label name="allowCustomResponse" text="Custom response">
                <Input
                  {...register("allowCustomResponse", {
                    shouldUnregister: true,
                  })}
                  type="checkbox"
                  disabled={
                    disabled ||
                    (selectedMatterField?.possibleValues
                      ? !selectedMatterField.possibleValues.includes("")
                      : false)
                  }
                />
              </Label>
            )}
          </div>
        </div>
        <div className="layout-group">
          {!!loaded.result?.values.matterTypes.length && (
            <Label name="layout" text="Layout">
              <TypeaheadField
                interaction={disabled ? "disabled" : undefined}
                control={control}
                name="layout"
                hook={useLoadMatterLayouts}
                locators={{
                  matterTypeIds: loaded.result.values.matterTypes.map(
                    (m) => m.id,
                  ),
                }}
                input={(s) => s.displayName || ""}
                display={(s) => s.name || ""}
                idKey="id"
                parentidKey="parentId"
                filter={(search) => (item) =>
                  item.name
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
                }
              />
            </Label>
          )}
          {!!selectedLayout && (
            <Label name="field" text="Field">
              <TypeaheadField
                interaction={disabled ? "disabled" : undefined}
                control={control}
                name="field"
                hook={useLoadMatterFields}
                locators={{
                  matterLayoutId: selectedLayout.id,
                  providerId: selectedLayout.providerId,
                  teamUri: user?.teamUri || "",
                  matterTypeIds: loaded.result?.values.matterTypes.map(
                    (t) => t.id,
                  ),
                }}
                input={(s) => s.displayName || ""}
                display={(s) => s.displayName || ""}
                details={(s) => `${s.type} | ${s.details}`}
                idKey="name"
                filter={(search) => (item) =>
                  item.name
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) &&
                  item.type !== "Role"
                }
              />
            </Label>
          )}
        </div>
        {(isTypeWithChoices || isYesNo) && (
          <FieldOptions
            options={options}
            register={register}
            permittedOperations={getFieldOptionPermissions()}
          />
        )}
        <FieldLinks mode={mode} uri={locators.uri} formUri={locators.formUri} />
        {isTypeAllowedCopyFrom && (
          <CopyField
            disabled={disabled}
            control={control}
            fieldType={selectedType}
            formUri={locators.formUri}
            uri={locators.uri}
          />
        )}
      </>
    );
  },
});

export { EditField };
