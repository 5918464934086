import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";

import {
  isIndexedField,
  layoutFieldTypeMap,
  roleProviderId,
} from "@smart/bridge-smokeball-basic";
import {
  FieldNS,
  groupTemplates,
  groupTemplateTypeLabel,
  groupTemplateTypeValue,
  GroupType,
  groupTypeLabel,
  groupTypeValue,
} from "@smart/bridge-types-basic";
import {
  Input,
  Label,
  Select,
  SelectionList,
  TypeaheadField,
  updateFormFactory,
} from "@smart/itops-components-dom";
import { rankBetween } from "@smart/itops-utils-basic";
import {
  useLoadForm,
  useLoadMatterLayouts,
  useLoadMatterFields,
  useUpdateGroupWithFields,
  useLoadRepeatableMatterFields,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { GroupField, groupSchema } from "../shared-schemas";

const CreateGroupModal = updateFormFactory({
  factoryProps: {
    mutation: useUpdateGroupWithFields,
    schema: groupSchema,
    converter: (input) => ({
      ...input,
      templateType: input.templateType || undefined,
      links: input.links || undefined,
      allowedRepeatable:
        input.field?.allowedRepeatable ||
        input.type === "custom" ||
        input.type === "template" ||
        input.layout?.providerId === roleProviderId,
      repeatable: input.field?.allowedRepeatable,
      layout: input.layout
        ? {
            id: input.layout.id,
            providerId: input.layout.providerId,
            name: input.layout.name,
            parentId: input.layout.parentId || undefined,
            parentName: input.layout.parentName || undefined,
            parentProviderId: input.layout.parentProviderId || undefined,
          }
        : undefined,
      field: input.field
        ? {
            name: input.field.name,
            type: input.field.type,
            possibleValues: input.field.possibleValues || undefined,
          }
        : undefined,
      fields:
        input.fields?.map((f) => ({
          ...f,
          layout: f.layout
            ? {
                id: f.layout.id,
                providerId: f.layout.providerId,
                name: f.layout.name,
                parentId: f.layout.parentId || undefined,
                parentName: f.layout.parentName || undefined,
                parentProviderId: f.layout.parentProviderId || undefined,
              }
            : undefined,
          field: f.field
            ? {
                ...f.field,
                possibleValues: f.field.possibleValues || undefined,
              }
            : undefined,
        })) || undefined,
    }),
  },
  render: ({
    locators,
    form: {
      control,
      register,
      watch,
      setValue,
      formState: { errors },
    },
    result: { loading },
  }) => {
    const { user } = useUser();
    const loaded = useLoadForm({ uri: locators.formUri });
    const groupFields = useFieldArray({ control, name: "fields" });
    const selectedType = watch("type");
    const layout = watch("layout");
    const roleField = watch("field");
    const selectedTemplateType = watch("templateType");
    const [availableTypes, setAvailableTypes] = useState<readonly GroupType[]>(
      [],
    );
    const matterTypes = loaded.data?.form?.values.matterTypes.map((t) => ({
      id: t.id,
      name: t.name,
      category: t.category,
      location: t.location,
      source: t.source,
    }));

    const isLayoutType =
      selectedType === "layout" ||
      selectedType === "layoutContact" ||
      selectedType === "layoutRepeatable";

    useEffect(() => {
      const currentTemplate = groupTemplates.find(
        (t) => t.type === selectedTemplateType,
      );

      if (!currentTemplate) {
        groupFields.remove();
      } else {
        const fieldsFromTemplate = currentTemplate.fields.map((f) => ({
          uri: FieldNS.generateUri(),
          label: f.label,
          hint: f.hint,
          type: f.type,
          order: "",
          options: f.options,
          mandatory: f.mandatory,
        }));

        fieldsFromTemplate.reduce((prevOrder, _, index) => {
          const fieldOrder = rankBetween({ after: prevOrder });

          fieldsFromTemplate[index].order = fieldOrder;
          return fieldOrder;
        }, locators.order);

        groupFields.replace(fieldsFromTemplate);
      }
    }, [selectedTemplateType]);

    const onSelectedFieldsChange = (selectedFields: Record<string, any>[]) => {
      const fieldsFromLayoutFields: GroupField[] = [];

      selectedFields.reduce((prevOrder, f) => {
        const fieldOrder = rankBetween({ after: prevOrder });
        const options = f.possibleValues
          ? (f.possibleValues as string[])
              .filter((value) => value.length)
              .map((value) => ({
                label: value,
                value,
              }))
          : [];

        fieldsFromLayoutFields.push({
          uri: FieldNS.generateUri(),
          label: f.displayName,
          hint: "",
          type: layoutFieldTypeMap[f.type]
            ? layoutFieldTypeMap[f.type][0]
            : "text",
          order: fieldOrder,
          options,
          mandatory: false,
          layout,
          field: {
            name: f.name,
            type: f.type,
            possibleValues: f.possibleValues,
          },
        });

        return fieldOrder;
      }, locators.order);

      groupFields.replace(fieldsFromLayoutFields);
    };

    useEffect(() => {
      if (loaded.result?.values.matterTypes.length) {
        setAvailableTypes(groupTypeValue);
        setValue("type", "layout");
      } else {
        setAvailableTypes(groupTypeValue.filter((v) => v !== "layout"));
        setValue("type", "template");
      }
    }, [loaded.result]);

    useEffect(() => {
      if (!isLayoutType) {
        setValue("layout", undefined);
      }
      if (selectedType !== "template") {
        setValue("templateType", undefined);
      }
    }, [selectedType]);

    useEffect(() => {
      if (!layout) {
        groupFields.remove();
      }
    }, [layout]);

    return (
      <>
        <Label
          name="label"
          text="Label"
          error={errors.label?.message}
          loading={loading}
        >
          <Input {...register("label")} autoFocus />
        </Label>
        <Label name="type" text="Type" mandatory>
          <Select {...register("type")}>
            {availableTypes.map((t) => (
              <option key={t} value={t}>
                {groupTypeLabel[t]}
              </option>
            ))}
          </Select>
        </Label>
        {isLayoutType && (
          <Label
            name="layout"
            text="Layout"
            error={errors.layout?.message}
            mandatory
          >
            <TypeaheadField
              control={control}
              name="layout"
              hook={useLoadMatterLayouts}
              locators={{
                matterTypeIds: matterTypes?.map((m) => m.id) || [],
              }}
              input={(s) => s.displayName || ""}
              display={(s) => s.name || ""}
              idKey="id"
              parentidKey="parentId"
              filter={(search) => (item) =>
                item.name
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              }
            />
          </Label>
        )}
        {!!layout && selectedType === "layout" && (
          <Label
            name="fields"
            text="Fields"
            error={errors.fields?.message}
            mandatory
          >
            <SelectionList
              hook={useLoadMatterFields}
              locators={{
                matterLayoutId: layout.id,
                providerId: layout.providerId,
                teamUri: user?.teamUri || "",
                matterTypeIds: matterTypes?.map((m) => m.id),
              }}
              idKey="name"
              columns={[
                { key: "displayName" as never, header: "Display name" },
                { key: "name" as never, header: "Name" },
                { key: "type" as never, header: "Type" },
              ]}
              onChange={onSelectedFieldsChange}
              filter={(item) => item.type !== "Role" && !item.allowedRepeatable}
              noDataText="No fields available for this layout"
            />
          </Label>
        )}
        {!!layout && selectedType === "layoutRepeatable" && (
          <Label
            name="repeatableField"
            text="Repeatable field"
            error={errors.fields?.message}
            mandatory
          >
            <TypeaheadField
              control={control}
              name="field"
              hook={useLoadRepeatableMatterFields}
              locators={{
                matterLayoutId: layout.id,
                providerId: layout.providerId,
              }}
              input={(s) => s.displayName || ""}
              display={(s) => s.displayName || ""}
              details={(s) => `${s.type} | ${s.details}`}
              idKey="name"
              filter={(search) => (item) =>
                item.name
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              }
            />
          </Label>
        )}
        {selectedType === "layoutRepeatable" && !!roleField && !!layout && (
          <Label
            name="fields"
            text="Repeatable fields"
            error={errors.fields?.message}
            mandatory
          >
            <SelectionList
              hook={useLoadMatterFields}
              locators={{
                matterLayoutId: layout.id,
                providerId: layout.providerId,
                teamUri: user?.teamUri || "",
                prefix: (roleField as Record<string, any>).displayName,
                matterTypeIds: matterTypes?.map((m) => m.id),
              }}
              idKey="name"
              columns={[
                { key: "displayName" as never, header: "Display name" },
                { key: "name" as never, header: "Name" },
                { key: "type" as never, header: "Type" },
              ]}
              onChange={onSelectedFieldsChange}
              filter={(item) => item.type !== "Role"}
              noDataText="No fields available for this layout"
            />
          </Label>
        )}
        {!!layout && selectedType === "layoutContact" && (
          <Label
            name="roleField"
            text="Role field"
            error={errors.fields?.message}
            mandatory
          >
            <TypeaheadField
              control={control}
              name="field"
              hook={useLoadMatterFields}
              locators={{
                matterLayoutId: layout.id,
                providerId: layout.providerId,
                teamUri: user?.teamUri || "",
                matterTypeIds: matterTypes?.map((m) => m.id),
              }}
              input={(s) => s.displayName || ""}
              display={(s) => s.displayName || ""}
              details={(s) => `${s.type} | ${s.details}`}
              idKey="name"
              filter={(search) => (item) =>
                item.name
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()) &&
                item.type === "Role" &&
                !isIndexedField(item.name)
              }
            />
          </Label>
        )}
        {selectedType === "layoutContact" && !!roleField && !!layout && (
          <Label
            name="fields"
            text="Role provider fields"
            error={errors.fields?.message}
            mandatory
          >
            <SelectionList
              hook={useLoadMatterFields}
              locators={{
                matterLayoutId: layout.id,
                providerId: roleProviderId,
                teamUri: user?.teamUri || "",
                matterTypeIds: matterTypes?.map((m) => m.id),
              }}
              idKey="name"
              columns={[
                { key: "displayName" as never, header: "Display name" },
                { key: "name" as never, header: "Name" },
                { key: "type" as never, header: "Type" },
              ]}
              onChange={onSelectedFieldsChange}
              noDataText="No fields available for this layout"
            />
          </Label>
        )}
        {selectedType === "template" && (
          <Label name="type" text="Template" mandatory>
            <Select {...register("templateType")}>
              {groupTemplateTypeValue.map((t) => (
                <option key={t} value={t}>
                  {groupTemplateTypeLabel[t]}
                </option>
              ))}
            </Select>
          </Label>
        )}
      </>
    );
  },
});

export { CreateGroupModal };
