import styled from "@emotion/styled";
import { transparentize } from "polished";
import { useSelected } from "slate-react";

import {
  PlaceholderName,
  requiredDisplayTextPlaceholderNames,
} from "@smart/bridge-templates-basic";
import { EditorElement } from "@smart/itops-serialisation-basic";

import { EditorRenderElementProps } from "../../types";

const DisplayWrapper = styled.span`
  padding: 0.2rem;
  background: ${(props) => props.theme.palette.disabled.highlight};
  border-radius: 0.2rem;

  &[aria-selected="true"] {
    box-shadow: 0 0 0 1px
      ${(props) => transparentize(0.6, props.theme.palette.active.accent)};
  }
`;

export const EditorPlaceholder = ({
  attributes,
  children,
  element,
}: EditorRenderElementProps<EditorElement<"placeholder">>) => {
  const selected = useSelected();
  const getReplacingValue = () => {
    if (!element.replacingValue) return undefined;
    return element.casing === "lowercase"
      ? element.replacingValue.toLocaleLowerCase()
      : element.replacingValue;
  };
  const getDisplayText = () => {
    const displayText = element.displayText || element.name;
    const displayTextWithCasing =
      element.casing === "lowercase"
        ? displayText.toLocaleLowerCase()
        : displayText;

    return requiredDisplayTextPlaceholderNames.includes(
      element.name as PlaceholderName,
    ) ? (
      displayTextWithCasing
    ) : (
      <>&#123;{displayTextWithCasing}&#125;</>
    );
  };

  return (
    <span {...attributes} contentEditable={false} title={element.name}>
      {children}
      <DisplayWrapper aria-selected={selected}>
        {getReplacingValue() || getDisplayText()}
      </DisplayWrapper>
    </span>
  );
};
