import { useState } from "react";

import { ButtonRow } from "@smart/itops-components-dom";

import { CreateField } from "./create";
import { CreateLayout } from "./create-layout";

type ItemType = "none" | "field" | "group";

type CreateItemProps = {
  formUri: string;
  sectionUri: string;
  after?: string;
};

const CreateItem = (props: CreateItemProps) => {
  const [itemType, setItemType] = useState<ItemType>("none");

  return (
    <ButtonRow>
      <CreateField
        {...props}
        onCreate={() => setItemType("field")}
        onDone={() => setItemType("none")}
      />
      {itemType === "none" && <CreateLayout {...props} />}
    </ButtonRow>
  );
};

export { CreateItem };
