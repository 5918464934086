/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlSubmissionLinkHashesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GqlSubmissionLinkHashesQuery = {
  __typename: "Query";
  submissionLinkHashes: Array<{
    __typename: "SubmissionLinkHash";
    uri: string;
    operationId: string;
    teamUri: string;
    formUri: string;
    slugUri: string;
    userUri: string;
    notifiedUserUris?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    deleted?: boolean | null;
    analyticsTools?: Array<{
      __typename: "AnalyticsTool";
      type: string;
      trackingId: string;
    }> | null;
  }>;
};

export const SubmissionLinkHashesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "submissionLinkHashes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submissionLinkHashes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "submissionLinkHashFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "submissionLinkHashFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubmissionLinkHash" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "teamUri" } },
          { kind: "Field", name: { kind: "Name", value: "formUri" } },
          { kind: "Field", name: { kind: "Name", value: "slugUri" } },
          { kind: "Field", name: { kind: "Name", value: "userUri" } },
          { kind: "Field", name: { kind: "Name", value: "notifiedUserUris" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "analyticsTools" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "trackingId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlSubmissionLinkHashesQuery,
  GqlSubmissionLinkHashesQueryVariables
>;
