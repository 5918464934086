import styled from "@emotion/styled";
import { position, transparentize } from "polished";

export const Empty = styled.div<{ full?: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: ${(props) => (props.full ? "0" : "2rem")};
  height: ${(props) => (props.full ? "100vh" : "auto")};
  ${(props) => (props.full ? position("absolute", 0) : "")}
  background-color: ${(props) =>
    props.full
      ? transparentize(0.1, props.theme.palette.background.base)
      : "transparent"};

  font-size: ${(props) => props.theme.fontSize.base};

  .empty-icon {
    color: ${(props) => props.theme.palette.foreground.highlight};
    margin-bottom: 2rem;
  }

  .empty-text {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: 0.4rem auto;
    max-width: ${(props) => props.theme.breakPoints.tablet}px;
    width: 100%;
    text-align: center;

    small {
      font-size: 0.75em;
      font-weight: 500;
    }
  }

  .empty-button {
    margin-top: 1.2rem;
  }
`;
