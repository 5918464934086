import { Country, SmokeballAddress } from "@smart/bridge-types-basic";
import { removeEmptyValues, removeKeys } from "@smart/itops-utils-basic";

const throwUnknownCountry = (country: string) => {
  throw new Error(`Unknown country: ${country}`);
};

export const isEmptySBAddress = (address: Record<string, any>) => {
  const remainingKeys = Object.keys(
    removeEmptyValues(
      removeKeys(address, [
        "state",
        "country",
        "poBox",
        "Country",
        "County",
        "StateShort",
      ]),
    ),
  );

  return remainingKeys.length === 0;
};

export const convertRoleAddressFromSB = (
  address: Record<string, any>,
  country: Country,
) => {
  switch (country) {
    case "AU":
      return {
        unit: address.unitNumber,
        unitLabel: address.unitType,
        streetNumber: address.streetNumber,
        streetName: [address.streetName, address.streetType]
          .filter(Boolean)
          .join(" "),
        suburb: address.city,
        state: address.state,
        postalCode: address.zipCode,
        country: address.country,
        formattedAddress: [
          [
            [address.unitNumber, address.streetNumber]
              .filter(Boolean)
              .join("/"),
            address.streetName,
            address.streetType,
          ]
            .filter(Boolean)
            .join(" "),
          [address.city, address.state, address.zipCode]
            .filter(Boolean)
            .join(" "),
        ]
          .filter(Boolean)
          .join(", "),
      };
    case "US":
      return {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        postalCode: address.zipCode,
        county: address.county,
        country: address.country,
        formattedAddress: [
          address.addressLine1,
          address.addressLine2,
          address.county,
          address.city,
          address.state,
          address.zipCode,
          address.country,
        ]
          .filter(Boolean)
          .join(", "),
      };
    case "GB":
      return {
        unit: address.unitNumber,
        unitLabel: address.buildingLevel,
        streetNumber: address.streetNumber,
        streetName: address.streetName,
        city: address.city,
        state: address.state,
        postalCode: address.zipCode,
        locality: address.locality,
        county: address.county,
        country: address.country,
        formattedAddress: [
          [
            [address.unitNumber, address.streetNumber]
              .filter(Boolean)
              .join("/"),
            address.streetName,
          ]
            .filter(Boolean)
            .join(" "),
          address.locality,
          address.city,
          [address.county, address.state, address.zipCode]
            .filter(Boolean)
            .join(" "),
          address.country,
        ]
          .filter(Boolean)
          .join(", "),
      };
    default:
      return throwUnknownCountry(country);
  }
};

export const convertLayoutAddressFromSB = (
  address: Record<string, any>,
  country: Country,
) => {
  switch (country) {
    case "AU":
      return {
        unit: address.UnitNumber,
        unitLabel: address.UnitType,
        streetNumber: address.StreetNumber,
        streetName: [address.StreetName, address.StreetType]
          .filter(Boolean)
          .join(" "),
        suburb: address.SuburbTown,
        postalCode: address.Postcode,
        state: address.StateShort,
        formattedAddress: [
          [
            [address.UnitNumber, address.StreetNumber]
              .filter(Boolean)
              .join("/"),
            address.StreetName,
            address.StreetType,
          ]
            .filter(Boolean)
            .join(" "),
          [address.SuburbTown, address.StateShort, address.Postcode]
            .filter(Boolean)
            .join(" "),
        ]
          .filter(Boolean)
          .join(", "),
      };
    case "US":
      return {
        addressLine1: address.AddressLine1,
        addressLine2: address.AddressLine2,
        city: address.City,
        state: address.State,
        postalCode: address.ZipCode,
        county: address.County,
        formattedAddress: [
          address.AddressLine1,
          address.AddressLine2,
          address.County,
          address.City,
          address.State,
          address.ZipCode,
          address.Country,
        ]
          .filter(Boolean)
          .join(", "),
      };
    case "GB":
      return {
        unit: address.FlatUnit,
        unitLabel: address.PropertyBuildingName,
        streetNumber: address.StreetNumber,
        streetName: address.StreetName,
        locality: address.Locality,
        city: address.TownCity,
        county: address.County,
        postalCode: address.Postcode,
        formattedAddress: [
          [
            [address.FlatUnit, address.StreetNumber].filter(Boolean).join("/"),
            address.StreetName,
          ]
            .filter(Boolean)
            .join(" "),
          address.Locality,
          address.TownCity,
          [address.County, address.Postcode].filter(Boolean).join(" "),
        ]
          .filter(Boolean)
          .join(", "),
      };
    default:
      return throwUnknownCountry(country);
  }
};

export const convertRoleAddressToSB = (
  address: SmokeballAddress,
  country: Country,
) => {
  switch (country) {
    case "AU": {
      const streetParts: string[] | undefined = address.streetName?.split(" ");
      const streetType = streetParts?.pop();
      return {
        unitNumber: address.unit,
        unitType: address.unitLabel,
        streetNumber: address.streetNumber,
        streetName: streetParts?.join(" "),
        streetType,
        city: address.suburb,
        zipCode: address.postalCode,
        state: address.state,
        country: address.country,
      };
    }
    case "US":
      return {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        county: address.county,
        city: address.city,
        state: address.state,
        zipCode: address.postalCode,
        country: address.country,
      };
    case "GB":
      return {
        unitNumber: address.unit,
        buildingLevel: address.unitLabel,
        streetNumber: address.streetNumber,
        streetName: address.streetName,
        locality: address.locality,
        city: address.city,
        county: address.county,
        state: address.state,
        country: address.country,
        zipCode: address.postalCode,
      };
    default:
      return throwUnknownCountry(country);
  }
};

export const convertLayoutAddressToSB = (
  name: string,
  address: SmokeballAddress,
  country: Country,
) => {
  switch (country) {
    case "AU": {
      const streetParts = address.streetName?.split(" ") || [];
      const streetType = streetParts.pop();
      return removeEmptyValues(
        {
          [`${name}/UnitNumber`]: address.unit,
          [`${name}/UnitType`]: address.unitLabel,
          [`${name}/StreetNumber`]: address.streetNumber,
          [`${name}/StreetName`]: streetParts.join(" "),
          [`${name}/StreetType`]: streetType,
          [`${name}/SuburbTown`]: address.suburb,
          [`${name}/Postcode`]: address.postalCode,
          [`${name}/StateShort`]: address.state,
        },
        [undefined],
      )!;
    }
    case "US":
      return removeEmptyValues(
        {
          [`${name}/AddressLine1`]: address.addressLine1,
          [`${name}/AddressLine2`]: address.addressLine2,
          [`${name}/City`]: address.city,
          [`${name}/ZipCode`]: address.postalCode,
          [`${name}/State`]: address.state,
          [`${name}/County`]: address.county,
        },
        [undefined],
      )!;
    case "GB":
      return removeEmptyValues(
        {
          [`${name}/FlatUnit`]: address.unit,
          [`${name}/PropertyBuildingName`]: address.unitLabel,
          [`${name}/StreetNumber`]: address.streetNumber,
          [`${name}/StreetName`]: address.streetName,
          [`${name}/Locality`]: address.locality,
          [`${name}/TownCity`]: address.city,
          [`${name}/County`]: address.county,
          [`${name}/Postcode`]: address.postalCode,
        },
        [undefined],
      )!;
    default:
      return throwUnknownCountry(country);
  }
};
