/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlAddressPredictionsQueryVariables = Types.Exact<{
  search: Types.Scalars["String"]["input"];
  sessionKey?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GqlAddressPredictionsQuery = {
  __typename: "Query";
  addressPredictions: Array<{
    __typename: "AddressPrediction";
    placeId: string;
    description: string;
    mainText: string;
    secondaryText: string;
  }>;
};

export const AddressPredictionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "addressPredictions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sessionKey" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addressPredictions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "search" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "search" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sessionKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sessionKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "addressPredictionFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "addressPredictionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AddressPrediction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "placeId" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "mainText" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryText" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlAddressPredictionsQuery,
  GqlAddressPredictionsQueryVariables
>;
