import styled from "@emotion/styled";

import { loadProvider } from "@smart/bridge-providers-dom";

const HeaderWrapper = styled.div`
  overflow: hidden;
  border-bottom: 1px solid black;
  color: ${(props) => props.theme.palette.disabled.base};
  padding-right: 6rem;

  img {
    height: 3rem;
    width: auto;
    margin: 1rem;
    filter: grayscale(100%);
  }

  .menu {
    float: right;
    display: flex;
    align-items: center;

    a {
      display: block;
      text-align: center;
      padding: 1rem 2rem;
      text-decoration: none;
      font-size: ${(props) => props.theme.fontSize.subHeading};
    }
  }

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding-right: 0;
  }
`;

type HeaderProps = {
  providerKey?: string;
  menuItems: string[];
};

const Header = ({ providerKey, menuItems }: HeaderProps) => {
  const provider = loadProvider(providerKey, true);

  return (
    <HeaderWrapper>
      <img src={provider.icon} alt={provider.label} />
      <div className="menu">
        {menuItems.map((item) => (
          <a href="#sample-button" key={item}>
            {item}
          </a>
        ))}
      </div>
    </HeaderWrapper>
  );
};

export { HeaderProps, Header };
