import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { globalTeamUri } from "@smart/bridge-types-basic";
import { ButtonRow, Rule } from "@smart/itops-components-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { FormTabProps } from "./types";
import { AssignForm } from "../assign";
import { CopyForm } from "../copy-form";
import { DeleteForm } from "../delete";
import { EditForm } from "../edit";
import { ExportForm } from "../export";
import { MakeFormGlobal } from "../make-global";

const FormSettings = ({ mode, form: { uri, values } }: FormTabProps) => {
  const { title, category, response, teamUri, matterTypes, active } = values;
  const navigate = useNavigate();
  const { actAsFirm, formImportExport, globalForm } = useFeatureFlags();
  const { permission } = useUser();

  const canAccessGlobal =
    teamUri !== globalTeamUri &&
    permission?.can("add", {
      type: "form",
      value: { uri, teamUri: globalTeamUri },
    });
  const shouldShowMakeGlobal = globalForm && canAccessGlobal;
  const shouldShowAssignForm = actAsFirm && canAccessGlobal;
  const canDelete = permission?.can("delete", {
    type: "form",
    value: { uri, teamUri, active: active ?? undefined },
  });

  return (
    <>
      <ButtonRow>
        {shouldShowAssignForm && <AssignForm formUri={uri} title={title} />}
        {formImportExport && <ExportForm formUri={uri} />}
        {shouldShowMakeGlobal && (
          <MakeFormGlobal
            formUri={uri}
            onDone={() => navigate("/forms")}
            disabled={
              matterTypes.length
                ? undefined
                : "Select a matter type to make this form global"
            }
          />
        )}
        {teamUri === globalTeamUri && (
          <CopyForm formUri={uri} onDone={() => navigate("/forms")} />
        )}
      </ButtonRow>
      <Rule />
      <EditForm
        mode={mode}
        locators={{ uri }}
        defaultValues={{
          title,
          category,
          response,
        }}
        debounce
      />
      <Rule />
      {canDelete && (
        <ButtonRow>
          <DeleteForm variables={{ uri }} />
        </ButtonRow>
      )}
    </>
  );
};

export { FormSettings };
