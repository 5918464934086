import styled from "@emotion/styled";

import { Icon } from "@smart/itops-components-dom";

import { GqlSubmission } from "./types";

const SubmissionStatusWrapper = styled.div<{ completed?: boolean }>`
  color: ${(props) =>
    props.completed
      ? props.theme.palette.success.base
      : props.theme.palette.disabled.base};
`;

type SubmissionStatusProps = {
  submission: GqlSubmission | null | undefined;
};

const SubmissionStatus = ({ submission }: SubmissionStatusProps) => (
  <SubmissionStatusWrapper
    completed={submission?.values.status === "completed"}
    title={submission?.values.status === "completed" ? "Completed" : "Ongoing"}
  >
    <Icon
      name={submission?.values.status === "completed" ? "checkCircle" : "clock"}
      size={25}
    />
  </SubmissionStatusWrapper>
);

export { SubmissionStatus, SubmissionStatusProps };
