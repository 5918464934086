/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterTypesQueryVariables = Types.Exact<{
  type: Types.GqlFormCategory;
  category?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  location?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  matterTypeIds?: Types.InputMaybe<
    Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"]
  >;
}>;

export type GqlMatterTypesQuery = {
  __typename: "Query";
  matterTypes: Array<{
    __typename: "MatterType";
    id: string;
    source: string;
    name: string;
    category: string;
    location: string;
    representativeOptions: Array<string>;
  }>;
};

export const MatterTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "matterTypes" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FormCategory" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "category" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "location" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterTypeIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "matterTypes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "category" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "category" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "location" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "location" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterTypeIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterTypeIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "matterTypeFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "matterTypeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatterType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "representativeOptions" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlMatterTypesQuery, GqlMatterTypesQueryVariables>;
