import { useLazyQuery, useMutation } from "@apollo/client";

import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useUpdateTeam = () => useMutation(mutationDocuments.updateTeam);

export const useLoadTeam = useQueryFactory(queryDocuments.team, {
  map: ({ team }) => team,
});

export const useLazyLoadTeams = () => useLazyQuery(queryDocuments.teams);

export const useLoadTeams = useQueryFactory(queryDocuments.teams, {
  map: ({ teams }) => teams,
});
