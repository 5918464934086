import {
  fieldSeparatorDroppableIdRegex,
  groupSeparatorDroppableIdRegex,
} from "../constants";

export const isFieldSeparatorDroppable = (droppableId: string): boolean =>
  !!droppableId.match(fieldSeparatorDroppableIdRegex);

export const isGroupSeparatorDroppable = (droppableId: string): boolean =>
  !!droppableId.match(groupSeparatorDroppableIdRegex);

export const extractDroppableFieldPosition = (droppableId: string): number => {
  if (
    !isFieldSeparatorDroppable(droppableId) &&
    !isGroupSeparatorDroppable(droppableId)
  )
    return -1;
  const parts = droppableId.split("|");
  return parseInt(parts[parts.length - 1], 10);
};
