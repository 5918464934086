import { Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode } from "react";

import { ItemSelect } from "@smart/itops-smokeball-components-dom";

const inputStyle = (props: { readOnly?: boolean; theme: Theme }) => css`
  border: 1px solid ${props.theme.scheme.grey.r35};
  border-radius: 0.8rem;
  background-color: ${props.theme.scheme.grey[props.readOnly ? "r15" : "r0"]};
  cursor: ${props.readOnly ? "not-allowed" : "text"};
  height: 4rem;
`;

const Dropdown = styled(ItemSelect)`
  ${inputStyle}
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 0.8rem;
  padding-right: 0.2rem;

  input {
    cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
    caret-color: transparent;
    text-overflow: ellipsis;
  }
`;

const Input = styled.input`
  ${inputStyle}
  padding: 1.6rem;
  text-overflow: ellipsis;
  outline: none;
`;

const ConditionFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 1;

  .condition-field-label {
    font-weight: 500;
  }
`;

export const ConditionField = ({
  label,
  dataTestId,
  children,
}: {
  label: string;
  dataTestId?: string;
  children: ReactNode;
}) => (
  <ConditionFieldWrapper data-testid={dataTestId}>
    <div className="condition-field-label">{label}</div>
    {children}
  </ConditionFieldWrapper>
);

type DropdownFieldProps<V> = {
  label: string;
  options: V[];
  readOnly?: boolean;
  selected: V | undefined;
  render?: (value: V) => string;
  keys?: (value: V) => string;
  onChange: (value: V) => void;
  name?: string;
};
export const DropdownField = <V extends any>({
  options,
  readOnly = false,
  selected,
  label,
  keys,
  onChange,
  render,
  name,
}: DropdownFieldProps<V>) => (
  <ConditionField label={label}>
    <Dropdown
      name={name || "dropdown"}
      readOnly={readOnly}
      empty="No items available"
      selected={selected}
      keys={(f) => (keys ? keys(f as V) : (f as string))}
      render={(f) => (render ? render(f as V) : (f as string))}
      onSelect={(f) => onChange(f as V)}
      collections={[{ key: "options", options }]}
      shouldSortOptions={false}
    />
  </ConditionField>
);

export const InputField = ({
  value,
  width,
  label,
  name,
  onChange,
  readOnly,
  type = "text",
}: {
  value: string;
  width?: "s" | "m" | "l";
  label: string;
  name?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  type?: "text" | "number";
}) => (
  <ConditionFieldWrapper>
    <div className="condition-field-label">{label}</div>
    <Input
      type={type}
      readOnly={readOnly}
      value={value}
      width={width}
      data-testid={`input-${name}`}
      onChange={(event) => onChange?.(event.target.value)}
    />
  </ConditionFieldWrapper>
);
