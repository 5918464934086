import { useMutation } from "@apollo/client";

import { AnalyticsTool } from "@smart/bridge-types-basic";
import { sortField } from "@smart/itops-utils-basic";
import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation } from "../cache";

export const useUpdateSubmissionLinkHash = () =>
  useMutation(mutationDocuments.generateLinkParameter, {
    update: (cache, { data }, { variables }) => {
      if (data?.generateLinkParameter && variables) {
        const { uri } = data.generateLinkParameter;
        cache.modify({
          id: cache.identify({ __typename: "SubmissionLinkHash", uri }),
          fields: {
            notifiedUserUris: () => variables.fields.notifiedUserUris || [],
            analyticsTools: variables.fields.analyticsTools
              ? () => variables.fields.analyticsTools as AnalyticsTool[]
              : undefined,
          },
        });

        cache.updateQuery(
          { query: queryDocuments.submissionLinkHashes },
          (ex) => {
            const exists = ex?.submissionLinkHashes.find((f) => f.uri === uri);
            if (exists) return ex;

            return {
              submissionLinkHashes: [
                data.generateLinkParameter,
                ...(ex?.submissionLinkHashes || []),
              ],
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useDeleteSubmissionLinkHash = () =>
  useMutation(mutationDocuments.deleteSubmissionLinkHash, {
    optimisticResponse: optimisticOperation("deleteSubmissionLinkHash"),
    update: (cache, { data }, { variables }) => {
      if (variables) {
        if (data?.deleteSubmissionLinkHash && variables) {
          const { uri } = variables;
          cache.modify({
            id: cache.identify({ __typename: "SubmissionLinkHash", uri }),
            fields: {
              deleted: () => true,
              updatedAt: () => data.deleteSubmissionLinkHash.createdAt,
            },
          });
        }
      }
    },
  });

export const useLoadSubmissionLinkHash = useQueryFactory(
  queryDocuments.submissionLinkHash,
  {
    map: ({ submissionLinkHash }) => submissionLinkHash,
  },
);

export const useLoadSubmissionLinkHashes = useQueryFactory(
  queryDocuments.submissionLinkHashes,
  {
    map: ({ submissionLinkHashes }) =>
      sortField(
        submissionLinkHashes.filter((h) => !h.deleted),
        { key: (f) => f.updatedAt || f.createdAt, dir: "desc" },
      ),
  },
);
