import { useEffect } from "react";

import { loadEnv } from "@smart/bridge-env-dom";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { MatterTabs, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Region } from "@smart/itops-utils-basic";

export const useOpenFamilyProForm = ({
  selectedFormUri,
  externalSubmissionEmbedUrl,
  callbackFn,
}: {
  selectedFormUri?: string;
  externalSubmissionEmbedUrl?: string;
  callbackFn?: Function;
}) => {
  const { host } = useSmokeballApp();
  const isUsRegion = (loadEnv("Region") as Region) === "nova";

  useEffect(() => {
    if (selectedFormUri !== familyProQuestionnaireFormUri) return;

    if (isUsRegion) {
      host?.selectTab(MatterTabs.FamilyProperty);
      callbackFn?.();
    } else if (externalSubmissionEmbedUrl) {
      host?.open({
        url: externalSubmissionEmbedUrl,
        title: "Questionnaire",
      });
      callbackFn?.();
    }
  }, [selectedFormUri]);
};
