import styled from "@emotion/styled";
import { useMemo, useState } from "react";

import { Avatar } from "@smart/itops-components-dom";
import {
  buildDisplayName,
  getBuildVersion,
  specialChars,
} from "@smart/itops-utils-basic";
import { useUser } from "@smart/manage-hooks-dom";

const UserMenuWrapper = styled.div<{ visible: boolean }>`
  .user {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0.5rem;
    margin: 0.2rem 0;
    width: 100%;

    background: none;
    border: 0;
    cursor: pointer;

    p {
      margin: 0.8rem 0 0.8rem 2rem;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: 700;
    }
  }

  .menu {
    margin: -1rem 0 1rem;
    padding-left: 7rem;

    overflow: hidden;
    max-height: ${(props) => (props.visible ? 5 : 0)}rem;
    transition: max-height 0.2s ease;

    button {
      background: none;
      border: 0;
      width: 100%;
      margin: 0.2rem 0;
      padding: 0.2rem 0.8rem;
      cursor: pointer;

      text-align: left;
      font-size: ${(props) => props.theme.fontSize.small};
      text-decoration: underline;
    }
  }

  .info {
    margin: 0 0 1rem;

    p {
      width: 100%;
      margin: 0.2rem 0;
      padding: 0.2rem 0.8rem;

      text-align: center;
      font-size: ${(props) => props.theme.fontSize.xSmall};
      color: ${(props) => props.theme.palette.foreground.accent};
    }
  }
`;

type UserMenuProps = {
  setExpanded: (expanded: boolean) => void;
};

const UserMenu = ({ setExpanded }: UserMenuProps) => {
  const { user, signOut } = useUser();
  const [visible, setVisible] = useState(false);
  const displayName = buildDisplayName(user, specialChars.ellipsis);
  const buildVersion = useMemo(getBuildVersion, []);

  return (
    <UserMenuWrapper
      visible={visible}
      onClick={() => {
        if (setExpanded) setExpanded(!visible);
        setVisible(!visible);
      }}
    >
      <button type="button" className="user">
        <Avatar {...user} />
        <p>{displayName}</p>
      </button>
      <div className="menu">
        <button type="button" aria-label="Sign Out" onClick={signOut}>
          Sign Out
        </button>
      </div>
      <div className="info" title={buildVersion.build}>
        <p>{buildVersion.version}</p>
      </div>
    </UserMenuWrapper>
  );
};

export { UserMenu, UserMenuProps };
