import styled from "@emotion/styled";

import { Icon } from "@smart/itops-icons-dom";
import { Label } from "@smart/itops-ui-dom";

import { GqlField, GqlGroup } from "../../types";
import { ItemToUpdate } from "../hooks";
import { GqlFieldValues } from "../types";
import { buildMappedPathParts } from "../utils";

const RemapStatusesWrapper = styled.ul`
  font-size: ${(props) => props.theme.fontSize.base};
`;

const RemapStatuses = ({
  itemsToUpdate,
  groups,
  fields,
  type,
}: {
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  itemsToUpdate: ItemToUpdate[];
  type: "group" | "field";
}) => (
  <>
    {itemsToUpdate.map((item) => {
      const isGroup = type === "group";
      const itemValues = (isGroup ? groups : fields)?.find(
        (i) => i.uri === item.uri,
      )?.values;
      const group = isGroup
        ? undefined
        : groups?.find(
            (g) => g.uri === (itemValues as GqlFieldValues).groupUri,
          );

      if (!itemValues) return null;

      return (
        <li key={item.uri}>
          {itemValues.label || item.uri} (
          {(isGroup
            ? buildMappedPathParts({
                layout: itemValues.layout!,
                field: itemValues.field!,
                matterFields: [],
              })
            : buildMappedPathParts({
                layout: group?.values.layout || itemValues.layout!,
                field: itemValues.field!,
                groupMatterField: group?.values.field,
                matterFields: [],
              })
          )
            .filter(Boolean)
            .join(" > ")}
          ){isGroup && " [group]"}
        </li>
      );
    })}
  </>
);

const RemappingFieldListWrapper = styled.div`
  margin-top: 2rem;
`;

const Alert = styled(Label)`
  flex-flow: row nowrap;
  margin-top: 1rem;
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.scheme.orange.r60};
`;

type RemappingFieldListProps = {
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  groupsToUpdate: ItemToUpdate[];
  fieldsToUpdate: ItemToUpdate[];
};

export const RemappingFieldList = ({
  groups,
  fields,
  groupsToUpdate,
  fieldsToUpdate,
}: RemappingFieldListProps) => {
  const groupsToRemoveMapping = groupsToUpdate.filter(
    (g) => !g.layout && !g.field,
  );
  const fieldsToRemoveMapping = fieldsToUpdate.filter(
    (f) => !f.layout && !f.field,
  );
  const isRemoving =
    groupsToRemoveMapping.length || fieldsToRemoveMapping.length;

  return isRemoving ? (
    <RemappingFieldListWrapper>
      <Alert>
        <Icon size={30} library="lucide" name="AlertCircle" />
        <span>
          The following fields could not be matched to the new matter type and
          mapping will be removed:
        </span>
      </Alert>
      <RemapStatusesWrapper>
        {!!groupsToRemoveMapping.length && (
          <RemapStatuses
            groups={groups}
            fields={fields}
            itemsToUpdate={groupsToRemoveMapping}
            type="group"
          />
        )}
        {!!fieldsToRemoveMapping.length && (
          <RemapStatuses
            groups={groups}
            fields={fields}
            itemsToUpdate={fieldsToRemoveMapping}
            type="field"
          />
        )}
      </RemapStatusesWrapper>
    </RemappingFieldListWrapper>
  ) : null;
};
