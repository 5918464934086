import { useFieldArray, useFormContext } from "react-hook-form";

import { Label } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { useLoadFields, useLoadSections } from "@smart/manage-gql-client-dom";

import { CreateLink } from "./create-link";
import { LinkField } from "./field";
import {
  MAX_FIELD_LINKS,
  getFieldValuesByUri,
  getInitialValue,
  getSectionOrder,
} from "./utils";
import { FormDisplayMode } from "../forms/types";
import { GroupSchema } from "../shared-schemas";

type GroupLinksProps = {
  mode: FormDisplayMode;
  uri: string;
  formUri: string;
};

const GroupLinks = ({ mode, uri, formUri }: GroupLinksProps) => {
  const { control } = useFormContext<UpdateHookValues<GroupSchema>>();
  const sections = useLoadSections({ formUri });
  const fields = useLoadFields({ formUri });
  const links = useFieldArray({ control, name: "links" });

  if (!sections.result?.length || !fields.result?.length) return null;

  const availableFields = fields.result?.filter(
    (f) =>
      f.values.type !== "info" &&
      f.values.groupUri !== uri &&
      !links.fields.find((linkedField) => linkedField.fieldUri === f.uri),
  );

  const isOrderRight = (fieldUri: string): boolean => {
    if (!fieldUri) return true;

    const currentField = getFieldValuesByUri(uri, fields.result);
    const linkedField = getFieldValuesByUri(fieldUri, fields.result);

    const currentFieldOrder = `${getSectionOrder(
      currentField?.sectionUri,
      sections.result,
    )}-${currentField?.order}`;
    const linkedFieldOrder = `${getSectionOrder(
      linkedField?.sectionUri,
      sections.result,
    )}-${linkedField?.order}`;

    return !currentField || currentFieldOrder >= linkedFieldOrder;
  };

  if (mode === "view" && links.fields.length === 0) return null;

  return (
    <Label name="links" text="Links">
      {links.fields.map((fieldLink, i) => (
        <LinkField
          mode={mode}
          key={fieldLink.id}
          index={i}
          fieldValues={getFieldValuesByUri(fieldLink.fieldUri, fields.result)}
          onRemove={() => links.remove(i)}
          orderWarning={!isOrderRight(fieldLink.fieldUri)}
        />
      ))}
      {mode === "edit" && links.fields.length < MAX_FIELD_LINKS && (
        <CreateLink
          availableFields={availableFields}
          sections={sections.result}
          create={(fieldUri) =>
            links.append({
              fieldUri,
              condition: "equalTo",
              value: getInitialValue(
                getFieldValuesByUri(fieldUri, fields.result),
              ),
              hide: false,
            })
          }
        />
      )}
    </Label>
  );
};

export { GroupLinks, GroupLinksProps };
