import { useMemo } from "react";

import { globalTeamUri } from "@smart/bridge-types-basic";
import { useLoadForms, useLoadSetting } from "@smart/manage-gql-client-dom";
import { useManageFormsContext, useUser } from "@smart/manage-hooks-dom";

import { ListForms } from "../../components/embedded/form-builder/list-forms";

const Builder = () => {
  const { permission } = useUser();
  const { actingTeam } = useManageFormsContext();
  const setting = useLoadSetting({});
  const forms = useLoadForms(
    {
      teamUri: actingTeam?.uri,
      includeGlobal: true,
    },
    { fetchPolicy: "cache-and-network" },
  );

  const formsToDisplay = useMemo(
    () =>
      forms.result?.filter((form) => {
        const matchCategory = setting.result?.areasOfLaw?.length
          ? form.matterTypeCategories.some((category) =>
              setting.result?.areasOfLaw?.includes(category),
            )
          : true;
        const matchLocation = setting.result?.locations?.length
          ? form.matterTypeLocations.some((location) =>
              setting.result?.locations?.includes(location),
            )
          : true;
        const canAccessGlobal = permission?.can("add", {
          type: "form",
          value: { uri: form.uri, teamUri: globalTeamUri },
        });
        const isDisplayableGlobalForm =
          form.teamUri === globalTeamUri && (form.active || canAccessGlobal);
        const isTeamForm = form.teamUri !== globalTeamUri;

        return (
          matchCategory &&
          matchLocation &&
          (isTeamForm || isDisplayableGlobalForm)
        );
      }),
    [forms.result, setting.result, permission],
  );

  return (
    <ListForms
      loading={forms.loading || setting.loading}
      forms={formsToDisplay}
      setting={setting.result || undefined}
    />
  );
};

export { Builder };
