import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import {
  CheckboxItem,
  Icon,
  IconButton,
  Input,
} from "@smart/itops-components-dom";
import { isNullOrUndefined, jsonParseOrReturn } from "@smart/itops-utils-basic";

const DropdownCheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .expander {
    position: absolute;
    right: 0;
    top: 0;
  }

  .checkbox-list {
    padding-left: 1.4rem;
    position: absolute;
    background: var(--background);
    width: 95%;
    top: 4rem;
    left: 0;
    z-index: 99;
    border: 1px solid ${(props) => props.theme.palette.disabled.base};
  }
`;

type ControlledLinkValueProps = {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string | null) => void;
  disabled?: boolean;
};

const DropdownCheckbox = ({
  options,
  value,
  onChange,
  disabled,
}: ControlledLinkValueProps) => {
  const converter = {
    toString: (list: string[]): string =>
      list.map((v) => options.find((o) => o.value === v)?.label).join(", "),
    toList: (json: string | null | undefined): string[] => {
      if (isNullOrUndefined(json)) return [];

      const fromJson = jsonParseOrReturn(json);
      if (Array.isArray(fromJson)) {
        return fromJson.every((v) => !!options.find((o) => o.value === v))
          ? fromJson
          : [];
      }

      return options.find((o) => o.value === json) ? [json] : [];
    },
  };

  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(() =>
    converter.toList(value),
  );

  useEffect(() => {
    onChange(JSON.stringify(selectedValues));
  }, []);

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedSelectedValues = [...selectedValues];
    if (e.currentTarget.checked) {
      updatedSelectedValues.push(e.currentTarget.value);
    } else {
      updatedSelectedValues = updatedSelectedValues.filter(
        (existing) => existing !== e.currentTarget.value,
      );
    }

    if (updatedSelectedValues.length > 0) {
      onChange(JSON.stringify(updatedSelectedValues));
    } else {
      onChange(null);
    }

    setSelectedValues(updatedSelectedValues);
  };

  return (
    <DropdownCheckboxWrapper>
      <Input value={converter.toString(selectedValues)} readOnly />
      <IconButton
        className="expander"
        icon={<Icon name="angleRight" rotate={open ? -90 : 90} size={16} />}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <div className="checkbox-list">
          {options.map((o) => (
            <CheckboxItem key={o.value}>
              <input
                type="checkbox"
                value={o.value}
                checked={selectedValues.includes(o.value)}
                onChange={onCheckboxChange}
                disabled={disabled}
              />
              <div className="checkmark" />
              <div className="label">{o.label}</div>
            </CheckboxItem>
          ))}
        </div>
      )}
    </DropdownCheckboxWrapper>
  );
};

export { DropdownCheckbox, ControlledLinkValueProps };
