import styled from "@emotion/styled";

export const Selection = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  font-size: ${(props) => props.theme.fontSize.small};
  margin: 0.4rem;
  min-height: 2.5rem;
  max-height: 8rem;
  overflow-y: auto;
  gap: 0.2rem;
  outline: none;
`;

export const Tag = styled.button`
  background: ${(props) => props.theme.palette.disabled.highlight};
  border: 0;
  border-radius: 2px;

  padding: 0.2rem 0.6rem;
  white-space: nowrap;

  outline: none;
  &[aria-selected="true"],
  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.active.accent};
  }
  &
`;

export const Input = styled.input`
  background: none;
  border: 0;
  padding: 0 0.2rem !important;
  outline: none;
  flex: 1;
`;

export const ListHolder = styled.div`
  position: absolute;
  margin-top: 1px;
  left: 0;
  right: 0;
  z-index: 10;

  background: ${(props) => props.theme.palette.background.base};
  border: 1px solid ${(props) => props.theme.palette.background.highlight};
  border-radius: 2px;

  display: flex;
  flex-flow: column;
`;

export const List = styled.div`
  max-height: 20rem;
  overflow: auto;
`;

export const Close = styled.button`
  border: 0;
  background: none;
  margin: 0.1rem 1.2rem;
  padding: 0.2rem 2rem;
  align-self: flex-start;

  color: ${(props) => props.theme.palette.primary.base};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.small};
  outline: none;
  text-decoration: underline;
`;

export const Seperator = styled.hr`
  border: 0;
  border-bottom: 1px dotted ${(props) => props.theme.palette.disabled.base};
  margin: 0.4rem 1rem 0.2rem;
`;

export const NotItem = styled.div`
  color: ${(props) => props.theme.palette.foreground.accent};
  font-size: ${(props) => props.theme.fontSize.small};
  font-style: italic;
  padding: 0.5rem 2.8rem;
`;

export const Item = styled.label<{ disabled?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;

  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 0.5rem 1rem;

  input {
    display: none;
  }

  .arrow {
    margin-right: 0.6rem;
  }

  input:checked ~ .arrow {
    transform: rotate(90deg);
  }

  .checkmark {
    position: relative;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 0.2rem;
    margin-right: 0.6rem;

    &.bordered {
      background-color: ${(props) => props.theme.palette.background.base};
      border: 1px solid ${(props) => props.theme.palette.disabled.base};
    }

    &:after {
      position: absolute;
      left: 0.5rem;
      content: "";
      display: block;
      opacity: 0;
      width: 0.5rem;
      height: 1.2rem;
      border: solid ${(props) => props.theme.palette.primary.base};
      border-width: 0 0.25rem 0.25rem 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    &:after {
      opacity: 1;
    }
  }

  &[aria-disabled="true"] {
    cursor: default;
  }

  &:not([aria-disabled="true"]):hover {
    background: ${(props) => props.theme.palette.background.accent};
  }

  &[aria-selected="true"],
  &[aria-selected="true"]:hover {
    background: ${(props) => props.theme.palette.secondary.accent};
  }
`;
