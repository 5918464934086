/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlGenerateLinkParameterMutationVariables = Types.Exact<{
  uri: Types.Scalars["ID"]["input"];
  fields: Types.GqlCreateSubmissionLinkInput;
}>;

export type GqlGenerateLinkParameterMutation = {
  __typename: "Mutation";
  generateLinkParameter: {
    __typename: "SubmissionLinkHash";
    uri: string;
    operationId: string;
    teamUri: string;
    formUri: string;
    slugUri: string;
    userUri: string;
    notifiedUserUris?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    deleted?: boolean | null;
    analyticsTools?: Array<{
      __typename: "AnalyticsTool";
      type: string;
      trackingId: string;
    }> | null;
  };
};

export const GenerateLinkParameterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "generateLinkParameter" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uri" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fields" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSubmissionLinkInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateLinkParameter" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fields" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "submissionLinkHashFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "submissionLinkHashFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubmissionLinkHash" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "operationId" } },
          { kind: "Field", name: { kind: "Name", value: "teamUri" } },
          { kind: "Field", name: { kind: "Name", value: "formUri" } },
          { kind: "Field", name: { kind: "Name", value: "slugUri" } },
          { kind: "Field", name: { kind: "Name", value: "userUri" } },
          { kind: "Field", name: { kind: "Name", value: "notifiedUserUris" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "analyticsTools" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "trackingId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlGenerateLinkParameterMutation,
  GqlGenerateLinkParameterMutationVariables
>;
