import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { getLinkVariants, LinkVariant } from "./class-names";
import { Icon, IconName } from "../icon";

type LinkProps = {
  variant?: LinkVariant;
  text: string;
  href?: string;
  onClick?: () => void;
  leftIcon?: IconName;
  rightIcon?: IconName;
  disabled?: boolean;
  className?: string;
};

export const Link = ({
  variant = "default",
  text,
  href,
  onClick,
  leftIcon,
  rightIcon,
  disabled,
  className,
}: LinkProps) => (
  <span className={twMerge(getLinkVariants({ variant, disabled }))}>
    {leftIcon && <Icon name={leftIcon} />}
    <a
      className={twMerge(clsx("text-ui", className))}
      href={href}
      onClick={onClick}
    >
      {text}
    </a>
    {rightIcon && <Icon name={rightIcon} />}
  </span>
);
