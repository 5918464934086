import styled from "@emotion/styled";
import { capitalCase } from "change-case";

import { defaultSizeLimit } from "@smart/bridge-intake-components-dom";
import { Locale } from "@smart/bridge-types-basic";
import { loadLocale } from "@smart/itops-locale-dom";
import { FileUpload } from "@smart/itops-ui-dom";

import { Appointment } from "./appointment";
import { EditChoice } from "./edit-choice";
import { Payment } from "./payment";
import { GqlFieldValues, OnUpdateField } from "../types";

const Box = styled.div<{ size: "small" | "big" }>`
  height: ${(props) => (props.size === "small" ? "5rem" : "9rem")};
  border: 1px solid ${(props) => props.theme.scheme.grey.r35};
  border-radius: 0.5rem;
  margin: 1rem 0;
`;

const DatePartsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
`;

const DatePart = styled.div<{ isYear?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .box {
    height: 5rem;
    width: ${(props) => (props.isYear ? "10rem" : "6rem")};
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.5rem;
  }

  span {
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.scheme.grey.r60};
  }
`;

const DateDisplayer = ({
  dateFormat,
}: {
  dateFormat: Locale["dateFormat"];
}) => (
  <DatePartsContainer>
    {dateFormat.map((part) => (
      <DatePart isYear={part === "year"} key={part}>
        <span>{capitalCase(part)}</span>
        <div className="box" />
      </DatePart>
    ))}
  </DatePartsContainer>
);

type FieldBoxProps = {
  field: GqlFieldValues;
  onUpdateField: OnUpdateField;
};

export const FieldBox = ({ field, onUpdateField }: FieldBoxProps) => {
  const { dateFormat } = loadLocale();

  switch (field.type) {
    case "text":
    case "number":
    case "email":
    case "address":
    case "currency":
    case "phoneNumber":
      return <Box data-testid="small-text-box" size="small" />;
    case "file":
      return (
        <FileUpload
          id="file-upload"
          errorId="file-upload"
          sizeLimit={defaultSizeLimit}
          onSelect={() => {}}
        />
      );
    case "multilineText":
      return <Box data-testid="large-text-box" size="big" />;
    case "date":
      return <DateDisplayer dateFormat={dateFormat} />;
    case "choice":
    case "checkbox":
      return <EditChoice field={field} onUpdateField={onUpdateField} />;
    case "appointment":
      return <Appointment field={field} onUpdateField={onUpdateField} />;
    case "payment":
      return <Payment field={field} onUpdateField={onUpdateField} />;
    default:
      return null;
  }
};
