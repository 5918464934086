import { ReactNode } from "react";

import { EditorElement } from "@smart/itops-serialisation-basic";

export type DisplayLinkProps = {
  element: EditorElement<"link">;
  children: ReactNode;
};

export const DisplayLink = ({ children, element }: DisplayLinkProps) => (
  <a
    className="text-blue-560"
    href={element.url}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);
