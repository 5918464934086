import { SubmissionNS } from "@smart/bridge-types-basic";
import { CloseButton, Page } from "@smart/itops-components-dom";
import { useParamsWithDefault } from "@smart/itops-hooks-dom";
import { specialChars } from "@smart/itops-utils-basic";
import { useLoadSubmission } from "@smart/manage-gql-client-dom";

import {
  SubmissionStatus,
  SubmissionTags,
  SubmissionView,
} from "../components";

const Submission = () => {
  const params = useParamsWithDefault((p) => ({
    id: p.id || "",
  }));
  const submission = useLoadSubmission({
    uri: SubmissionNS.generateUri(params.id),
  });

  return (
    <Page
      top={<SubmissionTags submission={submission.result} />}
      text={submission.result?.summary || specialChars.enDash}
      left={<SubmissionStatus submission={submission.result} />}
      right={<CloseButton previous={/\/submissions/} />}
    >
      <SubmissionView submission={submission.result} result={submission} />
    </Page>
  );
};

export { Submission };
