import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";

import { typePolicies } from "./policy";

const hasStatusCode = (e: any): e is { statusCode: number } =>
  !!e && !!e.statusCode;

export type LinkOptions = {
  token: string;
  idToken: string;
  refresh: () => void;
};

export const buildLink = ({ token, idToken, refresh }: LinkOptions) => {
  const httpLink = new HttpLink({ uri: `/api/gql` });
  const authLink = setContext(() => ({
    headers: {
      Authorization: `Bearer ${token}`,
      "x-id-token": idToken,
    },
  }));
  const errorLink = onError(({ networkError }) => {
    if (hasStatusCode(networkError) && networkError.statusCode === 401)
      refresh();
  });
  const retryLink = new RetryLink();

  return from([retryLink, errorLink, authLink, httpLink]);
};

export const buildClient = (options: LinkOptions) =>
  new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: buildLink(options),
    connectToDevTools: true,
  });
