import { ContextMenu } from "@smart/itops-components-dom";
import { CellProps } from "@smart/itops-smokeball-components-dom";

import { EmbedType } from "./embed-options";
import { LinkItemType, SubmissionLink } from "./types";

export const LinkTableActions =
  ({
    onEdit,
    onDelete,
    onCopy,
    onGenerateQRCode,
  }: Record<
    "onEdit" | "onDelete" | "onCopy" | "onGenerateQRCode",
    (link: SubmissionLink, options?: { copyType: EmbedType }) => void
  >) =>
  ({ row }: CellProps<LinkItemType>) => (
    <ContextMenu
      placement="bottom"
      items={[
        {
          label: "Change Settings",
          value: "changeSettings",
          onClick: () => onEdit(row.original.link),
        },
        {
          label: "Delete Link",
          value: "deleteLink",
          onClick: () => onDelete(row.original.link),
        },
        {
          label: 'Copy "Link"',
          value: "copyLink",
          onClick: () => onCopy(row.original.link, { copyType: "url" }),
          divider: "top",
        },
        {
          label: 'Copy "Embedded"',
          value: "copyEmbedded",
          onClick: () => onCopy(row.original.link, { copyType: "iframe" }),
        },
        {
          label: 'Copy "Contact Us"',
          value: "copyContactUs",
          onClick: () => onCopy(row.original.link, { copyType: "button" }),
        },
        {
          label: "Show QR Code",
          value: "generateQRCode",
          onClick: () => onGenerateQRCode(row.original.link),
        },
      ]}
    />
  );
