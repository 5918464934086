import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  PlaceholderName,
  appendDownloadFormLink,
  appendFormLink,
  defaultTemplate,
} from "@smart/bridge-templates-basic";
import { Accordion } from "@smart/itops-smokeball-components-dom";

import { MessageSection } from "./message-section";

type MessageContainerProps = {
  setting: {
    matter?: string | null;
    lead?: string | null;
    reminder?: string | null;
    confirmation?: string | null;
  };
  update: (values: {
    matter?: string;
    lead?: string;
    reminder?: string;
    confirmation?: string;
  }) => Promise<void>;
  loading?: boolean;
};

type SectionOptions = {
  key: string;
  label: string;
  description: string;
  value: string;
  fallback: string;
  save: (value?: string) => Promise<void>;
  excludingFieldNames: PlaceholderName[];
};

export const MessageContainer = ({
  setting,
  update,
  loading,
}: MessageContainerProps) => {
  const { leadPro, matterFlow } = useFeatureFlags();
  const keys: string[] = [];
  const labels: Record<string, string> = {};
  const views: Record<string, JSX.Element> = {};

  const addSection = ({
    key,
    label,
    description,
    value,
    fallback,
    save,
    excludingFieldNames,
  }: SectionOptions) => {
    keys.push(key);
    labels[key] = label;

    views[key] = (
      <MessageSection
        description={description}
        loading={loading}
        value={value}
        fallback={fallback}
        save={save}
        excludingFieldNames={excludingFieldNames}
      />
    );
  };

  if (leadPro) {
    addSection({
      key: "lead",
      label: "New Intake Message (Lead)",
      description:
        "This will be the default message when a new client intake form is sent for a lead.",
      value: appendFormLink(setting?.lead?.trim() || defaultTemplate.lead),
      fallback: defaultTemplate.lead,
      save: (lead) => update({ lead }),
      excludingFieldNames: ["downloadFormLink"],
    });
  }

  if (matterFlow) {
    addSection({
      key: "matter",
      label: "New Intake Message (Matter)",
      description:
        "This will be the default message when a new client intake form is sent for a matter.",
      value: appendFormLink(setting?.matter?.trim() || defaultTemplate.matter),
      fallback: defaultTemplate.matter,
      save: (matter) => update({ matter }),
      excludingFieldNames: ["downloadFormLink"],
    });
  }

  addSection({
    key: "reminder",
    label: "Reminder Message",
    description:
      "This will be the default message when a reminder is sent for a form.",
    value: appendFormLink(
      setting?.reminder?.trim() || defaultTemplate.reminder,
    ),
    fallback: defaultTemplate.reminder,
    save: (reminder) => update({ reminder }),
    excludingFieldNames: ["downloadFormLink"],
  });

  addSection({
    key: "confirmation",
    label: "Confirmation Message",
    description:
      "This message will be shown after the form has been submitted.",
    value: appendDownloadFormLink(
      setting?.confirmation?.trim() || defaultTemplate.response,
    ),
    fallback: defaultTemplate.response,
    save: (confirmation) => update({ confirmation }),
    excludingFieldNames: ["clientName", "formLink", "matterType"],
  });

  return (
    <>
      <p className="description">Default messages for client communication</p>
      <Accordion keys={keys} labels={labels} views={views} />
    </>
  );
};
