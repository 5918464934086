import styled from "@emotion/styled";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";

import {
  FieldComponentType,
  fieldFallbackLabel,
} from "@smart/bridge-intake-components-dom";
import { Icon } from "@smart/itops-icons-dom";
import { Button, Card, EditableText } from "@smart/itops-ui-dom";

import { FieldTypeIndicator } from "./field-type-indicator";
import { fieldColor, fieldIcon } from "../../constants";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
} from "../../types";
import { Actions } from "../actions";
import { FieldBox } from "../field-box";

const FieldBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
`;

const Footer = styled.div`
  margin: 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  overflow: hidden;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Handle = styled.div`
  margin: 1rem 1.2rem 1rem 0;
  opacity: 0.3;
  transition: opacity 0.5s ease;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  gap: 0.1rem;
  position: relative;

  width: 100%;
  overflow: hidden;
`;

type FieldCardProps = {
  loading?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isShrinking?: boolean;
  isDragging?: boolean;
  editing?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  field: GqlFieldValues;
  conditionActions: ConditionActions;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onFieldTypeChange: OnFieldTypeChange;
  excludingFieldTemplates: FieldTemplate[];
};

export const FieldCard = ({
  loading,
  isShrinking,
  onUpdateField,
  onDeleteField,
  dragHandleProps,
  editing,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  field,
  conditionActions,
  groupLayout,
  groupMatterField,
  onFieldTypeChange,
  excludingFieldTemplates,
}: FieldCardProps) => {
  const {
    type,
    label: question,
    description,
    mandatory,
    field: matterField,
    layout,
  } = field;

  const fieldType = matterField && layout ? "mapped" : type;
  const [editedQuestion, setEditedQuestion] = useState(
    question || fieldFallbackLabel[fieldType],
  );
  const [editedDescription, setEditedDescription] = useState(description);
  const [descriptionVisible, setDescriptionVisible] = useState(!!description);
  const [isEditing, setIsEditing] = useState(editing);

  const handleEditComplete = () =>
    onUpdateField({
      field,
      updated: {
        label: editedQuestion,
        description: editedDescription ?? undefined,
      },
    });

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const onChangeFieldType = async (newFieldType: FieldComponentType) => {
    await onFieldTypeChange({ field, newFieldType });
  };

  return (
    <FieldBoxWrapper>
      <Card
        current={isEditing}
        breakPoint="readable"
        flow="row"
        loading={loading}
      >
        <Left>
          <Handle className="handle" {...dragHandleProps}>
            <Icon library="lucide" name="GripVertical" size={24} />
          </Handle>
        </Left>
        <Right>
          <Header>
            <LabelGroup>
              <EditableText
                text={editedQuestion}
                onChange={setEditedQuestion}
                onComplete={handleEditComplete}
                isMandatory={mandatory}
                editing={isEditing}
                onEditing={setIsEditing}
                fontSize="subHeading"
                isEditor
              />
              {descriptionVisible && (
                <EditableText
                  text={editedDescription}
                  onChange={setEditedDescription}
                  onComplete={handleEditComplete}
                  placeholder="Description"
                  fontSize="emphasis"
                  isEditor
                />
              )}
            </LabelGroup>
            <Actions
              type={type}
              descriptionVisible={descriptionVisible}
              mandatory={!!mandatory}
              onToggleDescription={async (checked) => {
                setDescriptionVisible(checked);
                if (!checked) {
                  setEditedDescription("");
                  await onUpdateField({ field, updated: { description: "" } });
                }
              }}
              onToggleMandatory={async (isMandatory) => {
                await onUpdateField({
                  field,
                  updated: { mandatory: isMandatory },
                });
              }}
              onDelete={() => onDeleteField(field)}
            />
          </Header>
          {!isShrinking && (
            <FieldBox field={field} onUpdateField={onUpdateField} />
          )}
          <Footer data-testid="field-card-footer">
            <FieldTypeIndicator
              label={fieldFallbackLabel[type]}
              icon={fieldIcon[fieldType]}
              uiKey={fieldColor[fieldType]}
              matterField={matterField}
              layout={layout}
              matterLayouts={matterLayouts}
              matterTypes={matterTypes}
              loadMatterFields={loadMatterFields}
              groupMatterField={groupMatterField}
              groupLayout={groupLayout}
              onChangeFieldType={onChangeFieldType}
              excludingFieldTemplates={excludingFieldTemplates}
            />
            {type !== "info" && (
              <Button
                icon={{ library: "lucide", name: "Split", size: 16 }}
                kind="borderless"
                variant={
                  conditionActions.checkHasConditions(field.uri)
                    ? "highlightOrange"
                    : "action"
                }
                onClick={() => conditionActions.setEditingCondition(field)}
              />
            )}
          </Footer>
        </Right>
      </Card>
    </FieldBoxWrapper>
  );
};
