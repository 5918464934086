import {
  submissionExtendedLabel,
  SubmissionStatus,
  submissionStatusLabel,
  submissionStatusValue,
} from "@smart/bridge-types-basic";
import {
  SearchInput,
  Select,
  TableProps,
} from "@smart/itops-sb-design-system-dom";

import { TabRowValue } from "./cells";
import { ShareButton } from "../../shared";

const toolbarStyles =
  "flex justify-between flex-1 max-w-screen-maximum mx-auto p-6";

export type TabListToolbarProps = {
  onShare: (share: "regular" | "internal" | undefined) => void;
  globalFilter: TableProps<TabRowValue>["globalFilter"];
  statuses: SubmissionStatus[];
  setStatuses: (statuses: SubmissionStatus[]) => void;
  onCreateMatterSpecificForm: (options: {
    filePath: string;
    fileName: string;
    fileId: string;
  }) => Promise<void>;
};

export const TabListToolbar = ({
  onShare,
  globalFilter,
  statuses,
  setStatuses,
  onCreateMatterSpecificForm,
}: TabListToolbarProps) => (
  <div className="flex text-ui">
    <div className={toolbarStyles}>
      <div className="flex items-center gap-small">
        <SearchInput
          value={globalFilter?.state}
          onChange={(e) => globalFilter?.onChange(e.currentTarget.value)}
          onClear={() => globalFilter?.onChange("")}
          placeholder="Search"
          dataTestId="search-forms"
        />
        <Select
          options={submissionStatusValue.map((status) => ({
            label:
              status === "draft"
                ? submissionExtendedLabel.internalUse
                : submissionStatusLabel[status],
            value: status,
          }))}
          value={statuses}
          isMultiple
          onChange={setStatuses}
          placeholder="Status"
          dataTestId="filter-by-status"
        />
      </div>
      <div className="flex gap-4 z-1">
        <ShareButton
          onShare={onShare}
          onCreateMatterSpecificForm={onCreateMatterSpecificForm}
        />
      </div>
    </div>
  </div>
);
