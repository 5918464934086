import { ReactNode, useCallback, useEffect, useRef } from "react";

import {
  ActivityType,
  EventDataType,
  FormEventDataType,
  EventCaptureAttributes,
  CapturedEventType,
} from "@smart/bridge-types-basic";
import { useDebounce, useParamsWithDefault } from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { extractId } from "@smart/itops-types-basic";

import { formsApi } from "./api";
import { useUser } from "./user";

type ActionEvent = {
  type: EventDataType;
  activity: ActivityType;
  formId?: string;
  submissionId?: string;
  fieldId?: string;
};

export const registerCapturedEvent = ({
  form,
  submission,
  fieldUri,
  eventDataType,
  eventType,
  activityType,
}: {
  form?: { uri: string } | null;
  submission?: { uri: string };
  fieldUri?: string;
  eventDataType: EventDataType;
  eventType: CapturedEventType;
  activityType: ActivityType;
}): EventCaptureAttributes => ({
  "data-enabled-capture": "true",
  "data-captured-event-type": eventType,
  "data-event-data-type": eventDataType,
  "data-activity-type": activityType,
  "data-form-id": form?.uri ? extractId(form.uri) : undefined,
  "data-submission-id": submission?.uri ? extractId(submission.uri) : undefined,
  "data-field-id": fieldUri ? extractId(fieldUri) : undefined,
});

type EventHandlerTuple = [
  eventType: CapturedEventType,
  handler: (event: Event) => void,
  options?: boolean,
];

const DEBOUNCING_DELAY = 600;

export type EventLogger = (event: ActionEvent) => void;

export const useEvent = () => {
  const { matters } = useSmokeballApp();
  const { token } = useUser();
  const eventQueuer = formsApi.activityEventQueuer();

  const logEvent = useCallback<EventLogger>(
    (event) => {
      const matterId = matters?.current?.id;
      if (matterId) {
        eventQueuer
          .post({
            body: [
              {
                ...event,
                matterId,
                createdAt: new Date().toISOString(),
              },
            ],
            token,
          })
          .catch(console.error);
      }
    },
    [matters?.current?.id, token],
  );

  return { logEvent };
};

export type EventCapturerProps = { children: ReactNode };

export const EventCapturer = ({ children }: EventCapturerProps) => {
  const params = useParamsWithDefault((p) => ({
    id: p.id || "",
  }));
  const currentSubmissionId = useRef<string | undefined>(
    params.id.length > 0 ? params.id : undefined,
  );
  const { logEvent } = useEvent();

  const captureEvent = (event: Event) => {
    if (event.target instanceof HTMLElement) {
      if (
        event.target.dataset.enabledCapture === "true" &&
        event.target.dataset.capturedEventType === event.type
      ) {
        logEvent({
          type: event.target.dataset.eventDataType as EventDataType,
          activity: event.target.dataset.activityType as ActivityType,
          fieldId: event.target.dataset.fieldId,
          formId: event.target.dataset.formId,
          submissionId:
            event.target.dataset.submissionId || currentSubmissionId.current,
        });

        currentSubmissionId.current = event.target.dataset.submissionId;
      }
    }
  };

  const debouncedEventHandler = useDebounce(captureEvent, DEBOUNCING_DELAY);

  const handleVisibiltyEvent = () => {
    if (params.id) {
      logEvent({
        type: document.hidden ? "leave" : "view",
        activity: "view-submission",
        submissionId: params.id,
      });
    }
  };

  const handleCustomElementEvent = (event: Event) => {
    if (event.target instanceof HTMLElement) {
      logEvent({
        type: event.target.dataset.eventDataType as EventDataType,
        activity: event.target.dataset.activityType as ActivityType,
        fieldId: event.target.dataset.fieldId,
        formId: event.target.dataset.formId,
        submissionId:
          event.target.dataset.submissionId || currentSubmissionId.current,
      });

      currentSubmissionId.current = event.target.dataset.submissionId;
    }
  };

  const debouncedCustomEvenHandler = useDebounce(
    handleCustomElementEvent,
    DEBOUNCING_DELAY,
  );

  const eventHandlers: EventHandlerTuple[] = [
    ["click", debouncedEventHandler],
    ["focus", captureEvent],
    ["scroll", debouncedEventHandler, true],
    ["input", debouncedEventHandler],
    ["visibilitychange", handleVisibiltyEvent],
    ["custom_element_event", debouncedCustomEvenHandler],
  ];

  useEffect(() => {
    for (const eventHandler of eventHandlers) {
      document.addEventListener(
        eventHandler[0],
        eventHandler[1],
        eventHandler[2],
      );
    }

    return () => {
      for (const eventHandler of eventHandlers) {
        document.removeEventListener(eventHandler[0], eventHandler[1]);
      }
    };
  }, [logEvent, params.id]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export type EventForwarderProps<T extends CapturedEventType[]> = {
  eventTypes: T;
  eventDataTypeMap: Record<T[number], FormEventDataType>;
  activityType: ActivityType;
  children: ReactNode;
  submission?: { uri: string };
  form?: { uri: string } | null;
  fieldUri?: string;
};

export const EventForwarder = <T extends CapturedEventType[]>({
  eventTypes,
  eventDataTypeMap,
  activityType,
  submission,
  form,
  fieldUri,
  children,
}: EventForwarderProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleEvent = (event: Event) => {
    if (
      event.target instanceof HTMLElement &&
      event.target.dataset.enabledCapture !== "true"
    ) {
      event.target.dataset.eventDataType =
        eventDataTypeMap[event.type as T[number]];
      event.target.dataset.activityType = activityType;

      if (submission?.uri)
        event.target.dataset.submissionId = extractId(submission.uri);
      if (form?.uri) event.target.dataset.formId = extractId(form.uri);
      if (fieldUri) event.target.dataset.fieldId = extractId(fieldUri);

      event.target.dispatchEvent(
        new Event("custom_element_event", { bubbles: true }),
      );
    }
  };

  const debouncedEventHandler = useDebounce(handleEvent, DEBOUNCING_DELAY);

  useEffect(() => {
    for (const eventType of eventTypes) {
      if (ref && ref.current) {
        ref.current.addEventListener(eventType, debouncedEventHandler);
      }
    }

    return () => {
      for (const eventType of eventTypes) {
        if (ref && ref.current) {
          ref.current.removeEventListener(eventType, debouncedEventHandler);
        }
      }
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};
