import { isNotNullOrUndefined, unique } from "@smart/itops-utils-basic";
import { CachedGql } from "@smart/manage-cached-gql-operations-dom";

export const getUpdatedMatterTypeGroups = async ({
  isAdding,
  currentLocations,
  location,
  currentGroups,
  loadGroups,
  type,
}: {
  isAdding: boolean;
  currentLocations: string[];
  location: string;
  currentGroups: CachedGql.MatterTypeGroupsQuery["matterTypeGroups"];
  loadGroups: (
    variables: CachedGql.MatterTypeGroupsQueryVariables,
  ) => Promise<CachedGql.MatterTypeGroupsQuery["matterTypeGroups"]>;
  type: "lead" | "matter";
}) => {
  if (isAdding) {
    const updatedLocations = [...currentLocations, location];
    const availableGroups = await Promise.all(
      unique(currentGroups.map((group) => group.category)).map(
        async (category) => ({
          category,
          results: await loadGroups({
            category,
            type,
            locations: updatedLocations,
          }),
        }),
      ),
    );

    return currentGroups
      .map((group) =>
        availableGroups
          .find((available) => available.category === group.category)
          ?.results.find((available) => available.name === group.name),
      )
      .filter(isNotNullOrUndefined);
  }

  return currentGroups
    .map((group) => {
      const updatedTypes = group.matterTypes.filter(
        (matterType) => matterType.location !== location,
      );
      return updatedTypes.length
        ? { ...group, matterTypes: updatedTypes }
        : undefined;
    })
    .filter(isNotNullOrUndefined);
};
