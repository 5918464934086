import styled from "@emotion/styled";
import {
  autoUpdate,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { useState } from "react";

import { Icon } from "@smart/itops-icons-dom";

type TooltipProps = {
  children: JSX.Element;
  className?: string;
  placement?: Placement;
  offsetOptions?: {
    mainAxis: number;
    crossAxis: number;
  };
  target?: JSX.Element;
  iconSize?: number;
};

const TooltipWrapper = styled.span`
  display: inline-block;
  margin-bottom: -2px;
`;

const TooltipIcon = styled(Icon)`
  cursor: pointer;
`;
const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background.base};
  z-index: 1;
  border: solid ${(props) => props.theme.palette.disabled.base} 1px;
  border-radius: 0.5rem;
`;

const Tooltip = ({
  target,
  children,
  className,
  iconSize = 20,
  placement = "top-start",
  offsetOptions = { mainAxis: 0, crossAxis: 10 },
}: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(offsetOptions), shift({ padding: 10 })],
    whileElementsMounted: autoUpdate,
  });
  const { getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useDismiss(context),
  ]);

  return (
    <TooltipWrapper
      className={className}
      data-testid="tooltip-icon"
      ref={refs.setReference}
    >
      {target || <TooltipIcon size={iconSize} name="questionCircle" />}
      {open && (
        <ContentWrapper
          ref={refs.setFloating}
          {...getFloatingProps({
            style: {
              position: strategy,
              top: y ? y - 10 : 0,
              left: x ? x - 40 : 0,
            },
          })}
        >
          {children}
        </ContentWrapper>
      )}
    </TooltipWrapper>
  );
};

type TooltipContainerProps = {
  children: JSX.Element;
  content: JSX.Element | null;
  className?: string;
};

const TooltipContainer = ({
  children,
  content,
  className,
}: TooltipContainerProps) =>
  content ? (
    <Tooltip target={children} className={className}>
      {content}
    </Tooltip>
  ) : (
    children
  );

export { Tooltip, TooltipProps, TooltipContainer, TooltipContainerProps };
