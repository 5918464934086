import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import {
  MatchingContact,
  SBContact,
  SBContactType,
  createNewContactPlaceholder,
  lookupMatchingContacts,
} from "@smart/bridge-smokeball-basic";
import { Icon } from "@smart/itops-components-dom";
import { sortField } from "@smart/itops-utils-basic";
import { useLazyLoadContacts } from "@smart/manage-gql-client-dom";

import { ContactItem } from "./contact-item";

const ContactSelectWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.scheme.grey.r35};

  .role-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 2rem;
    border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r35};

    .warning-icon {
      color: ${(props) => props.theme.scheme.red.r80};
    }
  }

  .contact-item {
    padding-left: 2rem;
    :not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r35};
    }
  }
`;

type ContactSelectProps = {
  roleName: string;
  hasRoleContact: boolean;
  contact: Partial<Record<SBContactType, Record<string, any>>>;
  selected: string | undefined;
  onSelect: (selected: string) => void;
  searchContacts: ReturnType<typeof useLazyLoadContacts>;
  openContact: ((id: string) => void) | undefined;
};

export const ContactSelect = ({
  roleName,
  hasRoleContact,
  contact,
  selected,
  onSelect,
  searchContacts,
  openContact,
}: ContactSelectProps) => {
  const [matches, setMatches] = useState<MatchingContact[]>([]);

  useEffect(() => {
    if (contact && !hasRoleContact) {
      lookupMatchingContacts({
        input: contact,
        search: async ({ names, emails, phones }) => {
          const searchResult = await searchContacts({
            names,
            emails,
            phones,
          });

          return (searchResult as SBContact[]) || [];
        },
      })
        .then((result) =>
          setMatches(sortField(result, { key: "rank", dir: "desc" })),
        )
        .catch(console.error);
    }
  }, [contact, hasRoleContact]);

  return (
    <ContactSelectWrapper data-testid={`role-section-${roleName}`}>
      <div className="role-info">
        {!selected && (
          <Icon name="exclamationCircleFilled" className="warning-icon" />
        )}
        <h2>{roleName}</h2>
      </div>
      <div className="contact-item">
        <ContactItem
          contact={contact}
          value={createNewContactPlaceholder}
          isChecked={selected === createNewContactPlaceholder}
          onChange={() => onSelect(createNewContactPlaceholder)}
          label={
            hasRoleContact ? "Update existing contact" : "Create a new contact"
          }
          openContact={undefined}
        />
      </div>
      {matches.map((match) => (
        <div key={match.id} className="contact-item">
          <ContactItem
            contact={match}
            value={match.id}
            isChecked={!!selected && selected === match.id}
            onChange={() => onSelect(match.id)}
            label="Use this existing contact"
            openContact={openContact}
          />
        </div>
      ))}
    </ContactSelectWrapper>
  );
};
