import clsx from "clsx";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import {
  getGroupClassNames,
  getLabelsClassNames,
  getRadioButtonClassNames,
  getRadioCardClassNames,
} from "./class-names";
import { Label } from "../label";

export type RadioButtonOptionItem = {
  label: string;
  subtitle?: string;
  detail?: ReactNode;
  value: string;
};

export type RadioButtonsProps = {
  className?: string;
  options: RadioButtonOptionItem[];
  value: string | undefined;
  onChange: (value: string) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  box?: boolean;
  direction?: "row" | "column";
  align?: "start" | "end" | "center";
  label?: string;
  mandatory?: boolean;
  message?: string;
};

const BaseRadioButtons = (
  {
    className,
    options,
    value,
    onChange,
    id,
    name,
    error,
    disabled,
    readOnly,
    box,
    direction,
    align,
    label,
    mandatory,
    message,
  }: RadioButtonsProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const labelsClassNames = getLabelsClassNames({
    disabled,
    box,
  });

  return (
    <Label
      text={label}
      mandatory={mandatory}
      disabled={readOnly}
      name={id}
      error={!!error}
      message={message}
    >
      <div
        id={id}
        className={twMerge(
          clsx(getGroupClassNames({ align, direction }), className),
        )}
      >
        {options.map((o, idx) => (
          <label
            key={o.value}
            tabIndex={-1}
            aria-disabled={disabled}
            className={twMerge(
              getRadioCardClassNames({
                readOnly,
                disabled,
                box,
                error,
              }),
            )}
          >
            <input
              id={id && `${id}-${o.value}`}
              ref={idx === 0 ? ref : undefined}
              name={name}
              type="radio"
              value={o.value}
              checked={o.value === value}
              onChange={(e) => !readOnly && onChange(e.currentTarget.value)}
              disabled={disabled}
              className="appearance-none outline-none"
            />
            <div
              className={twMerge(
                getRadioButtonClassNames({
                  checked: o.value === value,
                  disabled,
                  error,
                }),
              )}
            />
            <div className={labelsClassNames.labelGroupClassNames}>
              <div className={labelsClassNames.titleClassNames}>{o.label}</div>
              {o.subtitle && <div>{o.subtitle}</div>}
              {o.detail && <div>{o.detail}</div>}
            </div>
          </label>
        ))}
      </div>
    </Label>
  );
};

export const RadioButtons = forwardRef(BaseRadioButtons);
