import { clsx } from "clsx";
import { ReactNode } from "react";

import { Icon } from "../icon";
import { RequiredAsterisk } from "../required-asterisk";

type LabelProps = {
  text?: string;
  name?: string;
  children: ReactNode;
  className?: string;
  message?: string | ReactNode;
  error?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
};

export const Label = ({
  text,
  name,
  children,
  className,
  message,
  error,
  disabled,
  mandatory,
}: LabelProps) => {
  const textClassName = clsx(
    "text-ui font-semibold",
    disabled && "text-neutral-400",
  );

  const messageClassName = clsx(
    "flex items-center gap-1 mt-2",
    disabled && "text-neutral-400",
    error ? "text-red-550" : "text-neutral-600",
  );

  return (
    <label className={className} htmlFor={name}>
      {text && (
        <div className="flex items-center gap-1 mb-2">
          <div className={textClassName}>{text}</div>
          {mandatory && <RequiredAsterisk />}
        </div>
      )}
      {children}
      {message && (
        <div className={messageClassName}>
          <Icon className="w-4 h-4 mr-2" name="regularCircleExclamation" />
          <div className="text-caption font-normal">{message}</div>
        </div>
      )}
    </label>
  );
};
