import { useMutation } from "@apollo/client";

import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useUploadFiles = () => useMutation(mutationDocuments.uploadFiles);

export const useUploadQuestionGenerationFiles = () =>
  useMutation(mutationDocuments.uploadQuestionGenerationFiles);

export const useLoadMatterFiles = useQueryFactory(queryDocuments.matterFiles, {
  map: ({ matterFiles }) => matterFiles,
});

export const useLoadMatterFile = useQueryFactory(queryDocuments.matterFile, {
  map: ({ matterFile }) => matterFile,
});
