import { RenderElementProps } from "slate-react";

import { isEditorElementType } from "@smart/itops-serialisation-basic";

import { EditorLink } from "./link";
import { EditorPlaceholder } from "./placeholder/editor-placeholder";

const Element = ({ attributes, children, element }: RenderElementProps) => {
  if (isEditorElementType("link")(element)) {
    return (
      <EditorLink attributes={attributes} element={element}>
        {children}
      </EditorLink>
    );
  }

  if (isEditorElementType("placeholder")(element)) {
    return (
      <EditorPlaceholder attributes={attributes} element={element}>
        {children}
      </EditorPlaceholder>
    );
  }

  return <p {...attributes}>{children}</p>;
};

export { Element };
