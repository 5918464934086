import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { loadEnv } from "@smart/bridge-env-dom";
import { trackChanged } from "@smart/bridge-metrics-dom";
import {
  familyProQuestionnaireFormUri,
  FormNS,
} from "@smart/bridge-types-basic";
import { Overlay } from "@smart/itops-components-dom";
import {
  useDocumentVisibility,
  useSearchWithDefault,
} from "@smart/itops-hooks-dom";
import { MatterTabs, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { extractId } from "@smart/itops-types-basic";
import { Region } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  submissionLink as getSubmissionLink,
} from "@smart/manage-gql-client-dom";
import { useToast } from "@smart/manage-hooks-dom";

import { TabCreateAndSend } from "./create-and-send";
import { TabList } from "./list";
import { GenerateQRCode } from "../shared";
import { SubmissionActions } from "../submission/actions";
import { ReviewContactModal } from "../submission/submissionV2/review-contact-modal";
import { SubmissionView } from "../submission/view";

export const TabView = () => {
  const { sharingFormId, isInternal } = useSearchWithDefault((p) => ({
    sharingFormId: p.get("sharingFormId") || "",
    isInternal: !!p.get("isInternal"),
  }));

  const { matters } = useSmokeballApp();
  const { setToasts } = useToast();
  const { host } = useSmokeballApp();
  const navigate = useNavigate();
  const [sharing, onShare] = useState<"regular" | "internal" | undefined>(
    undefined,
  );
  const [viewing, onView] = useState<MatterSubmission>();
  const [cancelling, onCancel] = useState<MatterSubmission>();
  const [deleting, onDelete] = useState<MatterSubmission>();
  const [reminding, onRemind] = useState<MatterSubmission>();
  const [editing, onEdit] = useState<MatterSubmission>();
  const [generatingQRCode, onGenerateQRCode] = useState<MatterSubmission>();
  const [submissionLink, setSubmissionLink] = useState<string>();

  useDocumentVisibility({
    onHide: () => {
      onShare(undefined);
      onView(undefined);
      onCancel(undefined);
      onDelete(undefined);
      onRemind(undefined);
      onGenerateQRCode(undefined);
    },
  });

  if (!matters?.current && !matters?.loading)
    return (
      <Overlay
        icon="form"
        heading="Intake not available"
        subHeading="No matter information currently available"
      />
    );

  trackChanged(matters.current, "Submissions Listed", (matter) => {
    if (!matter) return undefined;

    return {
      matterCategory: matter.isLead ? "lead" : "matter",
      matterTypeId: matter.matterTypeId,
    };
  });

  trackChanged(viewing, "Submission Viewed", (submission) => {
    if (!submission || submission.formUri === familyProQuestionnaireFormUri)
      return undefined;

    return {
      submissionUri: submission.uri,
      formUri: submission.formUri,
      matterCategory: submission.rawSubmission.form.values.category,
      matterTypeId: matters.current?.matterTypeId,
      matterTypeName: submission.rawSubmission.form.values.matterTypes.find(
        (mt) => mt.id === matters.current?.matterTypeId,
      )?.name,
    };
  });

  useEffect(() => {
    if (!generatingQRCode) {
      setSubmissionLink(undefined);
    } else {
      setSubmissionLink(
        generatingQRCode.formUri === familyProQuestionnaireFormUri
          ? generatingQRCode.externalSubmissionLink
          : getSubmissionLink(generatingQRCode.rawSubmission),
      );
    }
  }, [generatingQRCode]);

  const isPendingContactReview = (submission: MatterSubmission | undefined) =>
    submission?.rawSubmission?.values?.syncStatus === "pendingContactReview";

  const isFamilyProForm = (submission: MatterSubmission) =>
    submission.formUri === familyProQuestionnaireFormUri;

  const openFamilyProForm = (submission: MatterSubmission) => {
    const isUsRegion = (loadEnv("Region") as Region) === "nova";

    if (isUsRegion) {
      host?.selectTab(MatterTabs.FamilyProperty);
    } else {
      host?.open({
        url: submission.externalSubmissionEmbedUrl || "",
        title: "Questionnaire",
      });
    }
  };

  const onViewAndEdit = (
    submission: MatterSubmission,
    tab: "view" | "edit",
  ) => {
    if (isPendingContactReview(submission)) return;

    if (isFamilyProForm(submission)) {
      openFamilyProForm(submission);
    } else {
      navigate(`/embedded/tab/${extractId(submission.uri)}?tab=${tab}`);
    }
    onView(undefined);
    onEdit(undefined);
  };

  useEffect(() => {
    const submission = viewing || editing;
    const tab = viewing ? "view" : "edit";
    if (submission) {
      onViewAndEdit(submission, tab);
    }
  }, [viewing, editing]);

  useEffect(() => {
    if (sharingFormId) {
      onShare(isInternal ? "internal" : "regular");
    }
  }, [sharingFormId, isInternal]);

  return (
    <>
      <TabList
        onView={onView}
        onViewForm={(form) =>
          navigate(`/embedded/builder/${extractId(form.uri)}`)
        }
        onCancel={onCancel}
        onDelete={onDelete}
        onRemind={onRemind}
        onEdit={onEdit}
        onShare={onShare}
        onGenerateQRCode={onGenerateQRCode}
      />
      <TabCreateAndSend
        sharing={sharing}
        sharingFormUri={FormNS.generateUri(sharingFormId)}
        onClose={() => onShare(undefined)}
        onEditSubmission={onEdit}
      />
      <SubmissionView
        onClose={() => onView(undefined)}
        onCancel={onCancel}
        onDelete={onDelete}
        onRemind={onRemind}
        onEdit={onEdit}
      />
      <SubmissionActions
        onViewClose={() => onView(undefined)}
        cancelling={{
          selected: cancelling,
          onClose: () => onCancel(undefined),
        }}
        deleting={{
          selected: deleting,
          onClose: () => onDelete(undefined),
        }}
        reminding={{
          selected: reminding,
          onClose: () => onRemind(undefined),
        }}
        editing={{
          onClose: () => onEdit(undefined),
        }}
      />
      <GenerateQRCode
        link={submissionLink}
        onClose={() => onGenerateQRCode(undefined)}
        onCopied={() =>
          setToasts([
            {
              text: "QR code copied!",
            },
          ])
        }
      />
      <ReviewContactModal
        onClose={() => onView(undefined)}
        matterSubmission={isPendingContactReview(viewing) ? viewing : undefined}
      />
    </>
  );
};
