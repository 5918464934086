import { Modal } from "@smart/itops-sb-design-system-dom";

type ClearFormProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
};
export const ClearForm = ({ open, onSubmit, onClose }: ClearFormProps) => (
  <Modal
    header={{
      icon: "solidCircleExclamation",
      iconBgColor: "bg-cyan-140",
      text: "Clear Form",
    }}
    footer={{
      buttons: [
        {
          text: "Cancel",
          variant: "secondarySubtle",
          onClick: onClose,
        },
        {
          text: "Clear form",
          variant: "primaryDefault",
          onClick: onSubmit,
        },
      ],
    }}
    open={open}
    onClose={onClose}
    closeOptions={{
      escapeKey: false,
      clickOutside: false,
    }}
  >
    <p>
      This will remove any answers entered into the form and can not be undone.
    </p>
    <p>Are you sure you want to clear it?</p>
  </Modal>
);
