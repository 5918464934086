import { Editor, Transforms } from "slate";

const createPlaceholderNode = ({
  name,
  displayText,
  replacingValue,
  casing,
}: {
  name: string;
  displayText?: string;
  replacingValue?: string;
  casing?: "lowercase";
}) => ({
  type: "placeholder" as const,
  name,
  displayText,
  replacingValue,
  casing,
  children: [{ text: "" }],
});

export const insertPlaceholder = ({
  editor,
  name,
  displayText,
  replacingValue,
  casing,
}: {
  editor: Editor;
  name: string;
  displayText?: string;
  replacingValue?: string;
  casing?: "lowercase";
}) => {
  if (!name) return;

  const placeholder = createPlaceholderNode({
    name,
    displayText,
    replacingValue,
    casing,
  });
  Transforms.insertNodes(editor, placeholder);
};
