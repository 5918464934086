import { useEffect, useState } from "react";

import { logError, SDKError } from "@smart/itops-utils-basic";

import {
  LayoutMatterItem,
  UpdateLayoutMatterItemRequest,
  UseSDKApiOptions,
} from "../types";

export const useLayouts = ({ client, isInit, observe }: UseSDKApiOptions) => {
  const [currentLayouts, setCurrentLayouts] = useState<LayoutMatterItem[]>();
  const shouldObserve = observe?.includes("layouts");

  useEffect(() => {
    if (isInit && client && shouldObserve && client.context?.matterId) {
      client.layouts
        .get(client.context.matterId)
        .then((layoutMatter) => setCurrentLayouts(layoutMatter.value))
        .catch((err) =>
          logError(
            new SDKError(err, {
              action: "layouts.get",
              input: { matterId: client.context.matterId },
            }),
          ),
        );
      client.layouts.observe((layoutMatter) =>
        setCurrentLayouts(layoutMatter.value),
      );
    }
  }, [isInit, client?.context?.matterId, shouldObserve]);

  if (!isInit || !client) return undefined;

  return {
    get: async (matterId?: string) => {
      try {
        return await client.layouts.get(matterId);
      } catch (error) {
        throw new SDKError(error, {
          action: "layouts.get",
          input: { matterId },
        });
      }
    },
    update: async ({
      itemId,
      layoutId,
      matterId,
      values,
    }: UpdateLayoutMatterItemRequest) => {
      try {
        let id: string | undefined;
        if (itemId) {
          id = itemId;
        } else if (layoutId) {
          const layout = currentLayouts?.find(
            (l) => l.layoutDesign.id === layoutId,
          );
          if (layout) id = layout.itemId;
        }

        if (!id) throw new Error("Item id not found");

        const input = {
          values,
          matterId,
          itemId: id,
        };

        const result = await client.layouts.update(input);
        const updatedLayout = result.value?.find((l) => l.itemId === id);
        const failedUpdates = values.filter(({ key, value }) => {
          const updatedValue = updatedLayout?.values.find(
            (updated) => updated.key === key || updated.key.includes(key),
          )?.value;
          return (
            (updatedValue ?? "").toLowerCase() !== (value ?? "").toLowerCase()
          );
        });
        if (failedUpdates.length) {
          throw new Error(
            `Fields did not update - ${failedUpdates
              .map((u) => u.key)
              .join(", ")}`,
          );
        }

        return result;
      } catch (error) {
        throw new SDKError(error, {
          action: "layouts.update",
          input: { itemId, layoutId, matterId, values },
        });
      }
    },
    current: currentLayouts,
  };
};
