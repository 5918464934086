import styled from "@emotion/styled";

const ToggleWrapper = styled.label<{
  size: number;
  flow: "row" | "column";
  disabled: boolean;
  isLoading?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  display: flex;
  flex-flow: ${(props) => props.flow};
  align-items: center;

  .toggle-label {
    flex: 1;

    .toggle-label-text {
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: 600;
      margin: 1rem 0;
    }

    p {
      font-size: ${(props) => props.theme.fontSize.base};
      margin: 1rem;
    }
  }

  input {
    display: none;
  }

  .toggle {
    background: ${(props) => props.theme.scheme.grey.r35};
    border-radius: 100rem;

    opacity: ${(props) => (props.isLoading ? 0 : 1)};
    position: relative;
    width: ${(props) => props.size}rem;
    height: ${(props) => props.size * 0.6}rem;
    transition: background 0.4s ease, opacity: 0.4s ease;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: ${(props) => props.size * 0.08}rem;
      left: ${(props) => props.size * 0.08}rem;
      width: ${(props) => props.size * 0.44}rem;
      height: ${(props) => props.size * 0.44}rem;
      background: ${(props) => props.theme.scheme.grey.r5};
      border-radius: 100rem;
      transition: left 0.4s ease;
    }
  }

  input:checked + .toggle {
    background: ${(props) => props.theme.scheme.blue.r100};

    &::after {
      left: ${(props) => props.size * 0.48}rem;
    }
  }
`;

export type ToggleProps = {
  text?: string;
  sub?: string;
  title?: string;
  toggled: boolean;
  onClick: () => void;
  size?: number;
  flow?: "row" | "column";
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  dataTestId?: string;
};

export const Toggle = ({
  text,
  sub,
  title,
  toggled,
  onClick,
  size,
  flow,
  disabled = false,
  loading,
  className,
  dataTestId,
}: ToggleProps) => (
  <ToggleWrapper
    size={size || 6}
    flow={flow || "row"}
    title={title}
    disabled={disabled}
    className={className}
    isLoading={loading}
  >
    {(text || sub) && (
      <div className="toggle-label">
        {text && <h4 className="toggle-label-text">{text}</h4>}
        {sub && <p>{sub}</p>}
      </div>
    )}
    <input
      type="checkbox"
      disabled={disabled}
      checked={toggled}
      onChange={() => onClick()}
      data-testid={dataTestId || "toggle"}
    />
    <div className="toggle" />
  </ToggleWrapper>
);
