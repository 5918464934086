/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterQuestionnaireStatusQueryVariables = Types.Exact<{
  matterId: Types.Scalars["String"]["input"];
  party: Types.GqlMatterQuestionnaireParty;
}>;

export type GqlMatterQuestionnaireStatusQuery = {
  __typename: "Query";
  matterQuestionnaireStatus?: {
    __typename: "MatterQuestionnaireStatus";
    id: string;
    link?: string | null;
    embedUrl: string;
    status: Types.GqlMatterQuestionnaireStatusValues;
    submitted?: string | null;
    sent?: string | null;
    updated?: string | null;
    questionnaireType?: Types.GqlQuestionnaireType | null;
  } | null;
};

export const MatterQuestionnaireStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "matterQuestionnaireStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "party" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MatterQuestionnaireParty" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "matterQuestionnaireStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "party" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "party" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "matterQuestionnaireStatusFields",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "matterQuestionnaireStatusFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatterQuestionnaireStatus" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "link" } },
          { kind: "Field", name: { kind: "Name", value: "embedUrl" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "submitted" } },
          { kind: "Field", name: { kind: "Name", value: "sent" } },
          { kind: "Field", name: { kind: "Name", value: "updated" } },
          { kind: "Field", name: { kind: "Name", value: "questionnaireType" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlMatterQuestionnaireStatusQuery,
  GqlMatterQuestionnaireStatusQueryVariables
>;
