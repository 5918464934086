import { Overlay } from "@smart/itops-components-dom";

const NotFound = () => <Overlay icon="warning" subHeading="Page Not Found" />;

const ConditionalNotFound = ({
  show,
  element,
}: {
  show: boolean | undefined;
  element: JSX.Element;
}) => (show ? element : <NotFound />);

export { NotFound, ConditionalNotFound };
