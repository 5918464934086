/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlFamilyProAuthorizationStateQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GqlFamilyProAuthorizationStateQuery = {
  __typename: "Query";
  familyProAuthorizationState?: {
    __typename: "FamilyProAuthorizationState";
    isAuthorized: boolean;
  } | null;
};

export const FamilyProAuthorizationStateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "familyProAuthorizationState" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "familyProAuthorizationState" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "familyProAuthorizationStateFields",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "familyProAuthorizationStateFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FamilyProAuthorizationState" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "isAuthorized" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlFamilyProAuthorizationStateQuery,
  GqlFamilyProAuthorizationStateQueryVariables
>;
