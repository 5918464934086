import { clsx } from "clsx";
import { MouseEvent } from "react";
import { twMerge } from "tailwind-merge";

import { getTagVariants, TagVariant } from "./class-names";
import { Icon, IconName } from "../icon";

type TagProps = {
  variant?: TagVariant;
  text: string;
  leftIcon?: IconName;
  onClose?: (event: MouseEvent<SVGSVGElement>) => void;
  disabled?: boolean;
};

export const Tag = ({
  variant = "default",
  text,
  leftIcon,
  onClose,
  disabled,
}: TagProps) => (
  <span
    className={twMerge(
      getTagVariants({ variant, disabled, closeable: !!onClose }),
    )}
  >
    {leftIcon && (
      <Icon
        name={leftIcon}
        className={clsx(variant === "compact" && "w-[1rem] h-[1rem]")}
      />
    )}
    <span
      className={clsx(
        variant === "default" && "text-ui",
        variant === "compact" && "text-caption",
      )}
    >
      {text}
    </span>
    {onClose && (
      <Icon
        name="regularXmark"
        className={clsx(!disabled && "cursor-pointer")}
        onClick={onClose}
      />
    )}
  </span>
);
