/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlRoleFieldsFragment = {
  __typename: "Role";
  id: string;
  name: string;
  contactId?: string | null;
  representativeIds?: Array<string> | null;
  isClient?: boolean | null;
  isOtherSide?: boolean | null;
};

export const RoleFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "roleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Role" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "contactId" } },
          { kind: "Field", name: { kind: "Name", value: "representativeIds" } },
          { kind: "Field", name: { kind: "Name", value: "isClient" } },
          { kind: "Field", name: { kind: "Name", value: "isOtherSide" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlRoleFieldsFragment, unknown>;
