import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { FormNS, SectionNS } from "@smart/bridge-types-basic";
import { loadLocale } from "@smart/itops-locale-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { Modal } from "@smart/itops-ui-dom";
import { rankBetween } from "@smart/itops-utils-basic";
import {
  useAddFormMatterType,
  useGenerateForm,
  useLoadMatterTypeCategories,
  useQueryMatterTypes,
  useUpdateForm,
  useUpdateSection,
} from "@smart/manage-gql-client-dom";
import { useManageFormsContext, useUser } from "@smart/manage-hooks-dom";

import { FormFieldsList } from "./form-field-list";
import { GqlForm, GqlSetting } from "../../types";
import {
  CreatingFormOptions,
  creatingFormDefaults,
  creatingFormSchema,
  formCategoryOptions,
  formCreationOptions,
} from "../form";

export type CreatingFormModalProps = {
  creatingForm: boolean | undefined;
  forms: GqlForm[];
  setting?: GqlSetting;
  onClose: () => void;
};

export const CreatingFormModal = ({
  creatingForm,
  forms,
  setting,
  onClose,
}: CreatingFormModalProps) => {
  const { terms, states } = loadLocale();
  const { generateFormFromFile, aiWordSupport } = useFeatureFlags();
  const providerInfo = useProviderInfo();
  const navigate = useNavigate();
  const [updateForm] = useUpdateForm();
  const [addFormMatterType] = useAddFormMatterType();
  const [updateSection] = useUpdateSection();
  const { actingTeam } = useManageFormsContext();
  const [generateForm] = useGenerateForm();
  const { user } = useUser();
  const { result: availableAreasOfLaw, loading: loadingAvailableAreasOfLaw } =
    useLoadMatterTypeCategories({ teamUri: setting?.teamUri }, { skip: !user });

  const formSchema = creatingFormSchema({ terms });
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<CreatingFormOptions>({
    defaultValues: creatingFormDefaults,
    resolver: zodResolver(
      formSchema.superRefine((data, ctx) => {
        if (
          forms.find(
            (f) =>
              data.formName.toLocaleLowerCase() ===
              f.formTitle.toLocaleLowerCase(),
          )
        )
          ctx.addIssue({
            path: ["formName"],
            code: "custom",
            message: "A form with this name already exists",
          });

        if (data.creationType === "upload" && !data.filePath)
          ctx.addIssue({
            path: ["filePath"],
            code: "custom",
            message: "Please upload a file",
          });
      }),
    ),
  });

  useEffect(() => {
    if (setting?.locations?.length === 1)
      setValue("locations", setting.locations);
  }, [setting?.locations]);

  const [formCategory, locations, creationType] = watch([
    "formCategory",
    "locations",
    "creationType",
  ]);

  const creationTypeOptions = formCreationOptions({
    terms,
    isSmokeballProvider: providerInfo?.label.toLowerCase() === "smokeball",
  });

  const matterTypeOptions = useQueryMatterTypes({
    type: formCategory,
    locations,
    categories:
      availableAreasOfLaw?.filter((areaOfLaw) =>
        setting?.areasOfLaw?.includes(areaOfLaw),
      ) || [],
  });
  const onSubmit = handleSubmit(async (values) => {
    const formUri = FormNS.generateUri();
    const sectionUri = SectionNS.generateUri();
    const creation = generateFormFromFile ? values.creationType : "blank";

    await updateForm({
      variables: {
        uri: formUri,
        teamUri: actingTeam?.uri,
        fields: {
          title: values.formName,
          category: values.formCategory,
          source: (
            {
              blank: "manual",
              generate: "aiPrompt",
              upload: "aiTemplate",
            } as const
          )[creation],
          creationStatus: {
            blank: undefined,
            generate: "generatingQuestions",
            upload: "fileUploaded",
          }[creation],
          response: "",
        },
      },
    });

    const matterTypes = values.matterTypeGroups.flatMap((g) => g.matterTypes);
    for (const matterType of matterTypes) {
      await addFormMatterType({
        variables: {
          formUri,
          matterType,
        },
      });
    }

    if (creation === "blank") {
      await updateSection({
        variables: {
          formUri,
          uri: sectionUri,
          fields: {
            title: "Section",
          },
          order: rankBetween({}),
        },
      });
    }

    if (creation !== "blank") {
      await generateForm({
        variables: {
          formUri,
          fields: {
            title: values.formName,
            matterTypeIds: matterTypes.map((m) => m.id),
            filePath: values.filePath,
          },
        },
      });
    }

    navigate(`/embedded/builder/${extractId(formUri)}`);
  });

  useEffect(() => {
    if (!creatingForm) reset(creatingFormDefaults);
  }, [creatingForm]);

  return (
    <form onSubmit={onSubmit}>
      <Modal
        onClose={onClose}
        open={!!creatingForm}
        loading={loadingAvailableAreasOfLaw || isSubmitting}
        dataTestId="creating-form-modal"
        header={{
          icon: { library: "lucide", name: "Zap", variant: "action" },
          text: "New intake form",
        }}
        footer={{
          left: [
            {
              text: "Cancel",
              variant: "cancel",
              onClick: onClose,
            },
          ],
          right: [
            {
              text: `Create form`,
              type: "submit",
              variant: "save",
            },
          ],
        }}
      >
        <FormFieldsList
          control={control}
          formCategoryOptions={formCategoryOptions}
          formName
          locations={setting?.locations || states.map((s) => s.name)}
          matterTypeOptions={matterTypeOptions.byCategory.flatMap((c) =>
            c.loading
              ? [{ category: c.category, name: "Loading..." }]
              : c.matterTypes,
          )}
          creationTypeOptions={
            generateFormFromFile ? creationTypeOptions : undefined
          }
          fileUpload={creationType === "upload"}
          aiWordSupport={aiWordSupport}
        />
      </Modal>
    </form>
  );
};
