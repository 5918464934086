import { globalTeamUri } from "@smart/bridge-types-basic";

import { RuleEvaluators } from "../types";
import { hasRole } from "../utils";

export const slug: RuleEvaluators["slug"] = (
  user,
  action,
  { value: { formUri }, parentForm: { uri, teamUri } },
) => {
  // Mismatch between slug and form fails
  if (formUri !== uri) return false;

  // Users can perform all actions on slugs for their team
  if (teamUri === user.teamUri) return true;

  // All users can view slugs for global forms
  if (teamUri === globalTeamUri && action === "view") return true;

  // Users with the global role can perform all actions on all slugs
  if (hasRole(user, "CanUpdateGlobalIntakeForms")) return true;

  // Otherwise, no access
  return false;
};
