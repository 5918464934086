import { useMutation } from "@apollo/client";

import {
  Gql,
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

export const useAddFormMatterType = () =>
  useMutation(mutationDocuments.addFormMatterType, {
    update: (cache, { data }, { variables }) => {
      if (data?.addFormMatterType && variables) {
        cache.updateQuery(
          { query: queryDocuments.form, variables: { uri: variables.formUri } },
          (ex) => {
            if (!ex?.form) return ex;

            const exists = ex.form.values.matterTypes.find(
              (m) => m.id === variables.matterType.id,
            );
            if (exists) return ex;

            return {
              form: {
                ...ex.form,
                values: {
                  ...ex.form.values,
                  matterTypes: [
                    ...ex.form.values.matterTypes,
                    {
                      ...variables.matterType,
                      __typename: "MatterType" as const,
                    },
                  ],
                },
              },
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useRemoveFormMatterType = () =>
  useMutation(mutationDocuments.removeFormMatterType, {
    update: (cache, { data }, { variables }) => {
      if (data?.removeFormMatterType && variables) {
        cache.updateQuery(
          { query: queryDocuments.form, variables: { uri: variables.formUri } },
          (ex) => {
            if (!ex?.form) return ex;

            const exists = ex.form.values.matterTypes.find(
              (m) => m.id === variables.matterType.id,
            );
            if (!exists) return ex;

            return {
              form: {
                ...ex.form,
                values: {
                  ...ex.form.values,
                  matterTypes: ex.form.values.matterTypes.filter(
                    (f) => f.id !== variables.matterType.id,
                  ),
                },
              },
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useUpdateFormMatterTypes = () => {
  const [add, adding] = useAddFormMatterType();
  const [remove, removing] = useRemoveFormMatterType();

  return [
    ({
      formUri,
      existing,
      updated,
    }: {
      formUri: string;
      existing: Gql.FormFieldsFragment["values"]["matterTypes"];
      updated: Gql.FormFieldsFragment["values"]["matterTypes"];
    }) => {
      const toRemove = existing.filter(
        (e) => !updated.find((u) => u.id === e.id),
      );
      const toAdd = updated.filter((u) => !existing.find((e) => e.id === u.id));

      return Promise.all([
        ...toRemove.map(
          ({ id, category, location, name, source, representativeOptions }) =>
            remove({
              variables: {
                formUri,
                matterType: {
                  id,
                  category,
                  location,
                  name,
                  source,
                  representativeOptions,
                },
              },
            }),
        ),
        ...toAdd.map(
          ({ id, category, location, name, source, representativeOptions }) =>
            add({
              variables: {
                formUri,
                matterType: {
                  id,
                  category,
                  location,
                  name,
                  source,
                  representativeOptions,
                },
              },
            }),
        ),
      ]);
    },
    { loading: adding.loading || removing.loading },
  ] as const;
};
