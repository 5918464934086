import styled from "@emotion/styled";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { MeetingType } from "@smart/bridge-types-basic";
import {
  Button,
  EditableText,
  ProgressOverlay,
  Spinner,
} from "@smart/itops-ui-dom";
import { DeepPartial, localTimeZoneCode } from "@smart/itops-utils-basic";
import {
  useDeleteField,
  useDeleteGroup,
  useUpdateAIUserFeedback,
  useUpdateGroup,
} from "@smart/manage-gql-client-dom";

import { Field } from "./field";
import { GeneratingForm } from "./generating-form";
import { Group } from "./group";
import { Separator } from "./separator";
import { FormLoadingStatus } from "../../create-form/creation-status";
import {
  sectionItemContainerDroppablePrefix,
  sectionItemsDroppableId,
} from "../constants";
import { useUpdateData } from "../hooks";
import {
  ConditionActions,
  EditableSection,
  FieldTemplate,
  GqlFieldValues,
  GqlForm,
  GqlMatterLayout,
  GqlMatterType,
  GqlSectionItem,
  LoadMatterFields,
  QuestionEditMode,
  isSectionItemField,
} from "../types";
import { getDefaultAvailability } from "../utils";

const QuestionsWrapper = styled.div`
  flex: 1;
  --background: ${(props) => props.theme.scheme.grey.r20};
  background: var(--background);
  padding: 0 2rem 2rem 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  overflow-y: auto;

  .section-title {
    &[aria-disabled="true"] {
      display: none;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${(props) => props.theme.breakPoints.readable}px;
    margin: ${(props) => props.theme.baseUnit * 2}rem auto
      ${(props) => props.theme.baseUnit}rem auto;
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  &[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }

  .spinner {
    margin: 2rem auto;
  }
`;

type QuestionsProps = {
  sectionItems: GqlSectionItem[];
  loading: {
    sections: boolean;
    groups: boolean;
    fields: boolean;
  };
  form?: GqlForm | null;
  section?: EditableSection;
  mode?: QuestionEditMode;
  editingItemUri?: string;
  updatingItems: Record<string, boolean | undefined>;
  mergeUpdateField: ReturnType<typeof useUpdateData>["mergeUpdateField"];
  updateGroup: ReturnType<typeof useUpdateGroup>[0];
  deleteField: ReturnType<typeof useDeleteField>[0];
  deleteGroup: ReturnType<typeof useDeleteGroup>[0];
  updateSection: (
    section: EditableSection,
    values: { title: string },
  ) => Promise<void>;
  onUpdatingFieldDone: () => void;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  loadMatterFields: LoadMatterFields;
  loadingStatus: FormLoadingStatus;
  creationStatus?: string | null;
  conditionActions: ConditionActions;
  setSelectedFieldToMap: React.Dispatch<
    React.SetStateAction<GqlFieldValues | undefined>
  >;
  isValidSection: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

const Questions = ({
  form,
  section,
  sectionItems,
  loading,
  mode,
  editingItemUri,
  updatingItems,
  mergeUpdateField,
  updateGroup,
  deleteField,
  updateSection,
  onUpdatingFieldDone,
  matterLayouts,
  matterTypes,
  updateAIUserFeedback,
  loadMatterFields,
  deleteGroup,
  loadingStatus,
  creationStatus,
  conditionActions,
  setSelectedFieldToMap,
  isValidSection,
  excludingFieldTemplates,
}: QuestionsProps) => {
  const [editingTitle, setEditingTitle] = useState(
    section?.values?.title || "Section",
  );

  useEffect(() => {
    setEditingTitle(section?.values?.title || "Section");
  }, [section]);

  const onUpdateField = async ({
    field,
    updated,
  }: {
    field: GqlFieldValues;
    updated: DeepPartial<GqlFieldValues>;
  }) => {
    await mergeUpdateField({ current: field, incoming: updated }).catch(
      console.error,
    );
    onUpdatingFieldDone();
  };

  const onDeleteField = async (field: GqlFieldValues) => {
    await deleteField({
      variables: {
        uri: field.uri,
        sectionUri: field.sectionUri,
        formUri: field.formUri,
      },
    }).catch(console.error);
    onUpdatingFieldDone();
  };

  const onFieldTypeChange = async ({
    field,
    newFieldType,
  }: {
    field: GqlFieldValues;
    newFieldType: FieldComponentType;
  }) => {
    if (
      newFieldType === "group" ||
      (newFieldType === field.type && !field.layout && !field.field)
    ) {
      return;
    }

    if (newFieldType === "mapped") {
      setSelectedFieldToMap(field);
      return;
    }

    const initialValues =
      newFieldType === "appointment"
        ? {
            duration: 15,
            availability: getDefaultAvailability(),
            timezone: localTimeZoneCode,
            meetingType: "inPerson" as MeetingType,
          }
        : undefined;

    await mergeUpdateField({
      current: field,
      incoming: {
        options:
          (newFieldType === "choice" || newFieldType === "checkbox") &&
          field.options
            ? field.options.map((option) => ({
                label: option.label,
                value: option.value,
              }))
            : [],
        type: newFieldType,
        layout: undefined,
        field: undefined,
        links: undefined,
        ...initialValues,
      },
    }).catch(console.error);
  };

  const disabled =
    loadingStatus === "checking" ||
    loadingStatus === "timedOut" ||
    form?.aiUserFeedback?.status === "generating" ||
    form?.aiUserFeedback?.status === "generated";

  return (
    <QuestionsWrapper data-testid="edit-questions">
      {section && updatingItems[section.uri] && (
        <ProgressOverlay position="top" size={0.3} indeterminate />
      )}
      <GeneratingForm
        updating={
          !!form?.aiUserFeedback && updatingItems[form.aiUserFeedback.uri]
        }
        title={form?.formTitle || ""}
        loadingStatus={loadingStatus}
        creationStatus={creationStatus}
        aiUserFeedback={form?.aiUserFeedback}
        updateAIUserFeedback={updateAIUserFeedback}
        form={form}
      />
      <div className="section-title" aria-disabled={disabled}>
        <EditableText
          dataTestId="section-title-edit"
          text={editingTitle}
          onChange={setEditingTitle}
          onComplete={async () => {
            if (section)
              await updateSection(section, { title: editingTitle || "" });
          }}
          disabled={disabled}
          loading={loading.sections}
          fontSize="heading"
          bold
        />
        {section && conditionActions.checkHasConditions(section.uri) && (
          <Button
            dataTestId="section-conditions-button"
            icon={{ library: "lucide", name: "Split", size: 16 }}
            kind="borderless"
            variant="highlightOrange"
            onClick={() =>
              section.values &&
              conditionActions.setEditingCondition(section.values)
            }
          />
        )}
      </div>
      <FieldsWrapper aria-disabled={disabled}>
        <Droppable droppableId={sectionItemsDroppableId} type="SECTION">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Separator index={0} isFieldReordering={mode === "reordering"} />
              {(loading.groups || loading.fields) && (
                <Spinner className="spinner" size={8} />
              )}
              {sectionItems.map((item, index) => (
                <div key={item.uri} data-testid="section-item-container">
                  <Droppable
                    droppableId={`${sectionItemContainerDroppablePrefix}-${item.uri}`}
                    type="GROUP"
                  >
                    {(droppableContainerProvided) => (
                      <div
                        ref={droppableContainerProvided.innerRef}
                        {...droppableContainerProvided.droppableProps}
                      >
                        <Draggable draggableId={item.uri} index={index}>
                          {(draggableProvided, snapshot) =>
                            isSectionItemField(item) ? (
                              <Field
                                mode={mode}
                                index={index}
                                item={item}
                                matterTypes={matterTypes}
                                onUpdateField={onUpdateField}
                                onDeleteField={onDeleteField}
                                matterLayouts={matterLayouts}
                                isDragging={snapshot.isDragging}
                                loadMatterFields={loadMatterFields}
                                draggableProvided={draggableProvided}
                                loading={updatingItems[item.uri]}
                                isEditing={item.uri === editingItemUri}
                                conditionActions={conditionActions}
                                onFieldTypeChange={onFieldTypeChange}
                                isDropAnimating={snapshot.isDropAnimating}
                                isValidSection={isValidSection}
                                excludingFieldTemplates={
                                  excludingFieldTemplates
                                }
                              />
                            ) : (
                              <Group
                                mode={mode}
                                index={index}
                                item={item}
                                fields={item.fields}
                                matterTypes={matterTypes}
                                updateGroup={updateGroup}
                                deleteGroup={deleteGroup}
                                onUpdateField={onUpdateField}
                                onDeleteField={onDeleteField}
                                matterLayouts={matterLayouts}
                                isDragging={snapshot.isDragging}
                                editingItemUri={editingItemUri}
                                loadMatterFields={loadMatterFields}
                                updatingItems={updatingItems}
                                draggableProvided={draggableProvided}
                                loading={updatingItems[item.uri]}
                                isEditing={item.uri === editingItemUri}
                                conditionActions={conditionActions}
                                onFieldTypeChange={onFieldTypeChange}
                                isDropAnimating={snapshot.isDropAnimating}
                                isValidSection={isValidSection}
                                excludingFieldTemplates={
                                  excludingFieldTemplates
                                }
                              />
                            )
                          }
                        </Draggable>
                        {droppableContainerProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </FieldsWrapper>
    </QuestionsWrapper>
  );
};

export { Questions };
