/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlAddressDetailsFieldsFragment = {
  __typename: "AddressDetails";
  unit?: string | null;
  unitLabel?: string | null;
  streetNumber?: string | null;
  streetName?: string | null;
  streetType?: string | null;
  suburb?: string | null;
  locality?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  county?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  formattedAddress?: string | null;
};

export const AddressDetailsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "addressDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AddressDetails" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "unit" } },
          { kind: "Field", name: { kind: "Name", value: "unitLabel" } },
          { kind: "Field", name: { kind: "Name", value: "streetNumber" } },
          { kind: "Field", name: { kind: "Name", value: "streetName" } },
          { kind: "Field", name: { kind: "Name", value: "streetType" } },
          { kind: "Field", name: { kind: "Name", value: "suburb" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine1" } },
          { kind: "Field", name: { kind: "Name", value: "addressLine2" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "county" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "formattedAddress" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlAddressDetailsFieldsFragment, unknown>;
