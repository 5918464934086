import styled from "@emotion/styled";
import { useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  AutoFillStatus,
  IntakeForm,
  IntakeNotFound,
  SectionItem,
  intakeTheme,
} from "@smart/bridge-intake-components-dom";
import {
  appendDownloadFormLink,
  defaultTemplate,
} from "@smart/bridge-templates-basic";
import { SubmissionNS } from "@smart/bridge-types-basic";
import { Loading, ThemeProvider } from "@smart/itops-components-dom";
import { useLoadStaffDetails } from "@smart/manage-gql-client-dom";
import { useFormForDisplay } from "@smart/manage-hooks-dom";

import {
  GqlField,
  GqlForm,
  GqlGroup,
  GqlSection,
  GqlSetting,
  GqlTeam,
} from "../types";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

type PreviewProps = {
  formUri: string;
  team?: GqlTeam | null;
  setting?: GqlSetting | null;
  form?: GqlForm | null;
  sections?: GqlSection[] | null;
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  canPreviewOnDraft?: boolean;
  previewOptions: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    previewResponses: [
      Record<string, any>,
      React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ];
    previewLastSection: [
      SectionItem | "summary" | undefined,
      React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
    ];
  };
  previewLoading?: boolean;
  staffDetails: ReturnType<typeof useLoadStaffDetails>;
  intakeFormKey?: string;
};

const Preview = ({
  intakeFormKey,
  team,
  setting,
  form,
  sections,
  groups,
  fields,
  canPreviewOnDraft,
  previewOptions,
  previewLoading,
  staffDetails,
}: PreviewProps) => {
  const { loading, exists, ...rest } = useFormForDisplay({
    loading: previewLoading || false,
    team,
    setting,
    form: form?.rawForm,
    sections,
    groups,
    fields,
  });
  const [autoFillStatus, setAutoFillStatus] = useState<AutoFillStatus>();
  const { aiWordSupport } = useFeatureFlags();

  if (loading || staffDetails.loading) return <Loading />;

  if (!canPreviewOnDraft && !exists) return <IntakeNotFound />;

  return (
    <Wrapper>
      <ThemeProvider theme={intakeTheme}>
        <IntakeForm
          key={intakeFormKey}
          intakeFormKey={intakeFormKey}
          {...rest}
          form={{
            ...rest.form,
            response: appendDownloadFormLink(
              rest.form.response || defaultTemplate.response,
            ),
          }}
          submission={{ uri: SubmissionNS.generateUri(), responses: {} }}
          submitResponses={async () => ({
            operationIds: ["mockId"],
            submissionStatus: {},
          })}
          previewOptions={previewOptions}
          loadResponses={async () => undefined}
          staffDetails={staffDetails.result || []}
          runAutoFilling={async () => {
            setAutoFillStatus("filling");
            setTimeout(() => setAutoFillStatus("completed"), 3000);
          }}
          autoFillStatus={autoFillStatus}
          submitAutofillFeedback={async () => setAutoFillStatus(undefined)}
          aiWordSupport={aiWordSupport}
        />
      </ThemeProvider>
    </Wrapper>
  );
};

export { Preview };
