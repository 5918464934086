/* eslint-disable */
import {
  AddressDetailsFieldsFragmentDoc,
  GqlAddressDetailsFieldsFragment,
} from "./address-details-fields";
import {
  AddressFieldsFragmentDoc,
  GqlAddressFieldsFragment,
} from "./address-fields";
import {
  AddressPredictionFieldsFragmentDoc,
  GqlAddressPredictionFieldsFragment,
} from "./address-prediction-fields";
import {
  AiUserFeedbackFieldsFragmentDoc,
  GqlAiUserFeedbackFieldsFragment,
} from "./ai-user-feedback-fields";
import {
  BankAccountFieldsFragmentDoc,
  GqlBankAccountFieldsFragment,
} from "./bank-account-fields";
import {
  ContactFieldsFragmentDoc,
  GqlContactFieldsFragment,
} from "./contact-fields";
import {
  FamilyProAuthorizationStateFieldsFragmentDoc,
  GqlFamilyProAuthorizationStateFieldsFragment,
} from "./family-pro-authorization-state-fields";
import {
  FamilyProTemplateFieldsFragmentDoc,
  GqlFamilyProTemplateFieldsFragment,
} from "./family-pro-template-fields";
import { FieldFieldsFragmentDoc, GqlFieldFieldsFragment } from "./field-fields";
import {
  FileItemFieldsFragmentDoc,
  GqlFileItemFieldsFragment,
} from "./file-item-fields";
import { FormFieldsFragmentDoc, GqlFormFieldsFragment } from "./form-fields";
import { GroupFieldsFragmentDoc, GqlGroupFieldsFragment } from "./group-fields";
import {
  GroupWithFieldsFieldsFragmentDoc,
  GqlGroupWithFieldsFieldsFragment,
} from "./group-with-fields-fields";
import {
  MatterLayoutFieldsFragmentDoc,
  GqlMatterLayoutFieldsFragment,
} from "./matter-layout-fields";
import {
  MatterQuestionnaireStatusFieldsFragmentDoc,
  GqlMatterQuestionnaireStatusFieldsFragment,
} from "./matter-questionnaire-status-fields";
import {
  OperationFieldsFragmentDoc,
  GqlOperationFieldsFragment,
} from "./operation-fields";
import { PhoneFieldsFragmentDoc, GqlPhoneFieldsFragment } from "./phone-fields";
import {
  ResponseFieldsFragmentDoc,
  GqlResponseFieldsFragment,
} from "./response-fields";
import { RoleFieldsFragmentDoc, GqlRoleFieldsFragment } from "./role-fields";
import {
  SectionFieldsFragmentDoc,
  GqlSectionFieldsFragment,
} from "./section-fields";
import {
  SettingFieldsFragmentDoc,
  GqlSettingFieldsFragment,
} from "./setting-fields";
import { SlugFieldsFragmentDoc, GqlSlugFieldsFragment } from "./slug-fields";
import {
  StaffBusySlotsFieldsFragmentDoc,
  GqlStaffBusySlotsFieldsFragment,
} from "./staff-busy-slots-fields";
import {
  StaffDetailsFieldsFragmentDoc,
  GqlStaffDetailsFieldsFragment,
} from "./staff-details-fields";
import {
  SubmissionFieldsFragmentDoc,
  GqlSubmissionFieldsFragment,
} from "./submission-fields";
import {
  SubmissionLinkHashFieldsFragmentDoc,
  GqlSubmissionLinkHashFieldsFragment,
} from "./submission-link-hash-fields";
import { TeamFieldsFragmentDoc, GqlTeamFieldsFragment } from "./team-fields";
import { UserFieldsFragmentDoc, GqlUserFieldsFragment } from "./user-fields";
import {
  AddFormMatterTypeDocument,
  GqlAddFormMatterTypeMutation,
  GqlAddFormMatterTypeMutationVariables,
} from "./add-form-matter-type";
import {
  CopyFormDocument,
  GqlCopyFormMutation,
  GqlCopyFormMutationVariables,
} from "./copy-form";
import {
  CopySubmissionLinkDocument,
  GqlCopySubmissionLinkMutation,
  GqlCopySubmissionLinkMutationVariables,
} from "./copy-submission-link";
import {
  CreateSubmissionDocument,
  GqlCreateSubmissionMutation,
  GqlCreateSubmissionMutationVariables,
} from "./create-submission";
import {
  DeleteFieldDocument,
  GqlDeleteFieldMutation,
  GqlDeleteFieldMutationVariables,
} from "./delete-field";
import {
  DeleteFormDocument,
  GqlDeleteFormMutation,
  GqlDeleteFormMutationVariables,
} from "./delete-form";
import {
  DeleteGroupDocument,
  GqlDeleteGroupMutation,
  GqlDeleteGroupMutationVariables,
} from "./delete-group";
import {
  DeleteSectionDocument,
  GqlDeleteSectionMutation,
  GqlDeleteSectionMutationVariables,
} from "./delete-section";
import {
  DeleteSubmissionLinkHashDocument,
  GqlDeleteSubmissionLinkHashMutation,
  GqlDeleteSubmissionLinkHashMutationVariables,
} from "./delete-submission-link-hash";
import {
  DeleteSubmissionDocument,
  GqlDeleteSubmissionMutation,
  GqlDeleteSubmissionMutationVariables,
} from "./delete-submission";
import {
  GenerateFormDocument,
  GqlGenerateFormMutation,
  GqlGenerateFormMutationVariables,
} from "./generate-form";
import {
  GenerateLinkParameterDocument,
  GqlGenerateLinkParameterMutation,
  GqlGenerateLinkParameterMutationVariables,
} from "./generate-link-parameter";
import {
  RemoveFormMatterTypeDocument,
  GqlRemoveFormMatterTypeMutation,
  GqlRemoveFormMatterTypeMutationVariables,
} from "./remove-form-matter-type";
import {
  SendMatterQuestionnaireDocument,
  GqlSendMatterQuestionnaireMutation,
  GqlSendMatterQuestionnaireMutationVariables,
} from "./send-matter-questionnaire";
import {
  SendSubmissionDocument,
  GqlSendSubmissionMutation,
  GqlSendSubmissionMutationVariables,
} from "./send-submission";
import {
  SyncSubmissionDocument,
  GqlSyncSubmissionMutation,
  GqlSyncSubmissionMutationVariables,
} from "./sync-submission";
import {
  UpdateAiUserFeedbackDocument,
  GqlUpdateAiUserFeedbackMutation,
  GqlUpdateAiUserFeedbackMutationVariables,
} from "./update-ai-user-feedback";
import {
  UpdateFieldOrderDocument,
  GqlUpdateFieldOrderMutation,
  GqlUpdateFieldOrderMutationVariables,
} from "./update-field-order";
import {
  UpdateFieldDocument,
  GqlUpdateFieldMutation,
  GqlUpdateFieldMutationVariables,
} from "./update-field";
import {
  UpdateFormOwnershipDocument,
  GqlUpdateFormOwnershipMutation,
  GqlUpdateFormOwnershipMutationVariables,
} from "./update-form-ownership";
import {
  UpdateFormDocument,
  GqlUpdateFormMutation,
  GqlUpdateFormMutationVariables,
} from "./update-form";
import {
  UpdateGroupOrderDocument,
  GqlUpdateGroupOrderMutation,
  GqlUpdateGroupOrderMutationVariables,
} from "./update-group-order";
import {
  UpdateGroupWithFieldsDocument,
  GqlUpdateGroupWithFieldsMutation,
  GqlUpdateGroupWithFieldsMutationVariables,
} from "./update-group-with-fields";
import {
  UpdateGroupDocument,
  GqlUpdateGroupMutation,
  GqlUpdateGroupMutationVariables,
} from "./update-group";
import {
  UpdateResponseExistingItemIdsDocument,
  GqlUpdateResponseExistingItemIdsMutation,
  GqlUpdateResponseExistingItemIdsMutationVariables,
} from "./update-response-existing-item-id";
import {
  UpdateResponseSyncStatusDocument,
  GqlUpdateResponseSyncStatusMutation,
  GqlUpdateResponseSyncStatusMutationVariables,
} from "./update-response-sync-status";
import {
  UpdateResponseDocument,
  GqlUpdateResponseMutation,
  GqlUpdateResponseMutationVariables,
} from "./update-response";
import {
  UpdateSectionOrderDocument,
  GqlUpdateSectionOrderMutation,
  GqlUpdateSectionOrderMutationVariables,
} from "./update-section-order";
import {
  UpdateSectionDocument,
  GqlUpdateSectionMutation,
  GqlUpdateSectionMutationVariables,
} from "./update-section";
import {
  UpdateSettingDocument,
  GqlUpdateSettingMutation,
  GqlUpdateSettingMutationVariables,
} from "./update-setting";
import {
  UpdateSlugDocument,
  GqlUpdateSlugMutation,
  GqlUpdateSlugMutationVariables,
} from "./update-slug";
import {
  UpdateSubmissionDocument,
  GqlUpdateSubmissionMutation,
  GqlUpdateSubmissionMutationVariables,
} from "./update-submission";
import {
  UpdateTeamDocument,
  GqlUpdateTeamMutation,
  GqlUpdateTeamMutationVariables,
} from "./update-team";
import {
  UploadFilesDocument,
  GqlUploadFilesMutation,
  GqlUploadFilesMutationVariables,
} from "./upload-files";
import {
  UploadQuestionGenerationFilesDocument,
  GqlUploadQuestionGenerationFilesMutation,
  GqlUploadQuestionGenerationFilesMutationVariables,
} from "./upload-question-generation-files";
import {
  AddressDetailsDocument,
  GqlAddressDetailsQuery,
  GqlAddressDetailsQueryVariables,
} from "./address-details";
import {
  AddressPredictionsDocument,
  GqlAddressPredictionsQuery,
  GqlAddressPredictionsQueryVariables,
} from "./address-predictions";
import {
  BankAccountsDocument,
  GqlBankAccountsQuery,
  GqlBankAccountsQueryVariables,
} from "./bank-accounts";
import {
  ContactsDocument,
  GqlContactsQuery,
  GqlContactsQueryVariables,
} from "./contacts";
import {
  FamilyProAuthorizationStateDocument,
  GqlFamilyProAuthorizationStateQuery,
  GqlFamilyProAuthorizationStateQueryVariables,
} from "./family-pro-authorization-state";
import {
  FamilyProTemplateDocument,
  GqlFamilyProTemplateQuery,
  GqlFamilyProTemplateQueryVariables,
} from "./family-pro-template";
import {
  FieldsDocument,
  GqlFieldsQuery,
  GqlFieldsQueryVariables,
} from "./fields";
import {
  FormSubmissionsDocument,
  GqlFormSubmissionsQuery,
  GqlFormSubmissionsQueryVariables,
} from "./form-submissions";
import { FormDocument, GqlFormQuery, GqlFormQueryVariables } from "./form";
import { FormsDocument, GqlFormsQuery, GqlFormsQueryVariables } from "./forms";
import {
  GlobalFormsDocument,
  GqlGlobalFormsQuery,
  GqlGlobalFormsQueryVariables,
} from "./global-forms";
import {
  GlobalMatterCategoryFormsDocument,
  GqlGlobalMatterCategoryFormsQuery,
  GqlGlobalMatterCategoryFormsQueryVariables,
} from "./global-matter-category-forms";
import {
  GlobalMatterTypeFormsDocument,
  GqlGlobalMatterTypeFormsQuery,
  GqlGlobalMatterTypeFormsQueryVariables,
} from "./global-matter-type-forms";
import {
  GroupsWithFieldsDocument,
  GqlGroupsWithFieldsQuery,
  GqlGroupsWithFieldsQueryVariables,
} from "./groups-with-fields";
import {
  GroupsDocument,
  GqlGroupsQuery,
  GqlGroupsQueryVariables,
} from "./groups";
import {
  MatterCategoryFormsDocument,
  GqlMatterCategoryFormsQuery,
  GqlMatterCategoryFormsQueryVariables,
} from "./matter-category-forms";
import {
  MatterFileDocument,
  GqlMatterFileQuery,
  GqlMatterFileQueryVariables,
} from "./matter-file";
import {
  MatterFilesDocument,
  GqlMatterFilesQuery,
  GqlMatterFilesQueryVariables,
} from "./matter-files";
import {
  MatterLayoutsDocument,
  GqlMatterLayoutsQuery,
  GqlMatterLayoutsQueryVariables,
} from "./matter-layouts";
import {
  MatterQuestionnaireStatusDocument,
  GqlMatterQuestionnaireStatusQuery,
  GqlMatterQuestionnaireStatusQueryVariables,
} from "./matter-questionnaire-status";
import {
  MatterRolesDocument,
  GqlMatterRolesQuery,
  GqlMatterRolesQueryVariables,
} from "./matter-roles";
import {
  MatterSpecificFormsDocument,
  GqlMatterSpecificFormsQuery,
  GqlMatterSpecificFormsQueryVariables,
} from "./matter-specific-forms";
import {
  MatterSubmissionsDocument,
  GqlMatterSubmissionsQuery,
  GqlMatterSubmissionsQueryVariables,
} from "./matter-submissions";
import {
  MatterTypeFormsDocument,
  GqlMatterTypeFormsQuery,
  GqlMatterTypeFormsQueryVariables,
} from "./matter-type-forms";
import {
  SectionsDocument,
  GqlSectionsQuery,
  GqlSectionsQueryVariables,
} from "./sections";
import {
  SettingDocument,
  GqlSettingQuery,
  GqlSettingQueryVariables,
} from "./setting";
import { SlugDocument, GqlSlugQuery, GqlSlugQueryVariables } from "./slug";
import {
  StaffDetailsDocument,
  GqlStaffDetailsQuery,
  GqlStaffDetailsQueryVariables,
} from "./staff";
import {
  SubmissionLinkHashDocument,
  GqlSubmissionLinkHashQuery,
  GqlSubmissionLinkHashQueryVariables,
} from "./submission-link-hash";
import {
  SubmissionLinkHashesDocument,
  GqlSubmissionLinkHashesQuery,
  GqlSubmissionLinkHashesQueryVariables,
} from "./submission-link-hashes";
import {
  SubmissionDocument,
  GqlSubmissionQuery,
  GqlSubmissionQueryVariables,
} from "./submission";
import {
  SubmissionsDocument,
  GqlSubmissionsQuery,
  GqlSubmissionsQueryVariables,
} from "./submissions";
import { TeamDocument, GqlTeamQuery, GqlTeamQueryVariables } from "./team";
import { TeamsDocument, GqlTeamsQuery, GqlTeamsQueryVariables } from "./teams";

export const queryKeys = [
  "addressDetails",
  "addressPredictions",
  "bankAccounts",
  "contacts",
  "familyProAuthorizationState",
  "familyProTemplate",
  "fields",
  "formSubmissions",
  "form",
  "forms",
  "globalForms",
  "globalMatterCategoryForms",
  "globalMatterTypeForms",
  "groupsWithFields",
  "groups",
  "matterCategoryForms",
  "matterFile",
  "matterFiles",
  "matterLayouts",
  "matterQuestionnaireStatus",
  "matterRoles",
  "matterSpecificForms",
  "matterSubmissions",
  "matterTypeForms",
  "sections",
  "setting",
  "slug",
  "staffDetails",
  "submissionLinkHash",
  "submissionLinkHashes",
  "submission",
  "submissions",
  "team",
  "teams",
] as const;
export type QueryKey = (typeof queryKeys)[number];
export const queryDocuments = {
  addressDetails: AddressDetailsDocument,
  addressPredictions: AddressPredictionsDocument,
  bankAccounts: BankAccountsDocument,
  contacts: ContactsDocument,
  familyProAuthorizationState: FamilyProAuthorizationStateDocument,
  familyProTemplate: FamilyProTemplateDocument,
  fields: FieldsDocument,
  formSubmissions: FormSubmissionsDocument,
  form: FormDocument,
  forms: FormsDocument,
  globalForms: GlobalFormsDocument,
  globalMatterCategoryForms: GlobalMatterCategoryFormsDocument,
  globalMatterTypeForms: GlobalMatterTypeFormsDocument,
  groupsWithFields: GroupsWithFieldsDocument,
  groups: GroupsDocument,
  matterCategoryForms: MatterCategoryFormsDocument,
  matterFile: MatterFileDocument,
  matterFiles: MatterFilesDocument,
  matterLayouts: MatterLayoutsDocument,
  matterQuestionnaireStatus: MatterQuestionnaireStatusDocument,
  matterRoles: MatterRolesDocument,
  matterSpecificForms: MatterSpecificFormsDocument,
  matterSubmissions: MatterSubmissionsDocument,
  matterTypeForms: MatterTypeFormsDocument,
  sections: SectionsDocument,
  setting: SettingDocument,
  slug: SlugDocument,
  staffDetails: StaffDetailsDocument,
  submissionLinkHash: SubmissionLinkHashDocument,
  submissionLinkHashes: SubmissionLinkHashesDocument,
  submission: SubmissionDocument,
  submissions: SubmissionsDocument,
  team: TeamDocument,
  teams: TeamsDocument,
};

export const mutationKeys = [
  "addFormMatterType",
  "copyForm",
  "copySubmissionLink",
  "createSubmission",
  "deleteField",
  "deleteForm",
  "deleteGroup",
  "deleteSection",
  "deleteSubmissionLinkHash",
  "deleteSubmission",
  "generateForm",
  "generateLinkParameter",
  "removeFormMatterType",
  "sendMatterQuestionnaire",
  "sendSubmission",
  "syncSubmission",
  "updateAiUserFeedback",
  "updateFieldOrder",
  "updateField",
  "updateFormOwnership",
  "updateForm",
  "updateGroupOrder",
  "updateGroupWithFields",
  "updateGroup",
  "updateResponseExistingItemIds",
  "updateResponseSyncStatus",
  "updateResponse",
  "updateSectionOrder",
  "updateSection",
  "updateSetting",
  "updateSlug",
  "updateSubmission",
  "updateTeam",
  "uploadFiles",
  "uploadQuestionGenerationFiles",
] as const;
export type MutationKey = (typeof mutationKeys)[number];
export const mutationDocuments = {
  addFormMatterType: AddFormMatterTypeDocument,
  copyForm: CopyFormDocument,
  copySubmissionLink: CopySubmissionLinkDocument,
  createSubmission: CreateSubmissionDocument,
  deleteField: DeleteFieldDocument,
  deleteForm: DeleteFormDocument,
  deleteGroup: DeleteGroupDocument,
  deleteSection: DeleteSectionDocument,
  deleteSubmissionLinkHash: DeleteSubmissionLinkHashDocument,
  deleteSubmission: DeleteSubmissionDocument,
  generateForm: GenerateFormDocument,
  generateLinkParameter: GenerateLinkParameterDocument,
  removeFormMatterType: RemoveFormMatterTypeDocument,
  sendMatterQuestionnaire: SendMatterQuestionnaireDocument,
  sendSubmission: SendSubmissionDocument,
  syncSubmission: SyncSubmissionDocument,
  updateAiUserFeedback: UpdateAiUserFeedbackDocument,
  updateFieldOrder: UpdateFieldOrderDocument,
  updateField: UpdateFieldDocument,
  updateFormOwnership: UpdateFormOwnershipDocument,
  updateForm: UpdateFormDocument,
  updateGroupOrder: UpdateGroupOrderDocument,
  updateGroupWithFields: UpdateGroupWithFieldsDocument,
  updateGroup: UpdateGroupDocument,
  updateResponseExistingItemIds: UpdateResponseExistingItemIdsDocument,
  updateResponseSyncStatus: UpdateResponseSyncStatusDocument,
  updateResponse: UpdateResponseDocument,
  updateSectionOrder: UpdateSectionOrderDocument,
  updateSection: UpdateSectionDocument,
  updateSetting: UpdateSettingDocument,
  updateSlug: UpdateSlugDocument,
  updateSubmission: UpdateSubmissionDocument,
  updateTeam: UpdateTeamDocument,
  uploadFiles: UploadFilesDocument,
  uploadQuestionGenerationFiles: UploadQuestionGenerationFilesDocument,
};

export const subscriptionKeys = [] as const;
export type SubscriptionKey = (typeof subscriptionKeys)[number];
export const subscriptionDocuments = {};

export const fragments = {
  addressDetailsFields: AddressDetailsFieldsFragmentDoc,
  addressFields: AddressFieldsFragmentDoc,
  addressPredictionFields: AddressPredictionFieldsFragmentDoc,
  aiUserFeedbackFields: AiUserFeedbackFieldsFragmentDoc,
  bankAccountFields: BankAccountFieldsFragmentDoc,
  contactFields: ContactFieldsFragmentDoc,
  familyProAuthorizationStateFields:
    FamilyProAuthorizationStateFieldsFragmentDoc,
  familyProTemplateFields: FamilyProTemplateFieldsFragmentDoc,
  fieldFields: FieldFieldsFragmentDoc,
  fileItemFields: FileItemFieldsFragmentDoc,
  formFields: FormFieldsFragmentDoc,
  groupFields: GroupFieldsFragmentDoc,
  groupWithFieldsFields: GroupWithFieldsFieldsFragmentDoc,
  matterLayoutFields: MatterLayoutFieldsFragmentDoc,
  matterQuestionnaireStatusFields: MatterQuestionnaireStatusFieldsFragmentDoc,
  operationFields: OperationFieldsFragmentDoc,
  phoneFields: PhoneFieldsFragmentDoc,
  responseFields: ResponseFieldsFragmentDoc,
  roleFields: RoleFieldsFragmentDoc,
  sectionFields: SectionFieldsFragmentDoc,
  settingFields: SettingFieldsFragmentDoc,
  slugFields: SlugFieldsFragmentDoc,
  staffBusySlotsFields: StaffBusySlotsFieldsFragmentDoc,
  staffDetailsFields: StaffDetailsFieldsFragmentDoc,
  submissionFields: SubmissionFieldsFragmentDoc,
  submissionLinkHashFields: SubmissionLinkHashFieldsFragmentDoc,
  teamFields: TeamFieldsFragmentDoc,
  userFields: UserFieldsFragmentDoc,
};
export namespace Gql {
  export type AddressDetailsFieldsFragment = GqlAddressDetailsFieldsFragment;
  export type AddressFieldsFragment = GqlAddressFieldsFragment;
  export type AddressPredictionFieldsFragment =
    GqlAddressPredictionFieldsFragment;
  export type AiUserFeedbackFieldsFragment = GqlAiUserFeedbackFieldsFragment;
  export type BankAccountFieldsFragment = GqlBankAccountFieldsFragment;
  export type ContactFieldsFragment = GqlContactFieldsFragment;
  export type FamilyProAuthorizationStateFieldsFragment =
    GqlFamilyProAuthorizationStateFieldsFragment;
  export type FamilyProTemplateFieldsFragment =
    GqlFamilyProTemplateFieldsFragment;
  export type FieldFieldsFragment = GqlFieldFieldsFragment;
  export type FileItemFieldsFragment = GqlFileItemFieldsFragment;
  export type FormFieldsFragment = GqlFormFieldsFragment;
  export type GroupFieldsFragment = GqlGroupFieldsFragment;
  export type GroupWithFieldsFieldsFragment = GqlGroupWithFieldsFieldsFragment;
  export type MatterLayoutFieldsFragment = GqlMatterLayoutFieldsFragment;
  export type MatterQuestionnaireStatusFieldsFragment =
    GqlMatterQuestionnaireStatusFieldsFragment;
  export type OperationFieldsFragment = GqlOperationFieldsFragment;
  export type PhoneFieldsFragment = GqlPhoneFieldsFragment;
  export type ResponseFieldsFragment = GqlResponseFieldsFragment;
  export type RoleFieldsFragment = GqlRoleFieldsFragment;
  export type SectionFieldsFragment = GqlSectionFieldsFragment;
  export type SettingFieldsFragment = GqlSettingFieldsFragment;
  export type SlugFieldsFragment = GqlSlugFieldsFragment;
  export type StaffBusySlotsFieldsFragment = GqlStaffBusySlotsFieldsFragment;
  export type StaffDetailsFieldsFragment = GqlStaffDetailsFieldsFragment;
  export type SubmissionFieldsFragment = GqlSubmissionFieldsFragment;
  export type SubmissionLinkHashFieldsFragment =
    GqlSubmissionLinkHashFieldsFragment;
  export type TeamFieldsFragment = GqlTeamFieldsFragment;
  export type UserFieldsFragment = GqlUserFieldsFragment;
  export type AddFormMatterTypeMutation = GqlAddFormMatterTypeMutation;
  export type AddFormMatterTypeMutationVariables =
    GqlAddFormMatterTypeMutationVariables;
  export type CopyFormMutation = GqlCopyFormMutation;
  export type CopyFormMutationVariables = GqlCopyFormMutationVariables;
  export type CopySubmissionLinkMutation = GqlCopySubmissionLinkMutation;
  export type CopySubmissionLinkMutationVariables =
    GqlCopySubmissionLinkMutationVariables;
  export type CreateSubmissionMutation = GqlCreateSubmissionMutation;
  export type CreateSubmissionMutationVariables =
    GqlCreateSubmissionMutationVariables;
  export type DeleteFieldMutation = GqlDeleteFieldMutation;
  export type DeleteFieldMutationVariables = GqlDeleteFieldMutationVariables;
  export type DeleteFormMutation = GqlDeleteFormMutation;
  export type DeleteFormMutationVariables = GqlDeleteFormMutationVariables;
  export type DeleteGroupMutation = GqlDeleteGroupMutation;
  export type DeleteGroupMutationVariables = GqlDeleteGroupMutationVariables;
  export type DeleteSectionMutation = GqlDeleteSectionMutation;
  export type DeleteSectionMutationVariables =
    GqlDeleteSectionMutationVariables;
  export type DeleteSubmissionLinkHashMutation =
    GqlDeleteSubmissionLinkHashMutation;
  export type DeleteSubmissionLinkHashMutationVariables =
    GqlDeleteSubmissionLinkHashMutationVariables;
  export type DeleteSubmissionMutation = GqlDeleteSubmissionMutation;
  export type DeleteSubmissionMutationVariables =
    GqlDeleteSubmissionMutationVariables;
  export type GenerateFormMutation = GqlGenerateFormMutation;
  export type GenerateFormMutationVariables = GqlGenerateFormMutationVariables;
  export type GenerateLinkParameterMutation = GqlGenerateLinkParameterMutation;
  export type GenerateLinkParameterMutationVariables =
    GqlGenerateLinkParameterMutationVariables;
  export type RemoveFormMatterTypeMutation = GqlRemoveFormMatterTypeMutation;
  export type RemoveFormMatterTypeMutationVariables =
    GqlRemoveFormMatterTypeMutationVariables;
  export type SendMatterQuestionnaireMutation =
    GqlSendMatterQuestionnaireMutation;
  export type SendMatterQuestionnaireMutationVariables =
    GqlSendMatterQuestionnaireMutationVariables;
  export type SendSubmissionMutation = GqlSendSubmissionMutation;
  export type SendSubmissionMutationVariables =
    GqlSendSubmissionMutationVariables;
  export type SyncSubmissionMutation = GqlSyncSubmissionMutation;
  export type SyncSubmissionMutationVariables =
    GqlSyncSubmissionMutationVariables;
  export type UpdateAiUserFeedbackMutation = GqlUpdateAiUserFeedbackMutation;
  export type UpdateAiUserFeedbackMutationVariables =
    GqlUpdateAiUserFeedbackMutationVariables;
  export type UpdateFieldOrderMutation = GqlUpdateFieldOrderMutation;
  export type UpdateFieldOrderMutationVariables =
    GqlUpdateFieldOrderMutationVariables;
  export type UpdateFieldMutation = GqlUpdateFieldMutation;
  export type UpdateFieldMutationVariables = GqlUpdateFieldMutationVariables;
  export type UpdateFormOwnershipMutation = GqlUpdateFormOwnershipMutation;
  export type UpdateFormOwnershipMutationVariables =
    GqlUpdateFormOwnershipMutationVariables;
  export type UpdateFormMutation = GqlUpdateFormMutation;
  export type UpdateFormMutationVariables = GqlUpdateFormMutationVariables;
  export type UpdateGroupOrderMutation = GqlUpdateGroupOrderMutation;
  export type UpdateGroupOrderMutationVariables =
    GqlUpdateGroupOrderMutationVariables;
  export type UpdateGroupWithFieldsMutation = GqlUpdateGroupWithFieldsMutation;
  export type UpdateGroupWithFieldsMutationVariables =
    GqlUpdateGroupWithFieldsMutationVariables;
  export type UpdateGroupMutation = GqlUpdateGroupMutation;
  export type UpdateGroupMutationVariables = GqlUpdateGroupMutationVariables;
  export type UpdateResponseExistingItemIdsMutation =
    GqlUpdateResponseExistingItemIdsMutation;
  export type UpdateResponseExistingItemIdsMutationVariables =
    GqlUpdateResponseExistingItemIdsMutationVariables;
  export type UpdateResponseSyncStatusMutation =
    GqlUpdateResponseSyncStatusMutation;
  export type UpdateResponseSyncStatusMutationVariables =
    GqlUpdateResponseSyncStatusMutationVariables;
  export type UpdateResponseMutation = GqlUpdateResponseMutation;
  export type UpdateResponseMutationVariables =
    GqlUpdateResponseMutationVariables;
  export type UpdateSectionOrderMutation = GqlUpdateSectionOrderMutation;
  export type UpdateSectionOrderMutationVariables =
    GqlUpdateSectionOrderMutationVariables;
  export type UpdateSectionMutation = GqlUpdateSectionMutation;
  export type UpdateSectionMutationVariables =
    GqlUpdateSectionMutationVariables;
  export type UpdateSettingMutation = GqlUpdateSettingMutation;
  export type UpdateSettingMutationVariables =
    GqlUpdateSettingMutationVariables;
  export type UpdateSlugMutation = GqlUpdateSlugMutation;
  export type UpdateSlugMutationVariables = GqlUpdateSlugMutationVariables;
  export type UpdateSubmissionMutation = GqlUpdateSubmissionMutation;
  export type UpdateSubmissionMutationVariables =
    GqlUpdateSubmissionMutationVariables;
  export type UpdateTeamMutation = GqlUpdateTeamMutation;
  export type UpdateTeamMutationVariables = GqlUpdateTeamMutationVariables;
  export type UploadFilesMutation = GqlUploadFilesMutation;
  export type UploadFilesMutationVariables = GqlUploadFilesMutationVariables;
  export type UploadQuestionGenerationFilesMutation =
    GqlUploadQuestionGenerationFilesMutation;
  export type UploadQuestionGenerationFilesMutationVariables =
    GqlUploadQuestionGenerationFilesMutationVariables;
  export type AddressDetailsQuery = GqlAddressDetailsQuery;
  export type AddressDetailsQueryVariables = GqlAddressDetailsQueryVariables;
  export type AddressPredictionsQuery = GqlAddressPredictionsQuery;
  export type AddressPredictionsQueryVariables =
    GqlAddressPredictionsQueryVariables;
  export type BankAccountsQuery = GqlBankAccountsQuery;
  export type BankAccountsQueryVariables = GqlBankAccountsQueryVariables;
  export type ContactsQuery = GqlContactsQuery;
  export type ContactsQueryVariables = GqlContactsQueryVariables;
  export type FamilyProAuthorizationStateQuery =
    GqlFamilyProAuthorizationStateQuery;
  export type FamilyProAuthorizationStateQueryVariables =
    GqlFamilyProAuthorizationStateQueryVariables;
  export type FamilyProTemplateQuery = GqlFamilyProTemplateQuery;
  export type FamilyProTemplateQueryVariables =
    GqlFamilyProTemplateQueryVariables;
  export type FieldsQuery = GqlFieldsQuery;
  export type FieldsQueryVariables = GqlFieldsQueryVariables;
  export type FormSubmissionsQuery = GqlFormSubmissionsQuery;
  export type FormSubmissionsQueryVariables = GqlFormSubmissionsQueryVariables;
  export type FormQuery = GqlFormQuery;
  export type FormQueryVariables = GqlFormQueryVariables;
  export type FormsQuery = GqlFormsQuery;
  export type FormsQueryVariables = GqlFormsQueryVariables;
  export type GlobalFormsQuery = GqlGlobalFormsQuery;
  export type GlobalFormsQueryVariables = GqlGlobalFormsQueryVariables;
  export type GlobalMatterCategoryFormsQuery =
    GqlGlobalMatterCategoryFormsQuery;
  export type GlobalMatterCategoryFormsQueryVariables =
    GqlGlobalMatterCategoryFormsQueryVariables;
  export type GlobalMatterTypeFormsQuery = GqlGlobalMatterTypeFormsQuery;
  export type GlobalMatterTypeFormsQueryVariables =
    GqlGlobalMatterTypeFormsQueryVariables;
  export type GroupsWithFieldsQuery = GqlGroupsWithFieldsQuery;
  export type GroupsWithFieldsQueryVariables =
    GqlGroupsWithFieldsQueryVariables;
  export type GroupsQuery = GqlGroupsQuery;
  export type GroupsQueryVariables = GqlGroupsQueryVariables;
  export type MatterCategoryFormsQuery = GqlMatterCategoryFormsQuery;
  export type MatterCategoryFormsQueryVariables =
    GqlMatterCategoryFormsQueryVariables;
  export type MatterFileQuery = GqlMatterFileQuery;
  export type MatterFileQueryVariables = GqlMatterFileQueryVariables;
  export type MatterFilesQuery = GqlMatterFilesQuery;
  export type MatterFilesQueryVariables = GqlMatterFilesQueryVariables;
  export type MatterLayoutsQuery = GqlMatterLayoutsQuery;
  export type MatterLayoutsQueryVariables = GqlMatterLayoutsQueryVariables;
  export type MatterQuestionnaireStatusQuery =
    GqlMatterQuestionnaireStatusQuery;
  export type MatterQuestionnaireStatusQueryVariables =
    GqlMatterQuestionnaireStatusQueryVariables;
  export type MatterRolesQuery = GqlMatterRolesQuery;
  export type MatterRolesQueryVariables = GqlMatterRolesQueryVariables;
  export type MatterSpecificFormsQuery = GqlMatterSpecificFormsQuery;
  export type MatterSpecificFormsQueryVariables =
    GqlMatterSpecificFormsQueryVariables;
  export type MatterSubmissionsQuery = GqlMatterSubmissionsQuery;
  export type MatterSubmissionsQueryVariables =
    GqlMatterSubmissionsQueryVariables;
  export type MatterTypeFormsQuery = GqlMatterTypeFormsQuery;
  export type MatterTypeFormsQueryVariables = GqlMatterTypeFormsQueryVariables;
  export type SectionsQuery = GqlSectionsQuery;
  export type SectionsQueryVariables = GqlSectionsQueryVariables;
  export type SettingQuery = GqlSettingQuery;
  export type SettingQueryVariables = GqlSettingQueryVariables;
  export type SlugQuery = GqlSlugQuery;
  export type SlugQueryVariables = GqlSlugQueryVariables;
  export type StaffDetailsQuery = GqlStaffDetailsQuery;
  export type StaffDetailsQueryVariables = GqlStaffDetailsQueryVariables;
  export type SubmissionLinkHashQuery = GqlSubmissionLinkHashQuery;
  export type SubmissionLinkHashQueryVariables =
    GqlSubmissionLinkHashQueryVariables;
  export type SubmissionLinkHashesQuery = GqlSubmissionLinkHashesQuery;
  export type SubmissionLinkHashesQueryVariables =
    GqlSubmissionLinkHashesQueryVariables;
  export type SubmissionQuery = GqlSubmissionQuery;
  export type SubmissionQueryVariables = GqlSubmissionQueryVariables;
  export type SubmissionsQuery = GqlSubmissionsQuery;
  export type SubmissionsQueryVariables = GqlSubmissionsQueryVariables;
  export type TeamQuery = GqlTeamQuery;
  export type TeamQueryVariables = GqlTeamQueryVariables;
  export type TeamsQuery = GqlTeamsQuery;
  export type TeamsQueryVariables = GqlTeamsQueryVariables;
}
