import { Tags } from "@smart/itops-components-dom";
import { useTime } from "@smart/itops-hooks-dom";

import { GqlSubmission } from "./types";

type SubmissionTagsProps = {
  submission: GqlSubmission | null | undefined;
};

const SubmissionTags = ({ submission }: SubmissionTagsProps) => {
  const time = useTime(submission?.values.updatedAt);
  return (
    <Tags
      tags={
        submission
          ? [
              submission.form.values.title,
              submission.values.matterId
                ? `Linked to ${submission.form.values.category}`
                : `Unlinked`,
              `Last Updated ${time}`,
            ]
          : []
      }
      titles={["Form", "Matter", "Updates"]}
    />
  );
};

export { SubmissionTags, SubmissionTagsProps };
