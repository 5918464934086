import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  AutofillFeedback,
  AutoFillStatus,
  ConnectionItem,
  IntakeForm,
  intakeTheme,
  ResponseSubmitter,
  SubmissionItem,
} from "@smart/bridge-intake-components-dom";
import { track } from "@smart/bridge-metrics-dom";
import {
  appendDownloadFormLink,
  defaultTemplate,
} from "@smart/bridge-templates-basic";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import { ThemeProvider } from "@smart/itops-components-dom";
import { Modal } from "@smart/itops-sb-design-system-dom";
import { uploadFile as upload } from "@smart/itops-ui-dom";
import { entriesOf, fromEntries, jsonParse } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLoadSubmission,
  useUpdateAIUserFeedback,
  useUpdateResponse,
  useUpdateSubmission,
  useUploadFiles,
} from "@smart/manage-gql-client-dom";
import { useLoadFormForDisplay, useToast } from "@smart/manage-hooks-dom";

import { useOpenFamilyProForm } from "../../shared/use-open-family-pro-form";
import { SubmissionTabOptions } from "../../types";

export type SubmissionActionEditProps = {
  updateResponse: ReturnType<typeof useUpdateResponse>[0];
  updateSubmission: ReturnType<typeof useUpdateSubmission>[0];
  uploadFiles: ReturnType<typeof useUploadFiles>[0];
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  displayProps: ReturnType<typeof useLoadFormForDisplay>;
  submission: ReturnType<typeof useLoadSubmission>;
  matterSubmission?: MatterSubmission;
  navigateTo: (destination: SubmissionTabOptions | "home") => void;
  staffDetails: StaffDetails[];
  loading?: boolean;
};

export const EditResponses = ({
  updateResponse,
  updateSubmission,
  updateAIUserFeedback,
  uploadFiles,
  displayProps,
  submission,
  matterSubmission,
  navigateTo,
  staffDetails,
  loading,
}: SubmissionActionEditProps) => {
  const { setToasts } = useToast();
  const [connection, setConnection] = useState<ConnectionItem>({
    status: "initial",
  });
  const [submitted, setSubmitted] = useState(false);
  const [autoFillStatus, setAutoFillStatus] = useState<AutoFillStatus>();
  const [hideAutoFillCompleteToast, setHideAutoFillCompletedToast] =
    useState(false);
  const { aiWordSupport } = useFeatureFlags();
  useOpenFamilyProForm({
    selectedFormUri: matterSubmission?.formUri,
    externalSubmissionEmbedUrl: matterSubmission?.externalSubmissionEmbedUrl,
  });

  const isInternal =
    matterSubmission?.rawSubmission.values.communicationMethod ===
    "internalUse";

  const submit: ResponseSubmitter = async ({
    status,
    responses,
    lastUpdatedFieldUri,
    lastUpdatedSectionUri,
  }) => {
    const submissionUri = matterSubmission!.uri;
    const { formUri } = matterSubmission!;
    if (status === "completed")
      track("Submission Completed", {
        formUri,
        submissionUri,
      });

    try {
      setConnection({ status: "loading" });
      const updateResponseResults = await Promise.all(
        entriesOf(responses)
          .filter(([, value]) => value !== undefined)
          .map(([fieldUri, value]) =>
            updateResponse({
              variables: {
                fieldUri,
                value: JSON.stringify(value),
                submissionUri,
                formUri,
              },
            }),
          ),
      );
      const operationIds = updateResponseResults.map(
        (r) => r.data?.updateResponse.operationId,
      );

      let updateSubmissionResult;
      if (status || lastUpdatedFieldUri || lastUpdatedSectionUri) {
        updateSubmissionResult = await updateSubmission({
          variables: {
            uri: submissionUri,
            formUri,
            fields: {
              lastUpdatedFieldUri,
              lastUpdatedSectionUri,
              status,
            },
          },
        });
        operationIds.push(
          updateSubmissionResult.data?.updateSubmission.values.operationId,
        );
      }
      setConnection({ status: "success" });

      if (status === "completed") {
        if (!isInternal) {
          setToasts([
            {
              text: "Your responses have been saved",
            },
          ]);
        }
        setSubmitted(true);
      }

      return {
        operationIds: operationIds.filter(Boolean) as string[],
        submissionStatus: {
          status:
            status ||
            updateSubmissionResult?.data?.updateSubmission.values.status,
          deleted: updateSubmissionResult?.data?.updateSubmission.values
            .deleted as boolean | undefined,
        },
      };
    } catch (err) {
      setConnection({ status: "error", error: String(err) });
      return undefined;
    }
  };

  if (matterSubmission?.formUri === familyProQuestionnaireFormUri) return null;

  const statusToDisplay = () => {
    switch (matterSubmission?.status) {
      case "completed":
        return "completed";
      case "cancelled":
        return "closed";
      default:
        return undefined;
    }
  };
  const { aiFillFromMatterInfo, aiFillFiles } = submission.result?.values || {};

  useEffect(() => {
    if (!aiFillFromMatterInfo || aiFillFiles?.length) return;
    if (
      matterSubmission?.status === "completed" ||
      matterSubmission?.status === "cancelled"
    )
      return;

    const { status: aiUserFeedbackStatus } =
      submission.result?.aiUserFeedback?.values || {};

    switch (aiUserFeedbackStatus) {
      case "reviewed":
        setAutoFillStatus(undefined);
        break;
      case "generated":
      case "completed":
        if (autoFillStatus !== "filling") {
          setHideAutoFillCompletedToast(true);
        } else {
          setHideAutoFillCompletedToast(false);
        }
        setAutoFillStatus("completed");
        break;
      case "generating":
        setAutoFillStatus("filling");
        break;
      default:
        setAutoFillStatus("filling");
    }
  }, [
    submission.result?.aiUserFeedback?.values.status,
    matterSubmission?.status,
  ]);

  return (
    <ThemeProvider theme={intakeTheme}>
      {!displayProps.loading && displayProps.exists && submission.result && (
        <IntakeForm
          {...displayProps}
          key={
            autoFillStatus === "filling"
              ? JSON.stringify(submission.result?.responses)
              : undefined
          }
          autoFillStatus={autoFillStatus}
          form={{
            ...displayProps.form,
            response: appendDownloadFormLink(
              displayProps.form.response || defaultTemplate.response,
            ),
          }}
          lookup={{
            addressLookup: displayProps.lookup.addressLookup,
            fileLookup: {
              upload,
              load: async ({ fieldUri, fileNames }) => {
                const result = await uploadFiles({
                  variables: {
                    fieldUri,
                    fileNames,
                    formUri: matterSubmission!.formUri,
                    submissionUri: matterSubmission!.uri,
                  },
                });
                return result.data?.uploadFiles;
              },
            },
          }}
          connection={connection}
          submission={{
            ...submission.result.values,
            autoFillStatus: submission.result.aiUserFeedback?.values.status,
            responses: fromEntries(
              submission.result.responses.map((r) => [
                r.fieldUri,
                {
                  value: jsonParse(r.value, "parse response"),
                  syncStatus: r.syncStatus,
                },
              ]),
            ) as SubmissionItem["responses"],
          }}
          submitResponses={submit}
          isInternal={isInternal}
          loadResponses={async () => undefined}
          staffDetails={staffDetails}
          newUI
          hideAutoFillCompleteToast={hideAutoFillCompleteToast}
          aiWordSupport={aiWordSupport}
          loading={loading}
          isAutoFillFileUploadDisabled
        />
      )}
      <Modal
        header={{
          icon: "solidCheck",
          iconBgColor: "bg-green-100",
          text: `This form has been ${statusToDisplay()}`,
        }}
        onClose={() => navigateTo("view")}
        open={!!statusToDisplay() && !submitted}
        footer={{
          buttons: [
            {
              text: "OK",
              variant: "primaryDefault",
              onClick: () => navigateTo("view"),
            },
          ],
        }}
        loading={displayProps.loading}
      >
        <p className="text-ui">
          The form you are trying to edit has been marked as {statusToDisplay()}{" "}
          and responses can no longer be edited.
        </p>
      </Modal>
      <Modal
        header={{
          icon: "solidCheck",
          iconBgColor: "bg-green-100",
          text: "Form submitted",
        }}
        onClose={() => navigateTo("view")}
        open={
          submitted &&
          isInternal &&
          !aiFillFromMatterInfo &&
          !aiFillFiles?.length
        }
        footer={{
          buttons: [
            {
              text: "OK",
              variant: "primaryDefault",
              onClick: () => navigateTo("view"),
            },
          ],
        }}
        dataTestId="form-submitted-modal"
      >
        <p className="text-ui">Your form has been submitted successfully.</p>
      </Modal>
      <AutofillFeedback
        title="Your form has been submitted"
        open={submitted && autoFillStatus === "completed" && isInternal}
        onSubmit={async (feedback) => {
          if (!submission.result?.aiUserFeedback?.uri) return;

          await updateAIUserFeedback({
            variables: {
              uri: submission.result?.aiUserFeedback?.uri,
              feedback,
              status: "reviewed",
            },
          });
          navigateTo("view");
        }}
      />
    </ThemeProvider>
  );
};
