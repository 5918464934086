import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Typeahead } from "@smart/itops-components-dom";
import {
  FailureMessage,
  FieldList,
  Label,
  Modal,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { buildLogProps } from "@smart/itops-utils-basic";
import { useLazyLoadContacts } from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

const useSearchContacts = ({ name }: { name: string }) => {
  // useLazyLoadContacts needs to be called here so when getContacts
  // is called, the internal state update of useLazyLoadContacts happens
  // in the same component (Typeahead).
  // Refer to https://github.com/facebook/react/issues/18178#issuecomment-602323184
  const getContacts = useLazyLoadContacts();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Gql.ContactFieldsFragment[]>();
  const [search, setSearch] = useState<string>();

  if (!name || name.length <= 1) return { result: undefined, loading: false };

  if (name.length > 1 && search !== name) {
    setResult(undefined);
    setSearch(name);
    setLoading(true);
    getContacts?.({
      names: [name],
    })
      .then((searchResult) => {
        setLoading(false);
        setResult(searchResult?.filter((c) => !!c.person) || []);
      })
      .catch(() => {
        setLoading(false);
        setResult(undefined);
      });
  }

  return { result, loading };
};

export type TabAddContactProps = {
  onClose: () => void;
  onCreate: (props: {
    firstName: string;
    lastName: string;
    email: string;
    id?: string;
  }) => Promise<void>;
  visible: boolean;
};

const tabAddContactSchema = z.object({
  firstName: z.string().min(1, "Please enter a first name"),
  lastName: z.string().min(1, "Please enter a last name"),
  email: z
    .string()
    .min(1, "Please enter an email")
    .email("Please enter a valid email"),
  selectedContact: z
    .object({
      person: z
        .object({
          firstName: z.string().optional(),
          lastName: z.string().optional(),
          email: z.string().optional(),
        })
        .passthrough(),
    })
    .passthrough()
    .optional()
    .nullable(),
});

export const TabAddContact = ({
  onClose,
  onCreate,
  visible,
}: TabAddContactProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();

  const form = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    selectedContact: Gql.ContactFieldsFragment | null;
  }>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      selectedContact: null,
    },
    resolver: zodResolver(tabAddContactSchema),
  });
  const { handleSubmit, watch, setValue, control, trigger } = form;

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, email, selectedContact }) => {
      setHasError(undefined);
      setIsLoading(true);
      try {
        await onCreate({
          firstName: selectedContact?.person?.firstName || firstName,
          lastName: selectedContact?.person?.lastName || lastName,
          email,
          id: selectedContact?.id,
        });
      } catch (e) {
        const { logMessage, errorProps } = buildLogProps(e);
        setHasError(`${logMessage} - ${errorProps.message}`);
      }
      setIsLoading(false);
    },
  );

  const selectedContact = watch("selectedContact");
  const displayName = ({ person }: Gql.ContactFieldsFragment) =>
    [person?.firstName, person?.middleName, person?.lastName]
      .filter(Boolean)
      .join(" ");
  const displayDetails = ({ person }: Gql.ContactFieldsFragment) => {
    const addressDisplay = [
      person?.residentialAddress?.city,
      [person?.residentialAddress?.state, person?.residentialAddress?.zipCode]
        .filter(Boolean)
        .join(" "),
    ]
      .filter(Boolean)
      .join(", ");
    return [
      person?.email,
      person?.cell?.number || person?.phone?.number,
      addressDisplay,
    ]
      .filter(Boolean)
      .join(" | ");
  };

  useEffect(() => {
    const person = selectedContact?.person;
    if (person) {
      setValue("email", person.email || "");
      trigger("email").catch(console.error);
    }
  }, [selectedContact]);

  return (
    <Modal
      header={{
        icon: "solidContactCard",
        text: "Missing contact for the matter",
      }}
      open={visible}
      onClose={onClose}
      footer={{
        buttons: [
          {
            text: "Cancel",
            type: "reset",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: "Save To Matter",
            onClick: onSubmit,
          },
        ],
      }}
      loading={isLoading}
      closeOptions={{ clickOutside: false }}
    >
      <p>Enter client contact details in order to share a form to them.</p>
      <FieldList>
        <Controller
          control={control}
          name="firstName"
          render={({ field, fieldState }) => (
            <Label
              name={field.name}
              text="First Name"
              error={!!fieldState.error}
              message={fieldState.error?.message}
              mandatory
            >
              <Typeahead
                id={field.name}
                selected={selectedContact}
                onSelect={(selected) => setValue("selectedContact", selected)}
                onInput={(value) => field.onChange(value)}
                onClear={() => setValue("email", "")}
                hook={useSearchContacts}
                locators={{}}
                idKey="id"
                input={(s) => s.person?.firstName || ""}
                display={displayName}
                details={displayDetails}
                searchKey="name"
                interaction={selectedContact ? "readOnly" : undefined}
                showSelectedDetails={false}
                newUI
              />
            </Label>
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState }) => (
            <Label
              name={field.name}
              text="Last Name"
              error={!!fieldState.error}
              message={fieldState.error?.message}
              mandatory
            >
              <Typeahead
                id={field.name}
                selected={selectedContact}
                onSelect={(selected) => setValue("selectedContact", selected)}
                onInput={(value) => field.onChange(value)}
                onClear={() => setValue("email", "")}
                hook={useSearchContacts}
                locators={{}}
                idKey="id"
                input={(s) => s.person?.lastName || ""}
                display={displayName}
                details={displayDetails}
                searchKey="name"
                interaction={selectedContact ? "readOnly" : undefined}
                showSelectedDetails={false}
                newUI
              />
            </Label>
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <TextFieldInput
              id={field.name}
              label="Email"
              message={fieldState.error?.message}
              error={!!fieldState.error}
              dataTestId="add-contact"
              mandatory
              {...field}
            />
          )}
        />
      </FieldList>
      {hasError && (
        <FailureMessage action="save contact information" title={hasError} />
      )}
    </Modal>
  );
};
