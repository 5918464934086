import clsx from "clsx";

import { focusBorder } from "../button/class-names";

export type AvatarStyle = "square" | "round";
export type AvatarSize = 24 | 32 | 40 | 48 | 56 | 64;

const base =
  "flex justify-center items-center relative bg-black/10 font-semibold outline-none";
const focus = clsx(focusBorder, "focus:bg-neutral-50");
const hover = "hover:bg-black/5";
const activeStyles = "active:bg-black/20";
const disabledStyles = "text-neutral-400 bg-black/5";

export const getAvatarClassNames = ({
  style,
  size,
  disabled,
  active,
  bgColor,
}: {
  style: AvatarStyle;
  size: AvatarSize;
  disabled: boolean | undefined;
  active: boolean | undefined;
  bgColor?: string;
}) => {
  const sizeClassNames = {
    24: "w-[2.4rem] h-[2.4rem] text-caption",
    32: "w-[3.2rem] h-[3.2rem] text-ui",
    40: "w-[4rem] h-[4rem] text-paragraph",
    48: "w-[4.8rem] h-[4.8rem] text-paragraph",
    56: "w-[5.6rem] h-[5.6rem] text-paragraph",
    64: "w-[6.4rem] h-[6.4rem] text-paragraph",
  };
  const styleClassNames = {
    square:
      size === 24
        ? "rounded-[0.4rem] focus:before:rounded-[0.4rem]"
        : "rounded focus:before:rounded",
    round: "rounded-full focus:before:rounded-full",
  };

  return clsx(
    base,
    bgColor,
    !disabled
      ? !bgColor && clsx(focus, hover, activeStyles, active && "bg-black/20")
      : disabledStyles,
    sizeClassNames[size],
    styleClassNames[style],
  );
};

export const getIconClassNames = (size: AvatarSize) => {
  const iconSizeClassNames = {
    24: "w-[1.3rem] h-[1.3rem]",
    32: "w-[1.8rem] h-[1.8rem]",
    40: "w-[2.4rem] h-[2.4rem]",
    48: "w-[2.4rem] h-[2.4rem]",
    56: "w-[3.2rem] h-[3.2rem]",
    64: "w-[3.2rem] h-[3.2rem]",
  };

  return iconSizeClassNames[size];
};
