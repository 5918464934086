import { Button, ButtonProps } from "../button";

export type ModalFooterProps = {
  buttons?: (ButtonProps & { key?: string })[];
  loading?: boolean;
};

export const ModalFooter = ({ buttons, loading }: ModalFooterProps) => (
  <div className="relative flex items-center justify-end gap-medium h-[6.4rem] px-[2.4rem] py-[1.2rem]">
    {buttons?.map((buttonProps) => (
      <Button key={buttonProps.key || buttonProps.text} {...buttonProps} />
    ))}
    {loading && <div className="absolute inset-0 cursor-wait" />}
  </div>
);
