import { useMutation } from "@apollo/client";

import { sortField } from "@smart/itops-utils-basic";
import {
  Gql,
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation, updateAfterDelete } from "../cache";

export const useDeleteGroup = () =>
  useMutation(mutationDocuments.deleteGroup, {
    optimisticResponse: optimisticOperation("deleteGroup"),
    update: (cache, { data }, { variables }) => {
      if (variables && data?.deleteGroup) {
        const queryFields = cache.readQuery({
          query: queryDocuments.fields,
          variables: {
            formUri: variables?.formUri || "",
          },
        });

        queryFields?.fields
          .filter((f) => f.values.groupUri === variables.uri)
          .forEach((f) =>
            updateAfterDelete("deleteField", "Field")(
              cache,
              {
                data: {
                  deleteField: { createdAt: data.deleteGroup.createdAt },
                },
              },
              { variables: { uri: f.uri } },
            ),
          );

        updateAfterDelete("deleteGroup", "Group")(
          cache,
          { data },
          { variables },
        );
      }
    },
    refetchQueries: [queryDocuments.fields],
  });

export const useUpdateGroup = () =>
  useMutation(mutationDocuments.updateGroup, {
    optimisticResponse: (input): Gql.UpdateGroupMutation => ({
      __typename: "Mutation",
      updateGroup: {
        __typename: "Group",
        uri: input.uri,
        values: {
          __typename: "GroupValues",
          uri: input.uri,
          sectionUri: input.sectionUri,
          formUri: input.formUri,
          order: input.order,
          type: input.fields.type,
          label: input.fields.label ?? null,
          description: input.fields.description ?? null,
          hint: input.fields.hint ?? null,
          allowedRepeatable: input.fields.allowedRepeatable ?? null,
          repeatable: input.fields.repeatable ?? null,
          maxRepeat: input.fields.maxRepeat ?? null,
          minRepeat: input.fields.minRepeat ?? null,
          repeatPrompt: input.fields.repeatPrompt ?? null,
          templateType: input.fields.templateType ?? null,

          layout: input.fields.layout
            ? {
                __typename: "MatterLayout",
                id: input.fields.layout.id,
                name: input.fields.layout.name,
                providerId: input.fields.layout.providerId,
                displayName: input.fields.layout.parentName
                  ? `${input.fields.layout.parentName}/${input.fields.layout.name}`
                  : input.fields.layout.name,
                parentId: input.fields.layout.parentId ?? null,
                parentName: input.fields.layout.parentName ?? null,
                parentProviderId: input.fields.layout.parentProviderId ?? null,
              }
            : null,
          field: input.fields.field
            ? {
                __typename: "MatterField",
                name: input.fields.field.name,
                type: input.fields.field.type,
                possibleValues: input.fields.field.possibleValues ?? null,
                details: input.fields.field.name.includes("/")
                  ? input.fields.field.name.slice(
                      0,
                      input.fields.field.name.lastIndexOf("/"),
                    )
                  : "",
                displayName: input.fields.field.name.slice(
                  input.fields.field.name.lastIndexOf("/") + 1,
                ),
              }
            : null,
          links: (input.fields.links || []).map((link) => ({
            __typename: "FieldLink",
            condition: link.condition,
            fieldUri: link.fieldUri,
            value: link.value,
            hide: link.hide,
          })),
          updatedAt: new Date().toISOString(),
          deleted: false,
          operationId: "",
        },
      },
    }),
    update: (cache, { data }, { variables }) => {
      if (data?.updateGroup && variables) {
        const queryFields = cache.readQuery({
          query: queryDocuments.fields,
          variables: {
            formUri: variables?.formUri || "",
          },
        });

        const fieldsWithWrongSectionUri = queryFields?.fields.filter(
          (f) =>
            !f.values.deleted &&
            f.values.groupUri === data.updateGroup.uri &&
            f.values.sectionUri !== data.updateGroup.values.sectionUri,
        );

        fieldsWithWrongSectionUri?.forEach((f) => {
          cache.updateQuery(
            {
              query: queryDocuments.fields,
              variables: {
                formUri: variables.formUri,
                sectionUri: variables.sectionUri,
              },
            },
            (ex): Gql.FieldsQuery | null => ({
              fields: [
                {
                  ...f,
                  values: {
                    ...f.values,
                    sectionUri: data.updateGroup.values.sectionUri,
                  },
                },
                ...(ex?.fields.filter(
                  (existingField) => existingField.uri !== f.uri,
                ) || []),
              ],
              __typename: "Query",
            }),
          );
        });

        cache.updateQuery(
          {
            query: queryDocuments.groups,
            variables: {
              formUri: variables.formUri,
              sectionUri: variables.sectionUri,
            },
          },
          (ex): Gql.GroupsQuery | null => {
            const exists = ex?.groups.find(
              (s) => s.uri === data.updateGroup.uri,
            );
            if (exists) return ex;
            return {
              groups: [data.updateGroup, ...(ex?.groups || [])],
              __typename: "Query",
            };
          },
        );

        cache.updateQuery(
          {
            query: queryDocuments.groups,
            variables: {
              formUri: variables.formUri,
            },
          },
          (ex): Gql.GroupsQuery | null => {
            const exists = ex?.groups.find(
              (s) => s.uri === data.updateGroup.uri,
            );
            if (exists) return ex;
            return {
              groups: [data.updateGroup, ...(ex?.groups || [])],
              __typename: "Query",
            };
          },
        );
      }
    },
  });

export const useUpdateGroupWithFields = () =>
  useMutation(mutationDocuments.updateGroupWithFields, {
    update: (cache, { data }, { variables }) => {
      if (data?.updateGroupWithFields && variables) {
        const updateGroupFields = (
          ex: Gql.FieldsQuery | null,
        ): Gql.FieldsQuery | null => {
          const newFieldValues =
            data.updateGroupWithFields.values.fields?.filter(
              (f) => !ex?.fields.find((ef) => ef.uri === f.uri),
            );

          if (!newFieldValues || newFieldValues.length === 0) return ex;

          return {
            fields: [
              ...(ex?.fields || []),
              ...newFieldValues.map(
                (nf): Gql.FieldFieldsFragment => ({
                  __typename: "Field",
                  uri: nf.uri,
                  values: { ...nf },
                }),
              ),
            ],
            __typename: "Query",
          };
        };

        cache.updateQuery(
          {
            query: queryDocuments.fields,
            variables: {
              formUri: variables.formUri,
              sectionUri: variables.sectionUri,
            },
          },
          updateGroupFields,
        );

        cache.updateQuery(
          {
            query: queryDocuments.fields,
            variables: {
              formUri: variables.formUri,
            },
          },
          updateGroupFields,
        );

        const update = (ex: Gql.GroupsQuery | null): Gql.GroupsQuery | null => {
          const exists = ex?.groups.find(
            (g) => g.uri === data.updateGroupWithFields.uri,
          );

          if (exists) return ex;

          return {
            groups: [data.updateGroupWithFields, ...(ex?.groups || [])],
            __typename: "Query",
          };
        };

        cache.updateQuery(
          {
            query: queryDocuments.groups,
            variables: {
              formUri: variables.formUri,
              sectionUri: variables.sectionUri,
            },
          },
          update,
        );

        cache.updateQuery(
          {
            query: queryDocuments.groups,
            variables: {
              formUri: variables.formUri,
            },
          },
          update,
        );
      }
    },
  });

export const useUpdateGroupOrder = (shouldRefetch: boolean = true) =>
  useMutation(mutationDocuments.updateGroupOrder, {
    optimisticResponse: optimisticOperation("updateGroupOrder"),
    update: (cache, _, { variables }) => {
      if (variables) {
        const { uri, order } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Group", uri }),
          fields: {
            values: (existing) => ({ ...existing, order }),
          },
        });
      }
    },
    refetchQueries: shouldRefetch ? [queryDocuments.groups] : undefined,
  });

export const useLoadGroups = useQueryFactory(queryDocuments.groups, {
  map: ({ groups }) =>
    sortField(
      groups.filter((f) => !f.values.deleted),
      { key: (f) => f.values.order, dir: "asc" },
    ),
});

export const useLoadGroupsWithFields = useQueryFactory(
  queryDocuments.groupsWithFields,
  {
    map: ({ groups }) =>
      sortField(
        groups.filter((f) => !f.values.deleted),
        { key: (f) => f.values.order, dir: "asc" },
      ),
  },
);
