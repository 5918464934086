import {
  removeEmptyStringValues,
  removeEmptyValues,
} from "@smart/itops-utils-basic";

import {
  Address,
  IntakeContact,
  MatchType,
  MatchingContact,
  Phone,
  SBContact,
} from "./types";

const mergeObjectProperty = <T extends Object>({
  current,
  incoming,
  shouldOverride,
}: {
  current: T | undefined | null;
  incoming: T | undefined | null;
  shouldOverride?: boolean;
}): T | undefined | null => {
  if (!incoming) return current;
  if (!current) return incoming;

  const target = shouldOverride ? current : incoming;
  const source = shouldOverride
    ? removeEmptyValues(incoming)
    : removeEmptyValues(current);

  return Object.assign(target, removeEmptyStringValues(source as any));
};

const mergeContactDetails = <
  T extends SBContact["person"] | SBContact["company"],
>({
  current,
  incoming,
  shouldOverride,
}: {
  current: T | undefined;
  incoming: T | undefined;
  shouldOverride?: boolean;
}): T | undefined => {
  if (!incoming) return current;
  if (!current) return incoming;

  const mergedDetails = Object.entries(current).reduce(
    (merged, [key, currentValue]) => {
      let mergedValue;
      const incomingValue = incoming![key as keyof T];
      if (typeof currentValue === "object") {
        mergedValue = mergeObjectProperty({
          current: currentValue,
          incoming: incomingValue || null,
          shouldOverride,
        });
      } else {
        mergedValue = shouldOverride
          ? incomingValue || currentValue
          : currentValue || incomingValue;
      }

      return { ...merged, [key]: mergedValue };
    },
    {},
  ) as T;

  // Copy values which exist in incoming but not in current
  Object.keys(incoming).forEach((key) => {
    const typedKey = key as keyof T;
    if (!mergedDetails![typedKey] && incoming![typedKey])
      mergedDetails![typedKey] = incoming![typedKey];
  });

  return mergedDetails;
};

export const mergeContacts = ({
  current,
  incoming,
  shouldOverride,
}: {
  current: SBContact;
  incoming: Omit<SBContact, "id"> | null | undefined;
  shouldOverride?: boolean;
}): SBContact => {
  if (!incoming) return current;

  return {
    ...current,
    person: mergeContactDetails({
      current: current.person,
      incoming: incoming.person,
      shouldOverride,
    }),
    company: mergeContactDetails({
      current: current.company,
      incoming: incoming.company,
      shouldOverride,
    }),
  };
};

type RankingField = "name" | "email" | "phone" | "cell" | "address";

const ContactFieldRank: Record<RankingField, Record<MatchType, number>> = {
  name: {
    exact: 100,
    partial: 100,
    none: 0,
  },
  email: {
    exact: 200,
    partial: 0,
    none: 0,
  },
  phone: {
    exact: 300,
    partial: 0,
    none: 0,
  },
  cell: {
    exact: 310,
    partial: 0,
    none: 0,
  },
  address: {
    exact: 400,
    partial: 0,
    none: 0,
  },
};

const checkTextMatch = (
  input: string | undefined | null,
  existing: string | undefined | null,
): MatchType => {
  if (!input || !existing) return "none";

  const inputLowerCase = input.toLowerCase();
  const existingLowerCase = existing.toLowerCase();

  if (inputLowerCase === existingLowerCase) return "exact";
  if (
    inputLowerCase.includes(existingLowerCase) ||
    existingLowerCase.includes(inputLowerCase)
  )
    return "partial";

  return "none";
};

const checkAddressMatch = (
  input: Address | undefined | null,
  existing: Address | undefined | null,
): MatchType => {
  if (!input || !existing) return "none";

  const toAddressString = (address: Address): string =>
    [
      address.buildingLevel,
      address.unitNumber,
      address.unitType,
      address.streetNumber,
      address.streetName,
      address.streetType,
      address.addressLine1,
      address.addressLine2,
      address.city,
      address.state,
      address.zipCode,
      address.locality,
      address.county,
      address.country,
    ]
      .filter(Boolean)
      .join("")
      .toLowerCase();

  return checkTextMatch(toAddressString(input), toAddressString(existing));
};

const checkPhoneMatch = (
  input: Phone | undefined | null,
  existing: Phone | undefined | null,
): MatchType => {
  if (!input || !existing) return "none";

  const toPhoneString = (phone: Phone): string =>
    [phone.areaCode, phone.number]
      .filter(Boolean)
      .join("")
      .toLowerCase()
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .trim();

  return checkTextMatch(toPhoneString(input), toPhoneString(existing));
};

export const checkContactMatch = (
  input: IntakeContact,
  existing: SBContact,
): {
  matchType: MatchType;
  rank: number;
} => {
  let contactMatch: MatchType = "none";
  let nameMatch: MatchType = "none";
  let emailMatch: MatchType = "none";
  let phoneMatch: MatchType = "none";
  let cellMatch: MatchType = "none";
  let phoneCellMatch: MatchType = "none";
  let cellPhoneMatch: MatchType = "none";
  let addressMatch: MatchType = "none";
  let mailingAddressMatch: MatchType = "none";

  if (input.person && Object.keys(input.person).length > 0) {
    const firstNameMatch = checkTextMatch(
      input.person.firstName,
      existing.person?.firstName,
    );
    const lastNameMatch = checkTextMatch(
      input.person.lastName,
      existing.person?.lastName,
    );
    if (firstNameMatch === "exact" && lastNameMatch === "exact") {
      nameMatch = "exact";
    } else if (firstNameMatch === "none" || lastNameMatch === "none") {
      nameMatch = "none";
    } else {
      nameMatch = "partial";
    }
    emailMatch = checkTextMatch(input.person.email, existing.person?.email);
    phoneMatch = checkPhoneMatch(input.person.phone, existing.person?.phone);
    cellMatch = checkPhoneMatch(input.person.cell, existing.person?.cell);
    phoneCellMatch = checkPhoneMatch(input.person.phone, existing.person?.cell);
    cellPhoneMatch = checkPhoneMatch(input.person.cell, existing.person?.phone);
    addressMatch = checkAddressMatch(
      input.person.residentialAddress,
      existing.person?.residentialAddress,
    );
    mailingAddressMatch = checkAddressMatch(
      input.person.mailingAddress,
      existing.person?.mailingAddress,
    );
  } else if (input.company) {
    nameMatch = checkTextMatch(input.company.name, existing.company?.name);
    emailMatch = checkTextMatch(input.company.email, existing.company?.email);
    phoneMatch = checkPhoneMatch(input.company.phone, existing.company?.phone);
    addressMatch = checkAddressMatch(
      input.company.businessAddress,
      existing.company?.businessAddress,
    );
    mailingAddressMatch = checkAddressMatch(
      input.company.mailingAddress,
      existing.company?.mailingAddress,
    );
  }

  if (nameMatch === "exact") {
    contactMatch = emailMatch === "exact" ? "exact" : "partial";
  } else {
    contactMatch =
      emailMatch === "exact" ||
      phoneMatch === "exact" ||
      cellMatch === "exact" ||
      phoneCellMatch === "exact" ||
      cellPhoneMatch === "exact" ||
      addressMatch === "exact" ||
      mailingAddressMatch === "exact"
        ? "partial"
        : nameMatch;
  }

  return {
    matchType: contactMatch,
    rank:
      ContactFieldRank.name[nameMatch] +
      ContactFieldRank.email[emailMatch] +
      ContactFieldRank.phone[phoneMatch] +
      ContactFieldRank.cell[cellMatch] +
      ContactFieldRank.phone[phoneCellMatch] +
      ContactFieldRank.cell[cellPhoneMatch] +
      ContactFieldRank.address[addressMatch] +
      ContactFieldRank.address[mailingAddressMatch],
  };
};

export const getMatchingContacts = (
  input: IntakeContact,
  existings: SBContact[],
): MatchingContact[] =>
  existings
    .map((e) => ({
      ...e,
      ...checkContactMatch(input, e),
    }))
    .filter((mc) => mc.matchType !== "none");
