import { useCallback, useReducer } from "react";

import { SubmissionNS } from "@smart/bridge-types-basic";
import {
  Column,
  GqlStatusOverlay,
  IconButton,
  LinkDisplay,
  Toggle,
} from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import {
  submissionLink,
  useCreateSubmission,
  useToggleSlug,
  useUpdateSubmission,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { FormTabProps } from "./types";

const FormDetails = ({ form: { uri, values, slug } }: FormTabProps) => {
  const { title, teamUri, active } = values;
  const [create, { loading: creating }] = useCreateSubmission();
  const [update, { loading: updating }] = useUpdateSubmission();
  const [mutate, toggleSlug] = useToggleSlug();

  const { permission } = useUser();
  const canActivate = permission?.can("update", {
    type: "slug",
    value: {
      uri: slug?.uri || "",
      formUri: uri,
      active: slug?.values.active ?? false,
    },
    parentForm: { uri, teamUri, active: active ?? undefined },
  });

  const slugActive = !!slug?.values.active;

  const [links, addLink] = useReducer(
    (state: string[], link: string) => [...state, link],
    [],
  );
  const createLink = useCallback(async () => {
    const created = await create({
      variables: { uri: SubmissionNS.generateUri(), formUri: uri },
    });
    if (created?.data?.createSubmission) {
      await update({
        variables: {
          uri: created.data.createSubmission.uri,
          formUri: uri,
          fields: {
            status: "active",
          },
        },
      });

      const link = submissionLink(created.data.createSubmission);
      if (link) addLink(link);
    }
  }, [uri]);

  return (
    <Column slim>
      <LinkDisplay
        instruction="Preview your Form:"
        text={title}
        link={`/preview/${extractId(uri)}`}
      />
      {canActivate && (
        <Toggle
          text="Allow responses?"
          toggled={slugActive}
          onClick={() =>
            mutate({
              variables: {
                formUri: uri,
                fields: { active: !slugActive },
              },
            })
          }
        />
      )}
      {slugActive && (
        <LinkDisplay instruction="Share your Form:">
          {links.map((link) => (
            <a target="_blank" rel="noreferrer" href={link} key={link}>
              {link}
            </a>
          ))}
          <IconButton
            name="add"
            text="Generate Unique Link"
            onClick={createLink}
          />
        </LinkDisplay>
      )}
      <GqlStatusOverlay
        {...toggleSlug}
        loading={toggleSlug.loading || creating || updating}
      />
    </Column>
  );
};

export { FormDetails };
