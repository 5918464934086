import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import { Modal } from "@smart/itops-ui-dom";
import {
  useLoadMatterTypeCategories,
  useQueryMatterTypes,
} from "@smart/manage-gql-client-dom";

import { RemappingFieldList } from "./remapping-field-list";
import { FormFieldsList } from "../../list-forms/creating/form-field-list";
import {
  EditingFormInfo,
  editingFormInfoSchema,
  formCategoryOptions,
} from "../../list-forms/form";
import {
  GqlField,
  GqlFormCategory,
  GqlGroup,
  GqlMatterType,
  GqlSetting,
} from "../../types";
import { useRemapFields } from "../hooks";

type EditFormInfoModalProps = {
  loading?: boolean;
  onClose: () => void;
  open: boolean;
  category?: GqlFormCategory;
  updateFormInfo: (values: EditingFormInfo) => Promise<void>;
  setting?: GqlSetting | null;
  matterTypeLocations: string[];
  matterTypes: GqlMatterType[];
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
};

const defaultMatterTypesGroups = (matterTypes: GqlMatterType[]) =>
  matterTypes.reduce(
    (result, matterType) => {
      const { name, category } = matterType;
      const existingName = result.find((item) => item.name === name);
      if (existingName) {
        existingName.matterTypes.push(matterType);
      } else {
        result.push({
          name,
          category,
          matterTypes: [matterType],
        });
      }
      return result;
    },
    [] as { name: string; category: string; matterTypes: GqlMatterType[] }[],
  );

export const EditFormInfoModal = ({
  loading,
  setting,
  onClose,
  updateFormInfo,
  open,
  category,
  matterTypeLocations,
  matterTypes,
  groups,
  fields,
}: EditFormInfoModalProps) => {
  const { aiWordSupport } = useFeatureFlags();
  const { terms, states } = loadLocale();
  const defaultLocations = [...new Set(matterTypeLocations)].sort();
  const { result: availableAreasOfLaw } = useLoadMatterTypeCategories({
    teamUri: setting?.teamUri,
  });

  const formSchema = editingFormInfoSchema({ terms });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<EditingFormInfo>({
    defaultValues: {
      formCategory: category || "lead",
      locations: defaultLocations,
      matterTypeGroups: defaultMatterTypesGroups(matterTypes),
    },
    resolver: zodResolver(formSchema),
  });

  const [formCategory, locations, matterTypeGroups] = watch([
    "formCategory",
    "locations",
    "matterTypeGroups",
  ]);
  const {
    groupsToUpdate,
    fieldsToUpdate,
    hasSameMatterTypes,
    remapFields,
    isLoading: isCheckingRemapping,
  } = useRemapFields({
    groups,
    fields,
    currentMatterTypeIds: matterTypes.map((mt) => mt.id),
    targetMatterTypeIds: matterTypeGroups.flatMap((g) =>
      g.matterTypes.map((mt) => mt.id),
    ),
  });

  const matterTypeOptions = useQueryMatterTypes({
    type: formCategory,
    locations,
    categories:
      availableAreasOfLaw?.filter((areaOfLaw) =>
        setting?.areasOfLaw?.includes(areaOfLaw),
      ) || [],
  }).byCategory;

  const onSubmit = handleSubmit(async (values) => {
    await updateFormInfo(values);
    await remapFields();
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <Modal
        onClose={onClose}
        open={open}
        loading={loading || isCheckingRemapping || isSubmitting}
        header={{
          icon: { library: "lucide", name: "Zap", variant: "action" },
          text: "Update form",
        }}
        footer={{
          left: [
            {
              text: "Cancel",
              variant: "cancel",
              onClick: onClose,
            },
          ],
          right: [
            {
              text: `Update form`,
              type: "submit",
              variant: "save",
            },
          ],
        }}
      >
        <FormFieldsList
          control={control}
          formCategoryOptions={formCategoryOptions}
          locations={setting?.locations || states.map((s) => s.name)}
          matterTypeOptions={matterTypeOptions.flatMap((c) =>
            c.loading
              ? [{ category: c.category, name: "Loading..." }]
              : c.matterTypes,
          )}
          aiWordSupport={aiWordSupport}
        />
        {!hasSameMatterTypes && (
          <RemappingFieldList
            groups={groups}
            fields={fields}
            groupsToUpdate={groupsToUpdate}
            fieldsToUpdate={fieldsToUpdate}
          />
        )}
      </Modal>
    </form>
  );
};
