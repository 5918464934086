/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlFileItemFieldsFragment = {
  __typename: "FileItem";
  id: string;
  type: Types.GqlFileItemType;
  name: string;
  fileExtension?: string | null;
  parentId?: string | null;
  sizeBytes?: number | null;
};

export const FileItemFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "fileItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FileItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "fileExtension" } },
          { kind: "Field", name: { kind: "Name", value: "parentId" } },
          { kind: "Field", name: { kind: "Name", value: "sizeBytes" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlFileItemFieldsFragment, unknown>;
