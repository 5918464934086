import clsx from "clsx";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import {
  Button,
  DraggableItemProps,
  EditableText,
  Icon,
} from "@smart/itops-sb-design-system-dom";

import { UpdatedSection } from "../../types";

export type MovableSectionEditProps = {
  numOfSections: number;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  setTitle: (index: number, title: string) => void;
  setDeleted: (index: number, deleted: boolean) => void;
  newSectionUri?: string;
};

type MovableSectionProps = DraggableItemProps<UpdatedSection> &
  MovableSectionEditProps;

const MovableSection = ({
  index,
  item,
  draggableProvided,
  moveUp,
  moveDown,
  setTitle,
  setDeleted,
  numOfSections,
  newSectionUri,
}: MovableSectionProps) => {
  const draggable = numOfSections > 1;
  return (
    <div
      className={clsx(
        "flex flex-wrap items-center gap-small p-[0.8rem] pr-[1.6rem] bg-white border rounded border-solid border-neutral-200",
        item.deleted && "hidden",
      )}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      draggable={draggable}
    >
      <div
        {...draggableProvided?.dragHandleProps}
        className={clsx(
          "flex items-center",
          !draggable && "pointer-events-none invisible",
        )}
      >
        <Icon name="regularGripDotsVertical" />
      </div>
      <div className="flex-1 text-ellipsis overflow-hidden">
        <EditableText
          text={item.title}
          placeholder={fieldFallbackLabel.section}
          editing={newSectionUri ? newSectionUri === item.uri : undefined}
          onChange={(value) => setTitle(index, value)}
          onComplete={async () => {}}
        />
      </div>
      <div className="flex gap-small">
        <Button
          variant="secondarySubtle"
          leftIcon="regularAngleUp"
          dataTestId={`move-up-button-${item.uri}`}
          disabled={index === 0}
          onClick={() => moveUp(index)}
        />
        <Button
          variant="secondarySubtle"
          leftIcon="regularAngleDown"
          dataTestId={`move-down-button-${item.uri}`}
          disabled={index + 1 === numOfSections}
          onClick={() => moveDown(index)}
        />
        <Button
          variant="secondarySubtle"
          leftIcon="regularTrashCan"
          dataTestId={`delete-section-button-${item.uri}`}
          onClick={() => setDeleted(index, true)}
        />
      </div>
    </div>
  );
};

export { MovableSection };
