import { SDKError } from "@smart/itops-utils-basic";

import { FileRequest, UseSDKApiOptions } from "../types";

export const useFiles = ({ client, isInit }: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  return {
    open: (request: FileRequest) => {
      try {
        return client.files.open(request);
      } catch (error) {
        throw new SDKError(error, {
          action: "files.open",
          input: request,
        });
      }
    },
  };
};
