import { TField } from "@smart/bridge-resources-basic";
import { convertMatterFields } from "@smart/bridge-smokeball-basic";
import { loadDefaultCountry } from "@smart/itops-locale-dom";
import { Matter, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import {
  fromEntries,
  jsonParseOrReturn,
  isNotNullOrUndefined,
} from "@smart/itops-utils-basic";
import { CachedGql } from "@smart/manage-cached-gql-operations-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

type ConvertToResponsesOptions = {
  layouts: NonNullable<ReturnType<typeof useSmokeballApp>["layouts"]>;
  contacts: NonNullable<ReturnType<typeof useSmokeballApp>["contacts"]>;
  relationships: NonNullable<
    ReturnType<typeof useSmokeballApp>["relationships"]
  >;
  roles: NonNullable<ReturnType<typeof useSmokeballApp>["roles"]>;
  sections: Gql.SectionFieldsFragment["values"][];
  fields: Gql.FieldFieldsFragment["values"][];
  groups: Gql.GroupFieldsFragment["values"][];
  matterType: CachedGql.MatterTypeFieldsFragment;
  matter: Matter;
  isFormCategoryDifferent: boolean;
  formMatterTypeRepresentatives?: string[];
  recipientContactId?: string;
};

export const convertToResponses = async ({
  layouts,
  contacts,
  sections,
  fields,
  relationships,
  roles,
  groups,
  matterType,
  matter,
  isFormCategoryDifferent,
  formMatterTypeRepresentatives,
  recipientContactId,
}: ConvertToResponsesOptions) => {
  const currentLayouts = (await layouts.get())?.value || [];
  const currentRoles = (await roles.get())?.roles || [];
  const relationshipRoles = (
    await Promise.all(
      currentRoles.map(async (role) => {
        try {
          const roleRelationships = await relationships.get(role.id);
          return roleRelationships.relationships
            .filter((relationship) => isNotNullOrUndefined(relationship.name))
            .map((relationship) => ({
              relationshipRoleName: role.name,
              ...relationship,
            }));
        } catch (error) {
          console.error("Error calling SDK API", error);
          return [];
        }
      }),
    )
  ).flat();

  /**
   * Because the SDK hasn't supported "getLayoutContacts" as in the API yet,
   * so it has to retrieve the layout contact via roleId which is the value
   * of the layoutItem's key/value when key matches the "layout contact"
   * group's name.
   *
   * The SDK hasn't supported "ContactRelation" API either so just ignore it for now
   *
   */
  const { responses } = await convertMatterFields({
    isFormCategoryDifferent,
    formMatterTypeRepresentatives,
    recipientContactId,
    country: loadDefaultCountry(),
    layouts: currentLayouts,
    fields: fields as TField[],
    sections,
    groups,
    matterType,
    matter,
    roles: [...currentRoles, ...relationshipRoles],
    getContact: contacts.get,
    getLayoutContacts: async (layoutItemId) => {
      const layoutContactGroups = groups.filter(
        (g) => g.type === "layoutContact",
      );
      if (layoutContactGroups.length === 0) return [];

      const layoutItem = currentLayouts.find((l) => l.itemId === layoutItemId);
      if (!layoutItem) return [];

      const layoutContactValues = layoutItem.values.filter(
        (v) => !!layoutContactGroups.find((g) => g.field?.name === v.key),
      );
      const layoutContacts = [];

      for (const { key, value } of layoutContactValues) {
        const contactId = currentRoles.find((r) => r.id === value)?.contactId;

        if (contactId) {
          const contact = await contacts.get(contactId);
          if (contact) layoutContacts.push({ key, contact });
        }
      }
      return layoutContacts;
    },
    getContactRelation: async () => [],
  });

  return responses;
};

export const convertToResponsesObject = async (
  options: ConvertToResponsesOptions,
) => {
  const asList = await convertToResponses(options);
  return fromEntries(
    asList.map(({ fieldUri, value }) => [fieldUri, jsonParseOrReturn(value)]),
  );
};
