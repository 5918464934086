import styled from "@emotion/styled";
import { useState } from "react";

import { DragNDropList } from "@smart/itops-components-dom";
import { Button, FullButtonRow } from "@smart/itops-ui-dom";

import { DraggableOptionItem, OptionsSharedProps } from "./option-item";
import { OtherOptionItem } from "./other-item";

const OptionList = styled.div`
  .draggable-options {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0 0.4rem;
  }
`;

const MAX_OPTIONS_TO_DISPLAY = 6;

type EditOptionsProps = OptionsSharedProps & {
  addOption: (options: "text" | "other") => void;
  moveOption: (sourceIndex: number, destinationIndex: number) => Promise<void>;
  openOptionModal?: () => void;
  isShowingOtherOption: boolean;
};

export const EditOptions = ({
  optionsFields,
  type,
  addOption,
  isShowingOtherOption,
  removeOption,
  updateOption,
  moveOption,
  openOptionModal,
}: EditOptionsProps) => {
  const [newOptionIndex, setNewOptionIndex] = useState<number>();
  const displayOverflow =
    openOptionModal && optionsFields.length > MAX_OPTIONS_TO_DISPLAY;

  return (
    <OptionList>
      <DragNDropList
        id="draggable-options"
        className="draggable-options"
        items={
          displayOverflow
            ? optionsFields.slice(0, MAX_OPTIONS_TO_DISPLAY)
            : optionsFields
        }
        itemKey="id"
        render={DraggableOptionItem}
        renderProps={{
          type,
          removeOption,
          optionsFields,
          updateOption: async (index: number, label: string | undefined) => {
            await updateOption(index, label);
            setNewOptionIndex(undefined);
          },
          newOptionIndex,
        }}
        onMove={async ({ source, destination }) => {
          await moveOption(source.index, destination.index);
        }}
      />
      <OtherOptionItem
        type={type}
        isShowingOtherOption={isShowingOtherOption}
        removeOption={removeOption}
      />
      {displayOverflow ? (
        <Button
          size="base"
          text={`View or edit all ${optionsFields.length} options`}
          variant="link"
          onClick={openOptionModal}
        />
      ) : (
        <FullButtonRow>
          <Button
            text="Add choice"
            icon={{ library: "lucide", name: "Plus" }}
            variant="action"
            onClick={() => {
              addOption("text");
              setNewOptionIndex(optionsFields.length);
            }}
          />
          <Button
            text="Add other"
            icon={{ library: "lucide", name: "Plus" }}
            variant="action"
            onClick={() => {
              addOption("other");
            }}
            disabled={isShowingOtherOption}
          />
        </FullButtonRow>
      )}
    </OptionList>
  );
};
