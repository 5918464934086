import { clsx } from "clsx";

const base =
  "flex items-center justify-between w-[3.6rem] h-[2rem] px-[0.3rem] transition duration-300 border border-solid border-neutral-200 rounded-[10px] bg-neutral-100 relative";
const notToggledFocus =
  "group-focus:bg-neutral-50 group-focus:border-neutral-200";
const notToggledHover =
  "group-hover:bg-neutral-50 group-hover:border-neutral-200";
const notToggledActive =
  "group-active:bg-blue-160 group-active:border-blue-160";
const toggledBase = "border-blue-560 bg-blue-560";
const toggledFocus = "group-focus:bg-blue-460 group-focus:border-blue-460";
const toggledHover = "group-hover:bg-blue-460 group-hover:border-blue-460";
const toggledActive = "group-active:bg-blue-660 group-active:border-blue-660";

export const getToggleClassNames = ({
  toggled,
  disabled,
  error,
}: {
  toggled: boolean;
  disabled: boolean;
  error: boolean;
}) => {
  const errorClassNames = toggled
    ? "bg-blue-460 border-blue-460"
    : "bg-neutral-50 border-neutral-200";
  const enabledClassNames = clsx(
    notToggledFocus,
    notToggledHover,
    notToggledActive,
    toggled && clsx(toggledBase, toggledFocus, toggledHover, toggledActive),
    error && errorClassNames,
  );

  return clsx(
    base,
    disabled ? "bg-black/10 border-black/0" : enabledClassNames,
  );
};
