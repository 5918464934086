import {
  tableKeys,
  transportKeys,
  bucketKeys,
} from "@smart/bridge-resources-basic";
import { buildPermissionsDef } from "@smart/itops-constructs-basic";

import { apiGateway } from "./api-gateway";
import { cachedGateway } from "./cached-gateway";
import { snsSqsLambdas } from "./sns-sqs";

export const permissions = buildPermissionsDef(
  [
    ...apiGateway.lambdaKeys,
    ...cachedGateway.lambdaKeys,
    ...snsSqsLambdas.lambdaKeys,
  ],
  tableKeys,
  transportKeys,
  bucketKeys,
  ["KeyGroups", "Bedrock"],
  {
    asset: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null, Asset: "WRITE", IntakeUploadedFiles: "WRITE" },
      additionalResources: { default: null },
    },
    echo: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    activityEventQueuer: {
      dynamo: { default: null },
      sns: { default: null, ActivityEvent: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    gql: {
      dynamo: { default: "READ" },
      sns: {
        default: null,
        Plan: "PUBLISH",
        QuestionGeneration: "PUBLISH",
        SmokeballIntegration: "PUBLISH",
      },
      s3: {
        default: null,
        IntakeUploadedFiles: "WRITE",
        QuestionGenerationFiles: "WRITE",
      },
      additionalResources: { default: null },
    },
    me: {
      dynamo: { default: null, Team: "READ" },
      sns: { default: null, Plan: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null, KeyGroups: "ACCESS" },
    },
    cachedGql: {
      dynamo: { default: "READ" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null, KeyGroups: "ACCESS" },
    },
    Backoff: {
      dynamo: { default: null },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Plan: {
      dynamo: { default: "WRITE" },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Requeue: {
      dynamo: { default: null },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Migrate: {
      dynamo: { default: "WRITE" },
      sns: { default: null, Migrate: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    QuestionGeneration: {
      dynamo: { default: "READ" },
      sns: { default: null, Backoff: "PUBLISH", Plan: "PUBLISH" },
      s3: {
        default: null,
        QuestionGenerationFiles: "WRITE",
        IntakeUploadedFiles: "READ",
      },
      additionalResources: {
        default: null,
        Bedrock: "ACCESS",
      },
    },
  },
);

export const specialPermissions = buildPermissionsDef(
  ["Plan", "Requeue", "Migrate", "dead-letter-dequeuer", "change-processor"],
  ["Audit", "DeadLetterItem", "Change", "Migration"],
  [],
  [],
  [],
  {
    Plan: {
      dynamo: { default: null, Audit: "WRITE" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Requeue: {
      dynamo: { default: null, DeadLetterItem: "WRITE" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Migrate: {
      dynamo: { default: null, Change: "READ", Migration: "WRITE" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    "dead-letter-dequeuer": {
      dynamo: { default: null, DeadLetterItem: "WRITE" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    "change-processor": {
      dynamo: { default: null, Change: "WRITE" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
  },
);
