import { Control, useController } from "react-hook-form";

import { FieldType } from "@smart/bridge-types-basic";
import { Label, Typeahead } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { useLoadFields, useLoadSections } from "@smart/manage-gql-client-dom";

import { FieldSchema } from "../shared-schemas";

type CopyFieldProps = {
  control: Control<UpdateHookValues<FieldSchema>>;
  fieldType: FieldType;
  formUri: string;
  uri: string;
  disabled?: boolean;
};

const CopyField = ({
  control,
  disabled,
  fieldType,
  formUri,
  uri,
}: CopyFieldProps) => {
  const { field } = useController({ control, name: "allowCopyFromFieldUri" });
  const sections = useLoadSections({ formUri });
  const fields = useLoadFields({ formUri });

  if (!sections.result?.length || !fields.result?.length) return null;

  const availableFields = fields.result!.filter(
    (f) => f.values.type === fieldType && f.uri !== uri,
  );

  const sectionTitles = sections.result!.reduce<Record<string, string>>(
    (titles, s) => ({
      ...titles,
      [s.uri]: s.values.title,
    }),
    {},
  );

  const searchableFields = availableFields.map((f) => ({
    ...f.values,
    sectionTitle: sectionTitles[f.values.sectionUri],
  }));

  const useSearchFields = ({ label }: { label: string }) => ({
    result: searchableFields.filter((v) => {
      const searchText = label.toLocaleLowerCase();
      return v.label?.toLocaleLowerCase().includes(searchText);
    }),
  });

  return (
    <Label name="copy-field" text="Allow copy from">
      <Typeahead
        interaction={disabled ? "disabled" : undefined}
        selected={searchableFields.find((f) => f.uri === field.value)}
        onSelect={(selected) => field.onChange(selected?.uri || "")}
        hook={useSearchFields}
        idKey="uri"
        input={(s) => s.label}
        display={(s) => s.label}
        details={(s) => s.sectionTitle}
        searchKey="label"
        locators={{}}
      />
    </Label>
  );
};

export { CopyField, CopyFieldProps };
