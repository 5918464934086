import {
  CellProps,
  MultipleTextLabel,
} from "@smart/itops-smokeball-components-dom";

export const Staff = ({ row }: CellProps<{ staff: string }>) => (
  <MultipleTextLabel
    texts={row.original.staff.split(", ")}
    maxVisibleItems={2}
  />
);
