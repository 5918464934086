import { useMemo, useState } from "react";

import { roleProviderId } from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { PickerSelection } from "./picker";
import { GqlField, GqlGroup, GqlMatterType } from "../../../types";
import { LoadMatterFields, MatterField } from "../../types";

export const useMappedMatterFields = ({
  selection,
  matterTypes,
  fields,
  groups,
  loadMatterFields,
}: {
  selection: PickerSelection;
  matterTypes?: GqlMatterType[];
  groups: GqlGroup[];
  fields: GqlField[];
  loadMatterFields: LoadMatterFields;
}) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [matterFields, setMatterFields] = useState<MatterField[]>([]);

  const currentLayoutComponent = [...selection.components]
    .filter((c) => c.type !== "path")
    .reverse()[0];

  const mapSharedPrefixFields = (
    inputFields: MatterField[],
    prefix: string,
  ): MatterField[] =>
    inputFields
      .filter((f) => f.name.startsWith(prefix))
      .map((f) => {
        const suffix = f.name.replace(new RegExp(`^${prefix}/`), "");
        const [displayName, ...rest] = suffix.split("/").reverse();
        return {
          ...f,
          displayName,
          details: rest.join("/"),
        };
      });

  const valueOrDefault = <T>(
    value: T | null | undefined,
    defaultValue?: T | null,
  ) => value || defaultValue;

  useAsync(
    async (info) => {
      setIsLoading(undefined);
      if (!selection.layout || !selection.components.length) {
        setMatterFields([]);
        return;
      }
      setIsLoading(true);

      try {
        const loaded = await loadMatterFields({
          matterLayoutId: selection.layout.id,
          matterTypeIds: matterTypes?.map((mt) => mt.id),
          providerId: selection.layout.providerId,
          teamUri: user?.teamUri || "",
        });

        const loadedRoleFields =
          currentLayoutComponent.type === "role"
            ? await loadMatterFields({
                matterLayoutId: selection.layout.id,
                matterTypeIds: matterTypes?.map((mt) => mt.id),
                providerId: roleProviderId,
                teamUri: user?.teamUri || "",
              })
            : [];

        // Combining layout fields and role's fields if it's a Role field
        const loadedLayoutFields =
          currentLayoutComponent.type === "role"
            ? mapSharedPrefixFields(
                loaded.filter((f) => f.type !== "Role"),
                currentLayoutComponent.name,
              )
            : loaded;

        if (info.mounted) {
          setMatterFields([...loadedLayoutFields, ...loadedRoleFields]);
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    },
    [selection.layout?.id, matterTypes, selection.components],
  );

  const existingMappedFields = useMemo(() => {
    const selectionRole = selection.components.find((c) => c.type === "role");

    return fields.reduce((agg, field) => {
      const group = groups.find((g) => g.uri === field.values.groupUri);

      if (group?.values.type === "layoutContact" && group.values.field) {
        if (
          !selectionRole ||
          !selection.layout?.id ||
          !group.values.layout ||
          valueOrDefault(group.values.layout.parentId) !==
            valueOrDefault(selection.layout?.parentId) ||
          group.values.layout.id !== selection.layout?.id ||
          group.values.field.name !== selectionRole.name ||
          !field.values.field
        )
          return agg;
      } else if (
        (group?.values.field &&
          valueOrDefault(selectionRole?.name) !==
            valueOrDefault(group.values.field.name)) ||
        (selectionRole && !group) ||
        !selection.layout?.id ||
        !field.values.layout ||
        valueOrDefault(field.values.layout.parentId) !==
          valueOrDefault(selection.layout?.parentId) ||
        field.values.layout.id !== selection.layout?.id ||
        !field.values.field
      )
        return agg;

      return [...agg, field.values.field.name];
    }, [] as string[]);
  }, [selection.layout?.id, selection.components, fields, groups]);

  return {
    currentLayoutComponent,
    isLoading,
    matterFields,
    existingMappedFields,
  };
};
