import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useRef, useState } from "react";

const MultipleTextLabelWrapper = styled.span`
  display: flex;
  align-items: center;

  .visible-items {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rest-count {
    margin-left: 0.5rem;
    padding: 0.1rem 0.6rem;
    border: 1px solid ${(props) => props.theme.palette.foreground.highlight};
    border-radius: 20px;
  }
`;

const TooltipContent = styled.div`
  background: var(--background);
  border: 1px solid ${(props) => props.theme.palette.foreground.highlight};
  border-radius: 5px;
  max-width: min(30rem, 96%);
  padding: ${(props) => props.theme.baseUnit * 0.2}rem
    ${(props) => props.theme.baseUnit * 0.6}rem;
  z-index: 1;

  svg {
    fill: var(--background);
  }

  p {
    margin: ${(props) => props.theme.baseUnit * 0.4}rem 0;
    white-space: normal;
  }
`;

type MultipleTextLabelProps = {
  texts: string[];
  maxVisibleItems?: number;
};

const MultipleTextLabel = ({
  texts,
  maxVisibleItems = 1,
}: MultipleTextLabelProps) => {
  const sorted = [...new Set(texts)].sort();
  const tooltip = sorted.join(", ");
  const visibleItems = sorted.splice(0, maxVisibleItems);

  const theme = useTheme();
  const arrowRef = useRef(null);
  const [open, onOpenChange] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: "bottom",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      shift({ padding: 5 }),
      flip({ fallbackPlacements: ["right", "left", "top"] }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <MultipleTextLabelWrapper
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <span className="visible-items">{visibleItems.join(", ")}</span>
        {sorted.length ? (
          <span className="rest-count">+{sorted.length}</span>
        ) : null}
      </MultipleTextLabelWrapper>
      {open && !!sorted.length && (
        <TooltipContent
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow
            ref={arrowRef}
            context={context}
            strokeWidth={0.5}
            stroke={theme.palette.foreground.highlight}
          />
          <p>{tooltip}</p>
        </TooltipContent>
      )}
    </>
  );
};

export { MultipleTextLabel };
