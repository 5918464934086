import { useEffect, useRef, useState } from "react";

import {
  useLoadFields,
  useLoadFormForEdit,
  useLoadGroups,
  useLoadSections,
} from "@smart/manage-gql-client-dom";

const TIMEOUT_SEC = 600;

export type FormLoadingStatus =
  | "checking"
  | "created"
  | "timedOut"
  | "fileEncrypted"
  | undefined;

export const useLoadFormWithLoadingStatus = ({
  formUri,
  shouldCheck,
}: {
  formUri: string;
  shouldCheck: boolean;
}) => {
  const statusRef = useRef<string | null>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [loadingStatus, setLoadingStatus] = useState<FormLoadingStatus>();

  const form = useLoadFormForEdit(
    { uri: formUri },
    {
      fetchPolicy: "cache-and-network",
      pollInterval: loadingStatus === "checking" ? 1000 : 0,
    },
  );
  const sections = useLoadSections({ formUri });
  const groups = useLoadGroups({ formUri });
  const fields = useLoadFields({ formUri });

  useEffect(() => {
    if (!form.result) return;

    if (
      form.result.creationStatus === "failedToGenerateQuestions-fileEncrypted"
    ) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setLoadingStatus("fileEncrypted");
      statusRef.current = form.result.creationStatus;
      return;
    }

    if (!form.result.creationStatus && loadingStatus === "checking") {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      sections.refetch().catch(console.error);
      groups.refetch().catch(console.error);
      fields.refetch().catch(console.error);

      setLoadingStatus("created");
    }

    if (form.result.creationStatus && !loadingStatus && shouldCheck) {
      setLoadingStatus("checking");

      if (!timeoutRef.current)
        timeoutRef.current = setTimeout(
          () => setLoadingStatus("timedOut"),
          TIMEOUT_SEC * 1000,
        );
    }

    if (
      form.result.creationStatus &&
      !statusRef.current &&
      loadingStatus === "created"
    ) {
      setLoadingStatus(undefined);
    }
    statusRef.current = form.result.creationStatus;
  }, [form.result?.creationStatus, loadingStatus]);

  return { loadingStatus, form, sections, groups, fields };
};
