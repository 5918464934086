import styled from "@emotion/styled";
import { useState } from "react";
import { FieldArrayWithId, UseFieldArrayReturn } from "react-hook-form";

import { DraggableItemProps } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { Icon } from "@smart/itops-icons-dom";
import { EditableText, Button } from "@smart/itops-ui-dom";

export const OptionContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  --background: ${(props) => props.theme.scheme.grey.r15};
  background: var(--background);
  border-radius: 0.5rem;
  gap: 1rem;
  margin: 0.4rem 0;
  padding: 0.4rem 0.8rem;

  .option-item-edit {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .option-drag-handle {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    .option-drag-handle {
      opacity: 1;
    }
  }

  &:not([draggable="true"]) {
    .option-drag-handle {
      visibility: hidden;
      pointer-events: none;
    }
  }
`;

export const OptionItemIndicator = styled.div<{ isRadio: boolean }>`
  transition: border-radius 0.5s ease;
  background: white;
  border: 1px solid ${(props) => props.theme.scheme.grey.r70};
  border-radius: ${(props) => (props.isRadio ? "1.8rem" : "0.4rem")};
  height: 1.8rem;
  width: 1.8rem;
`;

export type OptionsProps = {
  allowCustomResponse?: boolean | null | undefined;
  options: { label: string; value: string }[];
};

export type OptionsSharedProps = {
  type: "choice" | "checkbox";
  optionsFields: FieldArrayWithId<
    UpdateHookValues<OptionsProps>,
    "options",
    "id"
  >[];
  removeOption: (index?: number) => Promise<void>;
  updateOption: (index: number, label: string | undefined) => Promise<void>;
};

export type DraggableOptionItemProps = DraggableItemProps<
  UseFieldArrayReturn<OptionsProps, "options">["fields"][number]
> &
  OptionsSharedProps & { newOptionIndex: number | undefined };

export const DraggableOptionItem = ({
  index,
  draggableProvided,
  type,
  removeOption,
  updateOption,
  optionsFields,
  newOptionIndex,
}: DraggableOptionItemProps) => {
  const hasMultipleOptions = optionsFields.length > 1;

  const [editingTitle, setEditingTitle] = useState(
    optionsFields[index]?.label || undefined,
  );

  return (
    <OptionContainer
      data-testid={`option-item-${index}`}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      draggable={hasMultipleOptions}
    >
      <div
        {...draggableProvided?.dragHandleProps}
        className="option-drag-handle"
        data-testid="option-drag-handle"
      >
        <Icon
          library="lucide"
          name="GripVertical"
          className="option-drag-handle-icon"
        />
      </div>
      <OptionItemIndicator
        isRadio={type === "choice"}
        data-testid={`select-icon-${index}`}
      />
      <div className="option-item-edit">
        <EditableText
          text={editingTitle || ""}
          onChange={setEditingTitle}
          placeholder="Option"
          onComplete={() => updateOption(index, editingTitle)}
          backgroundOnHover={false}
          editing={newOptionIndex === index}
          fontSize="emphasis"
        />
      </div>
      {hasMultipleOptions && (
        <Button
          kind="borderless"
          icon={{ library: "lucide", name: "X" }}
          variant="plain"
          dataTestId={`delete-icon-option-${index}`}
          onClick={async () => {
            await removeOption(index);
          }}
        />
      )}
    </OptionContainer>
  );
};
