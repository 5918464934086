import { ReactNode } from "react";

import { ClickOutsideWrapper, Icon } from "@smart/itops-components-dom";

import { Close, Input, List, ListHolder, Selection, Tag } from "./components";
import { FieldWrapper } from "../field";

export type TagsProps<V> = {
  name: string;
  readOnly?: boolean;
  selected: V[];
  keys: (value: V) => string;
  render: (value: V) => string | ReactNode;
  onToggle: (v: V) => void;
  override?: (
    value: V,
  ) => { disabled?: boolean; selected?: boolean } | undefined;
};

export type TagsWrapperProps<V> = TagsProps<V> & {
  children: ReactNode;
  showOptions: boolean;
  setShowOptions: (show: boolean) => void;
};

export const TagsWrapper = <V extends any>({
  name,
  children,
  showOptions,
  setShowOptions,
  readOnly,
  selected,
  keys,
  render,
  onToggle,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override,
}: TagsWrapperProps<V>) => (
  <FieldWrapper
    data-testid={`tags-${name}`}
    onFocus={() => !readOnly && setShowOptions(true)}
  >
    <ClickOutsideWrapper onClickOutside={() => setShowOptions(false)}>
      <Selection>
        {selected.map((v) => (
          <Tag
            key={keys(v)}
            disabled={readOnly}
            data-testid="selected-tag"
            type="button"
            onKeyUp={(e) => {
              e.preventDefault();
              switch (e.key) {
                case "Backspace": {
                  (
                    (e.currentTarget.previousSibling ||
                      e.currentTarget.nextSibling) as HTMLButtonElement
                  )?.focus();
                  onToggle(v);
                  break;
                }
                case "Delete": {
                  (e.currentTarget.nextSibling as HTMLButtonElement)?.focus();
                  onToggle(v);
                  break;
                }
                case "ArrowLeft": {
                  (
                    e.currentTarget.previousSibling as HTMLButtonElement
                  )?.focus();
                  break;
                }
                case "ArrowRight": {
                  (e.currentTarget.nextSibling as HTMLButtonElement)?.focus();
                  break;
                }
                default: {
                  break;
                }
              }
            }}
          >
            {render(v)}
          </Tag>
        ))}
        <Input
          id={name}
          name={name}
          disabled={readOnly}
          value=""
          onChange={() => {}}
          onKeyUp={(e) => {
            e.preventDefault();
            if (selected.length) {
              switch (e.key) {
                case "Backspace": {
                  onToggle(selected[selected.length - 1]);
                  break;
                }
                case "ArrowLeft": {
                  (
                    e.currentTarget.previousSibling as HTMLButtonElement
                  )?.focus();
                  break;
                }
                default: {
                  break;
                }
              }
            }
          }}
        />
        <Icon
          name={showOptions ? "angleUp" : "angleDown"}
          size={12}
          onClick={() => !readOnly && setShowOptions(!showOptions)}
        />
      </Selection>
      {showOptions && (
        <ListHolder>
          <List className="tag-options">{children}</List>
          <Close type="button" onClick={() => setShowOptions(false)}>
            Close
          </Close>
        </ListHolder>
      )}
    </ClickOutsideWrapper>
  </FieldWrapper>
);
