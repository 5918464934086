import { v4 } from "@smart/itops-utils-basic";

export const optimisticOperation =
  <K extends string>(key: K) =>
  () =>
    ({
      [key]: {
        operationId: v4(),
        createdAt: new Date().toISOString(),
        __typename: "Operation",
      },
      __typename: "Mutation",
    }) as {
      [key in K]: {
        operationId: string;
        createdAt: string;
        __typename: "Operation";
      };
    } & { __typename: "Mutation" };
