import { z } from "zod";

import {
  formCategoryLabel,
  formCategorySchema,
  formCategoryValue,
} from "@smart/bridge-types-basic";
import {
  updateFormFactory,
  Input,
  Label,
  RadioField,
  CenteredForm,
} from "@smart/itops-components-dom";
import { FormEditor } from "@smart/itops-editor-dom";
import { extractId } from "@smart/itops-types-basic";
import { useUpdateForm } from "@smart/manage-gql-client-dom";

const formSchema = z.object({
  title: z.string().min(1, "Required"),
  response: z.string(),
  type: z.string().optional(),
  category: formCategorySchema,
});

const EditForm = updateFormFactory({
  factoryProps: {
    next: ({ uri }) => `/forms/${extractId(uri)}`,
    mutation: useUpdateForm,
    schema: formSchema,
    converter: (input) => ({
      ...input,
      state: undefined,
    }),
  },
  form: CenteredForm,
  render: ({
    mode,
    form: {
      control,
      register,
      formState: { errors },
    },
    result: { loading },
  }) => {
    const disabled = mode === "view";

    return (
      <>
        <Label
          name="title"
          text="Title"
          error={errors.title?.message}
          loading={loading}
        >
          <Input {...register("title")} disabled={disabled} />
        </Label>
        <Label
          name="category"
          text="Type of Matter Type"
          error={errors.category?.message}
        >
          <RadioField
            control={control}
            name="category"
            options={formCategoryValue.map((value) => ({
              value,
              label: formCategoryLabel[value],
            }))}
            disabled={disabled}
          />
        </Label>
        <Label name="response" text="Submission Response">
          <FormEditor control={control} name="response" disabled={disabled} />
        </Label>
      </>
    );
  },
});

export { EditForm };
