import styled from "@emotion/styled";

import { ContextMenu, Icon } from "@smart/itops-components-dom";
import { Button } from "@smart/itops-smokeball-components-dom";
import { buildDisplayName } from "@smart/itops-utils-basic";
import { MatterSubmission, submissionLink } from "@smart/manage-gql-client-dom";

import {
  formatEventDate,
  getHistoryEventType,
  GqlTracker,
  historyEventTypeLabel,
} from "./helpers";

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;

  .view-icon {
    color: ${(props) => props.theme.palette.primary.base};
  }

  .view-title {
    flex: 1;
    width: 0;
    margin: 0 1rem;

    h2 {
      font-weight: 300;
      font-size: ${(props) => props.theme.fontSize.heading};
      line-height: ${(props) => props.theme.fontSize.heading};
      margin: 0 0 0.4rem 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p.event {
      color: ${(props) => props.theme.palette.foreground.accent};
      font-size: ${(props) => props.theme.fontSize.base};
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export type SubmissionViewToolbarProps = {
  submission: MatterSubmission;
  showCopy?: boolean;
  onHistory: () => void;
  onCopyLink: (link: string) => void;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
  onSync: (submission: MatterSubmission) => void;
};

const latestEvent = (
  tracker: GqlTracker | null | undefined,
  {
    createdAt,
    createdBy,
  }: Pick<
    MatterSubmission["rawSubmission"]["values"],
    "createdAt" | "createdBy"
  >,
) => {
  const eventType = tracker ? getHistoryEventType(tracker) : "created";
  const eventCreator = tracker ? tracker.user : createdBy;
  const eventTime = tracker ? tracker.sentAt : createdAt;

  return [
    eventType && historyEventTypeLabel[eventType],
    eventCreator && `by ${buildDisplayName(eventCreator, "")}`,
    formatEventDate(eventTime, { preposition: "on" }),
  ]
    .filter(Boolean)
    .join(" ");
};

export const SubmissionViewToolbar = ({
  submission,
  showCopy,
  onSync,
  onHistory,
  onCopyLink,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
}: SubmissionViewToolbarProps) => {
  const isInternalUse =
    submission.status === "draft" &&
    submission.rawSubmission.values.communicationMethod === "internalUse";
  const isActionable = submission.status === "active" || isInternalUse;

  return (
    <ToolbarWrapper>
      <Icon className="view-icon" name="form" size={32} />
      <div className="view-title">
        <h2>{submission.formTitle}</h2>
        <p className="event" data-testid="latest-event">
          {latestEvent(
            submission.trackers?.[0],
            submission.rawSubmission.values,
          )}
        </p>
      </div>
      <Button
        palette="primary"
        text="Save Responses"
        disabled={
          submission.syncStatus !== "notSynced" ||
          submission.status === "completed"
        }
        onClick={() => onSync(submission)}
      />
      <ContextMenu
        id="view-submission"
        items={[
          { label: "Form History", value: "history", onClick: onHistory },
          {
            label: "Copy Link",
            value: "copy",
            onClick: () => {
              const link = submissionLink(submission.rawSubmission);
              if (link) onCopyLink(link);
            },
            hidden: !isActionable || !showCopy,
          },
          {
            label: isInternalUse ? "Send" : "Send Reminder",
            value: "remind",
            onClick: () => onRemind(submission),
            hidden: !isActionable,
          },
          {
            label: "Edit Responses",
            value: "edit",
            onClick: () => onEdit(submission),
            hidden: !isActionable,
          },
          {
            label: "Close Form",
            value: "close",
            onClick: () => onCancel(submission),
            hidden: !isActionable,
          },
          {
            label: "Delete Form",
            value: "delete",
            onClick: () => onDelete(submission),
            hidden: !isActionable,
          },
        ]}
      />
    </ToolbarWrapper>
  );
};
