import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { FieldNS, GroupNS, SectionNS } from "@smart/bridge-types-basic";

import {
  GqlFieldValues,
  GqlGroupValues,
  GqlSectionValues,
  ItemType,
} from "../../../types";

export const getItemType = (uri?: string): ItemType | undefined => {
  if (!uri) return undefined;
  if (SectionNS.isUriMatch(uri)) return "Section";
  if (GroupNS.isUriMatch(uri)) return "Group";
  if (FieldNS.isUriMatch(uri)) return "Field";

  return undefined;
};

export type ConditionItem = GqlFieldValues | GqlGroupValues | GqlSectionValues;

export const isConditionSection = (
  item: ConditionItem | null,
): item is GqlSectionValues => !!item && SectionNS.isUriMatch(item.uri);

export const isConditionGroup = (
  item: ConditionItem | null,
): item is GqlGroupValues => !!item && GroupNS.isUriMatch(item.uri);

export const isConditionField = (
  item: ConditionItem | null,
): item is GqlFieldValues => !!item && FieldNS.isUriMatch(item.uri);

export const getItemLabel = (item: ConditionItem | null) => {
  if (!item) return "";
  if (isConditionSection(item)) return item.title || fieldFallbackLabel.section;
  if (isConditionGroup(item)) return item.label || fieldFallbackLabel.group;

  return item.label || fieldFallbackLabel[item.type];
};
