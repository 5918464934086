/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterFieldsQueryVariables = Types.Exact<{
  matterLayoutId: Types.Scalars["String"]["input"];
  providerId: Types.Scalars["String"]["input"];
  teamUri: Types.Scalars["ID"]["input"];
  prefix?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  preserved?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  matterTypeIds?: Types.InputMaybe<
    Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"]
  >;
}>;

export type GqlMatterFieldsQuery = {
  __typename: "Query";
  matterFields: Array<{
    __typename: "MatterField";
    name: string;
    type: string;
    possibleValues?: Array<string> | null;
    displayName?: string | null;
    details?: string | null;
    allowedRepeatable?: boolean | null;
    hint?: string | null;
  }>;
};

export const MatterFieldsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "matterFields" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterLayoutId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "providerId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teamUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prefix" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preserved" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterTypeIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "matterFields" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterLayoutId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterLayoutId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "providerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "providerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "teamUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teamUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prefix" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prefix" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "preserved" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "preserved" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterTypeIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterTypeIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "matterFieldFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "matterFieldFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatterField" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "possibleValues" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "details" } },
          { kind: "Field", name: { kind: "Name", value: "allowedRepeatable" } },
          { kind: "Field", name: { kind: "Name", value: "hint" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlMatterFieldsQuery,
  GqlMatterFieldsQueryVariables
>;
