import { queryDocuments } from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useLoadStaffDetails = useQueryFactory(
  queryDocuments.staffDetails,
  {
    map: ({ staffDetails }) => staffDetails,
  },
);
