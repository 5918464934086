import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowSelectionState,
  OnChangeFn,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
import clsx from "clsx";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import {
  getCellWrapperClassNames,
  getDataCellClassNames,
  getDataRowClassNames,
  getHeaderCellClassNames,
  tableStyles,
} from "./class-names";
import { CellSkeleton } from "./table-part";
import { Icon } from "../icon";
import { ProgressBar } from "../progress-bar";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    action?: boolean;
  }
}

type TableState<S> = { state: S; onChange: OnChangeFn<S> };

export type TableProps<I extends {}> = {
  data: I[];
  columns: ColumnDef<I, any>[];
  updating?: boolean;
  loadingRows?: number;
  getRowId?: (row: I) => string;
  select: TableState<RowSelectionState> | undefined;
  sort: TableState<SortingState> | undefined;
  globalFilter: (TableState<string> & { fn: FilterFn<I> }) | undefined;
  empty?: ReactNode;
  applyColumnSize?: boolean;
  disabled?: boolean;
  standalone?: boolean;
};

export const Table = <I extends {}>({
  data,
  columns,
  updating,
  loadingRows,
  getRowId,
  select,
  sort,
  globalFilter,
  empty,
  applyColumnSize,
  disabled,
  standalone,
}: TableProps<I>) => {
  const table = useReactTable({
    state: {
      rowSelection: select?.state,
      sorting: sort?.state,
      globalFilter: globalFilter?.state,
    },
    data,
    columns,
    enableRowSelection: !!select,
    onRowSelectionChange: select?.onChange,
    enableSorting: !!sort,
    onSortingChange: sort?.onChange,
    globalFilterFn: globalFilter?.fn,
    onGlobalFilterChange: select?.onChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
  });

  const sortingIcon = (isSorted: "asc" | "desc" | "none") => {
    if (isSorted === "none") return <span className="w-4" />;
    return (
      <Icon
        className="size-4"
        name={isSorted === "asc" ? "regularArrowUp" : "regularArrowDown"}
      />
    );
  };

  const noop = () => {};

  return (
    <div
      className={clsx(
        "overflow-auto w-full border-neutral-100 border-y rounded-b",
        standalone && "border",
        standalone && "rounded",
      )}
    >
      <table className={tableStyles}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  id={header.column.id}
                  title={header.column.columnDef.header?.toString()}
                  onClick={
                    disabled ? noop : header.column.getToggleSortingHandler()
                  }
                  className={twMerge(
                    getHeaderCellClassNames({
                      sortable: header.column.getCanSort(),
                      disabled,
                    }),
                  )}
                  aria-sort={
                    (
                      {
                        asc: "ascending",
                        desc: "descending",
                        none: undefined,
                      } as const
                    )[header.column.getIsSorted() || "none"]
                  }
                >
                  <span className="flex items-center w-full">
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap mr-4">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </span>
                    {header.column.getCanSort() &&
                      sortingIcon(header.column.getIsSorted() || "none")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {updating && (
            <tr className="relative">
              <td className="p-0 border-b-0">
                <ProgressBar overlay className="h-[0.2rem]" />
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              aria-selected={row.getIsSelected()}
              className={twMerge(
                getDataRowClassNames({ selected: row.getIsSelected() }),
              )}
            >
              {row.getAllCells().map((cell, index) => (
                <td
                  key={cell.id}
                  aria-describedby={cell.column.id}
                  className={twMerge(
                    getDataCellClassNames({
                      action: cell.column.columnDef.meta?.action,
                      selectable: cell.row.getCanSelect(),
                      minWidth: applyColumnSize
                        ? cell.column.getSize()
                        : undefined,
                      width: applyColumnSize
                        ? cell.column.getSize()
                        : undefined,
                      disabled,
                    }),
                  )}
                  onClick={
                    cell.column.columnDef.meta?.action
                      ? undefined
                      : row.getToggleSelectedHandler()
                  }
                >
                  <div
                    className={getCellWrapperClassNames({
                      selected: row.getIsSelected() && index === 0,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
          {loadingRows &&
            Array.from({ length: loadingRows }, (_, i) => (
              <tr key={`loading-${i}`}>
                {table.getAllLeafColumns().map((column) => (
                  <td
                    key={column.id}
                    className={getDataCellClassNames({
                      maxWidth: column.getSize(),
                      minWidth: column.getSize(),
                    })}
                  >
                    <div className={getCellWrapperClassNames({})}>
                      <div className="h-6">
                        <CellSkeleton />
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          {!loadingRows && !!empty && table.getRowCount() === 0 && (
            <tr>
              <td colSpan={100} className={getDataCellClassNames({})}>
                <span className="flex flex-col flex-nowrap items-center justify-center text-center px-6 py-4">
                  {empty}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
