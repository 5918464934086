import { useMutation } from "@apollo/client";

import { mutationDocuments } from "@smart/manage-gql-operations-dom";

export const useGenerateForm = () =>
  useMutation(mutationDocuments.generateForm);

export const useUpdateAIUserFeedback = () =>
  useMutation(mutationDocuments.updateAiUserFeedback, {
    update: (cache, { data }) => {
      if (data?.updateAiUserFeedback.values) {
        const { formUri, updatedAt, status } = data.updateAiUserFeedback.values;

        let creationStatus: string | undefined;
        switch (status) {
          case "generating": {
            creationStatus = "generatingQuestions";
            break;
          }
          case "completed": {
            creationStatus = "savingQuestions";
            break;
          }
          default: {
            creationStatus = undefined;
          }
        }

        if (creationStatus) {
          cache.modify({
            id: cache.identify({ __typename: "Form", uri: formUri }),
            fields: {
              values: (existing) => ({
                ...existing,
                creationStatus,
                updatedAt,
              }),
            },
          });
        }
      }
    },
  });
