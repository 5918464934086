import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { FragmentProvider, HistoryProvider } from "@smart/itops-hooks-dom";

import { RouteSelector } from "./routes";

export const ManageApp = () => (
  <HelmetProvider>
    <BrowserRouter>
      <HistoryProvider>
        <FragmentProvider>
          <RouteSelector />
        </FragmentProvider>
      </HistoryProvider>
    </BrowserRouter>
  </HelmetProvider>
);
