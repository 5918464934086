import styled from "@emotion/styled";
import {
  ColumnDef,
  ColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortDirection,
  SortingState,
  useReactTable,
  createColumnHelper,
  RowData,
  RowSelectionState,
} from "@tanstack/react-table";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { Empty, Spinner } from "@smart/itops-components-dom";
import { useDebounce } from "@smart/itops-hooks-dom";
import { Icon, IconName } from "@smart/itops-icons-dom";
import { specialChars } from "@smart/itops-utils-basic";

export type CellProps<I extends {}> = { row: { original: I } };

export const useColumns = <I extends {}>(
  fn: (helper: ColumnHelper<I>) => ColumnDef<I, any>[],
) =>
  useMemo(() => {
    const helper = createColumnHelper<I>();
    return fn(helper);
  }, []);

const TableWrapper = styled.div<{
  hasVariableWidth: boolean;
  small?: boolean;
  columnResizing?: boolean;
}>`
  border-spacing: 0;
  font-size: ${(props) =>
    props.small ? props.theme.fontSize.small : props.theme.fontSize.base};
  ${(props) => (props.hasVariableWidth ? "min-width: 100%;" : "")}
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  .thead .tr {
    background: ${(props) => props.theme.palette.background.accent};
    border-color: ${(props) => props.theme.palette.background.highlight};
    border-style: solid;
    border-width: 1px;
  }

  .thead,
  .tbody {
    ${(props) => (props.hasVariableWidth ? "min-width: 100%;" : "")}
    width: 100%;
  }

  .tr {
    min-width: 100%;
    display: flex;
    align-items: center;

    border-color: ${(props) => props.theme.palette.background.highlight};
    border-style: solid;
    border-width: 0 1px;
  }

  .th,
  .td {
    flex-shrink: 0;
  }

  .th {
    border-right: 1px solid
      ${(props) => props.theme.palette.background.highlight};
    position: relative;

    .title {
      padding: 0.8rem 1.4rem;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      p {
        font-size: inherit;
        font-weight: bold;
        flex: 1 1 50%;
        margin: 0;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .sorted {
        opacity: 0.5;
        margin-left: 0.5rem;
      }
    }

    &.sortable {
      cursor: pointer;
    }

    &.action {
      border-right: 0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 0 ${(props) => props.theme.palette.primary.highlight}
        inset;
    }

    .resizer {
      position: absolute;
      top: 0;
      right: -4px;
      height: 100%;
      width: 7px;
      margin: 0;
      padding: 0;
      display: ${(props) => (props.columnResizing ? "block" : "none")};

      cursor: ew-resize;
      background: transparent;
      border: 0;
    }
  }

  .tbody .tr {
    border-bottom: 1px solid ${(props) => props.theme.palette.background.accent};

    &:hover,
    &:focus {
      outline: none;
      background: ${(props) => props.theme.palette.secondary.highlight};
    }

    &.selected {
      background: ${(props) => props.theme.palette.secondary.accent};
    }
  }

  .td {
    padding: 0.8rem 1.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;

    &.action {
      padding: 0 1.4rem;

      .context-menu-icon {
        margin: 0.4rem;
        padding: 0.6rem;
      }
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    action?: boolean;
    variableWidth?: boolean;
    showTooltip?: boolean;
  }
}

const SortIcon = ({ sorted }: { sorted: false | SortDirection }) => {
  if (!sorted) return null;
  return (
    <Icon
      size={8}
      name="triangleDown"
      className="sorted"
      rotate={sorted === "asc" ? 180 : 0}
    />
  );
};

type TableProps<I extends {}> = {
  data: I[];
  columns: ColumnDef<I>[];
  initialState?: {
    sorting?: { id: keyof I & string; desc: boolean }[];
    setInitialColumnSort?: React.Dispatch<
      React.SetStateAction<
        { id: keyof I & string; desc: boolean }[] | undefined
      >
    >;
    setInitialColumnSize?: React.Dispatch<
      React.SetStateAction<{ id: keyof I & string; size: number }[] | undefined>
    >;
  };
  onSelect?: (item: I) => void;
  small?: boolean;
  columnResizing?: boolean;
};

const Table = <I extends {}>({
  data,
  columns,
  initialState,
  onSelect,
  small,
  columnResizing = true,
}: TableProps<I>) => {
  const [rowSelection, onRowSelectionChange] = useState<RowSelectionState>({});
  const [sorting, onSortingChange] = useState<SortingState>(
    initialState?.sorting ? initialState.sorting : [],
  );
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    onSortingChange,
    onRowSelectionChange,
    enableMultiRowSelection: false,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    enableColumnResizing: columnResizing,
  });

  const hasVariableWidth = !!columns.find((c) => c.meta?.variableWidth);

  const getColumnSize = () =>
    table.getHeaderGroups().flatMap((headerGroup) =>
      headerGroup.headers.map((header) => ({
        id: header.id as keyof I & string,
        size: header.getSize(),
      })),
    );

  const getColumnSort = () =>
    table.getHeaderGroups().flatMap((headerGroup) =>
      headerGroup.headers
        .filter((header) => header.column.getIsSorted() !== false)
        .map((header) => ({
          id: header.id as keyof I & string,
          desc: header.column.getIsSorted() === "desc",
        })),
    );

  const onSaveColumnSize = useDebounce(() => {
    if (initialState?.setInitialColumnSize) {
      initialState.setInitialColumnSize(getColumnSize());
    }
  }, 500);

  useEffect(() => {
    if (initialState?.setInitialColumnSort) {
      initialState.setInitialColumnSort(getColumnSort());
    }
  }, [sorting]);

  useEffect(() => {
    const isResizing = table.getAllColumns().some((col) => col.getIsResizing());
    if (isResizing) {
      onSaveColumnSize();
    }
  });

  return (
    <TableWrapper
      hasVariableWidth={hasVariableWidth}
      columnResizing={columnResizing}
      small={small}
      role="table"
    >
      <div className="thead" role="rowgroup">
        {table.getHeaderGroups().map((headerGroup) => (
          <div className="tr" key={headerGroup.id} role="row">
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className={`th ${
                  header.column.getCanSort() ? "sortable" : ""
                } ${header.column.columnDef.meta?.action ? "action" : ""}`}
                style={{
                  flex: header.column.columnDef.meta?.variableWidth ? 1 : 0,
                  minWidth: header.column.getSize(),
                }}
                onClick={(e) => {
                  header.column.toggleSorting();
                  e.currentTarget.blur();
                }}
                onKeyUp={(e) =>
                  e.code === "Space" && header.column.toggleSorting()
                }
                tabIndex={header.column.getCanSort() ? 0 : undefined}
                role="columnheader"
              >
                {header.isPlaceholder ? null : (
                  <div className="title" data-testid={header.id}>
                    <p>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      ) || specialChars.nbsp}
                    </p>
                    {header.column.getCanSort() && (
                      <SortIcon sorted={header.column.getIsSorted()} />
                    )}
                    <button
                      className="resizer"
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      aria-label="Resize"
                      type="button"
                      tabIndex={-1}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody" role="rowgroup">
        {table.getRowModel().rows.map((row) => (
          <div
            key={row.id}
            className={`tr ${row.getIsSelected() ? "selected" : ""}`}
            onClick={() => onSelect?.(row.original)}
            onMouseDown={(e) => {
              if (onSelect && e.detail > 1) {
                e.preventDefault();
              }
            }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                onSelect?.(row.original);
              }
            }}
            tabIndex={0}
            role="row"
          >
            {row.getVisibleCells().map((cell, index) => {
              const { header } =
                table.getHeaderGroups()[0].headers[index].column.columnDef;
              return (
                <div
                  data-testid={typeof header === "string" ? header : ""}
                  key={cell.id}
                  style={{
                    flex: cell.column.columnDef.meta?.variableWidth ? 1 : 0,
                    minWidth: cell.column.getSize(),
                    width: cell.column.getSize(),
                  }}
                  className={`td ${
                    cell.column.columnDef.meta?.action ? "action" : ""
                  }`}
                  role="cell"
                  title={
                    cell.column.columnDef.meta?.showTooltip
                      ? (cell.getValue() as string)
                      : ""
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </TableWrapper>
  );
};

type ListProps<I extends {}> = Omit<TableProps<I>, "data"> & {
  data: I[] | undefined;
  loading?: boolean;
  empty?: { icon: IconName; title: string; content?: ReactNode };
  sortingColumns?: {
    id: keyof I & string;
    desc: boolean;
  }[];
  columnResizing?: boolean;
};

const List = <I extends {}>({
  columns,
  initialState,
  onSelect,
  data,
  loading,
  empty,
  sortingColumns,
  small,
  columnResizing,
}: ListProps<I>) => {
  if (!data) return null;

  let sorting: { id: keyof I & string; desc: boolean }[] =
    initialState?.sorting || [];
  // Only the column with heterogeneous values will be sorted
  if (sortingColumns && data.length > 1) {
    for (const col of sortingColumns) {
      if (data.some((row) => row[col.id] !== data[0][col.id])) {
        sorting = [col];
        break;
      }
    }
  }

  return (
    <>
      <Table
        columns={columns}
        initialState={{ ...initialState, sorting }}
        onSelect={onSelect}
        data={data}
        small={small}
        columnResizing={columnResizing}
      />
      {loading && (
        <LoadingWrapper>
          <Spinner size={4} />
        </LoadingWrapper>
      )}
      {!data.length && !loading && empty ? (
        <Empty>
          <Icon className="empty-icon" name={empty.icon} size={30} />
          <p className="empty-text">{empty.title}</p>
          {empty.content}
        </Empty>
      ) : null}
    </>
  );
};

export { ListProps, List };
