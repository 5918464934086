import { Icon } from "@smart/itops-sb-design-system-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

export const ActingAsLabel = () => {
  const { actingTeam } = useManageFormsContext();
  return (
    actingTeam?.name && (
      <div className="flex items-center gap-4 mr-4 text-orange-500 text-ui">
        <Icon name="regularCircleExclamation" className="w-8 h-8" />
        <span data-testid="acting-as-firm">Acting as {actingTeam.name}</span>
      </div>
    )
  );
};
