import { ReactNode } from "react";

import { useManageForms } from "./forms";
import { ManageFormsProvider } from "./forms-context";

type ActAsProviderProps = {
  children: ReactNode;
};

export const ActAsProvider = ({ children }: ActAsProviderProps) => {
  const { context } = useManageForms();

  return <ManageFormsProvider value={context}>{children}</ManageFormsProvider>;
};
