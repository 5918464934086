import { useLazyQuery } from "@apollo/client";

import { TField, TGroup, TSection } from "@smart/bridge-resources-basic";
import {
  createFilterClientSolicitor,
  createFilterHiddenFields,
  defaultClientRoleId,
  groupResponse,
  lookupMatchingContacts,
  MatchingContact,
  SBContact,
  SBContactType,
} from "@smart/bridge-smokeball-basic";
import {
  ResponseSyncStatus,
  SubmissionSyncStatus,
} from "@smart/bridge-types-basic";
import { loadDefaultCountry } from "@smart/itops-locale-dom";
import { Matter, Roles, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import {
  delay,
  entriesOf,
  fromEntries,
  jsonParse,
  logError,
  resolveSettled,
  timeout,
} from "@smart/itops-utils-basic";
import {
  useUpdateSubmission,
  useSyncSubmission,
  MatterSubmission,
  useLazyLoadContacts,
  useUpdateResponseSyncStatus,
  useQueryMatterFields,
} from "@smart/manage-gql-client-dom";
import { Gql, queryDocuments } from "@smart/manage-gql-operations-dom";

import { useUser } from "./user";

export type SyncOptions = {
  submission: MatterSubmission;
  fields: Gql.FieldFieldsFragment[];
  groups: Gql.GroupFieldsFragment[];
  sections: Gql.SectionFieldsFragment[];
  formMatterTypeRepresentatives?: string[];
  shouldReviewConflict?: boolean;
};

type SyncResult = "completed" | "backendSynced" | "pendingContactReview";

type ProcessResponsesOptions = SyncOptions & {
  matter: Matter | undefined;
  filterResponses?: (response: {
    field: TField;
    group?: TGroup;
    value: any;
    updatedAt: number;
    uri: string;
  }) => boolean;
  getLayoutFields: ({
    layoutId,
    providerId,
  }: {
    layoutId: string;
    providerId: string;
  }) => Promise<NonNullable<TField["field"]>[]>;
};

export const processResponses = ({
  matter,
  groups,
  fields,
  sections,
  submission,
  formMatterTypeRepresentatives,
  filterResponses = () => true,
  getLayoutFields,
}: ProcessResponsesOptions) => {
  const notToSyncStatus = [
    "synced",
    "loaded",
    "notToSync",
  ] as ResponseSyncStatus[];
  const responses = fromEntries(
    submission.responses
      .filter((r) => !r.syncStatus || !notToSyncStatus.includes(r.syncStatus))
      .map((r) => [
        r.fieldUri,
        {
          uri: r.uri,
          value: jsonParse(r.value, "response value"),
          existingItemIds: r.existingItemIds,
          syncStatus: r.syncStatus,
        },
      ]),
  );
  const backendResponseUpdatedTime =
    submission.responses.reduce<Record<string, number>>(
      (aggr, r) => ({
        ...aggr,
        [r.fieldUri]: new Date(r.updatedAt).getTime(),
      }),
      {},
    ) || {};

  const groupsMap = fromEntries(groups.map((g) => [g.uri, g.values]));
  const fieldsMap = fromEntries(fields.map((f) => [f.uri, f.values]));

  const filterHiddenFields = createFilterHiddenFields({
    sections,
    groups,
    fields: fields.map((f) => f.values),
    responses,
  });
  const fieldResponses = fields
    .map((field) => ({
      field: field.values as TField,
      group: fieldsMap[field.uri].groupUri
        ? (groupsMap[fieldsMap[field.uri].groupUri!] as TGroup)
        : undefined,
      value: responses[field.uri]?.value,
      updatedAt: backendResponseUpdatedTime[field.uri] || 0,
      uri: responses[field.uri]?.uri,
      existingItemIds: responses[field.uri]?.existingItemIds || undefined,
      syncStatus: responses[field.uri]?.syncStatus || undefined,
    }))
    /**
     * undefined response value indicates that the field hasn't been anwsered before
     * while null indicates that the answer is removed.
     */
    .filter((r) => r.value !== undefined && !!r.uri)
    .filter(filterHiddenFields)
    .filter(filterResponses);
  const isFormCategoryDifferent =
    !!matter &&
    matter.isLead !==
      (submission.rawSubmission.form.values.category === "lead");

  return groupResponse({
    sections: sections.map((s) => s.values as TSection),
    fieldResponses,
    country: loadDefaultCountry(),
    isFormCategoryDifferent,
    formMatterTypeRepresentatives,
    getLayoutFields,
  });
};

export const useSyncToMatter = () => {
  const { user } = useUser();
  const { matters, layouts, roles, contacts, relationships } =
    useSmokeballApp();

  const searchContacts = useLazyLoadContacts();
  const [loadSubmission] = useLazyQuery(queryDocuments.submission, {
    fetchPolicy: "network-only",
  });
  const [backendSync] = useSyncSubmission();
  const [updateSubmission] = useUpdateSubmission({ user });
  const updateSyncStatus = (
    { uri, formUri }: { uri: string; formUri: string },
    syncStatus: SubmissionSyncStatus,
  ) =>
    updateSubmission({
      variables: {
        uri,
        formUri,
        fields: {
          syncStatus,
        },
      },
    });
  const [updateResponsSyncStatus] = useUpdateResponseSyncStatus();
  const loadMatterFields = useQueryMatterFields();

  const setResponsesSyncStatus = async (
    uriSet: Set<string> | undefined,
    action: string,
    syncStatus: "synced" | "notToSync",
  ) => {
    if (!uriSet) return;

    try {
      await Promise.allSettled(
        Array.from(uriSet).map((uri) =>
          updateResponsSyncStatus({
            variables: {
              uri,
              syncStatus,
            },
          }),
        ),
      );
    } catch (error) {
      console.error(`Error set responses as "synced" for ${action}.`, error);
    }
  };

  const waitForStatus = async (uri: string) => {
    let complete = false;
    let syncStatus: Gql.SubmissionFieldsFragment["values"]["syncStatus"];

    while (!complete) {
      await delay(1000);
      const result = await loadSubmission({ variables: { uri } });
      syncStatus = result.data?.submission?.values.syncStatus;

      if (syncStatus === "synced" || syncStatus === "failedToSync")
        complete = true;
    }

    if (syncStatus === "failedToSync") throw new Error("API failed to sync");

    return syncStatus;
  };

  return {
    sync: async ({
      groups,
      fields,
      sections,
      submission,
      formMatterTypeRepresentatives,
      shouldReviewConflict,
    }: SyncOptions): Promise<SyncResult | undefined> => {
      await updateSyncStatus(submission, "syncing");

      try {
        const partialMatchingContacts: MatchingContact[] = [];

        const existingRoles = await roles?.get();
        const existingClientRoleName = existingRoles?.roles?.find(
          (r) => r.isClient,
        )?.name;

        const grouped = await processResponses({
          matter: matters?.current,
          groups,
          fields,
          sections,
          submission,
          formMatterTypeRepresentatives,
          filterResponses: createFilterClientSolicitor(existingClientRoleName),
          getLayoutFields: async ({ layoutId, providerId }) => {
            const matterFields = await loadMatterFields({
              matterLayoutId: layoutId,
              providerId,
              teamUri: user?.teamUri || "",
              preserved: true,
            });

            return matterFields.map((f) => ({
              ...f,
              possibleValues: f.possibleValues || undefined,
            }));
          },
        });

        const performBackendSync = async () => {
          await backendSync({ variables: { uri: submission.uri } });
          await Promise.race([
            waitForStatus(submission.uri),
            timeout(10 * 60000, "API syncing timed out"),
          ]);
        };

        /**
         * LayoutContact fields won't be synced back to SB via the SDK
         * as it has yet to support Contact Layout APIs.
         */
        const hasReviewedLayoutContacts = entriesOf(
          grouped.layoutContacts,
        ).some(([, inputContacts]) => inputContacts.some((c) => c.isReviewed));
        const hasReviewedCompanyContactsWithRelation = [
          ...Object.values(grouped.roles),
          ...Object.values(grouped.relationships).flatMap(
            (roleContactResponses) => Object.values(roleContactResponses),
          ),
        ].some(
          (contactResponse) =>
            contactResponse.hasRelation && contactResponse.isReviewed,
        );
        if (
          hasReviewedLayoutContacts ||
          hasReviewedCompanyContactsWithRelation
        ) {
          console.warn(
            "Reviewed layout contact or company contact with relation will be handled by backend",
          );
          await performBackendSync();
          return "backendSynced";
        }

        const updateOrCreateContact = async ({
          existingContactId,
          existingRoleContactId,
          contactInput,
        }: {
          existingContactId: string | undefined;
          existingRoleContactId: string | undefined;
          contactInput: Record<SBContactType, Record<string, any>>;
        }) => {
          if (existingRoleContactId)
            return contacts?.update({
              id: existingRoleContactId,
              ...contactInput,
            });

          if (existingContactId) return { id: existingContactId };

          const matchingContacts = await lookupMatchingContacts({
            input: contactInput,
            search: async ({ names, emails, phones }) => {
              const searchResult = await searchContacts({
                names,
                emails,
                phones,
              });

              return (searchResult as SBContact[]) || [];
            },
          });

          const exactMatch = matchingContacts.find(
            (mc) => mc.matchType === "exact",
          );
          if (exactMatch) return exactMatch;

          partialMatchingContacts.push(
            ...matchingContacts.filter((mc) => mc.matchType === "partial"),
          );
          return shouldReviewConflict && partialMatchingContacts.length
            ? undefined
            : contacts?.create(contactInput);
        };

        const updateLayouts = entriesOf(grouped.layouts).map(
          async ([layoutId, items]) => {
            await layouts?.update({
              layoutId,
              values: entriesOf(items).map(([key, value]) => ({ key, value })),
            });
            await setResponsesSyncStatus(
              grouped.responseUris.layouts[layoutId],
              `syncing for layout=${layoutId}`,
              "synced",
            );
          },
        );

        const updateRoles = entriesOf(grouped.roles).map(
          async ([responseRoleName, items]) => {
            const roleName =
              responseRoleName === defaultClientRoleId
                ? existingClientRoleName
                : responseRoleName;

            const foundRoles = existingRoles?.roles?.filter(
              (r) => r.name === roleName,
            );
            const recipientContactId = submission.recipient?.contactId;
            const isClientRole = roleName === existingClientRoleName;
            const isHandlingMultiClients =
              isClientRole &&
              !items.group?.length &&
              recipientContactId &&
              foundRoles &&
              foundRoles.length > 1;
            const contactItems = items.group?.length ? items.group : [items];

            const result = await Promise.all(
              contactItems.map(async (contactItem, index) => {
                const found =
                  foundRoles && isHandlingMultiClients
                    ? foundRoles.find((r) => r.contactId === recipientContactId)
                    : foundRoles?.[index];
                const contact = await updateOrCreateContact({
                  existingContactId: items.existingContactIds?.[index],
                  existingRoleContactId: found?.contactId,
                  contactInput: contactItem,
                });
                if (contact) {
                  if (!found && roleName) {
                    await roles?.add({
                      name: roleName,
                      contactId: contact.id,
                    });
                  } else if (
                    found &&
                    (!found.contactId || items.existingContactIds?.[index])
                  ) {
                    await roles?.update({
                      id: found.id,
                      contactId: contact.id,
                    });
                  }
                }

                return contact;
              }),
            );
            if (result.every(Boolean)) {
              await setResponsesSyncStatus(
                grouped.responseUris.roles[responseRoleName],
                `syncing for role=${responseRoleName}`,
                items.existingContactIds?.filter(Boolean).length
                  ? "notToSync"
                  : "synced",
              );
            }
          },
        );

        const updateRelationships = (allExistingRoles: Roles | undefined) =>
          entriesOf(grouped.relationships).map(
            async ([roleName, relationshipItems]) => {
              const existingRole = allExistingRoles?.roles.find(
                (r) => r.name === roleName,
              );
              /**
               * The current SDK doesn't support creating a role without contact.
               * So we just process existing roles at the moment.
               */
              if (!existingRole) return;

              await Promise.all(
                entriesOf(relationshipItems).map(
                  async ([relationshipId, items]) => {
                    const contactItems = items.group?.length
                      ? items.group
                      : [items];

                    await Promise.all(
                      contactItems.map(async (contactItem, index) => {
                        const existingRelationships = await relationships?.get(
                          existingRole.id,
                        );
                        const matchedExistingRelationships =
                          existingRelationships?.relationships.filter(
                            (r) => r.name === relationshipId,
                          );
                        const contact = await updateOrCreateContact({
                          existingContactId: items.existingContactIds?.[index],
                          existingRoleContactId:
                            matchedExistingRelationships?.[index]?.contactId,
                          contactInput: contactItem,
                        });
                        if (contact) {
                          await (matchedExistingRelationships?.[index]
                            ? relationships?.update({
                                id: matchedExistingRelationships?.[index].id,
                                roleId: roleName,
                                contactId: contact.id,
                              })
                            : relationships?.add({
                                roleId: roleName,
                                name: relationshipId,
                                contactId: contact.id,
                              }));

                          await setResponsesSyncStatus(
                            grouped.responseUris.relationships[
                              `${roleName}/${relationshipId}`
                            ],
                            `syncing for relationship=${relationshipId}`,
                            items.existingContactIds?.filter(Boolean).length
                              ? "notToSync"
                              : "synced",
                          );
                        }
                      }),
                    );
                  },
                ),
              );
            },
          );

        const { rejected } = await resolveSettled(
          Promise.allSettled([...updateLayouts, ...updateRoles]),
        );

        /**
         * Updating relationships have to start after updating roles,
         * so that it can have latest roles.
         */
        const latestExistingRoles = await roles?.get();
        const { rejected: rejectedRelationships } = await resolveSettled(
          Promise.allSettled(updateRelationships(latestExistingRoles)),
        );

        if (rejected.length === 0 && rejectedRelationships.length === 0) {
          if (shouldReviewConflict && partialMatchingContacts.length > 0) {
            await updateSyncStatus(submission, "pendingContactReview");
            return "pendingContactReview";
          }

          await updateSyncStatus(submission, "synced");
          return "completed";
        }

        console.warn("Failed to sync via SDK, falling back to API");
        [...rejected, ...rejectedRelationships].forEach((error) =>
          logError(error, "sdk syncing"),
        );

        await performBackendSync();
        return "backendSynced";
      } catch (error) {
        await updateSyncStatus(submission, "failedToSync");
        logError(error, "syncing");
        throw error;
      }
    },
  };
};
