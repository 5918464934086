import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { track } from "@smart/bridge-metrics-dom";
import { appendFormLink } from "@smart/bridge-templates-basic";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import {
  MatterSubmission,
  useCreateSubmission,
  useLoadFamilyProAuthorizationState,
  useLoadMatterQuestionnaireStatus,
  useLoadMatterType,
  useLoadMatterTypeSearch,
  useLoadSetting,
  useLoadTeam,
  useSendMatterQuestionnaire,
} from "@smart/manage-gql-client-dom";
import {
  buildRoleContactName,
  useAvailabilities,
  useEvent,
  useFormLoader,
  useRoleContacts,
  useSendMessage,
  useToast,
  useUser,
} from "@smart/manage-hooks-dom";

import { TabCreateAndSendContainer } from "./create-and-send-container";
import { setEmail } from "../../shared/submission/helpers";
import { Preview } from "../preview";

export type TabCreateAndSendProps = {
  sharing: "regular" | "internal" | undefined;
  sharingFormUri?: string;
  onClose: () => void;
  onEditSubmission: (submission: MatterSubmission) => void;
};

export const TabCreateAndSend = ({
  sharing,
  sharingFormUri,
  onClose,
  onEditSubmission,
}: TabCreateAndSendProps) => {
  const { user } = useUser();
  const { matters, contacts, roles } = useSmokeballApp();
  const authorizationState = useLoadFamilyProAuthorizationState({});
  const { availableForMatterFormsOnLeads, availableForFamilyPro } =
    useAvailabilities({
      matter: matters?.current,
      isFamilyProAuthorized: authorizationState.result?.isAuthorized ?? false,
    });
  const questionnairePartyA = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "a",
    },
    { reloadOnVisible: true, skip: !availableForFamilyPro },
  );
  const questionnairePartyB = useLoadMatterQuestionnaireStatus(
    {
      matterId: matters?.current?.id || "",
      party: "b",
    },
    { reloadOnVisible: true, skip: !availableForFamilyPro },
  );
  const { logEvent } = useEvent();
  const { setToasts } = useToast();
  const { sendMessage } = useSendMessage();
  const [createSubmission] = useCreateSubmission();
  const [sendQuestionnaire] = useSendMatterQuestionnaire();

  const roleContacts = useRoleContacts();
  const matterType = useLoadMatterType(
    {
      matterTypeId: matters?.current?.matterTypeId || "",
    },
    { skip: !user || !matters?.current?.matterTypeId },
  );
  const currentMatterType = matterType.result;
  const shouldSkipMatterFormsOnLeads =
    !matters?.current?.isLead || !availableForMatterFormsOnLeads;

  const matchedMatterType = useLoadMatterTypeSearch(
    {
      search: currentMatterType?.name || "",
      type: "matter",
      category: currentMatterType?.category || "",
      location: currentMatterType?.location || "",
    },
    {
      skip: shouldSkipMatterFormsOnLeads || !currentMatterType,
    },
  );

  const {
    teamForms,
    matterSpecificForms,
    globalForms,
    teamCategoryForms,
    globalCategoryForms,
  } = useFormLoader({
    userUri: user?.userUri,
    matter: matters?.current,
    onlyAICompletedForms: true,
  });
  const setting = useLoadSetting({});
  const team = useLoadTeam({});
  const { sendToClientOnly } = useFeatureFlags();

  if (!matters?.current || setting.loading) return null;

  const teamValue = team.result?.values || { name: "Firm", phone: "" };

  return (
    <TabCreateAndSendContainer
      matter={matters.current}
      matterType={currentMatterType || { name: "", location: "" }}
      matchedMatterType={matchedMatterType.data?.matterTypeSearch?.find(
        (mt) =>
          mt.name === currentMatterType?.name &&
          mt.category === currentMatterType.category &&
          mt.location === currentMatterType.location,
      )}
      setting={setting.result}
      team={teamValue}
      teamForms={teamForms}
      matterSpecificForms={matterSpecificForms}
      globalForms={globalForms}
      teamCategoryForms={teamCategoryForms}
      globalCategoryForms={globalCategoryForms}
      roleContacts={
        sendToClientOnly
          ? {
              ...roleContacts,
              roleContacts: roleContacts.roleContacts?.filter(
                (rc) => rc.role.isClient,
              ),
            }
          : roleContacts
      }
      sharing={sharing}
      sharingFormUri={sharingFormUri}
      Preview={Preview}
      logEvent={logEvent}
      isFamilyProAuthorized={authorizationState.result?.isAuthorized ?? false}
      isFamilyProFormShared={
        !!(questionnairePartyA.result?.link || questionnairePartyB.result?.link)
      }
      onSendQuestionnaire={async (options) => {
        const result = await sendQuestionnaire(options);
        questionnairePartyA.refetch().catch(console.error);
        questionnairePartyB.refetch().catch(console.error);
        return result;
      }}
      onSetEmail={async ({ roleContact, email }) => {
        try {
          if (!contacts?.update) return;
          await setEmail({ roleContact, email, update: contacts?.update });
          setToasts([
            {
              text: "Email successfully saved to contact",
            },
          ]);
        } catch (e) {
          console.error(e);
        }
      }}
      onCreateContact={async (personInfo) => {
        const { id, ...person } = personInfo;
        const contact = await (id
          ? contacts?.update({ id, person })
          : contacts?.create({
              person,
            }));
        if (!contact?.id)
          throw new Error(`Failed to ${id ? "update" : "create"} contact`);

        const clientRole = (await roles?.get())?.roles.find((r) => r.isClient);
        if (!clientRole) throw new Error("Failed to get the client role");

        const updatedRole = await roles?.update({
          id: clientRole.id,
          contactId: contact.id,
        });

        if (updatedRole?.contactId !== contact.id)
          throw new Error(
            `Failed to update role with contact id ${contact.id}`,
          );

        setToasts([
          {
            text: "Contact successfully saved to matter",
          },
        ]);
      }}
      onCreateSubmission={async (
        { uri, matter, aiFillFromMatterInfo },
        { form, contact, communicationMethod },
      ) => {
        if (!form) throw new Error(`Please select a form`);
        if (!contact) throw new Error(`Please select a contact`);
        if (!contact.email && communicationMethod !== "internalUse")
          throw new Error(`Please select a contact with an email address`);

        const created = await createSubmission({
          variables: {
            uri,
            formUri: form.uri,
            matterId: matter.id,
            sent: communicationMethod !== "internalUse",
            syncStatus: "loading",
            aiFillFromMatterInfo,
            communicationMethod,
            recipient: {
              contactId: contact.id,
              contactName: buildRoleContactName(contact, { hideRole: true }),
              email: contact.email || "",
            },
          },
        });

        const submission = created.data?.createSubmission;
        if (!submission) throw new Error(`Failed to create submission`);

        return submission;
      }}
      onEditSubmission={onEditSubmission}
      onMessage={(
        { contact, template, communicationMethod },
        placeholderData,
        trackingInfo,
      ) => {
        if (!contact) throw new Error(`Please select a contact`);
        if (!contact.email) throw new Error(`Please select a valid email`);
        if (communicationMethod === "internalUse") return;

        sendMessage({
          id: contact.id,
          email: contact.email,
          communicationMethod,
          placeholderData,
          subject: [matters.current?.reLine, "Please complete this online form"]
            .filter(Boolean)
            .join(" - "),
          template: appendFormLink(template),
        });
        track("Submission Sent", trackingInfo);
      }}
      onClose={onClose}
    />
  );
};
