import { useMemo } from "react";

import { SectionNS } from "@smart/bridge-types-basic";
import {
  DragNDropList,
  GqlStatusOverlay,
  GqlStatusOverlayProps,
} from "@smart/itops-components-dom";
import { useReorder, useSearchWithDefault } from "@smart/itops-hooks-dom";
import { useUpdateSectionOrder } from "@smart/manage-gql-client-dom";

import { CreateSection } from "./create";
import { DraggableSectionItem, SectionItem } from "./item";
import { GqlSection } from "./types";
import { SectionView } from "./view";
import { FormDisplayMode } from "../forms/types";

type SectionListProps = {
  mode: FormDisplayMode;
  formUri: string;
  sections: GqlSection[];
  result: GqlStatusOverlayProps;
};

const SectionList = ({ mode, formUri, sections, result }: SectionListProps) => {
  const { sectionId } = useSearchWithDefault((params) => ({
    sectionId: params.get("section"),
  }));
  const { onMove } = useReorder({
    mutation: () => useUpdateSectionOrder(false),
    extraFields: { formUri },
  });

  const section = useMemo(
    () =>
      sectionId &&
      sections.find((s) => s.uri === SectionNS.generateUri(sectionId)),
    [sections, sectionId],
  );

  if (section) {
    return <SectionView mode={mode} section={section} />;
  }

  return mode === "view" ? (
    <>
      {sections.map((s) => (
        <SectionItem key={s.uri} item={s} />
      ))}
    </>
  ) : (
    <>
      <DragNDropList
        id="draggable-section-list"
        items={sections}
        itemKey="uri"
        render={DraggableSectionItem}
        onMove={onMove}
        footer={
          <CreateSection
            formUri={formUri}
            after={sections[sections.length - 1]?.values.order}
          />
        }
      />
      <GqlStatusOverlay {...result} />
    </>
  );
};

export { SectionList, SectionListProps };
