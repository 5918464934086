import styled from "@emotion/styled";
import { transparentize } from "polished";

import { hands } from "@smart/bridge-images-dom";

const CardWrapper = styled.div`
  border-radius: 1rem;
  box-shadow: 0 0 0.8rem 0.2rem
    ${(props) => transparentize(0.6, props.theme.palette.foreground.accent)};
  font-size: ${(props) => props.theme.fontSize.small};

  figure {
    max-height: 160px;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin: 0;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    img {
      width: 100%;
      filter: blur(8px) grayscale(100%);
    }
  }

  div.content {
    padding: 2rem;
    color: ${(props) => props.theme.palette.disabled.base};

    .header {
      font-weight: 600;
      padding-bottom: 1.2rem;
      font-size: ${(props) => props.theme.fontSize.base};
    }

    .sub-header {
      font-weight: 500;
      padding-bottom: 1rem;
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }

  a {
    background-color: ${(props) => props.theme.palette.disabled.base};
    text-decoration: none;
    color: white;
    border-radius: 0.5rem;
    text-align: center;
    display: inline-block;
    transition: all 0.3s;
    padding: 1rem;
    margin: 0 2rem 2rem 2rem;
    cursor: default;
  }
`;

type CardProps = {
  image?: string;
  title?: string;
  content: string;
  buttonLabel?: string;
};

const Card = ({ image, title, content, buttonLabel }: CardProps) => (
  <CardWrapper>
    <figure>
      <img src={image ?? hands} alt="sample" />
    </figure>
    <div className="content">
      {title && <div className="header">{title}</div>}
      {content}
    </div>
    {buttonLabel && <a href="#sample-button">{buttonLabel}</a>}
  </CardWrapper>
);

export { CardProps, Card };
