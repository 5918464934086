import styled from "@emotion/styled";

import {
  DateFieldInput as DateFieldInputComponent,
  MultiList as MultiListComponent,
} from "@smart/bridge-intake-components-dom";
import { jsonParseOrReturn } from "@smart/itops-utils-basic";

import { ConditionField, DropdownField, InputField } from "./condition-field";
import { Condition } from "../conditions";

const DateFieldWrapper = styled.span`
  flex-grow: 0;
`;

const DateFieldInput = styled(DateFieldInputComponent)`
  margin: 0;

  label {
    margin: 0 1rem 0 0;
  }

  label:last-child {
    margin-right: 0;
  }

  input {
    height: 4rem;
    border-radius: 0.8rem;
  }
`;

const MultiList = styled(MultiListComponent)`
  height: 4rem;
  border-radius: 0.8rem;
  margin: 0;
`;

type ConditionValueProps = {
  condition: Condition;
  onChange: (value: string) => void;
};

const ConditionValue = ({ condition, onChange }: ConditionValueProps) => {
  const field = condition.affectingField;
  const { type } = field;
  const label = "Answer";
  const multiListValue = (
    <ConditionField label={label} dataTestId="condition-value-multi-list">
      <MultiList
        field={{ ...field, allowCustomResponse: false }}
        value={jsonParseOrReturn(condition.value)}
        onChange={(selected) => onChange(JSON.stringify(selected))}
        onBlur={() => {}}
      />
    </ConditionField>
  );

  switch (type) {
    case "number":
      return (
        <InputField
          label={label}
          name="condition-value"
          value={condition.value}
          onChange={onChange}
          type="number"
        />
      );
    case "date":
      return (
        <DateFieldWrapper>
          <DateFieldInput
            field={field}
            value={condition.value}
            onChange={onChange}
            onBlur={() => {}}
          />
        </DateFieldWrapper>
      );
    case "choice":
    case "yesNo":
      return condition.condition === "equalTo" ? (
        <DropdownField
          name="condition-value"
          label={label}
          options={field.options}
          keys={(o) => o.value}
          render={(o) => o.label}
          selected={
            field.options.find((o) => o.value === condition.value) || {
              label: "",
              value: "",
            }
          }
          onChange={(o) => onChange(o.value)}
        />
      ) : (
        multiListValue
      );
    case "checkbox":
      return multiListValue;
    default:
      return (
        <InputField
          label={label}
          name="condition-value"
          value={condition.value}
          onChange={onChange}
        />
      );
  }
};

export { ConditionValue, ConditionValueProps };
