import { PropsWithChildren, useEffect, useRef } from "react";

type ClickOutsideWrapperProps = PropsWithChildren<{
  onClickOutside: () => void;
}>;

const ClickOutsideWrapper = ({
  onClickOutside,
  children,
}: ClickOutsideWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

export { ClickOutsideWrapperProps, ClickOutsideWrapper };
