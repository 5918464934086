import styled from "@emotion/styled";
import { ReactNode } from "react";

import { DraggableItemProps, Icon } from "@smart/itops-components-dom";

import { DeleteField } from "./delete";
import { DeleteGroup } from "./delete-group";
import { EditField } from "./edit";
import { GroupItem } from "./group-item";
import { FormField, GqlField } from "./types";
import { FormDisplayMode } from "../forms/types";

type FieldItemProps = {
  mode?: FormDisplayMode;
  item: GqlField;
  children?: ReactNode;
};

const FieldItem = ({
  mode = "edit",
  children,
  item: {
    uri,
    values: {
      sectionUri,
      formUri,
      order,
      label,
      type,
      hint,
      options,
      mandatory,
      allowCustomResponse,
      layout,
      field,
      links,
      allowCopyFromFieldUri,
    },
  },
}: FieldItemProps) => (
  <EditField
    mode={mode}
    locators={{ uri, sectionUri, formUri, order }}
    defaultValues={{
      label,
      type,
      hint,
      options,
      mandatory,
      allowCustomResponse,
      layout: layout || undefined,
      field: field
        ? {
            ...field,
            possibleValues: field.possibleValues || undefined,
          }
        : undefined,
      links: links || undefined,
      allowCopyFromFieldUri: allowCopyFromFieldUri || undefined,
    }}
    debounce
  >
    {children}
  </EditField>
);

const Wrapper = styled.div`
  .handle {
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type DraggableFieldItemProps = DraggableItemProps<FormField>;

const DraggableFieldItem = ({
  item,
  draggableProvided,
}: DraggableFieldItemProps) => (
  <Wrapper
    ref={draggableProvided?.innerRef}
    {...draggableProvided?.draggableProps}
  >
    {item.type === "single" ? (
      <FieldItem item={item.field}>
        <div
          {...draggableProvided?.dragHandleProps}
          aria-label="Draggable field item handle"
          className="handle"
        >
          <Icon name="handle" />
        </div>
        <div className="delete">
          <DeleteField
            variables={{
              uri: item.field.uri,
              sectionUri: item.field.values.sectionUri,
              formUri: item.field.values.formUri,
            }}
          />
        </div>
      </FieldItem>
    ) : (
      <GroupItem item={item.group}>
        <div {...draggableProvided?.dragHandleProps} className="handle">
          <Icon name="handle" />
        </div>
        <div className="delete">
          <DeleteGroup
            variables={{
              uri: item.group.uri,
              sectionUri: item.group.values.sectionUri,
              formUri: item.group.values.formUri,
            }}
          />
        </div>
      </GroupItem>
    )}
  </Wrapper>
);

export { DraggableFieldItem, DraggableFieldItemProps, FieldItem };
