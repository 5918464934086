import { IconButton } from "@smart/itops-components-dom";
import { useExportForm, ExportFormProps } from "@smart/manage-gql-client-dom";

const ExportForm = ({ formUri }: ExportFormProps) => {
  const { status, errorCode, triggerExport } = useExportForm({ formUri });
  const icon = (
    {
      initial: "download",
      error: "warning",
      loading: "refresh",
      success: "checkCircle",
    } as const
  )[status];

  return (
    <IconButton
      name={icon}
      text="Export Form"
      onClick={triggerExport}
      title={`${status}${errorCode ? ` - ${errorCode}` : ""}`}
      spin={status === "loading"}
    />
  );
};

export { ExportForm };
