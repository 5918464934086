import { micromark } from "micromark";

import { PlaceholderData } from "./elements";
import {
  buildPlaceholderHtmlExtension,
  placeholderSyntaxExtension,
} from "./placeholder";

type ConvertOptions = {
  template: string;
  data: PlaceholderData;
  prettyDisplayText?: boolean;
};

export const convertToHtml = (options: ConvertOptions) => {
  const { template, data } = options;
  const placeholderHtmlExtension = buildPlaceholderHtmlExtension(data);

  return micromark(template, {
    extensions: [placeholderSyntaxExtension],
    htmlExtensions: [placeholderHtmlExtension],
  });
};

const removeCurlyBraces = (str: string) => str.replaceAll(/[{}]/g, "");
const getPlaceholderNameAndCasing = (nameWithCasing: string) => {
  const hasCasing = nameWithCasing.startsWith("_");
  return hasCasing
    ? ({
        casing: "lowercase",
        placeholderName: nameWithCasing.substring(1),
      } as const)
    : { placeholderName: nameWithCasing };
};

export const convertToText = (options: ConvertOptions) => {
  const { template, prettyDisplayText, data } = options;
  let text = template;

  const placeholdersWithLinkText = text.match(/\{\w+\|>[^}]*\}/g);
  for (const placeholder of placeholdersWithLinkText || []) {
    const [placeholderNameWithCasing, linkText] =
      removeCurlyBraces(placeholder).split("|>");
    const value =
      data[
        getPlaceholderNameAndCasing(placeholderNameWithCasing).placeholderName
      ];
    if (value) {
      text = text.replace(
        placeholder,
        prettyDisplayText
          ? `${linkText} - ${value}`
          : `[${linkText}](${value})`,
      );
    }
  }

  const placeholdersWithDisplayText = text.match(/\{\w+\|[^}]*\}/g);
  for (const placeholder of placeholdersWithDisplayText || []) {
    const [placeholderNameWithCasing] =
      removeCurlyBraces(placeholder).split("|");
    const { placeholderName, casing } = getPlaceholderNameAndCasing(
      placeholderNameWithCasing,
    );
    const value = data[placeholderName];
    if (value) {
      text = text.replace(
        placeholder,
        casing === "lowercase" ? value.toLocaleLowerCase() : value,
      );
    }
  }

  const regularPlaceholders = text.match(/\{\w+\}/g);
  for (const placeholder of regularPlaceholders || []) {
    const placeholderNameWithCasing = removeCurlyBraces(placeholder);
    const { placeholderName, casing } = getPlaceholderNameAndCasing(
      placeholderNameWithCasing,
    );
    const value = data[placeholderName];
    if (value) {
      text = text.replace(
        placeholder,
        casing === "lowercase" ? value.toLocaleLowerCase() : value,
      );
    }
  }

  return text;
};

export const convertSimplifiedHtmlToMarkdown = (html: string): string =>
  html
    .replace(/>\s+</g, "><")
    .replace(/<h1>(.*?)<\/h1>/g, "# $1\n")
    .replace(/<h2>(.*?)<\/h2>/g, "## $1\n")
    .replace(/<h3>(.*?)<\/h3>/g, "### $1\n")
    .replace(/<a\s+href="(.*?)".*?>(.*?)<\/a>/g, "[$2]($1)")
    .replace(/<em>(.*?)<\/em>/g, "*$1*")
    .replace(/<strong>(.*?)<\/strong>/g, "**$1**")
    .replace(/<ul>\s*(<li>.*?<\/li>\s*)+<\/ul>/g, (match) =>
      match.replace(/<\/?ul>/g, "").replace(/<li>(.*?)<\/li>/g, "- $1\n"),
    )
    .replace(/<ol>\s*(<li>.*?<\/li>\s*)+<\/ol>/g, (match) => {
      let count = 0;
      return match
        .replace(/<\/?ol>/g, "")
        .replace(/<li>(.*?)<\/li>/g, (_, item) => {
          count += 1;
          return `${count}. ${item}\n`;
        });
    })
    .replace(/<p>(.*?)<\/p>/g, "$1\n\n")
    .replace(/<\/?[^>]+(>|$)/g, "");
