import { Modal } from "@smart/itops-ui-dom";
import { useDeleteForm } from "@smart/manage-gql-client-dom";

import { GqlForm } from "../types";

export type DeletingFormModalProps = {
  deletingForm: GqlForm | undefined;
  onClose: () => void;
};

export const DeletingFormModal = ({
  deletingForm,
  onClose,
}: DeletingFormModalProps) => {
  const [deleteForm, { loading }] = useDeleteForm();

  return (
    <Modal
      onClose={onClose}
      open={!!deletingForm}
      loading={loading}
      header={{
        icon: { library: "lucide", name: "Trash2", variant: "error" },
        text: "Delete Form?",
      }}
      footer={{
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: onClose,
          },
        ],
        right: [
          {
            text: "Delete form",
            variant: "error",
            onClick: async () => {
              if (deletingForm)
                await deleteForm({ variables: { uri: deletingForm.uri } });
              onClose();
            },
          },
        ],
      }}
    >
      <p>
        Deleting &quot;{deletingForm?.formTitle || "this form"}&quot; can not be
        undone.
      </p>
      <p>
        <strong>Are you sure you want to delete it?</strong>
      </p>
    </Modal>
  );
};
