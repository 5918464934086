import { SectionNS } from "@smart/bridge-types-basic";
import { CreateForm } from "@smart/itops-components-dom";
import { rankBetween } from "@smart/itops-utils-basic";

import { EditSection } from "./edit";

type CreateSectionProps = {
  formUri: string;
  after?: string;
};

const CreateSection = ({ formUri, after }: CreateSectionProps) => (
  <CreateForm
    form={EditSection}
    buildLocators={() => ({
      formUri,
      uri: SectionNS.generateUri(),
      order: rankBetween({ after }),
    })}
    defaultValues={{
      title: "",
    }}
    create="Add New Section"
    submit="Create"
    cancel="Cancel"
  />
);

export { CreateSection, CreateSectionProps };
