import { useCallback, useEffect, useState } from "react";

import { indexBy, logError, SDKError } from "@smart/itops-utils-basic";

import {
  Contact,
  CreateContactRequest,
  Role,
  UpdateContactRequest,
  UseSDKApiOptions,
} from "../types";

const parseContactInput = ({
  person,
  company,
  groupOfPeople,
}: CreateContactRequest) => {
  const result: Omit<CreateContactRequest, "groupOfPeople"> = {};

  if (person && Object.keys(person).length > 0) result.person = person;
  if (company && Object.keys(company).length > 0) result.company = company;

  if (Object.keys(result).length > 0) return result;

  if (groupOfPeople && Object.keys(groupOfPeople).length > 0)
    throw new Error("SDK does not support groupOfPeople updates");

  return undefined;
};

export const useContacts = ({ client, isInit, observe }: UseSDKApiOptions) => {
  const [currentContacts, setCurrentContacts] = useState<Contact[]>();
  const [currentRoles, setCurrentRoles] = useState<Role[]>();
  const shouldObserve = observe?.includes("contacts");

  useEffect(() => {
    if (isInit && client && shouldObserve && client?.context?.matterId) {
      /**
       * There is no contacts api from the SDK to get all the contacts associated
       * to a matter. For the currentContacts value, we find all the contacts
       * based on the roles associated to a matter.
       */
      client.roles
        .get()
        .then(({ roles }) => setCurrentRoles(roles))
        .catch((err) => logError(new SDKError(err, { action: "roles.get" })));

      client.roles.observe(({ roles }) => setCurrentRoles(roles));
    }
  }, [isInit, shouldObserve, client?.context?.matterId]);

  useEffect(() => {
    if (isInit && client && shouldObserve && currentRoles) {
      Promise.all(
        currentRoles
          .filter((role) => role.contactId)
          .map(
            (role) => client.contacts.get(role.contactId) as Promise<Contact>,
          ),
      )
        .then(async (contacts) => {
          const groupOrCompanyMemberContactIds = contacts.flatMap(
            /**
             * groupOfPeople.people in smokeball version 8.6 is { id: string }[]
             * and it changes to string[] in version 8.8
             * The check should be removed once all clients have upgraded to 8.8
             */
            (c) =>
              (c.groupOfPeople?.people?.map((p: any) =>
                typeof p === "string" ? p : p.id,
              ) as string[]) || [
                ...(c.company?.staff ?? c.company?.staffIds ?? []),
                ...(c.company?.directors ?? c.company?.directorIds ?? []),
              ],
          );
          const groupOrCompanyMemberContacts =
            groupOrCompanyMemberContactIds.length > 0
              ? await Promise.all(
                  groupOrCompanyMemberContactIds.map((id) =>
                    client.contacts.get(id),
                  ),
                )
              : [];
          return [...contacts, ...groupOrCompanyMemberContacts].filter(
            (c) => !c.isDeleted,
          );
        })
        .then(setCurrentContacts)
        .catch((err) =>
          logError(
            new SDKError(err, { action: "contacts.get", input: currentRoles }),
          ),
        );
    }
  }, [isInit, shouldObserve, currentRoles]);

  const updateContacts = useCallback(
    (updatedContacts: Contact[]) => {
      const updatedContactMap = indexBy("id", updatedContacts);
      setCurrentContacts((current) =>
        current
          ?.map((c) => updatedContactMap[c.id] || c)
          .filter((c) => !c.isDeleted),
      );
    },
    [currentContacts, currentRoles],
  );

  useEffect(() => {
    if (isInit && client && shouldObserve && currentRoles) {
      client.contacts.observe(updateContacts);
    }
  }, [isInit, shouldObserve, currentRoles, updateContacts]);

  if (!isInit || !client) return undefined;

  return {
    get: (contactId: string) => client.contacts.get(contactId),
    create: async (request: CreateContactRequest) => {
      try {
        const input = parseContactInput(request);
        if (!input) return undefined;

        const result = await client.contacts.create(input);
        setCurrentContacts((contacts) => [...(contacts || []), result]);
        return result;
      } catch (error) {
        throw new SDKError(error, {
          action: "contacts.create",
          input: request,
        });
      }
    },
    update: async (request: UpdateContactRequest) => {
      try {
        const input = parseContactInput(request);
        if (!input) return undefined;

        const existing = await client.contacts.get(request.id);
        const result = await client.contacts.update({
          id: request.id,
          person: input.person
            ? {
                ...existing.person,
                ...input.person,
              }
            : existing.person,
          company: input.company
            ? {
                ...existing.company,
                ...input.company,
              }
            : existing.company,
        });
        updateContacts([result]);
        return result;
      } catch (error) {
        throw new SDKError(error, {
          action: "contacts.update",
          input: request,
        });
      }
    },
    open: (id: string) => client.contacts.open(id),
    current: currentContacts,
  };
};
