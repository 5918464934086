import {
  envKeys,
  familyProApiKeys,
  googleMapApiKeys,
  smokeballApiKeys,
  smokeballPoolKeys,
} from "@smart/bridge-env-types-basic";
import { buildApiGatewayDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

const lambdaDefs = {
  echo: {
    pkg: "@smart/manage-echo-lambda",
    deployable: "echo",
    method: "POST",
    authenticated: false,
    env: [],
  },
  gql: {
    pkg: "@smart/manage-gql-lambda",
    deployable: "gql",
    method: "POST",
    authenticated: true,
    env: [...smokeballApiKeys, ...familyProApiKeys, ...googleMapApiKeys],
  },
  me: {
    pkg: "@smart/manage-me-lambda",
    deployable: "me",
    method: "POST",
    authenticated: true,
    env: ["CdnPrivateKey"],
  },
  asset: {
    pkg: "@smart/manage-asset-lambda",
    deployable: "asset",
    method: "POST",
    authenticated: true,
    env: [],
  },
  activityEventQueuer: {
    pkg: "@smart/manage-activity-event-queuer-lambda",
    deployable: "activityEventQueuer",
    method: "POST",
    authenticated: true,
    env: [],
  },
} as const;

export const apiGateway = buildApiGatewayDef({
  envKeys,
  cdn,
  namespace: buildNamespace({ system, entity: "manage" }),
  prefix: "api",
  authorized: true,
  authorizer: {
    pkg: "@smart/manage-authorizer-lambda",
    deployable: "authorizer",
    env: [...smokeballPoolKeys],
  },
  lambdaKeys: keysOf(lambdaDefs),
  lambdas: lambdaDefs,
  port: 9111,
});
