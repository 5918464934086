import { useEffect, useState } from "react";

import { extractPrefixedTeamId } from "@smart/bridge-types-basic";
import { useDebounce } from "@smart/itops-hooks-dom";
import { Button, TextInput } from "@smart/itops-ui-dom";
import { useLazyLoadTeams } from "@smart/manage-gql-client-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

type ActAsProps = {
  loadTeam: ReturnType<typeof useLazyLoadTeams>[0];
  loadedTeam: ReturnType<typeof useLazyLoadTeams>[1]["data"];
};

export const ActAs = ({ loadTeam, loadedTeam }: ActAsProps) => {
  const { setActingAsFirm, clearActingFirm, actingFirmId } =
    useManageFormsContext();

  const [searchInput, setSearchInput] = useState(
    actingFirmId ? extractPrefixedTeamId(actingFirmId, "sb").id : "",
  );

  const debouncedSearch = useDebounce(
    (teamId: string) => loadTeam({ variables: { teamId } }),
    500,
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchInput(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (loadedTeam?.teams?.values) {
      setActingAsFirm({
        ...loadedTeam.teams.values,
        email: loadedTeam.teams.values.email ?? undefined,
        picture: loadedTeam.teams.values.picture ?? undefined,
      });
    }
  }, [loadedTeam]);

  useEffect(() => {
    if (!searchInput.length) clearActingFirm();
  }, [searchInput]);

  return (
    <TextInput
      kind="round"
      icon={{ library: "lucide", name: "Building" }}
      title="Act as"
      onChange={onChange}
      placeholder="Act as"
      value={searchInput}
      right={
        searchInput && (
          <Button
            icon={{ library: "lucide", name: "X", size: 16 }}
            kind="borderless"
            dataTestId="clear-firm"
            onClick={() => {
              setSearchInput("");
              clearActingFirm();
            }}
          />
        )
      }
    />
  );
};
