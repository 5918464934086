import { z } from "zod";

import { useStorage } from "@smart/itops-hooks-dom";
import { Avatar, Table, TableProps } from "@smart/itops-sb-design-system-dom";
import { useColumns } from "@smart/itops-smokeball-components-dom";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import {
  Created,
  FormTitle,
  Modified,
  Status,
  statusSortingFn,
  SubmissionAction,
  TabRowValue,
} from "./cells";
import { MatterSpecificForm } from "./matter-specific-forms-modal";

const sortSchema = z.array(z.object({ id: z.string(), desc: z.boolean() }));

export type TabListTableProps = {
  submissions: MatterSubmission[];
  filteredSubmissions: MatterSubmission[];
  matterSpecificForms: MatterSpecificForm[];
  loading: boolean;
  onView: (submission: MatterSubmission) => void;
  onViewForm: (form: MatterSpecificForm) => void;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
  onGenerateQRCode: (submission: MatterSubmission) => void;
  globalFilter: TableProps<TabRowValue>["globalFilter"];
};

export const TabListTable = ({
  submissions,
  filteredSubmissions,
  matterSpecificForms,
  loading,
  onView,
  onViewForm,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
  onGenerateQRCode,
  globalFilter,
}: TabListTableProps) => {
  const [columnSort, setColumnSort] = useStorage({
    defaultValue: [{ id: "createdAt", desc: true }],
    key: `submission-list-sort`,
    schema: sortSchema,
    storage: "session",
  });

  const columns = useColumns<TabRowValue>((c) => [
    c.accessor((v) => (v.submission ? v.submission.formTitle : v.form.title), {
      id: "name",
      header: "Name",
      size: 250,
      meta: { variableWidth: true },
      cell: FormTitle,
    }),
    c.accessor((v) => v.submission?.createdAt, {
      id: "createdAt",
      header: "Shared Date",
      size: 200,
      cell: Created,
      enableGlobalFilter: false,
      sortUndefined: -1,
    }),
    c.accessor((v) => v.submission?.status, {
      id: "status",
      header: "Status",
      size: 160,
      cell: Status,
      sortingFn: statusSortingFn,
      enableGlobalFilter: false,
    }),
    c.accessor((v) => v.submission?.updatedAt, {
      id: "updatedAt",
      header: "Modified",
      size: 200,
      cell: Modified,
      enableGlobalFilter: false,
    }),
    c.display({
      id: "actions",
      cell: SubmissionAction({
        onView,
        onViewForm,
        onCancel,
        onDelete,
        onRemind,
        onEdit,
        onGenerateQRCode,
      }),
      meta: { action: true },
      size: 65,
    }),
  ]);

  const filteredForms = matterSpecificForms.filter(
    (form) => !submissions.find((s) => s.formUri === form.uri),
  );

  return (
    <Table
      data={[
        ...filteredSubmissions.map((submission) => ({
          submission,
          form: undefined,
        })),
        ...filteredForms.map((form) => ({
          form,
          submission: undefined,
        })),
      ]}
      columns={columns}
      updating={loading}
      loadingRows={loading && !filteredSubmissions?.length ? 3 : undefined}
      getRowId={(row) => row.submission?.uri || row.form?.uri || ""}
      select={{
        state: {},
        onChange: (selected) => {
          const value =
            typeof selected === "function" ? selected({}) : selected;
          const uri = Object.keys(value)[0];
          const submission = filteredSubmissions.find((s) => s.uri === uri);
          if (submission) onView(submission);
          const form = matterSpecificForms.find((f) => f.uri === uri);
          if (form) onViewForm(form);
        },
      }}
      sort={{
        state: columnSort,
        onChange: setColumnSort,
      }}
      globalFilter={globalFilter}
      empty={
        <div className="flex flex-col items-center gap-small py-[3.2rem]">
          <Avatar size={32} icon="solidCircleInfo" />
          <h2 className="text-ui font-semibold text-neutral-600">
            {submissions.length ? "No shared forms found" : "No shared forms"}
          </h2>
          {!submissions.length && (
            <p className="text-ui text-neutral-600">
              To get started, share a form above
            </p>
          )}
        </div>
      }
      applyColumnSize
    />
  );
};
