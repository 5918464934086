import { DependencyList, useEffect, useRef } from "react";

export const useChangedEffect = (fn: () => void, deps: DependencyList) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      fn();
    } else {
      mounted.current = true;
    }
  }, deps);
};
