import { ThemeProvider } from "@emotion/react";
import { renderToStaticMarkup } from "react-dom/server";

import {
  intakeTheme,
  renderOrderedItems,
  SummaryField,
  SummaryGroup,
  SummarySection,
  useOrderedSections,
  useVisibility,
} from "@smart/bridge-intake-components-dom";
import { GlobalStyles } from "@smart/itops-components-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { fromEntries, jsonParseOrReturn } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLoadFields,
  useLoadGroups,
  useLoadSections,
} from "@smart/manage-gql-client-dom";

export const useCreateDocFromSubmission = (
  matterSubmission?: MatterSubmission,
) => {
  const { correspondence, hostType, matters } = useSmokeballApp();
  const { result: sectionsResult } = useLoadSections({
    formUri: matterSubmission?.formUri || "",
  });
  const { result: groupsResult } = useLoadGroups({
    formUri: matterSubmission?.formUri || "",
  });
  const { result: fieldsResult } = useLoadFields({
    formUri: matterSubmission?.formUri || "",
  });
  const sections = sectionsResult?.map((s) => s.values) || [];
  const groups = groupsResult?.map((g) => g.values) || [];
  const fields = fieldsResult?.map((f) => f.values) || [];
  const responses = matterSubmission?.responses
    ? fromEntries(
        matterSubmission.responses.map((r) => [
          r.fieldUri,
          jsonParseOrReturn(r.value),
        ]),
      )
    : {};
  const visibility = useVisibility({
    sections,
    groups,
    fields,
    responses,
  });
  const orderedSections = useOrderedSections({
    sections,
    groups,
    fields,
    visibility,
  });

  if (!matterSubmission || hostType === "web") return {};

  const docToRender = (
    <ThemeProvider theme={intakeTheme}>
      <GlobalStyles />
      {orderedSections.map(({ orderedItems, section }) => (
        <SummarySection
          key={section.uri}
          section={section}
          titleParagraphTag="h1"
        >
          {renderOrderedItems({
            orderedItems,
            responses,
            renderGroup: (props) => <SummaryGroup {...props} />,
            renderField: (props) => <SummaryField {...props} />,
          })}
        </SummarySection>
      ))}
    </ThemeProvider>
  );

  const body = renderToStaticMarkup(docToRender);

  return {
    createDocument: () =>
      correspondence?.document({
        body,
        bodyContentType: "html",
        matterId: matters?.current?.id,
      }),
    createLetterDocument: () =>
      correspondence?.letter({
        body,
        bodyContentType: "html",
        matterId: matters?.current?.id,
      }),
    createFormDocument: () =>
      correspondence?.form({
        body,
        bodyContentType: "html",
        matterId: matters?.current?.id,
      }),
  };
};
