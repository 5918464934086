import { RuleEvaluators } from "../types";

export const user: RuleEvaluators["user"] = (
  currentUser,
  _,
  { value: { uri } },
) => {
  // Users can perform any action on their own user object
  if (currentUser.userUri === uri) return true;

  // Otherwise, no access
  return false;
};
