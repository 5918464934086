import styled from "@emotion/styled";
import { useState } from "react";

const TabsView = styled.div`
  width: 98%;
  min-height: 89%;

  display: flex;
  flex-flow: column nowrap;
`;

const TabList = styled.div`
  display: flex;
  flex-flow: rows wrap;
  margin-bottom: -1px;
  z-index: 2;

  .tab {
    background-color: ${(props) => props.theme.palette.background.base};
    border: 1px solid ${(props) => props.theme.palette.active.highlight};
    font-size: ${(props) => props.theme.fontSize.small};
    padding: 4px 8px;

    text-transform: uppercase;

    &[aria-selected="true"] {
      border-bottom-color: transparent;
    }

    &:not(:first-of-type) {
      border-left-width: 0;
    }

    &:focus {
      outline: none;
    }
  }
`;

const TabView = styled.div`
  background-color: ${(props) => props.theme.palette.background.base};
  border: 1px solid ${(props) => props.theme.palette.active.highlight};
  padding: 1.4rem;

  flex: 1;
`;

export type TabsProps<K extends string> = {
  keys: K[];
  initial?: K;
  labels: Record<K, string>;
  views: Record<K, () => JSX.Element>;
};

export const Tabs = <K extends string>({
  keys,
  initial,
  labels,
  views,
}: TabsProps<K>) => {
  const [selected, setSelected] = useState(initial);
  const View = selected ? views[selected] : () => null;

  return (
    <TabsView>
      <TabList>
        {keys.map((key) => (
          <button
            type="button"
            className="tab"
            onClick={() => setSelected(key)}
            aria-selected={selected === key}
            role="tab"
            key={key}
          >
            {labels[key]}
          </button>
        ))}
      </TabList>
      <TabView>
        <View />
      </TabView>
    </TabsView>
  );
};
