import { useMemo, useState } from "react";

import { FieldLinkType, LinkConditionType } from "@smart/bridge-types-basic";
import { fromEntries, removeKeys } from "@smart/itops-utils-basic";

import { ConditionItem } from "./edit-conditions-modal/utils";
import { GqlField, GqlFieldValues, GqlGroup, GqlSection } from "../../types";

export type Condition = {
  id: string;
  condition: LinkConditionType;
  value: string;
  affectingField: GqlFieldValues;
  affectedItem: ConditionItem | undefined;
  hide?: boolean | null;
};

export type ConditionMap = {
  affect: Record<string, Condition[]>;
  affectedBy: Record<string, Condition[]>;
};

export const useConditions = ({
  sections,
  groups,
  fields,
}: {
  sections?: GqlSection[] | null;
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
}) => {
  const [editingCondition, setEditingCondition] =
    useState<ConditionItem | null>(null);

  const conditionsMap = useMemo(() => {
    const items = [...(sections || []), ...(groups || []), ...(fields || [])];

    const itemLookup = fromEntries(
      items.map((item) => [item.uri, item.values]),
    );
    const affect: Record<string, Condition[]> = {};
    const affectedBy: Record<string, Condition[]> = {};
    items.forEach((item) => {
      const { links } = item.values;
      if (!links?.length) return;

      links.forEach((link, linkIndex) => {
        const condition = {
          id: `${item.uri}-${linkIndex}`,
          condition: link.condition,
          value: link.value,
          affectingField: itemLookup[link.fieldUri] as GqlFieldValues,
          affectedItem: item.values,
          hide: link.hide,
        };
        affectedBy[item.uri] = [...(affectedBy[item.uri] || []), condition];
        affect[link.fieldUri] = [...(affect[link.fieldUri] || []), condition];
      });
    });

    return {
      affect,
      affectedBy,
    };
  }, [sections, groups, fields]);

  const checkHasConditions = (itemUri: string) =>
    !!conditionsMap.affect[itemUri]?.length ||
    !!conditionsMap.affectedBy[itemUri]?.length;

  const createLinksMap = (
    currentFieldUri: string,
    conditions: Condition[],
  ): Record<string, FieldLinkType[]> => {
    const linksMap: Record<string, FieldLinkType[]> = {};
    const items = [...(sections || []), ...(groups || []), ...(fields || [])];
    const itemLookup = fromEntries(
      items.map((item) => [item.uri, item.values]),
    );

    items.forEach((item) => {
      if (item.values.links?.some((l) => l.fieldUri === currentFieldUri)) {
        linksMap[item.uri] = item.values.links
          .filter((link) => link.fieldUri !== currentFieldUri)
          .map((l) => removeKeys(l, ["__typename"]));
      }
    });

    conditions
      .filter((condition) => condition.affectingField.uri === currentFieldUri)
      .forEach((condition) => {
        const fieldLink = {
          fieldUri: currentFieldUri,
          value: condition.value,
          condition: condition.condition,
          hide: !!condition.hide,
        };
        const affectedItemUri = condition.affectedItem?.uri;
        if (!affectedItemUri) return;

        const isUpdatingExistingCondition = !!linksMap[affectedItemUri];

        if (isUpdatingExistingCondition) {
          linksMap[affectedItemUri].push(fieldLink);
        } else {
          // Adding new condition
          linksMap[affectedItemUri] = [
            ...(itemLookup[affectedItemUri].links || []).map((l) =>
              removeKeys(l, ["__typename"]),
            ),
            fieldLink,
          ];
        }
      });

    return linksMap;
  };

  return {
    editingCondition,
    setEditingCondition,
    conditionsMap,
    checkHasConditions,
    createLinksMap,
  };
};
