import { HotKey, parseHotkey } from "is-hotkey";
import { KeyboardEvent, MouseEvent, TouchEvent } from "react";
import { Editor, Element } from "slate";

import { EditorElementType, Mark } from "@smart/itops-serialisation-basic";

import { IconName } from "../../icon";

export const hotkeyLabel = (hotkey?: HotKey) =>
  hotkey
    ? [
        hotkey.ctrlKey && "Ctrl",
        hotkey.metaKey && "Cmd",
        hotkey.altKey && "Alt",
        hotkey.shiftKey && "Shift",
        hotkey.key?.toLocaleUpperCase(),
      ]
        .filter(Boolean)
        .join(" + ")
    : undefined;

export const marks: Record<Mark, { icon: IconName; hotkey?: HotKey }> = {
  bold: { icon: "regularBold", hotkey: parseHotkey("mod+b", { byKey: true }) },
  italic: {
    icon: "regularItalic",
    hotkey: parseHotkey("mod+i", { byKey: true }),
  },
};
export const markOptions: Mark[] = ["bold", "italic"];

export const isMarkActive = (editor: Editor, mark: Mark) => {
  const selected = Editor.marks(editor);
  return selected ? selected[mark] === true : false;
};

export const toggleMark =
  (editor: Editor, mark: Mark) =>
  (e: KeyboardEvent | MouseEvent | TouchEvent) => {
    e.preventDefault();
    const isActive = isMarkActive(editor, mark);

    if (isActive) {
      Editor.removeMark(editor, mark);
    } else {
      Editor.addMark(editor, mark, true);
    }
  };

export const modalOptions = ["link", "placeholder"] as const;

export type ModalOption = (typeof modalOptions)[number];

export const modals: Record<ModalOption, { icon: IconName; hotkey?: HotKey }> =
  {
    link: {
      icon: "regularLink",
      hotkey: parseHotkey("mod+k", { byKey: true }),
    },
    placeholder: {
      icon: "solidSquareA",
      hotkey: parseHotkey("mod+shift+c", { byKey: true }),
    },
  };

export const isBlockActive = (editor: Editor, types: EditorElementType[]) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && types.includes(n.type),
    }),
  );

  return !!match;
};
