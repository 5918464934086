import clsx from "clsx";

import { Avatar, Tooltip } from "@smart/itops-sb-design-system-dom";

import {
  fieldComponentIcons,
  fieldComponentNames,
  fieldComponentTooltips,
} from "../constants";
import { FieldTemplate } from "../types";

type FieldComponentProps = FieldTemplate & {
  disabled?: boolean;
  isDragging?: boolean;
};

const FieldComponent = ({
  type,
  disabled,
  isDragging,
}: FieldComponentProps) => (
  <div
    className={clsx(
      "flex items-center gap-small px-[1.6rem] py-[0.4rem] rounded hover:bg-cyan-140",
      isDragging && "bg-cyan-140 shadow-modal",
    )}
    aria-disabled={disabled}
    aria-selected={isDragging}
  >
    <Tooltip {...(fieldComponentTooltips[type] || {})} offsetBy={7}>
      <Avatar
        size={32}
        icon={fieldComponentIcons[type].name}
        bgColor={fieldComponentIcons[type].bgColor}
      />
    </Tooltip>
    <span className="text-ui font-semibold">{fieldComponentNames[type]}</span>
  </div>
);

export { FieldComponent, FieldComponentProps };
