import { FieldType } from "@smart/bridge-types-basic";

export type SBContactType = "person" | "company";

export type Phone = {
  areaCode?: string | null;
  number?: string | null;
};

export type Address = {
  buildingLevel?: string | null;
  unitNumber?: string | null;
  unitType?: string | null;
  streetNumber?: string | null;
  streetName?: string | null;
  streetType?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locality?: string | null;
  county?: string | null;
  country?: string | null;
};

export type SBContact = {
  id: string;
  person?: {
    title?: string | null;
    nameSuffix?: string | null;
    residentialAddress?: Address | null;
    mailingAddress?: Address | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    phone?: Phone | null;
    cell?: Phone | null;
    email?: string | null;
    notes?: string | null;
    occupation?: string | null;
    maritalStatus?: string | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    birthState?: string | null;
    birthCountry?: string | null;
  };
  company?: {
    name?: string | null;
    type?: string | null;
    phone?: Phone | null;
    fax?: Phone | null;
    email?: string | null;
    notes?: string | null;
    abn?: string | null;
    businessAddress?: Address | null;
    mailingAddress?: Address | null;
    companyNumberType?: string | null;
    companyNumber?: string | null;
  };
  groupOfPeople?: {
    people?: any[]; // API and SDK return different data so need to be cautious
  };
};

export const createNewContactPlaceholder = "CREATE_NEW_CONTACT_PLACEHOLDER";

export const consolidatedAddressLayoutType = "Address";
export const addressRegex = /\/?Address(\/[a-zA-Z]+)$/;

export const checkboxLayoutType = "Checkbox";
export const consolidatedCheckboxesLayoutType = "Checkboxes";
export const checkboxesLayoutRegex = /.*(\/[a-zA-Z]+)$/;

export const basicLayoutProviderId = "LayoutProvider" as const;

export const roleProviderId = "roles_provider" as const;

export const defaultClientRoleId = "intakeDefaultClientRole" as const;

export const matterTypeProviderId = "MatterTypeProvider" as const;

export const cuiLayoutId = ({
  id,
  providerId,
}: {
  id: string;
  providerId: string;
}) => (providerId === `${id}Provider` ? id : undefined);

export const layoutFieldTypeMap: Record<string, FieldType[]> = {
  Address: ["address"],
  Phone: ["phoneNumber"],
  DateTime: ["date"],
  Email: ["email"],
  Combobox: ["choice"],
  Checkboxes: ["checkbox"],
  Checkbox: ["yesNo"],
  Text: ["text", "multilineText"],
};

export const matterTypeTypeNumberToFormCategory: Record<
  number,
  "matter" | "lead"
> = {
  0: "matter",
  1: "lead",
} as const;

export type LayoutField = {
  name: string;
  type: string;
  possibleValues?: string[];
};

export type Field = {
  uri: string;
  layout?: {
    providerId: string;
    id: string;
    parentId?: string;
    parentProviderId?: string;
  };
  field?: LayoutField;
  type: FieldType;
  order: string;
  sectionUri: string;
  groupUri?: string;
};

export type Section = {
  uri: string;
  order: string;
};

export const layoutAddressComponents = [
  "AddressLine1",
  "AddressLine2",
  "City",
  "County",
  "FlatUnit",
  "Locality",
  "Postcode",
  "PropertyBuildingName",
  "State",
  "StateShort",
  "StreetName",
  "StreetNumber",
  "StreetType",
  "SuburbTown",
  "TownCity",
  "UnitNumber",
  "UnitType",
  "ZipCode",
];

export type MatchType = "exact" | "partial" | "none";

export type MatchingContact = SBContact & {
  matchType: MatchType;
  rank: number;
};

export type IntakeContact = Omit<SBContact, "id" | "groupOfPeople">;

export const clientSolicitorIds = ["Solicitor", "Attorney"];

export type IntakeMatterEvent = {
  bookedStaff?: {
    id: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }[];
  startTime?: string;
  endTime?: string;
  timezone?: string;
  status?: "scheduled" | "cancelled" | "rejected" | "invalid" | "pending";
};
