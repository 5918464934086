import { loadLocale } from "@smart/itops-locale-dom";
import {
  useLoadMatterTypeCategories,
  useLoadSetting,
  useUpdateSetting,
} from "@smart/manage-gql-client-dom";

import { AreasContainer } from "./areas-container";
import { AreaInfo } from "../../types";

const PracticeAreas = () => {
  const { states } = loadLocale();
  const { result: setting, loading } = useLoadSetting({});
  const categories = useLoadMatterTypeCategories({
    teamUri: setting?.teamUri,
  });
  const [post, result] = useUpdateSetting();

  const availableAreasOfLaw: AreaInfo[] | undefined = categories.result?.map(
    (cat) => ({ name: cat, displayName: cat }),
  );

  const selectedAreasOfLaw: AreaInfo[] =
    availableAreasOfLaw?.filter((cat) =>
      setting?.areasOfLaw?.includes(cat.name),
    ) || [];

  return (
    <AreasContainer
      states={{
        available: states,
        selected: states.filter((s) => setting?.locations?.includes(s.name)),
        loading: loading || result.loading,
        save: async (locations) => {
          await post({ variables: { fields: { locations } } });
        },
      }}
      practiceAreas={{
        available: availableAreasOfLaw,
        selected: selectedAreasOfLaw,
        loading: categories.loading || loading || result.loading,
        save: async (areasOfLaw) => {
          await post({ variables: { fields: { areasOfLaw } } });
        },
      }}
    />
  );
};

export { PracticeAreas };
