import styled from "@emotion/styled";
import React from "react";

import { AnalyticsTool } from "@smart/bridge-types-basic";
import {
  FieldContentWrapper,
  Label,
} from "@smart/itops-smokeball-components-dom";
import { TextInput as TextInputComponent } from "@smart/itops-ui-dom";

const availableTypes = ["googleAnalytics"] as const;
const labels: Record<(typeof availableTypes)[number], string> = {
  googleAnalytics: "Google Analytics measurement ID",
};
const defaultConfig = availableTypes.map((type) => ({ type, trackingId: "" }));

const FieldLabel = styled(Label)`
  min-width: 6rem;
  max-width: 15rem;
`;

const TextInput = styled(TextInputComponent)`
  border-radius: ${(props) => props.theme.baseUnit * 0.3}rem;
  flex: 1;

  &::before {
    height: 3rem;
  }

  input {
    font-size: ${(props) => props.theme.fontSize.base};
    padding: 0;
  }
`;

type AnalyticsConfigProps = {
  config: AnalyticsTool[] | undefined;
  setConfig: (
    set: (config: AnalyticsTool[] | undefined) => AnalyticsTool[],
  ) => void;
};

export const AnalyticsConfig = ({ config, setConfig }: AnalyticsConfigProps) =>
  (config?.length ? config : defaultConfig).map(({ type, trackingId }) => (
    <React.Fragment key={type}>
      <FieldLabel htmlFor={type}>{labels[type]}</FieldLabel>
      <FieldContentWrapper>
        <TextInput
          id={type}
          value={trackingId}
          onChange={(event) =>
            setConfig((existing) => [
              ...(existing || []).filter(
                ({ type: existingType }) => existingType !== type,
              ),
              { type, trackingId: event.target.value },
            ])
          }
        />
      </FieldContentWrapper>
    </React.Fragment>
  ));
