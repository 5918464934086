import { z } from "zod";

import { useApi } from "@smart/itops-hooks-dom";
import { toSubdomain } from "@smart/itops-utils-basic";

export const authApi = useApi(
  toSubdomain("dev", `/local`),
  ["matters", "matter"],
  {
    matters: { method: "GET", deployable: "matters", authenticated: false },
    matter: { method: "POST", deployable: "matter", authenticated: false },
  },
  {
    matters: {
      input: z.object({ accountId: z.string() }),
      output: z.array(
        z.object({
          matterId: z.string(),
          number: z.string(),
          isLead: z.boolean(),
          matterTypeId: z.string(),
          matterTypeName: z.string(),
        }),
      ),
    },
    matter: {
      input: z.object({
        accountId: z.string(),
        userId: z.string(),
        matterId: z.string(),
      }),
      output: z.any(),
    },
  },
);
