import { ReactNode } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { FeatureFlags } from "@smart/bridge-feature-flags-dom/lib/types";
import { Sidebar, SidebarLink } from "@smart/itops-components-dom";

import { UserMenu } from "./user-menu";

const getLinks = (featureFlags: FeatureFlags): SidebarLink[] => {
  const links: (SidebarLink | false | undefined)[] = [
    {
      to: "/",
      label: "Dashboard",
      icon: "home",
    },
    {
      to: "/forms",
      label: "Forms",
      icon: "form",
    },
    {
      to: "/submissions",
      label: "Submissions",
      icon: "personCircle",
    },
    {
      to: "/team",
      label: "Firm Details",
      icon: "building",
    },
    featureFlags.globalForm && {
      to: "/library",
      label: "Library",
      icon: "library",
    },
  ];

  return links.filter((_) => _) as SidebarLink[];
};

export type MenuProps = { children: ReactNode };

const Menu = ({ children }: MenuProps) => {
  const featureFlags = useFeatureFlags();

  return (
    <Sidebar links={getLinks(featureFlags)} footer={UserMenu}>
      {children}
    </Sidebar>
  );
};

export { Menu };
