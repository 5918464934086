import { parse } from "date-fns";
import { ZodSchema, z } from "zod";

import { FieldType } from "@smart/bridge-types-basic";
import { fromEntries, isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { Condition } from "./conditions";

type ConditionValueValidator = (response: any) => string | undefined;

const schemaValidator =
  (schema: ZodSchema): ConditionValueValidator =>
  (value) => {
    if (!value) return undefined;

    const parsed = schema.safeParse(value);
    if (parsed.success) return undefined;

    const flattened = parsed.error.flatten();
    return flattened.formErrors[0];
  };

const validationByType: Partial<Record<FieldType, ConditionValueValidator>> = {
  date: schemaValidator(
    z.preprocess(
      (val) => {
        const parts = (val as string).split("-");
        return parse(
          [
            parseInt(parts[0], 10),
            parseInt(parts[1], 10),
            parseInt(parts[2], 10),
          ].join("-"),
          "yyyy-MM-dd",
          0,
        );
      },
      z
        .date({
          errorMap: (error, ctx) => {
            if (error.code === z.ZodIssueCode.invalid_date) {
              return { ...error, message: "Date is invalid" };
            }

            return { ...error, message: error.message || ctx.defaultError };
          },
        })
        .min(new Date("1000-01-01"), "Date is invalid"),
    ),
  ),
};

const validateCondition = (condition: Condition): string | undefined => {
  if (!condition.affectedItem?.uri) return `Condition must have a result`;
  if (!condition.condition || !condition.value)
    return `Condition must have an answer`;
  if (validationByType[condition.affectingField.type])
    return validationByType[condition.affectingField.type]!(condition.value);

  return undefined;
};

export const validateConditions = (
  conditions: Condition[],
): Record<string, string> | undefined => {
  if (!conditions?.length) return undefined;

  const errors = conditions
    .map((condition): [string, string] | undefined => {
      const error = validateCondition(condition);
      return error ? [condition.id, error] : undefined;
    })
    .filter(isNotNullOrUndefined);

  return errors.length ? fromEntries(errors) : undefined;
};
