import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useMemo } from "react";

import { FieldComponent } from "./field-component";
import { fieldComponentGroups, fieldComponentsDroppableId } from "../constants";
import { FieldTemplate } from "../types";

type FieldComponentsProps = {
  disabled?: boolean;
  excluding?: FieldTemplate[];
};

const FieldComponents = ({ disabled, excluding }: FieldComponentsProps) => {
  const filteredFieldComponentGroups = useMemo(
    () =>
      fieldComponentGroups
        .map((g) => ({
          ...g,
          components: g.components.filter(
            (c) => !excluding?.some((e) => e.type === c.type),
          ),
        }))
        .filter((g) => g.components.length > 0),
    [excluding],
  );

  return (
    <div className="w-[25.6rem] px-[0.8rem] py-[1.6rem] overflow-auto">
      <h2 className="text-paragraph font-semibold font-poppins pl-[1.6rem] mb-[1.6rem]">
        Components
      </h2>
      <div className="border-y border-neutral-100 my-[1.6rem]" />
      <Droppable droppableId={fieldComponentsDroppableId} isDropDisabled>
        {(provided) => (
          <div ref={provided.innerRef} className="flex flex-col gap-medium">
            {filteredFieldComponentGroups.map(({ name, components }) => (
              <div className="flex flex-col gap-small">
                <h3 className="text-ui text-neutral-600 font-semibold pl-[1.6rem]">
                  {name}
                </h3>
                <div className="flex flex-col gap-small">
                  {components.map((template, index) => (
                    <Draggable
                      key={template.type}
                      draggableId={`field-component-${template.type}`}
                      isDragDisabled={disabled}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => (
                        <>
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={
                              snapshot.isDragging
                                ? {
                                    ...draggableProvided.draggableProps.style,
                                    transform:
                                      snapshot.isDropAnimating &&
                                      !snapshot.draggingOver
                                        ? undefined
                                        : draggableProvided.draggableProps.style
                                            ?.transform,
                                  }
                                : undefined
                            }
                          >
                            <FieldComponent
                              type={template.type}
                              isDragging={snapshot.isDragging}
                              disabled={disabled}
                            />
                          </div>
                          {snapshot.isDragging && (
                            <FieldComponent type={template.type} disabled />
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export { FieldComponents };
