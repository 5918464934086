import React from "react";

import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Modal } from "@smart/itops-ui-dom";

type NoFormsModalProps = {
  open: boolean;
  onSubmit: () => void;
};
export const NoFormsModal = ({ open, onSubmit }: NoFormsModalProps) => {
  const { hostType, host } = useSmokeballApp();
  const formBuilderText = `${hostType === "web" ? "Settings" : "File"} > Intake Forms`;
  const smokeballUrl = "smokeball://intakeforms/";

  const handleOpenFormBuilder = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    if (hostType === "web") {
      host?.webNavigate("intake-form-builder");
      onSubmit();
    } else if (hostType === "windows") {
      window.open(smokeballUrl);
    }
  };

  const formBuilderLink =
    host?.isEarlierThanVersion({ major: 9, minor: 5 }) && hostType !== "web" ? (
      formBuilderText
    ) : (
      <a
        data-testid="form-builder-url"
        href={smokeballUrl}
        onClick={handleOpenFormBuilder}
      >
        {formBuilderText}
      </a>
    );

  return (
    <Modal
      header={{
        icon: {
          library: "lucide",
          name: "AlertCircle",
          variant: "header",
        },
        text: "No active intake forms available",
      }}
      footer={{
        right: [
          {
            text: "Ok",
            variant: "save",
            onClick: onSubmit,
          },
        ],
      }}
      open={open}
      closeOptions={{
        escapeKey: false,
        clickOutside: false,
      }}
    >
      <p>
        There are no active Intake Forms available for this matter. Please go to{" "}
        {formBuilderLink} to create and activate a form.
      </p>
    </Modal>
  );
};
