import { Placement, Side, Alignment } from "@floating-ui/react";
import { clsx } from "clsx";

export type TooltipVariant = "label" | "paragraphy";

const getAlignmentClassNames = ({
  variant,
  placement,
}: {
  variant: TooltipVariant;
  placement: Placement;
}) => {
  const [side, alignment = "center"] = placement.split("-") as [
    Side,
    Alignment | undefined,
  ];
  const isLabel = variant === "label";
  const leftRight = {
    start: isLabel ? "mt-[-0.7rem]" : "mt-[-1.4rem]",
    center: undefined,
    end: isLabel ? "mb-[-0.7rem]" : "mb-[-1.4rem]",
  };
  const topBottom = {
    start: isLabel ? "ml-[-0.7rem]" : "ml-[-0.9rem]",
    center: undefined,
    end: isLabel ? "mr-[-0.7rem]" : "mr-[-0.9rem]",
  };
  const classNames = {
    top: topBottom,
    bottom: topBottom,
    left: leftRight,
    right: leftRight,
  };

  return classNames[side][alignment];
};

export const getTooltipClassNames = ({
  variant,
  placement,
}: {
  variant: TooltipVariant;
  placement: Placement;
}) =>
  clsx(
    getAlignmentClassNames({
      variant,
      placement,
    }),
    "flex flex-col gap-small max-w-[32rem] rounded bg-black/90 text-white p-[1.6rem]",
    variant === "label" && "py-[0.8rem]",
  );
