import styled from "@emotion/styled";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { Icon } from "@smart/itops-components-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import { Tabs } from "@smart/itops-smokeball-components-dom";

import { ClientMessages } from "./client-message";
import { GenerateHTML } from "./generate-html";
import { PracticeAreas } from "./practice-areas";

const ViewWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background.accent};
  height: 100vh;
  overflow: scroll;

  h1 {
    font-size: ${(props) => props.theme.fontSize.subHeading};
    font-weight: 400;
    margin-top: 0;

    .title-icon {
      color: ${(props) => props.theme.palette.primary.base};
    }
  }

  p.description {
    margin-top: 0;
  }
`;

export const SettingsView = () => {
  const { leadPro } = useFeatureFlags();
  const availableTabs = [
    leadPro && "generate-html",
    "client-messages",
    "practice-areas",
  ].filter(Boolean) as string[];

  return (
    <ViewWrapper>
      <h1>
        <Icon className="title-icon" name="form" size={22} /> Intake Forms
      </h1>
      <Tabs
        keys={availableTabs}
        initial={availableTabs[0]}
        labels={{
          "generate-html":
            loadLocale().country === "US" ? "Web Inquiry" : "Web Enquiry",
          "client-messages": "Client Messages",
          "practice-areas": "Practice Areas",
        }}
        views={{
          "generate-html": GenerateHTML,
          "client-messages": ClientMessages,
          "practice-areas": PracticeAreas,
        }}
      />
    </ViewWrapper>
  );
};
