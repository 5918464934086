import { Outlet } from "react-router-dom";

import { GlobalStyles, ThemeProvider } from "@smart/itops-components-dom";
import { SmokeballProvider } from "@smart/itops-smokeball-app-dom";
import {
  SmokeballStyles,
  smokeballTheme,
} from "@smart/itops-smokeball-components-dom";
import { Debug } from "@smart/itops-smokeball-components-dom/src/debug";
import {
  ActAsProvider,
  ClientProvider,
  EventCapturer,
  ToastProvider,
  UserProvider,
} from "@smart/manage-hooks-dom";

const Embedded = () => (
  <ThemeProvider theme={smokeballTheme}>
    <GlobalStyles />
    <SmokeballStyles />
    <SmokeballProvider observe={["matters", "layouts", "contacts", "staff"]}>
      <UserProvider isEmbeddedPage>
        <ClientProvider>
          <EventCapturer>
            <ToastProvider>
              <ActAsProvider>
                <Outlet />
              </ActAsProvider>
            </ToastProvider>
          </EventCapturer>
          <Debug />
        </ClientProvider>
      </UserProvider>
    </SmokeballProvider>
  </ThemeProvider>
);

export { Embedded };
