/* eslint-disable react/no-array-index-key */
import clsx from "clsx";
import { LegacyRef } from "react";
import { twMerge } from "tailwind-merge";

import { dropdownBase } from "./class-names";
import { DropdownPart, DropdownPartProps } from "./dropdown-part";
import { SearchInput } from "../input/search"; // Import from the file to avoid circular dependency

type Item = DropdownPartProps & { ref?: LegacyRef<HTMLDivElement> };

type VariantItems = {
  default: Item[];
  divided: Item[][];
  grouped: { heading: string; items: Item[] }[];
};

type Variant = keyof VariantItems;

type SearchProps = {
  searchValue?: string;
  onSearch?: (value: string) => void;
};

type DropdownProps<V extends Variant = Variant> = {
  className?: string;
  variant?: V;
  items: VariantItems[V];
} & SearchProps;

const Search = ({ searchValue, onSearch }: SearchProps) => {
  if (!onSearch) return null;

  return (
    <div className="mx-[1.6rem] mt-[0.4rem] mb-[0.8rem]">
      <SearchInput
        value={searchValue}
        onChange={(e) => onSearch(e.target.value)}
        onClear={(e) => {
          e.preventDefault();
          onSearch("");
        }}
      />
    </div>
  );
};

export const DefaultDropdown = ({
  items,
  className,
  searchValue,
  onSearch,
}: {
  items: VariantItems["default"];
  className?: string;
} & SearchProps) => (
  <div className={twMerge(clsx(dropdownBase, className))}>
    <Search searchValue={searchValue} onSearch={onSearch} />
    {items.map(({ ref, ...item }) => (
      <div ref={ref} key={item.label}>
        <DropdownPart {...item} />
      </div>
    ))}
  </div>
);

export const DividedDropdown = ({
  items,
  className,
  searchValue,
  onSearch,
}: {
  items: VariantItems["divided"];
  className?: string;
} & SearchProps) => (
  <div className={twMerge(clsx(dropdownBase, className))}>
    <Search searchValue={searchValue} onSearch={onSearch} />
    {items.map((group, index) => (
      <div key={index}>
        {group.map(({ ref, ...item }) => (
          <div ref={ref} key={item.label}>
            <DropdownPart {...item} />
          </div>
        ))}
        {index !== items.length - 1 && (
          <div className="mx-[1.6rem] my-[1rem] border-solid border-t border-neutral-100" />
        )}
      </div>
    ))}
  </div>
);

export const GroupedDropdown = ({
  items,
  className,
  searchValue,
  onSearch,
}: {
  items: VariantItems["grouped"];
  className?: string;
} & SearchProps) => (
  <div
    className={twMerge(
      clsx(dropdownBase, className, "flex flex-col gap-small"),
    )}
  >
    <Search searchValue={searchValue} onSearch={onSearch} />
    {items.map((group, index) => (
      <div key={index}>
        {group.heading && (
          <h3 className="text-ui font-semibold px-[1.6rem] pt-[1rem] pb-[0.5rem] text-neutral-600">
            {group.heading}
          </h3>
        )}
        {group.items.map(({ ref, ...item }) => (
          <div ref={ref} key={item.label}>
            <DropdownPart {...item} />
          </div>
        ))}
      </div>
    ))}
  </div>
);

export const Dropdown = ({ variant = "default", ...rest }: DropdownProps) => {
  const variants = {
    default: DefaultDropdown,
    divided: DividedDropdown,
    grouped: GroupedDropdown,
  };

  const Component = variants[variant];

  return <Component {...(rest as any)} />;
};

export type { DropdownPartProps } from "./dropdown-part";
