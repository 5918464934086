import { useState } from "react";

import { FailureMessage, Modal } from "@smart/itops-sb-design-system-dom";
import { buildLogProps } from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

export type SubmissionViewSyncProps = {
  selected?: MatterSubmission;
  onConfirm: (submission: MatterSubmission) => Promise<void>;
  onClose: () => void;
};

export const SubmissionViewSync = ({
  selected,
  onConfirm,
  onClose,
}: SubmissionViewSyncProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();

  const onSubmit = async () => {
    setHasError(undefined);
    setIsLoading(true);
    try {
      if (!selected) throw new Error(`Please select a form`);

      await onConfirm(selected);
      onClose();
    } catch (e) {
      const { logMessage, errorProps } = buildLogProps(e);
      setHasError(`${logMessage} - ${errorProps.message}`);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      dataTestId="sync-responses-modal"
      header={{
        icon: "solidFloppyDisk",
        iconBgColor: "bg-cyan-140",
        text: "Save Responses",
      }}
      open={!!selected}
      onClose={onClose}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: "Yes, save",
            variant: "primaryDefault",
            onClick: onSubmit,
          },
        ],
      }}
      loading={isLoading}
    >
      <p>Saving response will override any existing data on this matter.</p>
      <p>Are you sure you want to save the responses?</p>
      {hasError && <FailureMessage action="save responses" title={hasError} />}
    </Modal>
  );
};
