import { MutationResult, MutationTuple } from "@apollo/client";

import {
  rankBetween,
  Reorderable,
  reorderItem,
  ReorderItemProps,
  ReorderItemsProps,
} from "@smart/itops-utils-basic";

export type ReorderHookProps<D, V> = {
  extraFields: V;
  mutation: () => MutationTuple<D, { uri: string; order: string } & V>;
};

export type ReorderHookResult<D, V> = {
  onMove: (props: ReorderItemProps<Reorderable & { values: V }>) => void;
  onGroupMove: (props: ReorderItemsProps<Reorderable & { values: V }>) => void;
  result: MutationResult<D>;
};

export const useReorder = <E, R>({
  extraFields,
  mutation,
}: ReorderHookProps<E, R>): ReorderHookResult<E, R> => {
  const [update, result] = mutation();

  return {
    onMove: (props) => {
      const order = reorderItem(props);
      if (order) {
        update({
          variables: { ...extraFields, order, uri: props.item.uri },
        }).catch(console.error);
      }
    },
    onGroupMove: (props) => {
      const { items, index, reorderedItems } = props;
      const updatePromises: Promise<any>[] = [];

      reorderedItems.reduce(
        (prevOrder, item) => {
          const order = prevOrder.order
            ? rankBetween({
                after: prevOrder.order,
                before: items[index]?.values.order,
              })
            : reorderItem({ items, index, item });

          if (order) {
            updatePromises.push(
              update({
                variables: { ...extraFields, order, uri: item.uri },
              }),
            );
          }

          return { order };
        },
        { order: undefined } as { order: string | undefined },
      );

      Promise.all(updatePromises).catch(console.error);
    },
    result,
  };
};
