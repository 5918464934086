import { get, common } from "@smokeballdev/smokeball-client-sdk";
import { useEffect, useMemo, useState } from "react";

import { loadEnv } from "@smart/bridge-env-dom";

import { useAuth } from "./auth";
import { useCommunicate } from "./communicate";
import { useContacts } from "./contacts";
import { useCorrespondence } from "./correspondence";
import { useFiles } from "./files";
import { useHost } from "./host";
import { useLayouts } from "./layouts";
import { useMatters } from "./matters";
import { useRelationships } from "./relationships";
import { useRoles } from "./roles";
import { useStaff } from "./staff";
import { UseSmokeballAppOptions } from "../types";

const client: common.Api | null = get();

export const hasSDKClient = () => !!client;

export const useSDKClient = (options?: UseSmokeballAppOptions) => {
  const [isInit, setIsInit] = useState(!!client?.context);
  const hostType = useMemo((): "windows" | "web" | "local" | undefined => {
    if (!client) return "local";

    const constructor = client.constructor as {
      inApp?: () => boolean;
      inMock?: () => boolean;
      inWeb?: () => boolean;
    };
    if (constructor.inApp?.()) return "windows";
    if (constructor.inWeb?.()) return "web";
    if (constructor.inMock?.()) return "local";

    return undefined;
  }, [client]);

  useEffect(() => {
    if (!isInit && client && client.init) {
      client
        .init({ clientId: loadEnv("SmokeballClientId") })
        .then(() => setIsInit(true))
        .catch((err) => {
          console.error("Failed to init smokeball SDK client", err);
        });
    }
  }, [isInit]);

  const useSDKApiOptions = {
    client,
    hostType,
    isInit,
    observe: options?.observe,
  };

  return {
    hostType,
    context: isInit && client ? client.context : undefined,
    communicate: useCommunicate(useSDKApiOptions),
    correspondence: useCorrespondence(useSDKApiOptions),
    matters: useMatters(useSDKApiOptions),
    layouts: useLayouts(useSDKApiOptions),
    contacts: useContacts(useSDKApiOptions),
    roles: useRoles(useSDKApiOptions),
    auth: useAuth(useSDKApiOptions),
    host: useHost(useSDKApiOptions),
    staff: useStaff(useSDKApiOptions),
    relationships: useRelationships(useSDKApiOptions),
    files: useFiles(useSDKApiOptions),
  };
};

export type SmokeballAppClient = ReturnType<typeof useSDKClient>;
