import { envKeys } from "@smart/bridge-env-types-basic";
import { tableKeys, tables } from "@smart/bridge-resources-basic";
import { buildDynamoStreamHandlerDef } from "@smart/itops-constructs-basic";

export const changeProcessorLambda = buildDynamoStreamHandlerDef({
  envKeys,
  tables,
  tableKeys,
  handler: {
    deployable: "change-processor",
    pkg: "@smart/manage-change-processor-lambda",
    env: [],
  } as const,
});
