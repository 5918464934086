import { RenderLeafProps } from "slate-react";

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  let display = children;
  if (leaf.bold) display = <strong>{display}</strong>;
  if (leaf.italic) display = <em>{display}</em>;

  return <span {...attributes}>{display}</span>;
};

export { Leaf };
