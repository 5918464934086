import {
  ApolloClient,
  NormalizedCacheObject,
  OperationVariables,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useEffect, useMemo } from "react";

import { useCachedClient } from "../cached-client";

type FactoryOptions<D, I> = { map?: (d: D) => I };

type QueryOptions<D> = {
  client?: ApolloClient<NormalizedCacheObject>;
  reloadOnVisible?: boolean;
  onResult?: (data: D) => void;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
  pollInterval?: number;
};

export const useQueryFactory =
  <D, V extends OperationVariables, I>(
    queryDocument: TypedDocumentNode<D, V>,
    factoryOptions?: FactoryOptions<D, I>,
  ) =>
  (variables: V, options?: QueryOptions<D>) => {
    const query = useQuery(queryDocument, {
      variables,
      client: options?.client,
      onCompleted: options?.onResult,
      fetchPolicy: options?.fetchPolicy || "network-only",
      nextFetchPolicy:
        options?.nextFetchPolicy || options?.fetchPolicy || "cache-and-network",
      skip: options?.skip,
      pollInterval: options?.pollInterval,
    });

    useEffect(() => {
      const handler = () => {
        if (!document.hidden) {
          query.refetch().catch(console.error);
        }
      };

      if (options?.reloadOnVisible) {
        document.addEventListener("visibilitychange", handler);
      }

      return () => document.removeEventListener("visibilitychange", handler);
    }, [!!options?.reloadOnVisible]);

    const result = useMemo(
      () =>
        factoryOptions?.map && query.data
          ? factoryOptions.map(query.data)
          : undefined,
      [factoryOptions?.map, query.data],
    );

    return {
      ...query,
      result,
    };
  };

export const useCachedQueryFactory =
  <D, V extends OperationVariables, I>(
    queryDocument: TypedDocumentNode<D, V>,
    factoryOptions?: FactoryOptions<D, I>,
  ) =>
  (variables: V, options?: QueryOptions<D>) => {
    const client = useCachedClient();

    return useQueryFactory(queryDocument, factoryOptions)(variables, {
      ...options,
      client,
    });
  };

export const useConditionalCachedQueryFactory =
  <D, V extends OperationVariables, I>(
    queryDocument: TypedDocumentNode<D, V>,
    factoryOptions?: FactoryOptions<D, I>,
    shouldUseCachedClient?: (variables: V) => boolean,
  ) =>
  (variables: V, options?: QueryOptions<D>) => {
    const client = useCachedClient();

    return useQueryFactory(queryDocument, factoryOptions)(variables, {
      ...options,
      client:
        shouldUseCachedClient && shouldUseCachedClient(variables)
          ? client
          : undefined,
    });
  };
