import { SDKError } from "@smart/itops-utils-basic";

import { MessageRequest, UseSDKApiOptions } from "../types";

export const useCommunicate = ({ client, isInit }: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  return {
    createMessage: (request: MessageRequest) => {
      try {
        return client.communicate.createMessage({
          ...request,
          /**
           * At the moment there is a bug in smokeball app making
           * contactIds and staffIds not optional. This should be removed
           * once the bug is fixed.
           */
          contactIds: request.contactIds || [],
          staffIds: request.staffIds || [],
        });
      } catch (error) {
        throw new SDKError(error, {
          action: "communicate.createMessage",
          input: request,
        });
      }
    },
    sendMessage: (request: MessageRequest) => {
      try {
        return client.communicate.sendMessage(request);
      } catch (error) {
        throw new SDKError(error, {
          action: "communicate.sendMessage",
          input: request,
        });
      }
    },
  };
};
