import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { Alert } from "@smart/itops-sb-design-system-dom";

type BannerFlagKey = "tabBannerInfo" | "builderBannerInfo";

const dismissedBannerKey = (id: string) => `dismissed-intake-tab-banner.${id}`;

const useBannerText = (flagKey: BannerFlagKey) => {
  const [dismissed, setDismissed] = useState(false);
  const flags = useFeatureFlags();

  const bannerFlag = flags[flagKey];
  useEffect(() => {
    if (bannerFlag?.id) {
      setDismissed(!!localStorage.getItem(dismissedBannerKey(bannerFlag?.id)));
    }
  }, [bannerFlag?.id]);

  if (!bannerFlag || !bannerFlag.id || !bannerFlag.text) return null;
  if (dismissed) return null;

  return {
    text: bannerFlag.text,
    style: bannerFlag.style,
    onDismiss: () => {
      if (bannerFlag?.id) {
        localStorage.setItem(dismissedBannerKey(bannerFlag.id), "true");
      }
      setDismissed(true);
    },
  };
};

type BannerProps = {
  flagKey: BannerFlagKey;
};

export const Banner = ({ flagKey }: BannerProps) => {
  const banner = useBannerText(flagKey);
  if (!banner) return null;

  return (
    <Alert
      variant="banner"
      palette="branding"
      content={banner.text}
      onClose={banner.onDismiss}
    />
  );
};
