import { loadLocale } from "@smart/itops-locale-dom";

import { AreaEditor } from "./area-editor";
import { AreaInfo } from "../../types";

export type AreasContainerProps = Record<
  "states" | "practiceAreas",
  {
    available: AreaInfo[] | null | undefined;
    selected: AreaInfo[] | null | undefined;
    save: (areas: string[]) => Promise<void>;
    loading?: boolean;
  }
>;

export const AreasContainer = ({
  states,
  practiceAreas,
}: AreasContainerProps) => {
  const localeTerms = loadLocale().terms;

  return (
    <>
      <p className="description">
        Configure your firm&apos;s primary practice areas and locations.
      </p>
      <AreaEditor
        singular={localeTerms.state.singular}
        plural={localeTerms.state.plural}
        title={localeTerms.state.title}
        {...states}
      />
      <AreaEditor
        singular="a practice area"
        plural="practice areas"
        title="Practice Areas"
        {...practiceAreas}
      />
    </>
  );
};
