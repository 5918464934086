export const alertBannerSectionPalettes = {
  default: {
    iconName: "solidCircleInfo",
    iconColor: "text-blue-660",
    borderColor: "bg-blue-660",
    background: "bg-cyan-40",
  },
  success: {
    iconName: "solidCircleCheck",
    iconColor: "text-green-800",
    borderColor: "bg-green-800",
    background: "bg-green-100",
  },
  warning: {
    iconName: "solidTriangleExclamation",
    iconColor: "text-yellow-850",
    borderColor: "bg-yellow-850",
    background: "bg-yellow-150",
  },
  destructive: {
    iconName: "solidDiamondExclamation",
    iconColor: "text-red-650",
    borderColor: "bg-red-650",
    background: "bg-red-50",
  },
  branding: {
    iconName: "solidCircleInfo",
    iconColor: "text-orange-600",
    borderColor: "bg-orange-600",
    background: "bg-orange-100",
  },
} as const;

export const alertToastPalettes = {
  default: {
    iconName: "solidCircleInfo",
    iconColor: "text-black",
    background: "bg-cyan-240",
    actionVariant: "subtle",
    closeButtonVariant: "secondarySubtle",
  },
  success: {
    iconName: "solidCircleCheck",
    iconColor: "text-black",
    background: "bg-green-400",
    actionVariant: "subtle",
    closeButtonVariant: "secondarySubtle",
  },
  warning: {
    iconName: "solidTriangleExclamation",
    iconColor: "text-black",
    background: "bg-yellow-450",
    actionVariant: "subtle",
    closeButtonVariant: "secondarySubtle",
  },
  destructive: {
    iconName: "solidDiamondExclamation",
    iconColor: "text-white",
    background: "bg-red-550",
    actionVariant: "inverted",
    closeButtonVariant: "invertedSubtle",
  },
  branding: {
    iconName: "solidCircleInfo",
    iconColor: "text-white",
    background: "bg-orange-500",
    actionVariant: "inverted",
    closeButtonVariant: "invertedSubtle",
  },
} as const;
