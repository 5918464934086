import { useNavigate } from "react-router-dom";

import { Page } from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { useLoadSubmissions } from "@smart/manage-gql-client-dom";

import { SubmissionList } from "../components";

const Submissions = () => {
  const submissions = useLoadSubmissions({});
  const navigate = useNavigate();

  return (
    <Page text="Recent Submissions">
      <SubmissionList
        submissions={submissions.result}
        select={(uri) => navigate(`/submissions/${extractId(uri)}`)}
        result={submissions}
      />
    </Page>
  );
};

export { Submissions };
