import { FC, useEffect, useState } from "react";
import { DefaultValues, FieldValues } from "react-hook-form";

import { AfterSubmitAction, Updatable } from "@smart/itops-hooks-dom";

import { Mode, UpdateFormProps } from "./update";
import { ButtonRow, IconButton } from "../buttons";

type CreateFormProps<D, V extends Updatable, S extends FieldValues> = {
  buildLocators: () => Omit<V, "fields">;
  create: string;
  submit: string;
  cancel: string;
  submitWithAction?: string;
  afterSubmitActionType?: AfterSubmitAction;
  defaultValues: DefaultValues<S>;
  fieldsToKeep?: (keyof S)[];
  onCreate?: () => void;
  onDone?: () => void;
  form: FC<UpdateFormProps<D, V, S>>;
  mode?: Mode;
};

const CreateForm = <D, V extends Updatable, S extends FieldValues>({
  buildLocators,
  create,
  form: Form,
  defaultValues,
  fieldsToKeep,
  mode,
  onCreate,
  onDone,
  ...props
}: CreateFormProps<D, V, S>) => {
  const [locators, setLocators] = useState<Omit<V, "fields"> | undefined>();
  const [valuesToKeep, setValuesToKeep] = useState<Partial<DefaultValues<S>>>(
    {},
  );

  useEffect(() => {
    // Try to scroll to bottom when open the create form
    if (locators) {
      window.scrollTo({ top: document.body.offsetHeight, behavior: "smooth" });
    }
  }, [!!locators]);

  return locators ? (
    <Form
      locators={locators}
      mode={mode}
      {...props}
      defaultValues={{
        ...defaultValues,
        ...valuesToKeep,
      }}
      cancelled={() => {
        if (onDone) onDone();
        setLocators(undefined);
      }}
      submitted={({ variables, afterSubmitActionType, reset }) => {
        const latestValuesToKeep =
          fieldsToKeep?.reduce<Partial<DefaultValues<S>>>(
            (values, field) => ({
              ...values,
              [field]: (variables.fields as S)[field],
            }),
            {},
          ) || {};
        setValuesToKeep(latestValuesToKeep);
        reset({
          ...defaultValues,
          ...latestValuesToKeep,
        });

        if (onDone) onDone();

        switch (afterSubmitActionType) {
          case "create-more":
            return setLocators(buildLocators());
          default:
            return setLocators(undefined);
        }
      }}
    />
  ) : (
    <ButtonRow>
      <IconButton
        name="add"
        text={create}
        onClick={() => {
          setLocators(buildLocators());
          if (onCreate) onCreate();
        }}
      />
    </ButtonRow>
  );
};

export { CreateForm, CreateFormProps };
