import { IconButton } from "@smart/itops-components-dom";
import { useCopyForm } from "@smart/manage-gql-client-dom";

type CopyFormProps = {
  formUri: string;
  onDone?: () => void;
};

const CopyForm = ({ formUri, onDone }: CopyFormProps) => {
  const [update, { loading, error }] = useCopyForm();

  const onClick = async () => {
    await update({ variables: { uri: formUri } });
    if (onDone) onDone();
  };

  return (
    <IconButton
      name="form"
      text="Copy to my forms"
      onClick={onClick}
      title={error?.message}
      spin={loading}
      disabled={loading}
    />
  );
};

export { CopyForm, CopyFormProps };
