import { CenteredForm, Loading } from "@smart/itops-components-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import {
  useLoadMatterTypeCategories,
  useLoadSetting,
  useQueryMatterTypeGroups,
  useUpdateFormMatterTypes,
} from "@smart/manage-gql-client-dom";

import { FormTabProps } from "./types";
import { EditMatterTypes } from "../edit-matter-types";

const { states } = loadLocale();

const FormMatterTypes = ({
  mode,
  form: {
    uri,
    values: { matterTypes, category },
  },
}: FormTabProps) => {
  const { result: settings } = useLoadSetting({});
  const categories = useLoadMatterTypeCategories({
    teamUri: settings?.teamUri,
  });
  const loadGroups = useQueryMatterTypeGroups();
  const [update, { loading }] = useUpdateFormMatterTypes();

  return (
    <CenteredForm>
      <EditMatterTypes
        formUri={uri}
        readOnly={mode === "view"}
        categories={categories.result || []}
        states={states}
        type={category}
        matterTypes={matterTypes}
        loadGroups={loadGroups}
        update={update}
      />
      {loading && <Loading title="Updating" />}
    </CenteredForm>
  );
};

export { FormMatterTypes };
