import { z } from "zod";

import {
  updateFormFactory,
  Input,
  Label,
  FileField,
} from "@smart/itops-components-dom";
import { useUpdateTeam } from "@smart/manage-gql-client-dom";
import { formsApi, useUser } from "@smart/manage-hooks-dom";

const teamSchema = z.object({
  name: z.string(),
  address: z.string(),
  phone: z.string(),
  picture: z.string().optional(),
});

const EditTeam = updateFormFactory({
  factoryProps: {
    mutation: useUpdateTeam,
    schema: teamSchema,
    file: {
      prop: "picture",
      key: () => "team-logo",
      tokenHook: useUser,
      urlHook: formsApi.asset,
    },
  },
  render: ({
    form: {
      control,
      register,
      formState: { errors },
    },
    result: { loading },
  }) => (
    <>
      <Label
        name="name"
        text="Firm Name"
        error={errors.name?.message}
        loading={loading}
      >
        <Input {...register("name")} />
      </Label>
      <Label name="address" text="Address">
        <Input {...register("address")} />
      </Label>
      <Label name="phone" text="Phone Number">
        <Input {...register("phone")} />
      </Label>
      <Label name="file" text="Logo">
        <FileField
          control={control}
          name="file"
          placeholder="Upload your firm logo"
        />
      </Label>
    </>
  ),
});

export { EditTeam };
