import { isTestEnv } from "./env";

export const createDownloadUrl = (
  contents: object,
  replacer?: (key: string, value: any) => any,
) => {
  const blob = new Blob([JSON.stringify(contents, replacer)], {
    type: "application/json",
  });

  return isTestEnv() ? (blob as unknown as string) : URL.createObjectURL(blob);
};

declare global {
  interface Window {
    downloads?: { contents: any; name: string }[];
  }
}

export const downloadFileUrl = (fileUrl: string, fileName: string) => {
  if (isTestEnv()) {
    window.downloads = window.downloads || [];
    window.downloads.push({ contents: fileUrl, name: fileName });
    return;
  }

  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
