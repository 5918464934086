import { Droppable } from "@hello-pangea/dnd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { archie } from "@smart/bridge-images-dom";
import { aiFillSectionUri } from "@smart/bridge-types-basic";
import {
  Badge,
  Button,
  Icon,
  Switch,
  Tooltip,
} from "@smart/itops-sb-design-system-dom";
import { FeatureInfo } from "@smart/itops-ui-dom";

import { EditSectionsModal } from "./edit-sections-modal";
import { NavItem, selectedClassNames } from "./nav-item";
import { sectionNavigationDroppableId } from "../constants";
import {
  EditableSection,
  GqlForm,
  UpdateAIFillSettings,
  UpdatedSection,
} from "../types";

type SectionNavigationProps = {
  form?: GqlForm | null;
  sections: EditableSection[];
  currentSectionUri: string;
  disabled?: boolean;
  loading: {
    form: boolean;
    sections: boolean;
  };
  setCurrentSectionUri: (sectionUri: string) => void;
  updateAllSections: (sections: UpdatedSection[]) => Promise<void>;
  setDroppedOnSectionUri: (sectionUri: string | undefined) => void;
  isDraggingItem: boolean;
  setIsValidSection: (isValid: boolean) => void;
  isValidSection: boolean;
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

const SectionNavigation = ({
  form,
  sections,
  currentSectionUri,
  disabled,
  loading,
  setCurrentSectionUri,
  updateAllSections,
  isDraggingItem,
  setDroppedOnSectionUri,
  setIsValidSection,
  isValidSection,
  aiFillSettings,
  updateAIFillSettings,
}: SectionNavigationProps) => {
  const { clientPopulateIntakeWithFile } = useFeatureFlags();
  const [sectionsModal, showSectionsModal] = useState(false);

  useEffect(() => {
    if (
      !aiFillSettings?.allowAiFill &&
      currentSectionUri === aiFillSectionUri
    ) {
      setCurrentSectionUri(sections[0].uri);
    }
  }, [!!aiFillSettings?.allowAiFill]);

  return (
    <div
      data-testid="sections-navigation"
      className="w-[32.4rem] px-[0.8rem] flex flex-col"
    >
      <div className="flex justify-between items-center gap-medium px-[1.6rem] mt-[1.6rem]">
        <h2 className="text-paragraph font-semibold text-neutral-850">
          Sections
        </h2>
        <Button
          variant="inlineDefault"
          text="Edit"
          disabled={loading.sections || disabled}
          onClick={() => showSectionsModal(true)}
        />
      </div>
      <div className="border border-neutral-100 border-solid my-[1.6rem]" />
      {clientPopulateIntakeWithFile && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          data-testid="ai-fill-section-nav"
          aria-disabled={!aiFillSettings?.allowAiFill}
          className={clsx(
            "flex items-center w-full h-[4rem] py-[0.4rem] mb-[1.6rem] rounded border border-transparent",
            aiFillSettings?.allowAiFill && "cursor-pointer hover:bg-cyan-140",
            currentSectionUri === aiFillSectionUri && selectedClassNames,
          )}
          onClick={() => {
            if (aiFillSettings?.allowAiFill)
              setCurrentSectionUri(aiFillSectionUri);
          }}
        >
          <FeatureInfo
            info={
              <div>
                <p className="mb-[1rem]">
                  <b>Meet the Autofill Assistant!</b>
                </p>
                <p className="mx-0 my-[0.5rem]">
                  It lets your clients automatically populate their intake form
                  by uploading relevant documents, which means faster form
                  completion!
                  <br />
                  <br />
                  Simply tell them which documents to upload.
                </p>
              </div>
            }
            featureName="new-autofill-assistant"
            placement="right"
          >
            <div className="flex gap-medium px-[1.6rem]">
              <img
                className={twMerge(
                  clsx(
                    "w-[3.2rem] opacity-50",
                    aiFillSettings?.allowAiFill && "opacity-100",
                  ),
                )}
                src={archie}
                alt="Archie"
              />
              <div className="flex justify-between items-center flex-1">
                <span
                  className={clsx(
                    "text-ui font-semibold whitespace-nowrap",
                    aiFillSettings?.allowAiFill
                      ? "text-neutral-600"
                      : "text-neutral-400",
                  )}
                >
                  Autofill Assistant
                </span>
                <Badge label="BETA" theme="pink" isRound isShade />
                <Tooltip
                  className="flex items-center"
                  text="Allows clients to automatically populate intake forms by uploading relevant documents."
                  placement="bottom"
                  offsetBy={7}
                >
                  <Icon className="text-blue-560" name="regularCircleInfo" />
                </Tooltip>
                <Switch
                  toggled={!!form?.aiFillSettings?.allowAiFill}
                  onClick={() =>
                    updateAIFillSettings({
                      allowAiFill: !form?.aiFillSettings?.allowAiFill,
                    })
                  }
                  dataTestId="ai-fill-toggle"
                  disabled={disabled || loading.form}
                />
              </div>
            </div>
          </FeatureInfo>
        </div>
      )}
      <Droppable
        droppableId={sectionNavigationDroppableId}
        type="GROUP"
        isDropDisabled={!isValidSection}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className="flex flex-col gap-[0.4rem]">
              {sections.map((section, index) => (
                <NavItem
                  key={section.uri}
                  section={section}
                  index={index}
                  loading={loading.sections}
                  currentSectionUri={currentSectionUri}
                  setCurrentSectionUri={setCurrentSectionUri}
                  isDraggingItem={isDraggingItem}
                  setDroppedOnSectionUri={setDroppedOnSectionUri}
                  setIsValidSection={setIsValidSection}
                />
              ))}
            </div>
            <span style={{ display: "none" }}>{provided.placeholder}</span>
          </div>
        )}
      </Droppable>
      <EditSectionsModal
        editSectionsModal={sectionsModal}
        onClose={() => showSectionsModal(false)}
        sections={sections}
        updateAllSections={updateAllSections}
        aiFillSettings={aiFillSettings}
        updateAIFillSettings={updateAIFillSettings}
      />
    </div>
  );
};

export { SectionNavigation, SectionNavigationProps };
