import { AlertBannerToast } from "./alert-banner-toast";
import { AlertSection } from "./alert-section";
import { AlertProps } from "./types";

export const Alert = ({ variant = "banner", ...variantProps }: AlertProps) => {
  const variants = {
    banner: AlertBannerToast,
    section: AlertSection,
    toast: AlertBannerToast,
  };

  const Component = variants[variant];

  return <Component variant={variant} {...variantProps} />;
};
