import { deleteButtonFactory } from "@smart/itops-components-dom";
import { useDeleteField } from "@smart/manage-gql-client-dom";

const DeleteField = deleteButtonFactory({
  icon: "cancel",
  confirmText: "Are you sure?",
  mutation: useDeleteField,
});

export { DeleteField };
