import { Page } from "@smart/itops-components-dom";
import { useLoadTeam } from "@smart/manage-gql-client-dom";

import { TeamView } from "../components/team";

const Team = () => {
  const team = useLoadTeam({});

  return (
    <Page
      text={team.result?.values.name || "About Your Firm"}
      label="About Your Firm"
    >
      <TeamView team={team.result} result={team} />
    </Page>
  );
};

export { Team };
