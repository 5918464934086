import { MessageTemplateOptions } from "./types";

const embedText = "Contact Us";

export const generateEmbedCode = ({
  link,
  text,
  embedOption = "button",
}: {
  link: string;
  text: string;
  embedOption?: "button" | "iframe";
}) => {
  const embedCode = {
    button: `<a href="${link}"
    style="
      background-color: #236ad6;
      border: none;
      color: white;
      padding: 8px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      border-radius: 4px;
      word-break: break-all;
    "
    target="_blank">${text}<svg
      style="margin-left: 12px; vertical-align: text-top"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 512 512"
    >
      <path
        transform="scale(1,-1) translate(0, -512)"
        fill="currentColor"
        d="M384 32l0 176c0 8 7 16 16 16 9 0 16-8 16-16l0-192c0-8-7-16-16-16l-384 0c-9 0-16 8-16 16l0 384c0 9 7 16 16 16l192 0c9 0 16-7 16-16 0-8-7-16-16-16l-176 0 0-352z m-261 69c-6-7-16-7-22 0-7 6-7 16 0 22l384 384c6 7 16 7 22 0 7-6 7-16 0-22z m357 379l-176 0c-9 0-16 7-16 16 0 9 7 16 16 16l192 0c9 0 16-7 16-16l0-192c0-9-7-16-16-16-9 0-16 7-16 16z"
      /></svg
  ></a>`,
    iframe: `<iframe src="${link}"
  style="
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  "
  id="matter-intake-iframe"
  name="matter-intake-iframe"
></iframe>`,
  };

  return {
    text: `${text}: ${link}`,
    html: embedCode[embedOption],
  };
};

export const embed = ({ link, embedOption }: MessageTemplateOptions) => ({
  link,
  ...generateEmbedCode({ link, text: embedText, embedOption }),
});
