import styled from "@emotion/styled";
import { FC } from "react";

import {
  AffectedBy,
  ConditionListItemProps,
  Effecting,
} from "./condition-list-item";
import { ConditionItem } from "./utils";
import { ItemType, itemTypeLabels } from "../../../types";
import { Condition } from "../conditions";

export const ConditionListWrapper = styled.div`
  --background: ${(props) => props.theme.scheme.grey.r5};
  background: var(--background);
  border: 1px solid ${(props) => props.theme.scheme.grey.r25};
  border-radius: 0.5rem;
  width: 50%;
  padding: 1.2rem 1.6rem;
  margin-bottom: 1.2rem;

  h4 {
    margin: 0.3rem 0;
  }

  p {
    margin: 0.3rem 0;
    line-height: 1.6;
  }
`;

type ConditionListProps = {
  conditions?: Condition[];
  setCurrentField: (field: ConditionItem | null) => void;
  itemType: ItemType;
};

type ConditionListFactoryProps = {
  title: (itemType: ItemType) => string;
  empty: (itemType: ItemType) => string;
  element: FC<ConditionListItemProps>;
};

const conditionListFactory =
  ({ title, empty, element: Element }: ConditionListFactoryProps) =>
  ({ conditions, setCurrentField, itemType }: ConditionListProps) => (
    <ConditionListWrapper>
      {conditions?.length ? (
        <h4>{title(itemType)}</h4>
      ) : (
        <p>{empty(itemType)}</p>
      )}
      {conditions?.map((condition) => (
        <Element
          key={condition.id}
          condition={condition}
          setCurrentField={setCurrentField}
        />
      ))}
    </ConditionListWrapper>
  );

export const AffectedByList = conditionListFactory({
  empty: (itemType) =>
    `This ${itemTypeLabels[itemType]} is always shown and no conditions affect this ${itemTypeLabels[itemType]}.`,
  title: (itemType) => `This ${itemTypeLabels[itemType]} is affected when:`,
  element: AffectedBy,
});

export const EffectingList = conditionListFactory({
  empty: (itemType) =>
    `This ${itemTypeLabels[itemType]} has no following conditions applied to it.`,
  title: (itemType) => `Answering this ${itemTypeLabels[itemType]} will:`,
  element: Effecting,
});
