import styled from "@emotion/styled";
import { useMemo, useState } from "react";

import {
  ContactResponses,
  SBContactType,
  createNewContactPlaceholder,
  defaultClientRoleId,
} from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import { Matter } from "@smart/itops-smokeball-app-dom";
import { Modal } from "@smart/itops-ui-dom";
import {
  backoff,
  entriesOf,
  fromEntries,
  groupBy,
  sortField,
} from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  mapToMatterSubmission,
  useLazyLoadContacts,
  useLoadMatterLayouts,
  useQueryMatterFields,
  useQuerySubmission,
  useUpdateResponseExistingItemIds,
  useUpdateResponseSyncStatus,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import {
  processResponses,
  useRoleContacts,
  useSyncToMatter,
  useUser,
} from "@smart/manage-hooks-dom";

import { ContactSelect } from "./contact-select";

const getIndexedName = (name: string, index: number) => `${name}-${index}`;

const ContactReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

type RoleToReview = {
  roleName: string;
  contact: Partial<Record<SBContactType, Record<string, any>>>;
  index: number;
  responseUris: Set<string>;
};

export type ContactReviewProps = {
  loading: boolean;
  onClose: () => void;
  matter: Matter | undefined;
  rolesAndRelationships: Record<string, { contactId: string }[]>;
  submission: MatterSubmission;
  sections: Gql.SectionFieldsFragment[] | undefined;
  groups: Gql.GroupFieldsFragment[] | undefined;
  fields: Gql.FieldFieldsFragment[] | undefined;
  formMatterTypeRepresentatives: string[] | undefined;
  searchContacts: ReturnType<typeof useLazyLoadContacts>;
  updateResponseExistingItemIds: ReturnType<
    typeof useUpdateResponseExistingItemIds
  >[0];
  updateResponseSyncStatus: ReturnType<typeof useUpdateResponseSyncStatus>[0];
  querySubmission: ReturnType<typeof useQuerySubmission>;
  sync: ReturnType<typeof useSyncToMatter>["sync"];
  onSuccess: () => void;
  onFailure: () => void;
  openContact: ((id: string) => void) | undefined;
};

export const ContactReview = ({
  loading,
  onClose,
  matter,
  rolesAndRelationships,
  submission,
  sections,
  groups,
  fields,
  formMatterTypeRepresentatives,
  searchContacts,
  updateResponseExistingItemIds,
  updateResponseSyncStatus,
  querySubmission,
  sync,
  onSuccess,
  onFailure,
  openContact,
}: ContactReviewProps) => {
  const { client } = useRoleContacts();
  const { user } = useUser();
  const [reviewResult, setReviewResult] = useState<Record<string, string>>({});
  const [isSaving, setIsSaving] = useState(false);
  const loadMatterFields = useQueryMatterFields();
  const [roles, setRoles] = useState<RoleToReview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const matterLayouts = useLoadMatterLayouts(
    {
      matterTypeIds: [matter?.matterTypeId || ""],
    },
    { skip: !matter?.matterTypeId },
  );
  const roleNameToDisplayNameMap = useMemo<Record<string, string>>(() => {
    if (!matterLayouts.result?.length) return {};

    return matterLayouts.result.reduce(
      (aggr, { id: roleName, name: displayName }) => ({
        ...aggr,
        [roleName]: displayName,
      }),
      {},
    );
  }, [matterLayouts.result]);

  useAsync(async () => {
    setIsLoading(true);

    const groupedResponses = await processResponses({
      matter,
      groups: groups || [],
      fields: fields || [],
      sections: sections || [],
      submission,
      formMatterTypeRepresentatives,
      getLayoutFields: async ({ layoutId, providerId }) => {
        const matterFields = await loadMatterFields({
          matterLayoutId: layoutId,
          providerId,
          teamUri: user?.teamUri || "",
          preserved: true,
        });

        return matterFields.map((f) => ({
          ...f,
          possibleValues: f.possibleValues || undefined,
        }));
      },
    });

    const getContacts = (contact: ContactResponses) => {
      // We don't do duplication check for the staff of a company
      if (contact.hasRelation) return [{ company: contact.company }];
      return contact.group?.length ? contact.group : [contact];
    };

    const rolesToReview: RoleToReview[] = [];

    entriesOf(groupedResponses.roles).forEach(([roleName, contact]) => {
      getContacts(contact).forEach((c, index) => {
        rolesToReview.push({
          roleName,
          contact: c,
          index,
          responseUris: groupedResponses.responseUris.roles[roleName],
        });
      });
    });

    entriesOf(groupedResponses.relationships).forEach(
      ([roleName, relationshipItems]) => {
        entriesOf(relationshipItems).forEach(([relationshipId, contact]) => {
          getContacts(contact).forEach((c, index) => {
            const key = `${roleName}/${relationshipId}`;
            rolesToReview.push({
              roleName: key,
              contact: c,
              index,
              responseUris: groupedResponses.responseUris.relationships[key],
            });
          });
        });
      },
    );

    entriesOf(groupedResponses.layoutContacts).forEach(
      ([groupUri, inputContacts]) => {
        const group = groups?.find((g) => g.uri === groupUri);
        const layout = group?.values.layout;
        const field = group?.values.field;
        if (!layout || !field) return;
        const roleName = `${layout.displayName || layout.name}/${field.displayName || field.name}`;
        inputContacts.forEach((contact, index) => {
          rolesToReview.push({
            roleName,
            contact,
            index,
            responseUris:
              groupedResponses.responseUris.layoutContacts[groupUri],
          });
        });
      },
    );

    setRoles(rolesToReview);
    setIsLoading(false);
  }, [
    matter,
    sections,
    groups,
    fields,
    submission,
    formMatterTypeRepresentatives,
  ]);

  const onSubmit = async () => {
    setIsSaving(true);

    const rolesUsingExistingContact = entriesOf(
      groupBy(roles, (role) => role.roleName),
    )
      .map(([roleName, groupedRoles]) => {
        const existingItemIds = sortField(groupedRoles, {
          key: "index",
          dir: "asc",
        }).map((r) => reviewResult[getIndexedName(r.roleName, r.index)]);

        return {
          roleName,
          existingItemIds,
          responseUris: groupedRoles[0].responseUris,
        };
      })
      .filter(({ existingItemIds }) =>
        existingItemIds.some((id) => id && id !== createNewContactPlaceholder),
      );
    const responsesUsingExistingContact = rolesUsingExistingContact.flatMap(
      ({ responseUris, existingItemIds }) =>
        [...responseUris].map((uri) => ({ uri, existingItemIds })),
    );
    const allResponseUris = [
      ...new Set(roles.flatMap((r) => [...r.responseUris])),
    ];

    try {
      await Promise.all([
        ...responsesUsingExistingContact.map(({ uri, existingItemIds }) =>
          updateResponseExistingItemIds({
            variables: {
              uri,
              existingItemIds: JSON.stringify(existingItemIds),
            },
          }),
        ),
        ...allResponseUris.map((uri) =>
          updateResponseSyncStatus({
            variables: { uri, syncStatus: "reviewed" },
          }),
        ),
      ]);

      // Wait for the existing item ids to update
      const updatedSubmission = await backoff({
        run: () => querySubmission({ uri: submission.uri }),
        test: (found) => {
          if (!found?.responses.length) return false;

          const responsesMap = fromEntries(
            found.responses.map((r) => [r.uri, r]),
          );
          const isExistingItemIdsUpdated = rolesUsingExistingContact.every(
            ({ responseUris, existingItemIds }) =>
              [...responseUris].some(
                (uri) =>
                  responsesMap[uri]?.existingItemIds ===
                  JSON.stringify(existingItemIds),
              ),
          );
          const isSyncStatusUpdated = allResponseUris.every(
            (uri) => responsesMap[uri]?.syncStatus === "reviewed",
          );
          return isExistingItemIdsUpdated && isSyncStatusUpdated;
        },
        attempts: 4,
        base: 1000,
      })();

      if (updatedSubmission) {
        await sync({
          submission: mapToMatterSubmission(updatedSubmission),
          fields: fields || [],
          groups: groups || [],
          sections: sections || [],
          formMatterTypeRepresentatives,
        });
      }
      onSuccess();
    } catch (err) {
      console.error(err);
      onFailure();
    }
  };

  const isSubmitDisabled =
    loading ||
    isSaving ||
    roles.some((r) => !reviewResult[getIndexedName(r.roleName, r.index)]);

  return (
    <Modal
      header={{
        icon: { library: "lucide", name: "Info", variant: "action" },
        text: "Existing Contact Review",
      }}
      loading={loading || isSaving || isLoading}
      open
      onClose={onClose}
      footer={{
        right: [
          {
            text: "OK",
            disabled: isSubmitDisabled,
            variant: "save",
            onClick: onSubmit,
          },
        ],
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: onClose,
          },
        ],
      }}
    >
      <ContactReviewWrapper>
        {roles.map(({ roleName, contact, index }) => {
          const key = getIndexedName(roleName, index);
          const getRoleName = () => {
            const hasMultipleContacts =
              roles.filter((r) => r.roleName === roleName).length > 1;
            const isDefaultClientRole = roleName === defaultClientRoleId;
            const roleNameToUse = isDefaultClientRole
              ? client?.role.name || "Client"
              : roleName
                  .split("/")
                  .map((part) => roleNameToDisplayNameMap[roleName] || part)
                  .join("/");

            return hasMultipleContacts
              ? `${roleNameToUse} ${index + 1}`
              : roleNameToUse;
          };
          return (
            <ContactSelect
              key={key}
              roleName={getRoleName()}
              hasRoleContact={
                !!rolesAndRelationships[roleName]?.[index]?.contactId
              }
              contact={contact}
              selected={reviewResult[key]}
              onSelect={(selected) =>
                setReviewResult((result) => ({
                  ...result,
                  [key]: selected,
                }))
              }
              searchContacts={searchContacts}
              openContact={openContact}
            />
          );
        })}
      </ContactReviewWrapper>
    </Modal>
  );
};
