import { globalTeamUri } from "@smart/bridge-types-basic";

import { RuleEvaluators } from "../types";
import { hasRole } from "../utils";

export const form: RuleEvaluators["form"] = (
  user,
  action,
  { value: { teamUri, active } },
) => {
  // User can perform all actions on forms for their team
  if (teamUri === user.teamUri) return true;

  // All users can view active global forms
  if (teamUri === globalTeamUri && active && action === "view") return true;

  // Users with the global role can perform all actions on all forms
  if (hasRole(user, "CanUpdateGlobalIntakeForms")) return true;

  // Otherwise, no access
  return false;
};
