import { Theme } from "@emotion/react";
import styled, { StyledComponent } from "@emotion/styled";
import { DetailedHTMLProps, ElementType, FormHTMLAttributes } from "react";

export const Form = styled.form<{
  opacity?: number;
  background?: keyof Theme["palette"];
}>`
  --background: ${(props) =>
    props.theme.palette[props.background || "background"].base};
  background-color: var(--background);
  border-radius: 4px;
  opacity: ${(props) => props.opacity || 1};
  position: relative;
  padding: 0.2rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CenteredForm = styled(Form)`
  width: 80rem;
  max-width: 98%;
  margin: 0 auto;
`;

export type StyledForm = StyledComponent<
  {
    theme?: Theme | undefined;
    as?: ElementType<any> | undefined;
  },
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
>;
