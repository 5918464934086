import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { EmailRequest } from "../types";

const emailList = (recipients?: { emailAddress?: string }[]) =>
  recipients
    ?.map((e) => e.emailAddress)
    .filter(isNotNullOrUndefined)
    .join(",");

export const buildMailTo = ({
  to,
  cc,
  bcc,
  subject,
  bodyText,
  bodyHtml,
}: EmailRequest) => {
  const link = Object.entries({
    subject: encodeURIComponent(subject || ""),
    body: encodeURIComponent(bodyText || bodyHtml || ""),
    cc: emailList(cc),
    bcc: emailList(bcc),
  })
    .filter(([, value]) => isNotNullOrUndefined(value))
    .map((v) => v.join("="))
    .join("&");

  return `mailto:${emailList(to)}?${link}`;
};
