import { Outlet } from "react-router-dom";

import { ManageFormsProvider, useManageForms } from "@smart/manage-hooks-dom";

import { ActingIndicator } from "../components";

const FormsOutlet = () => {
  const { context } = useManageForms();
  return (
    <ManageFormsProvider value={context}>
      <ActingIndicator />
      <Outlet />
    </ManageFormsProvider>
  );
};

export { FormsOutlet };
