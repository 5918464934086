/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUploadQuestionGenerationFilesMutationVariables = Types.Exact<{
  fileNames:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
  matterId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  fileIds?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type GqlUploadQuestionGenerationFilesMutation = {
  __typename: "Mutation";
  uploadQuestionGenerationFiles: Array<{
    __typename: "File";
    key: string;
    uploadUrl: string;
    downloadUrl: string;
  }>;
};

export const UploadQuestionGenerationFilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "uploadQuestionGenerationFiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fileNames" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fileIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadQuestionGenerationFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileNames" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fileNames" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fileIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUploadQuestionGenerationFilesMutation,
  GqlUploadQuestionGenerationFilesMutationVariables
>;
