/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUpdateSubmissionMutationVariables = Types.Exact<{
  uri: Types.Scalars["ID"]["input"];
  formUri: Types.Scalars["ID"]["input"];
  fields: Types.GqlSubmissionInput;
}>;

export type GqlUpdateSubmissionMutation = {
  __typename: "Mutation";
  updateSubmission: {
    __typename: "Submission";
    uri: string;
    summary?: string | null;
    values: {
      __typename: "SubmissionValues";
      uri: string;
      matterId?: string | null;
      label?: string | null;
      operationId: string;
      status: Types.GqlSubmissionStatus;
      syncStatus?: Types.GqlSyncStatus | null;
      aiFillFiles?: Array<string> | null;
      aiFillFromMatterInfo?: boolean | null;
      deleted?: boolean | null;
      createdAt: string;
      updatedAt: string;
      communicationMethod?: Types.GqlCommunicationMethod | null;
      recipientContactId?: string | null;
      hashUri?: string | null;
      createdBy: {
        __typename: "User";
        uri: string;
        email: string;
        firstName: string;
        lastName: string;
        initials?: string | null;
        picture?: string | null;
        latestLoginAt?: string | null;
      };
      recipient?: {
        __typename: "Recipient";
        contactId: string;
        email: string;
        contactName: string;
      } | null;
    };
    form: {
      __typename: "Form";
      uri: string;
      values: {
        __typename: "FormValues";
        uri: string;
        operationId: string;
        deleted?: boolean | null;
        title: string;
        response: string;
        active?: boolean | null;
        teamUri: string;
        createdAt: string;
        updatedAt: string;
        category: Types.GqlFormCategory;
        createdBy: {
          __typename: "User";
          uri: string;
          email: string;
          firstName: string;
          lastName: string;
          initials?: string | null;
          picture?: string | null;
          latestLoginAt?: string | null;
        };
        updatedBy: {
          __typename: "User";
          uri: string;
          email: string;
          firstName: string;
          lastName: string;
          initials?: string | null;
          picture?: string | null;
          latestLoginAt?: string | null;
        };
        matterTypes: Array<{
          __typename: "MatterType";
          id: string;
          source: string;
          name: string;
          category: string;
          location: string;
        }>;
      };
      slug?: {
        __typename: "Slug";
        uri: string;
        values: {
          __typename: "SlugValues";
          uri: string;
          operationId: string;
          value: string;
          active: boolean;
          updatedAt: string;
          updatedBy: {
            __typename: "User";
            uri: string;
            email: string;
            firstName: string;
            lastName: string;
            initials?: string | null;
            picture?: string | null;
            latestLoginAt?: string | null;
          };
        };
      } | null;
      fields: Array<{
        __typename: "Field";
        uri: string;
        values: {
          __typename: "FieldValues";
          uri: string;
          type: Types.GqlFieldType;
        };
      }>;
    };
    responses: Array<{
      __typename: "Response";
      uri: string;
      fieldUri: string;
      value: string;
      updatedAt: string;
      operationId: string;
      syncStatus?: Types.GqlSyncStatus | null;
      existingItemIds?: string | null;
    }>;
    trackers?: Array<{
      __typename: "Tracker";
      uri: string;
      operationId: string;
      submissionUri: string;
      method?: string | null;
      sentAt?: string | null;
      userUri?: string | null;
      action?: string | null;
      user?: {
        __typename: "User";
        uri: string;
        email: string;
        firstName: string;
        lastName: string;
        initials?: string | null;
        picture?: string | null;
        latestLoginAt?: string | null;
      } | null;
    } | null> | null;
    aiUserFeedback?: {
      __typename: "AIUserFeedback";
      uri: string;
      values: {
        __typename: "AIUserFeedbackValues";
        uri: string;
        submissionUri?: string | null;
        type: string;
        sourceFileIds?: Array<string> | null;
        status: Types.GqlAiUserFeedbackStatus;
        statusText?: string | null;
        output?: string | null;
        feedback?: string | null;
        rating?: number | null;
        updatedAt?: string | null;
      };
    } | null;
  };
};

export const UpdateSubmissionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateSubmission" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uri" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "formUri" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fields" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SubmissionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSubmission" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "formUri" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "formUri" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fields" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "submissionFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "submissionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Submission" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "summary" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "matterId" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
                { kind: "Field", name: { kind: "Name", value: "aiFillFiles" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aiFillFromMatterInfo" },
                },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initials" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "picture" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestLoginAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "communicationMethod" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recipientContactId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recipient" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contactId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contactName" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "hashUri" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "form" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "values" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deleted" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "response" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "active" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "teamUri" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "initials" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "picture" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latestLoginAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "initials" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "picture" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latestLoginAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "matterTypes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "location" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "slug" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "values" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "operationId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "active" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "initials" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "picture" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "latestLoginAt",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fields" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "values" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "fieldUri" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "existingItemIds" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trackers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "submissionUri" },
                },
                { kind: "Field", name: { kind: "Name", value: "method" } },
                { kind: "Field", name: { kind: "Name", value: "sentAt" } },
                { kind: "Field", name: { kind: "Name", value: "userUri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initials" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "picture" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestLoginAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "action" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "aiUserFeedback" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "values" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "submissionUri" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceFileIds" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "output" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedback" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUpdateSubmissionMutation,
  GqlUpdateSubmissionMutationVariables
>;
