/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlMatterRolesQueryVariables = Types.Exact<{
  matterId: Types.Scalars["String"]["input"];
}>;

export type GqlMatterRolesQuery = {
  __typename: "Query";
  matterRoles: Array<{
    __typename: "Role";
    id: string;
    name: string;
    contactId?: string | null;
    representativeIds?: Array<string> | null;
    isClient?: boolean | null;
    isOtherSide?: boolean | null;
  }>;
};

export const MatterRolesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "matterRoles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "matterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "matterRoles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "matterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "matterId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "roleFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "roleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Role" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "contactId" } },
          { kind: "Field", name: { kind: "Name", value: "representativeIds" } },
          { kind: "Field", name: { kind: "Name", value: "isClient" } },
          { kind: "Field", name: { kind: "Name", value: "isOtherSide" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlMatterRolesQuery, GqlMatterRolesQueryVariables>;
