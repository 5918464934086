import { useRoutes } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { IntakeNotFound } from "@smart/bridge-intake-components-dom";

import { Dashboard } from "./dashboard";
import {
  Embedded,
  Settings,
  Builder,
  EditForm,
  Submission as EmbeddedSubmission,
  Tab,
} from "./embedded";
import { Form } from "./form";
import { FormCreate } from "./form-create";
import { FormImport } from "./form-import";
import { Forms } from "./forms";
import { FormsOutlet } from "./forms-outlet";
import { Library } from "./library";
import { Main } from "./main";
import { NotFound, ConditionalNotFound } from "./not-found";
import { Preview, PreviewWrapper } from "./preview";
import { Submission } from "./submission";
import { Submissions } from "./submissions";
import { Team } from "./team";
import { SubmissionV2 } from "../components/embedded/submission/submissionV2";

const RouteSelector = () => {
  const { formImportExport, globalForm } = useFeatureFlags();

  return useRoutes([
    {
      path: "/",
      element: <Main />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: "forms",
          element: <FormsOutlet />,
          children: [
            { path: "", element: <Forms /> },
            { path: ":id", element: <Form /> },
            { path: ":id/:tab", element: <Form /> },
            { path: "create", element: <FormCreate /> },
            {
              path: "import",
              element: (
                <ConditionalNotFound
                  show={formImportExport}
                  element={<FormImport />}
                />
              ),
            },
          ],
        },
        { path: "/submissions", element: <Submissions /> },
        { path: "/submissions/:id", element: <Submission /> },
        { path: "/team", element: <Team /> },
        {
          path: "/library",
          element: (
            <ConditionalNotFound show={globalForm} element={<Library />} />
          ),
        },
        {
          path: "/library/:id",
          element: (
            <ConditionalNotFound
              show={globalForm}
              element={<Form type="global" />}
            />
          ),
        },
        {
          path: "/library/:id/:tab",
          element: (
            <ConditionalNotFound
              show={globalForm}
              element={<Form type="global" />}
            />
          ),
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "preview",
      element: <PreviewWrapper />,
      children: [
        { index: true, element: <IntakeNotFound /> },
        { path: ":id", element: <Preview /> },
        { path: "*", element: <IntakeNotFound /> },
      ],
    },
    {
      path: "embedded",
      element: <Embedded />,
      children: [
        { path: "tab", element: <Tab /> },
        { path: "tab/:id", element: <SubmissionV2 /> },
        { path: "settings", element: <Settings /> },
        {
          path: "builder",
          element: <Builder />,
        },
        { path: "builder/:id", element: <EditForm /> },
        { path: "submissions/:id", element: <EmbeddedSubmission /> },
        { path: "*", element: <NotFound /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
};

export { RouteSelector };
