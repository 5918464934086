import styled from "@emotion/styled";
import { MutableRefObject, useState } from "react";

import { ClickOutsideWrapper, Icon } from "@smart/itops-components-dom";
import { mapOrEmpty } from "@smart/itops-utils-basic";

import {
  Input,
  Selection,
  List,
  Item,
  NotItem,
  ListHolder,
} from "./components";
import { FieldWrapper } from "../field";

export type ItemOptionProps = {
  name: string;
  value: string;
  label: string;
  selected: boolean;
  onChange: () => void;
};

export const ItemOption = ({
  name,
  value,
  label,
  selected,
  onChange,
}: ItemOptionProps) => (
  <Item aria-selected={selected}>
    <input
      type="checkbox"
      name={name}
      data-testid={`${name}-${value}`}
      value={value}
      checked={selected}
      onChange={onChange}
    />
    <div className="checkmark" />
    {label}
  </Item>
);

const CollectionOptionWrapper = styled.div<{ checked?: boolean }>`
  display: ${(props) => (props.checked ? "block" : "none")};
`;

export type ItemSelectProps<V> = {
  innerRef?: MutableRefObject<HTMLInputElement>;
  id?: string;
  invalid?: boolean;
  error?: string;
  className?: string;
  name: string;
  readOnly?: boolean;
  collections: { key: string; title?: string; options: V[] }[];
  shouldSortOptions?: boolean;
  empty: string;
  selected: V | undefined;
  keys: (value: V) => string;
  render: (value: V) => string;
  onSelect: (v: V | undefined) => void;
};

export const ItemSelect = <V extends any>({
  innerRef,
  id,
  invalid,
  error,
  className,
  name,
  readOnly,
  collections,
  shouldSortOptions = true,
  empty,
  selected,
  keys,
  render,
  onSelect,
}: ItemSelectProps<V>) => {
  const [showOptions, setShowOptions] = useState(false);
  const [collectionChecked, setCollectionChecked] = useState<
    Record<number, boolean>
  >({ 0: true });

  const sortOptionsAlphabetically = (options: V[]) =>
    options.sort((a, b) =>
      render(a).toLowerCase().localeCompare(render(b).toLowerCase()),
    );

  return (
    <FieldWrapper
      className={className}
      data-testid={`select-${name}`}
      onFocus={() => !readOnly && setShowOptions(true)}
    >
      <ClickOutsideWrapper onClickOutside={() => setShowOptions(false)}>
        <Selection>
          <Input
            ref={innerRef}
            id={id}
            aria-invalid={invalid}
            aria-errormessage={error}
            name={name}
            disabled={readOnly}
            value={selected ? render(selected) : ""}
            onChange={() => {}}
            onKeyUp={(e) => {
              e.preventDefault();
              if (selected) {
                switch (e.key) {
                  case "Backspace": {
                    onSelect(undefined);
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
            }}
          />
          <Icon
            dataTestId={`item-select-dropdown-icon-${name}`}
            name={showOptions ? "angleUp" : "angleDown"}
            className="arrow"
            size={12}
            onClick={() => !readOnly && setShowOptions(!showOptions)}
          />
        </Selection>
        {showOptions && (
          <ListHolder>
            <List>
              {mapOrEmpty(
                collections.filter((c) => !!c.options.length),
                (collection, index) => (
                  <div key={collection.key}>
                    {collection.title && (
                      <Item aria-disabled>
                        <input
                          type="checkbox"
                          name={`collection-${name}`}
                          value={`collection-${collection.title}`}
                          onChange={() =>
                            setCollectionChecked((current) =>
                              current[index]
                                ? { ...current, [index]: false }
                                : { ...current, [index]: true },
                            )
                          }
                          checked={!!collectionChecked[index]}
                        />
                        <Icon
                          name="angleRightBold"
                          className="arrow"
                          size={8}
                        />
                        <strong>{collection.title}</strong>
                      </Item>
                    )}
                    <CollectionOptionWrapper checked={collectionChecked[index]}>
                      {(shouldSortOptions
                        ? sortOptionsAlphabetically([...collection.options])
                        : collection.options
                      ).map((o) => (
                        <ItemOption
                          name={name}
                          key={keys(o)}
                          value={keys(o)}
                          label={render(o)}
                          selected={!!selected && keys(selected) === keys(o)}
                          onChange={() => {
                            onSelect(o);
                            setShowOptions(false);
                          }}
                        />
                      ))}
                    </CollectionOptionWrapper>
                  </div>
                ),
                <NotItem>{empty}</NotItem>,
              )}
            </List>
          </ListHolder>
        )}
      </ClickOutsideWrapper>
    </FieldWrapper>
  );
};
