/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlFormFieldsFragment = {
  __typename: "Form";
  uri: string;
  values: {
    __typename: "FormValues";
    uri: string;
    operationId: string;
    deleted?: boolean | null;
    title: string;
    response: string;
    active?: boolean | null;
    teamUri: string;
    creationStatus?: string | null;
    source?: Types.GqlFormSource | null;
    matterId?: string | null;
    createdAt: string;
    updatedAt: string;
    category: Types.GqlFormCategory;
    aiFillSettings?: {
      __typename: "AIFillSettings";
      allowAiFill?: boolean | null;
      sectionTitle?: string | null;
      fileUploadLabel?: string | null;
      fileUploadDescription?: string | null;
    } | null;
    createdBy: {
      __typename: "User";
      uri: string;
      email: string;
      firstName: string;
      lastName: string;
      initials?: string | null;
      picture?: string | null;
      latestLoginAt?: string | null;
    };
    updatedBy: {
      __typename: "User";
      uri: string;
      email: string;
      firstName: string;
      lastName: string;
      initials?: string | null;
      picture?: string | null;
      latestLoginAt?: string | null;
    };
    matterTypes: Array<{
      __typename: "MatterType";
      id: string;
      source: string;
      name: string;
      category: string;
      location: string;
      representativeOptions: Array<string>;
    }>;
  };
  slug?: {
    __typename: "Slug";
    uri: string;
    values: {
      __typename: "SlugValues";
      uri: string;
      operationId: string;
      value: string;
      active: boolean;
      updatedAt: string;
      updatedBy: {
        __typename: "User";
        uri: string;
        email: string;
        firstName: string;
        lastName: string;
        initials?: string | null;
        picture?: string | null;
        latestLoginAt?: string | null;
      };
    };
  } | null;
  aiUserFeedback?: {
    __typename: "AIUserFeedback";
    uri: string;
    values: {
      __typename: "AIUserFeedbackValues";
      uri: string;
      formUri?: string | null;
      type: string;
      sourceFileIds?: Array<string> | null;
      status: Types.GqlAiUserFeedbackStatus;
      statusText?: string | null;
      output?: string | null;
      feedback?: string | null;
      rating?: number | null;
      updatedAt?: string | null;
    };
  } | null;
};

export const FormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "formFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Form" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "operationId" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "response" } },
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "teamUri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creationStatus" },
                },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "matterId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aiFillSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowAiFill" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sectionTitle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUploadLabel" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUploadDescription" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initials" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "picture" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestLoginAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initials" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "picture" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestLoginAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matterTypes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "representativeOptions" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "slug" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "values" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operationId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "active" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "initials" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "picture" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latestLoginAt" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "aiUserFeedback" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "values" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "formUri" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceFileIds" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "output" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedback" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlFormFieldsFragment, unknown>;
