import { useLazyQuery, useMutation } from "@apollo/client";

import { globalTeamUri } from "@smart/bridge-types-basic";
import { buildDisplayName, sortField } from "@smart/itops-utils-basic";
import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation, updateAfterDelete } from "../cache";

export const useDeleteForm = () =>
  useMutation(mutationDocuments.deleteForm, {
    optimisticResponse: optimisticOperation("deleteForm"),
    update: updateAfterDelete("deleteForm", "Form"),
  });

export const useUpdateForm = () =>
  useMutation(mutationDocuments.updateForm, {
    update: (cache, { data }, { variables }) => {
      if (data?.updateForm && variables) {
        cache.updateQuery({ query: queryDocuments.forms, variables }, (ex) => {
          const exists = ex?.forms.find((f) => f.uri === data.updateForm.uri);
          if (exists) return ex;

          return {
            forms: [data.updateForm, ...(ex?.forms || [])],
            __typename: "Query" as const,
          };
        });
      }
    },
  });

export const useUpdateFormOwnership = () =>
  useMutation(mutationDocuments.updateFormOwnership, {
    optimisticResponse: optimisticOperation("updateFormOwnership"),
    update: (cache, _, { variables }) => {
      if (variables) {
        const { uri, teamUri } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Form", uri }),
          fields: {
            values: (existing) => ({
              ...existing,
              /**
               * There is a backend trigger that changes the form to inactive if the ownership changes.
               * This is to make the cache update align with the backend behaviour.
               */
              active: existing.teamUri !== teamUri ? false : existing.active,
              teamUri,
            }),
          },
        });
      }
    },
  });

export const useCopyForm = () =>
  useMutation(mutationDocuments.copyForm, {
    optimisticResponse: optimisticOperation("copyForm"),
  });

export const useLoadForms = useQueryFactory(queryDocuments.forms, {
  map: ({ forms }) =>
    forms
      .filter((f) => !f.values.deleted)
      .map((f) => ({
        uri: f.uri,
        formTitle: f.values.title,
        formCategory: f.values.category,
        matterTypeCategories: f.values.matterTypes.map((mt) => mt.category),
        matterTypeNames: f.values.matterTypes.map((mt) => mt.name),
        matterTypeLocations: f.values.matterTypes.map((mt) => mt.location),
        matterTypeList: f.values.matterTypes
          .map((mt) => `${mt.name} - ${mt.location}`)
          .sort()
          .join(", "),
        updatedAt: f.values.updatedAt,
        createdBy:
          f.values.teamUri === globalTeamUri
            ? undefined
            : buildDisplayName(f.values.createdBy, ""),
        teamUri: f.values.teamUri,
        creationStatus: f.values.creationStatus,
        active: f.values.active ?? undefined,
        aiUserFeedback: f.aiUserFeedback?.values ?? undefined,
        aiFillSettings: f.values.aiFillSettings ?? undefined,
        rawForm: f,
      })),
});

export const useLoadTeamForms = useQueryFactory(queryDocuments.forms, {
  map: ({ forms }) =>
    sortField(
      forms.filter(
        (f) => !f.values.deleted && f.values.teamUri !== globalTeamUri,
      ),
      { key: (f) => f.values.updatedAt, dir: "desc" },
    ),
});

export const useLoadForm = useQueryFactory(queryDocuments.form, {
  map: ({ form }) => form,
});

export const useLazyLoadForm = () => useLazyQuery(queryDocuments.form);

export const useLoadGlobalForms = useQueryFactory(queryDocuments.globalForms, {
  map: ({ globalForms }) =>
    sortField(
      globalForms.filter((f) => !f.values.deleted),
      { key: (f) => f.values.updatedAt, dir: "desc" },
    ),
});

export const useLoadMatterTypeForms = useQueryFactory(
  queryDocuments.matterTypeForms,
  {
    map: ({ matterTypeForms }) =>
      matterTypeForms
        .filter((f) => !f.values.deleted && !!f.values.active)
        .map((f) => f.values),
  },
);

export const useLoadGlobalMatterTypeForms = useQueryFactory(
  queryDocuments.globalMatterTypeForms,
  {
    map: ({ globalMatterTypeForms }) =>
      globalMatterTypeForms
        .filter((f) => !f.values.deleted && !!f.values.active)
        .map((f) => f.values),
  },
);

export const useLoadMatterCategoryForms = useQueryFactory(
  queryDocuments.matterCategoryForms,
  {
    map: ({ matterCategoryForms }) =>
      matterCategoryForms
        .filter((f) => !f.values.deleted && !!f.values.active)
        .map((f) => f.values),
  },
);

export const useLoadGlobalMatterCategoryForms = useQueryFactory(
  queryDocuments.globalMatterCategoryForms,
  {
    map: ({ globalMatterCategoryForms }) =>
      globalMatterCategoryForms
        .filter((f) => !f.values.deleted && !!f.values.active)
        .map((f) => f.values),
  },
);

/**
 * This is for the new form builder feature while keeping useLoadForm for the existing one.
 * The useLoadForm will be updated and used when we no long use the existing form designer feature.
 */
export const useLoadFormForEdit = useQueryFactory(queryDocuments.form, {
  map: ({ form }) =>
    form
      ? {
          uri: form.uri,
          formTitle: form.values.title,
          formCategory: form.values.category,
          matterTypeCategories: form.values.matterTypes.map(
            (mt) => mt.category,
          ),
          matterTypeNames: form.values.matterTypes.map((mt) => mt.name),
          matterTypeLocations: form.values.matterTypes.map((mt) => mt.location),
          matterTypeList: form.values.matterTypes
            .map((mt) => `${mt.name} - ${mt.location}`)
            .sort()
            .join(", "),
          updatedAt: form.values.updatedAt,
          createdBy:
            form.values.teamUri === globalTeamUri
              ? undefined
              : buildDisplayName(form.values.createdBy, ""),
          teamUri: form.values.teamUri,
          active: form.values.active ?? undefined,
          creationStatus: form.values.creationStatus,
          aiUserFeedback: form.aiUserFeedback?.values ?? undefined,
          aiFillSettings: form.values.aiFillSettings ?? undefined,
          rawForm: form,
        }
      : form,
});

export const useLoadMatterSpecificForms = useQueryFactory(
  queryDocuments.matterSpecificForms,
  {
    map: ({ matterSpecificForms }) =>
      matterSpecificForms
        .filter((f) => !f.values.deleted)
        .map((f) => ({
          ...f.values,
          aiUserFeedback: f.aiUserFeedback?.values,
        })),
  },
);
