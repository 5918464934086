import { z } from "zod";

import { fieldTypeSchema } from "./field";
import { groupTemplateTypeSchema } from "./group";

export const GroupTemplateSchema = z.object({
  type: groupTemplateTypeSchema,

  fields: z.array(
    z.object({
      label: z.string(),
      hint: z.string(),
      type: fieldTypeSchema,
      options: z.array(z.object({ value: z.string(), label: z.string() })),
      mandatory: z.boolean(),
    }),
  ),
});

export type GroupTemplate = z.infer<typeof GroupTemplateSchema>;

// Hard-code supported group templates (JIRA ticket: SMART-118)
export const groupTemplates: GroupTemplate[] = [
  {
    type: "address",
    fields: [
      {
        label: "Street address",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
      {
        label: "Suburb",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
      {
        label: "Postcode",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
    ],
  },
  {
    type: "contact",
    fields: [
      {
        label: "Mobile phone",
        hint: "",
        type: "phoneNumber",
        options: [],
        mandatory: false,
      },
      {
        label: "Work phone",
        hint: "",
        type: "phoneNumber",
        options: [],
        mandatory: false,
      },
      {
        label: "Home phone",
        hint: "",
        type: "phoneNumber",
        options: [],
        mandatory: false,
      },
    ],
  },
  {
    type: "person",
    fields: [
      {
        label: "Title",
        hint: "",
        type: "choice",
        mandatory: false,
        options: [
          {
            value: "Mr",
            label: "Mr",
          },
          {
            value: "Mrs",
            label: "Mrs",
          },
          {
            value: "Miss",
            label: "Miss",
          },
          {
            value: "Ms",
            label: "Ms",
          },
          {
            value: "Mx",
            label: "Mx",
          },
          {
            value: "Dr",
            label: "Dr",
          },
          {
            value: "Master",
            label: "Master",
          },
          {
            value: "Hon",
            label: "Hon",
          },
          {
            value: "Professor",
            label: "Professor",
          },
          {
            value: "Reverend",
            label: "Reverend",
          },
          {
            value: "Magistrate",
            label: "Magistrate",
          },
          {
            value: "Judge",
            label: "Judge",
          },
          {
            value: "Father",
            label: "Father",
          },
        ],
      },
      {
        label: "First name",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
      {
        label: "Middle name",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
      {
        label: "Last name",
        hint: "",
        type: "text",
        options: [],
        mandatory: false,
      },
      {
        label: "Email",
        hint: "",
        type: "email",
        options: [],
        mandatory: false,
      },
      {
        label: "Mobile phone",
        hint: "",
        type: "phoneNumber",
        options: [],
        mandatory: false,
      },
    ],
  },
];
