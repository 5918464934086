import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";

import { DNDCurrentProps, DNDProps } from "@smart/itops-hooks-dom";

export type DraggableItemProps<I extends {}> = {
  index: number;
  item: I;
  draggableProvided?: DraggableProvided;
};

type DragNDropListProps<I extends {}, K extends keyof I, P extends {}> = Omit<
  DNDProps<I, K>,
  "onMove"
> & {
  className?: string;
  id: string;
  render: (props: DraggableItemProps<I> & P) => JSX.Element | null;
  placeholder?: (props: { item: I }) => JSX.Element;
  footer?: JSX.Element;
  renderProps?: P;
  onMove: (
    current: DNDCurrentProps<I> & {
      items: I[];
      destination: DraggableLocation;
      source: DraggableLocation;
    },
  ) => void;
};

const DragNDropList = <I extends {}, K extends keyof I, P extends {}>({
  className,
  id,
  render: Render,
  renderProps = {} as P,
  footer,
  items,
  itemKey,
  onMove,
}: DragNDropListProps<I, K, P>) => {
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    onMove({
      item: items[source.index],
      index:
        destination.index > source.index
          ? destination.index + 1
          : destination.index,
      items,
      destination,
      source,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => (
          <div
            className={className}
            data-testid={`draggable-container-${id}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable
                draggableId={`${item[itemKey]}`}
                index={index}
                key={`${item[itemKey]}`}
              >
                {(draggableProvided) => (
                  <Render
                    {...renderProps}
                    item={item}
                    index={index}
                    draggableProvided={draggableProvided}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {footer}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DragNDropListProps, DragNDropList };
