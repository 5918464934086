import { useEffect, useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { EditableInput } from "./editable-input";
import {
  EditableFontProps,
  FieldLoading,
  FieldQuestion,
  TextContent,
} from "./elements";
import { EditableTextError } from "./error";
import { RequiredAsterisk } from "../required-asterisk";

export type EditableTextProps = Partial<EditableFontProps> & {
  loading?: boolean;
  isMandatory?: boolean;
  text?: string | null;
  error?: string;
  placeholder?: string;
  editing?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  backgroundOnHover?: boolean;
  onChange: (value: string) => void;
  onComplete: () => Promise<void>;
  onEditing?: (isEditing: boolean) => void;
  isEditor?: boolean;
};

export const EditableText = ({
  loading,
  isMandatory,
  text,
  error,
  placeholder,
  editing,
  dataTestId,
  onEditing,
  onChange,
  onComplete,
  fontSize = "emphasis",
  bold = false,
  disabled = false,
  backgroundOnHover = true,
  isEditor,
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(editing);
  const onSubmit = async () => {
    setIsEditing(false);
    await onComplete();
  };

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  useEffect(() => {
    if (onEditing) onEditing(!!isEditing);
  }, [isEditing]);

  if (loading)
    return (
      <FieldLoading fontSize={fontSize} bold={bold}>
        <TextContent>Loading {placeholder || "placeholder"}...</TextContent>
      </FieldLoading>
    );
  if (isEditing)
    return (
      <EditableInput
        text={text ?? undefined}
        dataTestId={dataTestId}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        fontSize={fontSize}
        bold={bold}
        backgroundOnHover={backgroundOnHover}
        isEditor={isEditor}
      />
    );

  return (
    <FieldQuestion
      onClick={() => !disabled && setIsEditing(true)}
      backgroundOnHover={backgroundOnHover}
      aria-disabled={disabled}
      fontSize={fontSize}
      bold={bold}
      data-testid={dataTestId}
    >
      <EditableInput
        text={text ?? undefined}
        dataTestId={dataTestId}
        fontSize={fontSize}
        placeholder={placeholder}
        isPreview={false}
        bold={bold}
        disabled
      />
      {!placeholder && specialChars.nbsp}
      {isMandatory && <RequiredAsterisk />}
      {error && <EditableTextError error={error} />}

      <div className="pencil">
        <Icon library="lucide" name="Pencil" size={15} />
      </div>
    </FieldQuestion>
  );
};
