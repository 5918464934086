import { clsx } from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import {
  ButtonVariant,
  getSingleIconClassNames,
  getVariantClassNames,
} from "./class-names";
import { Icon, IconName } from "../icon";
import { Spinner } from "../spinner";

export type ButtonProps = {
  variant?: ButtonVariant;
  text?: string;
  title?: string;
  leftIcon?: IconName;
  rightIcon?: IconName;
  dataTestId?: string;
  loading?: boolean;
  eventCaptureAttributes?: Record<string, string | boolean | undefined>;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "type" | "className" | "disabled" | "onClick" | "onMouseDown" | "onTouchStart"
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primaryDefault",
      className,
      text,
      leftIcon,
      rightIcon,
      dataTestId,
      loading,
      disabled,
      eventCaptureAttributes,
      ...buttonProps
    },
    ref,
  ) => {
    const spinnerColors = {
      primaryDefault: "inverted",
      primarySubtle: "primary",
      secondaryDefault: "secondary",
      secondarySubtle: "secondary",
      highlight: "primary",
      invertedDefault: "inverted",
      invertedSubtle: "inverted",
      inlineDefault: "secondary",
      inlineSubtle: "secondary",
    } as const;

    const content = loading ? (
      <Spinner size="regular" color={spinnerColors[variant]} />
    ) : (
      <>
        {leftIcon && <Icon name={leftIcon} />}
        {text && (
          <span className="text-ui font-inter font-semibold">{text}</span>
        )}
        {rightIcon && <Icon name={rightIcon} />}
      </>
    );

    const isSingleIcon =
      loading ||
      (leftIcon && !rightIcon && !text) ||
      (!leftIcon && rightIcon && !text);

    return (
      <button
        className={twMerge(
          clsx(
            getVariantClassNames({ loading, variant }),
            isSingleIcon && getSingleIconClassNames(variant),
            className,
          ),
        )}
        ref={ref}
        type="button"
        data-testid={dataTestId}
        {...buttonProps}
        {...eventCaptureAttributes}
        disabled={disabled || loading}
      >
        {content}
      </button>
    );
  },
);
