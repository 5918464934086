/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlAiUserFeedbackFieldsFragment = {
  __typename: "AIUserFeedback";
  uri: string;
  values: {
    __typename: "AIUserFeedbackValues";
    uri: string;
    formUri?: string | null;
    type: string;
    sourceFileIds?: Array<string> | null;
    status: Types.GqlAiUserFeedbackStatus;
    statusText?: string | null;
    output?: string | null;
    feedback?: string | null;
    rating?: number | null;
    updatedAt?: string | null;
  };
};

export const AiUserFeedbackFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "aiUserFeedbackFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AIUserFeedback" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "values" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uri" } },
                { kind: "Field", name: { kind: "Name", value: "formUri" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sourceFileIds" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "statusText" } },
                { kind: "Field", name: { kind: "Name", value: "output" } },
                { kind: "Field", name: { kind: "Name", value: "feedback" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlAiUserFeedbackFieldsFragment, unknown>;
