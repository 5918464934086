import { Icon, Switch, Tooltip } from "@smart/itops-sb-design-system-dom";

type FieldValidationToggleProps = {
  toggled: boolean;
  onClick: () => void;
};

export const FieldValidationToggle = ({
  toggled,
  onClick,
}: FieldValidationToggleProps) => (
  <div className="flex items-center gap-small">
    <Tooltip
      text="Turn off field validation to preview your form without errors."
      placement="top"
      offsetBy={7}
    >
      <Icon name="regularCircleExclamation" />
    </Tooltip>
    <Switch toggled={toggled} onClick={onClick} />
    <div className="text-ui">Field validation</div>
  </div>
);
