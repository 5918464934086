import { MutationTuple } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { IconName } from "@smart/itops-icons-dom";

import { ConfirmButton } from "../buttons";

type Deletable = { uri: string };

type DeleteFactoryProps<D, V extends Deletable> = {
  text?: string;
  icon?: IconName;
  confirmText: string;
  next?: (props: { variables: V; data: D | null | undefined }) => string;
  mutation: () => MutationTuple<D, V>;
};

export type DeleteButtonProps<V extends Deletable> = {
  variables: V;
};

export const deleteButtonFactory =
  <D, V extends Deletable>({
    text,
    icon,
    confirmText,
    next,
    mutation,
  }: DeleteFactoryProps<D, V>) =>
  ({ variables }: DeleteButtonProps<V>) => {
    const navigate = useNavigate();
    const [update] = mutation();
    const onClick = useCallback(async () => {
      const { data } = await update({ variables });
      if (next) navigate(next({ variables, data }));
    }, [variables]);

    return (
      <ConfirmButton
        confirmText={confirmText}
        onClick={onClick}
        text={text}
        icon={icon}
        palette="danger"
      />
    );
  };
