import { clsx } from "clsx";

import { focusBorder } from "../button/class-names";

const base =
  "font-inter font-semibold text-neutral-850 inline-flex items-center gap-small border border-solid border-neutral-200 relative cursor-default bg-clip-padding";
const disabledClassNames =
  "text-neutral-400 hover:bg-white/0 active:bg-white/0 active:border-neutral-200";
const closeableClassNames =
  "px-[0.8rem] bg-cyan-140 border-cyan-140 focus:bg-cyan-40 focus:border-cyan-40 hover:border-cyan-40 hover:bg-cyan-40 active:border-cyan-240 active:bg-cyan-240";
const closeableDisabledClassNames =
  "bg-black/5 border-black/5  hover:bg-black/5 hover:border-black/5 active:bg-black/5 active:border-black/5";

const defaultBase = clsx(base, "rounded px-[1.2rem] py-[0.5rem]");
const defaultFocus = clsx(focusBorder, "focus:bg-neutral-50");
const defaultHover = "hover:bg-neutral-50";
const defaultActive = "active:bg-cyan-140 active:border-cyan-140";

const compactBase = clsx(base, "rounded-small px-[1.2rem] py-[0.3rem]");
const compactFocus = clsx(defaultFocus, "focus:before:rounded-small");
const compactHover = defaultHover;
const compactActive = defaultActive;

export type TagVariant = "default" | "compact";

export const getTagVariants = ({
  disabled,
  variant,
  closeable,
}: {
  variant: TagVariant;
  disabled?: boolean;
  closeable?: boolean;
}) => {
  const variants = {
    default: clsx(
      defaultBase,
      defaultFocus,
      defaultHover,
      defaultActive,
      disabled && disabledClassNames,
      closeable && closeableClassNames,
      closeable && disabled && closeableDisabledClassNames,
    ),
    compact: clsx(
      compactBase,
      compactFocus,
      compactHover,
      compactActive,
      disabled && disabledClassNames,
      closeable && closeableClassNames,
      closeable && disabled && closeableDisabledClassNames,
    ),
  };

  return variants[variant];
};
