import { clsx } from "clsx";

const base =
  "font-inter focus:underline hover:underline active:underline active:font-semibold cursor-pointer inline-flex items-center gap-[0.8rem]";
const disabledClassNames =
  "text-neutral-400 focus:no-underline hover:no-underline hover:text-neutral-400 active:no-underline active:text-neutral-400 active:font-normal cursor-not-allowed";

const defaultBase = clsx(base, "text-blue-560");
const defaultFocus = "focus:text-blue-460";
const defaultHover = "hover:text-blue-460";
const defaultActive = "active:text-blue-660";

const subtleBase = clsx(base, "text-neutral-850");
const subtleFocus = "focus:text-neutral-750";
const subtleHover = "hover:text-neutral-750";
const subtleActive = "active:text-neutral-950";

const invertedBase = clsx(base, "text-white");
const invertedFocus = "focus:text-white/80";
const invertedHover = "hover:text-white/80";

export type LinkVariant = "default" | "subtle" | "inverted";

export const getLinkVariants = ({
  disabled,
  variant,
}: {
  variant: LinkVariant;
  disabled?: boolean;
}) => {
  const variants = {
    default: clsx(
      defaultBase,
      defaultFocus,
      defaultHover,
      defaultActive,
      disabled && disabledClassNames,
    ),
    subtle: clsx(
      subtleBase,
      subtleFocus,
      subtleHover,
      subtleActive,
      disabled && disabledClassNames,
    ),
    inverted: clsx(
      invertedBase,
      invertedFocus,
      invertedHover,
      disabled && disabledClassNames,
    ),
  };

  return variants[variant];
};
