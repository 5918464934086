import { Label, Select } from "@smart/itops-components-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { authApi } from "./local";
import { Button } from "../components";

const MatterDebugLocal = ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}) => {
  const { post } = authApi.matter();
  const { result } = authApi.matters({ body: { accountId } });

  return (
    <Label name="matters" text="Matters">
      <Select
        name="matters"
        value=""
        onChange={async (e) => {
          const context = await post({
            body: { accountId, userId, matterId: e.currentTarget.value },
          });
          localStorage.setItem(
            "local-smokeball-app-context",
            JSON.stringify(context),
          );
          window.location.reload();
        }}
      >
        <option value="" disabled>
          Select a Matter
        </option>
        {result?.map((r) => (
          <option key={r.matterId} value={r.matterId}>
            {r.number} - {r.matterTypeName} ({r.isLead ? "Lead" : "Matter"})
          </option>
        ))}
      </Select>
    </Label>
  );
};

const MatterDebug = () => {
  const { hostType, matters, context, contacts, layouts } = useSmokeballApp();

  return (
    <>
      {hostType === "local" && context && (
        <MatterDebugLocal accountId={context.firmId} userId={context.userId} />
      )}
      <dl>
        <dt>{matters?.current?.isLead ? "Lead" : "Matter"}</dt>
        <dd>
          {matters?.current?.number || specialChars.enDash} (
          {matters?.current?.status || specialChars.enDash})
        </dd>
        <dt>Id</dt>
        <dd>{matters?.current?.id}</dd>
        <dt>Type</dt>
        <dd>{matters?.current?.matterTypeId || specialChars.enDash}</dd>
      </dl>
      <Button
        text="Log Matter Details"
        onClick={() =>
          console.info({
            context,
            matter: matters?.current,
            contacts: contacts?.current,
            layouts: layouts?.current,
          })
        }
      />
    </>
  );
};

export { MatterDebug };
