import styled from "@emotion/styled";
import { useState } from "react";

import { Modal } from "@smart/itops-smokeball-components-dom";
import { MatterSubmission } from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";

import { SubmissionViewHistory } from "./history";
import { SubmissionViewResponses } from "./responses";
import { SubmissionViewSync } from "./sync";
import { SubmissionViewToolbar } from "./toolbar";

const SubmissionViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  .tab-view-details {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
`;

export type SubmissionViewContainerProps = {
  loading?: boolean;
  showCopy?: boolean;
  fullView?: boolean;
  submission: MatterSubmission;
  responseList: MatterSubmission["responses"];
  sections: Gql.SectionFieldsFragment[];
  groups: Gql.GroupFieldsFragment[];
  fields: Gql.FieldFieldsFragment[];
  onClose: () => void;
  onCopyLink: (link: string) => Promise<void>;
  onConfirmSync: (submission: MatterSubmission) => Promise<void>;
  onCancel: (submission: MatterSubmission) => void;
  onDelete: (submission: MatterSubmission) => void;
  onRemind: (submission: MatterSubmission) => void;
  onEdit: (submission: MatterSubmission) => void;
};

export const SubmissionViewContainer = ({
  loading,
  showCopy,
  fullView,
  submission,
  responseList,
  sections,
  groups,
  fields,
  onClose,
  onCopyLink,
  onCancel,
  onDelete,
  onRemind,
  onEdit,
  onConfirmSync,
}: SubmissionViewContainerProps) => {
  const [history, setHistory] = useState(false);
  const [syncing, setSyncing] = useState<MatterSubmission>();
  const trackersToDisplay =
    submission.trackers?.filter(
      (tracker) =>
        tracker?.action !== "submission-created" ||
        tracker.method === "internalUse",
    ) || [];

  return (
    <>
      <Modal
        size="maximum"
        title={!fullView ? "View Form" : undefined}
        isForm={false}
        transparent
        open={[!!submission, onClose]}
        buttons={[]}
        closeOptions={{
          closeButton: !fullView,
          escapeKey: false,
          clickOutside: false,
        }}
      >
        <SubmissionViewWrapper>
          <SubmissionViewToolbar
            submission={submission}
            showCopy={showCopy}
            onCancel={onCancel}
            onDelete={onDelete}
            onRemind={onRemind}
            onEdit={onEdit}
            onCopyLink={onCopyLink}
            onSync={setSyncing}
            onHistory={() => setHistory(true)}
          />
          <div className="tab-view-details">
            <SubmissionViewResponses
              loading={loading}
              sections={sections.map((s) => s.values)}
              groups={groups.map((g) => g.values)}
              fields={fields.map((f) => f.values)}
              responseList={responseList}
            />
            <SubmissionViewHistory
              trackers={trackersToDisplay}
              visible={history}
              onClose={() => setHistory(false)}
            />
          </div>
        </SubmissionViewWrapper>
      </Modal>
      <SubmissionViewSync
        selected={syncing}
        onClose={() => setSyncing(undefined)}
        onConfirm={onConfirmSync}
      />
    </>
  );
};
