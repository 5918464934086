import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { TField } from "@smart/bridge-resources-basic";
import { defaultClientRoleId } from "@smart/bridge-smokeball-basic";
import { buildMessageTemplate } from "@smart/bridge-templates-basic";
import { AnalyticsTool, UserNS } from "@smart/bridge-types-basic";
import { Hint, Icon, Loading } from "@smart/itops-components-dom";
import {
  FieldContentWrapper,
  FieldGroup as FieldGroupComponent,
  ItemSelect,
  ItemTags,
  Label,
  smokeballTheme,
  Modal as SmokeballModal,
} from "@smart/itops-smokeball-components-dom";
import { buildDisplayName } from "@smart/itops-utils-basic";
import {
  useLazyLoadFields,
  useLazyLoadMatterTypes,
} from "@smart/manage-gql-client-dom";
import { Toast } from "@smart/manage-hooks-dom";

import { AnalyticsConfig } from "./analytics-config";
import { EmbedOptions } from "./embed-options";
import { GenerateForm, Staff } from "./types";
import { findClientRoles, validateForm } from "./validation-utils";

const Modal = styled(SmokeballModal)`
  .modal {
    width: 90%;
    max-width: 147.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content .tag-options {
      max-height: 15rem;
    }
  }

  .heading {
    padding: 0 0 1rem 0;

    h2 {
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: 600;
      margin: 0;
    }
  }
`;

const FieldGroup = styled(FieldGroupComponent)`
  align-items: center;
`;

const FieldLabel = styled(Label)`
  min-width: 6rem;
`;

const InvalidWarning = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-size: ${(props) => props.theme.fontSize.small};
  background-color: ${(props) => props.theme.palette.warning.highlight};
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-radius: 2px;
`;

type ValidationStatus = "valid" | "invalid" | "validating";

export type CreateLinkProps = {
  loading?: boolean;
  areasOfLaw: string[];
  locations: string[];
  teamForms: GenerateForm[];
  teamName: string;
  globalForms: GenerateForm[];
  productName: string;
  staff: Staff[];
  user?: Staff;
  onGenerate: (fields: {
    formUri: string;
    slugUri: string;
    notifiedUserUris: string[];
    analyticsTools?: AnalyticsTool[];
  }) => Promise<string | undefined>;
  onCopy: (options: { code: string; toast: Toast }) => Promise<void>;
  loadFields: ReturnType<typeof useLazyLoadFields>;
  loadMatterTypes: ReturnType<typeof useLazyLoadMatterTypes>[0];
  creatingModal: [boolean, (v: boolean) => void];
};

export const CreateLink = ({
  loading,
  areasOfLaw,
  locations,
  teamForms,
  teamName,
  globalForms,
  productName,
  user,
  staff,
  onGenerate,
  onCopy,
  loadFields,
  loadMatterTypes,
  creatingModal,
}: CreateLinkProps) => {
  const [, setModalOpen] = creatingModal;
  const [selectedForm, setSelectedForm] = useState<GenerateForm | undefined>();
  const [validationStatus, setValidationStatus] = useState<
    ValidationStatus | undefined
  >();
  const [selectedStaff, setSelectedStaff] = useState(user ? [user] : []);
  const [analyticsTools, setAnalyticsTools] = useState<
    AnalyticsTool[] | undefined
  >(undefined);

  const filterForms = (forms: GenerateForm[]) =>
    forms.filter((form) =>
      form.values.matterTypes.some(
        (mt) =>
          areasOfLaw.includes(mt.category) && locations.includes(mt.location),
      ),
    );

  useEffect(() => {
    if (!selectedForm) return;

    const validate = async (form: GenerateForm) => {
      setValidationStatus("validating");
      const fields = (await loadFields({ formUri: form.uri }))?.map(
        (f) => f.values as unknown as TField,
      );

      if (!fields?.length) return setValidationStatus("invalid");

      if (validateForm(fields, defaultClientRoleId))
        return setValidationStatus("valid");

      const matterTypes = await loadMatterTypes({
        variables: {
          type: form.values.category,
          matterTypeIds: form.values.matterTypes.map((mt) => mt.id),
        },
      });
      const clientRoles = findClientRoles(matterTypes.data?.matterTypes);

      if (clientRoles.some((role) => validateForm(fields, role)))
        return setValidationStatus("valid");

      return setValidationStatus("invalid");
    };

    validate(selectedForm).catch(console.error);
  }, [selectedForm]);

  useEffect(() => {
    setSelectedStaff(user ? [user] : []);
  }, [user]);

  const onCloseModal = () => {
    setSelectedStaff([]);
    setAnalyticsTools(undefined);
    setSelectedForm(undefined);
    setValidationStatus(undefined);
    setModalOpen(false);
  };

  return (
    <Modal
      title=" "
      loading={loading}
      open={creatingModal}
      onReset={onCloseModal}
      buttons={[
        {
          text: "Cancel",
          onClick: onCloseModal,
        },
      ]}
    >
      <div className="heading">
        <h2>New Link</h2>
      </div>
      <FieldGroup>
        <FieldLabel htmlFor="form" aria-disabled={loading}>
          Form
        </FieldLabel>
        <FieldContentWrapper>
          <ItemSelect
            name="form"
            collections={[
              {
                key: "team",
                title: `${teamName} Forms`,
                options: filterForms(teamForms),
              },
              {
                key: "global",
                title: `${productName} Forms`,
                options: filterForms(globalForms),
              },
            ]}
            readOnly={loading}
            keys={(f) => f.uri}
            render={(f) => f.values.title}
            selected={selectedForm}
            onSelect={(f) => {
              setSelectedForm(f);
            }}
            empty="No forms available"
          />
        </FieldContentWrapper>
        {validationStatus === "valid" && (
          <>
            <FieldLabel htmlFor="staff" aria-disabled={loading}>
              <span>Staff</span>{" "}
              <Hint placement="bottom-start" iconSize={16} maxWidth={200}>
                Selected staff will be notified when this form is completed.
              </Hint>
            </FieldLabel>
            <FieldContentWrapper>
              <ItemTags
                name="staff"
                options={staff}
                readOnly={loading}
                keys={(s) => s.userId}
                render={(s) => buildDisplayName(s, s.userId)}
                selected={selectedStaff}
                onToggle={(item) => {
                  setSelectedStaff((current) => {
                    if (current.some((i) => i.userId === item.userId)) {
                      return current.filter((i) => i.userId !== item.userId);
                    }

                    return [...current, item];
                  });
                }}
                onToggleAll={(show) => setSelectedStaff(show ? staff : [])}
                empty="No Staff Available"
              />
            </FieldContentWrapper>
          </>
        )}
        {validationStatus === "valid" && (
          <AnalyticsConfig
            config={analyticsTools}
            setConfig={setAnalyticsTools}
          />
        )}
      </FieldGroup>
      {validationStatus === "validating" && <Loading />}
      {validationStatus === "invalid" && (
        <InvalidWarning>
          <Icon name="alert" color={smokeballTheme.palette.warning.base} />A Web
          Enquiry form can not be shared without a required mapped field for the
          Prospective Client Name. Please map this field and mark it as
          “Required” to continue.
        </InvalidWarning>
      )}
      {validationStatus === "valid" && (
        <EmbedOptions
          disabled={loading}
          onCopy={async (type) => {
            if (selectedForm) {
              const link = await onGenerate({
                formUri: selectedForm.uri,
                slugUri: selectedForm.slug?.uri || "",
                notifiedUserUris: selectedStaff.map((s) =>
                  UserNS.generateUri("sb", s.userId),
                ),
                analyticsTools,
              });

              if (link) {
                const code =
                  type === "url"
                    ? link
                    : buildMessageTemplate({
                        link,
                        format: "embed",
                        category: "lead",
                        matterType: { name: "" },
                        form: { title: selectedForm.values.title },
                        embedOption: type,
                      }).html;
                await onCopy({
                  code,
                  toast: {
                    text: `${
                      type === "url" ? "Link" : "Code"
                    } copied and ready to be used`,
                  },
                });
              }
            }
            onCloseModal();
          }}
        />
      )}
    </Modal>
  );
};
