import { loadEnv } from "@smart/bridge-env-dom";
import {
  useFeatureFlags,
  useSmokeballFeatureFlagsContext,
} from "@smart/bridge-feature-flags-dom";
import {
  familyProBalanceSheetIntakeBalanceSheetFormUri,
  familyProBalanceSheetIntakeFormUri,
  FamilyProFormUri,
  familyProIlFinancialAffidavitBalanceSheetIntakeBalanceSheetFormUri,
  familyProIlFinancialAffidavitBalanceSheetIntakeFormUri,
  familyProIlFinancialAffidavitFormUri,
  familyProIntakeEnquiryFormUri,
  familyProParentingAndPropertyExclCurrSitFormUri,
  familyProParentingAndPropertyFormUri,
  familyProParentingExclCurrSitFormUri,
  familyProParentingFormUri,
  familyProPropertyExclCurrSitFormUri,
  familyProPropertyFormUri,
  usFamilyProMatterTypesWithAdditionalQuestionnaire,
} from "@smart/bridge-types-basic";
import { loadLocale } from "@smart/itops-locale-dom";
import { Matter, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { Region } from "@smart/itops-utils-basic";
import {
  useLoadFamilyProAuthorizationState,
  useLoadGlobalMatterTypeForms,
  useLoadGlobalMatterCategoryForms,
  useLoadMatterCategoryForms,
  useLoadMatterType,
  useLoadMatterTypeForms,
  useLoadMatterTypeSearch,
  useLoadTeam,
  useLoadMatterSpecificForms,
  useLoadMatterSubmissions,
} from "@smart/manage-gql-client-dom";

import { useUser } from "./user";

export type SubmissionForm = {
  uri: string;
  title: string;
  category: "matter" | "lead";
  matterTypes: { id: string; location: string; category: string }[];
  matterId?: string | null;
};

export const useAvailabilities = ({
  matter,
  isFamilyProAuthorized,
}: {
  matter: Matter | undefined;
  isFamilyProAuthorized: boolean;
}) => {
  const {
    familyPro,
    leadPro,
    matterFlow,
    matterFormsOnLeads,
    matterSpecificForms,
  } = useFeatureFlags();
  const availableForLead = leadPro && matter?.isLead;
  const availableForMatter = matterFlow && matter && !matter.isLead;
  const {
    featureFlags: { fusionFamilyPropertyIntegrationV2: fpFeatureFlags },
  } = useSmokeballFeatureFlagsContext();
  const familyProSupportedMatterTypeIds = [
    ...(fpFeatureFlags?.IntakeMatterTypeIds || []),
    ...(fpFeatureFlags?.QuestionnaireMatterTypeIds || []),
    ...(fpFeatureFlags?.FamilyPropertyMatterTypeIds || []),
  ];

  const availableForFamilyPro =
    familyPro &&
    familyProSupportedMatterTypeIds?.some((id) =>
      matter?.matterTypeId.includes(id),
    ) &&
    isFamilyProAuthorized;

  const availableForMatterFormsOnLeads =
    leadPro && matterFlow && matterFormsOnLeads && matter?.isLead;

  return {
    availableForLead,
    availableForMatter,
    availableForFamilyPro,
    availableForMatterFormsOnLeads,
    availableForMatterSpecificForm: matterSpecificForms,
  };
};

const getMatterForms = (
  matterType: { location: string },
  matchedMatterType: { id: string; location: string } | undefined,
  forms: SubmissionForm[],
) => {
  const matterForms = forms.filter((f) => f.category === "matter");

  return matchedMatterType
    ? matterForms.filter((f) =>
        f.matterTypes.some(
          (mt) =>
            mt.id === matchedMatterType.id &&
            mt.location === matchedMatterType.location,
        ),
      )
    : matterForms.filter((f) =>
        f.matterTypes.some(
          (mt) => !matterType.location || mt.location === matterType.location,
        ),
      );
};

export const useFormLoader = ({
  userUri,
  matter,
  reloadOnVisible,
  onlyAICompletedForms,
}: {
  userUri: string | undefined;
  matter: Matter | undefined;
  reloadOnVisible?: boolean;
  onlyAICompletedForms?: boolean;
}): {
  loading?: boolean;
  teamForms: SubmissionForm[];
  matterSpecificForms: SubmissionForm[];
  globalForms: SubmissionForm[];
  teamCategoryForms: SubmissionForm[];
  globalCategoryForms: SubmissionForm[];
  refetch: () => Promise<void>;
} => {
  const authorizationState = useLoadFamilyProAuthorizationState({});
  const { availableForMatterFormsOnLeads } = useAvailabilities({
    matter,
    isFamilyProAuthorized: authorizationState.result?.isAuthorized ?? false,
  });

  const matterSubmissions = useLoadMatterSubmissions(
    { matterId: matter?.id || "" },
    { reloadOnVisible: true },
  );
  const teamForms = useLoadMatterTypeForms(
    {
      matterTypeId: matter?.matterTypeId || "",
    },
    { reloadOnVisible },
  );
  const matterSpecificForms = useLoadMatterSpecificForms({
    matterId: matter?.id || "",
  });
  const globalForms = useLoadGlobalMatterTypeForms(
    {
      matterTypeId: matter?.matterTypeId || "",
    },
    { reloadOnVisible },
  );
  const matterType = useLoadMatterType(
    {
      matterTypeId: matter?.matterTypeId || "",
    },
    {
      skip: !userUri || !matter?.matterTypeId,
      reloadOnVisible,
    },
  );
  const currentMatterType = matterType.result;
  const shouldSkipMatterFormsOnLeads =
    !matter?.isLead || !availableForMatterFormsOnLeads;

  const teamCategoryForms = useLoadMatterCategoryForms(
    {
      matterCategory: currentMatterType?.category || "",
      location: currentMatterType?.location || "",
    },
    {
      skip: shouldSkipMatterFormsOnLeads || !currentMatterType?.category,
      reloadOnVisible,
    },
  );
  const globalCategoryForms = useLoadGlobalMatterCategoryForms(
    {
      matterCategory: currentMatterType?.category || "",
      location: currentMatterType?.location || "",
    },
    {
      skip: shouldSkipMatterFormsOnLeads || !currentMatterType?.category,
      reloadOnVisible,
    },
  );

  return {
    loading:
      teamForms.loading ||
      matterSpecificForms.loading ||
      globalForms.loading ||
      teamCategoryForms.loading ||
      globalCategoryForms.loading,
    teamForms: teamForms.result || [],
    matterSpecificForms:
      matterSpecificForms.result?.filter(
        (f) =>
          f.active &&
          (!onlyAICompletedForms ||
            !f.aiUserFeedback ||
            f.aiUserFeedback.status === "completed" ||
            f.aiUserFeedback.status === "reviewed") &&
          !matterSubmissions.result?.some(
            (submission) => submission.formUri === f.uri,
          ),
      ) || [],
    globalForms: globalForms.result || [],
    teamCategoryForms: teamCategoryForms.result || [],
    globalCategoryForms: globalCategoryForms.result || [],
    refetch: async () => {
      await Promise.all([
        authorizationState.refetch(),
        matterSubmissions.refetch(),
        teamForms.refetch(),
        matterSpecificForms.refetch(),
        globalForms.refetch(),
        matterType.refetch(),
        teamCategoryForms.refetch(),
        globalCategoryForms.refetch(),
      ]);
    },
  };
};

export const familyProFormTitles: Record<FamilyProFormUri, string> = {
  [familyProIntakeEnquiryFormUri]: `Intake ${loadLocale().terms.enquiry.title}`,
  [familyProParentingFormUri]: "Parenting (incl Current Sit)",
  [familyProParentingAndPropertyFormUri]:
    "Parenting & Property (incl Current Sit)",
  [familyProPropertyFormUri]: "Property (incl Current Sit)",
  [familyProParentingExclCurrSitFormUri]: "Parenting (excl Current Sit)",
  [familyProParentingAndPropertyExclCurrSitFormUri]:
    "Parenting & Property (excl Current Sit)",
  [familyProPropertyExclCurrSitFormUri]: "Property (excl Current Sit)",
  [familyProBalanceSheetIntakeFormUri]: "Balance Sheet intake only",
  [familyProBalanceSheetIntakeBalanceSheetFormUri]:
    "Balance Sheet intake and Balance Sheet",
  [familyProIlFinancialAffidavitFormUri]: "IL Financial affidavit only",
  [familyProIlFinancialAffidavitBalanceSheetIntakeFormUri]:
    "IL Financial Affidavit & Balance Sheet intake",
  [familyProIlFinancialAffidavitBalanceSheetIntakeBalanceSheetFormUri]:
    "IL Financial Affidavit, Balance Sheet intake, Balance Sheet",
};

export const useFormCollections = ({
  availabilities: {
    availableForLead,
    availableForMatter,
    availableForFamilyPro,
    availableForMatterFormsOnLeads,
    availableForMatterSpecificForm,
  },
  teamForms,
  matterSpecificForms,
  globalForms,
  teamCategoryForms,
  globalCategoryForms,
  matterTypeId,
  matterNumber,
  teamName,
  matterType,
  matchedMatterType,
  isFamilyProFormShared,
}: {
  availabilities: ReturnType<typeof useAvailabilities>;
  teamForms: SubmissionForm[];
  matterSpecificForms: SubmissionForm[];
  globalForms: SubmissionForm[];
  teamCategoryForms: SubmissionForm[];
  globalCategoryForms: SubmissionForm[];
  matterTypeId?: string;
  matterNumber?: string;
  teamName: string;
  matterType: { location: string };
  matchedMatterType?: { id: string; location: string };
  isFamilyProFormShared: boolean;
}) => {
  const providerInfo = useProviderInfo();

  const getIntakeFormCollections = () => {
    const getRegularForms = () => {
      if (availableForMatterFormsOnLeads) {
        return [
          {
            key: "teamLead",
            title: `${teamName} Forms (Lead)`,
            options: teamForms,
          },
          {
            key: "globalLead",
            title: `${providerInfo?.label || "Global"} Forms (Lead)`,
            options: globalForms,
          },
          {
            key: "teamMatter",
            title: `${teamName} Forms (Matter)`,
            options: getMatterForms(
              matterType,
              matchedMatterType,
              teamCategoryForms,
            ),
          },
          {
            key: "globalMatter",
            title: `${providerInfo?.label || "Global"} Forms (Matter)`,
            options: getMatterForms(
              matterType,
              matchedMatterType,
              globalCategoryForms,
            ),
          },
        ];
      }

      if (availableForLead || availableForMatter)
        return [
          {
            key: "team",
            title: `${teamName} Forms`,
            options: teamForms,
          },
          {
            key: "global",
            title: `${providerInfo?.label || "Global"} Forms`,
            options: globalForms,
          },
        ];

      return [];
    };

    return availableForMatterSpecificForm
      ? [
          {
            key: "matterSpecific",
            title: `${matterNumber || "Matter"} Forms`,
            options: matterSpecificForms,
          },
          ...getRegularForms(),
        ]
      : getRegularForms();
  };

  const intakeFormCollections = getIntakeFormCollections();
  const auFamilyProFormOptions = [
    {
      uri: familyProIntakeEnquiryFormUri,
      title: familyProFormTitles[familyProIntakeEnquiryFormUri],
    },
    {
      uri: familyProParentingFormUri,
      title: familyProFormTitles[familyProParentingFormUri],
    },
    {
      uri: familyProParentingAndPropertyFormUri,
      title: familyProFormTitles[familyProParentingAndPropertyFormUri],
    },
    {
      uri: familyProPropertyFormUri,
      title: familyProFormTitles[familyProPropertyFormUri],
    },
    {
      uri: familyProParentingExclCurrSitFormUri,
      title: familyProFormTitles[familyProParentingExclCurrSitFormUri],
    },
    {
      uri: familyProParentingAndPropertyExclCurrSitFormUri,
      title:
        familyProFormTitles[familyProParentingAndPropertyExclCurrSitFormUri],
    },
    {
      uri: familyProPropertyExclCurrSitFormUri,
      title: familyProFormTitles[familyProPropertyExclCurrSitFormUri],
    },
  ];
  const usFamilyProFormBaseOptions = [
    {
      uri: familyProBalanceSheetIntakeFormUri,
      title: familyProFormTitles[familyProBalanceSheetIntakeFormUri],
    },
    {
      uri: familyProBalanceSheetIntakeBalanceSheetFormUri,
      title:
        familyProFormTitles[familyProBalanceSheetIntakeBalanceSheetFormUri],
    },
  ];
  const usFamilyProFormAdditionalOptions = [
    {
      uri: familyProIlFinancialAffidavitFormUri,
      title: familyProFormTitles[familyProIlFinancialAffidavitFormUri],
    },
    {
      uri: familyProIlFinancialAffidavitBalanceSheetIntakeFormUri,
      title:
        familyProFormTitles[
          familyProIlFinancialAffidavitBalanceSheetIntakeFormUri
        ],
    },
    {
      uri: familyProIlFinancialAffidavitBalanceSheetIntakeBalanceSheetFormUri,
      title:
        familyProFormTitles[
          familyProIlFinancialAffidavitBalanceSheetIntakeBalanceSheetFormUri
        ],
    },
  ];
  const usFamilyProFormOptions =
    usFamilyProMatterTypesWithAdditionalQuestionnaire.includes(
      matterTypeId || "",
    )
      ? [...usFamilyProFormBaseOptions, ...usFamilyProFormAdditionalOptions]
      : usFamilyProFormBaseOptions;
  const isUsRegion = (loadEnv("Region") as Region) === "nova";
  const familyProFormCollection = {
    key: "familyPro",
    title: `${loadLocale().terms.familyPro.name} Forms`,
    options: isUsRegion ? usFamilyProFormOptions : auFamilyProFormOptions,
  };

  return availableForFamilyPro && !isFamilyProFormShared
    ? [...intakeFormCollections, familyProFormCollection]
    : intakeFormCollections;
};

export const useAvailableFormCollections = ({
  reloadOnVisible,
}: {
  reloadOnVisible?: boolean;
}) => {
  const { user } = useUser();
  const team = useLoadTeam({});

  const { matters } = useSmokeballApp();
  const authorizationState = useLoadFamilyProAuthorizationState({});
  const availabilities = useAvailabilities({
    matter: matters?.current,
    isFamilyProAuthorized: authorizationState.result?.isAuthorized ?? false,
  });
  const { availableForMatterFormsOnLeads } = availabilities;
  const matterType = useLoadMatterType(
    {
      matterTypeId: matters?.current?.matterTypeId || "",
    },
    { skip: !user || !matters?.current?.matterTypeId },
  );
  const currentMatterType = matterType.result;
  const shouldSkipMatterFormsOnLeads =
    !matters?.current?.isLead || !availableForMatterFormsOnLeads;

  const matchedMatterType = useLoadMatterTypeSearch(
    {
      search: currentMatterType?.name || "",
      type: "matter",
      category: currentMatterType?.category || "",
      location: currentMatterType?.location || "",
    },
    {
      skip: shouldSkipMatterFormsOnLeads || !currentMatterType,
    },
  );
  const exactMatchedMatterType = matchedMatterType.data?.matterTypeSearch?.find(
    (mt) =>
      mt.name === currentMatterType?.name &&
      mt.category === currentMatterType.category &&
      mt.location === currentMatterType.location,
  );

  const {
    loading: formsLoading,
    teamForms,
    matterSpecificForms,
    globalForms,
    teamCategoryForms,
    globalCategoryForms,
    refetch,
  } = useFormLoader({
    userUri: user?.userUri,
    matter: matters?.current,
    reloadOnVisible,
  });

  const collections = useFormCollections({
    availabilities,
    teamForms,
    matterSpecificForms,
    globalForms,
    teamCategoryForms,
    globalCategoryForms,
    teamName: team.result?.values.name || "Firm",
    matterType: { location: currentMatterType?.location || "" },
    matchedMatterType: exactMatchedMatterType,
    isFamilyProFormShared: false,
  });

  return {
    loading:
      !user ||
      formsLoading ||
      matters?.loading ||
      authorizationState.loading ||
      matterType.loading ||
      matchedMatterType.loading,
    collections,
    refetch,
  };
};
