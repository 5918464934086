import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { loadLocale } from "@smart/itops-locale-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { Combobox, FieldGroup, FieldList, Modal } from "@smart/itops-ui-dom";
import {
  useLoadMatterTypeCategories,
  useUpdateSetting,
} from "@smart/manage-gql-client-dom";

import { GqlSetting } from "../types";

const setupFormSchema = z.object({
  locations: z.array(z.string()).min(1, "Please select one or more locations"),
  areasOfLaw: z
    .array(z.string())
    .min(1, "Please select one or more areas of law"),
});

type SetupFormOptions = z.infer<typeof setupFormSchema>;

export type SetupModalProps = {
  loading?: boolean;
  setting?: GqlSetting;
};

export const SetupModal = ({ loading, setting }: SetupModalProps) => {
  const providerInfo = useProviderInfo();
  const { terms, states } = loadLocale();
  const [updateSetting] = useUpdateSetting();
  const categories = useLoadMatterTypeCategories({
    teamUri: setting?.teamUri,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<SetupFormOptions>({
    defaultValues: {
      locations: [],
      areasOfLaw: [],
    },
    resolver: zodResolver(setupFormSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    await updateSetting({
      variables: {
        fields: {
          areasOfLaw: values.areasOfLaw,
          locations: values.locations,
        },
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Modal
        dataTestId="builder-setup-modal"
        open={
          !loading &&
          (!setting?.areasOfLaw?.length || !setting?.locations?.length)
        }
        loading={loading || isSubmitting}
        header={{
          icon: { library: "lucide", name: "Settings", variant: "action" },
          text: "Firm Information",
        }}
        footer={{
          right: [
            {
              text: "Save Information",
              type: "submit",
              variant: "save",
            },
          ],
        }}
      >
        <p>
          In order to improve your Intake experience, we need to collect some
          additional information about your firm, including the{" "}
          {terms.state.plural} and areas of law you currently{" "}
          {terms.practise.future} in.
        </p>
        <p>
          You can update these details at any time from your{" "}
          {providerInfo?.label || "Smokeball"} Settings, in the Intake Forms
          section.
        </p>
        <FieldList size="emphasis">
          <Controller
            control={control}
            name="locations"
            render={({ field, fieldState }) => (
              <FieldGroup
                id={field.name}
                label={terms.state.title}
                error={fieldState.error?.message}
                required
              >
                <Combobox
                  id={field.name}
                  title={terms.state.title}
                  options={setting?.locations || states.map((s) => s.name)}
                  maxItems={3}
                  error={!!fieldState.error}
                  {...field}
                  onChange={(_, v) => field.onChange(v)}
                  disableClearable
                  multiple
                  selectAll
                />
              </FieldGroup>
            )}
          />
          <Controller
            control={control}
            name="areasOfLaw"
            render={({ field, fieldState }) => (
              <FieldGroup
                id={field.name}
                label="Areas of law"
                error={fieldState.error?.message}
                required
              >
                <Combobox
                  id={field.name}
                  title="Areas of law"
                  options={
                    categories.loading
                      ? ["Loading..."]
                      : categories.data?.matterTypeCategories || []
                  }
                  getOptionDisabled={(o) => o === "Loading..."}
                  maxItems={3}
                  error={!!fieldState.error}
                  {...field}
                  onChange={(_, v) => field.onChange(v)}
                  selectAll={!categories.loading}
                  disableClearable
                  multiple
                />
              </FieldGroup>
            )}
          />
        </FieldList>
      </Modal>
    </form>
  );
};
