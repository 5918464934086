export const containerBase = (isCompact: boolean) =>
  `flex text-ui bg-black/5 ${isCompact ? "rounded" : "rounded-[1.2rem]"} p-[0.4rem] gap-small`;
export const labelBase = (isCompact: boolean) =>
  `px-[1.2rem] ${isCompact ? "py-[0.2rem] rounded-[0.4rem]" : "py-[0.5rem] rounded"} font-semibold text-neutral-600 outline-none border-[0.1rem] border-black/0`;
export const iconBase = (isCompact: boolean) =>
  `flex items-center justify-center ${isCompact ? "h-[2.4rem] w-[2.4rem] rounded-[0.4rem]" : "h-[3.2rem] w-[3.2rem] rounded"} font-semibold text-neutral-600 outline-none border-[0.1rem] border-black/0`;
export const hover = "hover:bg-black/5";
export const disabled = "disabled:text-neutral-400 disabled:cursor-not-allowed";
export const border =
  "focus:outline-blue-560 focus:outline-[0.1rem] focus:border-[0.1rem] focus:border-white focus:bg-black/5";
export const active =
  "aria-checked:bg-white aria-checked:text-black aria-checked:border-[0.1rem] aria-checked:border-black/15 aria-checked:outline-black/5 aria-checked:outline-1 outline-offset-0";
