import { Colour, ColourKey } from "@smart/itops-components-dom";
import { formatDateByDistanceToNow } from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

export type GqlTracker = NonNullable<
  NonNullable<MatterSubmission["trackers"]>[number]
>;

export type HistoryEventType =
  | "created"
  | "createdForInternalUse"
  | "sentViaCommunicate"
  | "sentViaEmail"
  | "completed"
  | "savedToMatter"
  | "matterCreated"
  | "opened"
  | "closed"
  | "modified"
  | "submissionLinkCopied";

export const historyEventColour: Record<
  HistoryEventType,
  [ColourKey, keyof Colour]
> = {
  created: ["primary", "base"],
  createdForInternalUse: ["primary", "base"],
  sentViaCommunicate: ["active", "accent"],
  sentViaEmail: ["active", "accent"],
  completed: ["success", "highlight"],
  savedToMatter: ["active", "accent"],
  matterCreated: ["active", "accent"],
  opened: ["active", "accent"],
  closed: ["disabled", "highlight"],
  modified: ["active", "accent"],
  submissionLinkCopied: ["active", "accent"],
};

export const historyEventTypeLabel: Record<HistoryEventType, string> = {
  created: "Created",
  createdForInternalUse: "Created for Internal Use",
  sentViaCommunicate: "Shared via Client Portal",
  sentViaEmail: "Shared via Email",
  completed: "Completed",
  savedToMatter: "Responses Saved",
  matterCreated: "Matter Created",
  opened: "Opened",
  closed: "Closed",
  modified: "Modified",
  submissionLinkCopied: "Link Copied",
};

export const getHistoryEventType = (
  tracker: GqlTracker,
): HistoryEventType | undefined => {
  switch (tracker.action) {
    case "saved-to-matter":
      return "savedToMatter";
    case "matter-created":
      return "matterCreated";
    case "submission-created":
      if (tracker.method === "internalUse") return "createdForInternalUse";
      return "created";
    case "submission-modified":
      return "modified";
    case "submission-closed":
      return "closed";
    case "submission-completed":
      return "completed";
    case "intake-form-opened":
      return "opened";
    case "submission-link-copied":
      return "submissionLinkCopied";
    case "submission-sent":
    case null: // for backward compatibility only
      if (tracker.method === "email") {
        return "sentViaEmail";
      }
      if (tracker.method === "communicate") {
        return "sentViaCommunicate";
      }
      return undefined;
    default:
      return undefined;
  }
};

export const formatEventDate = (
  date: string | null | undefined,
  options?: { preposition?: string },
) =>
  date
    ? formatDateByDistanceToNow(new Date(date), {
        daysLimit: 7,
        preposition: options?.preposition,
      })
    : undefined;
