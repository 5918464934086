import { useEffect, useState } from "react";

import { bytesPerMegaByte } from "@smart/bridge-types-basic";
import {
  FileItem,
  FileItemType,
  FolderInfo,
  FolderPathDisplay,
  Icon,
  Modal,
  RequiredAsterisk,
} from "@smart/itops-sb-design-system-dom";
import { fromEntries } from "@smart/itops-utils-basic";
import {
  useLoadMatterFiles,
  useUploadQuestionGenerationFiles,
} from "@smart/manage-gql-client-dom";

const availableFileExtensions = [".png", ".jpg", ".jpeg", ".pdf"];
const maxFileSizeMB = 5;

type SelectFileModalProps = {
  matterId: string | undefined;
  viewingFolderId: string | "root" | undefined;
  aiWordSupport?: boolean;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
  onCreateMatterSpecificForm: (options: {
    filePath: string;
    fileName: string;
    fileId: string;
  }) => Promise<void>;
};

export const SelectFileModal = ({
  matterId,
  viewingFolderId,
  aiWordSupport,
  setViewingFolderId,
  onCreateMatterSpecificForm,
}: SelectFileModalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [selectedFileItem, setSelectedFileItem] = useState<FileItemType>();
  const [loadedFolders, setLoadedFolders] = useState<
    Record<string, FolderInfo>
  >({
    root: { id: "root", name: "Root", parentId: undefined },
  });
  const [uploadQuestionGenerationFiles] = useUploadQuestionGenerationFiles();

  const matterFiles = useLoadMatterFiles({
    matterId: matterId || "",
    folderId: viewingFolderId !== "root" ? viewingFolderId : undefined,
  });
  const close = () => {
    setSelectedFileItem(undefined);
    setViewingFolderId(undefined);
  };

  useEffect(() => {
    if (matterFiles.result) {
      const folders = matterFiles.result.filter(
        (fileItem) => fileItem.type === "folder",
      );
      setLoadedFolders((existing) => ({
        ...existing,
        ...fromEntries(
          folders.map((folder) => [
            folder.id,
            {
              id: folder.id,
              name: folder.name,
              parentId: folder.parentId || "root",
            },
          ]),
        ),
      }));
    }
  }, [matterFiles.result]);

  useEffect(() => setSelectedFileItem(undefined), [viewingFolderId]);

  const currentFolder = loadedFolders[viewingFolderId!];
  if (aiWordSupport) {
    availableFileExtensions.push(".doc", ".docx");
  }

  const onCreateForm = async () => {
    if (selectedFileItem?.type !== "file" || !selectedFileItem?.fileExtension)
      throw new Error(`Invalid file type or extension: ${selectedFileItem}`);

    setIsCreating(true);
    const result = await uploadQuestionGenerationFiles({
      variables: {
        fileNames: [
          `${selectedFileItem.name}${selectedFileItem.fileExtension}`,
        ],
        matterId,
        fileIds: [selectedFileItem.id],
      },
    });
    const fileInfo = result.data?.uploadQuestionGenerationFiles[0];

    if (!fileInfo)
      throw new Error("Failed to upload file for question generation");

    await onCreateMatterSpecificForm({
      filePath: fileInfo.key,
      fileName: selectedFileItem.name,
      fileId: selectedFileItem.id,
    });
    setIsCreating(false);
  };

  const isFileTooLarge =
    selectedFileItem?.type === "file" &&
    (selectedFileItem.sizeBytes || 0) > maxFileSizeMB * bytesPerMegaByte;

  return (
    <Modal
      header={{
        icon: "archie",
        text: "Create from document",
        iconBgColor: "bg-cyan-140",
      }}
      open={!!viewingFolderId}
      onClose={close}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: close,
          },
          {
            text: "Create form",
            disabled: selectedFileItem?.type !== "file" || isFileTooLarge,
            onClick: onCreateForm,
          },
        ],
      }}
      closeOptions={{ clickOutside: false }}
      loading={matterFiles.loading || isCreating}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-1 mb-2">
          <div className="text-ui font-semibold">Select document</div>
          <RequiredAsterisk />
        </div>
        <div className="border-gray-300 border rounded overflow-hidden">
          <FolderPathDisplay
            loadedFolders={loadedFolders}
            currentFolder={currentFolder}
            setViewingFolderId={setViewingFolderId}
          />
          <div className="h-[20rem] overflow-y-auto">
            {matterFiles.result
              ?.filter((f) =>
                f.type === "file"
                  ? f.fileExtension &&
                    availableFileExtensions.includes(
                      f.fileExtension.toLowerCase(),
                    )
                  : true,
              )
              .map((fileItem) => (
                <FileItem
                  key={fileItem.id}
                  fileItem={fileItem}
                  selectedFileItem={selectedFileItem}
                  setSelectedFileItem={setSelectedFileItem}
                  setViewingFolderId={setViewingFolderId}
                />
              ))}
          </div>
        </div>
        {isFileTooLarge && (
          <div className="flex items-center gap-1 mt-2 text-red-550 text-ui font-semibold">
            <Icon className="w-4 h-4 mr-2" name="regularCircleExclamation" />
            <div className="text-caption font-normal">{`This file is too large to be processed. Files larger than ${maxFileSizeMB} MB are not currently supported.`}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};
