import styled from "@emotion/styled";
import {
  Control,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { TField } from "@smart/bridge-resources-basic";
import {
  layoutFieldTypeMap,
  roleProviderId,
} from "@smart/bridge-smokeball-basic";
import { fieldTypeLabel, fieldTypeValue } from "@smart/bridge-types-basic";
import {
  DraggableItemProps,
  Icon,
  IconButton,
  Input,
  Label,
  Mode,
  Rule,
  Select,
  TypeaheadField,
} from "@smart/itops-components-dom";
import { FormEditor } from "@smart/itops-editor-dom";
import { UpdateHookValues, useChangedEffect } from "@smart/itops-hooks-dom";
import {
  useLoadMatterFields,
  useLoadMatterLayouts,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { FieldOptions } from "./options";
import { GroupSchema } from "../shared-schemas";

const GroupFieldContainer = styled.div`
  padding: 1rem 0 0 1rem;

  .group-field {
    display: flex;

    .field-wrapper {
      flex: 1;
    }
  }

  .field-details {
    display: grid;
    grid-template-areas: "label type";
    grid-template-columns: 3fr 1fr;
    column-gap: 1rem;

    .field-label {
      grid-area: label;
    }

    .field-type {
      grid-area: type;
      display: flex;

      input {
        font-size: ${(props) => props.theme.fontSize.small};
      }
    }
  }

  .field-more-details {
    display: grid;
    grid-template-areas: "hint mandatory";
    grid-template-columns: 4fr 1fr;
    column-gap: 1rem;
    margin-top: 1rem;

    .field-hint {
      grid-area: hint;
    }

    .field-mandatory {
      grid-area: mandatory;
      display: flex;
      gap: 1rem;

      .label {
        .spacer-text {
          display: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .layout-group {
    margin: 1rem 0 1.4rem 0;
  }

  .field-options {
    margin-bottom: 1rem;
  }

  .handle {
    margin-left: 1rem;
  }
`;

const Handle = styled.div<{ disabled: boolean }>`
  display: ${(props) => (props.disabled ? "none" : "block")};
`;

const NarrowRule = styled(Rule)`
  margin: 0.6rem 0;
`;

type GroupFieldProps = DraggableItemProps<TField> & {
  watch: UseFormWatch<UpdateHookValues<GroupSchema>>;
  register: UseFormRegister<UpdateHookValues<GroupSchema>>;
  control: Control<UpdateHookValues<GroupSchema>>;
  matterTypes?: {
    id: string;
    name: string;
    category: string;
    location: string;
    source: string;
  }[];
  repeatable?: boolean;
  type: "template" | "custom" | "layout" | "layoutContact" | "layoutRepeatable";
  groupFieldLength: number;
  removeField: UseFieldArrayRemove;
  fields: GroupSchema["fields"];
  mode: Mode;
  setValue: UseFormSetValue<UpdateHookValues<GroupSchema>>;
};

const GroupField = ({
  draggableProvided,
  index,
  register,
  control,
  matterTypes,
  type,
  groupFieldLength,
  removeField,
  fields,
  mode,
  watch,
  setValue,
}: GroupFieldProps) => {
  const { user } = useUser();
  const { layout, roleFieldName } = fields[index];
  const disabled = mode === "view";

  const options = useFieldArray({ control, name: `fields.${index}.options` });

  const selectedLayoutField = watch(`fields.${index}.field`);
  const hasPossibleValues = !!selectedLayoutField?.possibleValues?.length;
  const selectedFieldType = watch(`fields.${index}.type`);
  const isTypeWithChoices =
    selectedFieldType === "choice" || selectedFieldType === "checkbox";
  const isYesNo = selectedFieldType === "yesNo";

  const getFieldOptionPermissions = () => {
    if (disabled)
      return {
        add: false,
        remove: false,
        changeValue: false,
        changeLabel: false,
        changeOrder: false,
      };
    return isYesNo
      ? { add: false, remove: false, changeValue: false }
      : {
          add: !hasPossibleValues,
          remove: true,
          changeValue: !hasPossibleValues,
        };
  };

  const populateOptions = () => {
    if (isYesNo) {
      return options.replace([
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]);
    }

    if (!selectedLayoutField?.possibleValues) return undefined;

    return options.replace(
      selectedLayoutField.possibleValues
        .filter((value) => value && value.length > 0)
        .map((value) => ({ label: value, value })),
    );
  };

  useChangedEffect(() => {
    if (mode !== "edit" || !selectedLayoutField) return;

    const fieldType = layoutFieldTypeMap[selectedLayoutField.type]?.includes(
      selectedFieldType,
    )
      ? selectedFieldType
      : "text";

    setValue(`fields.${index}.type`, fieldType);
    populateOptions();
  }, [selectedLayoutField?.name]);

  useChangedEffect(() => {
    if (mode !== "edit") return;
    if (!isTypeWithChoices && !isYesNo) {
      options.remove();
      return;
    }

    populateOptions();
  }, [selectedFieldType]);

  return (
    <GroupFieldContainer>
      <div
        className="group-field"
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
      >
        <div className="field-wrapper">
          <div className="field-details">
            <div className="field-label">
              <Label name="field-label" text="Label">
                <Input
                  {...register(`fields.${index}.label`)}
                  data-testid={`fields.${index}.label`}
                  placeholder="Label"
                  disabled={disabled}
                />
              </Label>
            </div>
            <div className="field-type">
              <Label name="field-type" text="Type">
                <Select
                  {...register(`fields.${index}.type`)}
                  disabled={disabled}
                >
                  {fieldTypeValue.map((v) => (
                    <option key={v} value={v}>
                      {fieldTypeLabel[v]}
                    </option>
                  ))}
                </Select>
              </Label>
            </div>
          </div>
          <div className="field-more-details">
            <div className="field-hint">
              <Label name="hint" text="Hint">
                <FormEditor
                  control={control}
                  name={`fields.${index}.hint`}
                  disabled={disabled}
                />
              </Label>
            </div>
            <div className="field-mandatory">
              <Label name="field-mandatory" text="Mandatory">
                <Input
                  {...register(`fields.${index}.mandatory`)}
                  type="checkbox"
                  disabled={disabled}
                />
              </Label>
              {isTypeWithChoices && (
                <Label name="allowCustomResponse" text="Custom response">
                  <Input
                    {...register(`fields.${index}.allowCustomResponse`, {
                      shouldUnregister: true,
                    })}
                    type="checkbox"
                    disabled={
                      disabled ||
                      (selectedLayoutField?.possibleValues
                        ? !selectedLayoutField.possibleValues.includes("")
                        : false)
                    }
                  />
                </Label>
              )}
            </div>
          </div>
          <div className="layout-group" data-testid={`layout-group.${index}`}>
            {!!matterTypes && (
              <Label name="layout" text="Layout">
                <TypeaheadField
                  control={control}
                  name={`fields.${index}.layout`}
                  hook={useLoadMatterLayouts}
                  locators={{
                    matterTypeIds: matterTypes.map((m) => m.id),
                  }}
                  input={(s) => s.displayName || ""}
                  display={(s) => s.name || ""}
                  idKey="id"
                  parentidKey="parentId"
                  filter={(search) =>
                    ({ name }) =>
                      name
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    }
                  interaction={
                    type === "layout" || type === "layoutContact" || disabled
                      ? "disabled"
                      : undefined
                  }
                />
              </Label>
            )}
            {!!layout && roleFieldName && (
              <Label name="roleField" text="Role field">
                <Input
                  data-testid={`fields.${index}.roleField`}
                  value={roleFieldName}
                  readOnly
                />
              </Label>
            )}
            {!!layout && (
              <Label name="field" text="Field">
                <TypeaheadField
                  interaction={disabled ? "disabled" : undefined}
                  control={control}
                  name={`fields.${index}.field`}
                  hook={useLoadMatterFields}
                  locators={{
                    matterLayoutId: layout.id,
                    providerId: roleFieldName
                      ? roleProviderId
                      : layout.providerId,
                    teamUri: user?.teamUri || "",
                    preserved: true,
                    matterTypeIds: matterTypes?.map((t) => t.id),
                  }}
                  input={(s) => s.displayName || ""}
                  display={(s) => s.displayName || ""}
                  details={(s) => `${s.type} | ${s.details}`}
                  idKey="name"
                  filter={(search) =>
                    ({ name, type: itemType }) =>
                      name
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase()) &&
                      itemType !== "Role"
                    }
                />
              </Label>
            )}
          </div>
          {(isTypeWithChoices || isYesNo) && (
            <FieldOptions
              className="field-options"
              options={options}
              register={register}
              permittedOperations={getFieldOptionPermissions()}
              registeredName={`fields.${index}.options`}
            />
          )}
        </div>
        <Handle
          disabled={disabled}
          {...draggableProvided?.dragHandleProps}
          aria-label="Draggable group field item handle"
          className="handle"
        >
          <Icon name="handle" />
        </Handle>
        {!disabled && (
          <IconButton
            aria-label={`remove-group-field-${index}`}
            name="cancel"
            palette="danger"
            onClick={() => {
              removeField(index);
            }}
            size={10}
          />
        )}
      </div>
      {index !== groupFieldLength - 1 && <NarrowRule />}
    </GroupFieldContainer>
  );
};

export { GroupField, GroupFieldProps };
