import { delay } from "./delay";

export const throttle = async <T extends any>({
  functions,
  rateLimit,
}: {
  functions: (() => Promise<T>)[];
  rateLimit: number;
}) => {
  const results: T[] = [];
  let currentIndex = 0;
  let tokensInBucket = 0;
  const bucketMax = 10;

  const timePerRequest = 1000 / rateLimit;

  const next = async (): Promise<undefined> => {
    if (currentIndex >= functions.length) return undefined;

    // simulates bucket with tokens for burst limit
    if (tokensInBucket < bucketMax) {
      tokensInBucket += 1;
    } else {
      await delay(timePerRequest);
    }

    const index = currentIndex;
    const fn = functions[currentIndex];
    currentIndex += 1;
    results[index] = await fn();

    tokensInBucket = Math.max(tokensInBucket - 1, 0);

    return next();
  };

  await Promise.all(
    Array.from({ length: Math.min(rateLimit, functions.length) }, next),
  );

  return results;
};
