import { useNavigate } from "react-router-dom";

import { Page } from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { useLoadGlobalForms } from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { GlobalFormList } from "../components/library";

const Library = () => {
  const globalForms = useLoadGlobalForms({});
  const navigate = useNavigate();
  const { permission } = useUser();

  const filteredForms = globalForms.result?.filter((form) =>
    permission?.can("view", {
      type: "form",
      value: {
        uri: form.uri,
        teamUri: form.values.teamUri,
        active: form.values.active ?? undefined,
      },
    }),
  );

  return (
    <Page text="Library" label="Library">
      <GlobalFormList
        forms={filteredForms}
        mode="view"
        select={(uri) => navigate(`/library/${extractId(uri)}`)}
        result={globalForms}
      />
    </Page>
  );
};

export { Library };
