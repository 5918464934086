import styled from "@emotion/styled";
import { ReactNode } from "react";

const LinkDisplayWrapper = styled.div<{ disabled?: boolean }>`
  border-bottom: 1px dotted ${(props) => props.theme.palette.disabled.base};
  margin: 1rem auto;
  padding: 1rem;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  max-width: 80rem;

  h4 {
    color: ${(props) => props.theme.palette.foreground.accent};
    font-size: ${(props) => props.theme.fontSize.subHeading};
    font-weight: 600;
    margin: 1rem 0;
  }

  a {
    display: block;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
    color: ${(props) => props.theme.palette.primary.base};
    font-size: ${(props) => props.theme.fontSize.base};
    margin: 1rem;
    text-align: center;
    text-decoration: underline;
  }
`;

type LinkDisplayProps = {
  instruction?: string;
  link?: string;
  text?: string;
  disabled?: boolean;
  children?: ReactNode;
};

const LinkDisplay = ({
  instruction,
  link,
  text,
  disabled,
  children,
}: LinkDisplayProps) => (
  <LinkDisplayWrapper disabled={disabled}>
    {instruction && <h4>{instruction}</h4>}
    {link && (
      <a target="_blank" rel="noreferrer" href={link}>
        {text || link}
      </a>
    )}
    {children}
  </LinkDisplayWrapper>
);

export { LinkDisplay, LinkDisplayProps };
