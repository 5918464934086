import styled from "@emotion/styled";
import {
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
  KeyboardEventHandler,
} from "react";
import { Editor as SlateEditor, Transforms } from "slate";
import { Editable, Slate } from "slate-react";

/**
 * We need to import something from @smart/itops-components-dom
 * to make typescript load its declaration file. This can be removed
 * if we import something else from @smart/itops-components-dom in the future.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defaultTheme } from "@smart/itops-components-dom";
import {
  PlaceholderData,
  EditorElement,
} from "@smart/itops-serialisation-basic";
import { isTestEnv } from "@smart/itops-utils-basic";

import { EditorContextProvider } from "./context";
import { TestEditable } from "./test";
import { useEditorActions, useInitialValue } from "../hooks";
import {
  Element,
  ExtendedButton,
  Leaf,
  LinkButton,
  MarkButton,
} from "../parts";
import { createEditor } from "../plugins";
import {
  ExtendedAction,
  ToolbarBuitInButton,
  ToolbarVisibility,
} from "../types";

const EditorWrapper = styled.div<{ toolbarVisibility: ToolbarVisibility }>`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .toolbar {
    background-color: ${(props) => props.theme.palette.background.accent};

    display: ${(props) =>
      props.toolbarVisibility === "hidden" ? "none" : "flex"};
    flex-flow: row wrap;
    align-items: center;
    overflow: hidden;
    height: ${(props) =>
      props.toolbarVisibility === "fixed" ? "3rem" : "inherit"};
    max-height: ${(props) =>
      props.toolbarVisibility === "auto" ? "0" : "inherit"};
    transition: ${(props) =>
      props.toolbarVisibility === "fixed" ? "inherit" : "max-height 0.2s ease"};
    border-width: ${(props) =>
      props.toolbarVisibility === "fixed" ? "1px" : "0"};
    border-style: solid;
    border-color: ${(props) => props.theme.palette.disabled.base};

    margin-bottom: ${(props) =>
      props.toolbarVisibility === "fixed" ? "0" : "-1rem"};
    width: 100%;
    border-bottom: none;
  }

  &:focus-within .toolbar {
    max-height: ${(props) =>
      props.toolbarVisibility === "fixed" ? "inherit" : "2.5rem"};
  }

  &:focus-within .editor {
    outline: none;
  }

  .editor {
    padding: 1rem;
    p {
      font-size: ${(props) => props.theme.fontSize.base};
      margin: 0.4rem 0;
    }
  }
`;

type EditorProps = {
  initialValue?: string;
  placeholderData?: PlaceholderData;
  onChange?: (v: string) => void;
  onBlur?: () => void;
  onKeyDown?: KeyboardEventHandler;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  extendedActions?: ExtendedAction[];
  toolbarOptions?: {
    toolbarVisiblity?: ToolbarVisibility;
    hiddenButtons?: ToolbarBuitInButton[];
  };
  disabledHotkeys?: string[];
  dataTestId?: string;
};

const Editor = ({
  initialValue,
  placeholderData,
  onChange,
  onBlur,
  onKeyDown,
  className,
  children,
  disabled = false,
  autoFocus,
  toolbarOptions,
  extendedActions,
  disabledHotkeys,
  dataTestId,
}: EditorProps) => {
  const value = useInitialValue(initialValue);
  const editor = useMemo(createEditor, []);
  const actions = useEditorActions({
    editor,
    extendedActions,
    disabledHotkeys,
    onChange,
  });
  const renderElement = useCallback(Element, []);
  const renderLeaf = useCallback(Leaf, []);

  useEffect(() => {
    if (!initialValue) {
      editor.deleteBackward("block");
    }
  }, [initialValue]);
  useEffect(() => {
    if (autoFocus) {
      Transforms.select(editor, SlateEditor.end(editor, []));
    }
  }, [autoFocus]);

  useEffect(() => {
    if (!placeholderData) return;

    const placeholderNodeEntries = SlateEditor.nodes(editor, {
      at: [],
      match: (node) => "type" in node && node.type === "placeholder",
    });

    for (const nodeEntry of placeholderNodeEntries) {
      const [element, location] = nodeEntry;
      const placeholderDataValue =
        placeholderData[(element as EditorElement<"placeholder">).name];
      Transforms.setNodes(
        editor,
        {
          replacingValue:
            typeof placeholderDataValue === "string"
              ? placeholderDataValue
              : undefined,
        },
        { at: location },
      );
    }
  }, [placeholderData]);

  return (
    <EditorWrapper
      className="editor-wrapper"
      toolbarVisibility={toolbarOptions?.toolbarVisiblity || "auto"}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      <Slate editor={editor} initialValue={value} onChange={actions.onChange}>
        {!disabled && (
          <div className="toolbar">
            <MarkButton name="bold" mark="bold" />
            <MarkButton name="italic" mark="italic" />
            {!toolbarOptions?.hiddenButtons?.includes("link") && <LinkButton />}
            {extendedActions &&
              extendedActions.map((action) => (
                <ExtendedButton
                  key={action.name}
                  iconName={action.iconName}
                  onClick={action.onClick}
                  tooltipTexts={action.tooltipTexts}
                  label={action.name}
                />
              ))}
          </div>
        )}
        <Editable
          data-testid={dataTestId}
          readOnly={disabled}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder=""
          spellCheck
          className={className ? `${className} editor` : "editor"}
          onKeyDown={actions.onKeyDown}
          autoFocus={autoFocus}
        />
        {!!isTestEnv() && (
          <TestEditable
            dataTestId={dataTestId}
            readOnly={disabled}
            className={className ? `${className} editor` : "editor"}
            onKeyDown={actions.onKeyDown}
            autoFocus={autoFocus}
          />
        )}
        <EditorContextProvider value={{ editor }}>
          {children}
        </EditorContextProvider>
      </Slate>
    </EditorWrapper>
  );
};

export { Editor, EditorProps };
