import { MutationResult, MutationTuple } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { SyntheticEvent, useCallback, useEffect } from "react";
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z, ZodType } from "zod";

import { useDebounce } from "./debounce";
import { PostHook } from "./post";
import { useUpload } from "./upload";

export type AfterSubmitAction = "create-more";

export type Updatable = { fields: {} };

export type UpdateHookProps<D, V extends Updatable, S> = {
  schema: ZodType<S>;
  file?: {
    prop: keyof V["fields"];
    key: (locators: Omit<V, "fields">) => string;
    tokenHook: () => { token: string };
    urlHook: PostHook<
      boolean,
      { key: string },
      { downloadUrl: string; uploadUrl: string }
    >;
  };
  converter?: (input: S) => V["fields"];
  mutation: () => MutationTuple<D, V>;
  next?: (locators: Omit<V, "fields">) => string;
};

export type UpdateProps<D, V extends Updatable, S extends FieldValues> = {
  locators: Omit<V, "fields">;
  debounce?: boolean;
  defaultValues: DefaultValues<S>;
  submitted?: (params: {
    variables: V;
    result: D | null | undefined;
    afterSubmitActionType?: AfterSubmitAction;
    reset: UseFormReturn<S>["reset"];
  }) => void;
  cancelled?: (locators: Omit<V, "fields">) => void;
};

export type UpdateHookValues<S> = S & { file?: File | string | undefined };

export type UpdateHookResult<D, V extends Updatable, S> = {
  locators: Omit<V, "fields">;
  onCancel: () => void;
  onSubmit: (e?: SyntheticEvent | undefined) => Promise<void>;
  result: MutationResult<D>;
  form: UseFormReturn<UpdateHookValues<S>>;
};

export const useUpdate = <D, V extends Updatable, S extends FieldValues>({
  schema,
  file,
  converter,
  next,
  mutation,
  locators,
  debounce,
  defaultValues,
  submitted,
  cancelled,
}: UpdateHookProps<D, V, S> & UpdateProps<D, V, S>): UpdateHookResult<
  D,
  V,
  S
> => {
  const navigate = useNavigate();
  const [update, result] = mutation();
  const form = useForm<UpdateHookValues<S>>({
    defaultValues,
    resolver: zodResolver(
      z
        .object({ file: z.instanceof(File).or(z.string()).optional() })
        .and(schema),
    ),
  });

  const token = file && file.tokenHook();
  const url = file && file.urlHook();
  const upload = useUpload({
    existing: file && defaultValues[file.prop] && `${defaultValues[file.prop]}`,
  });
  useEffect(() => {
    form.reset({ ...defaultValues, file: upload.current });
  }, [upload.current]);

  const onCancel = useCallback(async () => {
    form.reset(defaultValues);
    if (cancelled) cancelled(locators);
  }, [locators, cancelled]);
  const onSubmit = useCallback(
    form.handleSubmit(
      async (input, event) => {
        if (
          event ||
          (Object.keys(form.formState.dirtyFields).length &&
            form.formState.isDirty)
        ) {
          let fields: V["fields"] = converter ? converter(input as S) : input;

          if (file) {
            const { file: fileField, ...inputFields } = input;
            let fileValue = fileField;
            if (
              fileField instanceof File &&
              token &&
              url &&
              input.file !== upload.current
            ) {
              const urls = await url.post({
                body: { key: file.key(locators) },
                token: token.token,
              });
              if (urls) {
                await upload.upload({ url: urls.uploadUrl, file: fileField });
                fileValue = urls.downloadUrl;
              }
            } else if (fileField instanceof File) {
              fileValue = fileField.name;
            }

            fields = { ...inputFields, [file.prop]: fileValue };
          }

          const variables = { ...locators, fields } as V;
          const { data } = await update({ variables });
          form.reset(input);
          if (submitted) {
            const afterSubmitActionType = (
              event?.nativeEvent as { submitter: HTMLButtonElement }
            ).submitter?.name as AfterSubmitAction;
            submitted({
              variables,
              result: data,
              afterSubmitActionType,
              reset: form.reset,
            });
          }
          if (next && !debounce) navigate(next(locators), { replace: true });
        }
      },
      (errors) => console.error("Error on form submit", errors),
    ),
    [
      locators,
      next,
      debounce,
      submitted,
      upload.current,
      form.formState.dirtyFields,
      form.formState.isDirty,
    ],
  );
  const debounced = useDebounce(onSubmit, 600);
  useEffect(
    () =>
      form.watch(() => {
        if (debounce) debounced();
      }).unsubscribe,
    [],
  );

  return {
    onCancel,
    onSubmit,
    result,
    locators,
    form,
  };
};
