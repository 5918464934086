import { deleteButtonFactory } from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import { useDeleteSection } from "@smart/manage-gql-client-dom";

const DeleteSection = deleteButtonFactory({
  text: "Delete Section",
  confirmText: "Are you sure?",
  mutation: useDeleteSection,
  next: ({ variables: { formUri } }) =>
    `/forms/${extractId(formUri)}/questions`,
});

export { DeleteSection };
