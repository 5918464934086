import { useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { Button, ContextMenu } from "@smart/itops-sb-design-system-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";

import { SelectFileModal } from "./select-file-modal";

type ShareButtonProps = {
  onShare: (share: "regular" | "internal" | undefined) => void;
  onCreateMatterSpecificForm: (options: {
    filePath: string;
    fileName: string;
    fileId: string;
  }) => Promise<void>;
};

export const ShareButton = ({
  onShare,
  onCreateMatterSpecificForm,
}: ShareButtonProps) => {
  const { matters } = useSmokeballApp();
  const [viewingFolderId, setViewingFolderId] = useState<
    string | "root" | undefined
  >(undefined);
  const { matterSpecificForms, aiWordSupport } = useFeatureFlags();

  const internalUseButton = {
    label: "Prefill for client",
    leftIcon: "regularPencil",
    onClick: () => onShare("internal"),
  } as const;
  const createMatterSpecificFormButton = {
    label: "Create from document",
    leftIcon: "archie",
    onClick: () => setViewingFolderId("root"),
  } as const;

  return (
    <>
      <div className="flex gap-small items-center">
        <ContextMenu
          id="share-form"
          button={{
            variant: "primarySubtle",
            leftIcon: "solidEllipsis",
            className: "p-4",
          }}
          placement="bottom-end"
          items={
            matterSpecificForms
              ? [internalUseButton, createMatterSpecificFormButton]
              : [internalUseButton]
          }
        />
        <Button
          leftIcon="regularShare"
          variant="primaryDefault"
          text="Share form"
          onClick={() => onShare("regular")}
        />
      </div>
      <SelectFileModal
        matterId={matters?.current?.id}
        viewingFolderId={viewingFolderId}
        setViewingFolderId={setViewingFolderId}
        onCreateMatterSpecificForm={onCreateMatterSpecificForm}
        aiWordSupport={aiWordSupport}
      />
    </>
  );
};
