import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { defaultAIFillSettings } from "@smart/bridge-types-basic";
import { Alert, Card } from "@smart/itops-sb-design-system-dom";
import { EditableText, FileUpload } from "@smart/itops-ui-dom";

import { GqlForm, UpdateAIFillSettings } from "../../types";

const defaultMaxNumOfFiles = 5;

type AIFillFileUploadProps = {
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

export const AIFillFileUpload = ({
  aiFillSettings,
  updateAIFillSettings,
}: AIFillFileUploadProps) => {
  const { aiWordSupport } = useFeatureFlags();
  const [editingDescription, setEditingDescription] = useState(
    aiFillSettings?.fileUploadDescription ||
      defaultAIFillSettings.fileUploadDescription,
  );

  useEffect(() => {
    setEditingDescription(
      aiFillSettings?.fileUploadDescription ||
        defaultAIFillSettings.fileUploadDescription,
    );
  }, [aiFillSettings?.fileUploadDescription]);

  const sizeLimit = aiWordSupport
    ? `Max ${defaultMaxNumOfFiles} files; Jpeg, pdf, png, doc and docx; Max 5MB each.`
    : `Max ${defaultMaxNumOfFiles} files; Jpeg, pdf or png; Max 5MB each.`;

  return (
    <div className="max-w-screen-readable m-auto flex flex-col gap-medium">
      <Alert
        content="Please revise text below to give clients enough information to choose
          the correct documents to upload."
        variant="section"
        palette="warning"
      />
      <Card size="readable">
        <div className="mb-[1rem]">
          <EditableText
            dataTestId="ai-fill-file-upload-description"
            text={editingDescription}
            onChange={setEditingDescription}
            onComplete={() =>
              updateAIFillSettings({
                fileUploadDescription: editingDescription,
              })
            }
            fontSize="emphasis"
            isEditor
          />
        </div>
        <FileUpload
          id=""
          errorId=""
          sizeLimit={sizeLimit}
          onSelect={() => {}}
        />
      </Card>
    </div>
  );
};
