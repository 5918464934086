import styled from "@emotion/styled";

import { Variant, variantStyle } from "@smart/itops-ui-dom";

import { ConditionItem, getItemLabel } from "./utils";

const ConditionLinkButton = styled.a<{ variant?: Variant }>`
  ${variantStyle}

  display: block;
  flex: 0 1 min-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ConditionLink = ({
  field,
  setCurrentField,
}: {
  field: ConditionItem | undefined;
  setCurrentField: (field: ConditionItem | null) => void;
}) => {
  if (field) {
    const label = getItemLabel(field);
    return (
      <ConditionLinkButton
        href="#"
        variant="link"
        title={label}
        onClick={(e) => {
          e.preventDefault();
          setCurrentField(field);
        }}
      >
        {label}
      </ConditionLinkButton>
    );
  }

  return null;
};
