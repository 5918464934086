import { useCallback, useEffect, useRef, useState } from "react";

import {
  useChangedEffect,
  useFragment,
  useStatus,
} from "@smart/itops-hooks-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import {
  buildErrorCode,
  extractCognitoToken,
  isTestEnv,
  toSubdomain,
  UserErrorCode,
} from "@smart/itops-utils-basic";

export type InputToken = {
  token?: string;
  idToken?: string;
  expiresAt?: string;
};

export type UserToken = {
  token: string;
  idToken: string;
  expiresAt: number;
};

const TOKEN_STORAGE_KEY = "smart-auth-token" as const;
const ID_TOKEN_STORAGE_KEY = "smart-auth-id-token" as const;
const EXPIRY_STORAGE_KEY = "smart-auth-expiry" as const;

export const clearLocalToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(ID_TOKEN_STORAGE_KEY);
  localStorage.removeItem(EXPIRY_STORAGE_KEY);
};

export const setLocalToken = ({
  token,
  idToken,
  expiresAt,
}: InputToken): Partial<UserToken> => {
  if (token) localStorage.setItem(TOKEN_STORAGE_KEY, token);
  if (idToken) localStorage.setItem(ID_TOKEN_STORAGE_KEY, idToken);
  if (expiresAt) localStorage.setItem(EXPIRY_STORAGE_KEY, expiresAt);

  return {
    token,
    idToken,
    expiresAt: (expiresAt && parseInt(expiresAt, 10)) || undefined,
  };
};

export const getLocalToken = (): Partial<UserToken> => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  const idToken = localStorage.getItem(ID_TOKEN_STORAGE_KEY);
  const expiresAt = localStorage.getItem(EXPIRY_STORAGE_KEY);

  return {
    token: token || undefined,
    idToken: idToken || undefined,
    expiresAt: (expiresAt && parseInt(expiresAt, 10)) || undefined,
  };
};

export const setOrGetLocalToken = (
  input: InputToken,
): UserToken | undefined => {
  const current = getLocalToken();
  const updated = setLocalToken(input);

  const token = updated.token || current.token;
  const idToken = updated.idToken || current.idToken;
  const expiresAt = updated.expiresAt || current.expiresAt;

  return token && idToken && expiresAt
    ? { token, idToken, expiresAt }
    : undefined;
};

const refreshFromFrame = (url: string) =>
  new Promise<InputToken>((resolve, reject) => {
    const frame = document.createElement("iframe");
    frame.style.display = "none";

    const timeout = setTimeout(() => {
      reject(new Error("ERR-2026"));
    }, 10000);
    window.addEventListener("message", (e) => {
      if (url.startsWith(e.origin)) {
        clearTimeout(timeout);
        try {
          const data = JSON.parse(e.data);
          if (
            data &&
            typeof data.token === "string" &&
            typeof data.idToken === "string" &&
            typeof data.expiresAt === "string"
          ) {
            window.document.body.removeChild(frame);
            resolve(data);
          } else if (data && typeof data.response) {
            reject(new Error(data.response));
          } else {
            reject(new Error("ERR-2026"));
          }
        } catch {
          reject(new Error("ERR-2026"));
        }
      }
    });

    window.document.body.appendChild(frame);
    frame.src = url;
  });

export type TokenHookProps = { domain: string };

export const useToken = ({ isEmbeddedPage }: { isEmbeddedPage: boolean }) => {
  const [fragment] = useFragment(["token", "idToken", "expiresAt"]);
  // On embedded pages, we'll always load the token from smokeball app
  const [token, setToken] = useState(() =>
    isEmbeddedPage ? undefined : setOrGetLocalToken(fragment),
  );
  const [status, setStatus] = useStatus(token?.token ? "success" : "initial");
  const [errorCode, setErrorCode] = useState<UserErrorCode>();
  const { hostType, auth } = useSmokeballApp();

  const isRefreshing = useRef(false);
  const refresh = useCallback(async () => {
    if (isRefreshing.current) return;

    const refreshTokenFromAuth = async () => {
      try {
        setStatus("loading");
        const result = await refreshFromFrame(
          toSubdomain("signin", `/api/recall`),
        );
        setToken(setOrGetLocalToken(result));
        setStatus("success");
      } catch (error) {
        setStatus("error");
        const params = new URLSearchParams({ response: buildErrorCode(error) });
        window.location.href = toSubdomain("signin", `/#${params.toString()}`);
      }
    };

    const refreshTokenFromSmokeballApp = async () => {
      if (!auth) return;
      try {
        setStatus("loading");
        let tokenFromApp = await auth.token();
        let idTokenFromApp = await auth.idToken();

        const isMissingToken = !tokenFromApp || !idTokenFromApp;
        if (isMissingToken && hostType === "local" && !isTestEnv()) {
          const result = await refreshFromFrame(
            toSubdomain("signin", `/api/recall`),
          );
          tokenFromApp = result.token || "";
          idTokenFromApp = result.idToken || "";
          setOrGetLocalToken(result);
        }
        const parsed = await extractCognitoToken(tokenFromApp);
        setToken({
          token: tokenFromApp,
          idToken: idTokenFromApp,
          expiresAt: parsed.exp,
        });
        setStatus("success");
      } catch (error) {
        console.error(error);
        setStatus("error");
        setErrorCode("ERR-2027");
      }
    };

    isRefreshing.current = true;
    if (isEmbeddedPage && (hostType !== "local" || isTestEnv())) {
      await refreshTokenFromSmokeballApp();
    } else {
      await refreshTokenFromAuth();
    }
    isRefreshing.current = false;
  }, [!!auth]);

  const signOut = useCallback(() => {
    clearLocalToken();
    window.location.href = toSubdomain("signin", `/api/clear`);
  }, []);

  // If the fragment changes, set the token
  useChangedEffect(() => {
    if (!isEmbeddedPage) {
      setToken(setOrGetLocalToken(fragment));
    }
  }, [fragment]);
  // If there is no token, refresh the token
  useEffect(() => {
    if (!token?.token) refresh().catch(console.error);
  }, [token?.token, refresh]);
  // If the token is about to expire, refresh the token
  useEffect(() => {
    const checkExpiry = () => {
      const threshold = 5 * 60 * 1000;
      if (token?.expiresAt && token.expiresAt - Date.now() <= threshold) {
        refresh().catch(console.error);
      }
    };
    const interval = setInterval(checkExpiry, 2 * 60 * 1000);
    checkExpiry();

    return () => {
      clearInterval(interval);
    };
  }, [token?.expiresAt, refresh]);

  return {
    refresh,
    signOut,
    status,
    token,
    errorCode,
  };
};
