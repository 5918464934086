import { FetchPolicy, useLazyQuery } from "@apollo/client";
import { useState } from "react";

import { useAsync } from "@smart/itops-hooks-dom";
import { isNotNullOrUndefined, throttle } from "@smart/itops-utils-basic";
import {
  CachedGql,
  cachedQueryDocuments,
} from "@smart/manage-cached-gql-operations-dom";

import {
  useCachedQueryFactory,
  useConditionalCachedQueryFactory,
} from "./base";
import { useCachedClient } from "../cached-client";

/**
 * Cached gql queries require certain cookies to work. These cookies are
 * set by the me endpoint. If a query can potentially be called before the
 * me request finishes, it should wait until the user object is avialable
 * from the useUser hook (which means the me request is finished).
 *
 * Example:
 * const { user } = useUser();
 * const matterType = useLoadMatterType({ matterTypeId: "abcd" }, { skip: !user });
 */

const MAX_MATTER_TYPES_LENGTH_FOR_CACHED_GQL = 100;

export const useLoadRepeatableMatterFields = useCachedQueryFactory(
  cachedQueryDocuments.repeatableMatterFields,
  {
    map: ({ repeatableMatterFields }) => repeatableMatterFields,
  },
);

export const useLoadMatterFields = useCachedQueryFactory(
  cachedQueryDocuments.matterFields,
  {
    map: ({ matterFields }) => matterFields,
  },
);

export const useQueryMatterFields = (options?: {
  fetchPolicy?: FetchPolicy;
}) => {
  const apollo = useCachedClient();

  return async (variables: CachedGql.MatterFieldsQueryVariables) => {
    if (!apollo) return [];

    const result = await apollo.query({
      query: cachedQueryDocuments.matterFields,
      variables,
      fetchPolicy: options?.fetchPolicy,
    });
    return result.data.matterFields || [];
  };
};

export const useLoadMatterLayouts = useConditionalCachedQueryFactory(
  cachedQueryDocuments.matterLayouts,
  {
    map: ({ matterLayouts }) => matterLayouts,
  },
  (variables) =>
    variables.matterTypeIds.length < MAX_MATTER_TYPES_LENGTH_FOR_CACHED_GQL,
);

export const useQueryMatterLayouts = () => {
  const apollo = useCachedClient();

  return async (variables: CachedGql.MatterLayoutsQueryVariables) => {
    if (!apollo) return [];

    const result = await apollo.query({
      query: cachedQueryDocuments.matterLayouts,
      variables,
    });
    return result.data.matterLayouts || [];
  };
};

export const useLoadMatterTypeCategories = useCachedQueryFactory(
  cachedQueryDocuments.matterTypeCategories,
  { map: ({ matterTypeCategories }) => matterTypeCategories },
);

export const useLoadMatterTypeSearch = useCachedQueryFactory(
  cachedQueryDocuments.matterTypeSearch,
  {
    map: ({ matterTypeSearch }) => matterTypeSearch,
  },
);

export const useQueryMatterTypeSearch = () => {
  const apollo = useCachedClient();

  return async (variables: CachedGql.MatterTypeSearchQueryVariables) => {
    if (!apollo) return [];

    const result = await apollo.query({
      query: cachedQueryDocuments.matterTypeSearch,
      variables,
    });
    return result.data.matterTypeSearch || [];
  };
};

export const useLoadMatterType = useCachedQueryFactory(
  cachedQueryDocuments.matterType,
  {
    map: ({ matterType }) => matterType,
  },
);

export const useLoadMatterTypes = useCachedQueryFactory(
  cachedQueryDocuments.matterTypes,
  {
    map: ({ matterTypes }) => matterTypes,
  },
);

export const useQueryMatterTypeGroups = () => {
  const apollo = useCachedClient();

  return async (variables: CachedGql.MatterTypeGroupsQueryVariables) => {
    if (!apollo) return [];

    const result = await apollo.query({
      query: cachedQueryDocuments.matterTypeGroups,
      variables,
    });
    return result.data.matterTypeGroups || [];
  };
};

export const useQueryMatterTypes = (variables: {
  type: "lead" | "matter";
  locations: string[];
  categories: string[];
}) => {
  const apollo = useCachedClient();
  const [byCategory, setByCategory] = useState<
    {
      category: string;
      loading: boolean;
      matterTypes: {
        name: string;
        category: string;
        matterTypes: NonNullable<CachedGql.MatterTypeQuery["matterType"]>[];
      }[];
    }[]
  >([]);

  useAsync(
    async (info) => {
      if (!apollo) return;

      setByCategory(
        variables.categories.map((category) => ({
          category,
          loading: true,
          matterTypes: [],
        })),
      );

      await throttle({
        functions: variables.categories.map((category) => async () => {
          const byLocation = await throttle({
            functions: variables.locations.map((location) => async () => {
              const result = await apollo.query({
                query: cachedQueryDocuments.matterTypes,
                variables: { type: variables.type, location, category },
              });
              return result.data.matterTypes;
            }),
            rateLimit: 3,
          });

          const categoryMatterTypes = (byLocation?.[0] || [])
            .map((item) => {
              const matterTypes = byLocation
                .map((loc) => loc.find((m) => m.name === item.name))
                .filter(isNotNullOrUndefined);
              return matterTypes.length === byLocation.length
                ? { name: item.name, category, matterTypes }
                : undefined;
            })
            .filter(isNotNullOrUndefined);

          if (info.mounted)
            setByCategory((prev) =>
              prev.map((prevItem) =>
                prevItem.category === category
                  ? {
                      category,
                      loading: false,
                      matterTypes: categoryMatterTypes,
                    }
                  : prevItem,
              ),
            );
        }),
        rateLimit: 2,
      });
    },
    [
      apollo,
      variables.type,
      variables.locations.join(","),
      variables.categories.join(","),
    ],
  );

  return {
    byCategory,
  };
};

export const useLazyLoadMatterTypes = () => {
  const client = useCachedClient();
  return useLazyQuery(cachedQueryDocuments.matterTypes, { client });
};
