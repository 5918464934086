import { useMutation } from "@apollo/client";

import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";

export const useUpdateSetting = () =>
  useMutation(mutationDocuments.updateSetting, {
    update: (cache, { data }, { variables }) => {
      if (data?.updateSetting && variables) {
        cache.updateQuery(
          { query: queryDocuments.setting, variables: {} },
          (ex) => {
            if (ex?.setting) return ex;

            return {
              setting: data.updateSetting,
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useLoadSetting = useQueryFactory(queryDocuments.setting, {
  map: ({ setting }) => setting,
});
