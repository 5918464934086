import { FieldFunctionOptions } from "@apollo/client";
import { ResultOf, TypedDocumentNode } from "@graphql-typed-document-node/core";

import { PickByKeyValue } from "@smart/itops-utils-basic";
import { fragments } from "@smart/manage-gql-operations-dom";

type UpdatableFragment = PickByKeyValue<
  typeof fragments,
  string,
  TypedDocumentNode<{ values: { uri: string; updatedAt?: string | null } }>
>;

export const mergeUpdated =
  <K extends keyof UpdatableFragment>(fragmentName: K) =>
  (
    e: ResultOf<UpdatableFragment[K]>["values"] | undefined,
    i: ResultOf<UpdatableFragment[K]>["values"],
    { cache }: FieldFunctionOptions,
  ) => {
    const c =
      e ||
      cache.readFragment({
        id: cache.identify({
          // eslint-disable-next-line no-underscore-dangle
          __typename: i.__typename.replace("Values", ""),
          uri: i.uri,
        }),
        fragmentName,
        fragment: fragments[fragmentName],
      })?.values;

    return !c || (i.updatedAt || 0) >= (c.updatedAt || 0) ? i : c;
  };
