import { globalTeamUri } from "@smart/bridge-types-basic";
import { IconButton } from "@smart/itops-components-dom";
import { useUpdateFormOwnership } from "@smart/manage-gql-client-dom";

type MakeFormGlobalProps = {
  formUri: string;
  onDone?: () => void;
  disabled?: string;
};

const MakeFormGlobal = ({ formUri, onDone, disabled }: MakeFormGlobalProps) => {
  const [update, { loading, error }] = useUpdateFormOwnership();

  const onClick = async () => {
    await update({ variables: { uri: formUri, teamUri: globalTeamUri } });
    if (onDone) onDone();
  };

  return (
    <IconButton
      data-testid="make-form-global-button"
      name="library"
      text="Move to library"
      onClick={onClick}
      title={error?.message || disabled}
      spin={loading}
      disabled={!!disabled}
    />
  );
};

export { MakeFormGlobal, MakeFormGlobalProps };
