import { useMutation } from "@apollo/client";

import { sortField } from "@smart/itops-utils-basic";
import {
  mutationDocuments,
  queryDocuments,
} from "@smart/manage-gql-operations-dom";

import { useQueryFactory } from "./base";
import { optimisticOperation, updateAfterDelete } from "../cache";

export const useDeleteSection = () =>
  useMutation(mutationDocuments.deleteSection, {
    optimisticResponse: optimisticOperation("deleteSection"),
    update: (cache, { data }, { variables }) => {
      if (variables && data?.deleteSection) {
        const queryFields = cache.readQuery({
          query: queryDocuments.fields,
          variables: {
            formUri: variables?.formUri || "",
          },
        });

        queryFields?.fields
          .filter((f) => f.values.sectionUri === variables.uri)
          .forEach((f) =>
            updateAfterDelete("deleteField", "Field")(
              cache,
              {
                data: {
                  deleteField: { createdAt: data.deleteSection.createdAt },
                },
              },
              { variables: { uri: f.uri } },
            ),
          );

        updateAfterDelete("deleteSection", "Section")(
          cache,
          { data },
          { variables },
        );
      }
    },
    refetchQueries: [queryDocuments.sections],
  });

export const useUpdateSection = () =>
  useMutation(mutationDocuments.updateSection, {
    optimisticResponse: (input) => ({
      __typename: "Mutation" as const,
      updateSection: {
        __typename: "Section" as const,
        uri: input.uri,
        values: {
          __typename: "SectionValues" as const,
          uri: input.uri,
          formUri: input.formUri,
          order: input.order,
          title: input.fields.title,
          description: input.fields.description || "",
          links: (input.fields.links || []).map((link) => ({
            __typename: "FieldLink" as const,
            condition: link.condition,
            fieldUri: link.fieldUri,
            value: link.value,
            hide: link.hide,
          })),
          updatedAt: new Date().toISOString(),
          deleted: false,
          operationId: "",
        },
      },
    }),
    update: (cache, { data }, { variables }) => {
      if (data?.updateSection && variables) {
        cache.updateQuery(
          {
            query: queryDocuments.sections,
            variables,
          },
          (ex) => {
            const exists = ex?.sections.find(
              (s) => s.uri === data.updateSection.uri,
            );
            if (exists) return ex;

            return {
              sections: [data.updateSection, ...(ex?.sections || [])],
              __typename: "Query" as const,
            };
          },
        );
      }
    },
  });

export const useUpdateSectionOrder = (shouldRefetch: boolean = true) =>
  useMutation(mutationDocuments.updateSectionOrder, {
    optimisticResponse: optimisticOperation("updateSectionOrder"),
    update: (cache, _, { variables }) => {
      if (variables) {
        const { uri, order } = variables;

        cache.modify({
          id: cache.identify({ __typename: "Section", uri }),
          fields: {
            values: (existing) => ({ ...existing, order }),
          },
        });
      }
    },
    refetchQueries: shouldRefetch ? [queryDocuments.sections] : undefined,
  });

export const useLoadSections = useQueryFactory(queryDocuments.sections, {
  map: ({ sections }) =>
    sortField(
      sections.filter((f) => !f.values.deleted),
      { key: (f) => f.values.order, dir: "asc" },
    ),
});
