import { RuleEvaluators } from "../types";
import { hasRole } from "../utils";

export const team: RuleEvaluators["team"] = (
  user,
  action,
  { value: { uri } },
) => {
  // User can perform any action on their own team
  if (user.teamUri === uri) return true;

  // Users with the global role can view other teams
  if (action === "view" && hasRole(user, "CanUpdateGlobalIntakeForms"))
    return true;

  // Otherwise, no access
  return false;
};
