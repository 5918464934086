import { SDKError } from "@smart/itops-utils-basic";

import {
  DocumentRequest,
  EmailRequest,
  FormRequest,
  LetterRequest,
  UseSDKApiOptions,
} from "../types";
import { buildMailTo } from "../utils";

export const useCorrespondence = ({
  client,
  hostType,
  isInit,
}: UseSDKApiOptions) => {
  if (!isInit || !client) return undefined;

  return {
    email: (emailRequest: EmailRequest) => {
      try {
        if (hostType === "web") {
          window.open(buildMailTo(emailRequest));
        } else {
          client.correspondence.email(emailRequest);
        }
      } catch (error) {
        throw new SDKError(error, {
          action: "correspondence.email",
          input: emailRequest,
        });
      }
    },
    letter: (letterRequest: LetterRequest) => {
      try {
        if (hostType === "web") {
          console.warn("correspondence.letter is not supported in web host");
        } else {
          client.correspondence.letter(letterRequest);
        }
      } catch (error) {
        throw new SDKError(error, {
          action: "correspondence.letter",
          input: letterRequest,
        });
      }
    },
    document: (documentRequest: DocumentRequest) => {
      try {
        if (hostType === "web") {
          console.warn("correspondence.document is not supported in web host");
        } else {
          client.correspondence.document(documentRequest);
        }
      } catch (error) {
        throw new SDKError(error, {
          action: "correspondence.document",
          input: documentRequest,
        });
      }
    },
    form: (formRequest: FormRequest) => {
      try {
        if (hostType === "web") {
          console.warn("correspondence.form is not supported in web host");
        } else {
          client.correspondence.form(formRequest);
        }
      } catch (error) {
        throw new SDKError(error, {
          action: "correspondence.form",
          input: formRequest,
        });
      }
    },
  };
};
