import styled from "@emotion/styled";
import { useState } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { useGlobalKeyboardShortcut } from "@smart/itops-hooks-dom";

import { MatterDebug } from "./matter";
import { UserDebug } from "./user";

const DebugWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  right: ${(props) => (props.visible ? 0 : -40)}rem;
  height: 60rem;
  width: 40rem;

  padding: 0 1.4rem;
  --background: ${(props) => props.theme.palette.background.accent};
  background: var(--background);
  border: 1px solid ${(props) => props.theme.palette.default.accent};
  border-top-left-radius: 1rem;

  .visibility {
    border: 1px solid ${(props) => props.theme.palette.default.accent};
    border-right: 0;
    border-radius: 1rem 0 0 1rem;
    color: ${(props) => props.theme.palette.foreground.base};
    position: absolute;
    right: 100%;
    bottom: 3rem;
    margin: 0;
  }

  dl {
    font-size: ${(props) => props.theme.fontSize.base};
    border-bottom: 1px dotted ${(props) => props.theme.palette.default.accent};
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.5rem;
    align-items: center;

    dt {
      font-weight: bold;
      text-align: right;
    }

    dd {
      margin: 0;
      padding: 0.4rem;
    }
  }
`;

const Debug = () => {
  const [enabled, setEnabled] = useState(false);
  const [visible, setVisible] = useState(false);
  useGlobalKeyboardShortcut({
    key: "F12",
    modifiers: { shift: true, ctrlOrMeta: true },
    fn: () => setEnabled((current) => !current),
  });

  if (!enabled) return null;

  return (
    <DebugWrapper visible={visible}>
      <IconButton
        className="visibility"
        size={12}
        name="angleLeft"
        rotate={visible ? 180 : 0}
        onClick={() => setVisible((current) => !current)}
      />
      <UserDebug />
      <MatterDebug />
    </DebugWrapper>
  );
};

export { Debug };
