/* eslint-disable react/no-array-index-key */
import styled from "@emotion/styled";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { formCategoryLabel } from "@smart/bridge-types-basic";
import {
  Button,
  ButtonRow,
  Form,
  Input,
  Label,
  Loading,
  Rule,
} from "@smart/itops-components-dom";
import { extractId } from "@smart/itops-types-basic";
import {
  buildLogProps,
  isNotNullOrUndefined,
  specialChars,
} from "@smart/itops-utils-basic";

import { useImportProcess } from "./process";
import { ImportedForm } from "./schema";
import { useImportUpdate } from "./update";

const Indented = styled.div`
  padding-left: 1rem;
  width: 100%;
`;

const SectionLabel = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.emphasis};
  width: 100%;
`;

const GroupLabel = styled(SectionLabel)`
  font-size: ${(props) => props.theme.fontSize.base};
`;

export const ImportResultDisplay = ({
  status,
  result,
}: Omit<ReturnType<typeof useImportUpdate>, "onSubmit">) => {
  if (status === "loading") return <Loading />;
  if (!result) return null;

  const failedSections = result.sections.filter(isNotNullOrUndefined);
  const failedFields = result.fields.filter(isNotNullOrUndefined);
  const failedGroups = result.groups.filter(isNotNullOrUndefined);

  return (
    <>
      <p>
        <strong>Form:</strong>{" "}
        {result.form ? (
          buildLogProps(result.form).logMessage
        ) : (
          <Link to={`/forms/${extractId(result.uri!)}`}>View Form</Link>
        )}
      </p>
      <p>
        <strong>Sections:</strong>{" "}
        {result.sections.length - failedSections.length}/
        {result.sections.length} succeeded
      </p>
      {failedSections.map((error, i) => (
        <code key={i}>{JSON.stringify(buildLogProps(error).errorProps)}</code>
      ))}
      <p>
        <strong>Groups:</strong> {result.groups.length - failedGroups.length}/
        {result.groups.length} succeeded
      </p>
      {failedGroups.map((error, i) => (
        <code key={i}>{JSON.stringify(buildLogProps(error).errorProps)}</code>
      ))}
      <p>
        <strong>Fields:</strong> {result.fields.length - failedFields.length}/
        {result.fields.length} succeeded
      </p>
      {failedFields.map((error, i) => (
        <code key={i}>{JSON.stringify(buildLogProps(error).errorProps)}</code>
      ))}
    </>
  );
};

export type ImportDisplayProps = {
  importedForm: ImportedForm;
  teamUri?: string;
};

export const ImportDisplay = ({
  importedForm,
  teamUri,
}: ImportDisplayProps) => {
  const { matterTypeResult, fieldResult } = useImportProcess({ importedForm });
  const { onSubmit, status, result } = useImportUpdate({
    importedForm,
    matterTypeResult,
    fieldResult,
    teamUri,
  });

  if (!matterTypeResult || !fieldResult) return <Loading />;

  return (
    <Form onSubmit={onSubmit}>
      <Label name="form" text="Form Title">
        <Input readOnly name="form" value={importedForm.form.title} />
      </Label>
      <Label
        name="matterType"
        text={`${formCategoryLabel[importedForm.form.category]} Type`}
        invalid={matterTypeResult.error}
      >
        <Input readOnly name="matterType" value={matterTypeResult.display} />
      </Label>
      <Rule />
      {importedForm.sections.map((section) => (
        <Fragment key={section.uri}>
          <SectionLabel>{section.title}</SectionLabel>
          <Indented>
            {importedForm.groups.map(
              (group) =>
                group.sectionUri === section.uri && (
                  <Fragment key={group.uri}>
                    <GroupLabel>{`${
                      group.label ? `${`${group.label} -`}` : ""
                    } Layout "${group.layout?.name}"`}</GroupLabel>
                  </Fragment>
                ),
            )}
            {importedForm.fields.map(
              (field) =>
                field.sectionUri === section.uri &&
                !field.groupUri && (
                  <Label
                    key={field.uri}
                    name={field.uri}
                    text={field.label}
                    invalid={fieldResult[field.uri]?.error}
                  >
                    <Input
                      readOnly
                      name={field.uri}
                      value={
                        fieldResult[field.uri]?.display || specialChars.enDash
                      }
                    />
                  </Label>
                ),
            )}
          </Indented>
        </Fragment>
      ))}

      <Rule />
      <p>
        Save form with {importedForm.sections.length} section(s) {", "}
        {importedForm.groups.length} group(s) & {importedForm.fields.length}{" "}
        field(s)?
      </p>
      <ButtonRow>
        <Button type="submit" disabled={status !== "initial"}>
          Save Form
        </Button>
      </ButtonRow>
      <ImportResultDisplay status={status} result={result} />
    </Form>
  );
};
