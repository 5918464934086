import clsx from "clsx";
import { Fragment } from "react";

import { MultipleTextLabel } from "./multiple-text-label";

export type MultipleLabelProps = {
  labels: string[][];
  placeholders?: string[];
  className?: string;
  dividerClassName?: string;
  loading?: boolean;
  maxVisibleItems?: number;
};

export const MultipleLabel = ({
  labels,
  placeholders,
  className,
  dividerClassName,
  loading,
  maxVisibleItems,
}: MultipleLabelProps) => (
  <span className={clsx("flex flex-wrap gap-small", className)}>
    {labels.map((label, index) =>
      label.length > 0 ? (
        <Fragment key={placeholders?.[index] || index}>
          {index > 0 && (
            <span
              className={clsx("border border-neutral-400", dividerClassName)}
            />
          )}
          <MultipleTextLabel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            texts={label}
            placeholder={placeholders?.[index]}
            loading={loading}
            maxVisibleItems={maxVisibleItems}
          />
        </Fragment>
      ) : null,
    )}
  </span>
);
