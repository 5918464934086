import { useMemo } from "react";
import { Schema, z } from "zod";

import {
  FormCategory,
  formCategoryLabel,
  globalTeamUri,
} from "@smart/bridge-types-basic";
import { useStorage } from "@smart/itops-hooks-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import {
  ContextMenuProps,
  SelectProps,
} from "@smart/itops-sb-design-system-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { unique } from "@smart/itops-utils-basic";

import { GqlForm } from "../types";

const categorySchema = z.enum(["lead", "matter"]).optional();
const filtersSchema = z.array(z.string());
const locationsSchema = z.array(z.string());
const matterTypesSchema = z.array(
  z.object({ name: z.string(), category: z.string() }),
);
const creatorsSchema = z.array(z.string());

export const useFormData = ({ forms }: { forms?: GqlForm[] }) => {
  const providerInfo = useProviderInfo();
  const { terms } = loadLocale();
  const [category, setCategory] = useStorage({
    defaultValue: undefined,
    key: "form-builder-list-category",
    schema: categorySchema,
    storage: "local",
  });

  const [filters, setFilters] = useStorage({
    defaultValue: [],
    key: "form-builder-list-filters",
    schema: filtersSchema as Schema<(keyof typeof filterSets)[]>,
    storage: "session",
  });
  const [locations, setLocations] = useStorage({
    defaultValue: [],
    key: "form-builder-list-locations",
    schema: locationsSchema,
    storage: "session",
  });
  const [matterTypes, setMatterTypes] = useStorage({
    defaultValue: [],
    key: "form-builder-list-matterTypes",
    schema: matterTypesSchema,
    storage: "session",
  });
  const [creators, setCreators] = useStorage({
    defaultValue: [],
    key: "form-builder-list-creators",
    schema: creatorsSchema,
    storage: "session",
  });

  const byCategory: SelectProps<"all" | FormCategory> & {
    key: string;
  } = {
    key: "category",
    id: "category",
    name: "category",
    title: "Filter by category",
    options: (["all", "lead", "matter"] as const).map((o) => ({
      value: o,
      label: `${o === "all" ? "All" : formCategoryLabel[o]} forms`,
    })),
    value: category || "all",
    onChange: (v) =>
      setCategory(v === "all" || Array.isArray(v) ? undefined : v),
    isMultiple: false,
    className: "w-56",
    dataTestId: "select-category",
  };

  const locationOptions = useMemo(
    () => unique(forms?.flatMap((f) => f.matterTypeLocations) || []).sort(),
    [forms],
  );
  const byLocation: SelectProps<string> & { key: string } = {
    key: "location",
    id: "location",
    name: "location",
    title: `Filter by ${terms.state.name}`,
    options: locationOptions.map((o) => ({ label: o, value: o })),
    value: locations,
    isMultiple: true,
    maxItems: 2,
    onChange: (v) => setLocations(Array.isArray(v) ? v : [v]),
    placeholder: terms.state.title,
    dataTestId: "select-state",
  };

  const matterTypeOptions = useMemo(
    () =>
      unique(
        forms?.flatMap((f) =>
          f.rawForm.values.matterTypes.map((m) => `${m.category}|||${m.name}`),
        ) || [],
      )
        .sort()
        .map((m) => {
          const parts = m.split("|||");
          return { category: parts[0], name: parts[1] };
        }),
    [forms],
  );
  const groupMatterTypeOptions = () => {
    const options: {
      heading: string;
      items: { label: string; value: { name: string; category: string } }[];
    }[] = [];

    matterTypeOptions.forEach((matterType) => {
      const group = options.find((o) => o.heading === matterType.category);
      if (group?.items) {
        group.items.push({ label: matterType.name, value: matterType });
      } else {
        options.push({
          heading: matterType.category,
          items: [{ label: matterType.name, value: matterType }],
        });
      }
    });

    return options;
  };

  const byMatterType: SelectProps<{ name: string; category: string }> & {
    key: string;
  } = {
    key: "matterType",
    id: "matterType",
    name: "matterType",
    title: "Filter by matter type",
    options: groupMatterTypeOptions(),
    value: matterTypes,
    isMultiple: true,
    maxItems: 2,
    onChange: (v) => setMatterTypes(Array.isArray(v) ? v : [v]),
    placeholder: "Types",
    valueComparer: (o, v) => o.name === v.name,
  };

  const creatorOptions = useMemo(
    () =>
      unique(
        forms?.map((f) => f.createdBy || providerInfo?.label || "") || [],
      ).sort(),
    [forms],
  );
  const byCreator: SelectProps<string> & { key: string } = {
    id: "creator",
    name: "creator",
    title: "Filter by creator",
    key: "creator",
    options: creatorOptions.map((o) => ({ label: o, value: o })),
    value: creators,
    isMultiple: true,
    maxItems: 2,
    onChange: (v) => setCreators(Array.isArray(v) ? v : [v]),
    placeholder: "Creators",
    dataTestId: "select-creator",
  };

  const filterSets = { byLocation, byMatterType, byCreator };

  const onToggleFilter = (key: keyof typeof filterSets) => () => {
    if (filters.includes(key)) {
      setFilters((f) => f.filter((k) => k !== key));
      ({
        byLocation: setLocations,
        byMatterType: setMatterTypes,
        byCreator: setCreators,
      })[key]([]);
    } else {
      setFilters((f) => [...f, key]);
    }
  };

  const filterOptions: ContextMenuProps["items"] = [
    {
      label: `By ${terms.state.name}`,
      checked: filters.includes("byLocation"),
      onClick: onToggleFilter("byLocation"),
    },
    {
      label: "By matter type",
      checked: filters.includes("byMatterType"),
      onClick: onToggleFilter("byMatterType"),
    },
    {
      label: "By creator",
      checked: filters.includes("byCreator"),
      onClick: onToggleFilter("byCreator"),
    },
  ];

  const data = useMemo(() => {
    if (!forms) return [];

    return forms.filter((f) => {
      if (category && f.formCategory !== category) return false;

      if (creators.length) {
        const isCreator = creators.find((c) => c === f.createdBy);
        const isSmokeball =
          creators.includes(providerInfo?.label || "") &&
          f.teamUri === globalTeamUri;

        if (!isCreator && !isSmokeball) return false;
      }

      if (locations.length) {
        if (
          !locations.find((l) => f.matterTypeLocations.some((mtl) => mtl === l))
        )
          return false;
      }

      if (matterTypes.length) {
        if (
          !matterTypes.find((mt) =>
            f.rawForm.values.matterTypes.some(
              (m) => m.category === mt.category && m.name === mt.name,
            ),
          )
        )
          return false;
      }

      return true;
    });
  }, [forms, category, creators, locations, matterTypes]);

  return {
    data,
    filterOptions,
    filters: [
      byCategory,
      ...filters.map((f) => filterSets[f]),
    ] as SelectProps<any>[],
  };
};
