import styled from "@emotion/styled";

import { hands } from "@smart/bridge-images-dom";

const HeroSectionWrapper = styled.section`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
  height: 24rem;
  color: white;
  font-size: ${(props) => props.theme.fontSize.small};
  margin: 2rem 0 4rem 0;
  filter: grayscale(100%);
  color: ${(props) => props.theme.palette.disabled.base};

  h1 {
    font-size: ${(props) => props.theme.fontSize.heading};
    font-weight: bold;
    padding: 0 0 1rem 1.4rem;
  }

  article p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 200;
  }

  a {
    background-color: ${(props) => props.theme.palette.disabled.base};
    text-decoration: none;
    color: white;
    border-radius: 0.5rem;
    text-align: center;
    display: inline-block;
    transition: all 0.3s;
    padding: 1rem;
    margin: 0 2rem 2rem 0;
    cursor: default;
  }
`;

type HeroSectionProps = {
  title?: string;
  content: string;
  buttonLabel?: string;
};

const HeroSection = ({ title, content, buttonLabel }: HeroSectionProps) => (
  <HeroSectionWrapper style={{ backgroundImage: `url(${hands})` }}>
    {title && <h1>{title}</h1>}
    <article>
      <p>{content}</p>
      {buttonLabel && <a href="#sample-button">{buttonLabel}</a>}
    </article>
  </HeroSectionWrapper>
);

export { HeroSectionProps, HeroSection };
