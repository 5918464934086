import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { isNullOrUndefined } from "@smart/itops-utils-basic";

import {
  active,
  border,
  containerBase,
  disabled,
  hover,
  iconBase,
  labelBase,
} from "./class-names";
import { Icon, IconName } from "../icon";

type LabelItem = { label: string; disabled?: boolean; onClick?: () => void };
type IconItem = { icon: IconName; disabled?: boolean; onClick?: () => void };

type Items<T extends "label" | "icon"> = T extends "label"
  ? LabelItem[]
  : IconItem[];

export type SegmentedControlProps<T extends "label" | "icon"> = {
  items: Items<T>;
  className?: string;
  isCompact?: boolean;
  defaultIndex?: number;
  index?: number;
};

export const SegmentedControl = <T extends "label" | "icon">({
  items,
  className,
  isCompact,
  defaultIndex,
  index,
}: SegmentedControlProps<T>) => {
  const [selected, setSelected] = useState<number | undefined>(defaultIndex);

  useEffect(() => {
    if (!isNullOrUndefined(index)) setSelected(index);
  }, [index]);

  return (
    <div
      className={twMerge(clsx(containerBase(!!isCompact), className))}
      role="radiogroup"
    >
      {items.map((item, itemIndex) => {
        const isLabelItem = "label" in item;
        const itemBase = isLabelItem
          ? labelBase(!!isCompact)
          : iconBase(!!isCompact);

        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={itemIndex}
            aria-checked={selected === itemIndex}
            disabled={item.disabled}
            onClick={() => {
              setSelected(itemIndex);
              item.onClick?.();
            }}
            className={twMerge(clsx(itemBase, hover, border, active, disabled))}
            role="radio"
            type="button"
          >
            {isLabelItem ? (
              item.label
            ) : (
              <Icon
                name={item.icon}
                className={
                  isCompact ? "h-[1rem] w-[1rem]" : "h-[1.6rem] w-[1.4rem]"
                }
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
