import { useState } from "react";

import { trackChanged } from "@smart/bridge-metrics-dom";
import { Overlay } from "@smart/itops-components-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import { SubmissionActions } from "./actions";
import { SubmissionView } from "./view";

export type SubmissionProps = {
  submission: MatterSubmission;
};

export const Submission = ({ submission }: SubmissionProps) => {
  const { matters, host } = useSmokeballApp();

  const [cancelling, onCancel] = useState<MatterSubmission>();
  const [deleting, onDelete] = useState<MatterSubmission>();
  const [reminding, onRemind] = useState<MatterSubmission>();
  const [editing, onEdit] = useState<MatterSubmission>();

  if (!matters?.current && !matters?.loading)
    return (
      <Overlay
        icon="form"
        heading="Intake not available"
        subHeading="No matter information currently available"
      />
    );

  trackChanged(submission, "Submission Viewed", (viewingSubmission) => {
    if (!viewingSubmission) return undefined;

    return {
      submissionUri: viewingSubmission.uri,
      formUri: viewingSubmission.formUri,
      matterCategory: viewingSubmission.rawSubmission.form.values.category,
      matterTypeId: matters.current?.matterTypeId,
      matterTypeName:
        viewingSubmission.rawSubmission.form.values.matterTypes.find(
          (mt) => mt.id === matters.current?.matterTypeId,
        )?.name,
    };
  });

  return (
    <>
      <SubmissionView
        selected={submission}
        fullView
        onClose={() => {}}
        onCancel={onCancel}
        onDelete={onDelete}
        onRemind={onRemind}
        onEdit={onEdit}
      />
      <SubmissionActions
        onViewClose={() => host?.close()}
        cancelling={{
          selected: cancelling,
          onClose: () => onCancel(undefined),
        }}
        deleting={{
          selected: deleting,
          onClose: () => onDelete(undefined),
        }}
        reminding={{
          selected: reminding,
          onClose: () => onRemind(undefined),
        }}
        editing={{
          selected: editing,
          onClose: () => onEdit(undefined),
        }}
      />
    </>
  );
};
