import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  ConnectionItem,
  IntakeForm,
  intakeTheme,
  ResponseSubmitter,
} from "@smart/bridge-intake-components-dom";
import { track } from "@smart/bridge-metrics-dom";
import {
  appendDownloadFormLink,
  defaultTemplate,
} from "@smart/bridge-templates-basic";
import { familyProQuestionnaireFormUri } from "@smart/bridge-types-basic";
import { ThemeProvider } from "@smart/itops-components-dom";
import { Modal as ModalComponent } from "@smart/itops-smokeball-components-dom";
import { uploadFile as upload } from "@smart/itops-ui-dom";
import { entriesOf, fromEntries, jsonParse } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLoadSubmission,
  useUpdateResponse,
  useUpdateSubmission,
  useUploadFiles,
} from "@smart/manage-gql-client-dom";
import { useLoadFormForDisplay, useToast } from "@smart/manage-hooks-dom";

import { useOpenFamilyProForm } from "../../shared/use-open-family-pro-form";

const Modal = styled(ModalComponent)`
  .modal {
    .content {
      form {
        max-height: initial;
      }
    }
  }
`;

export type SubmissionActionEditProps = {
  selected?: MatterSubmission;
  onClose: () => void;
  updateResponse: ReturnType<typeof useUpdateResponse>[0];
  updateSubmission: ReturnType<typeof useUpdateSubmission>[0];
  uploadFiles: ReturnType<typeof useUploadFiles>[0];
};

export const SubmissionActionEdit = ({
  selected,
  onClose,
  updateResponse,
  updateSubmission,
  uploadFiles,
}: SubmissionActionEditProps) => {
  const { setToasts } = useToast();
  const [connection, setConnection] = useState<ConnectionItem>({
    status: "initial",
  });
  const showConnectionBanner = useState(true);
  const displayProps = useLoadFormForDisplay({ formUri: selected?.formUri });
  const submission = useLoadSubmission(
    { uri: selected?.uri || "" },
    {
      fetchPolicy: "network-only",
      pollInterval: selected?.syncStatus === "loading" ? 5000 : undefined,
    },
  );
  const { aiWordSupport } = useFeatureFlags();
  useOpenFamilyProForm({
    selectedFormUri: selected?.formUri,
    externalSubmissionEmbedUrl: selected?.externalSubmissionEmbedUrl,
    callbackFn: onClose,
  });

  const isLoadingInfoFromSB =
    submission.result?.values.syncStatus === "loading";

  const submit: ResponseSubmitter = async ({
    status,
    responses,
    lastUpdatedFieldUri,
    lastUpdatedSectionUri,
  }) => {
    const submissionUri = selected!.uri;
    const { formUri } = selected!;
    if (status === "completed")
      track("Submission Completed", {
        formUri,
        submissionUri,
      });

    try {
      setConnection({ status: "loading" });
      const updateResponseResults = await Promise.all(
        entriesOf(responses)
          .filter(([, value]) => value !== undefined)
          .map(([fieldUri, value]) =>
            updateResponse({
              variables: {
                fieldUri,
                value: JSON.stringify(value),
                submissionUri,
                formUri,
              },
            }),
          ),
      );
      const operationIds = updateResponseResults.map(
        (r) => r.data?.updateResponse.operationId,
      );

      let updateSubmissionResult;
      if (status || lastUpdatedFieldUri || lastUpdatedSectionUri) {
        updateSubmissionResult = await updateSubmission({
          variables: {
            uri: submissionUri,
            formUri,
            fields: {
              lastUpdatedFieldUri,
              lastUpdatedSectionUri,
              status,
            },
          },
        });
        operationIds.push(
          updateSubmissionResult.data?.updateSubmission.values.operationId,
        );
      }
      setConnection({ status: "success" });

      if (status === "completed") {
        setToasts([{ text: "Your responses have been saved" }]);
        onClose();
      }

      return {
        operationIds: operationIds.filter(Boolean) as string[],
        submissionStatus: {
          status:
            status ||
            updateSubmissionResult?.data?.updateSubmission.values.status,
          deleted: updateSubmissionResult?.data?.updateSubmission.values
            .deleted as boolean | undefined,
        },
      };
    } catch (err) {
      setConnection({ status: "error", error: String(err) });
      return undefined;
    }
  };

  useEffect(() => {
    if (
      submission.result?.values.syncStatus &&
      submission.result?.values.syncStatus !== "loading"
    ) {
      if (submission.stopPolling) submission.stopPolling();
    }
  }, [submission]);

  if (selected?.formUri === familyProQuestionnaireFormUri) return null;

  return (
    <Modal
      size="maximum"
      title="Edit Responses"
      open={[!!selected, onClose]}
      buttons={[{ text: "Close", type: "reset", onClick: onClose }]}
      loading={
        displayProps.loading || submission.loading || isLoadingInfoFromSB
      }
      closeOptions={{ clickOutside: false }}
      isForm={false}
    >
      <ThemeProvider theme={intakeTheme}>
        {!displayProps.loading &&
          displayProps.exists &&
          submission.result &&
          !isLoadingInfoFromSB && (
            <IntakeForm
              {...displayProps}
              form={{
                ...displayProps.form,
                response: appendDownloadFormLink(
                  displayProps.form.response || defaultTemplate.response,
                ),
              }}
              lookup={{
                addressLookup: displayProps.lookup.addressLookup,
                fileLookup: {
                  upload,
                  load: async ({ fieldUri, fileNames }) => {
                    const result = await uploadFiles({
                      variables: {
                        fieldUri,
                        fileNames,
                        formUri: selected!.formUri,
                        submissionUri: selected!.uri,
                      },
                    });
                    return result.data?.uploadFiles;
                  },
                },
              }}
              connection={connection}
              submission={{
                ...submission.result,
                autoFillStatus: submission.result.aiUserFeedback?.values.status,
                responses: fromEntries(
                  submission.result.responses.map((r) => [
                    r.fieldUri,
                    {
                      value: jsonParse(r.value, "parse response"),
                    },
                  ]),
                ),
              }}
              submitResponses={submit}
              isInternal={
                selected?.rawSubmission.values.communicationMethod ===
                "internalUse"
              }
              loadResponses={async () => undefined}
              staffDetails={[]}
              showConnectionBanner={showConnectionBanner}
              aiWordSupport={aiWordSupport}
            />
          )}
      </ThemeProvider>
    </Modal>
  );
};
