import clsx from "clsx";

import { Checkbox, Icon, IconName } from "@smart/itops-sb-design-system-dom";

export type PickerItemProps = {
  icon: IconName;
  iconColor?: string;
  iconBgColor?: string;
  text: string;
  onClick: () => void;
  checked?: boolean;
  disabled?: boolean;
};

export const PickerItem = ({
  icon,
  iconBgColor,
  text,
  onClick,
  checked,
  disabled,
  iconColor,
}: PickerItemProps) => (
  <button
    className="flex flex-nowrap items-center gap-small disabled:cursor-not-allowed disabled:opacity-60"
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {checked !== undefined && (
      <Checkbox checked={checked} disabled={disabled} />
    )}
    <div
      className={clsx(
        "flex justify-center items-center w-[2.4rem] h-[2.4rem] rounded-small",
        iconBgColor,
      )}
    >
      <Icon name={icon} className={iconColor} />
    </div>
    <span
      className={clsx(
        checked === undefined ? "text-blue-560" : "text-neutral-850",
      )}
    >
      {text}
    </span>
  </button>
);
