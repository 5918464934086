import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@smart/itops-components-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 4rem;
  margin: 1rem 0 0 0;
  font-size: ${(props) => props.theme.fontSize.small};
`;

const ActingIndicator = () => {
  const navigate = useNavigate();
  const { actingTeam, clearActingFirm } = useManageFormsContext();

  return actingTeam ? (
    <Wrapper>
      {`You are acting as "${actingTeam.name}"`}
      <IconButton
        name="undo"
        text="Back to my firm"
        onClick={() => {
          clearActingFirm();
          navigate("/forms", { replace: true });
        }}
      />
    </Wrapper>
  ) : null;
};

export { ActingIndicator };
