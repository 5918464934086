import { format, isValid } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";

import {
  Country,
  CurrencyResponse,
  FieldType,
  PhoneNumberResponse,
} from "@smart/bridge-types-basic";
import {
  isNotNullOrUndefined,
  isNullOrUndefined,
  sortField,
} from "@smart/itops-utils-basic";

import { Field, LayoutField, SBContactType, Section } from "./types";

export const convertCurrencyToSB = (currency: CurrencyResponse) =>
  currency && typeof currency === "object"
    ? currency.value
    : `${currency || ""}`;

export const convertPhoneNumberToSB = (
  country: Country,
  phoneNumber?: PhoneNumberResponse,
) => {
  switch (country) {
    case "US": {
      const isDomestic = phoneNumber?.countryCallingCode === "1";
      const match = phoneNumber?.formattedNumber?.match(/\((\d{3})\) (.+)/);
      return isDomestic
        ? { areaCode: match?.[1], number: match?.[2] || phoneNumber?.number }
        : { number: phoneNumber?.number };
    }
    case "AU": {
      const isDomestic = phoneNumber?.countryCallingCode === "61";
      return {
        number: isDomestic ? phoneNumber.formattedNumber : phoneNumber?.number,
      };
    }
    case "GB": {
      const isDomestic = phoneNumber?.countryCallingCode === "44";
      return {
        number: isDomestic ? phoneNumber.formattedNumber : phoneNumber?.number,
      };
    }
    default:
      return { number: phoneNumber?.number };
  }
};

export const convertPhoneNumberFromSB = (
  country: Country,
  value?: string | Record<string, any>,
) => {
  const number =
    typeof value === "string"
      ? value
      : [value?.areaCode, value?.number].filter(Boolean).join("");

  try {
    const parsed = parsePhoneNumber(number, country);

    return {
      number: parsed?.number || number || null,
      countryCallingCode: parsed?.countryCallingCode || null,
      formattedNumber: parsed?.formatNational() || number || null,
      country: parsed?.country || null,
    };
  } catch (error) {
    return {
      number: null,
      countryCallingCode: null,
      formattedNumber: null,
      country: null,
    };
  }
};

export const convertDateToSB = (value: string | null | undefined) => {
  if (isNullOrUndefined(value)) return value;

  const date = new Date(value);

  return isValid(date) ? format(date, "yyyy-MM-dd") : undefined;
};

export const getContactInfo = (fieldName: string): [SBContactType, string] => {
  const [contactType, ...keys] = (fieldName.split("/") || []) as [
    SBContactType,
    string,
  ];
  const key = keys.join("/");

  return [contactType, key];
};

export const getClientRoleName = (
  sections: Section[],
  fields: Field[],
  formMatterTypeRepresentatives: string[] | undefined,
) => {
  const sortedSections = sortField(sections, {
    key: (f) => f.order,
    dir: "asc",
  });

  for (const section of sortedSections) {
    const sortedFields = sortField(
      fields.filter((f) => f.sectionUri === section.uri),
      { key: (f) => f.order, dir: "asc" },
    );

    const layoutId = sortedFields.find(
      ({ layout }) =>
        !!layout?.id && formMatterTypeRepresentatives?.includes(layout.id),
    )?.layout?.id;

    if (layoutId) return layoutId;
  }

  return undefined;
};

export const getMatterTypeRepresentatives = (
  matterTypes: { representativeOptions?: string[] }[],
) => [
  ...new Set(
    matterTypes
      .map(({ representativeOptions }) => representativeOptions)
      .flat()
      .filter(isNotNullOrUndefined),
  ),
];

export const getLayoutFieldName = (
  formLayoutField: LayoutField,
  matterLayoutFields: LayoutField[],
  type: FieldType,
): string | null => {
  for (const matterLayoutField of matterLayoutFields) {
    const basedMatterLayoutFieldName = matterLayoutField.name
      .replaceAll("[]", "")
      .toLowerCase();
    const basedFormLayoutFieldName = formLayoutField.name
      .replaceAll("[]", "")
      .toLowerCase();

    if (basedMatterLayoutFieldName === basedFormLayoutFieldName)
      return matterLayoutField.name;

    const consolidatedTypes: FieldType[] = ["address", "choice", "checkbox"];
    const matterLayoutFieldPathSize =
      basedMatterLayoutFieldName.split("/").length;
    const formLayoutFieldPathSize = basedFormLayoutFieldName.split("/").length;
    // Consolidated form fields should have only one extra suffixed option.
    if (
      consolidatedTypes.includes(type) &&
      basedMatterLayoutFieldName.startsWith(basedFormLayoutFieldName) &&
      matterLayoutFieldPathSize === formLayoutFieldPathSize + 1
    ) {
      const nameParts = matterLayoutField.name.split("/");
      const possibleValue = (nameParts.pop() || "").replace("[]", "");
      if (
        type === "address" ||
        formLayoutField.possibleValues?.includes(possibleValue)
      ) {
        return nameParts.join("/");
      }
    }
  }

  return null;
};

type IndexLocation = "left-most" | "right-most";
type BuildIndexOptions = {
  location: IndexLocation;
};

export const buildLayoutFieldName = ({
  name,
  index = 0,
  options = { location: "right-most" },
}: {
  name: string;
  index?: number;
  options?: BuildIndexOptions;
}) => {
  if (!name.includes("[]")) return name;

  const nameParts =
    options.location === "right-most"
      ? name.split("/").reverse()
      : name.split("/");

  let first = false;
  let found = false;
  const indexedParts = nameParts.map((part) => {
    if (part.endsWith("[]") && !found) {
      first = true;
      found = true;
    } else {
      first = false;
    }

    return part.replace("[]", first ? `[${index}]` : "[0]");
  });

  return options.location === "right-most"
    ? indexedParts.reverse().join("/")
    : indexedParts.join("/");
};
