import styled from "@emotion/styled";
import { useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { Button, ButtonRow, FieldGroup, TextArea } from "@smart/itops-ui-dom";
import {
  useToggleSlug,
  useUpdateAIUserFeedback,
} from "@smart/manage-gql-client-dom";

import { GqlForm } from "../types";

const MAX_RATING = 5;
const stars = Array.from({ length: MAX_RATING }, (_, index) => index + 1);

const Rating = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin: 1rem 0;

  .star {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.4rem;
    background: none;
    border: 0;
    color: ${(props) => props.theme.scheme.grey.r80};

    svg {
      fill: currentColor;
      fill-opacity: 0;
      transition: fill-opacity 0.2s ease;
    }
  }

  .star:hover svg,
  .star:has(~ .star:hover) svg {
    fill-opacity: 1;
  }

  .star[aria-pressed="true"] svg,
  .star:has(~ .star[aria-pressed="true"]) svg {
    color: ${(props) => props.theme.scheme.orange.r100};
    fill-opacity: 1;
  }
`;

export type GeneratingFeedbackProps = {
  aiUserFeedback?: GqlForm["aiUserFeedback"] | null;
  title: string;
  updating?: boolean;
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  updateSlug: ReturnType<typeof useToggleSlug>[0];
  form?: GqlForm | null;
};

export const GeneratingFeedback = ({
  aiUserFeedback,
  title,
  updating,
  updateAIUserFeedback,
  updateSlug,
  form,
}: GeneratingFeedbackProps) => {
  const [retry, setRetry] = useState(false);
  const [rating, setRating] = useState<number>();
  const [feedback, setFeedback] = useState<string>("");

  switch (aiUserFeedback?.status) {
    case "generated":
      return retry ? (
        <>
          <h4>How could this form be improved?</h4>
          <FieldGroup id="feedback" size="emphasis">
            <TextArea
              id="feedback"
              value={feedback}
              rows={3}
              onChange={(e) => setFeedback(e.currentTarget.value)}
            />
          </FieldGroup>
          <ButtonRow align="flex-end">
            <Button
              text="Cancel"
              variant="cancel"
              onClick={() => setRetry(false)}
            />
            <Button
              text="Regenerate"
              variant="save"
              onClick={async () => {
                await updateAIUserFeedback({
                  variables: {
                    uri: aiUserFeedback.uri,
                    status: "generating",
                    statusText: "",
                    feedback,
                  },
                });
                setRetry(false);
                setFeedback("");
              }}
            />
          </ButtonRow>
        </>
      ) : (
        <>
          <h4>How would you like to continue?</h4>
          <ButtonRow align="flex-start">
            <Button
              text="Refine"
              size="base"
              icon={{ library: "lucide", name: "RefreshCcw" }}
              variant="cancel"
              disabled={updating}
              onClick={() => setRetry(true)}
            />
            <Button
              text="Use"
              icon={{ library: "lucide", name: "Check" }}
              variant="save"
              disabled={updating}
              onClick={async () => {
                await updateAIUserFeedback({
                  variables: {
                    uri: aiUserFeedback.uri,
                    status: "completed",
                    statusText: `Saving "${title}"...`,
                  },
                });

                if (form?.rawForm.values.matterId) {
                  await updateSlug({
                    variables: {
                      formUri: form.uri,
                      fields: { active: true },
                    },
                  });
                }
              }}
            />
          </ButtonRow>
        </>
      );
    case "completed":
      return (
        <>
          <Button
            className="close-card"
            icon={{ library: "lucide", name: "X" }}
            kind="borderless"
            aria-label="Close Modal - X"
            variant="plain"
            onClick={() =>
              updateAIUserFeedback({
                variables: { uri: aiUserFeedback.uri, status: "reviewed" },
              })
            }
          />
          <h4>How would you rate this experience?</h4>
          <Rating>
            {stars.map((star) => (
              <button
                key={star}
                type="button"
                className="star"
                onClick={() => setRating(star)}
                aria-pressed={rating === star}
              >
                <Icon key={star} library="lucide" name="Star" size={24} />
              </button>
            ))}
          </Rating>
          {rating && (
            <>
              <h4>
                {rating < 4
                  ? "How could we improve this experience?"
                  : "What went well with this experience?"}
              </h4>
              <FieldGroup id="feedback" size="emphasis">
                <TextArea
                  id="feedback"
                  value={feedback}
                  rows={3}
                  onChange={(e) => setFeedback(e.currentTarget.value)}
                />
              </FieldGroup>
              <ButtonRow align="flex-end">
                <Button
                  text="Send feedback"
                  variant="save"
                  onClick={() =>
                    updateAIUserFeedback({
                      variables: {
                        uri: aiUserFeedback.uri,
                        status: "reviewed",
                        statusText: "",
                        rating,
                        feedback,
                      },
                    })
                  }
                />
              </ButtonRow>
            </>
          )}
        </>
      );
    default:
      return null;
  }
};
